import { Container, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import { ContentLayout } from 'components/layouts';
import SearchBox from 'components/search/SearchBox';
import { selectCurrentUserId, selectCurrentUserRole } from 'features/auth';
import { useGetCompanyByUserIdQuery } from 'features/companies/api/companies.api';
import StuckPointStatusMenu from 'features/stuckPoints/components/StuckPointStatusMenu';
import StuckPointTable from 'features/stuckPoints/components/StuckPointTable';
import { useGetCompaniesStuckPointsQuery } from 'features/stuckPoints/store/stuckPoint.api';
import debounce from 'lodash/debounce';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const ContactStuckPoints = () => {
  const { contactId } = useParams();
  const userId = useSelector((state) => selectCurrentUserId(state));
  const userRole = useSelector((state) => selectCurrentUserRole(state));
  const id = userRole === 'contractor_contact' ? userId : contactId;
  const { data: companyDetails } = useGetCompanyByUserIdQuery({ userId: id });

  const [filters, setFilters] = useState({ page: 1, search: undefined });

  const {
    data: stuckPointData = {},
    isLoading,
    isFetching,
  } = useGetCompaniesStuckPointsQuery({ id: companyDetails?.id, ...filters });
  const stuckPoints = stuckPointData?.data || [];

  const handleSearch = debounce((event) => {
    const searchQuery = event.target.value.trim();
    setFilters((filters) => ({ ...filters, search: searchQuery || undefined, page: 1 }));
  }, 500);

  const handleChangePage = (event, newPage) => {
    setFilters((prevFilters) => ({ ...prevFilters, page: newPage }));
  };

  return (
    <ContentLayout>
      <Container maxWidth={'xl'}>
        <Box mt={2}>
          <Typography variant={'h4'}>Contractor Stuck Points</Typography>
          <Paper elevation={2}>
            <Toolbar style={{ position: 'sticky', left: '0px' }}>
              <Grid container item>
                <SearchBox
                  bgcolor={'grey.200'}
                  borderRadius={1}
                  fullWidth={false}
                  placeholder="Search By Task Name"
                  onChange={handleSearch}
                />
                <Grid container item xs justifyContent={'flex-end'} spacing={1}>
                  <Grid item>
                    <StuckPointStatusMenu
                      selectedStatus={filters.resolved}
                      setFilters={setFilters}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
            <StuckPointTable
              isFetching={isFetching}
              isLoading={isLoading}
              stuckPoints={stuckPoints}
            />
            <Pagination
              color="primary"
              count={stuckPointData?.lastPage}
              disabled={isFetching}
              page={filters?.page}
              shape="rounded"
              sx={{ p: 1 }}
              onChange={handleChangePage}
            />
          </Paper>
        </Box>
      </Container>
    </ContentLayout>
  );
};

export default ContactStuckPoints;
