import { useState } from 'react';
import CreateComment from './CreateComment';
import IconButton from '@mui/material/IconButton';
import MoreVert from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { useDeleteCommentMutation } from "../store/comment.api";
import EditIconComponent from '@blackhyve/common/src/icons/Edit';
import DeleteIconComponent from '@blackhyve/common/src/icons/Delete';
import { ConfirmDeleteDialog, OptionsMenu, StyledDialog } from '@blackhyve/common';


/**
 * Comment Menu Options
 * @returns 
 */
const CommentMenuOptions = ({ comment }) => {

  const [isEditing, setIsEditing] = useState(false);
  const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState(false);

  const [deleteComment] = useDeleteCommentMutation();

  const handleDeleteComment = (meta) => {
    deleteComment({ ...meta, id: comment.id })
  };

  const handleCloseEditing = () => setIsEditing(false);

  return <>
    <OptionsMenu
      anchorOrigin={{
        vertical: 'middle',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'middle',
        horizontal: 'right',
      }}
      triggerButton={
        <IconButton style={{ padding: '0px' }}>
          <MoreVert />
        </IconButton>
      }
    >
      {(handleClose) => (
        <>
          <ListItemButton
            dense
            onClick={() => {
              handleClose();
              setIsEditing(true);
            }}
          >
            <ListItemIcon>
              <EditIconComponent />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </ListItemButton>
          <ListItemButton
            dense
            onClick={() => {
              handleClose();
              setDeleteConfirmationPopup(true);
            }}
          >
            <ListItemIcon>
              <DeleteIconComponent color={'red'} />
            </ListItemIcon>
            <ListItemText style={{ color: 'red' }}>Delete</ListItemText>
          </ListItemButton>
        </>
      )}
    </OptionsMenu>
    {deleteConfirmationPopup && (
      <ConfirmDeleteDialog
        handleClose={() => setDeleteConfirmationPopup(false)}
        handleDelete={handleDeleteComment}
        id={comment?.id}
        item="Comment"
        open={true}
      />
    )}
    {isEditing && <EditCommentDialog open={isEditing} handleClose={handleCloseEditing} comment={comment} />}
  </>
}

const EditCommentDialog = ({ open, handleClose, comment }) => {

  const handleSave = () => { }


  return <StyledDialog
    open={open}
    title={'Update Comment'}
    handleClose={handleClose}
  // actions={
  //   <>
  //     <Button
  //       size="small"
  //       onClick={handleClose}>
  //       Close
  //     </Button>
  //     <LoadingButton
  //       loading={false}
  //       size="small"
  //       variant="contained"
  //     >
  //       Save
  //     </LoadingButton>
  //   </>
  // }
  >
    <CreateComment id={comment.id} defaultComment={comment.comment} handleClose={handleClose} />
  </StyledDialog>

}



export default CommentMenuOptions