import { Grid, Container, Box, Typography, Paper, FormHelperText, FormLabel } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { ContentLayout } from 'components/layouts';
import { useParams } from 'react-router-dom';
import { SummaryCard, SummaryItem } from '../components/BudgetSummary';
import { formatMoney } from '../components/NumericControls';
import { useGetProjectForecastQuery } from '../store/forecast.api';
import { useGetProjectQuery } from 'features/projects/store/project.api';
import Forecast from '../components/Forecast';

const ProjectBudgetsSummary = ({ project }) => {
  const { data: forecast, isLoading } = useGetProjectForecastQuery({ projectId: +project });

  if (isLoading) {
    return null;
  }
  return (
    <>
      <Grid
        container
        item
        sx={{ display: 'grid', gap: '2em', gridTemplateColumns: 'repeat(4, minmax(0, 1fr));' }}
        xs={12}
      >
        <SummaryCard item title="Estimated">
          <SummaryItem label="Amount">
            ${formatMoney(forecast.summaries.estimated.amount)}
          </SummaryItem>
          <SummaryItem label="Labor">
            <span>${formatMoney(forecast.summaries.estimated.labor_amount)}</span>
          </SummaryItem>
          <SummaryItem label="Material">
            <span>${formatMoney(forecast.summaries.estimated.material_amount)}</span>
          </SummaryItem>
        </SummaryCard>

        <SummaryCard item title="Budgeted">
          <SummaryItem label="Amount">
            ${formatMoney(forecast.summaries.forecasted.amount)}
          </SummaryItem>
          <SummaryItem label="Labor">
            <span>${formatMoney(forecast.summaries.forecasted.labor_amount)}</span>
          </SummaryItem>
          <SummaryItem label="Material">
            <span>${formatMoney(forecast.summaries.forecasted.material_amount)}</span>
          </SummaryItem>
        </SummaryCard>

        <SummaryCard item title="Contracted">
          <SummaryItem label="Amount">
            ${formatMoney(forecast.summaries.contracted.amount)}
          </SummaryItem>
          <SummaryItem label="Labor">
            <span>${formatMoney(forecast.summaries.contracted.labor_amount)}</span>
          </SummaryItem>
          <SummaryItem label="Material">
            <span>${formatMoney(forecast.summaries.contracted.material_amount)}</span>
          </SummaryItem>
        </SummaryCard>

        <SummaryCard item title="Invoiced">
          <SummaryItem label="Amount">
            ${formatMoney(forecast.summaries.invoiced.amount)}
          </SummaryItem>
        </SummaryCard>
      </Grid>
    </>
  );
};

const ForecastsIndex = () => {
  const { workspaceId, projectId } = useParams();

  const { data: project } = useGetProjectQuery(+projectId);

  const { data: forecast, isLoading: isLoadingForecast } = useGetProjectForecastQuery({
    projectId: +projectId,
  });

  if (!project || isLoadingForecast) {
    return <>Loading...</>;
  }

  return (
    <ContentLayout>
      <Container maxWidth={'xl'}>
        <Box pt={2}>
          <Typography variant={'h4'}>
            <Breadcrumbs
              links={[
                {
                  to: `/workspace/${workspaceId}/projects/${projectId}/budgets`,
                  text: 'Budgets',
                },
                { text: 'Forecasts' },
              ]}
            />
          </Typography>
        </Box>

        <Box pt={2}>
          <ProjectBudgetsSummary project={projectId} />
        </Box>

        <Box pt={6}>
          <Forecast forecast={forecast} project={project} />
        </Box>
      </Container>
    </ContentLayout>
  );
};

export default ForecastsIndex;
