import { ConfirmDeleteDialog } from '@blackhyve/common';
import { theme } from '@blackhyve/common/src/AppTheme';
import { Archive, Delete, MoreVert, Restore } from '@mui/icons-material';
import { List, ListItemIcon, ListItemText, MenuItem, ThemeProvider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Popover } from 'components/Popover';
import { EditContext } from 'components/common/v3/DisplayWithEditAccess';
import { selectCurrentUserWorkspaceId } from 'features/auth';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useArchiveCompanyMutation,
  useDeleteCompanyMutation,
  useRestoreCompanyMutation,
} from '../api/companies.api';

export const MoreOptions = ({ company }) => {
  const { companyId: activeCompanyRoute } = useParams();
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const [deleteCompany, { isLoading: isDeleting }] = useDeleteCompanyMutation();
  const [archiveCompany, { isLoading: isArchiving }] = useArchiveCompanyMutation();
  const [restoreCompany, { isLoading: isRestoring }] = useRestoreCompanyMutation();
  const navigate = useNavigate();
  const canEdit = useContext(EditContext);

  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

  const handleRestore = () => {
    restoreCompany({ companyId: company.id });
  };

  const handleArchive = () => {
    archiveCompany({ companyId: company.id });
  };

  const handleDelete = () => {
    if (activeCompanyRoute == company.id) {
      navigate(`/workspace/${workspaceId}/projects`);
    }
    deleteCompany({ companyId: company.id });
  };

  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        trigger={
          <IconButton edge="end" size={'small'}>
            <MoreVert />
          </IconButton>
        }
      >
        {({ handleClose }) => {
          return (
            <List>
              {company?.deleted_at ? (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleRestore();
                  }}
                >
                  <ListItemIcon>
                    <Restore />
                  </ListItemIcon>
                  <ListItemText primary={'Restore'} />
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleArchive();
                  }}
                >
                  <ListItemIcon>
                    <Archive />
                  </ListItemIcon>
                  <ListItemText primary={'Archive'} />
                </MenuItem>
              )}
              <MenuItem
                disabled={!canEdit}
                onClick={() => {
                  setConfirmDeleteDialogOpen(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <Delete color={'error'} />
                </ListItemIcon>
                <ListItemText primary={'Delete'} sx={{ color: 'error.main' }} />
              </MenuItem>
            </List>
          );
        }}
      </Popover>
      <ThemeProvider theme={theme}>
        {confirmDeleteDialogOpen && (
          <ConfirmDeleteDialog
            handleClose={() => setConfirmDeleteDialogOpen(false)}
            handleDelete={handleDelete}
            item={'company'}
            open={confirmDeleteDialogOpen}
          />
        )}
      </ThemeProvider>
    </>
  );
};
