import { getQuarter } from 'date-fns';
export function zoomConfig(gantt) {
  return {
    levels: [
      {
        name: 'years',
        scale_height: 35,
        min_column_width: 300,
        max_column_width: 300,
        // bar_height: 10,
        // row_height: 20,
        scales: [
          {
            unit: 'year',
            step: 5,
            format: function (date) {
              const formatDate = gantt.date.date_to_str('%Y');
              const endDate = gantt.date.add(date, 5, 'year');
              return formatDate(date) + ' - ' + formatDate(endDate);
            },
          },
          { unit: 'year', step: 1, format: '%Y' },
        ],
      },
      {
        name: 'quarters',
        scale_height: 35,
        min_column_width: 250,
        max_column_width: 250,
        // bar_height: 10,
        // row_height: 20,
        scales: [
          {
            unit: 'year',
            step: 1,
            format: '%Y',
          },
          {
            unit: 'quarter',
            step: 1,
            format: (date) => {
              return `Q${getQuarter(date)}`;
            },
          },
        ],
      },
      {
        name: 'months',
        scale_height: 35,
        min_column_width: 200,
        max_column_width: 200,
        // bar_height: 13,
        // row_height: 25,
        scales: [
          {
            unit: 'year',
            step: 1,
            format: '%Y',
          },
          {
            unit: 'month',
            step: 1,
            format: '%M',
          },
        ],
      },
      {
        name: 'weeks',
        scale_height: 35,
        min_column_width: 120,
        max_column_width: 120,
        // bar_height: 18,
        // row_height: 30,
        scales: [
          {
            unit: 'month',
            step: 1,
            format: '%M',
          },
          {
            unit: 'week',
            format: function (date) {
              const weekStart = gantt.date.week_start(date);
              const weekEnd = gantt.date.add(gantt.date.add(weekStart, 1, 'week'), -1, 'day');
              const formatWeekRange = gantt.date.date_to_str('%M-%d');
              return formatWeekRange(weekStart) + ' - ' + formatWeekRange(weekEnd);
            },
          },
        ],
      },

      {
        name: 'days',
        scale_height: 35,
        min_column_width: 35,
        max_column_width: 35,
        bar_height: 16,
        row_height: 30,
        scales: [
          {
            unit: 'week',
            step: 1,
            format: function (date) {
              const weekStart = gantt.date.week_start(date);
              const weekEnd = gantt.date.add(gantt.date.add(weekStart, 1, 'week'), -1, 'day');
              const formatWeekRange = gantt.date.date_to_str('%M-%d');
              return formatWeekRange(weekStart) + ' - ' + formatWeekRange(weekEnd);
            },
          },
          {
            unit: 'day',
            step: 1,
            format: function (date) {
              const formatDaySpelledOut = gantt.date.date_to_str('%D');
              const formatDayNumber = gantt.date.date_to_str('%d');
              const daySpelledOut = formatDaySpelledOut(date);
              const dayAbbreviation =
                daySpelledOut[0] === 'S' || daySpelledOut[0] === 'T'
                  ? daySpelledOut.substring(0, 2)
                  : daySpelledOut[0];
              return dayAbbreviation + ' ' + formatDayNumber(date);
            },
            css: function (date) {
              if (!gantt.isWorkTime({ date: date, unit: 'day' })) {
                return 'weekend';
              }
              return '';
            },
          },
        ],
      },
    ],
    activeLevelIndex: 4,
    useKey: 'ctrlKey',
    trigger: 'wheel',
    element: function () {
      return gantt.$root.querySelector('.gantt_task');
    },
  };
}
