const { getChildren } = require('./getChildren');
const { hasChild } = require('./hasChild');

function calculateTaskForecastedDates(task, gantt) {
  let latestForecastedEndDate = -Infinity;
  let earliestForecastedStartDate = Infinity;

  if (hasChild(task.id, gantt)) {
    getChildren(task.id, gantt).forEach(function (child) {
      if (+child.forecasted_end_date > +latestForecastedEndDate) {
        latestForecastedEndDate = child.forecasted_end_date;
      }
      if (+child.forecasted_start_date < +earliestForecastedStartDate) {
        earliestForecastedStartDate = child.forecasted_start_date;
      }
    });

    return {
      forecastedStartDate: isFinite(earliestForecastedStartDate)
        ? earliestForecastedStartDate
        : task.stat_date,
      forecastedEndDate: isFinite(latestForecastedEndDate)
        ? latestForecastedEndDate
        : task.scheduled_end_date,
    };
  } else if (task.status === 'todo') {
    return {
      forecastedStartDate: task?.start_date,
      forecastedEndDate: task?.scheduled_end_date,
    };
  } else {
    return {
      forecastedStartDate: task?.forecasted_start_date || task?.start_date,
      forecastedEndDate: task?.forecasted_end_date || task?.scheduled_end_date,
    };
  }
}

module.exports = { calculateTaskForecastedDates };
