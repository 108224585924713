import SqftIconComponent from '@blackhyve/common/src/icons/SqftIcon';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { EditContext } from 'components/common/v3/DisplayWithEditAccess';
import { PolygonViewer } from 'features/polygons/components/PolygonViewer';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAreasQuery } from '../store/area.api';
import {
  useDeleteZoneMutation,
  useDuplicateZoneMutation,
  useUpdateZoneMutation,
} from '../store/zone.api';
import { getCroppedImg } from '../utils/cropImageHelper';
import { CROP_AREA_ASPECT } from '../utils/locationStyle';
import LbsMoreOptions from './LbsMoreOptions';
import { MapImage } from './MapImage';

/**
 * MapCardComponent
 * @param {Object} zone
 * @returns
 */
const ZoneCard = ({ zone, locationId, projectId }) => {
  const canEdit = useContext(EditContext);
  const navigate = useNavigate();

  const {
    data: zoneAreas = [],
    isLoading: isLoadingAreas,
    isFetching: isFetchingAreas,
  } = useGetAreasQuery({ zoneId: zone.id }, { skip: zone.areas });

  const areas = zone?.areas || zoneAreas;

  const [deleteZone, { isLoading: isDeleting }] = useDeleteZoneMutation();
  const [duplicateZone, { isLoading: isDuplicating }] = useDuplicateZoneMutation();
  const [updateZone, { isLoading: isUpdating }] = useUpdateZoneMutation();

  const handleUpdate = async (updatedZone) => {
    let croppedImage = undefined;
    const mapViewData = JSON.stringify(updatedZone.map_view_data);
    const zoneOriginalMap = updatedZone.original_map || zone.original_map;
    console.log(zoneOriginalMap);
    if (mapViewData && zoneOriginalMap && zone.map_view_data !== mapViewData) {
      croppedImage = await getCroppedImg(
        zoneOriginalMap?.original_url || URL.createObjectURL(zoneOriginalMap),
        updatedZone.map_view_data.croppedAreaPixels,
        updatedZone.map_view_data.rotate,
        zoneOriginalMap?.file_name || zoneOriginalMap?.name || `${zone.id}-map.png`
      );
    }
    updateZone({
      zoneId: zone.id,
      locationId,
      projectId,
      zone: {
        ...updatedZone,
        map_view_data: mapViewData,
        map: croppedImage?.croppedImageFile,
      },
    });
  };

  const handleDelete = () => {
    deleteZone({ locationId, projectId, zoneId: zone?.id });
  };

  const handleDuplicate = () => {
    duplicateZone({ zoneId: zone.id, locationId, projectId });
  };

  return (
    <Card elevation={4}>
      <CardActionArea onClick={() => navigate(`../${zone.location_id}/zones/${zone.id}`)}>
        <CardMedia>
          <PolygonViewer
            background={<MapImage alt={`${zone.name} map`} src={zone?.map?.original_url} />}
            PolygonProps={{ scale: 1 }}
            polygons={areas.length > 0 ? areas : [zone]}
          />
        </CardMedia>
      </CardActionArea>
      <CardActions sx={{ borderTop: '1px solid lightGray' }}>
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid container item xs alignItems={'center'}>
            <Typography variant="subtitle1">{zone?.name}</Typography>
          </Grid>
          <Grid container item xs justifyContent={'flex-end'}>
            {!!zone?.sqft && (
              <Box alignItems={'center'} display={'flex'} sx={{ mr: 1 }}>
                <SqftIconComponent style={{ marginRight: '2px' }} />
                <Typography variant="subtitle1">{zone?.sqft}</Typography>
              </Box>
            )}
            {canEdit && (
              <LbsMoreOptions
                entityObj={zone}
                handleDelete={handleDelete}
                handleDuplicate={handleDuplicate}
                handleSave={handleUpdate}
                isLoading={isDeleting || isDuplicating || isUpdating}
                type="Zone"
              />
            )}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export const ZoneMapCardSkeleton = () => {
  return (
    <Card elevation={4}>
      <CardActionArea>
        <CardMedia>
          <Skeleton
            height={'auto'}
            sx={{ aspectRatio: CROP_AREA_ASPECT }}
            variant="rectangular"
            width="100%"
          />
        </CardMedia>
      </CardActionArea>
      <CardActions sx={{ borderTop: '1px solid lightGray' }}>
        <Grid container alignItems={'center'}>
          <Grid item xs={11}>
            <Skeleton variant="text" width="80%" />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default ZoneCard;
