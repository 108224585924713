export const STATUS_COLOR = {
  active: '#448aff',
  todo: '#9E9C9C',
  paused: '#fcc034',
  complete: '#1eb980',
};

export const CONSTRAINT_CONDITIONS = [
  { name: 'Finish-to-Start', value: 'FS' },
  { name: 'Finish-to-Finish', value: 'FF' },
  { name: 'Start-to-Start', value: 'SS' },
  { name: 'Start-to-Finish', value: 'SF' },
];