import isEmpty from 'lodash/isEmpty';
const { api, providesList } = require('api');

const generateQueryString = (queryParam) => {
  return !isEmpty(queryParam)
    ? Object.entries(queryParam)
      .filter(([_, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')
    : '';
};

const generateInvalidationTags = (result, error, entityId, entityTag, stepId = null) => {
  if (!result) return [];
  const tags = [{ type: 'Step', id: stepId || 'LIST' }];
  if (entityTag) {
    tags.push({ type: entityTag, id: entityId ? entityId : 'LIST' });
  }
  return tags;
};

const stepAPI = api.enhanceEndpoints({ addTagTypes: ['Step'] }).injectEndpoints({
  endpoints: (build) => ({
    createStep: build.mutation({
      query: ({ entityType, entityId, ...body }) => ({
        url: `/${entityType}/${entityId}/steps`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { entityId, entityTag }) =>
        generateInvalidationTags(result, error, entityId, entityTag),
    }),
    updateStep: build.mutation({
      query: ({ entityType, entityId, id, ...body }) => ({
        url: `/${entityType}/${entityId}/steps/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, { id, entityId, entityTag }) =>
        generateInvalidationTags(result, error, entityId, entityTag, id),
    }),
    getSteps: build.query({
      query: ({ entityType, entityId, ...queryParam }) => {
        const queryString = generateQueryString(queryParam);
        return `/${entityType}/${entityId}/steps?${queryString}`;
      },
      providesTags: (result) => (result ? providesList(result, 'Step') : []),
      transformResponse: (response) => response.data,
    }),
    deleteStep: build.mutation({
      query: ({ entityType, entityId, id }) => ({
        url: `/${entityType}/${entityId}/steps/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id, entityId, entityTag }) =>
        generateInvalidationTags(result, error, entityId, entityTag, id),
    }),
    reorderSteps: build.mutation({
      query: ({ entityId, entityType, stepIds }) => ({
        url: `/${entityType}/${entityId}/steps/reorder`,
        method: 'POST',
        body: { step_ids: stepIds }
      }),
      invalidatesTags: (result, error, { id, entityId, entityTag }) =>
        generateInvalidationTags(result, error, entityId, entityTag, id),
    })
  }),
});

export const {
  useGetStepsQuery,
  useCreateStepMutation,
  useUpdateStepMutation,
  useDeleteStepMutation,
  useReorderStepsMutation
} = stepAPI;
