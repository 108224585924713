import { useState } from "react";
import Box from '@mui/material/Box';
import format from 'date-fns/format';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Alert from '@mui/material/Alert';
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from "@mui/material/CircularProgress";
import { MapImage } from "features/locations/components/MapImage";
import { useGetAreasQuery } from "features/locations/store/area.api";
import { useGetPhaseQuery, useGetPhasesQuery } from "features/locations/store/phase.api";
import ProductionBoardCreateLBSDialog from "./ProductionBoardCreateLBSDialog";
import { useCreatePhaseZoneMutation, useCreateZoneMutation, useDeleteZoneMutation, useGetPhaseZonesQuery, useUpdateZoneMutation } from "features/locations/store/zone.api";
import { ProductionBoardRow, ProductionBoardSkeletonList, ProductionBoardRowChildren } from '../components/ProductionBoardRow';
import { getCroppedImg } from "features/locations/utils/cropImageHelper";


const ProductionBoardPhase = () => {

  const workspaceId = Number(useParams().workspaceId) || undefined;
  const projectId = Number(useParams().projectId) || undefined;
  const locationId = Number(useParams().locationId) || undefined;
  const phaseId = Number(useParams().phaseId) || undefined;
  const [openCreateZoneDialog, setOpenCreateZoneDialog] = useState(false);
  const [openEditZoneDialog, setOpenEditZoneDialog] = useState({ open: false });

  const { phase, isLoadingPhase } = useGetPhasesQuery({ locationId }, {
    selectFromResult: ({ data: phases, isLoading }) => ({
      phase: phases?.find((phase) => phase.id === phaseId),
      isLoadingPhase: isLoading
    }),
  });

  const { data: zones = [], isLoading: isLoadingZones, isFetching: isFetchingZones } = useGetPhaseZonesQuery({ phaseId });

  const [deleteZone] = useDeleteZoneMutation();
  const [createPhaseZone, { isLoading: isCreatingZone }] = useCreatePhaseZoneMutation();
  const [updateZone, { isLoading: isUpdatingZone }] = useUpdateZoneMutation();


  const handleSaveAndAddAnother = (zone, e) => handleCreateZone(zone, e, true);
  const handleCreateZone = async (data, e, preventClose) => {
    let croppedImage = undefined;
    if (data.original_map) {
      croppedImage = await getCroppedImg(
        URL.createObjectURL(data.original_map),
        data.map_view_data.croppedAreaPixels,
        data.map_view_data.rotate,
        data.original_map.name
      );
    }
    createPhaseZone({
      phaseId,
      zone: {
        ...data,
        map_view_data: JSON.stringify(data.map_view_data),
        map: croppedImage?.croppedImageFile
      }

    }).then((response) => {
      if (!preventClose) setOpenCreateZoneDialog(false);
    }).catch((e) => {
      console.error(e);
    });

  };
  const handleDelete = (id) => deleteZone({ locationId: locationId, phaseId, zoneId: id })

  const handleUpdateZone = (data) => {
    updateZone({ zone: data, zoneId: data.id }).then(() => setOpenEditZoneDialog({ open: false }))
  }

  const handleOpenCreateZoneDialog = () => setOpenCreateZoneDialog(true)
  const handleCloseCreateZoneDialog = () => setOpenCreateZoneDialog(false);

  const handleOpenEditZoneDialog = (entityObj) => setOpenEditZoneDialog({ entityObj, open: true });
  const handleCloseEditZoneDialog = () => setOpenEditZoneDialog({ open: false });


  return isLoadingPhase ? <CircularProgress className="loading" /> :
    <Container maxWidth={'xl'}>
      <Grid container gap={1} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }} align="center">{phase?.name}</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button variant="contained" onClick={handleOpenCreateZoneDialog}>+ New Zone</Button>
        </Grid>
        <Grid item xs={12} gap={1} container sx={{ flexWrap: 'nowrap', overflow: 'auto' }}>
          {zones?.map((zone) => {
            return <Stack sx={{ border: '1px solid' }}>
              <MapImage
                alt={`${zone?.name}`}
                backgroundColor={'#fff'}
                maxWidth={'50vh'}
                src={zone?.map?.original_url}
                width={'70vw'}
              />
              <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>{zone?.name}</Typography>
            </Stack>
          })}
        </Grid>
        <Grid container item xs={12} gap={3}>
          {(!isLoadingZones && isFetchingZones && (
            <Box sx={{ width: '100%', }}>
              <LinearProgress />
            </Box>
          ))}
          {isLoadingZones ? <ProductionBoardSkeletonList /> :
            zones?.length ? zones?.map((zone) => {
              const pageLink = `/workspace/${workspaceId}/projects/${projectId}/production-board/locations/${locationId}/phases/${phaseId}/zones/${zone.id}`
              return <ProductionBoardRow
                key={zone?.id}
                entityObj={zone}
                pageLink={pageLink}
                onDelete={handleDelete}
                onEdit={handleOpenEditZoneDialog}
              >
                <ProductionBoardZoneAreas zoneId={zone.id} parentLink={pageLink} />
              </ProductionBoardRow>
            }) : <Alert sx={{ textAlign: 'center', m: 'auto' }} severity="warning">No zones have been added.</Alert>}
        </Grid>
        {openCreateZoneDialog &&
          <ProductionBoardCreateLBSDialog
            open={openCreateZoneDialog}
            handleClose={handleCloseCreateZoneDialog}
            type="Zone"
            handleSave={handleCreateZone}
            handleSaveAndAddAnother={handleSaveAndAddAnother}
            isSaving={isCreatingZone}
            isMapUploadEnabled={true}
          />}
        {openEditZoneDialog?.open &&
          <ProductionBoardCreateLBSDialog
            type="Zone"
            isSaving={isUpdatingZone}
            isMapUploadEnabled={true}
            handleSave={handleUpdateZone}
            open={openEditZoneDialog?.open}
            entityObj={openEditZoneDialog?.entityObj}
            handleClose={handleCloseEditZoneDialog}
          />}
      </Grid>
    </Container>
}

const ProductionBoardZoneAreas = ({ parentLink, zoneId }) => {

  const { data: areas = [], isLoading } = useGetAreasQuery({ zoneId });

  return <Grid container item xs={12} gap={1}>
    {isLoading ? <ProductionBoardSkeletonList isChildrenList={true} /> :
      areas?.map((area) => {
        const pageLink = `${parentLink}/areas/${area.id}`
        return <ProductionBoardRowChildren key={area.id} {...area} pageLink={pageLink} />
      })}
  </Grid>

}

export default ProductionBoardPhase;