export function maintainDisplayOrder(gantt) {
  if (gantt.config.order_branch) {
    gantt.attachEvent('onAfterTaskMove', function (id, parentId, tindex) {
      const task = gantt.getTask(id);
      const originalDisplayOrder = task.$display_order;
      const newDisplayOrder = (gantt.getTask(parentId).$display_order || 0) + tindex + 1;
      // console.log(originalDisplayOrder, newDisplayOrder);
      updateDisplayOrderBetween(gantt, originalDisplayOrder, newDisplayOrder);

      task.$display_order = newDisplayOrder;
    });

    gantt.attachEvent('onAfterTaskDelete', function (id, item) {
      const deletedDisplayOrder = item.$display_order;

      updateDisplayOrderBetween(gantt, deletedDisplayOrder, null);
    });

    gantt.attachEvent('onAfterTaskAdd', function (id, item) {
      if (item.$virtual) {
        return;
      }
      const addedDisplayOrder = item.$display_order;
      updateDisplayOrderBetween(gantt, addedDisplayOrder, null);
    });

    // Need to figure out to maintain display order after parse
  }
}

function updateDisplayOrderBetween(gantt, startOrder, endOrder) {
  gantt.eachTask((task) => {
    if (endOrder === null) {
      if (task.$display_order > startOrder) {
        task.$display_order--;
        // console.log('update ', task.text, ' : ', task.$display_order);
      }
    } else if (startOrder < endOrder) {
      if (task.$display_order > startOrder && task.$display_order <= endOrder) {
        task.$display_order--;
        // console.log('update', task);
      }
    } else if (startOrder > endOrder) {
      if (task.$display_order >= endOrder && task.$display_order < startOrder) {
        task.$display_order++;
        console.log('update', task);
      }
    }
  });
}
