import { api, providesList } from 'api';

const phaseAPI = api.enhanceEndpoints({ addTagTypes: ['Phase', 'Location-Phases'] }).injectEndpoints({
  endpoints: (build) => ({
    createPhase: build.mutation({
      query: ({ locationId, ...body }) => {
        return {
          url: `locations/${locationId}/phases`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, params) => {
        const locationId = params.locationId ? params.locationId : params.get('locationId');
        return result
          ? [
            { type: 'Phase', id: 'LIST' },
            { type: 'Location-Phases', id: locationId },
          ]
          : [];
      },
    }),
    updatePhase: build.mutation({
      query: (phase) => {
        return {
          url: `phases/${phase.id}`,
          method: 'PUT',
          body: phase,
        };
      },
      invalidatesTags: (result, error, args) => {
        return result
          ? [
            { type: 'Phase', id: args.id },
            { type: 'Location', id: args.locationId },
          ]
          : [];
      },
    }),
    getPhases: build.query({
      query: ({ locationId }) => `/locations/${locationId}/phases`,
      providesTags: (result, error, args) =>
        result
          ? [{ type: `Location-Phases`, id: args.locationId }, ...providesList(result, `Phase`)]
          : [],
      transformResponse: (response) => response.data,
    }),
    getPhase: build.query({
      query: ({ locationId, phaseId }) => `/locations/${locationId}/phases/${phaseId}`,
      providesTags: (result, error, args) =>
        result
          ? [
            { type: `Location-Phases`, id: args.locationId },
            { type: 'Phase', id: args.phaseId },
          ]
          : [],
      transformResponse: (response) => response.data,
    }),
    deletePhase: build.mutation({
      query: ({ locationId, phaseId }) => ({
        url: `/locations/${locationId}/phases/${phaseId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, params) =>
        result
          ? [
            { type: 'Phase', id: params.phaseId },
            { type: 'Location-Phases', id: params.locationId },
            { type: 'Location', id: params.locationId },
          ]
          : []
    }),
    duplicatePhase: build.mutation({
      query: ({ phaseId }) => ({
        url: `/phases/${phaseId}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, params) =>
        result
          ? [
            { type: 'Phase', id: 'LIST' },
            { type: 'Location-Phases', id: params.locationId },
            { type: 'Location', id: params.locationId },
          ]
          : [],
    }),

  }),
});

export const {
  useCreatePhaseMutation,
  useGetPhasesQuery,
  useGetPhaseQuery,
  useDeletePhaseMutation,
  useUpdatePhaseMutation,
  useDuplicatePhaseMutation,
} = phaseAPI;
