import * as React from 'react';

const SvgBlackhyveIcon = (props) => (
  <svg viewBox="0 0 38 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.452148 13.3129L11.2914 7.04826V0L15.5812 2.47925V28.1521L11.2914 25.6784V12.0003L4.74192 15.7865V28.6764L15.5812 34.9419V39.8949L0.452148 31.1501V13.3129ZM26.2084 0V7.04733L37.0477 13.3129V31.1501L21.9187 39.8949V34.9419L32.7579 28.6764V15.7865L26.2084 12.0003V25.6784L21.9187 28.1521V2.47925L26.2084 0Z"
      fill={props.color ? props.color : '#FFF'}
    />
  </svg>
);

export default SvgBlackhyveIcon;
