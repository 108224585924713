import isEmpty from 'lodash/isEmpty';
import { useState, useEffect, useCallback } from 'react';
import { MoreOptionDialog } from '@blackhyve/common';
import ganttStore from '../../ganttConfig/ganttStore';
import { useDispatch, useSelector } from 'react-redux';
import { MenuButton } from 'assets/style-components/button';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import SaveIconComponent from '@blackhyve/common/src/icons/Save';
import EditIconComponent from '@blackhyve/common/src/icons/Edit';
import CopyIconComponent from '@blackhyve/common/src/icons/Copy';
import ResetIconComponent from '@blackhyve/common/src/icons/Reset';
import CheckIconComponent from '@blackhyve/common/src/icons/Check';
import DeleteIconComponent from '@blackhyve/common/src/icons/Delete';
import RenameCustomView from '../../../../common/popovers/RenameCustomView';
import ArrowLeftIconComponent from '@blackhyve/common/src/icons/ArrowLeft';
import ConfirmDeleteDialog from '../../../../common/popovers/ConfirmDeleteDialog';
import {
  fetchCustomViews,
  deleteCustomView,
  duplicateCustomView,
  updateCustomView,
  createCustomView,
  selectCustomViewEntities,
  resetToDefaultView,
  setCustomView,
  updateMenuState,
} from '../../../../../slices/customViewSlice';

/**
 * Custom View Menu
 * @param {String} ganttId
 * @param {String} viewType
 * @returns
 */
const CustomViewMenu = ({ ganttId, viewType }) => {
  const dispatch = useDispatch();
  const [menuState, setMenuState] = useState(null);
  const [popupState, setPopupState] = useState({});
  const gantt = ganttStore.getGantt(ganttId);
  const [customViewOptionList, setCustomViewOptionList] = useState([]);
  const customViews = useSelector((state) => selectCustomViewEntities(state));
  const selectedCustomViewId = useSelector((state) => state.customViews?.selected);
  const selectedCustomView = customViews[selectedCustomViewId];

  const handleCloseMenu = () => {
    setMenuState(false);
  };

  const handleClosePopup = () => {
    setPopupState({});
  };

  const handleResetDefault = () => {
    dispatch(resetToDefaultView());
  };

  const handleSelectCustomView = (requestParameter) => {
    const customView = customViews[requestParameter.id];
    if (customView?.parameters?.closedTask?.length) {
      const closedTasks = customView?.parameters?.closedTask;

      gantt.eachTask(function (task) {
        if (gantt.hasChild(task.id)) {
          if (closedTasks?.includes(task.id)) {
            //task.$open = false;
            gantt.close(task.id);
          } else if (!task.$open) {
            //task.$open = true;
            gantt.open(task.id);
          }
          gantt.refreshTask(task.id);
        }
      });
    }

    dispatch(setCustomView(requestParameter.id));
    handleCloseMenu();
  };

  const handleRenameDialog = (requestParameter) => {
    setPopupState({
      popupType: 'rename-dialog',
      id: requestParameter.id,
      name: requestParameter.name,
    });
  };

  const handleDeleteConfirmation = (requestParameter) => {
    setPopupState({ popupType: 'delete-confirmation', id: requestParameter.id });
  };

  const handleRename = ({ id, value }) => {
    dispatch(updateCustomView({ id, name: value }));
    handleClosePopup();
  };

  const handleDeleteCustomView = (id) => {
    dispatch(deleteCustomView(id)).then((response) => {
      handleResetDefault();
    });
  };

  const handleDuplicateCustomView = ({ id }) => {
    dispatch(duplicateCustomView(id));
  };

  const handleSaveCustomView = () => {
    // const parameters = {};
    // Object.keys(menus).forEach((menuKey) => (parameters[menuKey] = menus[menuKey].currentValue));
    const closedTask = [];
    gantt.eachTask(function (task) {
      if (gantt.hasChild(task.id)) {
        if (!task.$open && task.type !== 'placeholder') {
          closedTask.push(task.id);
        }
      }
    });
    dispatch(
      createCustomView({
        name: `Custom view ${isEmpty(customViews) ? 1 : Object.keys(customViews).length}`,
        view: 'schedule',
        closedTask,
      })
    ).then((response) => {
      dispatch(setCustomView(response.payload.id));
    });
  };

  const handleTaskClosed = useCallback(() => {
    if (selectedCustomView?.name) {
      dispatch(updateMenuState());
    }
  }, [selectedCustomView, dispatch]);

  const handleTaskOpened = useCallback(() => {
    if (selectedCustomView?.name) {
      dispatch(updateMenuState());
    }
  }, [selectedCustomView, dispatch]);

  const anchorPosition = {
    vertical: 'bottom',
    horizontal: 'left',
  };

  useEffect(() => {
    let customViewsList = [];
    if (!isEmpty(customViews)) {
      customViewsList = Object.values(customViews)?.map((customView) => {
        return {
          name: customView.name,
          id: customView.id,
          icon: <ArrowLeftIconComponent height={14} viewBox={'0 0 16 24'} width={16} />,
          isCustomView: true,
          subItems: [
            {
              name: 'Select',
              icon: <CheckIconComponent height={16} viewBox={'0 0 12 10'} width={16} />,
              id: customView.id,
              action: handleSelectCustomView,
            },
            {
              name: 'Rename',
              id: customView.id,
              icon: <EditIconComponent height={14} width={16} />,
              action: handleRenameDialog,
            },
            {
              name: 'Duplicate',
              icon: <CopyIconComponent height={16} width={16} />,
              id: customView.id,
              action: handleDuplicateCustomView,
            },
            {
              name: 'Delete',
              icon: <DeleteIconComponent color="red" height={16} width={16} />,
              style: { color: 'red' },
              id: customView.id,
              action: handleDeleteConfirmation,
            },
          ],
        };
      });
    }

    customViewsList.push(
      {
        name: 'Reset view to default',
        icon: <ResetIconComponent height={16} width={16} />,
        isCustomView: true,
        action: handleResetDefault,
      },
      {
        name: 'Save custom view',
        icon: <SaveIconComponent height={16} width={16} />,
        isCustomView: true,
        action: handleSaveCustomView,
      }
    );
    setCustomViewOptionList(customViewsList);
  }, [customViews]);

  useEffect(() => {
    dispatch(fetchCustomViews(viewType));
  }, [viewType, dispatch]);

  useEffect(() => {
    let onTaskClosed = undefined;
    if (gantt) {
      onTaskClosed = gantt.attachEvent('onTaskClosed', handleTaskClosed);
    }
    return () => {
      if (gantt && onTaskClosed) {
        gantt.detachEvent(onTaskClosed);
      }
    };
  }, [gantt, handleTaskClosed]);

  useEffect(() => {
    let onTaskOpened = undefined;
    if (gantt) {
      onTaskOpened = gantt.attachEvent('onTaskOpened', handleTaskOpened);
    }
    return () => {
      if (gantt && onTaskOpened) {
        gantt.detachEvent(onTaskOpened);
      }
    };
  }, [gantt, handleTaskOpened]);

  return (
    <>
      <MenuButton
        isHighlighted={selectedCustomView?.name}
        onClick={(event) => setMenuState(event.currentTarget)}
      >
        View: {selectedCustomView?.name ? selectedCustomView?.name : 'Custom'}
        {Boolean(menuState) ? <ArrowDropUp /> : <ArrowDropDown />}
      </MenuButton>
      {menuState && (
        <MoreOptionDialog
          anchorEl={menuState}
          anchorPosition={anchorPosition}
          handleClose={handleCloseMenu}
          open={true}
          optionMenu={customViewOptionList}
        />
      )}
      {popupState?.popupType === 'delete-confirmation' && (
        <ConfirmDeleteDialog
          handleClose={handleClosePopup}
          handleDelete={handleDeleteCustomView}
          id={popupState.id}
          item={'custom view'}
          open={true}
        />
      )}
      {popupState?.popupType === 'rename-dialog' && (
        <RenameCustomView
          defaultValue={popupState.name}
          displayLabel="Rename Custom View"
          handleClose={handleClosePopup}
          handleUpdate={handleRename}
          id={popupState.id}
          open={true}
        />
      )}
    </>
  );
};

export default CustomViewMenu;
