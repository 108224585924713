import { Check } from '@mui/icons-material';
import { Box, MenuItem, Select, styled } from '@mui/material';
import { TASK_COLORS } from 'helpers/constants';
import { forwardRef } from 'react';

const ColorMenuItem = styled(MenuItem)(({ theme, color, selected }) => ({
  position: 'relative',
  boxSizing: 'border-box',
  display: 'inline-block',
  width: '35px',
  height: '35px',
  marginTop: '5px',
  marginRight: '5px',
  borderRadius: '5px',
  backgroundColor: `${color}`,
  border: '2px solid transparent',

  '&:hover': {
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: `inset 0 0 0 2px #fff`,
    backgroundColor: `${color}`,
  },

  '&>svg': {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    margin: 'auto',
  },
  '&.Mui-selected': {
    backgroundColor: color,
  },
}));

const StyledSelect = styled(Select)({
  '& ul': {
    maxWidth: '400px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
});

export const ColorSelect = forwardRef(({ onChange, value, ...inputProps }, ref) => {
  return (
    <StyledSelect
      defaultValue={''}
      ref={ref}
      value={value}
      variant={'outlined'}
      MenuProps={{
        disablePortal: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      renderValue={(value) => {
        return <Box sx={{ backgroundColor: value, aspectRatio: 1, height: '1em' }} />;
      }}
      onChange={onChange}
      {...inputProps}
      inputProps={{ sx: { display: 'flex', alignItems: 'center' } }}
    >
      {TASK_COLORS.map((color) => {
        return (
          <ColorMenuItem
            color={color.value}
            key={color.value}
            selected={color.value === value}
            value={color.value}
          >
            {color.value === value ? <Check htmlColor={'#fff'} /> : null}
          </ColorMenuItem>
        );
      })}
    </StyledSelect>
  );
});
