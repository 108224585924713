import times from 'lodash/times'
import { useState } from 'react';
import format from 'date-fns/format';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Collapse from '@mui/material/Collapse';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { parseDate } from '@blackhyve/utilities/dates';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TradeIcon from '@blackhyve/common/src/icons/Trade';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { MenuOptions } from './ProductionBoardMenuOptions';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TaskIconComponent from '@blackhyve/common/src/icons/Task';
import { useGetTradesQuery } from 'features/trades/api/trades.api';
import { CompanyIcon } from '@blackhyve/common/src/icons/CompanyIcon';
import PersonIconComponent from '@blackhyve/common/src/icons/Person';
import { useDeleteTasksMutation } from 'features/tasks/store/task.api';
import ProductionBoardCreateTaskDialog from './ProductionBoardCreateTaskDialog';


/**
 * ProductionBoardRow
 * @param {Object} entityObj 
 * @param {String} entityObj.id 
 * @param {String} entityObj.name 
 * @param {String} entityObj.scheduled_start 
 * @param {String} entityObj.scheduled_end 
 * @param {String} pageLink 
 * @param {Object} children 
 * @param {Object} onDelete 
 * @param {Object} onEdit 
 * @returns 
 */
export const ProductionBoardRow = ({ entityObj, pageLink, children, onDelete, onEdit }) => {

  const [open, setOpen] = useState(false);

  const startDate = entityObj?.scheduled_start ? format(parseDate(entityObj?.scheduled_start), "MM-dd-yyyy") : '';
  const endDate = entityObj?.scheduled_end ? format(parseDate(entityObj?.scheduled_end), "MM-dd-yyyy") : '';

  return <Grid container item xs={12} p={1} component={Paper} elevation={3}>
    <Grid item>
      <IconButton onClick={() => setOpen(!open)}>
        {open ? <ExpandMore /> : <ChevronRightIcon />}
      </IconButton>
    </Grid>
    <Grid item xs container alignItems={'center'}>
      <Grid item xs={11}>
        <Typography color={'primary'} sx={{ textDecoration: 'none', fontSize: '18px', fontWeight: 'bold' }} component={Link} to={pageLink}>{entityObj?.name}</Typography>
      </Grid>
      <Grid item container justifyContent={'flex-end'} xs={1}>
        <MenuOptions entityObj={entityObj} onDelete={onDelete} onEdit={onEdit} />
      </Grid>
      <Grid container alignItems={'center'} item xs={12} justifyContent={'space-between'} spacing={1}>
        {startDate && endDate ? <Grid xs={12} md={6} item container gap={1}>
          <IconButton sx={{ p: 0 }} size='small'>
            <DateRangeIcon fontSize='inherit' />
          </IconButton>
          <Typography variant='subtitle2' color={'grey.600'}>{startDate} - {endDate}</Typography>
        </Grid> : null}
      </Grid>
      <Collapse in={open} mountOnEnter unmountOnExit timeout={'auto'} sx={{ width: '100%' }}>
        <Grid container item xs={12} mt={1.5} ml={1}>
          {children}
        </Grid>
      </Collapse>
    </Grid>
  </Grid>
}

/**
 * Production board children row
 * @param {String} name 
 * @param {String} scheduled_start 
 * @param {String} scheduled_end 
 * @param {String} name 
 * @returns 
 */
export const ProductionBoardRowChildren = ({ name, scheduled_start, scheduled_end, pageLink }) => {

  const startDate = scheduled_start ? format(parseDate(scheduled_start), "MM-dd-yyyy") : '';
  const endDate = scheduled_end ? format(parseDate(scheduled_end), "MM-dd-yyyy") : '';

  return <Grid container item xs={12}>
    <Grid item xs={12}>
      <Typography component={Link} to={pageLink} color={'primary'} sx={{ textDecoration: 'none' }} variant='title'>{name}</Typography>
    </Grid>
    {startDate && endDate && <Grid container alignItems={'center'} item xs={12} gap={1}>
      <IconButton sx={{ p: 0 }} size='small'>
        <DateRangeIcon fontSize='inherit' />
      </IconButton>
      <Typography variant='subtitle2' color={'grey.600'}>{startDate} - {endDate}</Typography>
    </Grid>}
  </Grid>
}

/**
 * Production board task row
 * @param {Object} task 
 * @param {Number} task.id 
 * @param {String} task.name 
 * @param {String} task.scheduled_start 
 * @param {String} task.scheduled_end 
 * @param {String} areaId
 * @param {String} projectId
 * @param {Object} rowProps
 * @param {Object} onDelete 
 * @param {Object} onEdit   
 * @returns 
 */
export const ProductionBoardTaskRow = ({ pageLink, task, rowProps, areaId, projectId }) => {

  const { data: { entities: tradeEntities = {} } = {}, isLoading } = useGetTradesQuery();
  const startDate = task?.scheduled_start ? format(parseDate(task?.scheduled_start), "MM-dd-yyyy") : '';
  const endDate = task?.scheduled_end ? format(parseDate(task?.scheduled_end), "MM-dd-yyyy") : '';
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [deleteTasks] = useDeleteTasksMutation();

  const handleDelete = (id) => deleteTasks({ tasks: [id] });

  const handleEditDialog = () => setOpenEditDialog(true);
  const handleCloseDialog = () => setOpenEditDialog(false);


  return <Grid container item xs={12} sx={{ p: 1 }} {...rowProps}>
    <Grid item>
      <IconButton>
        <TaskIconComponent className="task-image" />
      </IconButton>
    </Grid>
    <Grid item xs container alignItems={'center'}>
      <Grid container item xs={12} gap={1} alignItems={'center'}>
        <Typography component={Link} to={pageLink} sx={{ textDecoration: 'none', fontWeight: 'bold' }}>{task?.name}</Typography>
        <Box sx={{ ml: 'auto' }}>
          <MenuOptions entityObj={task} onDelete={handleDelete} onEdit={handleEditDialog} />
        </Box>
      </Grid>
      <Grid item container xs={12} alignItems={'center'}>
        <IconButton size='small'>
          <DateRangeIcon fontSize='inherit' />
        </IconButton>
        <Typography>{startDate} - {endDate}</Typography>
      </Grid>
      <Grid container item xs={12} alignItems={'center'}>
        {task?.companies?.length ?
          <>
            <IconButton size='small'>
              <CompanyIcon fontSize='inherit' color="grey" />
            </IconButton>
            <Typography>{task?.companies?.map((company) => company?.name).join(', ')}</Typography>
          </> : null}
        {task?.crew_size ? <>
          <IconButton size='small' sx={{ paddingRight: 0.5 }}>
            <PersonIconComponent />
          </IconButton>
          <Typography>{task?.crew_size}</Typography>
        </> : null}
      </Grid>
      {task?.trades?.length ? <Grid container item xs={12} alignItems={'center'}>
        <IconButton size='small' sx={{ paddingRight: 0.5 }}>
          <TradeIcon />
        </IconButton>
        {task?.trades?.map((tradeId) => {
          return tradeEntities[tradeId]?.name
        }).join(', ')}
      </Grid> : null}
      {task?.responsible_users?.length ? <Grid container item xs={12} alignItems={'center'} gap={1}>
        {task?.responsible_users?.map((user) =>
          <Chip
            key={user.id}
            avatar={<Avatar alt={user?.name} src={user?.profile_image?.original_url} />}
            label={user?.name}
            variant="outlined"
          />)}
      </Grid> : null}
    </Grid>
    {openEditDialog &&
      <ProductionBoardCreateTaskDialog
        open={openEditDialog}
        task={task}
        areaId={areaId}
        projectId={projectId}
        handleClose={handleCloseDialog}
      />}
  </Grid>
}


export const ProductionBoardSkeletonList = ({ isChildrenList = false }) => {

  return times(5).map((no) => {
    return <Grid key={no} container item xs={12} p={1} component={isChildrenList ? null : Paper} elevation={isChildrenList ? 0 : 3}>
      <Grid item xs={12}>
        <Skeleton height={30} width={'100%'} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton height={30} width={'100%'} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton height={30} width={'100%'} />
      </Grid>
    </Grid>
  })

}

