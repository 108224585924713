import {
  Container,
  Fab,
  Paper,
  Stack,
  TableContainer,
  TablePagination,
  Toolbar,
  Typography,
} from '@mui/material';
import RecipesTable from '../components/RecipesTable';
import RecipeDialog from '../components/RecipeDialog';
import { Add } from '@mui/icons-material';
import SearchBox from 'components/search/SearchBox';
import { useGetRecipesQuery } from '../api/recipe.api';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';

export const Recipes = () => {
  const navigate = useNavigate();
  const { workspaceId } = useParams();

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useDebounceValue('');
  const [orderBy, setOrderBy] = useState('name,asc');

  const {
    data: { data: recipes = [], meta: { total = 0 } = {} } = {},
    isLoading: isLoadingRecipes,
    isFetching: isFetchingRecipes,
  } = useGetRecipesQuery({
    params: { order_by: orderBy, q: debouncedSearchQuery, page: pageNumber, per_page: pageSize },
  });
  return (
    <Container sx={{ p: { xs: 1, md: 3 } }}>
      <Typography variant={'h4'}>Recipes</Typography>
      <TableContainer component={Paper}>
        <Toolbar>
          <Stack
            useFlexGap
            alignItems={'center'}
            direction={'row'}
            justifyContent={'space-between'}
            spacing={1}
            width={1}
          >
            <SearchBox
              bgcolor={'grey.200'}
              borderRadius={1}
              fullWidth={false}
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setDebouncedSearchQuery(e.target.value);
              }}
            />
            <Fab
              color={'secondary'}
              size={'small'}
              sx={{ flexShrink: 0 }}
              variant={'extended'}
              onClick={() => setIsCreateDialogOpen(true)}
            >
              <Add /> Recipe
            </Fab>
            <RecipeDialog
              handleClose={() => setIsCreateDialogOpen(false)}
              open={isCreateDialogOpen}
            />
          </Stack>
        </Toolbar>
        <RecipesTable
          recipes={recipes}
          TableProps={{
            defaultOrder: 'asc',
            handleSort: (property, newSortOrder) => setOrderBy(`${property},${newSortOrder}`),
            isFetching: isFetchingRecipes,
            isLoading: isLoadingRecipes,
            rowProps: (recipe) => ({
              sx: { cursor: 'pointer' },
              role: 'button',
              onClick: () => navigate(`/workspace/${workspaceId}/recipes/${recipe.id}`),
            }),
          }}
        />
        {!isLoadingRecipes && (
          <TablePagination
            component={'div'}
            count={total}
            page={pageNumber - 1}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[1, 25, 50, 100]}
            sx={{ ml: 'auto' }}
            onPageChange={(_, page) => setPageNumber(page + 1)}
            onRowsPerPageChange={(event) => {
              setPageSize(event.target.value);
              setPageNumber(1);
            }}
          />
        )}
      </TableContainer>
    </Container>
  );
};
