import { api, providesList } from 'api';
import { normalize, schema } from 'normalizr';
import isEmpty from 'lodash/isEmpty';

const locationAPI = api
  .enhanceEndpoints({ addTagTypes: ['Location', 'Zone', 'Area'] })
  .injectEndpoints({
    endpoints: (build) => ({
      createLocation: build.mutation({
        query: (body) => ({
          url: `/locations`,
          method: 'POST',
          body,
        }),
        invalidatesTags: (result, error, params) =>
          result ? [{ type: 'Location', id: 'LIST' }] : [],
      }),
      updateLocation: build.mutation({
        query: ({ locationId, location }) => ({
          url: `/locations/${locationId}`,
          method: 'PATCH',
          body: location,
        }),
        invalidatesTags: (result, error, args) =>
          result ? [{ type: 'Location', id: args.id }] : [],
        transformResponse: (response) => response.data,
        async onQueryStarted(
          { sourceIndex, destinationIndex, locationId, projectId, location },
          { dispatch, queryFulfilled }
        ) {
          const updateGetLocations = dispatch(
            api.util.updateQueryData('getLocations', { projectId }, (locations) => {
              const index = locations.findIndex((location) => location.id === locationId);
              if (index !== -1) {
                locations.splice(index, 1, Object.assign(locations[index], location));
              }
            })
          );
          const updateGetLbs = dispatch(
            api.util.updateQueryData('getLbs', { projectId }, (locations) => {
              const index = locations.findIndex((location) => location.id === locationId);
              if (index !== -1) {
                locations.splice(index, 1, Object.assign(locations[index], location));
              }
            })
          );
          queryFulfilled.catch(() => {
            updateGetLocations.undo();
            updateGetLbs.undo();
          });
        },
      }),
      getLocations: build.query({
        query: ({ projectId, orderBy }) => ({
          url: `/locations`,
          params: { project: projectId, order_by: orderBy },
        }),
        providesTags: (result, error, args) => (result ? providesList(result, 'Location') : []),
        transformResponse: (response) => response.data,
      }),
      getLocation: build.query({
        query: ({ locationId }) => `/locations/${locationId}`,
        providesTags: (result, error, args) =>
          result ? [{ type: 'Location', id: args.locationId }] : [],
        transformResponse: (response) => response.data,
      }),
      getLbsWithTasks: build.query({
        query: ({
          projectId,
          locationIds,
          companyIds,
          userIds,
          tradeIds,
          color,
          startDate,
          endDate,
        }) => ({
          url: `tasks_lbs`,
          params: {
            project: projectId,
            'location[]': locationIds,
            'company[]': companyIds,
            'responsible[]': userIds,
            'trade[]': tradeIds,
            date_range: startDate && endDate ? `${startDate}..${endDate}` : undefined,
            // color,
          },
        }),
        transformResponse: (response) => response.data,
        providesTags: (result, error, args) => {
          const locations = [];
          const zones = [];
          const areas = [];
          const tasks = [];
          // need to provide tags for locations zones areas and tasks
          return result
            ? [
                ...providesList(locations, 'Location'),
                ...providesList(zones, 'Zone'),
                ...providesList(areas, 'Area'),
                ...providesList(tasks, 'Task'),
              ]
            : [];
        },
      }),
      getLbs: build.query({
        query: ({ projectId }) => `projects/${projectId}/lbs`,
        providesTags: (result, error, args) => {
          if (result) {
            const locationsList = [];
            const zonesList = [];
            const areasList = [];
            result.forEach((location) => {
              locationsList.push(location);
              location.zones.forEach((zone) => {
                zonesList.push(zone);
                zone.areas.forEach((area) => areasList.push(area));
              });
            });
            return [
              ...providesList(locationsList, 'Location'),
              ...providesList(zonesList, 'Zone'),
              ...providesList(areasList, 'Area'),
            ];
          } else {
            return [];
          }
        },
        transformResponse: (response) => response.data,
      }),
      getNormalizedLbs: build.query({
        query: (body) =>
          `/locations?${body?.projectId ? `project=${body?.projectId}&` : ''}include=zones.areas`,
        providesTags: (result, error, args) => {
          const locationList = !isEmpty(result?.locations)
            ? providesList(Object.values(result?.locations), 'Location')
            : [];
          const zoneList = !isEmpty(result?.zones)
            ? providesList(Object.values(result?.zones), 'Zone')
            : [];
          const areaList = !isEmpty(result?.areas)
            ? providesList(Object.values(result?.areas), 'Area')
            : [];
          return [...locationList, ...zoneList, ...areaList];
        },
        transformResponse: (response) => {
          const areaSchema = new schema.Entity('areas');
          const zoneSchema = new schema.Entity('zones', {
            areas: [areaSchema],
          });
          const locationSchema = new schema.Entity('locations', {
            zones: [zoneSchema],
          });
          const formattedData = new schema.Array(locationSchema);
          const formattedResponse = normalize(
            !isEmpty(response.data) ? response.data : {},
            formattedData
          );
          return { locationIds: formattedResponse.result, ...formattedResponse.entities };
        },
      }),
      duplicateLocation: build.mutation({
        query: ({ locationId }) => ({
          url: `/locations/${locationId}/duplicate`,
          method: 'POST',
        }),
        invalidatesTags: (result, error, params) =>
          result ? [{ type: 'Location', id: 'LIST' }] : [],
      }),
      deleteLocation: build.mutation({
        query: ({ locationId }) => ({
          url: `/locations/${locationId}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, params) => {
          return result ? [{ type: 'Location', id: params.locationId }] : [];
        },
        async onQueryStarted({ locationId, projectId }, { dispatch, queryFulfilled }) {
          const updateGetLocations = dispatch(
            api.util.updateQueryData('getLocations', { projectId }, (locations) => {
              const index = locations.findIndex((location) => location.id === locationId);
              if (index !== -1) {
                locations.splice(index, 1);
              }
            })
          );
          const updateGetLbs = dispatch(
            api.util.updateQueryData('getLbs', { projectId }, (locations) => {
              const index = locations.findIndex((location) => location.id === locationId);
              if (index !== -1) {
                locations.splice(index, 1);
              }
            })
          );
          queryFulfilled.catch(() => {
            updateGetLocations.undo();
            updateGetLbs.undo();
          });
        },
      }),
      reorderLocation: build.mutation({
        query: (body) => ({
          url: 'locations/reorder',
          method: 'POST',
          body,
        }),
        invalidatesTags: (result, error, params) =>
          result ? [{ type: 'Location', id: 'LIST' }] : [],
        async onQueryStarted(
          { sourceIndex, destinationIndex, project_id: projectId },
          { dispatch, queryFulfilled }
        ) {
          const updateGetLocations = dispatch(
            api.util.updateQueryData('getLocations', { projectId }, (locations) => {
              const location = locations.splice(sourceIndex, 1);
              locations.splice(destinationIndex, 0, ...location);
            })
          );
          const updateGetLbs = dispatch(
            api.util.updateQueryData('getLbs', { projectId }, (locations) => {
              const location = locations.splice(sourceIndex, 1);
              locations.splice(destinationIndex, 0, ...location);
            })
          );
          queryFulfilled.catch(() => {
            updateGetLocations.undo();
            updateGetLbs.undo();
          });
        },
      }),
    }),
  });

export const {
  useCreateLocationMutation,
  useGetLocationsQuery,
  useGetLocationQuery,
  useDeleteLocationMutation,
  useDuplicateLocationMutation,
  useUpdateLocationMutation,
  useGetLbsQuery,
  useGetNormalizedLbsQuery,
  useReorderLocationMutation,
  useGetLbsWithTasksQuery,
} = locationAPI;
