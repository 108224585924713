import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Check from '@mui/icons-material/Check';
import { selectAllUsers } from 'features/users';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import { useSearchParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { MenuButton } from 'assets/style-components/button';
import ListSubheader from '@mui/material/ListSubheader';
import InputAdornment from '@mui/material/InputAdornment';
import { selectAllContacts } from 'features/contacts/store/contactsSlice';
import {
  CheckBoxContainer,
  StyledMenuName,
  StyledMenu,
} from 'features/activityFeeds/assets/activityFeedStyle';

export const matchText = (stringToMatch, searchText) =>
  stringToMatch?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;

/**
User filter menu
* @param {Object} userId
* @param {Object} setFilters
* @param {String} label
* @returns 
*/
const UserFilterMenu = ({ userId, setFilters, label }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const users = useSelector((state) => selectAllUsers(state));
  const contacts = useSelector((state) => selectAllContacts(state));

  const allUsers = useMemo(() => {
    return [...users, ...contacts];
  }, [users, contacts]);

  const displayedOptions = useMemo(
    () =>
      allUsers.filter((option) =>
        matchText(`${option.first_name} ${option.last_name}`, searchText)
      ),
    [searchText, allUsers]
  );

  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleClose = () => {
    setOpenMenu(null);
    setSearchText('');
  };

  const handleSelect = (event, option) => {
    setSearchParams((prevParams) => {
      option?.id ? prevParams.set('user', option.id) : prevParams.delete('user');
      return prevParams;
    });
    setFilters((prevState) => {
      return { ...prevState, user: option?.id ? option.id : undefined };
    });
    handleClose();
  };

  return (
    <>
      <MenuButton onClick={handleClick}>
        {label}
        {Boolean(openMenu) ? <ArrowDropUp /> : <ArrowDropDown />}
      </MenuButton>
      <StyledMenu
        anchorEl={openMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        getContentAnchorEl={null}
        open={Boolean(openMenu)}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
      >
        <ListSubheader>
          <TextField
            autoFocus
            fullWidth
            placeholder="Type to search..."
            size="large"
            style={{ zIndex: 2, backgroundColor: 'white', paddingTop: '5px', paddingBottom: '5px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        <MenuItem onClick={(event) => handleSelect(event, undefined)}>
          <CheckBoxContainer>
            {userId === undefined ? <Check color={'action'} style={{ display: 'block' }} /> : null}
          </CheckBoxContainer>
          All
        </MenuItem>
        {displayedOptions.map((user, i) => (
          <MenuItem key={user.id} onClick={(event) => handleSelect(event, user)}>
            <CheckBoxContainer>
              {user.id == userId ? <Check color={'action'} style={{ display: 'block' }} /> : null}
            </CheckBoxContainer>
            <StyledMenuName>
              {user.first_name} {user.last_name}
            </StyledMenuName>
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default UserFilterMenu;
