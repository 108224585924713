import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const CompanyIcon = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path
      d="M15 9C15 6.49219 13.46 4.34625 11.2753 3.44937L10 6V2.5C10 2.36739 9.94732 2.24021 9.85355 2.14645C9.75979 2.05268 9.63261 2 9.5 2H6.5C6.36739 2 6.24021 2.05268 6.14645 2.14645C6.05268 2.24021 6 2.36739 6 2.5V6L4.72469 3.44937C2.54 4.34625 1 6.49219 1 9V11H15V9ZM15.5 12H0.5C0.367392 12 0.240215 12.0527 0.146447 12.1464C0.0526784 12.2402 0 12.3674 0 12.5L0 13.5C0 13.6326 0.0526784 13.7598 0.146447 13.8536C0.240215 13.9473 0.367392 14 0.5 14H15.5C15.6326 14 15.7598 13.9473 15.8536 13.8536C15.9473 13.7598 16 13.6326 16 13.5V12.5C16 12.3674 15.9473 12.2402 15.8536 12.1464C15.7598 12.0527 15.6326 12 15.5 12Z"
      fill={'currentColor'}
    />
  </SvgIcon>
);

export default CompanyIcon;
