import { Box, Typography } from '@mui/material';
import { ProductionRating as ProductionRatingIcon } from 'components/icons/ProductionRating';

export const ProductionRating = ({ value, ...props }) => {
  return (
    <Box
      alignItems={'center'}
      bgcolor={parseFloat(value) >= 1 ? 'success.light' : 'error.light'}
      borderRadius={1}
      color={'white'}
      component={'span'}
      display={'inline-flex'}
      gap={0.5}
      p={0.5}
      {...props}
    >
      <ProductionRatingIcon fontSize={'small'} />
      <Typography variant='subtitle2'>{parseFloat(value * 100).toFixed(0)}%</Typography>
    </Box>
  );
};
