import { useState, useMemo, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import { useSearchParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { MenuButton } from 'assets/style-components/button';
import ListSubheader from '@mui/material/ListSubheader';
import InputAdornment from '@mui/material/InputAdornment';
import { matchText } from './UserFilterMenu';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { StyledMenuName, StyledMenu } from 'features/activityFeeds/assets/activityFeedStyle';
import { useGetProjectsQuery } from 'features/projects/store/project.api';
import { useSelector } from 'react-redux';
import { selectCurrentUserWorkspaceId } from 'features/auth';

const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
const checkedIcon = <CheckBoxIcon fontSize="medium" />;

const MenuListContainer = styled('div')(({ theme }) => ({
  overflowY: 'scroll',
  maxHeight: 300,
  borderBottom: `1px solid ${theme.palette.borderColor}`,
}));

/**
Project Filter Menu
* @param {Object} projectIds
* @param {Object} setFilters
* @returns 
*/
const ProjectFilterMenu = ({ projectIds, setFilters }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedProject, setSelectedProject] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));

  const { data: projects = [], isLoading: isProjectsLoading } = useGetProjectsQuery({ workspaceId });


  const displayedOptions = useMemo(
    () => projects?.filter((option) => matchText(option.name, searchText)),
    [searchText, projects]
  );

  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleClose = () => {
    setOpenMenu(null);
  };

  const handleProjectToggle = (event, option) => {
    setSelectedProject((prevSelectedProject) => {
      if (prevSelectedProject.includes(option.id)) {
        return prevSelectedProject.filter((projectId) => projectId !== option.id);
      } else {
        return [...prevSelectedProject, option.id];
      }
    });
  };

  const handleApply = () => {
    setFilters((prevState) => {
      return { ...prevState, project: selectedProject.length ? selectedProject : undefined };
    });
    setSearchParams((prevParams) => {
      selectedProject.length
        ? prevParams.set('project', selectedProject.join(','))
        : prevParams.delete('project');
      return prevParams;
    });
    handleClose();
  };

  const handleClear = () => {
    setSelectedProject([]);
    setFilters((prevState) => {
      return { ...prevState, project: undefined };
    });
    setSearchParams((prevParams) => {
      prevParams.delete('project');
      return prevParams;
    });
    handleClose();
  };

  useEffect(() => {
    if (projectIds?.length) {
      setSelectedProject(projectIds);
    }
  }, [projectIds]);

  return (
    <>
      <MenuButton onClick={handleClick}>
        Select Projects
        {Boolean(openMenu) ? <ArrowDropUp /> : <ArrowDropDown />}
      </MenuButton>
      <StyledMenu
        anchorEl={openMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        getContentAnchorEl={null}
        open={Boolean(openMenu)}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
      >
        <ListSubheader>
          <TextField
            autoFocus
            fullWidth
            placeholder="Type to search..."
            size="large"
            style={{ zIndex: 2, backgroundColor: 'white', paddingTop: '5px', paddingBottom: '5px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        <MenuListContainer>
          {displayedOptions.map((project, i) => (
            <MenuItem key={project.id} onClick={(event) => handleProjectToggle(event, project)}>
              <Checkbox
                checked={selectedProject?.includes(project.id) ? true : false}
                checkedIcon={checkedIcon}
                icon={icon}
              />
              <StyledMenuName>{project.name}</StyledMenuName>
            </MenuItem>
          ))}
        </MenuListContainer>
        <MenuItem
          disabled={!selectedProject.length}
          style={{ justifyContent: 'center', borderBottom: '1px solid lightGray', padding: 8 }}
          onClick={handleApply}
        >
          <StyledMenuName>APPLY </StyledMenuName>
        </MenuItem>
        <MenuItem style={{ justifyContent: 'center', padding: 8 }} onClick={handleClear}>
          <StyledMenuName>CLEAR</StyledMenuName>
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export default ProjectFilterMenu;
