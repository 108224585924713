import { StyledDialog } from '@blackhyve/common';
import { Button, Typography } from '@mui/material';

const BudgetLineDeleteDialog = ({ open, handleClose, handleDelete, id, line, loading }) => {
  const onClose = (event, reason) => {
    if (reason !== 'escapeKeyDown' && reason !== 'backdropClick') {
      handleClose();
    }
  };

  const canDelete = (line?.contract_lines?.length + line?.change_order_lines?.length) === 0;

  return (
    <StyledDialog
      dialogOverrides={{ maxWidth: 'xs' }}
      handleClose={onClose}
      open={open}
      title="Confirm Delete"
      actions={
        <>
          <Button onClick={handleClose} disabled={loading}>Cancel</Button>
          {canDelete && <Button
            variant={'contained'}
            disabled={loading}
            onClick={() => {
              handleDelete(id);
              handleClose();
            }}
          >
            {loading ? 'Deleting...' : 'Yes, delete it'}
          </Button>}
        </>
      }
    >
      {canDelete && (
        <>
          <Typography variant={'h6'} fontWeight={'bold'} textAlign={'center'}>
            Are you sure you want to delete this item?
          </Typography>
          <Typography textAlign={'center'}>This action cannot be undone.</Typography>
        </>
      )}

      {!canDelete && (
        <>
          <Typography variant={'h6'} fontWeight={'bold'}>
            This item cannot be deleted.
          </Typography>

          <Typography>Please, delete these references first:</Typography>

          <ul>
            {line?.contract_lines?.map(contractLine => (
              <li key={`contract-line-${contractLine.id}`}> Contract: {contractLine.contract.title}, line: #{contractLine.id} ({contractLine.cost_code}), {contractLine.description}</li>
            ))}
            {line?.change_order_lines?.map(changeOrderLine => (
              <li key={`change-order-line-${changeOrderLine.id}`}> Contract: {changeOrderLine.contract.title}, change order: #{changeOrderLine.id} ({changeOrderLine.cost_code}), {changeOrderLine.description}</li>
            ))}
          </ul>
        </>
      )
      }
    </StyledDialog >
  );
};

export default BudgetLineDeleteDialog;
