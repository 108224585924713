import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  alpha,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const convertCanvasToFile = async (canvas) => {
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      const file = new File([blob], 'page.png', { type: 'image/png' });
      resolve(file);
    }, 'image/png');
  });
};

const PdfToImage = ({ pdf, onSelect }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfJsPdf, setPdfjsPdf] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState({ width: 0, height: 0 });

  const canvasRef = useRef();
  const containerRef = useRef();

  const onResize = useDebounceCallback(setSize, 200);

  useResizeObserver({
    ref: containerRef,
    box: 'border-box',
    onResize,
  });

  const onLoadSuccess = (loadedPdf) => {
    setPdfjsPdf(loadedPdf);
    setIsLoading(false);
  };

  const handleSelect = async () => {
    setIsLoading(true);
    const scale = 4; // Desired resolution scale

    // Create a new canvas with a higher resolution
    const highResCanvas = document.createElement('canvas');
    const highResContext = highResCanvas.getContext('2d');

    // Get the page dimensions at the higher resolution
    const pdfPage = await pdfJsPdf.getPage(pageNumber);
    const viewport = pdfPage.getViewport({ scale });

    // Set the new canvas dimensions
    highResCanvas.width = viewport.width;
    highResCanvas.height = viewport.height;

    // Render the PDF page to the new canvas
    const renderContext = {
      canvasContext: highResContext,
      viewport: viewport,
    };
    await pdfPage.render(renderContext).promise;

    // Convert the new high-resolution canvas to a file
    const file = await convertCanvasToFile(highResCanvas);
    onSelect(file);
    setIsLoading(false);
  };

  const numPages = pdfJsPdf?.numPages;

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <Box>
      {pdf && (
        <>
          <Container
            sx={{
              backgroundColor: 'grey.400',
              py: 2,
              boxSizing: 'border-box',
              position: 'relative',
            }}
          >
            <Box maxWidth={'100%'} ref={containerRef}>
              <Document
                file={pdf}
                onLoadStart={() => setIsLoading(true)}
                onLoadSuccess={onLoadSuccess}
              >
                <Page
                  canvasRef={canvasRef}
                  pageNumber={pageNumber}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  width={size.width}
                />
              </Document>
            </Box>
            {isLoading && (
              <Box
                bgcolor={alpha(grey[400], 0.6)}
                height={'100%'}
                left={0}
                position={'absolute'}
                top={0}
                width={'100%'}
              >
                <CircularProgress
                  sx={{ translate: '-50% -50%', position: 'absolute', top: '50%', left: '50%' }}
                />
              </Box>
            )}
          </Container>
          <Box
            alignItems={'center'}
            as={Stack}
            direction={'row'}
            display={'flex'}
            justifyContent={'center'}
            my={2}
            spacing={1}
          >
            <IconButton
              color={'primary'}
              disabled={pageNumber <= 1}
              size={'small'}
              variant={'contained'}
              onClick={handlePreviousPage}
            >
              <ChevronLeft />
            </IconButton>
            <Button variant={'contained'} onClick={handleSelect}>
              Select
            </Button>
            <IconButton
              color={'primary'}
              disabled={pageNumber >= numPages}
              size={'small'}
              variant={'contained'}
              onClick={handleNextPage}
            >
              <ChevronRight />
            </IconButton>
            <TextField
              label={'Page'}
              size={'small'}
              value={pageNumber}
              variant={'outlined'}
              InputProps={{
                inputProps: { min: 1, max: numPages },
                endAdornment: <InputAdornment position="end">of {numPages}</InputAdornment>,
              }}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const page = parseInt(e.target.value);
                if (page > numPages) {
                  setPageNumber(numPages);
                } else if (page < 1) {
                  setPageNumber(1);
                } else {
                  setPageNumber(page);
                }
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default PdfToImage;
