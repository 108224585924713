import { useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import logo from '../assets/images/blackhyve-logo.png';
import BlackHyveLogoComponent from './BlackHyveLogoComponent';
import coverImage from '../assets/images/login-background.jpg';
import compressedBackgroundImage from '../assets/images/login-background-compress.jpg';
import '../assets/styles/login.css';

export const PageContainer = styled('div')(() => ({
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#231f20',
}));

export const StyledBoxContainer = styled(Grid)(() => ({
  backgroundColor: '#353535',
  borderRadius: '4px',
  color: '#fff',
  padding: '40px',
  width: '450px',
}));

/**
 * Login layout component
 * @param {JSX} children
 * @returns {JSX.Element}
 */
const LoginLayoutComponent = ({ children }) => {
  return (
    <PageContainer>
      <StyledBoxContainer container>
        <Grid container item justifyContent={'center'}>
          <BlackHyveLogoComponent
            imgStyle={{ width: '100%', maxWidth: '200px', height: 'auto' }}
            isName={true}
          />
        </Grid>
        {children}
      </StyledBoxContainer>
    </PageContainer>
  );
};

/**
 * Logo Name component
 * @returns {JSX.Element}
 */
const LogoAndNameComponent = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" direction="column" style={{ flexGrow: 1 }}>
      <Grid container justifyContent="center">
        <img src={logo} alt="" style={{ height: '170px' }} />
        <br />
        <br />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: compressedBackgroundImage,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}));

const MainPageImageComponent = () => {
  const classes = useStyles();
  const containerRef = useRef();

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const img = new Image();
      img.src = containerRef.current.dataset.src;
      img.onload = () => {
        containerRef.current.style.backgroundImage = `url(${containerRef.current.dataset.src})`;
      };
    }
  }, [containerRef]);

  return (
    <Grid
      item
      xs={false}
      sm={6}
      md={8}
      container
      ref={containerRef}
      data-src={coverImage}
      className={classes.image}
    ></Grid>
  );
};

export default LoginLayoutComponent;
