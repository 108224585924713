import * as React from "react";

const SvgPercentIcon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.8451 5.8451C6.49588 5.19432 6.49588 4.13886 5.8451 3.48808C5.19432 2.83731 4.13886 2.83731 3.48808 3.48808C2.83731 4.13886 2.83731 5.19432 3.48808 5.8451C4.13912 6.49614 5.19432 6.49614 5.8451 5.8451ZM12.5117 10.1547C11.8609 9.50393 10.8055 9.50393 10.1547 10.1547C9.50393 10.8055 9.50393 11.8609 10.1547 12.5117C10.8055 13.1625 11.8609 13.1625 12.5117 12.5117C13.1628 11.8609 13.1628 10.8057 12.5117 10.1547ZM12.4612 4.12792L11.8721 3.53886C11.5466 3.21335 11.019 3.21335 10.6938 3.53886L3.53886 10.6938C3.21334 11.0193 3.21334 11.5469 3.53886 11.8721L4.12792 12.4612C4.45344 12.7867 4.98104 12.7867 5.3063 12.4612L12.4612 5.3063C12.7867 4.98104 12.7867 4.45344 12.4612 4.12792Z"
      fill={props.color ? props.color : "#B3B3B3"}
    />
  </svg>
);

export default SvgPercentIcon;
