import times from 'lodash/times';
import format from 'date-fns/format';
import ReactQuill from 'react-quill';
import Grid from '@mui/material/Grid';
import isEmpty from 'lodash/isEmpty';
import Paper from '@mui/material/Paper';
import isValid from 'date-fns/isValid';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import { DatePicker } from '@mui/x-date-pickers';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller, useForm } from 'react-hook-form';
import { parseDate } from '@blackhyve/utilities/dates';
import { ConfirmDeleteDialog } from '@blackhyve/common';
import FormHelperText from '@mui/material/FormHelperText';
import LinearProgress from '@mui/material/LinearProgress';
import { MenuOptions } from '../ProductionBoardMenuOptions';
import DragIndicator from '@mui/icons-material/DragIndicator';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import RichTextEditor from 'components/common/v3/RichTextEditor';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useCreateStepMutation, useDeleteStepMutation, useGetStepsQuery, useReorderStepsMutation, useUpdateStepMutation } from 'features/steps/store/step.api';
import { useGetProjectCompaniesQuery, useGetWorkspaceCompanyQuery } from 'features/projectCompanies/api/projectCompanies.api';

/**
 * Steps
 * @returns 
 */
const StepTab = ({ projectId, taskId }) => {

  const [displayAddStep, setDisplayAddStep] = useState(false);
  const [reorderSteps, { isLoading: isLoadingReorderSteps, isFetching: isFetchingReorderSteps }] = useReorderStepsMutation();
  const { data: steps = [], isLoading, isFetching } = useGetStepsQuery({ entityId: taskId, entityType: 'tasks', order_by: 'created_at,desc;' })
  const [stepPriority, setStepPriority] = useState()

  const handleCloseAddStep = () => setDisplayAddStep(false);

  const handleUpdateStepPriority = (data) => {
    const cloneStepPriority = [...stepPriority];
    if (isEmpty(data.destination)) {
      return false;
    }
    const destinationIndex = data.destination.index;
    const sourceIndex = data.source.index;
    const step = cloneStepPriority.splice(sourceIndex, 1);

    cloneStepPriority.splice(destinationIndex, 0, ...step);
    setStepPriority(cloneStepPriority);
    reorderSteps({ entityId: taskId, entityType: 'tasks', stepIds: cloneStepPriority.map((step) => step.id) })
  }

  const totalSteps = steps.length;
  const completedSteps = steps.filter((step) => step.is_complete)?.length

  const alertType =
    totalSteps === completedSteps
      ? 'success'
      : completedSteps === 0 && totalSteps > 0
        ? 'error'
        : completedSteps !== totalSteps
          ? 'warning'
          : 'info';

  useEffect(() => {
    setStepPriority(steps)
  }, [steps])

  return <Grid container gap={1}>
    <Grid container item alignItems={'center'} xs={12}>
      <IconButton disableRipple size='large'>
        <PlaylistAddIcon fontSize='inherit' />
      </IconButton>
      <Typography sx={{ fontWeight: '600' }} variant='h6'>Steps</Typography>
      {!displayAddStep &&
        <Button variant="text" onClick={() => setDisplayAddStep(true)}>
          + ADD STEP
        </Button>}
      {steps.length ? (
        <Alert severity={alertType} sx={{ px: 0.5, py: 0, ml: 'auto' }}>
          {completedSteps} / {totalSteps} completed
        </Alert>
      ) : null}
    </Grid>
    <DragDropContext onDragEnd={handleUpdateStepPriority}>
      <Droppable droppableId={'step-drop'} type={taskId}>
        {(provided) => (
          <Grid container item xs={12}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {displayAddStep &&
              <CreateStep
                handleClose={handleCloseAddStep}
                projectId={projectId}
                taskId={taskId}
              />}
            {!isLoading && isFetching && <LinearProgress sx={{ width: '100%' }} />}
            {isLoading && times(5, (row) => <StepSkeleton key={row} />)}
            {stepPriority?.map((step, index) =>
              <Step index={index} key={step?.id} projectId={projectId} step={step} />
            )}
          </Grid>)}
      </Droppable>
    </DragDropContext>
  </Grid>
}

/**
 * Step
 * @param {object} step 
 * @returns 
 */
const Step = ({ step, projectId, index }) => {

  const [openEditStep, setOpenEditStep] = useState(false);
  const [complete, setComplete] = useState(step?.is_complete)
  const [openDeleteStepConfirmation, setOpenDeleteStepConfirmation] = useState(false);

  const [deleteStep] = useDeleteStepMutation();
  const [updateStep, { isLoading: isUpdateStep }] = useUpdateStepMutation();

  const handleOnEdit = () => setOpenEditStep(true);
  const handleCloseEdit = () => setOpenEditStep(false);
  const handleOnDelete = () => setOpenDeleteStepConfirmation(true);
  const handleCloseDelete = () => setOpenDeleteStepConfirmation(false);

  const handleDelete = () => {
    deleteStep({ id: step?.id, entityId: step?.taskId, entityType: 'task' })
    handleCloseDelete();
  }

  const handleUpdateComplete = async () => {
    const newComplete = !complete;
    setComplete(newComplete)
  }

  useEffect(() => {
    if (step?.is_complete != complete) {
      updateStep({
        id: step?.id,
        entityId: step?.task_id,
        entityType: 'tasks',
        is_complete: complete
      })
    }
  }, [step?.is_complete, complete])

  useEffect(() => {
    setComplete(step?.is_complete);
  }, [step?.is_complete])


  return openEditStep ?
    <CreateStep handleClose={handleCloseEdit} projectId={projectId} step={step} /> :
    <Draggable
      draggableId={`step-${step?.id}`}
      index={index}
      key={`step-${step?.id}`}
    >
      {(provided, snapshot) => (
        <Grid
          {...provided.draggableProps}
          container
          item
          alignItems={'center'} component={Paper} elevation={0} px={2} py={0.5} ref={provided.innerRef} square="true" variant='outlined'
        >
          <Grid container item>
            <Grid container item alignItems={'flex-start'} xs={12}>
              <Grid container item alignItems={'center'} xs="auto"  {...provided.dragHandleProps}>
                <DragIndicator sx={{ color: 'grey.600' }} />
                <Checkbox checked={complete} disabled={isUpdateStep} onChange={handleUpdateComplete} />
              </Grid>
              <Grid container item xs alignItems={'center'} m="auto">
                <ReactQuill
                  bounds="quill"
                  className={'comment'}
                  readOnly={true}
                  theme={'bubble'}
                  value={step?.name}
                />
              </Grid>
              <Grid item alignItems={'flex-start'} justifyContent={'flex-end'} ml="auto" xs={'auto'}>
                <MenuOptions onDelete={handleOnDelete} onEdit={handleOnEdit} />
              </Grid>
            </Grid>
            {step?.companies?.length || step?.due_date ? <Grid container item gap={1} xs={12}>
              {step?.companies?.length ?
                <Grid container item xs gap={0.5}>
                  <FormLabel> Companies:</FormLabel>
                  <Typography> {step?.companies?.map((company) => company?.name).join(',')}</Typography>
                </Grid> : null}
              {step?.due_date ?
                <Grid container item xs gap={0.5}>
                  <FormLabel> Due date:</FormLabel>
                  <Typography> {step?.due_date}</Typography>
                </Grid> : null}
            </Grid> : null}
          </Grid>

          {openDeleteStepConfirmation &&
            <ConfirmDeleteDialog
              handleClose={handleCloseDelete}
              handleDelete={handleDelete}
              item={'step'}
              open={openDeleteStepConfirmation}
            />}
        </Grid>
      )}
    </Draggable>
}

const StepSkeleton = () => {
  return <Grid item square component={Paper} sx={{ px: 2, py: 1, display: 'flex', flexDirection: 'column', gap: 1 }} variant="outlined"
    xs={12}
  >
    <Grid container item alignItems="center" spacing={1}>
      <Grid item xs={12}>
        <Skeleton height={30} variant="text" width="100%" />
      </Grid>
    </Grid>

    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Skeleton height={20} variant="text" width="100%" />
      </Grid>
      <Grid item xs={6}>
        <Skeleton height={20} variant="text" width="100%" />
      </Grid>
    </Grid>
  </Grid>
}

const initialState = {
  id: undefined,
  name: '',
  due_date: null,
  companies: []
}

/**
 * Create Step
 * @param {object} step
 * @param {string} taskId
 * @param {string} projectId
 * @param {object} handleClose 
 * @returns 
 */
const CreateStep = ({ projectId, handleClose, taskId, step }) => {

  const formMethods = useForm({ defaultValues: { ...initialState }, });
  const { control, handleSubmit, watch, reset, setFocus, setValue } = formMethods;

  const { data: companies = [] } = useGetProjectCompaniesQuery({ projectId });
  const { data: userCompany = { users: [] } } = useGetWorkspaceCompanyQuery({ projectId });
  const filteredCompanies = companies?.filter((obj) => obj.id !== userCompany.id)

  const [createStep, { isLoading: isCreatingStep }] = useCreateStepMutation();
  const [updateStep, { isLoading: isUpdateStep }] = useUpdateStepMutation();

  const handleSave = (data) => {
    const payload = {
      ...data,
      entityId: taskId,
      entityType: 'tasks',
      due_date: data.due_date ? format(data.due_date, "yyyy-MM-dd") : null
    }
    const stepPromise = step?.id ? updateStep(payload) : createStep(payload);
    stepPromise?.then(() => {
      reset(initialState);
      handleClose();
    });
  }


  useEffect(() => {
    if (!isEmpty(step)) {
      reset({
        id: step.id,
        name: step.name,
        companies: step?.companies?.length ? step?.companies?.map((company) => company.id) : [],
        due_date: step?.due_date ? parseDate(step?.due_date) : null
      })
    }
  }, [step])


  return <Grid container item component={Paper} gap={2} px={2} py={1} square="true" variant='outlined' >
    <Grid item xs={12}>
      <FormLabel>Name*</FormLabel>
      <Controller
        control={control}
        name="name"
        render={({ field, fieldState: { error } }) => (
          <>
            <RichTextEditor
              className="add-step"
              readOnly={false}
              text={field.value}
              handleOnChange={(newValue) => {
                console.log('here', newValue);
                setValue('name', newValue)
              }}
            />
            {error && <FormHelperText sx={{ color: 'red' }}>
              {error.message}
            </FormHelperText>}
          </>
        )}
        rules={{
          required: 'Name field is required',
        }}
      />
    </Grid>
    <Grid container item md={8} xs={12}>
      <FormLabel>Companies</FormLabel>
      <Controller
        control={control}
        name="companies"
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            disableCloseOnSelect
            fullWidth
            multiple
            isOptionEqualToValue={(option, value) => option.id === value}
            options={filteredCompanies}
            getOptionLabel={(option) =>
              option?.name ??
              (filteredCompanies && filteredCompanies.find((company) => company.id === option)?.name)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                error={error}
                helperText={error?.message}
                placeholder="Select Company"
                size="small"
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option?.id}>
                <Checkbox checked={selected} />
                {option?.name}
              </li>
            )}
            onChange={(event, newValue) => {
              field.onChange(newValue.map((company) => company?.id ?? company));
            }}
          />
        )}
      />
    </Grid>
    <Grid container item md={'auto'} xs={12}>
      <FormLabel>Due Date</FormLabel>
      <Controller
        control={control}
        name={'due_date'}
        render={({
          field: { onChange, value, ref, ...field },
          fieldState: { error, invalid },
        }) => (
          <DatePicker
            ref={ref}
            value={value}
            slotProps={{
              textField: {
                id: 'due_date',
                fullWidth: true,
                size: 'small',
                helperText: error?.message,
                error: invalid,
                ...field,
              },
              actionBar: {
                actions: ['today'],
              },
            }}
            onChange={onChange}
          />
        )}
      />
    </Grid>
    <Grid container item gap={1} xs={9}>
      <Button disabled={isCreatingStep} size="small" onClick={handleClose}>
        Close
      </Button>
      <LoadingButton
        loading={isCreatingStep || isUpdateStep}
        size='small'
        variant={'contained'}
        onClick={handleSubmit(handleSave)}
      >
        Save
      </LoadingButton>
    </Grid>
  </Grid >


}

export default StepTab;