import {
  Avatar,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListItemButton,
  TextField,
} from '@mui/material';
import Box from '@mui/system/Box';
import { Clear } from '@mui/icons-material';
import { Alert, Autocomplete } from '@mui/material';
import SearchBox from 'components/search/SearchBox';
import { useState } from 'react';

export const SelectCompaniesList = ({ selected, handleSelect, companies }) => {
  const [search, setSearch] = useState('');
  const filteredCompanies = companies.filter((company) =>
    company.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <SearchBox
        fullWidth
        borderRadius={1}
        sx={{ bgcolor: 'grey.200' }}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />
      <List>
        {filteredCompanies.length > 0 ? (
          filteredCompanies.map((company) => {
            const isSelected = Boolean(
              selected.find((selectedCompany) => selectedCompany.id === company.id)
            );
            return (
              <ListItemButton
                key={company.id}
                selected={isSelected}
                onClick={(event) => handleSelect(company, isSelected)}
              >
                <ListItemAvatar>
                  <Avatar src={company?.logo?.thumb_url}>{company.name.slice(0, 2)}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={company.name} />
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={isSelected}
                    onClick={(event) => handleSelect(company, isSelected)}
                  />
                </ListItemSecondaryAction>
              </ListItemButton>
            );
          })
        ) : search === '' ? (
          <Alert severity={'info'}>No Companies To Assign</Alert>
        ) : (
          <Alert severity={'info'}>No Matches Found For Current Search Query</Alert>
        )}
      </List>
    </>
  );
};

/**
 * @param {*} props
 * @param {Set} props.selectedContacts
 * @param {Function} props.setSelectedContacts
 * @returns JSX List of contacts with checkboxes
 */
export const SelectCompaniesAutocompleteList = ({
  selected,
  setSelected,
  disabled,
  companies,
  isLoading,
}) => {
  return (
    <Box display={'flex'} flexDirection={'column'} height={'100%'}>
      <Autocomplete
        disableClearable
        disableCloseOnSelect
        fullWidth
        multiple
        autoFocus={false}
        disabled={disabled}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        limitTags={0}
        loading={isLoading}
        options={companies}
        renderTags={() => null}
        value={selected}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder="Select Companies"
            size="small"
            variant="outlined"
          />
        )}
        renderOption={(props, option, { selected }) => {
          return (
            <ListItemButton {...props}>
              <ListItemIcon>
                <Checkbox checked={selected} />
              </ListItemIcon>
              <ListItemText>{option.name}</ListItemText>
            </ListItemButton>
          );
        }}
        onChange={(event, newValue) => {
          if (event.key !== 'Backspace') {
            setSelected(newValue);
          }
        }}
      />
      <Box dense component={List} maxHeight={'25vh'} sx={{ overflowY: 'auto', padding: '5px' }}>
        {selected?.map((company) => {
          return (
            <ListItem disableGutters key={company.id}>
              <ListItemAvatar>
                <Avatar src={company?.logo?.thumb_url} />
              </ListItemAvatar>
              <ListItemText primary={company?.name} />

              {!disabled && (
                <ListItemSecondaryAction>
                  <IconButton
                    size="large"
                    onClick={() => {
                      setSelected(
                        selected.filter((selectedCompany) => selectedCompany.id !== company.id)
                      );
                    }}
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
      </Box>
    </Box>
  );
};
