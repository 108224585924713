import { Box, Container, Grid, Paper } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import ImageUploadComponent from 'components/common/ImageUploadComponent';
import { selectCurrentUser, selectCurrentUserId } from 'features/auth';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserDetails from '../components/UserDetails';
import ChangePasswordForm from '../components/forms/ChangePasswordForm';
import { fetchUser, uploadProfileImage } from '../store/usersSlice';

export const UserProfile = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => selectCurrentUser(state));
  const currentUserId = useSelector((state) => selectCurrentUserId(state));
  const { error: usersError } = useSelector((state) => state.users);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();

  const handleUploadImage = (file) => {
    setImage(file);
    const formData = new FormData();
    formData.append('id', currentUser.id);
    formData.append('profile_image', file);
    setLoading(true);
    dispatch(uploadProfileImage(formData))
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (!currentUser) {
      setLoading(true);
      dispatch(fetchUser(currentUserId)).then(() => setLoading(false));
    }
  }, [currentUser, currentUserId, dispatch]);

  useEffect(() => {
    setImage(
      currentUser?.profile_image?.original_url
        ? currentUser?.profile_image
        : currentUser?.profile_image
    );
  }, [currentUser]);

  return (
    <Container sx={{ py: 4 }}>
      {loading && <CircularProgress className="loading" />}
      <Paper>
        <Grid container p={2} spacing={2}>
          <Grid item m={'auto'} md={6} xs={12}>
            <ImageUploadComponent
              handleUpload={handleUploadImage}
              image={image?.original_url ? image.original_url : image}
              imageHeight={'auto'}
              imageWidth={'50%'}
              isImageBlob={!image?.original_url}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <UserDetails errors={usersError} userDetails={currentUser} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <ChangePasswordForm />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default UserProfile;
