import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import Check from '@mui/icons-material/Check';
import { List, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material';
import { MenuButton } from 'assets/style-components/button';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { registerMenuState, updateMenuState } from 'slices/customViewSlice';
import ganttStore from '../../../components/projectOverview/gantt/ganttConfig/ganttStore';
import { useGetProjectsQuery } from 'features/projects/store/project.api';
import { selectCurrentUserWorkspaceId } from 'features/auth';

const defaultColor = '#757575';

const displayFunctions = {
  Default: {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: false, type: 'location' },
    displayCriticalPath: false,
    displayCompany: false,
  },
  Task: {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: true, type: 'task' },
    displayCriticalPath: false,
    displayCompany: false,
  },
  'Critical Path': {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: false, type: 'location' },
    displayCriticalPath: true,
    displayCompany: false,
  },
  Pace: {
    displayTaskPace: true,
    displayFloat: false,
    displayType: { visible: false, type: 'location' },
    displayCriticalPath: false,
    displayCompany: false,
  },
  Float: {
    displayTaskPace: false,
    displayFloat: true,
    displayType: { visible: false, type: 'location' },
    displayCriticalPath: false,
    displayCompany: false,
  },
  Location: {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: true, type: 'location' },
    displayCriticalPath: false,
    displayCompany: false,
  },
  Zone: {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: true, type: 'zone' },
    displayCriticalPath: false,
    displayCompany: false,
  },
  Area: {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: true, type: 'area' },
    displayCriticalPath: false,
    displayCompany: false,
  },
  Project: {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: true, type: 'project' },
    displayCriticalPath: false,
    displayCompany: false,
  },
  Company: {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: true, type: 'company' },
    displayCriticalPath: false,
    displayCompany: false,
  },
};

const ColorMenu = ({ ganttId, options, projectId }) => {
  const gantt = ganttStore.getGantt(ganttId);
  const selectedOption = useSelector((state) => state.customViews?.menus?.color?.currentValue);
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { data: projects = [] } = useGetProjectsQuery({ workspaceId });

  const [anchorEl, setAnchorEl] = useState(null);
  const state = useStore().getState();
  const dispatch = useDispatch();

  const setDisplayCriticalPath = (isEnabled) => {
    gantt.config.show_critical_path = isEnabled;
    gantt.render();
  };

  const setDisplayTaskPace = (isEnabled) => {
    gantt.config.highlight_task_pace = isEnabled;
    gantt.render();
  };

  const setDisplayFloat = (isEnabled) => {
    gantt.config.show_slack = isEnabled;
    gantt.render();
  };

  const setDisplayType = (isEnabled, type) => {
    if (isEnabled) {
      const { areas, zones, locations } = gantt?.constants?.lbsData;

      let getTaskColorFunction = undefined;
      let color = defaultColor;
      switch (type) {
        case 'location':
          getTaskColorFunction = (task) => locations?.[task?.location_id]?.color || color;
          break;
        case 'zone':
          getTaskColorFunction = (task) =>
            zones?.[task?.zone_id]?.color || locations?.[task?.location_id]?.color || color;
          break;
        case 'area':
          getTaskColorFunction = (task) =>
            areas?.[task?.area_id]?.color ||
            zones?.[task?.zone_id]?.color ||
            locations?.[task?.location_id]?.color ||
            color;
          break;
        case 'project':
          const projectEntities = projects.reduce((entities, project) => {
            entities[project.id] = project;
            return entities;
          }, {});
          getTaskColorFunction = (task) =>
            projectEntities[task?.project_id]?.color
              ? projectEntities[task?.project_id]?.color
              : defaultColor;
          break;
        case 'task':
          getTaskColorFunction = (task) => task.task_color;
          break;
        case 'company':
          getTaskColorFunction = (task) => {
            if (!task?.companies || task.companies.length === 0) {
              return defaultColor;
            } else {
              // Calculate the gradient stops based on the number of companies
              const colorStops = task.companies
                .map((company, index, array) => {
                  const start = (index / array.length) * 100;
                  const end = ((index + 1) / array.length) * 100;
                  return `${company.color ? company.color : defaultColor} ${start}% ${end}%`;
                })
                .join(', ');
              return `linear-gradient(to right, ${colorStops})`;
            }
          };
          break;
        default:
          break;
      }
      gantt.detachEvent('colorTasks');
      gantt.attachEvent(
        'onBeforeTaskDisplay',
        function (id, task) {
          task.$color = getTaskColorFunction(task);
          return true;
        },
        { id: 'colorTasks' }
      );
    } else {
      gantt.detachEvent('colorTasks');
      gantt.batchUpdate(() => {
        gantt.eachTask((task) => {
          task.$color = undefined;
        });
      });
    }
    gantt.render();
  };

  const setCompanyColor = (isEnabled) => {
    gantt.config.show_company_color = isEnabled;
    gantt.refreshData();
  };

  const stateUpdateFunctions = useMemo(
    () => ({
      displayTaskPace: setDisplayTaskPace,
      displayFloat: setDisplayFloat,
      displayCriticalPath: setDisplayCriticalPath,
      displayType: setDisplayType,
      displayCompany: setCompanyColor,
    }),
    []
  );

  const handleSelectOption = (event, option) => {
    dispatch(updateMenuState({ name: 'color', value: option }));
  };

  useEffect(() => {
    if (selectedOption) {
      const displayConfig = displayFunctions[selectedOption];
      Object.entries(displayConfig).forEach(([key, value]) => {
        const functionName = stateUpdateFunctions[key];
        key === 'displayType' ? functionName(value.visible, value.type) : functionName(value);
      });
    }
  }, [selectedOption]);

  useEffect(() => {
    dispatch(registerMenuState({ name: 'color', value: 'Default' }));
  }, [dispatch]);

  useEffect(() => {
    gantt?.attachEvent(
      'onSetColorOption',
      function (option) {
        handleSelectOption(undefined, option);
      },
      { id: 'onSetColorOption' }
    );
    return () => {
      gantt?.detachEvent('onSetColorOption');
    };
  }, [gantt]);

  return (
    <>
      <MenuButton
        endIcon={Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />}
        isHighlighted={selectedOption !== 'Default'}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        Color
      </MenuButton>
      {anchorEl !== null ? (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={Boolean(anchorEl)}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setAnchorEl(null)}
        >
          <List dense>
            {options.map((option) => {
              const selected = option == selectedOption;
              return (
                <ListItemButton key={option} onClick={(event) => handleSelectOption(event, option)}>
                  <ListItemIcon>{selected ? <Check /> : null}</ListItemIcon>
                  <ListItemText primary={option} />
                </ListItemButton>
              );
            })}
          </List>
        </Popover>
      ) : null}
    </>
  );
};

export default ColorMenu;
