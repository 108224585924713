import { StyledDialog } from '@blackhyve/common';
import {
  Button,
  CircularProgress,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useCreateBudgetsByProjectMutation,
  useUpdateBudgetByIdMutation,
} from '../store/budget.api';
import { MoneyInput, PercentInput, unformatMoney } from './NumericControls';
import { breakdownChanges } from '../models/breakdown';
import {
  BELL_CURVE,
  NORMAL_DISTRIBUTION,
  CurveOptions,
  DistributionOptions,
} from '../models/curves';

const initialState = {
  name: '',
  strategy: 'top_down',
  amount: '',
  labor_amount: '',
  labor_percent: '50',
  labor_curve: BELL_CURVE,
  labor_curve_distribution: NORMAL_DISTRIBUTION,
  material_amount: '',
  material_percent: '50',
  material_curve: BELL_CURVE,
  material_curve_distribution: NORMAL_DISTRIBUTION,
};

export const TOP_DOWN = 'top_down';

export const StrategyOptions = [
  { id: TOP_DOWN, name: 'Top Down' },
  { id: 'bottom_up', name: 'Bottom Up' },
];

export const BudgetFormDialog = ({ open, handleClose, projectId, budget }) => {
  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: { ...initialState, ...budget },
  });

  const [createBudget, { isLoading: isLoadingCreating }] = useCreateBudgetsByProjectMutation();

  const [updateBudget, { isLoading: isLoadingUpdating }] = useUpdateBudgetByIdMutation();

  const isLoading = isLoadingCreating || isLoadingUpdating;

  async function handleStore(data) {
    if (budget?.id) {
      await updateBudget({
        ...data,
        amount: unformatMoney(data.amount, true),
        labor_amount: unformatMoney(data.labor_amount, true),
        material_amount: unformatMoney(data.material_amount, true),
      })
        .unwrap()
        .then(() => {
          handleClose();
        });
    } else {
      await createBudget({
        projectId,
        ...{
          ...data,
          amount: unformatMoney(data.amount, true),
          labor_amount: unformatMoney(data.labor_amount, true),
          material_amount: unformatMoney(data.material_amount, true),
        },
      })
        .unwrap()
        .then(() => {
          closeDialog();
        });
    }
  }

  function closeDialog() {
    handleClose();
    reset();
  }

  useEffect(() => {
    reset({ ...initialState, ...budget });
  }, [budget, reset]);

  const { amount, strategy } = watch();

  useEffect(() => {
    const laborWatcher = watch((state, { name, type }) => {
      if (!type) return;

      breakdownChanges(state, name).forEach(({ field, value }) => {
        setValue(field, value);
      });
    });

    return () => {
      laborWatcher.unsubscribe();
    };
  }, [watch, setValue, strategy]);

  const mode = budget?.id ? 'Edit' : 'Create';

  return (
    <StyledDialog
      DialogContentProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
      handleClose={closeDialog}
      open={open}
      title={`${mode} Budget`}
      actions={
        <>
          <Button disabled={isLoading} onClick={closeDialog}>
            Close
          </Button>
          <Button
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={'1rem'} />}
            variant={'contained'}
            onClick={handleSubmit(handleStore)}
          >
            Save
          </Button>
        </>
      }
    >
      <Grid container item spacing={2} xs={12}>
        <Grid container item xs={12}>
          <FormLabel>Name</FormLabel>
          <Controller
            control={control}
            name="name"
            rules={{ required: 'Name field is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={error}
                helperText={error?.message}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid container item xs={6}>
          <FormLabel>Strategy</FormLabel>
          <Controller
            control={control}
            name="strategy"
            rules={{ required: 'Strategy field is required' }}
            render={({ field, fieldState: { error } }) => (
              <Select {...field} fullWidth name={'name'} size={'small'}>
                {StrategyOptions.map((obj) => (
                  <MenuItem key={obj.id} value={obj.id}>
                    {obj.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>

        <Grid container item xs={6}>
          <FormLabel>Estimated Amount ($)</FormLabel>
          <Controller
            control={control}
            name="amount"
            rules={{ required: 'Estimated Amount field is required' }}
            render={({ field, fieldState: { error } }) => (
              <MoneyInput {...field} error={error} setValue={setValue} />
            )}
          />
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Estimated Labor (%)</FormLabel>
            <Controller
              control={control}
              name="labor_percent"
              rules={{ required: 'Estimated Labor Percent field is required' }}
              render={({ field, fieldState: { error } }) => (
                <PercentInput {...field} fullWidth disabled={strategy !== TOP_DOWN} error={error} />
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Estimated Material (%)</FormLabel>
            <Controller
              control={control}
              name="material_percent"
              rules={{ required: 'Estimated Material Percent field is required' }}
              render={({ field, fieldState: { error } }) => (
                <PercentInput {...field} fullWidth disabled={strategy !== TOP_DOWN} error={error} />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Estimated Labor ($)</FormLabel>
            <Controller
              control={control}
              name="labor_amount"
              rules={{ required: 'Estimated Labor Amount field is required' }}
              render={({ field, fieldState: { error } }) => (
                <MoneyInput
                  {...field}
                  defaultMax={unformatMoney(amount)}
                  disabled={strategy == TOP_DOWN}
                  error={error}
                  setValue={setValue}
                />
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Estimated Material ($)</FormLabel>
            <Controller
              control={control}
              name="material_amount"
              rules={{ required: 'Estimated Material Amount field is required' }}
              render={({ field, fieldState: { error } }) => (
                <MoneyInput
                  {...field}
                  defaultMax={unformatMoney(amount)}
                  disabled={strategy == TOP_DOWN}
                  error={error}
                  setValue={setValue}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Labor Curve</FormLabel>
            <Controller
              control={control}
              name="labor_curve"
              rules={{ required: 'Labor Curve field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  fullWidth
                  name={'name'}
                  error={Boolean(error)}
                  helperText={error?.message}
                  size={'small'}
                >
                  {CurveOptions.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Material Curve</FormLabel>
            <Controller
              control={control}
              name="material_curve"
              rules={{ required: 'Material Curve field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  fullWidth
                  name={'name'}
                  error={Boolean(error)}
                  helperText={error?.message}
                  size={'small'}
                >
                  {CurveOptions.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Labor Curve Distribution</FormLabel>
            <Controller
              control={control}
              name="labor_curve_distribution"
              rules={{ required: 'Labor Curve Distribution field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  fullWidth
                  name={'name'}
                  error={Boolean(error)}
                  helperText={error?.message}
                  size={'small'}
                >
                  {DistributionOptions.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Material Curve Distribution</FormLabel>
            <Controller
              control={control}
              name="material_curve_distribution"
              rules={{ required: 'Material Curve Distribution field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  fullWidth
                  name={'name'}
                  error={Boolean(error)}
                  helperText={error?.message}
                  size={'small'}
                >
                  {DistributionOptions.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};
