import { useState } from 'react';
import List from '@mui/material/List';
import Chip from '@mui/material/Chip';
import Check from '@mui/icons-material/Check';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { StyledMenu } from 'features/activityFeeds/assets/activityFeedStyle';


/**
 * Jobwalk Sort Menu
 * @param {Object} setFilters
 * @param {Object} filters
 * @returns 
 */
const JobwalkSortMenu = ({ filters, setFilters }) => {

  const [openMenu, setOpenMenu] = useState(false);

  const options = [
    { name: "None", value: undefined },
    { name: "Open Jobwalks", value: "jobwalk,asc;" },
    { name: "Closed Jobwalks", value: "jobwalk,desc;" },
  ]

  const handleClose = () => {
    setOpenMenu(null);
  };

  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };

  return <>
    <Chip
      icon={<SwapVertIcon fontSize="small" />}
      label={`Sort By ${filters?.order_by ? options?.find((menu) => menu.value === filters.order_by)?.name : "None"} `}
      variant={filters?.order_by ? 'filled' : 'outlined'}
      onClick={handleClick}
    />
    <StyledMenu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      getContentAnchorEl={null}
      open={Boolean(openMenu)}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onClose={handleClose}
    >
      <List dense>
        {options.map((option) => {
          const isSelected = filters.order_by === option.value;
          return (
            <ListItemButton key={option} onClick={() => setFilters((prevFilters) => ({ ...prevFilters, order_by: option.value }))}>
              <ListItemIcon>{isSelected ? <Check /> : null}</ListItemIcon>
              <ListItemText primary={option.name} />
            </ListItemButton>
          );
        })}
      </List>
    </StyledMenu>
  </>

}

export default JobwalkSortMenu;