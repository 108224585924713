import { useState, useEffect } from 'react';
import Edit from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { StyledTitle } from 'features/drawers/utils/drawerStyle';

export const StyledInlineEditDiv = styled(Grid)(({ theme, ...props }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    '& svg.edit-icon': {
      display: 'none',
    },
    '&:hover': () =>
      !props.disabled && {
        '& svg.edit-icon': {
          display: 'inline-block',
        },
      },
  };
});

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '&.MuiInputBase-root': {
    fontSize: '14px',
    height: '34px',
  },
  '&.MuiOutlinedInput-root': {
    borderRadius: '2px',
  },
  '& textArea': {
    font: 'inherit',
    height: '72px !important',
  },
}));

/**
 * Inline edit component
 * @param {String} value
 * @param {Object} handleSave
 * @param {Boolean} isMultiline
 * @returns
 */
const InlineEdit = ({ value, isMultiline, handleSave, disabled }) => {
  const [isEditing, setIsEditing] = useState(false);

  const [editingValue, setEditingValue] = useState(value);

  const onChange = (event) => setEditingValue(event.target.value);

  const handleCancelEditing = () => {
    setIsEditing(false);
    setEditingValue(value);
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.target.blur();
    }
  };

  const handleUpdate = () => {
    handleSave(editingValue);
    setIsEditing(false);
  };

  // const onBlur = (event) => {
  //   if (event.target.value.trim() === "") {
  //     setEditingValue(value);
  //   } else {
  //     setValue(event.target.value);
  //     setIsEditing(false)
  //   }
  // }

  useEffect(() => {
    setEditingValue(value);
  }, [value]);

  return (
    <>
      {!isEditing ? (
        <StyledInlineEditDiv disabled onClick={() => !disabled && setIsEditing(true)}>
          {isMultiline ? (
            <Typography style={{ marginRight: '15px' }}>{editingValue}</Typography>
          ) : (
            <StyledTitle>{editingValue}</StyledTitle>
          )}
          <Edit
            className="edit-icon"
            fontSize="small"
            style={{ color: '#B3B3B3', cursor: 'pointer' }}
          />
        </StyledInlineEditDiv>
      ) : (
        <Grid container item alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <StyledTextField
              autoFocus
              fullWidth
              multiline={isMultiline}
              name="name"
              placeholder="Description"
              size="small"
              type="text"
              value={editingValue}
              variant="outlined"
              onChange={onChange}
              onKeyDown={onKeyDown}
            />
          </Grid>
          <Grid item className="flex" style={{ gap: 10 }} xs={12}>
            <Button size='small' onClick={handleCancelEditing}>
              Cancel
            </Button>
            <Button
              size='small'
              type="submit"
              variant="contained"
              onClick={handleUpdate}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default InlineEdit;
