import { StyledDialog } from '@blackhyve/common';
import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useUpdateUserMutation } from '../api/users.api';
import { ContactForm } from './ContactForm';

const initialFormState = {
  first_name: '',
  last_name: '',
  title: '',
  phone: '',
  role: 'member',
  email: '',
  offices: [],
  trade_ids: [],
};

const UpdateContactDialog = ({ contact, open, handleClose, workspaceOwner }) => {
  const [updateContact, { isLoading: isUpdating }] = useUpdateUserMutation();

  const form = useForm({
    defaultValues: Object.assign(
      { ...initialFormState, trade_ids: contact.trades.map((trade) => trade.id) },
      contact
    ),
  });

  const { reset, handleSubmit, setError } = form;

  const onClose = () => {
    reset(
      Object.assign(
        { ...initialFormState, trade_ids: contact.trades.map((trade) => trade.id) },
        contact
      )
    );
    handleClose();
  };

  const onSubmit = async (data) => {
    data.office_ids = data.offices.map((office) => office.id);
    // data.trade_ids = data.trades.map((trade) => trade.id);
    updateContact({ user: data })
      .unwrap()
      .then((payload) => {
        handleClose();
      })
      .catch((response) => {
        Object.entries(response.data.errors).forEach(([field, error]) => {
          setError(field, {
            type: 'manual',
            message: error,
          });
        });
      });
  };

  return (
    <StyledDialog
      open={open}
      title={'Edit Contact'}
      actions={
        <>
          <Button onClick={handleClose}>Close</Button>
          <Button disabled={isUpdating} variant="contained" onClick={handleSubmit(onSubmit)}>
            Update
          </Button>
        </>
      }
      handleClose={(_, reason) => {
        if (reason && reason === 'backdropClick') return;
        onClose();
      }}
    >
      <ContactForm
        companyId={contact.company_id}
        form={form}
        workspaceOwner={workspaceOwner}
        onSubmit={onSubmit}
      />
    </StyledDialog>
  );
};

export default UpdateContactDialog;
