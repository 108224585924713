import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InputAdornment from '@mui/material/InputAdornment';
import { clearAuthErrors, resetPassword } from '../store/authSlice';
import { ShowPasswordLink, StyledArrowIcon, StyledButtonLoading, StyledErrorAlert, StyledLink, StyledLoginButton, StyledTextField } from '../assets/loginStyles';

const initialState = { password: '', confirmPassword: '', showPassword: false }

const SetPasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const passwordReset = useSelector((state) => state.auth.passwordReset);
  const error = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.loading);

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: initialState
  });

  const showPassword = watch('showPassword');
  const password = watch('password');

  const handleResetPassword = (data) => {
    let requestParameter = {
      email: email,
      password: data.password,
      password_confirmation: data.confirmPassword,
      token: urlToken,
    };
    dispatch(resetPassword(requestParameter));
  };

  const urlParams = new URL(window.location.href).searchParams;
  const urlToken = urlParams.get('token');
  const email = urlParams.get('email');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (passwordReset) {
      dispatch(clearAuthErrors())
    }
  }, [passwordReset, dispatch])



  if (!urlToken) {
    enqueueSnackbar('Please click on email link..!', { variant: 'error' });
    return navigate('/');
  }

  if (passwordReset) {
    enqueueSnackbar('Password has been set successfully.', { variant: 'success' });
    return navigate('/');
  }

  return (
    <form onSubmit={handleSubmit(handleResetPassword)}>
      <Grid container item style={{ marginTop: 25, gap: 20 }} xs={12} >
        {error && <Grid item xs={12}>
          <StyledErrorAlert fullWidth severity="error">
            {error}
          </StyledErrorAlert>
        </Grid>}
        <Grid item xs={12}>
          <Typography style={{ marginTop: '5px', fontSize: '18px', textAlign: 'center' }}>
            Reset your password
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontSize: '14px', fontWeight: '600' }}>Enter new password.</Typography>
          <Controller
            control={control}
            name="password"
            render={({ field, fieldState: { error } }) =>
              <StyledTextField
                {...field}
                fullWidth
                error={error}
                helperText={error?.message}
                placeholder="Password"
                size="small"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" >
                      <IconButton edge="end" size="large">
                        <LockIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            }
            rules={{
              required: 'Password field is required',
              validate: {
                minLength: (value) => value.length >= 8 || 'Password must be at least 8 characters'
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontSize: '14px', fontWeight: '600' }}>Confirm new password.</Typography>
          <Controller
            control={control}
            name="confirmPassword"
            render={({ field, fieldState: { error } }) =>
              <StyledTextField
                {...field}
                fullWidth
                error={error}
                helperText={error?.message}
                placeholder="Confirm Password"
                size="small"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" >
                      <IconButton edge="end" size="large">
                        <LockIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            }
            rules={{
              required: 'Confirm password field is required',
              validate: (value) => password === value || 'Confirm password does not match new password'
            }}
          />
          <ShowPasswordLink onClick={() => setValue('showPassword', !showPassword)}>{showPassword ? 'Hide' : 'Show'} Passwords</ShowPasswordLink>
        </Grid>



        <Grid item style={{ position: 'relative' }} xs={12}>
          <StyledLoginButton fullWidth disabled={loading} type="submit" variant="contained">
            Reset Password
          </StyledLoginButton>
          {loading && <StyledButtonLoading size={24} />}
        </Grid>
        <Grid item style={{ justifyContent: 'center', display: 'flex' }} xs={12}>
          <StyledLink to="/login">
            <StyledArrowIcon /> Go back to sign in screen
          </StyledLink>
        </Grid>
      </Grid>
    </form >
  );
};

export default SetPasswordForm;
