import { axiosRequestHandler } from 'helpers/apiHelper';

/** Fetch companies contacts
 * @param {*} requestParameters
 * @param {Number} requestParameters.companyId
 * @return {Promise}
 */
export const fetchCompanyContactsAPI = async ({ companyId }) => {
  try {
    return await axiosRequestHandler(`companies/${companyId}/contacts`, 'get');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/** Fetch a single companies contact
 * @param {*} requestParameters
 * @param {Number} requestParameters.companyId
 * @param {Number} requestParameters.contactId
 * @return {Promise}
 */
export const fetchCompanyContactAPI = async ({ companyId, contactId }) => {
  try {
    return await axiosRequestHandler(`companies/${companyId}/contacts/${contactId}`, 'get');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/** Create a single companies contact
 * @param {*} requestParameters
 * @param {Number} requestParameters.companyId
 * @param {*} requestParameters.contact
 * @return {Promise}
 */
export const createCompanyContactAPI = async ({ companyId, contact }) => {
  try {
    return await axiosRequestHandler(`companies/${companyId}/contacts`, 'post', contact);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/** Update a single companies contact
 * @param {*} requestParameters
 * @param {Number} requestParameters.companyId
 * @param {Number} requestParameters.contactId
 * @param {*} requestParameters.contact
 * @return {Promise}
 */
export const updateCompanyContactAPI = async ({ companyId, contactId, contact }) => {
  try {
    return await axiosRequestHandler(
      `companies/${companyId}/contacts/${contactId}`,
      'patch',
      contact
    );
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/** Assign contact to project
 * @param {*} requestParameters
 * @param {Number} requestParameters.projectId
 * @param {[Number]} requestParameters.contacts
 * @return {Promise}
 */
export const assignContactToProjectAPI = async ({ projectId, contacts }) => {
  try {
    return await axiosRequestHandler(`projects/${projectId}/contacts`, 'post', { contacts });
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/** Remove contact to project
 * @param {*} requestParameters
 * @param {Number} requestParameters.projectId
 * @param {Number} requestParameters.companyId
 * @param {[Number]} requestParameters.contacts
 * @return {Promise}
 */
export const removeContactFromProjectAPI = async ({ projectId, contacts }) => {
  try {
    return await axiosRequestHandler(`projects/${projectId}/contacts`, 'delete', { contacts });
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

export const toggleCompanyContactReportAPI = async ({ projectId, contactId }) => {
  return await axiosRequestHandler(
    `projects/${projectId}/contacts/${contactId}/toggle_send_report`,
    'post'
  );
};

/** Delete a single companies contact
 * @param {*} requestParameters
 * @param {Number} requestParameters.companyId
 * @param {Number} requestParameters.contactId
 * @return {Promise}
 */
export const deleteCompanyContactAPI = async ({ companyId, contactId }) => {
  try {
    return await axiosRequestHandler(
      `companies/${companyId}/contacts/${contactId}/force`,
      'delete'
    );
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/** Archive a single companies contact
 * @param {*} requestParameters
 * @param {Number} requestParameters.companyId
 * @param {Number} requestParameters.contactId
 * @return {Promise}
 */
export const archiveCompanyContactAPI = async ({ companyId, contactId }) => {
  try {
    return await axiosRequestHandler(`companies/${companyId}/contacts/${contactId}`, 'delete');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/** Restore a single companies contact
 * @param {*} requestParameters
 * @param {Number} requestParameters.companyId
 * @param {Number} requestParameters.contactId
 * @return {Promise}
 */
export const restoreCompanyContactAPI = async ({ companyId, contactId }) => {
  try {
    return await axiosRequestHandler(
      `companies/${companyId}/contacts/${contactId}/restore`,
      'patch'
    );
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};
