import moment from 'moment';
import ganttStore from './ganttStore';

export const scaleConfig = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  return {
    Days: {
      scale_height: 35,
      min_column_width: 35,
      max_column_width: 35,
      scales: [
        {
          unit: 'week',
          step: 1,
          format: function (date) {
            const weekStart = gantt.date.week_start(date);
            const weekEnd = gantt.date.add(gantt.date.add(weekStart, 1, 'week'), -1, 'day');
            const projectStart = gantt?.constants?.project?.start_date;
            const weekNumber = projectStart
              ? Math.ceil(
                  (weekStart.getTime() - projectStart.getTime()) / 604800000 // MS in week
                )
              : gantt.date.getWeek(date);
            const formatWeekRange = gantt.date.date_to_str('%M-%d');
            return (
              'Week ' +
              weekNumber +
              ' ' +
              formatWeekRange(weekStart) +
              ' - ' +
              formatWeekRange(weekEnd)
            );
          },
        },
        {
          unit: 'day',
          step: 1,
          format: function (date) {
            const formatDaySpelledOut = gantt.date.date_to_str('%D');
            const formatDayNumber = gantt.date.date_to_str('%d');
            const daySpelledOut = formatDaySpelledOut(date);
            const dayAbbreviation =
              daySpelledOut[0] === 'S' || daySpelledOut[0] === 'T'
                ? daySpelledOut.substring(0, 2)
                : daySpelledOut[0];
            return dayAbbreviation + ' ' + formatDayNumber(date);
          },
          css: function (date) {
            if (!gantt.isWorkTime({ date: date, unit: 'day' })) {
              return 'weekend';
            }
            return '';
          },
        },
      ],
    },
    Weeks: {
      scale_height: 35,
      min_column_width: 120,
      max_column_width: 120,
      scales: [
        {
          unit: 'month',
          step: 1,
          format: '%M',
        },
        {
          unit: 'week',
          step: 1,
          format: (date) =>
            'Week ' +
            Math.ceil((date.getTime() - gantt.constants.project.start_date.getTime()) / 604800000),
        },
      ],
    },
    Months: {
      scale_height: 35,
      min_column_width: 200,
      max_column_width: 200,
      scales: [
        {
          unit: 'year',
          step: 1,
          format: '%Y',
        },
        {
          unit: 'month',
          step: 1,
          format: '%M',
        },
      ],
    },
    Quarters: {
      scale_height: 35,
      min_column_width: 250,
      max_column_width: 250,
      scales: [
        {
          unit: 'year',
          step: 1,
          format: '%Y',
        },
        {
          unit: 'quarter',
          step: 1,
          format: (date) => {
            return `Q${moment(date).quarter()}`;
          },
        },
      ],
    },
    Years: {
      scale_height: 35,
      min_column_width: 300,
      max_column_width: 300,
      scales: [
        {
          unit: 'year',
          step: 5,
          format: function (date) {
            const formatDate = gantt.date.date_to_str('%Y');
            const endDate = gantt.date.add(date, 5, 'year');
            return formatDate(date) + ' - ' + formatDate(endDate);
          },
        },
        { unit: 'year', step: 1, format: '%Y' },
      ],
    },
  };
};

export const ganttDefaultZoomConfig = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  const scales = scaleConfig(ganttId);
  return {
    levels: [
      {
        name: 'years',
        ...scales['Years'],
      },
      { name: 'quarters', ...scales['Quarters'] },
      {
        name: 'months',
        ...scales['Months'],
      },
      {
        name: 'weeks',
        ...scales['Weeks'],
      },

      {
        name: 'days',
        ...scales['Days'],
      },
    ],
    activeLevelIndex: 4,
    useKey: 'ctrlKey',
    trigger: 'wheel',
    element: function () {
      return gantt.$root.querySelector('.gantt_task');
    },
  };
};

export const setZoomConfig = (zoomConfig, ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  if (zoomConfig) {
    gantt.ext.zoom.init(zoomConfig(ganttId));
  } else {
    gantt.ext.zoom.init(ganttDefaultZoomConfig(ganttId));
  }
};
