import { NoDataAvailableComponent } from '@blackhyve/common';
import { Box, LinearProgress, Stack } from '@mui/material';
import { EditContext } from 'components/common/v3/DisplayWithEditAccess';
import SkeletonListComponent from 'components/common/v3/SkeletonListComponent';
import isEmpty from 'lodash/isEmpty';
import { memo, useContext } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useGetLbsQuery, useReorderLocationMutation } from '../store/location.api';
import LocationRow from './LocationRow';

const LocationList = ({ projectId }) => {
  const canEdit = useContext(EditContext);
  const { data: locations = [], isLoading, isFetching } = useGetLbsQuery({ projectId });
  const [reorderLocation, { isLoading: isGettingReordered }] = useReorderLocationMutation();

  const handleUpdateLocationPriority = (data) => {
    if (isEmpty(data.destination)) {
      return false;
    }
    const parentId = parseInt(data.destination.droppableId.split('-')[1]);
    const locationIds = locations.map((location) => location.id);
    const destinationIndex = data.destination.index;
    const sourceIndex = data.source.index;
    const id = locationIds.splice(sourceIndex, 1);
    locationIds.splice(destinationIndex, 0, ...id);
    reorderLocation({
      locations: locationIds,
      project_id: parentId,
      sourceIndex,
      destinationIndex,
    });
  };

  return (
    <DragDropContext onDragEnd={handleUpdateLocationPriority}>
      {isGettingReordered ||
        (!isLoading && isFetching && (
          <Box sx={{ width: '100%', position: 'absolute', top: '0', left: '0' }}>
            <LinearProgress />
          </Box>
        ))}
      {isLoading ? (
        <SkeletonListComponent noOfRows={15} />
      ) : (
        <Droppable droppableId={'project-' + projectId} type={'LOCATION-' + projectId}>
          {(provided) => (
            <Stack spacing={3} {...provided.droppableProps} ref={provided.innerRef}>
              {locations && locations.length > 0 ? (
                locations.map((location, index) => {
                  return (
                    <Draggable
                      draggableId={`location-${location?.id}`}
                      index={index}
                      isDragDisabled={!canEdit || isFetching || isGettingReordered}
                      key={`${location?.id}-zone`}
                    >
                      {(provided) => (
                        <div
                          key={`location-${location?.id}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{ ...provided.draggableProps.style }}
                        >
                          <LocationRow
                            dragHandleProps={provided.dragHandleProps}
                            location={location}
                            projectId={projectId}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })
              ) : (
                <NoDataAvailableComponent />
              )}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      )}
    </DragDropContext>
  );
};

export default memo(LocationList);
