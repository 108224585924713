import SqftIconComponent from '@blackhyve/common/src/icons/SqftIcon';
import { Button, Paper, Skeleton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { EditContext } from 'components/common/v3/DisplayWithEditAccess';
import { times } from 'lodash';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import AddEditLbsDialog from '../components/AddEditLbsDialog';
import LbsMoreOptions from '../components/LbsMoreOptions';
import ZoneCard, { ZoneMapCardSkeleton } from '../components/ZoneCard';
import {
  useDeleteLocationMutation,
  useDuplicateLocationMutation,
  useGetLbsQuery,
  useUpdateLocationMutation,
} from '../store/location.api';
import { useCreateZoneMutation } from '../store/zone.api';
import { getCroppedImg } from '../utils/cropImageHelper';

/**
 * LBS Map View
 * @returns HTML
 */
const LbsMapView = () => {
  const projectId = parseInt(useParams().projectId);

  const { data: locations = [], isLoading, isFetching } = useGetLbsQuery({ projectId });

  return (
    <>
      {!isLoading && isFetching && (
        <Box sx={{ width: '100%', position: 'absolute', top: '0', left: '0' }}>
          <LinearProgress />
        </Box>
      )}
      <Stack spacing={3}>
        {isLoading
          ? times(3, (index) => <LocationSkeleton key={index} />)
          : locations?.map((location) => {
              return <Location key={location.id} location={location} projectId={projectId} />;
            })}
      </Stack>
    </>
  );
};

/**
 * Location Component
 * @param {LocationObject} location
 * @returns
 */
const Location = ({ location, projectId }) => {
  const canEdit = useContext(EditContext);
  const [isAddZoneDialogOpen, setIsAddZoneDialogOpen] = useState(false);

  const [deleteLocation, { isLoading: isDeleting }] = useDeleteLocationMutation();
  const [updateLocation, { isLoading: isUpdating }] = useUpdateLocationMutation();
  const [duplicateLocation, { isLoading: isDuplicating }] = useDuplicateLocationMutation();
  const [createZone, { isLoading: isCreatingZone, isError: isErrorCreatingZone }] =
    useCreateZoneMutation();

  const zones = location?.zones || [];

  const handleCreateZone = async (zone) => {
    let croppedImage = undefined;
    if (zone.original_map) {
      croppedImage = await getCroppedImg(
        URL.createObjectURL(zone.original_map),
        zone.map_view_data.croppedAreaPixels,
        zone.map_view_data.rotate,
        zone.original_map.name
      );
    }

    createZone({
      locationId: location.id,
      zone: {
        ...zone,
        map_view_data: JSON.stringify(zone.map_view_data),
        map: croppedImage?.croppedImageFile,
      },
    })
      .unwrap()
      .then(() => {
        setIsAddZoneDialogOpen(false);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleDeleteLocation = () => deleteLocation({ locationId: location?.id, projectId });

  const handleSaveLocation = (data) =>
    updateLocation({ locationId: location?.id, projectId, location: data });

  const handleDuplicate = () => duplicateLocation({ locationId: location?.id, projectId });

  return (
    <Stack as={Paper} elevation={2} p={3} spacing={1}>
      <Grid container alignItems={'center'}>
        <Typography sx={{ fontWeight: 'bold' }} variant="h6">
          {location?.name}
        </Typography>
        <Grid item mx={1}>
          {location?.sqft ? (
            <Box alignItems={'center'} display={'flex'}>
              <SqftIconComponent style={{ marginRight: '5px' }} />
              <Typography>{location?.sqft}</Typography>
            </Box>
          ) : (
            '-'
          )}
        </Grid>
        {canEdit && (
          <>
            <Grid item>
              <Button onClick={() => setIsAddZoneDialogOpen(true)}>+ Add zone</Button>
            </Grid>

            <Box ml={'auto'}>
              <LbsMoreOptions
                entityObj={location}
                handleDelete={handleDeleteLocation}
                handleDuplicate={handleDuplicate}
                handleSave={handleSaveLocation}
                id={location?.id}
                isLoading={isDeleting || isUpdating || isDuplicating}
                type="location"
              />
            </Box>
          </>
        )}
      </Grid>
      <ZoneComponent locationId={location.id} projectId={projectId} zones={zones} />
      {isAddZoneDialogOpen && (
        <AddEditLbsDialog
          isMapUploadEnabled
          handleClose={() => setIsAddZoneDialogOpen(false)}
          handleSave={handleCreateZone}
          isError={isErrorCreatingZone}
          isSaving={isCreatingZone}
          open={isAddZoneDialogOpen}
          type={'Zone'}
        />
      )}
    </Stack>
  );
};

/**
 * Zone component
 * @param {String} locationId
 * @returns
 */
const ZoneComponent = ({ zones, locationId, projectId }) => {
  return (
    <Grid item position={'relative'} xs={12}>
      <Grid container spacing={2}>
        {zones?.length ? (
          zones?.map((zone) => (
            <Grid item key={zone?.id} md={4} sm={6} xs={12}>
              <ZoneCard locationId={locationId} projectId={projectId} zone={zone} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography color="textSecondary">No zones added</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export const LocationSkeleton = () => {
  return (
    <Grid container as={Paper} bgcolor={'white'} elevation={2} gap={1} p={3}>
      <Grid container item alignItems={'center'} xs={12}>
        <Typography component="div" sx={{ flexGrow: 1 }} variant="h6">
          <Skeleton variant="text" width={200} />
        </Typography>
      </Grid>
      <Grid container item spacing={2} xs={12}>
        {[...Array(3)].map((_, index) => (
          <Grid item key={index} minWidth={'min(450px, 100%)'} xs={4}>
            <ZoneMapCardSkeleton />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default LbsMapView;
