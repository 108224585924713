const ganttTaskFormatter = require('./ganttTaskFormatter');
const taskSchedulerEvents = require('./taskSchedulerEvents');
const topologicalSort = require('./topologicalSort');
const updateCriticalPath = require('./updateCriticalPath');
const updateFloat = require('./updateFloat');
const updateForecastedDates = require('./updateForecastedDates');
const updateTaskProperties = require('./updateTaskProperties');
const addGanttCalendar = require('./addGanttCalendar');

module.exports = {
  ...ganttTaskFormatter,
  ...taskSchedulerEvents,
  ...topologicalSort,
  ...topologicalSort,
  ...updateCriticalPath,
  ...updateFloat,
  ...updateForecastedDates,
  ...updateTaskProperties,
  ...addGanttCalendar
};
