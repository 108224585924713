import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';



export const Container = styled('div')(() => ({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#231f20'
}));

export const LoginFormContainer = styled(Grid)(() => ({
  backgroundColor: '#353535',
  borderRadius: '4px',
  color: '#fff',
  padding: '40px',
  width: '450px',
}));

export const StyledTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#fff'
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#dd4d42',
    fontWeight: '600',
    fontSize: '12px',
  },
  '& .MuiOutlinedInput-inputMultiline': {
    fontFamily: 'inherit'
  },
  '& .MuiInputBase-input.Mui-disabled': {
    cursor: 'not-allowed'
  }
}));

export const StyledLink = styled(NavLink)({
  color: '#4170be',
  textDecoration: 'underline',
  cursor: 'pointer',
  fontSize: 13,
});

export const StyledLoginButton = styled(Button)(() => ({
  backgroundColor: '#966E2A',
  color: '#fff',
  fontWeight: '500',
  '&:hover': {
    color: '#000',
    backgroundColor: '#e0e0e0'
  }
}));

export const ShowPasswordLink = styled(Typography)(() => ({
  color: '#4170be',
  textDecoration: 'underline',
  cursor: 'pointer',
  fontSize: 13,
  marginTop: '5px',
  display: 'inline-flex'
}));

export const StyledErrorAlert = styled(Alert)(() => ({
  paddingLeft: '5px',
  paddingRight: '5px',
  paddingTop: '2px',
  paddingBottom: '0px',
  '& .MuiAlert-icon': {
    padding: '5px 0px',
    fontSize: '22px',
    marginRight: '5px'
  }
}));

export const StyledButtonLoading = styled(CircularProgress)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
  color: '#fff'
}));

export const StyledFormLabel = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: '600'
}));

export const StyledHeadingLabel = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '600'
}));


export const StyledArrowIcon = styled(ArrowBackIcon)(() => ({
  fontSize: '14px',
  verticalAlign: 'middle'
}));