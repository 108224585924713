import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Assignment, Comment, Notifications } from '@mui/icons-material';
import { api } from 'api';
import Avatar from 'components/common/v3/Avatar';
import { CustomBadgeIcon } from 'features/activityFeeds/assets/activityFeedStyle';
import { selectCurrentUserRole, selectCurrentUserWorkspaceId } from 'features/auth';
import { formatNotificationDate } from 'features/notifications/utils/notificationHelper';
import { selectUserOrContactById } from 'features/users';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

/**
 * Notification list item
 * @param {String} id
 * @param {Object} queryParams
 * @param {Object} setSelectedNotification
 * @returns
 */
const NotificationLogListItem = ({ id, setSelectedNotification, queryParams }) => {
  const { notification } = api.endpoints.getWorkspaceNotificationLog.useQueryState(queryParams, {
    selectFromResult: ({ data: notifications, isFetching, isLoading }) => {
      return {
        notification: notifications?.data?.find((notification) => notification.id === id),
        isFetching,
        isLoading,
      };
    },
  });

  const notificationAudit = notification?.audit;

  const user = useSelector((state) => selectUserOrContactById(state, notification?.user_id));
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const userRole = useSelector((state) => selectCurrentUserRole(state));

  const actingUser = notificationAudit?.acting_user;

  const userName = user?.first_name ? `${user?.first_name} ${user?.last_name}` : 'Deleted User';
  const actingUserName = actingUser?.first_name
    ? `${actingUser?.first_name} ${actingUser?.last_name}`
    : 'Deleted User';

  const toUrl = notificationAudit?.task?.deleted_at
    ? undefined
    : userRole === 'contractor_contact'
      ? `/workspace/${workspaceId}/my-tasks?task=${notificationAudit?.task?.id}`
      : `/workspace/${workspaceId}/projects/${notification?.project_id}/planning-board/gantt?task=${notificationAudit?.task?.id}`;

  const { notificationTitle, badgeIcon } = getNotificationText(notification, userName, toUrl);
  const notificationStatusColor =
    notification?.inbox_read_at || notification?.email_read_at ? 'secondary' : 'red';

  const header = `${userName} was notified on ${formatNotificationDate(notification?.created_at)}`;
  const body = (
    <div>
      {actingUserName} {notificationTitle}
    </div>
  );

  return (
    <ListItem
      button
      style={{ alignItems: 'flex-start', borderBottom: '1px solid #e7e4e4' }}
      onClick={() => {
        setSelectedNotification(id);
      }}
    >
      <ListItemAvatar style={{ alignItems: 'flex-start', display: 'flex' }}>
        <CustomBadgeIcon badgeContent={badgeIcon} color={notificationStatusColor} overlap="circle">
          <Avatar
            firstName={user?.first_name || 'Deleted User'}
            icon={user?.profile_image?.thumb_url}
            lastName={user?.last_name}
            avatarStyle={{
              height: '40px',
              width: '40px',
              fontSize: '14px',
              marginRight: '5px',
            }}
          />
        </CustomBadgeIcon>
      </ListItemAvatar>
      <ListItemText>
        <Typography variant={'body1'}>{header}</Typography>
        <Typography variant={'body2'}>{body}</Typography>
      </ListItemText>
    </ListItem>
  );
};

export const getNotificationText = (notification, userName, taskUrl) => {
  const task = notification?.audit?.task;

  let notificationTitle = '';
  let badgeIcon = <Notifications fontSize={'inherit'} />;

  switch (notification?.type) {
    case 'AssignedTask':
      notificationTitle = (
        <>
          assigned task to <b>{userName}</b>
        </>
      );
      badgeIcon = <Assignment fontSize={'inherit'} />;
      break;
    case 'UnassignedTask':
      notificationTitle = (
        <>
          removed
          {notification?.audit?.old_values?.users
            ?.map((user) => {
              return ` ${user.first_name} ${user.last_name}`;
            })
            .join('')}{' '}
          from{' '}
          <Link color={taskUrl ? 'primary' : 'inherit'} component={NavLink} to={taskUrl}>
            {task?.name}
          </Link>
        </>
      );
      badgeIcon = <Assignment fontSize={'inherit'} />;
      break;
    case 'TaskUpdated':
      notificationTitle = (
        <>
          updated task{' '}
          <Link color={taskUrl ? 'primary' : 'inherit'} component={NavLink} to={taskUrl}>
            {task?.name}
          </Link>
        </>
      );
      break;
    case 'TaskStartDateChanged': {
      notificationTitle = (
        <>
          updated start date for{' '}
          <Link color={taskUrl ? 'primary' : 'inherit'} component={NavLink} to={taskUrl}>
            {task?.name}
          </Link>
        </>
      );
      break;
    }
    case 'NewComment':
      notificationTitle = (
        <>
          added a comment on{' '}
          <Link color={taskUrl ? 'primary' : 'inherit'} component={NavLink} to={taskUrl}>
            {task?.name}
          </Link>
        </>
      );
      badgeIcon = <Comment fontSize={'inherit'} />;

      break;
    default:
      notificationTitle = '';
      break;
  }

  return { notificationTitle, badgeIcon };
};

export default NotificationLogListItem;
