import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const GanttIcon = (props) => (
  <SvgIcon
    // width="24"
    // height="24"
    viewBox="0 0 18 18"
    // fill="currentColor"
    // xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11.7333 2.35V4.43333C11.7333 4.53333 11.7083 4.61667 11.6583 4.68333C11.6083 4.75 11.5444 4.78333 11.4667 4.78333H0.266667C0.188889 4.78333 0.125 4.75 0.075 4.68333C0.025 4.61667 0 4.53333 0 4.43333V2.35C0 2.25 0.025 2.16667 0.075 2.1C0.125 2.03333 0.188889 2 0.266667 2H11.4667C11.5444 2 11.6083 2.03333 11.6583 2.1C11.7083 2.16667 11.7333 2.25 11.7333 2.35ZM16 5.68333V7.76667C16 7.86667 15.975 7.95 15.925 8.01667C15.875 8.08333 15.8111 8.11667 15.7333 8.11667H4.53333C4.45556 8.11667 4.39167 8.08333 4.34167 8.01667C4.29167 7.95 4.26667 7.86667 4.26667 7.76667V5.68333C4.26667 5.59444 4.29167 5.51667 4.34167 5.45C4.39167 5.38333 4.45556 5.34444 4.53333 5.33333H15.7333C15.8111 5.34444 15.875 5.38333 15.925 5.45C15.975 5.51667 16 5.59444 16 5.68333ZM9.6 9.03333V11.1167C9.6 11.2111 9.57361 11.2931 9.52083 11.3625C9.46806 11.4319 9.40556 11.4667 9.33333 11.4667H2.4C2.33333 11.4667 2.27222 11.4306 2.21667 11.3583C2.16111 11.2861 2.13333 11.2056 2.13333 11.1167V9.03333C2.13333 8.93333 2.16111 8.85 2.21667 8.78333C2.27222 8.71667 2.33333 8.68333 2.4 8.68333H9.33333C9.41111 8.68333 9.475 8.71667 9.525 8.78333C9.575 8.85 9.6 8.93333 9.6 9.03333ZM13.8667 12.3667V14.45C13.8667 14.5444 13.8403 14.6264 13.7875 14.6958C13.7347 14.7653 13.6722 14.8 13.6 14.8H6.66667C6.6 14.8 6.53889 14.7639 6.48333 14.6917C6.42778 14.6194 6.4 14.5389 6.4 14.45V12.3667C6.4 12.2778 6.42778 12.1972 6.48333 12.125C6.53889 12.0528 6.6 12.0167 6.66667 12.0167H13.6C13.6722 12.0167 13.7347 12.0514 13.7875 12.1208C13.8403 12.1903 13.8667 12.2722 13.8667 12.3667Z" />
  </SvgIcon>
);

export default GanttIcon;
