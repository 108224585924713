import debounce from 'lodash/debounce';
import { useGetJWTQuery } from '../api/analytics';
import React, { useEffect, useState, createContext, useContext, useCallback } from 'react';
import tigerFactory, { TigerJwtAuthProvider } from '@gooddata/sdk-backend-tiger';
import { useSelector } from 'react-redux';
import { selectCurrentUserWorkspaceId } from 'features/auth';

const BackendContext = createContext(null);

export const useBackend = () => {
  return useContext(BackendContext);
};

const BackendProvider = ({ children }) => {
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const {
    data: jwt,
    isLoading,
    refetch: refetchJWT,
    isUninitialized,
  } = useGetJWTQuery({ workspaceId });

  const [backend, setBackend] = useState(null);

  const jwtIsAboutToExpire = useCallback(
    async (setJwt) => {
      if (!isUninitialized) {
        const { data: newJWT } = await refetchJWT({ workspaceId });
        setJwt(newJWT);
      }
    },
    [isUninitialized, refetchJWT, workspaceId]
  );

  useEffect(() => {
    if (jwt) {
      const notAuthenticatedHandler = debounce((context, error) => {
        console.error('Not authenticated:', error);
      }, 500);

      const secondsBeforeTokenExpirationToCallReminder = 60;

      const jwtAuthProvider = new TigerJwtAuthProvider(
        jwt,
        notAuthenticatedHandler,
        jwtIsAboutToExpire,
        secondsBeforeTokenExpirationToCallReminder
      );

      const newBackend = tigerFactory()
        .onHostname(process.env.REACT_APP_GOODDATA_URL)
        .withAuthentication(jwtAuthProvider);

      setBackend(newBackend);
    }
  }, [jwt, jwtIsAboutToExpire]);

  if (!backend || isLoading) {
    return <div>Loading...</div>;
  }

  return <BackendContext.Provider value={backend}>{children}</BackendContext.Provider>;
};

export default BackendProvider;
