import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { MoreVert } from '@mui/icons-material';
import { useState } from 'react';
import ganttStore from '../ganttStore';
import SelectBox from './menu/selectBox';

const ActionComponent = ({ item, isCheckBox, children, ganttId }) => {
  const gantt = ganttStore.getGantt(ganttId);
  const [contextMenuAnchor, setContextMenuAnchor] = useState(undefined);
  const handleOpenContextMenu = (event) => {
    if (item.type == gantt.config.types.placeholder) {
      return false;
    }
    if (item.$new) {
      gantt.message({
        type: 'info',
        text: 'Task is being is still being created, please wait ',
      });
      return false;
    }
    if (gantt.isTaskExists(item.id)) {
      setContextMenuAnchor(event.currentTarget);
    }
  };

  const handleCloseContextMenu = () => {
    setContextMenuAnchor(undefined);
  };
  const displayOrder =
    item.type === gantt.config.types.placeholder || item.$group_id
      ? ' '
      : item.id == -1
      ? '0'
      : item.$wbs.split('.').length > 1
      ? item.$wbs //? item.$wbs.split('.').slice(1).join('.')
      : '';

  return (
    <Grid container item alignItems={'center'} wrap={'nowrap'}>
      {isCheckBox && (
        <Grid item xs={'auto'}>
          <SelectBox
            id={item.id}
            isDisabled={item.id === -1}
            value={gantt.isSelectedTask(item.id)}
          />
        </Grid>
      )}
      <Grid item xs className="task-row-action-column">
        <Grid item className="task-row-hierarchy">
          {displayOrder ? displayOrder : item.$wbs}
        </Grid>
      </Grid>
      <Grid item xs={'auto'}>
        <IconButton
          aria-label={`${item.id}-more-action`}
          style={{ padding: 0 }}
          onClick={handleOpenContextMenu}
          size="large">
          <MoreVert fontSize={'small'} />
        </IconButton>
      </Grid>
      {contextMenuAnchor &&
        children({ anchor: contextMenuAnchor, handleClose: handleCloseContextMenu, id: item.id })}
    </Grid>
  );
};

export default ActionComponent;
