import { Box, Link, List, ListSubheader } from '@mui/material';
import { NavScrollBar } from 'components/navigation/NavScrollBar';
import { selectCurrentUserId, selectCurrentUserWorkspaceId } from 'features/auth';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useGetMyNotificationsQuery } from '../api/notification.api';
import { Notification, NotificationSkeleton } from './Notification';
import { times } from 'lodash';

const thirtySecondsInMS = 30000;
const emptyArray = [];
export const NotificationDrawer = () => {
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const currentUserId = useSelector((state) => selectCurrentUserId(state));

  const currentPage = useGetMyNotificationsQuery(
    { page: 1, userId: currentUserId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { isLoading, isFetching } = currentPage;
  const notifications = currentPage?.data?.data || emptyArray;
  const unreadCount = currentPage?.data?.meta?.unread_count;

  const { readNotifications, unreadNotifications } = useMemo(() => {
    const notificationsToDisplay = notifications.slice(0, 10);
    const unreadNotifications = notificationsToDisplay.slice(0, unreadCount);
    const readNotifications = notificationsToDisplay.slice(unreadCount);
    return { readNotifications, unreadNotifications };
  }, [notifications, unreadCount]);

  return (
    <NavScrollBar>
      <List disablePadding>
        <ListSubheader disableSticky color={'inherit'}>
          Unread
        </ListSubheader>
        {isLoading
          ? times(10).map((value, index) => <NotificationSkeleton key={index} />)
          : unreadNotifications.map((notification) => {
              return <Notification key={notification.id} notification={notification} />;
            })}
      </List>
      {readNotifications.length > 0 && (
        <List disablePadding>
          <ListSubheader disableSticky color={'inherit'}>
            Read
          </ListSubheader>
          {readNotifications.map((notification) => {
            return <Notification key={notification.id} notification={notification} />;
          })}
        </List>
      )}
      <Box p={2} textAlign={'center'}>
        <Link color={'primary'} component={NavLink} to={`/workspace/${workspaceId}/notifications`}>
          View All Notifications
        </Link>
      </Box>
    </NavScrollBar>
  );
};
