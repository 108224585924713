import { selectCurrentUserRole } from 'features/auth';
import { useGetProjectQuery } from 'features/projects/store/project.api';
import { useSelector } from 'react-redux';

export const useCanEditProject = (projectId, queryOptions) => {
  const { data: project } = useGetProjectQuery(projectId, {
    ...queryOptions,
  });

  const userRole = useSelector((state) => selectCurrentUserRole(state));

  const canEdit =
    userRole === 'super_admin' ||
    userRole === 'admin' ||
    projectId === undefined ||
    (project?.user_access_level
      ? project?.user_access_level === 'full_access'
      : project?.general_access_level === 'full_access');

  return canEdit;
};
