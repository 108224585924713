import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { workspaceAPI } from 'features/workspace/api/workspace.api';
import { cloneDeep } from 'lodash';
import {
  CreateRecipeTaskAPI,
  DeleteRecipeTaskAPI,
  DeleteRecipeTasksAPI,
  FetchRecipeTasksAPI,
  ReorderRecipeTasksAPI,
  UpdateRecipeTaskAPI,
  UpdateRecipeTasksAPI,
} from '../api/RecipeService';

/**
 * Fetch Recipe Tasks
 * @param {*} requestParameters
 * @param {Number} requestParameters.recipeId - Recipe id to fetch recipe tasks for
 */
export const fetchRecipeTasks = createAsyncThunk(
  'recipeTasks/fetchRecipeTasks',
  async ({ recipeId }, { rejectWithValue }) => {
    const apiResponse = await FetchRecipeTasksAPI({ recipeId });
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response?.message);
    } else {
      return apiResponse.response;
    }
  }
);

/**
 * Create Recipe Task
 * @param {*} requestParameter
 * * @param {Number} requestParameter.recipeId
 * * @param {String} requestParameter.name
 */
export const createRecipeTask = createAsyncThunk(
  'recipeTasks/createRecipeTask',
  async (requestParameters, { rejectWithValue }) => {
    const data = cloneDeep(requestParameters);
    delete data.id;
    const apiResponse = await CreateRecipeTaskAPI(data);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response?.message);
    } else {
      return {
        data: apiResponse.response.data,
        removeId: requestParameters.id,
        recipeId: requestParameters.recipeId,
      };
    }
  }
);

/**
 * Update Recipe Task
 * @param {*} requestParameter
 * * @param {Number} requestParameter.id
 * * @param {String} requestParameter.name
 */
export const updateRecipeTask = createAsyncThunk(
  'recipeTasks/updateRecipeTask',
  async (requestParameters, { rejectWithValue }) => {
    const apiResponse = await UpdateRecipeTaskAPI(requestParameters);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response?.message);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * Update Recipe Tasks
 * @param {[Object]} requestParameter
 * * @param {Number} requestParameter.id
 * * @param {String} requestParameter.name
 */
export const updateRecipeTasks = createAsyncThunk(
  'recipeTasks/updateRecipeTasks',
  async (requestParameters, { rejectWithValue }) => {
    const apiResponse = await UpdateRecipeTasksAPI(requestParameters);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response?.message);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * Delete Recipe Task
 * @param {*} requestParameter
 * * @param {Number} requestParameter.id
 * * @param {Number} requestParameter.parentId
 * * @param {String} requestParameter.parentType
 */
export const deleteRecipeTask = createAsyncThunk(
  'recipeTasks/deleteRecipeTask',
  async (requestParameters, { rejectWithValue }) => {
    const apiResponse = await DeleteRecipeTaskAPI(requestParameters);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response?.message);
    } else {
      return apiResponse.response;
    }
  }
);

/**
 * Delete Recipe Tasks
 * @param {*} requestParameter
 * * @param {Number} requestParameter.parentId
 * * @param {[Number]} requestParameter.ids
 */
export const deleteRecipeTasks = createAsyncThunk(
  'recipeTasks/deleteRecipeTasks',
  async (requestParameters, { rejectWithValue }) => {
    const apiResponse = await DeleteRecipeTasksAPI(requestParameters);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response?.message);
    } else {
      return apiResponse.response;
    }
  }
);

/**
 * Reorder Recipe Tasks
 * @param {*} requestParameter
 * @param {Number} requestParameter.id
 * @param {Boolean} requestParameter.sibling - Id of sibling
 * @param {Number} requestParameter.insertAfter - Boolean if ids should be inserted above sibling
 * @param {Number} requestParameter.recipeId - Id of recipe
 */
export const reorderRecipeTask = createAsyncThunk(
  'recipeTasks/reorderRecipeTask',
  async (requestParameters, { rejectWithValue, getState }) => {
    //For State One Not Allowing Nested tasks So This Is Temp!!!
    const recipe = getState().recipes.entities[requestParameters.recipeId];
    // Remove Id
    const newOrder = recipe.tasks.filter((recipeTaskId) => recipeTaskId !== requestParameters.id);
    //Find index of sibling if insert after add one so it inserts id after
    const insertIndex =
      newOrder.indexOf(requestParameters.sibling) + (requestParameters.insertAfter ? 1 : 0);
    newOrder.splice(insertIndex, 0, requestParameters.id);

    const apiResponse = await ReorderRecipeTasksAPI({
      recipeId: requestParameters.recipeId,
      tasks: newOrder,
    });

    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response?.message);
    } else {
      return newOrder;
    }
  }
);

export const recipeTaskAdapter = createEntityAdapter({
  //sortComparer: (prev, curr) => new Date(curr.created_at) - new Date(prev.created_at),
});

const initialState = recipeTaskAdapter.getInitialState({
  error: undefined,
});

const recipeTaskSlice = createSlice({
  name: 'recipeTasks',
  initialState,
  reducers: {
    addEmptyRecipeTask: (state, action) => {
      recipeTaskAdapter.addOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    /* Fetch recipe tasks */
    builder.addCase(fetchRecipeTasks.fulfilled, (state, action) => {
      recipeTaskAdapter.setAll(state, action.payload?.data);
    });

    /** Create Recipe Task */
    builder.addCase(createRecipeTask.pending, (state, action) => {
      recipeTaskAdapter.upsertOne(state, { id: action.meta?.arg?.id, loading: true });
    });
    builder.addCase(createRecipeTask.fulfilled, (state, action) => {
      recipeTaskAdapter.upsertOne(state, { ...action.payload.data, loading: false });
      recipeTaskAdapter.removeOne(state, action.payload.removeId);
    });
    builder.addCase(createRecipeTask.rejected, (state, action) => {
      recipeTaskAdapter.upsertOne(state, { id: action.meta?.arg?.id, loading: false });
    });

    /** Update Recipe Task */
    builder.addCase(updateRecipeTask.pending, (state, action) => {
      recipeTaskAdapter.upsertOne(state, { id: action.meta?.arg?.id, loading: true });
    });
    builder.addCase(updateRecipeTask.fulfilled, (state, action) => {
      recipeTaskAdapter.upsertOne(state, { ...action.payload, loading: false });
    });
    builder.addCase(updateRecipeTask.rejected, (state, action) => {
      recipeTaskAdapter.upsertOne(state, { id: action.meta?.arg?.id, loading: false });
    });

    /** Update Recipe Tasks */
    builder.addCase(updateRecipeTasks.fulfilled, (state, action) => {
      recipeTaskAdapter.upsertMany(state, action.payload);
    });

    /** Reorder */
    builder.addCase(reorderRecipeTask.rejected, (state, action) => {
      state.error = action.payload;
    });

    /** Delete Recipe Task */
    builder.addCase(deleteRecipeTask.pending, (state, action) => {
      recipeTaskAdapter.upsertOne(state, { id: action.meta?.arg?.id, loading: true });
    });
    builder.addCase(deleteRecipeTask.fulfilled, (state, action) => {
      recipeTaskAdapter.removeOne(state, action.meta?.arg?.id);
    });
    builder.addCase(deleteRecipeTask.rejected, (state, action) => {
      recipeTaskAdapter.upsertOne(state, { id: action.meta?.arg?.id, loading: false });
    });

    /** Delete Recipe Tasks In BULK */
    builder.addCase(deleteRecipeTasks.fulfilled, (state, action) => {
      if (action?.meta?.arg?.ids) {
        recipeTaskAdapter.removeMany(state, action?.meta?.arg?.ids);
      }
    });

    builder.addMatcher(workspaceAPI.endpoints.setWorkspace.matchFulfilled, (state, action) => {
      recipeTaskAdapter.removeAll(state);
    });
  },
});

export default recipeTaskSlice.reducer;

/** Selectors **/

export const {
  selectById: selectRecipeTaskById,
  selectIds: selectRecipeTaskIds,
  selectEntities: selectRecipeTaskEntities,
  selectAll: selectAllRecipeTask,
  selectTotal: selectTotalRecipeTask,
} = recipeTaskAdapter.getSelectors((state) => state.recipeTasks);

export const { addEmptyRecipeTask } = recipeTaskSlice.actions;
