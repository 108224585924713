import { api, providesList } from 'api';
import { BUDGET_LINE_KEY } from './budget-lines.api';

export const CHANGE_ORDER_LINE_KEY = 'ChangeOrderLine';

const changeOrderLinesAPI = api
  .enhanceEndpoints({ addTagTypes: [CHANGE_ORDER_LINE_KEY] })
  .injectEndpoints({
    endpoints: (build) => ({
      getChangeOrderLinesByContract: build.query({
        query: ({ contractId }) => `/contracts/${contractId}/change-order-lines`,
        providesTags: (result, error, args) =>
          result ? providesList(result, CHANGE_ORDER_LINE_KEY) : [],
        transformResponse: (response) => response.data,
      }),
      createChangeOrderLine: build.mutation({
        query: ({ contractId, ...body }) => ({
          url: `/contracts/${contractId}/change-order-lines`,
          method: 'POST',
          body: body,
        }),
        invalidatesTags: (result, _error, params) =>
          result
            ? [
                { type: CHANGE_ORDER_LINE_KEY, id: 'LIST' },
                { type: 'Contract', id: 'LIST' },
                { type: 'Budget' },
                { type: BUDGET_LINE_KEY },
                { type: 'BudgetForecast' },
                { type: 'ProjectForecast' },
              ]
            : [],
      }),
      updateChangeOrderLine: build.mutation({
        query: ({ changeOrderLineId, ...body }) => ({
          url: `/change-order-lines/${changeOrderLineId}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: (result, _error, params) =>
          result
            ? [
                { type: CHANGE_ORDER_LINE_KEY, id: 'LIST' },
                { type: CHANGE_ORDER_LINE_KEY, id: params.changeOrderLineId },
                { type: BUDGET_LINE_KEY },
                { type: 'Contract', id: 'LIST' },
                { type: 'Budget' },
                { type: 'BudgetForecast' },
                { type: 'ProjectForecast' },
              ]
            : [],
      }),
      deleteChangeOrderLine: build.mutation({
        query: ({ line }) => ({
          url: `/change-order-lines/${line.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_result, error, params) =>
          error
            ? []
            : [
                { type: CHANGE_ORDER_LINE_KEY, id: 'LIST' },
                { type: BUDGET_LINE_KEY },
                { type: 'Contract', id: 'LIST' },
                { type: 'Budget' },
                { type: 'BudgetForecast' },
                { type: 'ProjectForecast' },
              ],
      }),
    }),
  });

export const {
  useGetChangeOrderLinesByContractQuery,
  useCreateChangeOrderLineMutation,
  useUpdateChangeOrderLineMutation,
  useDeleteChangeOrderLineMutation,
} = changeOrderLinesAPI;
