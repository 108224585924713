import { useState } from 'react';
import Menu from '@mui/material/Menu';
import Check from '@mui/icons-material/Check';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { MenuButton } from 'assets/style-components/button';
import { CheckBoxContainer, StyledMenuName } from 'features/activityFeeds/assets/activityFeedStyle';

/**
 * Feed type menu
 * @param {string} selectedType
 * @param {object} handleSelectType
 * @param {[object]} filterOptions
 * @param {string} label
 * @returns
 */
const FeedTypeMenu = ({ selectedType, handleSelectType, filterOptions, label }) => {
  const [openMenu, setOpenMenu] = useState(null);

  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleClose = () => {
    setOpenMenu(null);
  };

  const handleSelect = (event, option) => {
    handleSelectType(option.value);
    handleClose();
  };

  return (
    <>
      <MenuButton onClick={handleClick}>
        {label}
        {Boolean(openMenu) ? <ArrowDropUp /> : <ArrowDropDown />}
      </MenuButton>
      <Menu
        anchorEl={openMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        getContentAnchorEl={null}
        open={Boolean(openMenu)}
        style={{ maxHeight: '400px', width: '350px' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
      >
        {filterOptions?.map((option) => {
          return (
            <MenuItem key={option.name} onClick={(event) => handleSelect(event, option)}>
              <CheckBoxContainer>
                {selectedType === option.value ? (
                  <Check color={'action'} style={{ display: 'block' }} />
                ) : null}
              </CheckBoxContainer>
              <StyledMenuName>{option.name}</StyledMenuName>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default FeedTypeMenu;
