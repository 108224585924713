import { api, providesList } from 'api';
import { CONTRACT_LINE_KEY } from './contract-lines.api';
import { CHANGE_ORDER_LINE_KEY } from './change-order-lines.api';

const invoicesAPI = api.enhanceEndpoints({ addTagTypes: ['Invoice'] }).injectEndpoints({
  endpoints: (build) => ({
    getInvoicesByContract: build.query({
      query: ({ contractId }) => `/contracts/${contractId}/invoices`,
      providesTags: (result, error, args) => (result ? providesList(result, 'Invoice') : []),
      transformResponse: (response) => response.data,
    }),
    getBillingPeriodsByContract: build.query({
      query: ({ contractId }) => `/contracts/${contractId}/billings`,
      providesTags: (result, error, args) => (result ? providesList(result, 'Invoice') : []),
      transformResponse: (response) => response.data.map((item) => ({ ...item, id: item.period })),
    }),
    createInvoice: build.mutation({
      query: ({ contractId, ...body }) => ({
        url: `/contracts/${contractId}/invoices`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, params) =>
        result
          ? [
              { type: 'Invoice', id: 'LIST' },
              { type: 'Budget' },
              { type: 'Contract', id: 'LIST' },
              { type: 'Contract', id: params.contractId },
              { type: CONTRACT_LINE_KEY, id: 'LIST' },
              { type: CHANGE_ORDER_LINE_KEY, id: 'LIST' },
            ]
          : [],
    }),
    getInvoiceById: build.query({
      query: ({ id }) => `/invoices/${id}`,
      providesTags: (result, error, { id }) => [{ type: 'Invoice', id: id }],
      transformResponse: (response) => response.data,
    }),
    updateInvoiceById: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/invoices/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: 'Invoice', id: id },
              { type: 'Invoice', id: 'LIST' },
            ]
          : [],
    }),
    uploadInvoiceFiles: build.mutation({
      query: ({ id, formData }) => ({
        url: `/invoices/${id}/files`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: (result, error, { id }) => (result ? [{ type: 'Invoice', id }] : []),
    }),
    deleteInvoiceFile: build.mutation({
      query: ({ id, file }) => ({
        url: `/invoices/${id}/files/${file}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => {
        return [{ type: 'Invoice', id }];
      },
    }),
  }),
});

export const {
  useGetInvoicesByContractQuery,
  useGetBillingPeriodsByContractQuery,
  useGetInvoiceByIdQuery,
  useCreateInvoiceMutation,
  useUpdateInvoiceByIdMutation,
  useUploadInvoiceFilesMutation,
  useDeleteInvoiceFileMutation,
} = invoicesAPI;
