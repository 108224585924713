import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { ActionButtons } from 'features/gantt/components/TaskDetailDrawer';



const TaskBulkEditDurationMenuItem = ({ handleUpdate }) => {
  const [anchorEl, setAnchorEl] = useState(undefined);

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <>
      <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
        Duration
        {anchorEl ? (
          <ArrowDropDown sx={{ fontSize: 18 }} />
        ) : (
          <ArrowDropUp sx={{ fontSize: 18 }} />
        )}
      </Button>
      {anchorEl ? (
        <DurationMenu anchorEl={anchorEl} handleClose={handleClose} handleUpdate={handleUpdate} />
      ) : null}
    </>
  );
};

/**
 *
 * @param {*} props
 * @param {HTMLElement} props.anchorEl - Anchor element for menu
 * @param {Function} props.handleClose - Handle closing of the menu
 * @param {Function} props.handleUpdate - Function for handling updates
 * @returns {JSX}
 */
const DurationMenu = ({ anchorEl, handleClose, handleUpdate }) => {
  const [duration, setDuration] = useState(0);
  const [durationType, setDurationType] = useState('workdays');

  const handleSave = () => {
    handleUpdate({ duration, durationType });
    handleClose();
  };

  const handleSelectOption = (event) => {
    setDurationType(event.target.value);
  };

  const handleOnChange = (event) => {
    setDuration(event.target.value);
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      onClose={handleClose}
    >
      <Paper style={{ padding: 15 }}>
        <Grid container direction="column" spacing={1}>
          <Grid item xs={12}>
            <RadioGroup value={durationType} onChange={handleSelectOption}>
              <FormControlLabel control={<Radio />} label="Work Days" value="workdays" />
              <FormControlLabel control={<Radio />} label="Calendar Days" value="calendardays" />
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              fullWidth
              name="duration"
              placeholder="Work Days"
              size="small"
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
              value={duration}
              variant="outlined"
              onChange={handleOnChange}
            />
          </Grid>
          <ActionButtons handleClose={handleClose} handleUpdate={handleSave} />
        </Grid>
      </Paper>
    </Popover>
  );
};

export default TaskBulkEditDurationMenuItem;
