const { getChildren } = require('./getChildren');

/**
 * Return if task has children
 * @param {Number} taskId
 * @param {Gantt} gantt
 * @returns {Boolean}
 */
function hasChild(taskId, gantt) {
  return getChildren(taskId, gantt)?.length > 0;
}

module.exports = { hasChild };
