import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { forceLogout, tokenReceived } from 'features/auth';
import { Mutex } from 'async-mutex';
import { forceLogout, tokenReceived } from 'features/auth/store/extraActions';
import { getSocket } from 'helpers/websockets';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_SERVER_URL}/api/`,
  prepareHeaders: (headers, { getState }) => {
    headers.set('Accept', 'application/json');
    // By default, if we have a token in the store, let's use that for authenticated requests
    const { authToken, workspaceId } = getState().auth;
    if (authToken) {
      headers.set('Authorization', `Bearer ${authToken}`);
    }

    // If we have workspace Id add X-Tenant
    if (workspaceId) {
      headers.set('X-Tenant', workspaceId);
    }

    // Can be provided per query.
    // {url: 'xxx',headers: { 'X-Socket-ID': getSocket()?.socketId() }},
    const socket = getSocket();
    if (socket && socket?.socketId()) {
      headers.set('X-Socket-ID', socket?.socketId());
    }

    return headers;
  },
});

const mutex = new Mutex();
const baseQueryWithReauth = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          { url: '/refresh', method: 'POST', body: { refresh_token: localStorage.getItem('refreshToken') } },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          api.dispatch(tokenReceived(refreshResult.data));
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(forceLogout());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});

/**
 * Create list of tags for use with RTK query
 * @param {[Object]} resultsWithIds
 * @param {String} tagType
 * @param {String} idField
 * @returns {[Object]}
 */
export function providesList(resultsWithIds = [], tagType, idField = 'id') {
  return resultsWithIds
    ? [
      { type: tagType, id: 'LIST' },
      ...resultsWithIds.map((item) => ({
        type: tagType,
        id: typeof item === 'object' ? item[idField] : item,
      })),
    ]
    : [{ type: tagType, id: 'LIST' }];
}
