import CompanyIcon from '@blackhyve/common/src/icons/CompanyIcon';
import { Star } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { selectCurrentUserWorkspaceId } from 'features/auth';
import { MoreOptions } from 'features/companies/components/MoreOptions';
import { times } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

export const CompanyNavList = ({ companies, isLoading }) => {
  return (
    <List>
      {isLoading ? (
        times(10, (index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <Skeleton height={'1.5em'} variant="circular" width={'1.5em'} />
            </ListItemIcon>
            <ListItemText disableTypography>
              <Skeleton variant="rectangular" />
            </ListItemText>
          </ListItem>
        ))
      ) : companies && companies.length > 0 ? (
        companies.map((company) => {
          return <CompanyDrawerListRow company={company} key={company.id} />;
        })
      ) : (
        <ListItem>
          <Alert severity={'warning'} sx={{ width: '100%' }}>
            No Companies Found
          </Alert>
        </ListItem>
      )}
    </List>
  );
};

/**
 * Project row
 * @param {number} id
 * @param {boolean} isSelected
 * @returns
 */
const CompanyDrawerListRow = ({ company }) => {
  const navigate = useNavigate();
  const { companyId: selectedCompanyId } = useParams();
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));

  const isDeleted = company?.deleted_at;

  return (
    <ListItem disablePadding secondaryAction={<MoreOptions company={company} />}>
      <ListItemButton
        disabled={isDeleted}
        selected={selectedCompanyId == company.id}
        onClick={() => navigate(`/workspace/${workspaceId}/companies/${company.id}`)}
      >
        <ListItemAvatar>
          <Avatar alt={'company logo'} src={company?.logo?.thumb_url} sx={{ color: '#fff' }}>
            <CompanyIcon />
          </Avatar>
        </ListItemAvatar>
        <Tooltip
          arrow
          aria-label={company?.name}
          enterDelay={500}
          enterNextDelay={500}
          leaveDelay={0}
          placement={'top'}
          PopperProps={{ disablePortal: true }}
          title={company?.name}
        >
          <ListItemText
            disableTypography
            primary={<Typography>{company?.name}</Typography>}
            secondary={
              company.workspace_owner ? (
                <Chip icon={<Star />} label={'Workspace Owner'} size={'small'} />
              ) : undefined
            }
          />
        </Tooltip>
      </ListItemButton>
    </ListItem>
  );
};
