import { api, providesList } from 'api';

export const activityFeedApi = api
  .enhanceEndpoints({ addTagTypes: ['AuditNotification', 'ActivityFeed'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getActivityFeeds: build.query({
        query: (queryParam) => {
          const queryString = Object.entries(queryParam)
            .filter(([key, value]) => value !== undefined)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
          return `/activity_feed?${queryString}`;
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          const newQueryArgs = { ...queryArgs };
          if (newQueryArgs.hasOwnProperty('page')) {
            delete newQueryArgs.page;
          }
          return newQueryArgs;
        },
        // keepUnusedDataFor: 1,
        merge: (currentCache, newItems, queryArgs) => {
          if (queryArgs?.arg?.page) {
            currentCache.data.push(...newItems.data);
          } else {
            currentCache.data = newItems.data;
          }
          currentCache.current_page = newItems.current_page;
          currentCache.last_page = newItems.last_page;
        },
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
        transformResponse: (response) => {
          return {
            data: response.data,
            current_page: response?.meta?.current_page,
            last_page: response?.meta?.last_page,
          };
        },
      }),
      getActivityFeedNotifications: build.query({
        query: (id) => `audits/${id}/notifications`,
        transformResponse: (response) => {
          return response.data;
        },
        providesTags: (result) => providesList(result, 'AuditNotification'),
      }),
    }),
  });
export const {
  useGetActivityFeedsQuery,
  useLazyGetActivityFeedsQuery,
  useGetActivityFeedNotificationsQuery,
} = activityFeedApi;
