const { getChildren } = require('./getChildren');
const { hasChild } = require('./hasChild');

function calculateTaskStatus(task, gantt) {
  if (hasChild(task.id, gantt)) {
    const children = getChildren(task.id, gantt).filter((task) => task.type !== 'placeholder');
    const status = children[0].status;
    return children.every((child) => child.status === status) ? status : 'active';
  } else {
    if (parseFloat(task.progress) === 1) {
      return 'complete';
    } else if (parseFloat(task.progress) === 0 && !task.actual_start_date) {
      return 'todo';
    } else {
      return 'active';
    }
  }
}

module.exports = { calculateTaskStatus };
