import { useState } from 'react';
import Chip from '@mui/material/Chip';
import format from 'date-fns/format';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Check from '@mui/icons-material/Check';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { parseDate } from '@blackhyve/utilities/dates';
import { FormLabel, LinearProgress } from '@mui/material';
import differenceInDays from 'date-fns/differenceInDays';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import PendingActions from '@mui/icons-material/PendingActions';
import CreateComment from 'features/comments/components/CreateComment';
import SkeletonListComponent from 'components/common/v3/SkeletonListComponent';
import { StuckPointComments } from 'features/stuckPoints/components/StuckPointDetails';
import StuckPointMoreOptions from 'features/stuckPoints/components/StuckPointMoreOptions';
import { AddUpdateStuckPointDialog } from 'features/stuckPoints/components/AddUpdateStuckPointDialog';
import { useCreateStuckPointMutation, useGetTaskStuckPointsQuery } from 'features/stuckPoints/store/stuckPoint.api';


/**
 * Stuck point tab
 * @param {Number} taskId
 * @param {Number} projectId 
 * @returns 
 */
const StuckPointTab = ({ taskId, projectId }) => {

  const [displayAddStuckPoint, setDisplayAddStuckPoint] = useState(false);
  const { data: stuckPoints = [], isLoading, isFetching } = useGetTaskStuckPointsQuery({ taskId });

  const [createStuckPoint, { isLoading: isCreatingStuckPoint, isError }] =
    useCreateStuckPointMutation();

  const handleCreate = (data) =>
    createStuckPoint({
      ...data,
      taskId,
      opened_on: format(data.opened_on, 'yyyy-MM-dd'),
    }).then(() => {
      handleCloseStuckPointDialog();
    });

  const handleCloseStuckPointDialog = (event) => {
    setDisplayAddStuckPoint(false)
  };

  return <Grid container gap={1}>
    <Grid container item alignItems={'center'} xs={12}>
      <IconButton disableRipple size='large'>
        <PendingActions fontSize='inherit' />
      </IconButton>
      <Typography sx={{ fontWeight: '600' }} variant='h6'>Manage Stuck Points</Typography>
      {!displayAddStuckPoint && (
        <Button variant="text" onClick={() => setDisplayAddStuckPoint((prev) => !prev)}>
          + ADD STUCK POINT
        </Button>
      )}
    </Grid>
    {displayAddStuckPoint && <AddUpdateStuckPointDialog
      handleClose={handleCloseStuckPointDialog}
      handleSave={handleCreate}
      label="Create"
      open={displayAddStuckPoint}
      projectId={projectId}
    />}

    <Grid item component={Paper} elevation={2} xs={12}>
      {isLoading ? <SkeletonListComponent /> :
        <Accordion>
          <div>
            {isFetching && <LinearProgress />}
            {stuckPoints?.length ? stuckPoints?.map((stuckPoint) => {
              return <StuckPointCard stuckPoint={stuckPoint} />
            }) :
              <Alert severity="info">
                No Stuck Points
              </Alert>}
          </div>
        </Accordion>}
    </Grid>
  </Grid>
}


const StuckPointCard = ({ stuckPoint }) => {

  const daysStuck = stuckPoint?.is_resolved
    ? differenceInDays(parseDate(stuckPoint?.resolved_on), parseDate(stuckPoint?.opened_on))
    : differenceInDays(new Date(), parseDate(stuckPoint?.opened_on));

  const [expand, setExpand] = useState(false);

  const handleToggleExpand = () => setExpand(!expand)

  return (
    <Accordion expanded={expand}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ mr: 1, pointerEvents: 'auto' }} onClick={handleToggleExpand} />}
        sx={{ flexDirection: 'row-reverse', pointerEvents: 'none' }}
      >
        <Grid container>
          <Grid container item alignItems={'center'} gap={1} xs={10}>
            <Typography sx={{ fontSize: '18px' }}>{stuckPoint?.title}</Typography>
            {stuckPoint?.is_resolved ? (
              <Chip color="success" label="Resolved" variant="outlined" />
            ) : (
              <Chip color="error" label="Pending" variant="outlined" />
            )}
          </Grid>
          <Grid container item xs alignItems={'flex-start'} justifyContent={'flex-end'} >
            <StuckPointMoreOptions stuckPoint={stuckPoint} />
          </Grid>
          <Grid container item gap={1} xs={12}>
            <FormLabel> Stuck By:</FormLabel>
            <Typography> Development</Typography>
            <FormLabel>Days Stuck:</FormLabel>
            <Typography> {daysStuck} </Typography>
            {stuckPoint.affects_pr ? (
              <Chip color="error" label="Work is Paused" size="small" variant="outlined" />
            ) : null}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 2, py: 0, bgcolor: 'grey.50' }}>
        <Grid container spacing={2}>
          <Grid container item gap={1} xs={12}>
            <FormLabel>Description:  </FormLabel>
            <Typography> {stuckPoint?.description ?? 'No description added.'} </Typography>
          </Grid>
          <Grid container item alignItems={'center'} gap={0.5} md={4} xs={12}>
            <FormLabel> Opened on:</FormLabel>
            <Typography> {stuckPoint?.opened_on} </Typography>
          </Grid>
          {stuckPoint?.resolved_on && <>
            <Grid container item gap={0.5} md={4} xs={12}>
              <FormLabel> Resolved on:</FormLabel>
              <Typography> {stuckPoint?.resolved_on}</Typography>
            </Grid>
            <Grid container item gap={0.5} xs={12}>
              <FormLabel> Resolution Notes:</FormLabel>
              <Typography> {stuckPoint?.resolution_notes}</Typography>
            </Grid>
          </>
          }

          <Grid item xs={12}>
            <FormLabel>Comments: </FormLabel>
            <CreateComment
              meta={{
                entityId: stuckPoint?.id,
                entityType: 'stuck_points',
                entityTag: 'StuckPoint',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <StuckPointComments
              entityId={stuckPoint?.id}
              entityTag={'StuckPoint'}
              entityType="stuck_points"
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}



export default StuckPointTab;