import * as React from 'react';

const SvgTaskIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.5718 4.28867L8 0.57735L14.4282 4.28867V11.7113L8 15.4226L1.5718 11.7113V4.28867Z"
      stroke="currentColor"
    />
    <path
      d="M7.25966 8.73281L10.8151 5L12 6.24427L7.25966 11.2222L4 7.79916L5.18571 6.5549L7.25966 8.73281Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTaskIcon;
