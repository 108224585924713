import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import moment from 'moment';

const initMakers = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  if (!gantt.getMarker('todayMaker')) {
    gantt.addMarker({
      start_date: moment().startOf('day'),
      css: 'today-marker',
      text: 'Today',
      id: 'todayMaker',
    });
  }
};

export default initMakers;
