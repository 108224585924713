import { formatTaskToGanttTask } from '@blackhyve/utilities/gantt';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import { SelectList } from 'components/common/SelectList';
import SearchBox from 'components/search/SearchBox';
import { useGetNormalizedLbsQuery } from 'features/locations/store/location.api';
import { useSplitTasksByLBSMutation } from 'features/tasks/store/task.api';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import ganttStore from '../../ganttStore';

export const loadLocationMenuType = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  gantt.config.editor_types.location = {
    show: function (id, column, config, placeholder) {
      // called when input is displayed, put html markup of the editor into placeholder
      // and initialize your editor if needed:
      const task = gantt.getTask(id);

      let element = (
        <SelectLocation
          anchorEl={placeholder}
          key={'location-menu'}
          projectId={`${task?.project_id}`}
          task={task}
          type={column.label.toLowerCase()}
          handleClose={() => {
            gantt.ext.inlineEditors.hide();
          }}
          handleSave={(lbsId) => {
            placeholder.dataset.locationId = lbsId;
            gantt.ext.inlineEditors.save();
          }}
          handleSaveSplit={({ tasks, dependencies }) => {
            gantt.parse({
              data: tasks.map((task) => formatTaskToGanttTask(task)),
              links: dependencies,
            });
            gantt.getTask(id).$open = true;
            gantt.ext.inlineEditors.hide();
          }}
        />
      );

      const externalRender = gantt.config.external_render;
      if (externalRender.isElement(element)) {
        externalRender.renderElement(element, placeholder);
      }
    },
    hide: function (node) {
      node.remove();
      gantt.config.external_render.removeElement('location-menu');
    },

    set_value: function (value, id, column, node) {
      //   let effortTag = value?.effort_tag;
      //   document.getElementById('effort-menu-placeholder').dataset.effortTag = effortTag;
    },

    get_value: function (id, column, node) {
      const locationId = node.dataset?.locationId;
      return isNaN(parseInt(locationId)) ? null : parseInt(locationId);
    },

    is_changed: function (value, id, column, node) {
      const location = this.get_value(id, column, node);
      //called before save/close. Return true if new value differs from the original one
      //returning true will trigger saving changes, returning false will skip saving
      return value !== location;
    },

    is_valid: function (value, id, column, node) {
      // validate, changes will be discarded if the method returns false
      return true;
    },

    save: function (id, column, node) {
      const value = this.get_value(id, column, node);
      const task = gantt.getTask(id);
      const type = column.label.toLowerCase();
      const lbsData = gantt.constants?.lbsData;
      switch (type) {
        case 'location':
          task.location_id = value;
          task.zone_id = undefined;
          task.area_id = undefined;
          break;
        case 'zone':
          task.location_id =
            value && !isEmpty(lbsData?.zones) ? lbsData?.zones[value]?.location_id : undefined;
          task.zone_id = value;
          task.area_id = undefined;
          break;
        case 'area':
          task.area_id = value;
          task.zone_id =
            value && !isEmpty(lbsData?.areas) ? lbsData?.areas[value]?.zone_id : undefined;
          task.location_id =
            task.zone_id && value && !isEmpty(lbsData?.zones)
              ? lbsData?.zones[task.zone_id]?.location_id
              : undefined;
          break;
        default:
          break;
      }
      gantt.updateTask(task.id);
      this.hide(node);
    },
    focus: function (node) {},
  };
};

export const SelectLocation = ({
  anchorEl,
  handleClose,
  handleSave,
  handleSaveSplit,
  task,
  taskIds,
  type,
  projectId,
  ...popoverProps
}) => {
  const [splitTasksByLBS, { data, isLoading: isSplittingTasks }] = useSplitTasksByLBSMutation();
  const { data: lbsData, isLoading } = useGetNormalizedLbsQuery({ projectId });

  const locationEntities = !isEmpty(lbsData?.locations) ? lbsData?.locations : {};
  const zoneEntities = !isEmpty(lbsData?.zones) ? lbsData?.zones : {};
  const areaEntities = !isEmpty(lbsData?.areas) ? lbsData?.areas : {};

  const [selected, setSelected] = useState(task && task[type + '_id'] ? [task[type + '_id']] : []);
  const [multiple, setMultiple] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSelect = (location) => {
    if (selected.includes(location.id)) {
      setSelected(selected.filter((id) => id !== location.id));
    } else {
      setSelected(multiple ? [...selected, location.id] : [location.id]);
    }
  };

  const resetStates = () => {
    setSelected(task && task[type + '_id'] ? [task[type + '_id']] : []);
    setMultiple(false);
  };

  const handleToggleMultiple = () => {
    setMultiple(!multiple);
    setSelected(task && task[type + '_id'] ? [task[type + '_id']] : []);
  };

  let list = [];
  let groupBy = undefined;
  switch (type) {
    case 'location':
      list = Object.values(locationEntities).filter(({ project_id }) =>
        task?.project_id ? project_id === task.project_id : true
      );
      break;
    case 'zone':
      list = Object.values(zoneEntities).filter(({ project_id, location_id }) =>
        task?.project_id
          ? project_id
            ? project_id
            : locationEntities[location_id]?.project_id === task.project_id
          : true
      );
      groupBy = (zone) => {
        return locationEntities[zone?.location_id]?.name;
      };
      break;
    case 'area':
      list = Object.values(areaEntities).filter(({ project_id }) =>
        task?.project_id ? project_id === task.project_id : true
      );
      groupBy = (area) => {
        const zone = zoneEntities?.[area?.zone_id];
        const location = locationEntities?.[zone?.location_id];
        return location?.name + ' - ' + zone?.name;
      };
      break;
    default:
      break;
  }

  const save = () => {
    if (multiple) {
      splitTasksByLBS({
        tasks: task ? [task.id] : taskIds,
        type: type + 's',
        [type + 's']: selected,
      })
        .unwrap()
        .then((payload) => {
          const { tasks, dependencies } = payload;
          handleSaveSplit({ tasks, dependencies });
        });
    }
    handleSave(selected[0]);
    resetStates();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={!!anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={() => {
        handleClose();
        resetStates();
      }}
      {...popoverProps}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        maxHeight={'50vh'}
        maxWidth={'500px'}
        minWidth={'300px'}
        width={'10vw'}
      >
        <Box flex={0} p={0.5}>
          <SearchBox
            fullWidth
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
          />
        </Box>
        <Divider />
        <Button color={'primary'} size={'small'} variant={'text'} onClick={handleToggleMultiple}>
          {multiple ? 'Disable' : 'Enable'} Task Split
        </Button>
        <Divider />
        <Box flex={1} overflow={'auto'}>
          <SelectList
            dense
            disablePadding
            filterBy={(location) => location.name.toLowerCase().includes(searchQuery.toLowerCase())}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(location) => selected.includes(location.id)}
            groupBy={groupBy}
            isLoading={isLoading}
            multiple={multiple}
            options={list}
            onSelect={handleSelect}
          />
        </Box>
        <Box flex={0}>
          <LoadingButton
            fullWidth
            color={'primary'}
            loading={isSplittingTasks}
            size={'small'}
            variant={'contained'}
            onClick={save}
          >
            {multiple ? 'split' : 'Save'}
          </LoadingButton>
        </Box>
      </Box>
    </Popover>
  );
};
