const { differenceInDays } = require('date-fns');

function calculateLinkLag(link, gantt) {
  const sourceTask = gantt.getTask(link.source);
  const targetTask = gantt.getTask(link.target);
  const linkType = link.type;

  let lag = 0;

  if (
    !sourceTask?.start_date ||
    !sourceTask?.end_date ||
    !targetTask?.start_date ||
    !targetTask?.end_date
  ) {
    return 0;
  }

  if (linkType === 'SS') {
    // Lag for SS link type is the difference in start dates of the two tasks
    lag = differenceInDays(targetTask.start_date, sourceTask.start_date);
  } else if (linkType === 'SF') {
    // Lag for SF link type is the difference in finish date of source and start date of the target task
    lag = differenceInDays(targetTask.end_date, sourceTask.start_date);
  } else if (linkType === 'FF') {
    // Lag for FF link type is the difference in finish dates of the two tasks
    lag = differenceInDays(targetTask.end_date, sourceTask.end_date);
  } else if (linkType === 'FS') {
    // Lag for FS link type is the difference in start date of source and finish date of the target task
    lag = differenceInDays(targetTask.start_date, sourceTask.end_date);
  }

  return lag;
}

module.exports = { calculateLinkLag };
