import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Timeline from '@mui/lab/Timeline';
import {
  StyledLabelForNoResult,
  StyledPageLoadingContainer,
} from 'assets/style-components/typography';
import { format, isToday, isYesterday, toDate } from 'date-fns';
import { fetchCompaniesAndContacts } from 'features/companies/store/companiesSlice';
import { fetchUsers } from 'features/users';
import times from 'lodash/times';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import { useGetActivityFeedsQuery } from '../api/activityFeed.api';
import { TimelineGroupLabel } from '../assets/activityFeedStyle';
import ActivityFeedDrawer from './ActivityFeedDrawer';
import { ActivityFeedSkeleton, ActivityFeedTimelineItem } from './ActivityFeedItem';
import { useGetProjectsQuery } from 'features/projects/store/project.api';
import { useSelector } from 'react-redux';
import { selectCurrentUserWorkspaceId } from 'features/auth';

/**
 * Activity feed timeline view
 * @param {object} filters
 * @returns
 */
const ActivityFeedTimelineView = ({ filters }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [queryParams, setQueryParams] = useState({
    page: undefined,
  });
  const [openDrawer, setOpenDrawer] = useState(undefined);

  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const {
    data: activityFeeds,
    isFetching,
    isLoading,
    currentData,
  } = useGetActivityFeedsQuery(queryParams, { refetchOnMountOrArgChange: true });

  const { data: projects = [], isLoading: isLoadingProjects } = useGetProjectsQuery({
    workspaceId,
  });

  const { list } = groupData(activityFeeds?.data);

  const handleCloseDrawer = () => {
    setOpenDrawer(undefined);
  };

  const loadMoreRows = () => {
    const nextPage = activityFeeds?.current_page + 1;
    if (nextPage <= activityFeeds?.last_page && !isFetching && !isLoading) {
      setQueryParams((prevParams) => ({ ...prevParams, page: nextPage }));
    }
  };

  useEffect(() => {
    if (!isLoadingProjects) {
      const promiseArray = [];
      promiseArray.push(
        dispatch(fetchUsers({ projectIds: projects?.map((project) => project.id) })),
        dispatch(fetchCompaniesAndContacts({ queryParam: '&include_archived' }))
      );
      Promise.all(promiseArray).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, projects, isLoadingProjects]);

  useEffect(() => {
    setQueryParams({ ...filters });
  }, [filters]);

  return (
    <>
      <Timeline style={{ marginTop: '0px', marginBottom: '0px', paddingTop: '0px' }}>
        {isLoading || loading || isLoadingProjects || (isFetching && !currentData) ? (
          <ActivityFeedTimelineSkeleton />
        ) : (
          <>
            {!list ||
              (!list.length && (
                <StyledLabelForNoResult style={{ padding: '20px' }}>
                  No activity...
                </StyledLabelForNoResult>
              ))}
            <div
              style={{
                height: `calc(100vh - ${filters?.project ? '160px' : '110px'})`,
              }}
            >
              <Virtuoso
                data={list}
                endReached={loadMoreRows}
                style={{ height: '100%', width: '100%', opacity: isFetching ? 0.3 : 1 }}
                // footer={() => {
                //   return isFetching && <ActivityFeedTimelineSkeleton noOfRows={20} />;
                // }}
                itemContent={(index) => {
                  const activityFeed = list[index];
                  return activityFeed.isDay ? (
                    <TimeLineDateHeader date={activityFeed.name} />
                  ) : (
                    <ActivityFeedTimelineItem
                      activityFeed={activityFeed}
                      setOpenDrawer={setOpenDrawer}
                    />
                  );
                }}
              />
              {isFetching && (
                <StyledPageLoadingContainer>
                  <Typography>Please wait...</Typography>
                  <CircularProgress size="2rem" />
                </StyledPageLoadingContainer>
              )}
            </div>
          </>
        )}
      </Timeline>
      {Boolean(openDrawer) && (
        <ActivityFeedDrawer
          handleClose={handleCloseDrawer}
          id={openDrawer}
          open={true}
          queryParams={queryParams}
        />
      )}
    </>
  );
};

const TimeLineDateHeader = ({ date, onLoad }) => {
  useLayoutEffect(() => {
    onLoad && onLoad();
  }, [onLoad]);

  return <TimelineGroupLabel>{date}</TimelineGroupLabel>;
};

export const groupData = (data) => {
  let previousProjectId = '';
  let previousDirection = 'row';
  const groupedData = {};
  const list = [];
  const dataLength = data?.length;
  if (!dataLength) {
    return { groupedData, list };
  }
  for (let i = 0; i < dataLength; i++) {
    const activityFeed = data[i];
    const createdDate = toDate(new Date(activityFeed.created_at));
    const day = isToday(createdDate)
      ? 'Today'
      : isYesterday(createdDate)
        ? 'Yesterday'
        : format(createdDate, 'EEEE, MMMM, dd');

    if (!groupedData[day]) {
      groupedData[day] = [];
      previousProjectId = '';
      list.push({ name: day, isDay: true });
    }

    let activityFeedData = { ...activityFeed, day, direction: previousDirection };

    if (previousProjectId !== activityFeed.project_id) {
      previousProjectId = activityFeed.project_id;
      previousDirection = previousDirection === 'row' ? 'row-reverse' : 'row';
      activityFeedData = {
        ...activityFeed,
        day,
        direction: previousDirection,
        displayProjectName: true,
      };
    }
    list?.push(activityFeedData);

    // if (previousProjectId === activityFeed.project_id) {
    //   // const group = groupedData[day][groupedData[day].length - 1];
    //   // group?.push({ ...activityFeed, day, direction: previousDirection });
    //   // list?.push({ ...activityFeed, day, direction: previousDirection });
    // } else {
    //   previousProjectId = activityFeed.project_id;
    //   previousDirection = previousDirection === 'row' ? 'row-reverse' : 'row';
    //   groupedData[day]?.push([
    //     { ...activityFeed, day, direction: previousDirection, displayProjectName: true },
    //   ]);
    //   list?.push({ ...activityFeed, day, direction: previousDirection, displayProjectName: true });
    // }
  }
  return { groupedData, list };
};

export const ActivityFeedTimelineSkeleton = ({ noOfRows }) => {
  return times(noOfRows ? noOfRows : 50, (row) => {
    return (
      <ActivityFeedSkeleton direction={row % 2 === 0 ? 'row' : 'row-reverse'} id={row} key={row} />
    );
  });
};

export default ActivityFeedTimelineView;
