import { useEffect, useState } from 'react';
import RichTextEditor from 'components/common/v3/RichTextEditor';
import { Backdrop, Button, CircularProgress, Grid } from '@mui/material';
import { useCreateCommentMutation, useUpdateCommentMutation } from 'features/comments/store/comment.api';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'image', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ color: [] }, { background: [] }],
];

/**
 * Create or Update comment
 * @param {Number} id
 * @param {Object} meta
 * @param {Object} onLoad
 * @param {Object} onSuccess
 * @param {Object} handleClose
 * @param {String} defaultComment
 */
const CreateComment = ({ id, defaultComment, handleClose, onLoad, meta, onSuccess }) => {

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState(defaultComment);

  const [createComment] = useCreateCommentMutation(meta);
  const [updateComment] = useUpdateCommentMutation(meta);


  const handleOnChange = (newValue) => {
    setComment(newValue);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      id
        ? await updateComment({ comment, id, ...meta })
        : await createComment({ comment, ...meta })
    } catch (error) {
      console.error('Error occurred:', error);
    } finally {
      handleClose && handleClose();
      setLoading(false);
      setComment('');
      onSuccess && onSuccess()
    }
  };

  useEffect(() => {
    onLoad && onLoad();
  }, [comment]);

  return (
    <Grid container spacing={1}>
      {loading && (
        <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
          <CircularProgress className="loading" />
        </Backdrop>
      )}
      <Grid item xs={12}>
        <RichTextEditor
          className="drawer-comment"
          handleOnChange={handleOnChange}
          readOnly={false}
          text={comment}
          toolbarOptions={toolbarOptions}
        />
      </Grid>
      <Grid container item style={{ gap: '10px' }}>
        <Button color="primary" size="small" variant="contained" onClick={handleSave}>
          {id ? 'Update Comment' : 'Add Comment'}
        </Button>
        {id ? (
          <Button size="small" onClick={handleClose}>
            Cancel
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default CreateComment;
