import { Box } from '@mui/material';
import ganttStore from '../../ganttStore';
import Popover from '@mui/material/Popover';
import makeStyles from '@mui/styles/makeStyles';
import { createRef, forwardRef, useImperativeHandle, useState } from 'react';
import { SelectTradeDropdown } from 'features/contacts/components/SelectTradeDropdown';

export const loadTradeMenuType = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  let tradeEditorRef;
  gantt.config.editor_types.trades = {
    show: function (id, column, config, placeholder) {
      const task = gantt.getTask(id);
      tradeEditorRef = createRef();
      gantt.config.external_render.renderElement(
        <TradeMenuPopover
          anchorEl={placeholder}
          initialSelected={task.trades}
          key={`${task.id}-trade-menu`}
          ref={tradeEditorRef}
          onClose={() => gantt.ext.inlineEditors.save()}
        ></TradeMenuPopover>,
        placeholder
      );
    },
    hide: function () {
      // called when input is hidden
      // destroy any complex editors or detach event listeners from here
    },

    set_value: function (value, id, column, node) {
      // set input value
      if (tradeEditorRef.current) {
        tradeEditorRef?.current?.setValue(new Set(value || []));
      }
    },

    get_value: function (id, column, node) {
      return tradeEditorRef?.current?.getValue();
      // return input value
    },

    is_changed: function (value, id, column, node) {
      //called before save/close. Return true if new value differs from the original one
      //returning true will trigger saving changes, returning false will skip saving
      const currentValue = this.get_value() || [];
      const oldValue = value || [];
      if (currentValue.length !== oldValue.length) {
        return true;
      }
      currentValue?.slice().sort();
      oldValue?.slice().sort();
      return !currentValue.every((element, index) => element === oldValue[index]);
    },

    is_valid: function (value, id, column, node) {
      // validate, changes will be discarded if the method returns false
      return Array.isArray(this.get_value());
    },
    focus: function (node) {},
  };
};

const useStyles = makeStyles(() => ({
  paper: {
    width: 400,
    overflowY: 'auto',
    borderRadius: '8px',
    padding: '10px 10px',
  },
}));

const TradeMenuPopover = forwardRef(({ initialSelected, anchorEl, onClose }, ref) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [tradeIds, setTradeIds] = useState(initialSelected || []);

  const handleClose = () => {
    setOpen(false);
    onClose(tradeIds);
  };

  useImperativeHandle(ref, () => ({
    getValue: () => [...tradeIds],
    setValue: setTradeIds,
  }));

  return (
    <>
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          classes={{ paper: classes.paper }}
          open={open}
          onClose={handleClose}
        >
          <SelectTradeDropdown setTradeIds={setTradeIds} tradeIds={tradeIds} />
        </Popover>
      )}
    </>
  );
});
