import { useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckIconComponent from '@blackhyve/common/src/icons/Check';
import ArrowRight from '../icons/ArrowRight';
import React from 'react';
import { Menu, MenuItem } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  popoverBox: {
    borderRadius: '5px',
  },
  iconButton: {
    paddingBottom: '8px',
    paddingTop: '8px',
    '&:hover': {
      background: 'unset',
    },
  },
  menuName: {
    textAlign: 'center',
    fontSize: '13px',
    color: 'black',
    marginLeft: '5px',
  },
  menuItem: {
    '&:hover': {
      background: '#efefef',
    },
    // paddingRight: "40px",
    cursor: 'pointer',
  },
  justifyCenter: {
    flexBasis: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  customViewDropdownEllipsis: {
    width: 300,
    textAlign: 'start',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

/**
 * Handles the creation of the dropdown items based on the current dropdownName
 * Creates different types of menus [Menu with radio button on every option,
 * Menu with tick icon on every option, Checkbox menu item, Only option menu, Custom menu dropdown]
 * @param {String} id - Id of the popover
 * @param {Boolean} open - Display popover or not
 * @param {HTML} anchorEl - Element on which you want to show popover
 * @param {Object} handleClose - Close popover callback function
 * @param {Object} requestParameter - Parameters which are needed after the submit or click
 * @param {Object} anchorPosition - Position where should the popover display
 * @param {Object} style - Popover style
 * @param {Array} selectedOptions - Selected options from the list
 * @param {Boolean} isHeading - If you want to display heading on dropdown
 * @param {String} headingText - Heading text
 * @param {Object} containerStyle - Style which you want to add to container of menu item
 * @param {Object} headingComponentStyle - Style which you want to add to header container
 * @returns {Array} of dropdown items
 */
const MoreOptionDialog = (props) => {
  const classes = useStyles();
  const {
    id,
    open,
    anchorEl,
    optionMenu,
    handleClose,
    requestParameter,
    anchorPosition,
    anchor,
    style,
    selectedOptions,
    isHeading,
    headingText,
    containerStyle,
    headingComponentStyle,
    disabled,
  } = props;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorPosition={anchor ? { left: anchor.left, top: 500 } : { top: 500, left: 512 }}
      classes={{ paper: classes.popoverBox }}
      id={id}
      open={open}
      style={style}
      anchorOrigin={
        anchorPosition
          ? anchorPosition
          : {
              vertical: 'center',
              horizontal: 'center',
            }
      }
      onClose={handleClose}
    >
      {isHeading && (
        <Grid item style={{ padding: 10 }} xs={12}>
          <Typography style={headingComponentStyle ? { ...headingComponentStyle } : {}}>
            {headingText}
          </Typography>
          <Divider orientation="horizontal" />
        </Grid>
      )}
      <Grid container direction="column" style={containerStyle ? { ...containerStyle } : {}}>
        {optionMenu &&
          optionMenu.map((menuItem, index) => {
            const isSelected = selectedOptions?.toLowerCase() == menuItem.name?.toLowerCase();
            return menuItem.disabled ? null : menuItem.isToggle ? (
              <ToggleMenuItemComponent
                classes={classes}
                handleClose={handleClose}
                menuItem={menuItem}
                open={open}
                key={`more-options-${index}`}
                requestParameter={requestParameter}
              />
            ) : menuItem.isCheckSymbolShown ? (
              <CheckMenuItemComponent
                classes={classes}
                disabled={disabled}
                handleClose={handleClose}
                isSelected={isSelected}
                menuItem={menuItem}
                open={open}
                key={`more-options-${index}`}
                requestParameter={requestParameter}
              />
            ) : menuItem.isCheckBoxMenu ? (
              <CheckBoxMenuItemComponent
                classes={classes}
                disabled={disabled}
                handleClose={handleClose}
                menuItem={menuItem}
                open={open}
                key={`more-options-${index}`}
                requestParameter={requestParameter}
              />
            ) : menuItem.isCustomView ? (
              <CustomViewDropdownItemComponent
                classes={classes}
                handleClose={handleClose}
                menuItem={menuItem}
                open={open}
                key={`more-options-${index}`}
                requestParameter={requestParameter}
              />
            ) : menuItem.isSubMenu ? (
              <SubMenuItemComponent
                classes={classes}
                menuItem={menuItem}
                open={open}
                key={`more-options-${index}`}
              />
            ) : menuItem.disabled ? null : (
              <TextMenuItemComponent
                classes={classes}
                handleClose={handleClose}
                menuItem={menuItem}
                open={open}
                key={`more-options-${index}`}
                requestParameter={requestParameter}
              />
            );
          })}
      </Grid>
    </Popover>
  );
};

// DISPLAY TEXT AND ICON
const TextMenuItemComponent = ({ open, classes, menuItem, handleClose, requestParameter }) => {
  let isMenuItemDisabled = false;
  if (menuItem.conditionalRendering && menuItem.conditionalRendering.functionParams) {
    const conditionalRenderingParams = {};
    for (let param of menuItem.conditionalRendering.functionParams) {
      conditionalRenderingParams[param] = requestParameter[param];
    }
    isMenuItemDisabled = menuItem.conditionalRendering.functionObject(conditionalRenderingParams);
  }

  if (menuItem.isDisabled) {
    isMenuItemDisabled = menuItem.isDisabled;
  }

  const handleOnClick = (event) => {
    !isMenuItemDisabled && menuItem.action && menuItem.action(requestParameter, event);
    handleClose(event);
  };

  return isMenuItemDisabled ? (
    ''
  ) : (
    <Grid
      item
      className={classes.menuItem}
      id={menuItem.id}
      style={isMenuItemDisabled ? { cursor: 'not-allowed' } : {}}
      onClick={handleOnClick}
    >
      <IconButton
        aria-expanded={open ? 'true' : undefined}
        className={classes.iconButton}
        disabled={isMenuItemDisabled}
        disableFocusRipple={true}
        disableRipple={true}
        id={menuItem.id}
        size="medium"
        style={isMenuItemDisabled ? { opacity: 0.3 } : {}}
      >
        {menuItem.icon ? (
          menuItem.icon
        ) : (
          <img
            id={menuItem.id}
            src={menuItem.imagePath}
            style={menuItem.imageHeight ? menuItem.imageHeight : { height: '15px' }}
          />
        )}
        <Typography
          className={classes.menuName}
          id={menuItem.id}
          style={menuItem.style ? { ...menuItem.style } : {}}
        >
          {menuItem.name}
        </Typography>
      </IconButton>
    </Grid>
  );
};

// DISPLAY TEXT AND RADIO BUTTON FOR TOGGLE
const ToggleMenuItemComponent = ({ open, classes, menuItem, requestParameter }) => {
  const [isSelected, setIsSelected] = useState(menuItem.isChecked);

  const handleOnClick = () => {
    const cloneSelected = !isSelected;
    setIsSelected(cloneSelected);
    menuItem.action &&
      menuItem.action({
        ...requestParameter,
        menuItem: menuItem.name,
        selected: cloneSelected,
      });
  };

  return (
    <Grid item className={classes.menuItem} onClick={handleOnClick}>
      <IconButton
        aria-expanded={open ? 'true' : undefined}
        className={classes.iconButton}
        disableFocusRipple={true}
        disableRipple={true}
        size="medium"
      >
        <Switch checked={isSelected} color="primary" size={'small'} />
        <Typography
          className={classes.menuName}
          style={menuItem.style ? { ...menuItem.style } : {}}
        >
          {menuItem.name}
        </Typography>
      </IconButton>
    </Grid>
  );
};

// DISPLAY TEXT AND CHECK
const CheckMenuItemComponent = ({
  open,
  classes,
  menuItem,
  handleClose,
  requestParameter,
  isSelected,
  disabled,
}) => {
  const handleOnClick = (event) => {
    menuItem.action && menuItem.action({ ...requestParameter, menuItem: menuItem.name });
    handleClose(event);
  };

  return (
    <Grid item className={classes.menuItem} xs={12} onClick={!disabled ? handleOnClick : () => {}}>
      <IconButton
        aria-expanded={open ? 'true' : undefined}
        className={classes.iconButton}
        disabled={disabled}
        disableFocusRipple={true}
        disableRipple={true}
        size="medium"
      >
        <Grid container>
          <Grid
            item
            className={classes.justifyCenter}
            style={isSelected ? {} : { paddingLeft: '10px' }}
            xs={3}
          >
            {isSelected && <CheckIconComponent />}
          </Grid>
          <Grid item xs={8}>
            <Typography
              className={classes.menuName}
              style={menuItem.style ? { ...menuItem.style } : {}}
            >
              {menuItem.name}
            </Typography>
          </Grid>
        </Grid>
      </IconButton>
    </Grid>
  );
};

// DISPLAY DATA AND DROPDOWN
const CustomViewDropdownItemComponent = ({
  open,
  classes,
  menuItem,
  handleClose,
  requestParameter,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const show = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  let isMenuItemDisabled = false;
  if (menuItem.conditionalRendering && menuItem.conditionalRendering.functionParams) {
    const conditionalRenderingParams = {};
    for (let param of menuItem.conditionalRendering.functionParams) {
      conditionalRenderingParams[param] = requestParameter[param];
    }
    isMenuItemDisabled = menuItem.conditionalRendering.functionObject(conditionalRenderingParams);
  }

  const handleOnClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      {menuItem.isBeingLoaded ? (
        <Skeleton style={{ height: '35px', width: '90%', marginLeft: '5%' }} variant="text" />
      ) : isMenuItemDisabled ? (
        ''
      ) : (
        <Grid
          item
          className={classes.menuItem}
          style={
            isMenuItemDisabled
              ? { cursor: 'not-allowed', position: 'relative', paddingRight: 0 }
              : { position: 'relative', paddingRight: 0 }
          }
          onClick={
            menuItem.action
              ? () => {
                  menuItem.action(requestParameter);
                }
              : handleOnClick
          }
        >
          <IconButton
            aria-expanded={open ? 'true' : undefined}
            className={classes.iconButton}
            disabled={isMenuItemDisabled}
            disableFocusRipple={true}
            disableRipple={true}
            size="medium"
            style={isMenuItemDisabled ? { opacity: 0.3 } : {}}
          >
            {menuItem.icon ? (
              menuItem.icon
            ) : (
              <img
                src={menuItem.imagePath}
                style={menuItem.imageHeight ? menuItem.imageHeight : { height: '15px' }}
              />
            )}
            <Typography
              className={clsx('ellipsis', classes.menuName, classes.customViewDropdownEllipsis)}
              style={menuItem.style}
            >
              {menuItem.name}
            </Typography>
          </IconButton>
        </Grid>
      )}
      {show && menuItem.subItems && (
        <MoreOptionDialog
          anchorEl={anchorEl}
          anchorPosition={{ vertical: 'top' }}
          handleClose={handleMenuClose}
          id="option-popover"
          open={show}
          optionMenu={menuItem.subItems}
          requestParameter={{
            id: menuItem.id,
            name: menuItem.name,
            ...requestParameter,
          }}
          style={{
            position: 'absolute',
            left: '-99px',
            top: '12px',
            boxShadow: '0px 1px 1px 1px rgba(0, 0, 0, 0.25)',
          }}
        />
      )}
    </>
  );
};

// DISPLAY CHECKBOX AND DROPDOWN
const CheckBoxMenuItemComponent = ({
  open,
  classes,
  menuItem,
  handleClose,
  requestParameter,
  disabled,
}) => {
  console.log('IN');

  const [isChecked, setIsChecked] = useState(menuItem.isSelected);

  const handleOnClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const cloneSelected = !isChecked;
    setIsChecked(cloneSelected);
    menuItem.action &&
      menuItem.action({
        menuItemId: menuItem.id,
        objId: requestParameter.id,
        menuItem: menuItem.name,
        isSelected: cloneSelected,
      });
    //handleClose(event);
  };

  return (
    <Grid
      item
      className={classes.menuItem}
      style={{ paddingTop: '2px', paddingBottom: '2px', paddingLeft: 15 }}
      xs={12}
      onClick={!disabled ? handleOnClick : null}
    >
      <FormControlLabel
        className={classes.menuName}
        label={<span style={{ fontSize: 15 }}>{menuItem.name}</span>}
        style={menuItem.style ? { ...menuItem.style } : {}}
        control={
          <Checkbox checked={isChecked} disabled={disabled} size="medium" style={{ padding: 4 }} />
        }
      />
    </Grid>
  );
};

// DISPLAY SubMenu AND DROPDOWN
const SubMenuItemComponent = ({ open, classes, menuItem, requestParameter }) => {
  let isMenuItemDisabled = false;
  const [anchorEl, setAnchorEl] = React.useState(null);
  if (menuItem?.conditionalRendering && menuItem.conditionalRendering.functionParams) {
    const conditionalRenderingParams = {};
    for (let param of menuItem.conditionalRendering.functionParams) {
      conditionalRenderingParams[param] = requestParameter[param];
    }
    isMenuItemDisabled = menuItem.conditionalRendering.functionObject(conditionalRenderingParams);
  }

  if (menuItem?.isDisabled) {
    isMenuItemDisabled = menuItem.isDisabled;
  }

  const handleOpen = (event) => {
    if (!isMenuItemDisabled && menuItem.subMenu) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return isMenuItemDisabled ? (
    ''
  ) : (
    <>
      <Grid
        item
        className={classes['menuItem']}
        id={menuItem.id}
        style={{ paddingRight: 0 }}
        onClick={handleOpen}
      >
        <IconButton
          aria-expanded={open ? 'true' : undefined}
          className={classes.iconButton}
          disabled={isMenuItemDisabled}
          disableFocusRipple={true}
          disableRipple={true}
          id={menuItem.id}
          size="medium"
          style={isMenuItemDisabled ? { opacity: 0.3, width: '100%' } : { width: '100%' }}
        >
          {menuItem.icon ? (
            menuItem.icon
          ) : (
            <img
              id={menuItem.id}
              src={menuItem.imagePath}
              style={menuItem.imageHeight ? menuItem.imageHeight : { height: '15px' }}
            />
          )}
          <Typography
            className={classes.menuName}
            id={menuItem.id}
            style={menuItem.style ? { ...menuItem.style } : {}}
          >
            {menuItem.name}
          </Typography>
          <ArrowRight
            fill={'rgba(0, 0, 0, 0.54)'}
            style={{ marginLeft: 'auto', paddingLeft: 10 }}
          />
        </IconButton>
      </Grid>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        {menuItem?.subMenu
          ? menuItem.subMenu.map((item, index) => {
              return (
                <MenuItem key={`sub-menu-${index}`} onClick={item.action}>
                  {item.name}
                </MenuItem>
              );
            })
          : null}
      </Menu>
    </>
  );
};

export default MoreOptionDialog;
