import * as React from 'react';

const SvgPortfolioIcon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.715 8.40859L12.25 6.80934V2.22716C12.2484 2.13102 12.2193 2.03738 12.1661 1.95738C12.1129 1.87738 12.0379 1.8144 11.95 1.77596L8.2 0.0463611C8.13418 0.0158194 8.06252 0 7.99 0C7.91747 0 7.84581 0.0158194 7.77999 0.0463611L4.03 1.77596C3.94321 1.81623 3.86976 1.88062 3.81836 1.96148C3.76696 2.04234 3.73976 2.13628 3.74 2.23217V6.81435L0.290001 8.40859C0.203211 8.44886 0.129761 8.51324 0.0783601 8.5941C0.0269594 8.67496 -0.000235112 8.76891 1.53137e-06 8.8648V13.7678C-0.000235112 13.8637 0.0269594 13.9577 0.0783601 14.0385C0.129761 14.1194 0.203211 14.1838 0.290001 14.224L4.04 15.9536C4.10582 15.9842 4.17747 16 4.25 16C4.32252 16 4.39418 15.9842 4.46 15.9536L8 14.3193L11.54 15.9536C11.6058 15.9842 11.6775 16 11.75 16C11.8225 16 11.8942 15.9842 11.96 15.9536L15.71 14.224C15.7968 14.1838 15.8702 14.1194 15.9216 14.0385C15.973 13.9577 16.0002 13.8637 16 13.7678V8.8648C16.0007 8.76945 15.9742 8.67589 15.9237 8.59508C15.8733 8.51428 15.8008 8.44958 15.715 8.40859ZM11.755 10.0329L9.19999 8.8648L11.755 7.68667L14.31 8.8648L11.755 10.0329ZM11.255 6.80432L8.035 8.30832V4.45307C8.09526 4.4504 8.15455 4.43681 8.21 4.41297L11.25 3.01425V6.81435L11.255 6.80432ZM8 1.04903L10.555 2.22716L8 3.40529L5.45 2.22716L8 1.04903ZM4.25 7.68165L6.805 8.85979L4.25 10.0329L1.695 8.8648L4.25 7.68165ZM7.5 13.442L4.25 14.946V11.0907C4.31978 11.0866 4.38791 11.0678 4.45 11.0356L7.49 9.63184L7.5 13.442ZM15 13.442L11.77 14.946V11.0907C11.8329 11.0839 11.894 11.0652 11.95 11.0356L14.99 9.63184L15 13.442Z"
      fill={props.color ? props.color : '#B3B3B3'}
    />
  </svg>
);

export default SvgPortfolioIcon;
