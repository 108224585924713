import { Alert, Box, Container, Grid, Pagination, Paper, Toolbar, Typography } from '@mui/material';
import { ContentLayout } from 'components/layouts/ContentLayout';
import SearchBox from 'components/search/SearchBox';
import JobwalkUserFilterMenu from 'features/jobwalk/components/UserFilterMenu';
import { debounce } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import StuckPointLBSMenu from '../components/StuckPointLBSMenu';
import StuckPointStatusMenu from '../components/StuckPointStatusMenu';
import StuckPointTable from '../components/StuckPointTable';
import { useGetProjectStuckPointsQuery } from '../store/stuckPoint.api';

export const StuckPoints = () => {
  const projectId = Number(useParams().projectId) || undefined;

  const [filters, setFilters] = useState({ user: [], page: 1, search: undefined, resolved: 0 });

  const {
    data: { data: stuckPoints = [], lastPage, total } = {},
    isLoading,
    isFetching,
  } = useGetProjectStuckPointsQuery({ projectId, ...filters });

  const handleSearch = debounce((event) => {
    const searchQuery = event.target.value.trim();
    setFilters((filters) => ({ ...filters, search: searchQuery || undefined, page: 1 }));
  }, 500);

  const handleChangePage = (event, newPage) => {
    setFilters((prevFilters) => ({ ...prevFilters, page: newPage }));
  };

  const handleSelectUsers = (userId) => {
    setFilters((filters) => ({
      ...filters,
      user: filters.user.includes(userId)
        ? filters.user.filter((id) => id != userId)
        : [...filters.user, userId],
      page: 1,
    }));
  };

  return (
    <ContentLayout>
      <Container maxWidth={'xl'}>
        <Box pt={2}>
          <Typography variant={'h4'}>Stuck Points</Typography>
          <Grid item>
            <Alert severity={'info'} sx={{ py: '1px' }}>
              {total} Match Selected Filters
            </Alert>
          </Grid>
          <Paper elevation={2}>
            <Toolbar style={{ position: 'sticky', left: '0px' }}>
              <Grid container item>
                <SearchBox
                  bgcolor={'grey.200'}
                  borderRadius={1}
                  fullWidth={false}
                  placeholder="Search By Task Name"
                  onChange={handleSearch}
                />
                <Grid container item xs justifyContent={'flex-end'} spacing={1}>
                  <Grid item>
                    <StuckPointStatusMenu
                      selectedStatus={filters.resolved}
                      setFilters={setFilters}
                    />
                  </Grid>
                  <Grid item>
                    <JobwalkUserFilterMenu
                      handleSelect={handleSelectUsers}
                      label="Stuck Points"
                      selectedIds={filters?.user}
                    />
                  </Grid>
                  <Grid item>
                    <StuckPointLBSMenu
                      filters={filters}
                      projectId={projectId}
                      setFilters={setFilters}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
            <StuckPointTable
              isFetching={isFetching}
              isLoading={isLoading}
              stuckPoints={stuckPoints}
            />
            <Pagination
              color="primary"
              count={lastPage}
              disabled={isFetching}
              page={filters?.page}
              shape="rounded"
              sx={{ p: 1 }}
              onChange={handleChangePage}
            />
          </Paper>
        </Box>
      </Container>
    </ContentLayout>
  );
};

export default StuckPoints;
