const { parseDate } = require('../dates');

/**
 * Add Gantt Calendar
 * @param {Object} calendar
 * @param {string} calendar.id
 * @param {[Object]} calendar.days 
 * @param {[Object]} calendar.holidays 
 * @param {*} gantt 
 */
const addGanttCalendar = (calendar, gantt) => {
  const calendarId = gantt.addCalendar({
    id: calendar.id,
    worktime: { days: calendar?.days }
  });

  if (calendar?.holidays?.length) {
    const ganttCalendar = gantt.getCalendar(calendarId);
    for (const holiday of calendar?.holidays) {
      const parsedDate = parseDate(holiday.date);
      ganttCalendar.setWorkTime({ date: parsedDate, hours: false });
    }
  }
};


module.exports = { addGanttCalendar }