import { StyledDialog } from '@blackhyve/common';
import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { ColorSelect } from 'components/form/ColorSelect';
import { selectCurrentUserWorkspaceId } from 'features/auth';
import { TradesAutocomplete } from 'features/trades/components/TradesAutocomplete';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCreateCompanyMutation } from '../api/companies.api';
import { TASK_COLORS } from 'helpers/constants';
import random from 'lodash/random';
import { useEffect } from 'react';

const initialState = {
  name: '',
  email: '',
  website: '',
  color: TASK_COLORS[random(0, 44)].value,
  logo: '',
  trade_ids: [],
};
export const CreateCompanyDialog = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { control, handleSubmit, reset, setError } = useForm({
    defaultValues: initialState,
  });

  const [createCompany, { isLoading: isCreating }] = useCreateCompanyMutation();

  const close = () => {
    reset();
    handleClose();
  };

  const onSubmit = async (data) => {
    createCompany({ company: { ...data } })
      .unwrap()
      .then((payload) => {
        close();
        navigate(`/workspace/${workspaceId}/companies/${payload.id}`);
      })
      .catch((response) => {
        Object.entries(response.data.errors).forEach(([field, error]) => {
          setError(field, {
            type: 'manual',
            message: error,
          });
        });
      });
  };

  useEffect(() => {
    reset({
      ...initialState,
      color: TASK_COLORS[random(0, 44)].value,
    });
  }, []);

  return (
    <StyledDialog
      handleClose={close}
      open={open}
      title={'Create New Company'}
      actions={
        <>
          <Button onClick={close}>Cancel</Button>
          <Button
            disabled={isCreating}
            endIcon={isCreating && <CircularProgress color={'inherit'} size={'1em'} />}
            variant={'contained'}
            onClick={handleSubmit(onSubmit)}
          >
            Create
          </Button>
        </>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Typography>Company Name*</Typography>
            <Controller
              control={control}
              defaultValue=""
              name="name"
              rules={{ required: 'Company name is required' }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  size="small"
                  type="text"
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography>Website</Typography>
            <Controller
              control={control}
              defaultValue=""
              name="website"
              render={({ field }) => <TextField {...field} fullWidth size="small" type="text" />}
            />
          </Grid>
          <Grid item alignItems="center" justifyContent="center" xs={2}>
            <Typography>Color</Typography>
            <Controller
              control={control}
              name="color"
              render={({ field }) => <ColorSelect {...field} fullWidth size={'small'} />}
            />
          </Grid>
          <Grid item xs={10}>
            <Typography>Trades*</Typography>
            <Controller
              control={control}
              name="trade_ids"
              render={({ field: { onChange, ...field } }) => (
                <TradesAutocomplete
                  multiple
                  {...field}
                  onChange={(event, newValue) => onChange(newValue)}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </StyledDialog>
  );
};
