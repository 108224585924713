
import { useState } from "react";
import Box from '@mui/material/Box';
import format from 'date-fns/format';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import { MapImage } from 'features/locations/components/MapImage';
import { useCreateAreaMutation, useDeleteAreaMutation, useGetAreasQuery, useUpdateAreaMutation } from 'features/locations/store/area.api';
import { useGetPhaseZonesQuery, useGetZoneQuery, useGetZonesQuery } from 'features/locations/store/zone.api';
import { ProductionBoardRow, ProductionBoardSkeletonList, ProductionBoardTaskRow } from './ProductionBoardRow';
import ProductionBoardCreateLBSDialog from "./ProductionBoardCreateLBSDialog";
import { useDeleteTasksMutation, useGetTasksByAreaQuery } from "features/tasks/store/task.api";


const ProductionBoardZone = () => {

  const workspaceId = Number(useParams().workspaceId) || undefined;
  const projectId = Number(useParams().projectId) || undefined;
  const locationId = Number(useParams().locationId) || undefined;
  const phaseId = Number(useParams().phaseId) || undefined;
  const zoneId = Number(useParams().zoneId) || undefined;
  const [openCreateAreaDialog, setOpenCreateAreaDialog] = useState(false);
  const [openEditAreaDialog, setOpenEditAreaDialog] = useState({ open: false });

  const { zone, isLoadingZone } = useGetPhaseZonesQuery({ phaseId }, {
    selectFromResult: ({ data: zones, isLoading }) => ({
      zone: zones?.find((zone) => zone.id === zoneId),
      isLoadingZone: isLoading
    }),
  });

  const { data: areas = [], isLoading: isAreasLoading,
    isFetching: isFetchingAreas } = useGetAreasQuery({ zoneId });


  const [createArea, { isLoading: isCreateArea }] = useCreateAreaMutation();
  const [deleteArea] = useDeleteAreaMutation();
  const [updateArea, { isLoading: isUpdatingArea }] = useUpdateAreaMutation()

  const handleDelete = (id) => deleteArea({ areaId: id })
  const handleSaveAndAddAnother = (area, e) => handleCreateArea(area, e, true);
  const handleCreateArea = async (data, e, preventClose) => {
    createArea({ ...data, zoneId }).then((response) => {
      if (!preventClose) setOpenCreateAreaDialog(false);
    }).catch((e) => {
      console.error(e);
    });
  };
  const handleUpdateArea = (data) => {
    updateArea({ area: data, areaId: data.id }).then(() => setOpenEditAreaDialog({ open: false }))
  }

  const handleOpenCreateAreaDialog = () => setOpenCreateAreaDialog(true)
  const handleCloseDialog = () => setOpenCreateAreaDialog(false);

  const handleOpenEditAreaDialog = (entityObj) => setOpenEditAreaDialog({ entityObj, open: true })
  const handleCloseEditAreaDialog = () => setOpenEditAreaDialog({ open: false })


  return isLoadingZone ? <CircularProgress className='loading' /> :
    <Container maxWidth={'xl'}>
      <Grid container item xs={12} gap={1}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }} align="center">{zone?.name}</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button variant="contained" onClick={handleOpenCreateAreaDialog}>+ New Area</Button>
        </Grid>
        {zone?.map?.original_url ?
          <Grid item container justifyContent={'center'} xs={12}>
            <MapImage
              alt={`${zone?.name}`}
              backgroundColor={'#fff'}
              maxWidth={'50vh'}
              src={zone?.map?.original_url}
              width={'70vw'}
            />
          </Grid> : null}
        <Grid container item xs={12} gap={3}>
          {(!isAreasLoading && isFetchingAreas && (
            <Box sx={{ width: '100%', }}>
              <LinearProgress />
            </Box>
          ))}
          {isAreasLoading ? <ProductionBoardSkeletonList /> :
            areas?.length ? areas?.map((area) => {
              const pageLink = `/workspace/${workspaceId}/projects/${projectId}/production-board/locations/${locationId}/phases/${phaseId}/zones/${zoneId}/areas/${area?.id}`
              return <ProductionBoardRow
                key={area?.id}
                entityObj={area}
                pageLink={pageLink}
                onDelete={handleDelete}
                onEdit={handleOpenEditAreaDialog}
              >
                <ProductionBoardTasks projectId={projectId} areaId={area?.id} parentLink={pageLink} />
              </ProductionBoardRow>
            }) : <Alert sx={{ textAlign: 'center', m: 'auto' }} severity="warning">No areas have been added.</Alert>}
        </Grid>
        {openCreateAreaDialog &&
          <ProductionBoardCreateLBSDialog
            type="Area"
            isSaving={isCreateArea}
            open={openCreateAreaDialog}
            handleClose={handleCloseDialog}
            handleSave={handleCreateArea}
            handleSaveAndAddAnother={handleSaveAndAddAnother}
          />}
        {openEditAreaDialog?.open &&
          <ProductionBoardCreateLBSDialog
            type="Area"
            isSaving={isUpdatingArea}
            handleSave={handleUpdateArea}
            open={openEditAreaDialog?.open}
            handleClose={handleCloseEditAreaDialog}
            entityObj={openEditAreaDialog?.entityObj}
          />}
      </Grid>
    </Container>
}

const ProductionBoardTasks = ({ projectId, areaId, parentLink }) => {

  const { data: tasks = [], isLoading: isLoadingTasks, isFetching: isFetchingTasks } =
    useGetTasksByAreaQuery({ projectId, areaId })

  return <Grid container item xs={12} gap={1}>
    {isLoadingTasks ?
      <ProductionBoardSkeletonList isChildrenList={false} /> :
      tasks?.map((task) => {
        const pageLink = `${parentLink}/areas/${task.id}`
        return <ProductionBoardTaskRow
          key={task.id}
          task={task}
          pageLink={pageLink}
        />
      })}
  </Grid>
}




export default ProductionBoardZone;