import { useUploadContractFilesMutation } from '../store/contracts.api';
import { StyledDialog } from '@blackhyve/common';
import { Button, Grid, CircularProgress, IconButton, Box } from '@mui/material';
import { useState } from 'react';
import { InputFileUpload } from 'components/common/ImageUploadComponent';
import SvgDeleteIconRed from '@blackhyve/common/src/icons/Delete';
import { bytesForHumans } from '../models/files';

const randomId = () => {
  return (Math.random() * 10).toString(16).replace('.', '');
};

export const FileUploadDialog = ({ open, handleClose, contract }) => {
  const [files, setFiles] = useState([]);

  const reset = () => {
    setFiles([]);
  };

  const [uploadContractFiles, { isLoading }] = useUploadContractFilesMutation();

  async function upload() {
    const formData = new FormData();

    files.forEach(({ file }) => {
      formData.append('files[]', file);
    });

    await uploadContractFiles({
      id: contract.id,
      formData,
    })
      .unwrap()
      .then(() => {
        reset();
        handleClose();
      });
  }

  const addFiles = (event) => {
    if (event.target.files.length === 0) return;

    setFiles([
      ...files,
      ...Array.from(event.target.files).map((file) => ({
        file,
        id: randomId(),
      })),
    ]);
    event.target.value = '';
  };

  const resetAndClose = () => {
    reset();
    handleClose();
  };

  const removeFile = (id) => {
    setFiles(files.filter((file) => file.id !== id));
  };

  return (
    <StyledDialog
      DialogContentProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
      handleClose={() => handleClose()}
      open={open}
      title="Upload Files"
      actions={
        <>
          <Button disabled={isLoading} onClick={resetAndClose}>
            Close
          </Button>
          <Button
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={'1rem'} />}
            variant={'contained'}
            onClick={upload}
          >
            Upload
          </Button>
        </>
      }
    >
      <Grid container item spacing={1} xs={12}>
        <Grid container item sx={{ justifyContent: 'center' }} xs={12}>
          <InputFileUpload
            inputProps={{ onChange: addFiles, multiple: 'multiple' }}
            label="Select Files"
            ButtonProps={{
              size: 'small',
              sx: { mt: 1 },
              endIcon: isLoading && <CircularProgress color={'inherit'} size={'1em'} />,
            }}
          />
        </Grid>

        {files.length > 0 && (
          <Box sx={{ m: '1rem auto', width: 300 }}>
            <Grid>
              {files.map(({ file, id }) => (
                <div key={`file-${id}`}>
                  <Grid container alignItems="center">
                    <Grid
                      item
                      sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      xs={7}
                    >
                      {file.name}
                    </Grid>

                    <Grid item sx={{ whiteSpace: 'nowrap' }} xs={3}>
                      {bytesForHumans(file.size)}
                    </Grid>

                    <Grid item xs={2}>
                      <IconButton aria-label="Remove" onClick={() => removeFile(id)}>
                        <SvgDeleteIconRed />
                      </IconButton>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Grid>
          </Box>
        )}
      </Grid>
    </StyledDialog>
  );
};
