import moment from 'moment';

export const getRecipeGanttZoomConfig = (gantt) => {
  const recipeGanttZoomConfig = {
    levels: [
      {
        name: 'year',
        scale_height: 35,
        min_column_width: 300,
        max_column_width: 300,
        scales: [
          {
            unit: 'year',
            step: 5,
            format: function (date) {
              const yearNo = date.getFullYear() - gantt.config?.project_start?.getFullYear();
              return `Year ${yearNo} - Year ${yearNo + 5}`;
            },
          },
          {
            unit: 'year',
            step: 1,
            format: (date) => {
              const yearNo = date.getFullYear() - gantt.config?.project_start?.getFullYear();
              return `Year ${yearNo >= 0 ? yearNo + 1 : yearNo}`;
            },
          },
        ],
      },
      {
        name: 'quarter',
        scale_height: 35,
        min_column_width: 250,
        max_column_width: 250,
        scales: [
          {
            unit: 'year',
            step: 1,
            format: (date) => {
              const yearNo = date.getFullYear() - gantt.config?.project_start?.getFullYear();
              return `Year ${yearNo >= 0 ? yearNo + 1 : yearNo}`;
            },
          },
          {
            unit: 'quarter',
            step: 1,
            format: (date) => {
              return `Q${moment(date).quarter()}`;
            },
          },
        ],
      },
      {
        name: 'month',
        scale_height: 35,
        min_column_width: 200,
        max_column_width: 200,
        scales: [
          {
            unit: 'year',
            step: 1,
            format: (date) => {
              const yearNo = date.getFullYear() - gantt.config?.project_start?.getFullYear();
              return `Year ${yearNo >= 0 ? yearNo + 1 : yearNo}`;
            },
          },
          {
            unit: 'month',
            step: 1,
            format: (date) => {
              const monthNo = date.getMonth() - gantt.config?.project_start?.getMonth();
              return `Month ${monthNo >= 0 ? monthNo + 1 : monthNo}`;
            },
          },
        ],
      },
      {
        name: 'week',
        scale_height: 35,
        min_column_width: 50,
        max_column_width: 50,
        scales: [
          {
            unit: 'week',
            step: 1,
            format: (date) => {
              const weekStart = gantt.date.week_start(date);
              const weekNumber = Math.ceil(
                (weekStart.getTime() - gantt.config?.project_start?.getTime()) / 604800000
              ); // MS in week
              return `Week ${weekNumber >= 0 ? weekNumber + 1 : weekNumber}`;
            },
          },
          {
            unit: 'day',
            step: 1,
            format: (date) => {
              const msInDay = 24 * 60 * 60 * 1000;
              const dayNumber = Math.ceil(
                (date.getTime() - gantt.config?.project_start?.getTime()) / msInDay
              );
              return `Day ${dayNumber >= 0 ? dayNumber + 1 : dayNumber}`;
            },
          },
        ],
      },
    ],
    activeLevelIndex: 3,
    useKey: 'ctrlKey',
    trigger: 'wheel',
    element: function () {
      return gantt.$root.querySelector('.gantt_task');
    },
  };
  return recipeGanttZoomConfig;
};
