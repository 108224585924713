import { useEffect } from 'react';
import { StyledDialog } from '@blackhyve/common';
import { Autocomplete, MenuItem, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { FormTextField } from 'assets/style-components/textfield';
import { FormLabel } from 'assets/style-components/typography';
import WorkDaysComponent from 'components/common/v3/WorkDaysPopover';
import { useCanEditProject } from 'hooks/useCanEditProject';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { Controller, useForm } from 'react-hook-form';
import { STATES } from '../../../helpers/constants';
import { api } from 'api';
import { useUpdateProjectMutation } from '../store/project.api';
import { useSelector } from 'react-redux';
import { selectCurrentUserWorkspaceId } from 'features/auth';
import FeatureFlag, { features } from '@blackhyve/common/src/components/FeatureFlag';
import useAutoSchedulerJob from 'features/gantt/hooks/useAutoSchedulerJob';

const initialState = {
  id: undefined,
  shift: 0,
  workday_calendar: {
    friday: '1',
    monday: '1',
    sunday: '0',
    tuesday: '1',
    saturday: '0',
    thursday: '1',
    wednesday: '1',
  },
  address: '',
  city: '',
  state: '',
  zip_code: '',
  division: '',
  region: '',
  office: '',
  notification_frequency: 'daily',
  check_task_dependencies: false,
  budgets_feature: false,
  production_schedule: false,
  undo_feature: false,
};

const notificationFrequencyOptions = ['off', 'immediately', 'hourly', 'daily'];
const featureOptions = [
  { value: false, label: 'Off' },
  { value: true, label: 'On' },
];

/**
 * Project setting dialog
 * @param {String} id
 * @param {Boolean} open
 * @param {Object} canEdit
 * @param {Object} handleClose
 * @returns
 */
const ProjectSettingDialog = ({ handleClose, id, open }) => {
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { projectDetails, isProjectLoading } = api.endpoints.getProjects.useQueryState(
    { workspaceId },
    {
      selectFromResult: ({ data: projects, isFetching, isLoading }) => {
        return {
          projectDetails: projects?.find((project) => project.id === id),
          isFetching,
          isProjectLoading: isLoading,
        };
      },
    }
  );

  const [updateProject, { isLoading: isUpdatingProject }] = useUpdateProjectMutation();
  const runAutoSchedulerJob = useAutoSchedulerJob();
  const canEdit = useCanEditProject(id);

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: initialState,
  });

  const handleSave = async (data) => {
    const requestParameter = cloneDeep(data);
    requestParameter.shift =
      parseInt(requestParameter.hours) * 3600 +
      parseInt(requestParameter.minutes) * 60 +
      parseInt(requestParameter.seconds);
    updateProject(requestParameter)
      .unwrap()
      .then(() => {
        handleClose();
        runAutoSchedulerJob(id);
      });
  };

  const handleSelectDays = (calendar) => {
    setValue('workday_calendar', calendar.workday_calendar);
  };

  useEffect(() => {
    if (!isEmpty(projectDetails) && !projectDetails?.loading) {
      let shiftData = {};
      if (projectDetails.shift && projectDetails.shift.includes(':')) {
        shiftData.hours = projectDetails.shift.split(':')[0];
        shiftData.minutes = projectDetails.shift.split(':')[1];
        shiftData.seconds = projectDetails.shift.split(':')[2];
      }
      reset({
        id: projectDetails?.id,
        shift: projectDetails?.shift,
        hours: shiftData?.hours,
        minutes: shiftData?.minutes,
        seconds: shiftData?.seconds,
        workday_calendar: projectDetails?.workday_calendar,
        address: projectDetails?.address,
        city: projectDetails?.city,
        state: projectDetails?.state,
        zip_code: projectDetails?.zip_code,
        division: projectDetails?.division,
        region: projectDetails?.region,
        office: projectDetails?.office,
        notification_frequency: projectDetails?.notification_frequency,
        check_task_dependencies: projectDetails?.check_task_dependencies ? true : false,
        budgets_feature: projectDetails?.budgets_feature ? true : false,
        production_schedule: projectDetails?.production_schedule ? true : false,
        undo_feature: projectDetails?.undo_feature ? true : false,
      });
    }
  }, [projectDetails, reset]);

  return (
    <StyledDialog
      handleClose={handleClose}
      open={open}
      title={projectDetails?.name + ' Settings'}
      actions={
        <>
          <Button size={'small'} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={!canEdit}
            size={'small'}
            type="submit"
            variant="contained"
            onClick={handleSubmit(handleSave)}
          >
            Save
          </Button>
        </>
      }
    >
      {isUpdatingProject || isProjectLoading ? (
        <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
          <CircularProgress className="loading" />
        </Backdrop>
      ) : null}
      <form onSubmit={handleSubmit(handleSave)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel>WORK DAYS</FormLabel>
            <Controller
              control={control}
              name="workday_calendar"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <WorkDaysComponent
                  daysObject={{ workday_calendar: value }}
                  disabled={!canEdit}
                  displayLabel="Work Days"
                  handleClick={handleSelectDays}
                  style={{ fontSize: '12px', textTransform: 'uppercase' }}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={4}>
            <FormLabel>Hours</FormLabel>
            <Controller
              control={control}
              name="hours"
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  disabled={!canEdit}
                  name="hours"
                  placeholder="Hours"
                  size="small"
                  type="number"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 24,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <SvgClockIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <FormLabel>Minutes</FormLabel>
            <Controller
              control={control}
              name="minutes"
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  disabled={!canEdit}
                  name="minutes"
                  placeholder="Minutes"
                  size="small"
                  type="number"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 60,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <SvgClockIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <FormLabel>Seconds</FormLabel>
            <Controller
              control={control}
              name="seconds"
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  disabled={!canEdit}
                  placeholder="Seconds"
                  size="small"
                  type="number"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 60,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <SvgClockIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Typography variant={'h5'}>Address</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormLabel>STREET</FormLabel>
            <Controller
              control={control}
              name="address"
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  disabled={!canEdit}
                  placeholder="Street Address"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormLabel>CITY</FormLabel>
            <Controller
              control={control}
              name="city"
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  disabled={!canEdit}
                  placeholder="Project City"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormLabel>STATE</FormLabel>
            <Controller
              control={control}
              name="state"
              render={({ field: { value, onChange } }) => (
                <Autocomplete
                  autoComplete
                  autoHighlight
                  disabled={!canEdit}
                  options={STATES}
                  size="small"
                  value={value}
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Project State" variant="outlined" />
                  )}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel>ZIP CODE</FormLabel>
            <Controller
              control={control}
              name="zip_code"
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  disabled={!canEdit}
                  placeholder="Project Zip Code"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel>Region</FormLabel>
            <Controller
              control={control}
              name="region"
              render={({ field }) => (
                <FormTextField
                  fullWidth
                  {...field}
                  disabled={!canEdit}
                  name="region"
                  placeholder="Project Region"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel>Office</FormLabel>
            <Controller
              control={control}
              name="office"
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  disabled={!canEdit}
                  placeholder="Project Office Name"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel>Division</FormLabel>
            <Controller
              control={control}
              name="division"
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  disabled={!canEdit}
                  placeholder="Project division"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h5'}>Notifications</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormLabel>Frequency</FormLabel>
            <Controller
              control={control}
              name={'notification_frequency'}
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  select
                  disabled={!canEdit}
                  size={'small'}
                  variant={'outlined'}
                >
                  {notificationFrequencyOptions.map((option) => (
                    <MenuItem key={option} sx={{ textTransform: 'capitalize' }} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </FormTextField>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h5'}>Features</Typography>
          </Grid>
          <FeatureFlag name={features.BUDGETS}>
            <Grid item xs={6}>
              <FormLabel>Budgets</FormLabel>
              <Controller
                control={control}
                name={'budgets_feature'}
                render={({ field }) => (
                  <FormTextField
                    {...field}
                    fullWidth
                    select
                    disabled={!canEdit}
                    size={'small'}
                    variant={'outlined'}
                  >
                    {featureOptions?.map((option) => (
                      <MenuItem
                        key={option.label}
                        sx={{ textTransform: 'capitalize' }}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </FormTextField>
                )}
              />
            </Grid>
          </FeatureFlag>
          <FeatureFlag name={features.UNDO}>
            <Grid item xs={6}>
              <FormLabel>Gantt Undo/Redo (Beta)</FormLabel>
              <Controller
                control={control}
                name={'undo_feature'}
                render={({ field }) => (
                  <FormTextField
                    {...field}
                    fullWidth
                    select
                    disabled={!canEdit}
                    size={'small'}
                    variant={'outlined'}
                  >
                    {featureOptions.map((option) => (
                      <MenuItem
                        key={option}
                        sx={{ textTransform: 'capitalize' }}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </FormTextField>
                )}
              />
            </Grid>
          </FeatureFlag>
          <Grid item xs={6}>
            <FormLabel>Production Board</FormLabel>
            <Controller
              control={control}
              name={'production_schedule'}
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  select
                  disabled={!canEdit}
                  size={'small'}
                  variant={'outlined'}
                >
                  {featureOptions?.map((option) => (
                    <MenuItem
                      key={option}
                      sx={{ textTransform: 'capitalize' }}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </FormTextField>
              )}
            />
          </Grid>
        </Grid>
      </form>
    </StyledDialog>
  );
};

export default ProjectSettingDialog;
