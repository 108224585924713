
import Avatar from '@mui/material/Avatar';

const CustomAvatar = ({ firstName, lastName, icon, avatarStyle }) => {

  const name = firstName?.charAt(0);

  return icon ? (
    <Avatar
      alt={name}
      size="small"
      src={icon ? icon : null}
      style={avatarStyle}
    />
  ) : (
    <Avatar style={avatarStyle} size="small">
      {firstName?.charAt(0).toUpperCase()}
      {lastName
        ? lastName.charAt(0).toUpperCase()
        : firstName?.charAt(firstName.length - 1).toUpperCase()}
    </Avatar>
  );
}

export default CustomAvatar