import { StyledDialog } from '@blackhyve/common';
import FilterList from '@mui/icons-material/FilterList';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { transformRawData } from 'features/drawers/components/FlowAiDialog';
import { useGetLbsQuery } from 'features/locations/store/location.api';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { useEffect, useState } from 'react';
import { useGetProjectJobwalkLBSQuery } from '../store/jobwalk.api';
import JobwalkLbsFilter from './JobwalkLbsFilter';

/**
 * Jobwalk LBS Dialog
 * @param {Object} open
 * @param {String} setFilters
 * @param {String} projectId
 * @param {String} filters
 * @param {Object} handleClose
 * @returns
 */
const JobwalkLbsDialog = ({ setFilters, projectId, filters }) => {
  const [locations, setLocations] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [openLBSDialog, setOpenLBSDialog] = useState(false);
  const lbsFilterQuery = { projectId, ...filters };
  delete lbsFilterQuery.order_by;

  const { data: lbsData, isLoading: isLoadingLbs } = useGetLbsQuery({ projectId });

  const handleFilter = () => {
    const selectedLBSObj = { location: [], zone: [], area: [] };
    uniq(selectedIds).forEach((value) => {
      const [type, id] = value.split('-');
      selectedLBSObj[type] = [...selectedLBSObj[type], id];
    });
    setFilters((filters) => {
      return { ...filters, ...selectedLBSObj };
    });
    handleCloseDialog();
  };

  const handleSelect = (ids) => {
    setSelectedIds(ids);
  };

  const handleCloseDialog = () => setOpenLBSDialog(false);

  const { data: lbsJobwalkCount = {} } = useGetProjectJobwalkLBSQuery(lbsFilterQuery, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    const ids = [];
    const extractSelectedIds = (element) => {
      ids.push(`${element.type}-${element.id}`);
      element.children?.length &&
        element.children?.forEach((nextElement) => {
          extractSelectedIds(nextElement);
        });
    };

    if (!isEmpty(lbsData)) {
      const transformedData = transformRawData(lbsData);
      transformedData?.unshift({ id: 0, name: 'Unassigned', type: 'location' });
      transformedData.forEach((element) => extractSelectedIds(element));
      setLocations(transformedData);
      setSelectedIds(ids);
    }
  }, [lbsData]);

  return (
    <>
      <Chip
        icon={<FilterList fontSize="small" />}
        label={selectedIds?.length ? `${selectedIds.length} LBS Selected` : 'Select LBS'}
        variant={selectedIds?.length ? 'filled' : 'outlined'}
        onClick={() => {
          setOpenLBSDialog(true);
        }}
      />

      <StyledDialog
        dialogOverrides={{ maxWidth: 'sm' }}
        handleClose={handleCloseDialog}
        open={openLBSDialog}
        title="Filter By LBS"
        actions={
          <>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button variant="contained" onClick={handleFilter}>
              Filter
            </Button>
          </>
        }
      >
        <JobwalkLbsFilter
          handleSelect={handleSelect}
          lbsJobwalkCount={lbsJobwalkCount}
          locations={locations}
          selectedIds={selectedIds}
        />
      </StyledDialog>
    </>
  );
};

export default JobwalkLbsDialog;