/**
 * Returns list of each parent of a task
 * @param {Number} taskId
 * @param {Gantt} gantt
 * @returns {[Task]}
 */
function getParents(taskId, gantt) {
  const task = gantt.getTask(taskId);
  const parents = [];
  const recursiveParentTraversal = (task) => {
    parents.push(task);
    if (gantt.isTaskExists(task.parent)) {
      recursiveParentTraversal(gantt.getTask(task.parent));
    }
  };
  recursiveParentTraversal(task);
  return parents;
}

module.exports = { getParents };
