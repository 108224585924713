import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CreateSupportTicketAPI,
  SendResetPasswordLink,
  resetPasswordAPI,
  SignIn as signIn,
  SignOut as signOut,
} from '../../../services/LoginService';
import { authAPI } from '../api/auth';
import { forceLogout, tokenReceived } from './extraActions';
import { workspaceAPI } from 'features/workspace/api/workspace.api';

/**
 * Login async thunk
 * @param {object} signInData
 * @param {string} signInData.email - email address
 * @param {string} signInData.password - password
 */
export const login = createAsyncThunk('auth/login', async (signInData, { rejectWithValue }) => {
  const { email, password } = signInData;
  const apiResponse = await signIn({ email, password });

  if (apiResponse.error) {
    return rejectWithValue(apiResponse.error);
  } else if (apiResponse.response.error) {
    return rejectWithValue(apiResponse.response.error);
  } else {
    return apiResponse.response;
  }
});

/**
 * Logout async thunk
 */
export const logout = createAsyncThunk(
  'auth/logout',
  async (userId, { rejectWithValue, dispatch }) => {
    const apiResponse = await signOut();
    // dispatch(api.util.resetApiState());
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.message);
    } else {
      return apiResponse.response;
    }
  }
);

/**
 * Reset password async thunk
 * @param {object} resetPasswordData
 * @param {string} resetPasswordData.email - email of the user to reset password for
 * @param {string} resetPasswordData.password - new password
 * @property {string} resetPasswordData.passwordConfirmation - new password confirmation
 */
export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (resetPasswordData, { rejectWithValue }) => {
    const apiResponse = await resetPasswordAPI(resetPasswordData);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.error);
    } else if (apiResponse.response.error) {
      return rejectWithValue(apiResponse.response.error);
    } else {
      return apiResponse.response;
    }
  }
);

/**
 * Send password reset email async thunk
 * @param {string} email
 */
export const sendResetPasswordLink = createAsyncThunk(
  'auth/sendResetPasswordLink',
  async (email, { rejectWithValue }) => {
    const apiResponse = await SendResetPasswordLink({ email: email });
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.error);
    } else {
      return apiResponse.response;
    }
  }
);

/**
 * Create support ticket
 * @param {Object} requestParameter
 * @param {String} requestParameter.return_email
 * @param {String} requestParameter.subject
 * @param {String} requestParameter.body
 */
export const createSupportTicket = createAsyncThunk(
  'auth/createSupportTicket',
  async (requestParameter, { rejectWithValue }) => {
    const apiResponse = await CreateSupportTicketAPI(requestParameter);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.error);
    } else {
      return apiResponse.response;
    }
  }
);

// const getInitialState = () => ({
//   authToken: localStorage.getItem('authToken'),
//   workspaceId: localStorage.getItem('workspaceId'),
//   user: JSON.parse(localStorage.getItem('user')),
//   userRole: localStorage.getItem('userRole'),
//   loading: false,
//   passwordReset: undefined,
//   resetEmailSent: undefined,
//   error: null,
// });

const getInitialState = () => ({
  refreshToken: localStorage.getItem('refreshToken'),
  authToken: undefined,
  workspaceId: localStorage.getItem('workspaceId'),
  workspaceName: localStorage.getItem('workspaceName'),
  user: undefined,
  userRole: undefined,
  loading: false,
  passwordReset: undefined,
  resetEmailSent: undefined,
  error: null,
});

export const authSlice = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {
    clearAuthErrors: (state) => {
      state.error = null;
      state.passwordReset = false;
    },
    setSocketId: (state, action) => {
      state.socketId = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Login reducers
    builder.addCase(login.fulfilled, (state, action) => {
      const { user, access_token, workspace, refresh_token } = action.payload;
      // const id = user['id'];
      // localStorage.setItem('authToken', access_token);
      localStorage.setItem('refreshToken', refresh_token);
      // localStorage.setItem('workspaceId', user.workspace_id);
      // localStorage.setItem('user', JSON.stringify(user));
      // state.data = user;
      state.loading = false;
      state.error = null;
      state.authToken = access_token;
      state.refreshToken = refresh_token;
      // state.workspaceId = user.workspace_id;
      state.user = user;
      // state.id = id;

      window.pendo.identify({
        visitor: {
          id: user.id,
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
        },
        account: {
          id: state.workspaceId,
          accountName: state.workspaceName,
        },
      });
    });
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.payload;
      state.id = null;
    });

    // Logout reducers
    builder.addCase(logout.fulfilled, (state) => {
      // localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('workspaceId');
      localStorage.removeItem('workspaceName');
      // localStorage.removeItem('user');
      localStorage.removeItem('persist:root');
      return getInitialState();
    });
    builder.addCase(logout.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(logout.rejected, (state, action) => {
      // Is there a situation where we wouldn't want to remove these if logout was rejected
      localStorage.removeItem('authToken');
      localStorage.removeItem('workspaceId');
      localStorage.removeItem('workspaceName');
      localStorage.removeItem('user');
      localStorage.removeItem('persist:root');
      // state.error = action.payload;
      return getInitialState();
    });

    builder.addCase(forceLogout, () => {
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('workspaceId');
      localStorage.removeItem('workspaceName');
      return getInitialState();
    });

    // Reset password reducers
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.passwordReset = true;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.passwordReset = undefined;
      state.loading = true;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.passwordReset = false;
      state.loading = false;
      state.error = action.payload;
    });

    // Send reset password link reducers
    builder.addCase(sendResetPasswordLink.fulfilled, (state) => {
      state.resetEmailSent = true;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(sendResetPasswordLink.pending, (state) => {
      state.resetEmailSent = undefined;
      state.loading = true;
    });
    builder.addCase(sendResetPasswordLink.rejected, (state, action) => {
      state.resetEmailSent = false;
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(tokenReceived, (state, action) => {
      const { access_token: authToken, refresh_token: refreshToken, user } = action.payload;
      state.authToken = authToken;
      state.refreshToken = refreshToken;
      localStorage.setItem('refreshToken', refreshToken);
      state.user = user;
    });

    //Matchers
    builder.addMatcher(workspaceAPI.endpoints.setWorkspace.matchFulfilled, (state, { payload }) => {
      const { workspaceId, workspaceName, workspaceLogo } = payload;
      state.workspaceId = workspaceId;
      localStorage.setItem('workspaceId', workspaceId);
      state.workspaceName = workspaceName;
      localStorage.setItem('workspaceName', workspaceName);
      state.workspaceLogo = workspaceLogo;
      const user = state.user;

      window.pendo.identify({
        visitor: {
          id: user.id,
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
        },

        account: {
          id: state.workspaceId,
          accountName: state.workspaceName,
        },
      });
    });

    builder.addMatcher(authAPI.endpoints.getAuthToken.matchFulfilled, (state, action) => {
      const { access_token: authToken, refresh_token: refreshToken, user } = action.payload;
      state.authToken = authToken;
      state.refreshToken = refreshToken;
      localStorage.setItem('refreshToken', refreshToken);
      state.user = user;

      window.pendo.identify({
        visitor: {
          id: user.id,
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
        },

        account: {
          id: state.workspaceId,
          accountName: state.workspaceName,
        },
      });
    });

    builder.addMatcher(authAPI.endpoints.getAuthToken.matchRejected, (state, action) => {
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('workspaceId');
      localStorage.removeItem('workspaceName');
      localStorage.removeItem('persist:root');
      return getInitialState();
    });
  },
});

const authReducer = authSlice.reducer;
export default authReducer;

export const { clearAuthErrors, setSocketId } = authSlice.actions;

export const selectCurrentUserId = (state) => state.auth.user.id;
export const selectCurrentUserRole = (state) => state.auth.user.role;
export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentUserWorkspaceId = (state) => state.auth.workspaceId;
