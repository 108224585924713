const { api, providesList } = require('api');

const commentAPI = api.enhanceEndpoints({ addTagTypes: ['Comment'] }).injectEndpoints({
  endpoints: (build) => ({
    createComment: build.mutation({
      query: ({ entityType, entityId, entityTag, ...body }) => ({
        url: `/${entityType}/${entityId}/comments`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, { entityId, entityTag }) =>
        result
          ? [
            { id: 'LIST', type: `Comment` },
            { id: 'LIST', type: entityTag },
            ...(entityTag ? [{ id: entityId, type: entityTag }] : []),
          ]
          : [],
    }),
    updateComment: build.mutation({
      query: ({ entityType, entityId, id, entityTag, ...body }) => ({
        url: `/${entityType}/${entityId}/comments/${id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, { id, entityId, entityTag }) =>
        result
          ? [{ type: 'Comment', id }, ...(entityTag ? [{ id: entityId, type: entityTag }] : [])]
          : [],
    }),
    getComments: build.query({
      query: ({ entityType, entityId, ...queryParam }) => {
        const queryString = Object.entries(queryParam)
          .filter(([key, value]) => value !== undefined)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
        return `/${entityType}/${entityId}/comments?${queryString}`
      },
      providesTags: (result) => (result ? providesList(result, 'Comment') : []),
      transformResponse: (response) => response.data,
    }),

    deleteComment: build.mutation({
      query: ({ entityType, entityId, entityTag, id }) => ({
        url: `/${entityType}/${entityId}/comments/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id, entityId, entityTag }) =>
        result
          ? [{ type: 'Comment', id }, ...(entityTag ? [{ id: entityId, type: entityTag }] : [])]
          : [],
    }),
  }),
});

export const {
  useGetCommentsQuery,
  useCreateCommentMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
} = commentAPI;
