import GanttIcon from '@blackhyve/common/src/icons/GanttIcon';
import GridViewIcon from '@mui/icons-material/GridView';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ContentLayout } from 'components/layouts';
import { SecondaryNav, SecondaryNavLink } from 'components/navigation/SecondaryNav';
import { selectCurrentUserWorkspaceId } from 'features/auth';
import GanttOnlineUsers from 'features/gantt/components/GanttOnlineUsers';
import { mapRoutes } from 'features/taskMap/routes';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useParams } from 'react-router-dom';

export const Planning = () => {
  const projectId = Number(useParams().projectId) || undefined;
  const currentWorkspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const channelName = `workspaces.${currentWorkspaceId}.projects.${projectId}.tasks`;

  return (
    <>
      <ContentLayout
        header={
          <SecondaryNav>
            <Stack useFlexGap alignItems={'center'} direction={'row'} p={1} spacing={1} width={1}>
              <SecondaryNavLink
                size="medium"
                startIcon={<GanttIcon fontSize="inherit" />}
                to={'gantt'}
              >
                Gantt
              </SecondaryNavLink>
              {/* <SecondaryNavLink
                  size="medium"
                  startIcon={<ListIcon fontSize="inherit" />}
                  to={'list'}
                >
                  List
                </SecondaryNavLink> */}
              <SecondaryNavLink
                size="medium"
                startIcon={<GridViewIcon fontSize="inherit" />}
                to={'map'}
              >
                Map
              </SecondaryNavLink>
              <Box marginLeft={'auto'}>
                <GanttOnlineUsers channelNames={[channelName]} />
              </Box>
            </Stack>
          </SecondaryNav>
        }
      >
        <Outlet />
      </ContentLayout>
    </>
  );
};

export const PlanningRoutes = [
  {
    path: '',
    element: <Planning />,
    children: [
      {
        path: 'gantt',
        lazy: async () => {
          const { PlanningBoard } = await import('./PlanningBoard');
          return { Component: PlanningBoard };
        },
      },
      // { path: 'list', element: <ListView /> },
      { path: 'map', children: mapRoutes },
      { path: '*', element: <Navigate replace to={`./gantt`} /> },
      { index: true, element: <Navigate replace to={`./gantt`} /> },
    ],
  },
];
