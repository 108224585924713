const NoDataAvailableComponent = () => {
  return (
    <p
      style={{
        height: "300px",
        display: "flex",
        fontSize: "14px",
        color: "#8E8E90",
        fontWeight: "bold",
        borderRadius: "4px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      No data available. Please add some data!
    </p>
  );
};

export default NoDataAvailableComponent;
