import { Avatar, Box, Fab, Paper, TableContainer, Toolbar, Tooltip } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import SearchBox from 'components/search/SearchBox';
import { Table } from 'components/table/Table';
import { useMemo, useState } from 'react';
import { useGetUsersByCompanyIdQuery } from '../api/users.api';
import ContactMoreOptions from './ContactMoreOptions';
import InviteContactDialog from './InviteContactDialog';
import { useGetCompaniesQuery, useGetCompanyByIdQuery } from 'features/companies/api/companies.api';

const emptyArray = [];

const roleMap = { admin: 'Admin', member: 'Member', super_admin: 'Super Admin' };

const columns = [
  {
    field: 'profile_image',
    skeleton: <Skeleton height={40} variant="circular" width={40} />,
    render: (contact) => (
      <Avatar src={contact?.profile_image?.thumb_url}>
        {contact.first_name[0]}
        {contact.last_name[0]}
      </Avatar>
    ),
  },
  {
    label: 'First Name',
    field: 'first_name',
    sort: true,
  },
  {
    label: 'Last Name',
    field: 'last_name',
    sort: true,
  },
  {
    label: 'Title',
    field: 'title',
    sort: true,
  },
  {
    label: 'Email',
    field: 'email',
    sort: true,
  },
  {
    label: 'Phone',
    field: 'phone',
    cellProps: { align: 'right' },
  },
  {
    label: 'Role',
    field: 'role',
    sort: true,
    render: (user, meta) => roleMap[user?.role],
  },
  {
    label: 'Offices',
    field: 'offices',
    render: (contact) => contact.offices.map((office) => office.name).join(', '),
  },
  {
    label: 'Trades',
    field: 'trades',
    render: (contact) => contact?.trades?.map((trade) => trade.name).join(', '),
  },
  {
    label: 'Status',
    field: 'email_verified_at',
    sort: true,
    render: (contact) => (!!contact?.email_verified_at ? 'Verified' : 'Pending'),
  },
  {
    field: 'moreOptions',
    cellProps: { align: 'right' },
    render: (contact, meta) => (
      <ContactMoreOptions contact={contact} workspaceOwner={meta?.workspaceOwner} />
    ),
  },
];

export const ContactsTable = ({ companyId }) => {
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [search, setSearch] = useState('');

  const { data: company } = useGetCompanyByIdQuery({ companyId });

  const {
    data: contacts = emptyArray,
    isLoading,
    isFetching,
  } = useGetUsersByCompanyIdQuery({
    companyId,
  });

  const filteredColumns = useMemo(() => {
    if (company && company?.workspace_owner) {
      return columns;
    } else {
      return columns.filter((column) => column.field !== 'role');
    }
  }, [company]);

  const filteredContacts = contacts.filter((contact) =>
    (contact.first_name + contact.last_name).toLowerCase().includes(search.toLowerCase())
  );

  return (
    <TableContainer component={Paper}>
      <Toolbar style={{ position: 'sticky', left: '0px' }}>
        <SearchBox
          bgcolor={'grey.200'}
          borderRadius={1}
          fullWidth={false}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <Box ml={'auto'} />
        <Tooltip arrow placement={'top'} title={'Add'}>
          <Fab color="primary" size="small" onClick={() => setIsInviteDialogOpen(true)}>
            <Add />
          </Fab>
        </Tooltip>
        <InviteContactDialog
          companyId={companyId}
          handleClose={() => setIsInviteDialogOpen(false)}
          open={isInviteDialogOpen}
          workspaceOwner={Boolean(company?.workspace_owner)}
        />
      </Toolbar>
      <Table
        columns={filteredColumns}
        defaultOrderBy={'first_name'}
        isFetching={isFetching}
        isLoading={isLoading}
        meta={{ workspaceOwner: Boolean(company?.workspace_owner) }}
        rowProps={(row) => row.deleted_at && { style: { opacity: 0.5 } }}
        rows={filteredContacts}
      />
    </TableContainer>
  );
};
