import { useEffect, useMemo, useState } from 'react';
import {
  arePolygonsOverlapping,
  getClosestEdge,
  getClosestPerpendicularPoints,
  getClosestPointInPolygon,
} from '../utils/polygonUtils';

/**
 * @typedef {Object} Point
 * @property {number} x - The x-coordinate of the point.
 * @property {number} y - The y-coordinate of the point.
 */

/**
 * @typedef {Object} Polygon
 * @property {number} id - The unique identifier of the polygon.
 * @property {Array<Point>} points - The array of points defining the polygon.
 */

/**
 * @typedef {Object} DragPolygonsReturn
 * @property {Array<Polygon>} polygons - The array of polygons with their respective points.
 * @property {boolean} isDragging - Indicates whether a polygon or point is currently being dragged.
 * @property {Function} setSelectedPoint - A function to set the index of the selected point during dragging.
 * @property {number} selectedPoint - The index of the selected point during dragging.
 * @property {Function} setSelectedPolygon - A function to set the ID of the selected polygon during dragging.
 * @property {number} selectedPolygon - The ID of the selected polygon during dragging.
 * @property {Function} handleDragStart - A function to handle the start of dragging a polygon or point.
 * @property {Function} handleDrag - A function to handle dragging of a polygon or point.
 * @property {Function} handleDragEnd - A function to handle the end of dragging a polygon or point.
 * @property {Function} addPointToPolygonAtPosition - A function to add a point to a polygon at a specific position.
 * @property {Function} removePointFromPolygon - A function to remove a point from a polygon.
 */

/**
 * A custom React hook for handling draggable polygons with optional snapping functionality.
 * @param {Array<Polygon>} initialPolygons - The initial array of polygons with their respective points.
 * @param {Object} [options] - An optional object containing configuration options.
 * @param {Function} [options.onChange] - A function to be called whenever the polygons are updated.
 * @param {boolean} [options.enableSnapping=true] - Enable or disable snapping functionality.
 * @param {number} [options.snapDistance=10] - The distance in pixels within which snapping occurs.
 * @param {number} [options.scale=1] - The scaling factor used to adjust the snap distance.
 * @returns {DragPolygonsReturn} An object containing state variables and functions for handling draggable polygons.
 */
export function useDragPolygons(initialPolygons, options = {}) {
  const {
    disableSnap = false,
    snapDistance: defaultSnapDistance = 10,
    disableOverlap = true,
    onChange,
    size,
  } = options;

  const [polygons, setPolygons] = useState(initialPolygons);
  const [dragStartPos, setDragStartPos] = useState(undefined);
  const [polygonOriginalPoints, setPolygonOriginalPoints] = useState(undefined);
  const [snapEdgeX, setSnapEdgeX] = useState(undefined);
  const [snapEdgeY, setSnapEdgeY] = useState(undefined);
  const [snapPoint, setSnapPoint] = useState(undefined);
  const [selectedPolygon, setSelectedPolygon] = useState(undefined);
  const [selectedPoint, setSelectedPoint] = useState(undefined);
  const [overlappingPolygonId, setOverlappingPolygonId] = useState(undefined);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    setPolygons(initialPolygons);
  }, [initialPolygons]);

  const movePolygon = ({ polygonId, deltaX, deltaY }) => {
    setPolygons((prevPolygons) => {
      const updatedPolygons = prevPolygons.map((polygon) =>
        polygon.id === polygonId
          ? {
            ...polygon,
            points: polygonOriginalPoints.map((point) => ({
              ...point,
              x: size ? Math.min(Math.max(point.x + deltaX, 0), size.x) : point.x + deltaX,
              y: size ? Math.min(Math.max(point.y + deltaY, 0), size.y) : point.y + deltaY,
            })),
          }
          : polygon
      );
      if (disableOverlap) {
        setOverlappingPolygonId(
          arePolygonsOverlapping(
            updatedPolygons.filter((polygon) => polygon.points).map((polygon) => polygon.points)
          )
            ? polygonId
            : undefined
        );
      }
      return updatedPolygons;
    });
  };

  const movePoint = ({ polygonId, pointIndex, position, snapDistance = defaultSnapDistance }) => {
    let newPosition = {
      x: size ? Math.min(Math.max(position.x, 0), size.x) : position.x,
      y: size ? Math.min(Math.max(position.y, 0), size.y) : position.y,
    };

    if (!disableSnap) {
      // Find the closest point to position in polygons other than polygonId
      let closestPoint = polygons
        .filter((polygon) => polygon.id !== polygonId && polygon.points)
        .reduce(
          (minDistancePolygonPoint, polygon) => {
            const closestPointInPolygon = getClosestPointInPolygon(position, polygon.points);
            if (closestPointInPolygon.distance < minDistancePolygonPoint.distance) {
              return closestPointInPolygon;
            } else {
              return minDistancePolygonPoint;
            }
          },
          { point: undefined, distance: Infinity }
        );

      // Set snapPoint if closest point is within snapDistance
      const snapPoint = closestPoint.distance <= snapDistance ? closestPoint.point : undefined;

      // Update the snapping points based on the found closest points
      if (snapPoint) {
        newPosition = snapPoint;
        setSnapPoint(closestPoint.point);
        setSnapEdgeX(undefined);
        setSnapEdgeY(undefined);
      } else {
        const currentPolygon = polygons.find((polygon) => polygon.id === polygonId);
        const numberOfPoints = currentPolygon.points.length;
        const prevPoint = currentPolygon.points[(pointIndex - 1 + numberOfPoints) % numberOfPoints];
        const nextPoint = currentPolygon.points[(pointIndex + 1 + numberOfPoints) % numberOfPoints];
        const adjacentPoints = [prevPoint, nextPoint];

        // Get closest perpendicular points
        const [snapPointX, snapPointY] = getClosestPerpendicularPoints(
          position,
          adjacentPoints,
          snapDistance
        );

        // Update state
        setSnapPoint(undefined);
        if (snapPointX) {
          newPosition.x = size ? Math.min(Math.max(snapPointX.x, 0), size.x) : snapPointX.x;
          setSnapEdgeX({
            x1: newPosition.x,
            y1: snapPointX.y,
            x2: newPosition.x,
            y2: snapPointY?.y || position.y,
          });
        } else {
          setSnapEdgeX(undefined);
        }
        if (snapPointY) {
          newPosition.y = size ? Math.min(Math.max(snapPointY.y, 0), size.y) : snapPointY.y;
          setSnapEdgeY({
            x1: snapPointY.x,
            y1: newPosition.y,
            x2: snapPointX?.x || position.x,
            y2: newPosition.y,
          });
        } else {
          setSnapEdgeY(undefined);
        }
      }
    }

    // Update the point coordinates based on snapping or closest points on axes
    setPolygons((prevPolygons) => {
      const updatedPolygons = prevPolygons.map((polygon) =>
        polygon.id === polygonId
          ? {
            ...polygon,
            points: polygon.points?.map((point, index) =>
              index === pointIndex ? newPosition : point
            ),
          }
          : polygon
      );
      if (!disableOverlap) {
        setOverlappingPolygonId(
          arePolygonsOverlapping(
            updatedPolygons.filter((polygon) => polygon.points).map((polygon) => polygon.points)
          )
            ? polygonId
            : undefined
        );
      }
      return updatedPolygons;
    });
  };

  const removePointFromPolygon = (polygonId, removeIndex) => {
    const updatedPolygons = polygons.map((polygon) =>
      polygon.id === polygonId && polygon.points.length > 3
        ? {
          ...polygon,
          points: polygon.points.filter((point, index) => index !== removeIndex),
        }
        : polygon
    );
    if (onChange) onChange(updatedPolygons);
    setPolygons(updatedPolygons);
  };

  const addPointToPolygon = (polygonId, point, insertIndex) => {
    const updatedPolygons = polygons.map((polygon) =>
      polygon.id === polygonId
        ? {
          ...polygon,
          points: [
            ...polygon.points.slice(0, insertIndex + 1),
            point,
            ...polygon.points.slice(insertIndex + 1),
          ],
        }
        : polygon
    );
    if (onChange) onChange(updatedPolygons);
    setPolygons(updatedPolygons);
  };

  const handleDragStart = (polygonId, pointIndex, position) => {
    setDragStartPos(position);
    setSelectedPoint(pointIndex);
    setPolygonOriginalPoints(polygons.find((polygon) => polygon.id === polygonId).points);
    setIsDragging(true);
  };

  const handleDrag = (polygonId, pointIndex, position, snapDistance = defaultSnapDistance) => {
    if (polygonId !== undefined && pointIndex !== undefined) {
      movePoint({
        polygonId,
        pointIndex,
        position,
        snapDistance:
          typeof snapDistance === 'function' ? snapDistance(defaultSnapDistance) : snapDistance,
      });
    } else if (polygonId !== undefined) {
      const deltaX = position.x - dragStartPos.x;
      const deltaY = position.y - dragStartPos.y;
      movePolygon({ polygonId, deltaX, deltaY });
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);

    setPolygonOriginalPoints(undefined);

    // Reset snap points
    setSnapPoint(undefined);
    setSnapEdgeY(undefined);
    setSnapEdgeX(undefined);
    if (onChange) onChange(polygons);
  };

  const addPointToPolygonAtPosition = (polygonId, position) => {
    // Find the selected polygon
    const selectedPolygon = polygons.find((polygon) => polygon.id === polygonId);
    if (selectedPolygon) {
      // Calculate the closest edge to the double-clicked position
      let closestEdge = getClosestEdge(selectedPolygon.points, position);

      // Insert a new point on the closest edge
      if (closestEdge) {
        addPointToPolygon(polygonId, position, closestEdge.startIndex);
      }
    }
  };

  return {
    polygons,
    isDragging,
    setSelectedPoint,
    selectedPoint,
    setSelectedPolygon,
    selectedPolygon,
    addPointToPolygonAtPosition,
    removePointFromPolygon,
    handleDragStart,
    handleDrag,
    handleDragEnd,
    snapPoint,
    snapEdgeX,
    snapEdgeY,
    invalidPolygonId: overlappingPolygonId,
    isError: Boolean(overlappingPolygonId),
    size,
  };
}
