import ganttStore from './ganttStore';

export const initSetting = (canEdit, ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  const isTestEnv = process.env.NODE_ENV === 'test';

  gantt.plugins({
    marker: true,
    auto_scheduling: true,
    click_drag: true,
    keyboard_navigation: true,
    multiselect: true,
    critical_path: false,
    export_api: true,
    tooltip: true,
  });

  gantt.config = {
    ...gantt.config,
    readonly: !canEdit,

    auto_types: false,
    auto_scheduling: true,
    auto_scheduling_strict: false,
    auto_scheduling_compatibility: false,
    auto_scheduling_initial: true,
    deepcopy_on_parse: true,
    date_format: '%Y-%m-%d',
    date_grid: '%m-%d-%Y',
    project_start: undefined,
    project_end: undefined,
    round_dnd_dates: false, // Setting it to false, as it was not allowing the task to be dragged according to the time step.
    correct_work_time: true,
    work_time: true,
    time_step: 60 * 24,
    open_tree_initially: true,
    show_tasks_outside_timescale: true,
    show_unscheduled: true,
    drag_multiple: false,
    static_background: isTestEnv ? false : true,
    static_background_cells: isTestEnv ? false : true,
    smart_rendering: isTestEnv ? false : true,
    grid_elastic_columns: true,
    smart_scales: isTestEnv ? false : true,
    inline_editors_multiselect_open: true,
    keyboard_navigation: true,
    keyboard_navigation_cells: true,
    order_branch: 'marker',
    order_branch_free: true,
    placeholder_task: true,
    scale_height: 35,
    task_height: 16,
    row_height: 35,
    bar_height: 16,
    fit_tasks: false,
    sort: true,
    min_duration: 24 * 60 * 60 * 1000, // (1 day)
    duration_unit: 'day',
    links: {
      finish_to_start: 'FS',
      start_to_start: 'SS',
      finish_to_finish: 'FF',
      start_to_finish: 'SF',
    },
    drag_progress: false,
    constraint_types: {
      ASAP: 'asap',
      ALAP: 'alap',
      SNET: 'snet',
      SNLT: 'snlt',
      FNET: 'fnet',
      FNLT: 'fnlt',
      MSO: 'mso',
      MFO: 'mfo',
    },
  };

  gantt.locale.labels.section_baseline = 'Baseline';
  gantt.templates.link_class = function (link) {
    if (gantt?.config?.show_critical_path && gantt.getTask(link.target)?.critical_path) {
      return 'gantt_critical_link';
    }
    return '';
  };
  gantt.templates.task_class = (start, end, task) => {
    let classes = [];
    if (gantt?.config?.show_critical_path && task?.critical_path) {
      classes.push('gantt_critical_task');
    }
    // console.log(gantt.config.highlight_task_pace);
    if (gantt?.config?.highlight_task_pace) {
      if (task.status === 'active' || task.status === 'complete') {
        if (task.pace <= 0) {
          classes.push('on_pace_task');
        } else if (task.pace > 0) {
          classes.push('behind_pace_task');
        }
      } else {
        classes.push('no_pace_task');
      }
    }
    if (task?.status === 'complete') {
      classes.push('complete_task');
    }
    if (task?.status === 'active' && task.type !== 'parent_task') {
      classes.push('active_task');
    }
    // if (task?.$target && task.$target.length > 0) {
    //   classes.push('dependent_task');
    // }

    if (task.type === 'parent_task') {
      classes.push('gantt_project gantt_bar_project');
    }
    if (task.type === 'project_bar') {
      classes.push('gantt_project gantt_bar_project');
    }
    return classes.join(' ');
  };

  gantt.templates.grid_row_class = function (start, end, task) {
    // if (task.status === 'complete') return 'complete_task';
  };

  gantt.templates.timeline_cell_class = (task, date) => {
    if (!gantt.isWorkTime({ task: task, date: date }) && gantt?.ext?.zoom.getCurrentLevel() === 4) {
      return 'weekend';
    }
    return '';
  };

  // gantt.config.external_render = {
  //   // checks the element is a React element
  //   isElement: (element) => {
  //     return React.isValidElement(element);
  //   },
  //   // renders the React element into the DOM
  //   renderElement: (element, container) => {
  //     const root = createRoot(container);
  //     root.render(element);
  //   },
  // };

  const dateEditor = gantt.config.editor_types.date;
  const numberEditor = gantt.config.editor_types.number;

  gantt.config.editor_types.effort_hours = gantt.mixin(
    {
      set_value: function (value, id, column, node) {
        var correctedValue = parseFloat(value.effort_hours);
        return numberEditor.set_value.apply(numberEditor, [correctedValue, id, column, node]);
      },
      save: (id, column, node) => {
        let effortHours = parseFloat(
          numberEditor.get_value.apply(numberEditor, [id, column, node])
        );
        let task = gantt.getTask(id);
        task.effort_hours = !isNaN(effortHours) ? effortHours.toFixed(2) : undefined;
        task.effort_tag = null;
        gantt.updateTask(id);
      },
    },
    numberEditor
  );

  gantt.config.editor_types.end_date = gantt.mixin(
    {
      set_value: function (value, id, column, node) {
        var correctedValue = gantt.date.add(value, -1, 'day');
        return dateEditor.set_value.apply(this, [correctedValue, id, column, node]);
      },
      get_value: function (id, column, node) {
        var selectedValue = dateEditor.get_value.apply(this, [id, column, node]);
        var task = gantt.getTask(id);
        if (!gantt.isWorkTime({ date: selectedValue, task: task })) {
          return gantt.getClosestWorkTime({
            date: selectedValue,
            task: task,
            dir: 'past',
          });
        } else {
          return gantt.date.add(selectedValue, 1, 'day');
        }
      },
    },
    dateEditor
  );

  gantt.config.editor_types.start_date = gantt.mixin(
    {
      get_value: function (id, column, node) {
        var selectedValue = dateEditor.get_value.apply(this, [id, column, node]);
        var task = gantt.getTask(id);
        return gantt.getClosestWorkTime({
          date: selectedValue,
          task: task,
          dir: 'future',
        });
      },
    },
    dateEditor
  );
};
