import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import {
  archiveCompanyContactAPI,
  assignContactToProjectAPI,
  createCompanyContactAPI,
  deleteCompanyContactAPI,
  fetchCompanyContactAPI,
  fetchCompanyContactsAPI,
  removeContactFromProjectAPI,
  restoreCompanyContactAPI,
  toggleCompanyContactReportAPI,
  updateCompanyContactAPI,
} from 'features/contacts/api/contactsService';
import { handleAPIError } from 'helpers/handleAPIError';
import { difference } from 'lodash';
import format from 'date-fns/format';
import { selectContactIdsByProjectIdCompanyId } from 'features/contacts/store/projectContactsSlice';
import {
  fetchCompaniesAndContacts,
  fetchProjectCompanies,
} from '../../companies/store/companiesSlice';

/**
 * Fetch a companies contacts
 * @param {Object} requestParameters - An object that holds the parameters for the api request
 * @param {Number} requestParameters.companyId - The id of the company to fetch contacts for
 * @return {Promise} - A promise that resolves to the data returned from the API, or rejects with an error
 */
export const fetchCompanyContacts = createAsyncThunk(
  'contact/fetchCompanyContacts',
  async ({ companyId }, { rejectWithValue }) => {
    const apiResponse = await fetchCompanyContactsAPI({ companyId });
    return handleAPIError(apiResponse, rejectWithValue);
  }
);

/**
 * Fetch a specific companies contact
 * @param {Object} requestParameters - An object that holds the parameters for the api request
 * @param {Number} requestParameters.companyId - The id of the company to fetch the contact for
 * @param {Number} requestParameters.companyId - The id of the contact to fetch
 * @return {Promise} - A promise that resolves to the data returned from the API, or rejects with an error
 */
export const fetchCompanyContact = createAsyncThunk(
  'contact/fetchCompanyContact',
  async ({ companyId, contactId }, { rejectWithValue }) => {
    const apiResponse = await fetchCompanyContactAPI({ companyId, contactId });
    return handleAPIError(apiResponse, rejectWithValue);
  }
);

/**
 * Create contact to a company
 * @param {Object} requestParameters - An object that holds the parameters for the api request
 * @param {Number} requestParameters.companyId - The id of the company to create a new contact for
 * @param {Object} requestParameters.contract - The contact object to create
 * @return {Promise} - A promise that resolves to the data returned from the API, or rejects with an error
 */
export const createCompanyContact = createAsyncThunk(
  'contact/createCompanyContact',
  async ({ companyId, contact }, { rejectWithValue }) => {
    const apiResponse = await createCompanyContactAPI({ companyId, contact });
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * Update company contact
 * @param {Object} requestParameters - An object that holds the parameters for the api request
 * @param {Number} requestParameters.companyId - The id of the company to delete the contact of
 * @param {Number} requestParameters.contactId - The id of the contact to update
 * @param {Object} requestParameters.contact - The contact object with fields to update
 * @return {Promise} - A promise that resolves to the data returned from the API, or rejects with an error
 */
export const updateCompanyContact = createAsyncThunk(
  'contact/updateCompanyContact',
  async ({ companyId, contactId, contact }, { rejectWithValue }) => {
    const apiResponse = await updateCompanyContactAPI({
      companyId,
      contactId,
      contact: {
        ...contact,
        email_notifications: contact.email_notifications ? true : false,
        sms_notifications: contact.sms_notifications ? true : false,
      },
    });
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * Delete Contact
 * @param {Object} requestParameters - An object that holds the parameters for the api request
 * @param {Number} requestParameters.companyId - The id of the company to delete the contact of
 * @param {Number} requestParameters.contactId - The id of the contact to delete
 * @return {Promise} - A promise that resolves to the data returned from the API, or rejects with an error
 */

export const deleteCompanyContact = createAsyncThunk(
  'contact/deleteCompanyContact',
  async ({ companyId, contactId }, { rejectWithValue }) => {
    const apiResponse = await deleteCompanyContactAPI({ companyId, contactId });
    return handleAPIError(apiResponse, rejectWithValue);
  }
);

/**
 * Archive Contact
 * @param {Object} requestParameters - An object that holds the parameters for the api request
 * @param {Number} requestParameters.companyId - The id of the company to delete the contact of
 * @param {Number} requestParameters.contactId - The id of the contact to delete
 * @return {Promise} - A promise that resolves to the data returned from the API, or rejects with an error
 */

export const archiveCompanyContact = createAsyncThunk(
  'contact/archiveCompanyContact',
  async ({ companyId, contactId }, { rejectWithValue }) => {
    const apiResponse = await archiveCompanyContactAPI({ companyId, contactId });
    return handleAPIError(apiResponse, rejectWithValue);
  }
);

/**
 * Restore Contact
 * @param {Object} requestParameters - An object that holds the parameters for the api request
 * @param {Number} requestParameters.companyId - The id of the company to delete the contact of
 * @param {Number} requestParameters.contactId - The id of the contact to delete
 * @return {Promise} - A promise that resolves to the data returned from the API, or rejects with an error
 */

export const restoreCompanyContact = createAsyncThunk(
  'contact/restoreCompanyContact',
  async ({ companyId, contactId }, { rejectWithValue }) => {
    const apiResponse = await restoreCompanyContactAPI({ companyId, contactId });
    return handleAPIError(apiResponse, rejectWithValue);
  }
);

/**
 * Assign Contact to project
 * @param {Object} requestParameters - An object that holds the parameters for the api request
 * @param {Number} requestParameters.projectId
 * @param {[Number]} requestParameters.contacts
 * @return {Promise} - A promise that resolves to the data returned from the API, or rejects with an error
 */

export const assignContactToProject = createAsyncThunk(
  'contact/assignContactToProject',
  async (requestParameters, { rejectWithValue }) => {
    const apiResponse = await assignContactToProjectAPI(requestParameters);
    return handleAPIError(apiResponse, rejectWithValue);
  }
);

/**
 * Remove Contact to project
 * @param {Object} requestParameters - An object that holds the parameters for the api request
 * @param {Number} requestParameters.projectId
 * @param {[Number]} requestParameters.contacts
 * @return {Promise} - A promise that resolves to the data returned from the API, or rejects with an error
 */

export const removeContactFromProject = createAsyncThunk(
  'contact/removeContactFromProject',
  async (requestParameters, { rejectWithValue }) => {
    const apiResponse = await removeContactFromProjectAPI(requestParameters);
    return handleAPIError(apiResponse, rejectWithValue);
  }
);

/** Toggle contacts project company report
 * @param {object} requestParameter
 * @param {number} requestParameter.projectId
 * @param {number} requestParameter.contactId
 */
export const toggleContactCompanyReport = createAsyncThunk(
  'contact/toggleContactCompanyReport',
  async ({ projectId, contactId }, { rejectWithValue }) => {
    const apiResponse = await toggleCompanyContactReportAPI({ projectId, contactId });
    return handleAPIError(apiResponse, rejectWithValue);
  }
);

export const contactsAdapter = createEntityAdapter({
  sortComparer: (prev, next) => {
    const prevFirstName = prev.first_name;
    const nextFirstName = next.first_name;
    return prevFirstName.localeCompare(nextFirstName);
  },
});

const initialState = contactsAdapter.getInitialState({});

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    clearContacts: (state) => {
      contactsAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    /* Fetch company contacts */
    builder.addCase(fetchCompanyContacts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCompanyContacts.fulfilled, (state, action) => {
      state.loading = false;
      contactsAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(fetchCompanyContacts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    /* Fetch company contact */
    builder.addCase(fetchCompanyContact.fulfilled, (state, action) => {
      contactsAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(fetchCompanyContact.rejected, (state, action) => {
      state.error = action.payload;
    });

    /* Create company contact */
    builder.addCase(createCompanyContact.fulfilled, (state, action) => {
      contactsAdapter.addOne(state, action.payload);
    });
    builder.addCase(createCompanyContact.rejected, (state, action) => {
      console.log('Error =>', action.payload);
      state.error = action.payload;
    });

    /* Update company contact */
    builder.addCase(updateCompanyContact.fulfilled, (state, action) => {
      contactsAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(updateCompanyContact.rejected, (state, action) => {
      state.error = action.payload;
    });

    /* Delete company contact */
    builder.addCase(deleteCompanyContact.pending, (state, action) => {
      contactsAdapter.upsertOne(state, { id: action.meta.arg.contactId, loading: true });
    });
    builder.addCase(deleteCompanyContact.fulfilled, (state, action) => {
      contactsAdapter.removeOne(state, action.meta.arg.contactId);
    });
    builder.addCase(deleteCompanyContact.rejected, (state, action) => {
      contactsAdapter.upsertOne(state, { id: action.meta.arg.contactId, loading: false });
      state.error = action.payload;
    });

    /** Archive company contact */
    builder.addCase(archiveCompanyContact.pending, (state, action) => {
      contactsAdapter.upsertOne(state, { id: action.meta.arg.contactId, loading: true });
    });
    builder.addCase(archiveCompanyContact.fulfilled, (state, action) => {
      contactsAdapter.upsertOne(state, {
        id: action.meta.arg.contactId,
        deleted_at: format(new Date(), 'yyyy-MM-dd'),
        loading: false,
      });
    });
    builder.addCase(archiveCompanyContact.rejected, (state, action) => {
      contactsAdapter.upsertOne(state, { id: action.meta.arg.contactId, loading: false });
      state.error = action.payload;
    });

    /** Restore company contact */
    builder.addCase(restoreCompanyContact.pending, (state, action) => {
      contactsAdapter.upsertOne(state, { id: action.meta.arg.contactId, loading: true });
    });
    builder.addCase(restoreCompanyContact.fulfilled, (state, action) => {
      contactsAdapter.upsertOne(state, {
        id: action.meta.arg.contactId,
        deleted_at: null,
        loading: false,
      });
    });
    builder.addCase(restoreCompanyContact.rejected, (state, action) => {
      contactsAdapter.upsertOne(state, { id: action.meta.arg.contactId, loading: false });
      state.error = action.payload;
    });

    builder.addCase(fetchProjectCompanies.fulfilled, (state, action) => {
      if (action.payload?.entities?.contacts) {
        contactsAdapter.upsertMany(state, action.payload?.entities?.contacts);
      }
    });

    builder.addCase(assignContactToProject.fulfilled, (state, action) => {
      console.log('action =>', action.meta);
    });

    builder.addCase(toggleContactCompanyReport.fulfilled, (state, action) => {
      contactsAdapter.upsertOne(state, action.payload);
    });

    /** Fetch companies and contacts */
    builder.addCase(fetchCompaniesAndContacts.fulfilled, (state, action) => {
      if (action.payload.entities.contacts)
        contactsAdapter.setAll(state, action.payload?.entities?.contacts);
    });
  },
});

export default contactsSlice.reducer;

export const {
  selectById: selectContactById,
  selectIds: selectContactIds,
  selectEntities: selectContactEntities,
  selectAll: selectAllContacts,
  selectTotal: selectTotalContacts,
} = contactsAdapter.getSelectors((state) => state.contacts);

export const { clearContacts } = contactsSlice.actions;

export const selectContactIdsByCompanyId = createSelector(
  [(state, companyId) => selectContactsByCompanyId(state, companyId)],
  (companyContacts) => {
    return companyContacts.map((contact) => contact.id);
  }
);

export const selectContactsByCompanyId = createSelector(
  [(state) => selectAllContacts(state), (_, companyId) => Number.parseInt(companyId)],
  (allContacts, companyId) => {
    if (!allContacts && !isNaN(companyId)) return undefined;
    return allContacts.filter((contact) => contact.company_id === companyId);
  }
);

export const selectContactIdsNotAssignedToProjectByCompany = createSelector(
  [
    (state, projectId, companyId) => selectContactIdsByCompanyId(state, companyId),
    (state, projectId, companyId) =>
      selectContactIdsByProjectIdCompanyId(state, projectId, companyId),
  ],
  (contactIdsByCompany, contactIdsByCompanyOnProject) => {
    return difference(contactIdsByCompany, contactIdsByCompanyOnProject);
  }
);

export const selectContactsNotAssignedToProjectByCompany = createSelector(
  [
    (state, projectId, companyId) => selectContactIdsByCompanyId(state, companyId),
    (state, projectId, companyId) =>
      selectContactIdsByProjectIdCompanyId(state, projectId, companyId),
    (state, projectId, companyId) => selectContactEntities(state),
  ],
  (contactIdsByCompany, contactIdsByCompanyOnProject, contactEntities) => {
    return difference(contactIdsByCompany, contactIdsByCompanyOnProject).map(
      (contactId) => contactEntities[contactId]
    );
  }
);
