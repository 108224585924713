import { useEffect } from 'react';
import { format } from 'date-fns';
import Grid from '@mui/material/Grid';
import isValid from 'date-fns/isValid';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { StyledDialog } from '@blackhyve/common';
import LoadingButton from '@mui/lab/LoadingButton';
import { Controller, useForm } from 'react-hook-form';
import { parseDate } from '@blackhyve/utilities/dates';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useResolveStuckPointMutation } from '../store/stuckPoint.api';

/**
 * Stuck Point Row
 * @param {Object} stuckPoint 
 * @param {Boolean} open 
 * @param {Object} handleClose 
 */
const StuckPointResolutionForm = ({ stuckPoint, open, handleClose }) => {

  return <StyledDialog
    handleClose={handleClose}
    maxWidth={'md'}
    open={open}
    title={'Resolve Stuck Point'}
  >

    <Grid container gap={1}>
      <Grid item xs={12}>
        <ResolutionForm
          handleClose={handleClose}
          id={stuckPoint?.id}
          openedOn={stuckPoint?.opened_on}
          resolutionNotes={stuckPoint?.resolution_notes}
          resolvedOn={stuckPoint.resolved_on}
        />
      </Grid>
    </Grid>
  </StyledDialog>
}


const initialState = {
  resolved_on: new Date(),
  resolution_notes: null
}

/**
 * @param {String} id 
 * @param {Date} openedOn 
 * @param {Date} resolvedOn 
 * @param {Object} handleClose 
 * @param {String} resolutionNotes 
 * @returns 
 */
export const ResolutionForm = ({ id, openedOn, handleClose, resolvedOn, resolutionNotes }) => {

  const { control, handleSubmit, reset, } = useForm({
    defaultValues: { ...initialState },
  });
  const [resolveStuckPoint, { isLoading: isStuckPointBeingResolved }] = useResolveStuckPointMutation();

  const handleSave = (data) => {
    resolveStuckPoint({
      id: data.id,
      resolved_on: format(data.resolved_on, 'yyyy-MM-dd'),
      resolution_notes: data.resolution_notes
    }).then(() => {
      reset({ ...initialState });
      handleClose && handleClose();
    })
  }

  useEffect(() => {
    reset({
      ...initialState, id,
      resolved_on: resolvedOn ? parseDate(resolvedOn) : initialState.resolved_on,
      opened_on: openedOn ? parseDate(openedOn) : initialState.opened_on,
      resolution_notes: resolutionNotes
    })
  }, [id, resolvedOn, openedOn, resolutionNotes, reset]);

  return <form onSubmit={handleSubmit(handleSave)}>
    <Grid container gap={1}>
      <Grid item xs={12}>
        <FormLabel>Resolution Note</FormLabel>
        <Controller
          control={control}
          name="resolution_notes"
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              multiline
              error={error}
              helperText={error?.message}
              minRows={5}
              placeholder="Resolution notes"
              size="small"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Resolved On</FormLabel>
        <Controller
          control={control}
          id="resolved_on"
          name="resolved_on"
          render={({
            field: { onChange, value, ref, ...field },
            fieldState: { error, invalid },
          }) => (
            <DatePicker
              maxDate={new Date()}
              minDate={parseDate(openedOn)}
              ref={ref}
              value={value}
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small',
                  helperText: error?.message,
                  error: error,
                  ...field,
                },
                actionBar: {
                  actions: ['today'],
                },
              }}
              onChange={onChange}
            />
          )}
          rules={{
            required: 'Resolved on date is required',
            validate: {
              isDate: (v) => isValid(v) || 'Invalid Date',
            },
          }}
        />
      </Grid>
      <Grid container item justifyContent={'flex-end'} xs={12}>
        {handleClose && (
          <Button size="small" onClick={handleClose}>
            Close
          </Button>
        )}
        <LoadingButton loading={isStuckPointBeingResolved} size='small' type="submit" variant="contained">
          Resolve
        </LoadingButton>
      </Grid>
    </Grid>

  </form>


}

export default StuckPointResolutionForm;