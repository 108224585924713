import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import EditPredecessorDialog from 'components/projectOverview/gantt/components/dialog/EditPredecessorDialog';
import { useState } from 'react';

export const PredecessorsCell = ({ gantt, task, children }) => {
  const [isPredecessorDialogOpen, setIsPredecessorDialogOpen] = useState(false);
  const disabled = task.readonly === true || gantt.config.readonly;
  return (
    <div className={`predecessor-column ${disabled ? 'cell_disabled' : ''}`}>
      <span className="predecessor-label">{children}</span>
      <span className="edit-span">
        <IconButton
          size="small"
          sx={{ p: 0 }}
          onClick={(event) => {
            event.stopPropagation();
            setIsPredecessorDialogOpen(true);
          }}
        >
          <Edit color={'inherit'} />
        </IconButton>
        {isPredecessorDialogOpen && (
          <EditPredecessorDialog
            gantt={gantt}
            handleClose={() => setIsPredecessorDialogOpen(false)}
            open={isPredecessorDialogOpen}
            taskId={task.id}
          />
        )}
      </span>
    </div>
  );
};
