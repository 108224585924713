import * as React from "react";

const SvgSaveIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3H17L21 7V19C21 20.1 20.1 21 19 21H5C3.89001 21 3 20.1 3 19V5C3 3.89999 3.89001 3 5 3ZM19 19V7.83L16.17 5H5V19H19ZM12 12C10.34 12 9 13.34 9 15C9 16.66 10.34 18 12 18C13.66 18 15 16.66 15 15C15 13.34 13.66 12 12 12ZM15 6H6V10H15V6Z"
      fill="black"
      fill-opacity="0.54"
    />
  </svg>
);

export default SvgSaveIcon;
