import { ArrowBack } from '@mui/icons-material';
import { Breadcrumbs, Button, Link, Stack, Typography } from '@mui/material';
import { ContentLayout } from 'components/layouts';
import { ActionBar } from 'components/navigation/ActionBar';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { ZoneMapEditor } from '../components/ZoneMapEditor';
import { useGetLocationQuery } from '../store/location.api';
import { useGetZoneQuery } from '../store/zone.api';

export function Zone() {
  const navigate = useNavigate();
  const zoneId = Number(useParams().zoneId) || undefined;
  const locationId = Number(useParams().locationId) || undefined;
  const projectId = Number(useParams().projectId) || undefined;
  const {
    data: zone = {},
    isLoading: isLoadingZone,
    isFetching: isFetchingZone,
  } = useGetZoneQuery({ zoneId, locationId });
  const {
    data: location = {},
    isLoading: isLoadingLocation,
    isFetching: isFetchingLocation,
  } = useGetLocationQuery({ locationId });
  return (
    <ContentLayout
      header={
        <ActionBar>
          <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
            <Button
              color={'primary'}
              startIcon={<ArrowBack />}
              onClick={() => navigate('../map-view')}
            >
              Back
            </Button>
            <Breadcrumbs>
              <Link
                as={RouterLink}
                sx={{ color: (theme) => theme.palette.primary.main }}
                to={'../map-view'}
              >
                {location.name}
              </Link>
              <Typography color="text.primary">{zone.name}</Typography>
            </Breadcrumbs>
            <div></div>
          </Stack>
        </ActionBar>
      }
    >
      <ZoneMapEditor locationId={locationId} projectId={projectId} zoneId={zoneId} />
    </ContentLayout >
  );
}
