import { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Gantt from 'components/projectOverview/gantt/Gantt';
import { ActionBar } from 'components/navigation/ActionBar';
import { ContentLayout } from 'components/layouts/ContentLayout';
import CircularProgress from '@mui/material/CircularProgress';
import ProjectGanttHeader from '../config/ProjectGanttHeader';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import projectGanttColumns from 'features/projectsGantt/config/ProjectGanttColumns';
import { setTaskTemplate } from 'components/projectOverview/gantt/ganttConfig/bar/barRender';
import { initProjectGanttSettings } from 'features/projectsGantt/config/ProjectGanttSettings';
import { getProjectGanttZoomConfig } from 'features/projectsGantt/config/ProjectGanttZoomConfig';
import {
  registerEventListeners,
  removeEventListeners,
} from 'features/projectsGantt/config/ProjectGanttEvents';
import { useGetProjectsQuery } from 'features/projects/store/project.api';
import { useSelector } from 'react-redux';
import { selectCurrentUserWorkspaceId } from 'features/auth';

const ganttId = `project-portfolio-gantt`;

export const ProjectsGantt = () => {


  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { data: projects = [], isLoading } = useGetProjectsQuery({ workspaceId });
  const [gantt, setGantt] = useState(ganttStore.createGantt(ganttId));

  useEffect(() => {
    setGantt(ganttStore.createGantt(ganttId));
  }, []);



  return gantt && !isLoading ? (
    <ContentLayout
      header={
        <ActionBar>
          <ProjectGanttHeader ganttId={ganttId} />
        </ActionBar>
      }
    >
      <Gantt
        isFrozenColumn
        columns={projectGanttColumns}
        ganttId={ganttId}
        initSettings={initProjectGanttSettings}
        registerEventListeners={() => registerEventListeners(ganttId)}
        removeEventListeners={() => removeEventListeners(ganttId)}
        setTemplates={setTaskTemplate}
        zoomConfig={getProjectGanttZoomConfig}
        tasks={{
          data: formatProjectsForGantt(projects),
          links: [],
        }}
      />
    </ContentLayout>
  ) : (
    <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
      <CircularProgress className="loading" />
    </Backdrop>
  );
};

const formatProjectsForGantt = (projects) => {
  return projects?.map((project) => ({
    ...project,
    text: project.name,
    open: 0,
    parent: 0,
    type: 'project_task',
    progress: project.percent_complete,
    end_date: project.sub_completion_date,
    forecasted_end_date: project.est_completion_date,
    color: '#2a6750',
  }));
};
