import { Add, Remove } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Paper, Tooltip } from '@mui/material';
import { PolygonEditor } from 'features/polygons';
import { useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

export function Map({ background, PolygonEditorProps, disabled }) {
  const [scale, setScale] = useState(1);

  const updateScale = ({ state }) => {
    const newScale = state.scale;
    if (newScale !== scale) {
      setScale(newScale);
    }
  };

  return (
    <Box backgroundColor={'#c4c4c450'} height={'100%'} maxHeight={'100%'} width={'100%'}>
      <TransformWrapper
        centerOnInit
        doubleClick={{ disabled: true }}
        initialScale={1}
        minScale={0.95}
        panning={{ excluded: ['selected'] }}
        onTransformed={updateScale}
      >
        {(utils) => (
          <>
            <TransformComponent
              wrapperStyle={{
                width: '100%',
                height: '100%',
              }}
            >
              <PolygonEditor
                disabled={disabled}
                scale={scale}
                {...PolygonEditorProps}
                background={background}
              />
            </TransformComponent>
            <MapControls {...utils} />
          </>
        )}
      </TransformWrapper>
    </Box>
  );
}

function MapControls({ zoomIn, zoomOut }) {
  return (
    <Box as={Paper} elevation={5} position={'absolute'} right={16} top={16}>
      <ButtonGroup
        disableElevation
        color={'primary'}
        orientation={'vertical'}
        variant={'contained'}
      >
        <Tooltip arrow disableInteractive placement={'top'} title={'Zoom In'}>
          <Button sx={{ px: 1 }} onClick={() => zoomIn()}>
            <Add />
          </Button>
        </Tooltip>
        <Tooltip arrow disableInteractive placement={'top'} title={'Zoom Out'}>
          <Button sx={{ px: 1 }} onClick={() => zoomOut()}>
            <Remove />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Box>
  );
}
