import moment from 'moment';
import ganttStore from './ganttStore';

const initMakers = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  gantt.addMarker({
    start_date: moment().startOf('day'),
    css: 'today-marker',
    text: 'Today',
  });

  // const projectStartMaker = gantt.addMarker({
  //   start_date: gantt.config.start_date,
  //   css: 'today-marker',
  //   text: 'Start Project ',
  // });
  // const projectEndMarker = gantt.addMarker({
  //   start_date: gantt.config.end_date,
  //   css: 'today-marker',
  //   text: 'End Project ',
  // });
};

export default initMakers;
