import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const TaskFeedRowStyle = styled(Grid)(({ theme }) => ({
  borderTop: '1px solid lightgray',
  paddingTop: '10px !important',
  paddingBottom: '10px !important',
  cursor: 'pointer',
  paddingLeft: '6px',
  paddingRight: '6px',
  '&:hover': {
    backgroundColor: '#F5F5F5',
  },
  // '&:last-child': {
  //     borderBottom: '1px solid lightgray',
  // },
}));

export const TaskFeedStyleLabel = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  marginLeft: '5px',
}));

export const TaskFeedDateStyle = styled(Typography)(({ theme }) => ({
  fontWeight: '300',
  fontSize: '13px',
  marginLeft: 'auto',
}));

export const TaskFeedUserName = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  marginLeft: '5px',
  marginRight: '5px',
  fontSize: '15px',
}));
