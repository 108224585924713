import { ContentLayout } from 'components/layouts';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import TaskPortfolioActionBar from '../components/TaskPortfolioActionBar';
import { TaskPortfolioGantt } from '../components/TaskPortfolioGantt';
import { useTaskGanttDataProcessor } from '../hooks/useTaskGanttDataProcessor';
import ModalProvider from 'components/common/ModalProvider';
import { TaskGanttModals } from '../components/TaskGanttModals';

export const TaskPortfolio = () => {
  const ganttId = 'task-portfolio';
  const gantt = ganttStore.createGantt(ganttId);

  const { initDataProcessor, isSaving } = useTaskGanttDataProcessor();

  return !gantt ? null : (
    <ModalProvider>
      <ContentLayout
        header={<TaskPortfolioActionBar ganttId={ganttId} isSaving={isSaving} />}
        overflow={'hidden'}
      >
        <TaskPortfolioGantt ganttId={ganttId} initDataProcessor={initDataProcessor} />
        <TaskGanttModals />
      </ContentLayout>
    </ModalProvider>
  );
};
