import { memo } from 'react';
import Grid from '@mui/material/Grid';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import ProjectGroupByMenu from 'features/projects/components/ProjectGroupByMenu';


const ProjectGanttHeader = ({ ganttId }) => {
  const gantt = ganttStore.getGantt(ganttId);

  const generateServerList = (value) => {
    let array = [];
    gantt.eachTask((task) => {
      if (task.$virtual) {
        return;
      }
      array.push(task[value]);
    });
    array = [...new Set(array)];
    const groupCollection = array.map((item) => {
      return item ? { key: item, label: item } : { key: null, label: 'None' };
    });
    gantt.serverList(`${value}`, groupCollection);
  };

  const handleGroupBy = (value) => {
    if (!value) {
      gantt.groupBy(false);
    }
    generateServerList(value);
    gantt.groupBy({
      groups: gantt.serverList(value),
      relation_property: value,
      group_id: 'key',
      group_text: 'label',
    });
    gantt.sort('start_date');
  };

  return (
    <Grid container wrap={'nowrap'}>
      <Grid container item alignItems="center" justifyContent={'flex-end'} xs={'auto'}>
        <ProjectGroupByMenu handleUpdate={handleGroupBy} />
      </Grid>
    </Grid>
  );
};

export default memo(ProjectGanttHeader);
