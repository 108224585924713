import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  assignContactToProject,
  deleteCompanyContact,
  removeContactFromProject,
  selectContactEntities,
  toggleContactCompanyReport,
} from 'features/contacts/store/contactsSlice';
import {
  fetchProjectCompanies,
  removeCompanyFromProject,
} from '../../companies/store/companiesSlice';

export const projectContactsAdapter = createEntityAdapter({});

const initialState = projectContactsAdapter.getInitialState({});

const projectContactsSlice = createSlice({
  name: 'projectContacts',
  initialState,
  reducers: {
    addProjectContact: (state, action) => {
      projectContactsAdapter.addOne(state, action);
    },
    removeProjectContact: (state, action) => {
      projectContactsAdapter.removeOne(state, action);
    },
    clearProjectContacts: (state, action) => {
      projectContactsAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectCompanies.fulfilled, (state, action) => {
      const contacts = action.payload?.entities?.contacts;
      const projectId = action.meta.arg;
      if (projectId && contacts) {
        const projectContacts = Object.values(contacts).reduce((projectContacts, contact) => {
          const id = projectId + '-' + contact.id;
          return {
            ...projectContacts,
            [id]: {
              id,
              projectId: parseInt(projectId),
              contactId: contact.id,
              sendReport: contact.send_report,
            },
          };
        }, {});
        projectContactsAdapter.upsertMany(state, projectContacts);
      }
    });

    builder.addCase(assignContactToProject.fulfilled, (state, action) => {
      const projectId = action.meta.arg.projectId;
      const contactIds = action.meta.arg.contacts;
      const projectContacts = contactIds.reduce((projectContacts, contactId) => {
        const id = projectId + '-' + contactId;
        return { ...projectContacts, [id]: { id, projectId: parseInt(projectId), contactId } };
      }, {});
      projectContactsAdapter.upsertMany(state, projectContacts);
    });

    builder.addCase(removeContactFromProject.fulfilled, (state, action) => {
      const removedContactIds = action.meta.arg.contacts;
      const projectContactIdsToRemove = state.ids.filter((id) =>
        removedContactIds.includes(state.entities[id].contactId)
      );
      projectContactsAdapter.removeMany(state, projectContactIdsToRemove);
    });

    builder.addCase(removeCompanyFromProject.fulfilled, (state, action) => {
      const removedContactIds = action.payload.contactIdsToRemove;
      const projectId = action.meta.arg.projectId;
      const projectContactIdsToRemove = removedContactIds.map(
        (contactId) => projectId + '-' + contactId
      );
      projectContactsAdapter.removeMany(state, projectContactIdsToRemove);
    });

    builder.addCase(deleteCompanyContact.fulfilled, (state, action) => {
      const projectContactIdsToRemove = state.ids.filter(
        (id) => state.entities[id] === action.meta.arg.contactId
      );
      projectContactsAdapter.removeMany(state, projectContactIdsToRemove);
    });

    builder.addCase(toggleContactCompanyReport.fulfilled, (state, action) => {
      const projectId = action.meta.arg.projectId;
      const contactId = action.payload.id;
      const sendReport = action.payload.send_report;
      state.entities[`${projectId}-${contactId}`].sendReport = sendReport;
    });

    builder.addCase(toggleContactCompanyReport.pending, (state, action) => {
      const projectId = action.meta.arg.projectId;
      const contactId = action.meta.arg.contactId;
      state.entities[`${projectId}-${contactId}`].sendReport =
        !state.entities[`${projectId}-${contactId}`].sendReport;
    });

    builder.addCase(toggleContactCompanyReport.rejected, (state, action) => {
      const projectId = action.meta.arg.projectId;
      const contactId = action.meta.arg.contactId;
      state.entities[`${projectId}-${contactId}`].sendReport =
        !state.entities[`${projectId}-${contactId}`].sendReport;
    });
  },
});

const projectContactsReducer = projectContactsSlice.reducer;
export default projectContactsReducer;

export const { addProjectContact, removeProjectContact, clearProjectContacts } =
  projectContactsSlice.actions;

/** Selectors **/

export const {
  selectById: selectProjectContactById,
  selectIds: selectProjectContactIds,
  selectEntities: selectProjectContactEntities,
  selectAll: selectAllProjectContacts,
  selectTotal: selectTotalProjectContacts,
} = projectContactsAdapter.getSelectors((state) => state.projectContacts);

export const selectAllContactsByProjectId = createSelector(
  [
    (state, _) => selectAllProjectContacts(state),
    (state, _) => selectContactEntities(state),
    (state, projectId) => projectId,
  ],
  (allProjectContact, contactEntities, projectId) => {
    return allProjectContact
      .filter((projectContact) => projectContact.projectId == projectId)
      .map((projectContact) => contactEntities[projectContact.contactId])
      .sort((a, b) => a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase()));
  }
);

export const selectContactEntitiesByProjectId = createSelector(
  [(state, projectId) => selectAllContactsByProjectId(state, projectId)],
  (allProjectContactsForProject) => {
    return allProjectContactsForProject.reduce(
      (projectContactsEntitiesForProject, contactEntity) => ({
        ...projectContactsEntitiesForProject,
        [contactEntity.id]: contactEntity,
      }),
      {}
    );
  }
);

export const selectContactIdsByProjectId = createSelector(
  [(state, projectId) => selectAllContactsByProjectId(state, projectId)],
  (allProjectContacts) => {
    return allProjectContacts.map((contact) => contact.id);
  }
);

export const selectContactEntitiesByProjectIdCompanyId = createSelector(
  [
    (state, projectId) => selectAllContactsByProjectId(state, projectId),
    (state, projectId, companyId) => companyId,
  ],
  (allProjectContacts, companyId) => {
    return allProjectContacts.filter((contact) => contact.company_id === companyId);
  }
);
export const selectContactIdsByProjectIdCompanyId = createSelector(
  [
    (state, projectId, companyId) =>
      selectContactEntitiesByProjectIdCompanyId(state, projectId, companyId),
  ],
  (allProjectContacts) => {
    return allProjectContacts.map((contact) => contact.id);
  }
);

export const selectProjectContactByContactIdProjectId = createSelector(
  [
    (state) => selectProjectContactEntities(state),
    (state, projectId, contactId) => projectId,
    (state, projectId, contactId) => contactId,
  ],
  (projectContactEntities, projectId, contactId) => {
    return projectContactEntities[`${projectId}-${contactId}`];
  }
);
