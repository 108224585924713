import { api } from 'api';
import companiesSlice from 'features/companies/store/companiesSlice';
import taskCompaniesSlice from 'features/companies/store/taskCompaniesSlice';
import contactSlice from 'features/contacts/store/contactsSlice';
import recipeDependencies from 'features/recipes/store/recipeDependenciesSlice';
import { combineReducers } from 'redux';
import authSlice from '../features/auth/store/authSlice';
import recipeTasksSlice from '../features/recipes/store/recipeTasksSlice';
import usersSlice from '../features/users/store/usersSlice';
import customViewSlice from '../slices/customViewSlice';
import taskFeedSlice from '../slices/taskFeedSlice';
import snackBarReducer from './SnackBarReducer';

const appReducer = combineReducers({
  users: usersSlice,
  auth: authSlice,
  companies: companiesSlice,
  contacts: contactSlice,
  recipeTasks: recipeTasksSlice,
  recipeDependencies: recipeDependencies,
  snackBarReducer,
  taskCompanies: taskCompaniesSlice,
  taskFeeds: taskFeedSlice,
  customViews: customViewSlice,
  [api.reducerPath]: api.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout/fulfilled') {
    state = {};
  }
  return appReducer(state, action);
};

export default rootReducer;
