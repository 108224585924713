import { Button, DialogActions } from '@mui/material';
import Popover from '@mui/material/Popover';
import makeStyles from '@mui/styles/makeStyles';
import { SelectContactList } from 'features/contacts/components/SelectContactList';
import { useGetProjectCompaniesQuery, useGetWorkspaceCompanyQuery } from 'features/projectCompanies/api/projectCompanies.api';
import { createRef, forwardRef, useImperativeHandle, useState } from 'react';
import ganttStore from '../../ganttStore';
import { uniqBy } from 'lodash';

export const loadContactMenuType = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  let contactEditorRef;
  gantt.config.editor_types.contact = {
    show: function (id, column, config, placeholder) {
      const task = gantt.getTask(id);
      contactEditorRef = createRef();
      gantt.config.external_render.renderElement(
        <ContactMenuPopover
          anchorEl={placeholder}
          initialSelected={task.contacts}
          key={`${task.id}-contact-menu`}
          projectId={task.project_id}
          ref={contactEditorRef}
          onClose={() => gantt.ext.inlineEditors.save()}
        ></ContactMenuPopover>,
        placeholder
      );
    },
    hide: function () {
      // called when input is hidden
      // destroy any complex editors or detach event listeners from here
    },

    set_value: function (value, id, column, node) {
      // set input value
      if (contactEditorRef.current) {
        contactEditorRef?.current?.setValue(new Set(value || []));
      }
    },

    get_value: function (id, column, node) {
      return contactEditorRef?.current?.getValue();
      // return input value
    },

    is_changed: function (value, id, column, node) {
      //called before save/close. Return true if new value differs from the original one
      //returning true will trigger saving changes, returning false will skip saving
      const { contacts: currentValue } = this.get_value() || [];
      const oldValue = value.contacts || [];
      if (currentValue.length !== oldValue.length) {
        return true;
      }
      currentValue.sort();
      oldValue.sort();
      return !currentValue.every((element, index) => element.id === oldValue[index].id);
    },

    is_valid: function (value, id, column, node) {
      // validate, changes will be discarded if the method returns false
      return Array.isArray(this.get_value().contacts);
    },
    focus: function (node) { },
    save: function (id, column, node) {
      const { contacts, companies } = this.get_value(id, column, node);
      const task = gantt.getTask(id);
      task.contacts = contacts;
      task.companies = uniqBy([...task.companies, ...companies], 'id');
      gantt.updateTask(id);
    },
  };
};

const useStyles = makeStyles(() => ({
  paper: {
    width: 400,
    overflowY: 'auto',
    borderRadius: '8px',
    padding: '10px 10px',
  },
}));

const ContactMenuPopover = forwardRef(
  ({ initialSelected = [], anchorEl, onClose, projectId }, ref) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [selected, setSelected] = useState(initialSelected || []);

    const { data: companies = [], isLoading } = useGetProjectCompaniesQuery({ projectId });

    const { data: company = { users: [] }, isLoading: isLoadingUserCompany } = useGetWorkspaceCompanyQuery({ projectId });

    const companyEntities = Object.fromEntries(
      companies?.map(company => [company.id, company]) || []
    );

    const filteredCompanies = companies.filter(companyObj => companyObj.id !== company.id)

    const handleClose = () => {
      setOpen(false);
      onClose(selected);
    };

    const handleSubmit = () => {
      handleClose();
    };

    const handleCancel = () => {
      setOpen(false);
      setSelected(initialSelected);
      onClose(initialSelected);
    };

    useImperativeHandle(ref, () => ({
      getValue: () => ({
        contacts: [...selected],
        companies: [...new Set(selected.map(({ company_id }) => companyEntities[company_id]))],
      }),
      setValue: setSelected,
    }));

    return (
      <>
        {anchorEl && (
          <Popover
            anchorEl={anchorEl}
            classes={{ paper: classes.paper }}
            open={open}
            onClose={handleSubmit}
          >
            <SelectContactList
              companies={filteredCompanies}
              isLoading={isLoading || isLoadingUserCompany}
              selected={selected}
              setSelected={setSelected}
            />
            <DialogActions>
              <Button size="small" onClick={handleCancel}>
                Cancel
              </Button>
              <Button size="small" variant="contained" onClick={handleSubmit}>
                Update
              </Button>
            </DialogActions>
          </Popover>
        )}
      </>
    );
  }
);
