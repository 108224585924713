import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import {
  addTaskCommentAPI,
  fetchTaskHistoryAPI,
  updateTaskCommentAPI,
  deleteTaskCommentAPI,
} from 'features/tasks/api/tasksService';

/**
 * Bulk Update Task Companies
 * @param {Object} requestParameter
 * @param {Number} requestParameter.id
 */
export const fetchTaskFeeds = createAsyncThunk(
  'taskFeed/fetchTaskFeeds',
  async (requestParameter, { rejectWithValue }) => {
    const apiResponse = await fetchTaskHistoryAPI(requestParameter);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * Add comment
 * @param {Object} requestParameter
 * @param {Number} requestParameter.taskId
 * @param {String} requestParameter.comment
 */
export const saveComment = createAsyncThunk(
  'taskFeed/saveComment',
  async (requestParameter, { rejectWithValue }) => {
    const apiResponse = await addTaskCommentAPI(requestParameter);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * Update comment
 * @param {Object} requestParameter
 * @param {Number} requestParameter.taskId
 * @param {String} requestParameter.comment
 */
export const updateComment = createAsyncThunk(
  'taskFeed/updateComment',
  async (requestParameter, { rejectWithValue }) => {
    const apiResponse = await updateTaskCommentAPI(requestParameter);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * Delete comment
 * @param {Object} requestParameter
 * @param {Number} requestParameter.id
 */
export const deleteComment = createAsyncThunk(
  'taskFeed/deleteComment',
  async (requestParameter, { rejectWithValue }) => {
    const apiResponse = await deleteTaskCommentAPI(requestParameter);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      return apiResponse.response.data;
    }
  }
);

export const taskFeedAdapter = createEntityAdapter({
  sortComparer: (prev, curr) => new Date(curr.created_at) - new Date(prev.created_at),
});

const initialState = taskFeedAdapter.getInitialState({});

const taskFeedSlice = createSlice({
  name: 'taskFeed',
  initialState,
  reducers: {
    clearTaskFeeds: (state) => {
      taskFeedAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTaskFeeds.fulfilled, (state, action) => {
      taskFeedAdapter.upsertMany(state, action.payload);
    });

    // builder.addCase(saveComment.pending, (state, action) => {
    //   if (action.meta?.arg?.id) {
    //     taskFeedAdapter.upsertOne(state, { id: action.meta.arg.id, loading: true });
    //   }
    // });
    // builder.addCase(saveComment.fulfilled, (state, action) => {
    //    taskFeedAdapter.upsertOne(state, { ...action.payload, loading: false });
    // });

    builder.addCase(updateComment.pending, (state, action) => {
      if (action.meta?.arg?.id) {
        taskFeedAdapter.upsertOne(state, { id: action.meta.arg.taskFeedId, loading: true });
      }
    });

    builder.addCase(updateComment.fulfilled, (state, action) => {
      taskFeedAdapter.upsertOne(state, {
        id: action.meta.arg.taskFeedId,
        new_values: action.payload,
        comment: action.payload,
        loading: false,
      });
    });

    builder.addCase(deleteComment.pending, (state, action) => {
      if (action.meta?.arg?.id) {
        taskFeedAdapter.upsertOne(state, { id: action.meta.arg.taskFeedId, loading: true });
      }
    });

    builder.addCase(deleteComment.fulfilled, (state, action) => {
      if (action.meta?.arg?.id) {
        taskFeedAdapter.removeOne(state, action.meta.arg.taskFeedId);
      }
    });
  },
});

const taskFeedReducer = taskFeedSlice.reducer;

export const { clearTaskFeeds } = taskFeedSlice.actions;

// Selectors

export const {
  selectById: selectTaskFeedById,
  selectIds: selectTaskFeedIds,
  selectEntities: selectTaskFeedEntities,
  selectAll: selectAllTaskFeeds,
  selectTotal: selectTotalTaskFeeds,
} = taskFeedAdapter.getSelectors((state) => state.taskFeeds);

export const selectAllTaskFeedGroupedByDate = createSelector(
  [(state) => selectAllTaskFeeds(state)],
  (taskFeeds) => {
    return taskFeeds
      ? groupBy(taskFeeds, (taskFeed) => {
        return format(parse(taskFeed.created_at, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy');
      })
      : {};
  }
);

export default taskFeedReducer;
