import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { ContentLayout } from 'components/layouts';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SecondaryNav, SecondaryNavLink } from 'components/navigation/SecondaryNav';
import ActivityFeedTimelineView from 'features/activityFeeds/components/ActivityFeedTimelineView';
import ActivityFeedActionBar from 'features/activityFeeds/components/ActivityFeedActionBar';
import ProjectActivityFeedActionBar from './ProjectActivityFeedActionBar';

const ProjectActivityFeed = () => {
  const { projectId, workspaceId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState({ project: projectId });

  useEffect(() => {
    const queryParams = {};
    if (!searchParams.size) {
      return;
    }
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      queryParams[param] = value;

      setFilters(queryParams);
    }
  }, []);

  const handleRedirectToNotificationLog = (event) => {
    event.preventDefault();
    navigate(`/workspace/${workspaceId}/projects/${projectId}/notification-log`);
  };

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      project: projectId,
    }));
  }, [projectId]);

  return (
    <ContentLayout
      header={
        <>
          <SecondaryNav>
            <ProjectActivityFeedActionBar filters={filters} setFilters={setFilters} />
          </SecondaryNav>
        </>
      }
    >
      <>
        <Button
          color="primary"
          style={{ width: 'fit-content', color: '#4B70E7', padding: '10px 24px' }}
          onClick={handleRedirectToNotificationLog}
        >
          View as notification log
        </Button>
        <ActivityFeedTimelineView filters={filters} />
      </>
    </ContentLayout>
  );
};

export default ProjectActivityFeed;
