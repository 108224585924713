import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useGetAuthTokenQuery } from '../api/auth';
import { selectCurrentUser } from '../store/authSlice';
import { setupSocketConnection } from 'helpers/websockets';
import { CircularProgress } from '@mui/material';

export const RequireAuth = ({ children }) => {
  const authToken = useSelector((state) => state.auth.authToken);
  const refreshToken = useSelector((state) => state.auth.refreshToken);
  const { isLoading } = useGetAuthTokenQuery(
    { refreshToken },
    { skip: Boolean(authToken) || !refreshToken }
  );
  const user = useSelector((state) => selectCurrentUser(state));

  if (isLoading) {
    return <CircularProgress className='loading' />
  }

  if (Boolean(authToken) && Boolean(user)) {
    setupSocketConnection(authToken);
    return children;
  } else if (isLoading) {
    return null;
  } else {
    return <Navigate replace to={'/login'} />;
  }
};
