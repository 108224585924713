import { api } from 'api';

export const forecastKeys = {
  budget: (id) => ({ type: 'BudgetForecast', id }),
  project: (id) => ({ type: 'ProjectForecast', id }),
  refresh: ({ budgetId, projectId }) => {
    return [forecastKeys.budget(budgetId), forecastKeys.project(projectId)];
  },
};

const forecastAPI = api
  .enhanceEndpoints({ addTagTypes: ['BudgetForecast', 'ProjectForecast'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getBudgetForecast: build.query({
        query: ({ budgetId, budgetLines = [], contracts = [] }) => {
          const params = new URLSearchParams();

          budgetLines.forEach(line => params.append('budget_lines[]', line))
          contracts.forEach(contract => params.append('contracts[]', contract))

          return `/budgets/${budgetId}/forecast?${params.toString()}`
        },
        providesTags: (_result, _error, { budgetId }) => [forecastKeys.budget(budgetId)],
        transformResponse: (response) => ({
          ...response.data,
          filters: response.filters,
        }),
      }),
      getProjectForecast: build.query({
        query: ({ projectId }) => `/projects/${projectId}/forecast`,
        providesTags: (_result, _error, { projectId }) => [forecastKeys.project(projectId)],
        transformResponse: (response) => {
          return {
            ...response.data,
            filters: {
              budget_lines: [],
              contracts: [],
            },
            summaries: response.summaries,
          };
        },
      }),
    }),
  });

export const { useGetBudgetForecastQuery, useGetProjectForecastQuery } = forecastAPI;
