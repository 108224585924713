import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ShowPasswordLink,
  StyledButtonLoading,
  StyledErrorAlert,
  StyledLoginButton,
  StyledTextField,
} from '../assets/loginStyles';
import { clearAuthErrors, login } from '../store/authSlice';

const initialState = { email: '', password: '', showPassword: false };

/**
 * Login Form
 * @returns login form html
 */
const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.loading);

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: initialState,
  });

  const showPassword = watch('showPassword');

  const handleLogin = (loginDetails) => {
    dispatch(login(loginDetails))
  };

  useEffect(() => {
    return () => dispatch(clearAuthErrors());
  }, [dispatch]);

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <Grid container item style={{ marginTop: 25, gap: 20 }} xs={12}>
        {error && (
          <Grid item xs={12}>
            <StyledErrorAlert fullWidth severity="error">
              {error}
            </StyledErrorAlert>
          </Grid>
        )}
        <Grid container item xs={12}>
          <Typography style={{ fontSize: '14px', fontWeight: '600' }}>Email</Typography>
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                autoFocus
                fullWidth
                autoComplete={'username'}
                error={error}
                helperText={error?.message}
                placeholder="Email"
                size="small"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            rules={{
              required: 'Email field is required',
              validate: {
                matchPattern: (v) =>
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) ||
                  'Email address must be a valid address',
              },
            }}
          ></Controller>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontSize: '14px', fontWeight: '600' }}>Password</Typography>
          <Controller
            control={control}
            name="password"
            rules={{ required: 'Password field is required' }}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                fullWidth
                autoComplete={'current-password'}
                error={error}
                helperText={error?.message}
                placeholder="Password"
                size="small"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <ShowPasswordLink onClick={() => setValue('showPassword', !showPassword)}>
            {showPassword ? 'Hide' : 'Show'} Password
          </ShowPasswordLink>
        </Grid>
        <Grid item style={{ position: 'relative' }} xs={12}>
          <StyledLoginButton fullWidth disabled={loading} type="submit" variant="contained">
            SIGN IN
          </StyledLoginButton>
          {loading && <StyledButtonLoading size={24} />}
        </Grid>
        <Grid item xs={12}>
          <Divider color="#fff" orientation="horizontal" style={{ backgroundColor: '#fff' }} />
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontSize: '14px', marginBottom: '2px', textAlign: 'center' }}>
            Forgot your Username or Password?
          </Typography>
          <StyledLoginButton
            fullWidth
            variant="contained"
            onClick={() => navigate(`/forgot-password`)}
          >
            RECOVER CREDENTIALS
          </StyledLoginButton>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontSize: '14px', marginBottom: '2px', textAlign: 'center' }}>
            Want help from a real person?
          </Typography>
          <StyledLoginButton fullWidth variant="contained" onClick={() => navigate(`/help`)}>
            CONTACT SUPPORT
          </StyledLoginButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
