import { addDays, differenceInDays } from 'date-fns';
import ganttStore from '../../ganttStore';

export const parseDuration = (task, value, ganttId, isWorkDay) => {
  const gantt = ganttStore.getGantt(ganttId);
  const formatter = gantt.ext.formatters.durationFormatter({
    enter: 'day',
    store: 'day',
    format: ['month', 'week', 'day'],
    minutesPerHour: 60,
    hoursPerDay: 8,
    hoursPerWeek: 56,
    daysPerMonth: 30,
    daysPerYear: 365,
    labels: {
      day: {
        full: 'day',
        plural: 'days',
        short: 'd',
      },
      week: {
        full: 'week',
        plural: 'weeks',
        short: 'w',
      },
      month: {
        full: 'month',
        plural: 'months',
        short: 'm',
      },
    },
  });
  let parsedDuration = formatter.parse(value);
  let durationString = value;

  if (value && !parsedDuration) {
    const regex = /\d+/;
    const matches = value.match(regex);
    if (!matches) {
      gantt.message({ type: 'error', text: 'Invalid duration' });
      return false;
    }
    parsedDuration = parseInt(matches[0]);
    durationString = parsedDuration;
  }

  if (isWorkDay) {
    /** Convert workdays to calendar days */
    const calendar_end_date = gantt.calculateEndDate({
      start_date: task.start_date,
      duration: parsedDuration,
      task: task,
    });
    const calendarDays = differenceInDays(calendar_end_date, task.start_date);
    return {
      workdayString: durationString,
      workdays: parsedDuration,
      calendarDays,
      calendarDayString: calendarDays,
    };
  } else {
    /** Convert calendar days to work days */
    const workdays = gantt.calculateDuration({
      start_date: task.start_date,
      end_date: gantt.date.add(task.start_date, parsedDuration, 'day'),
      task: task,
    });
    return {
      workdays: workdays,
      workdayString: workdays,
      calendarDays: parsedDuration,
      calendarDayString:
        typeof durationString === 'string' && durationString?.split(' ').length === 1
          ? parsedDuration
          : durationString,
    };
  }
};

export const loadCustomDurationMenuType = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  const textEditor = gantt.config.editor_types.text;
  gantt.config.editor_types.custom_duration = gantt.mixin(
    {
      set_value: function (value, id, column, node) {
        return textEditor.set_value.apply(this, [value?.[column.name], id, column, node]);
      },

      get_value: function (id, column, node) {
        const task = gantt.getTask(id);
        const durationString = textEditor.get_value.apply(this, [id, column, node]).toLowerCase();
        return parseDuration(task, durationString, ganttId, true);
      },

      is_valid: (value, id, column, node) => {
        return true;
      },

      is_changed: function (value, id, column, node) {
        var parsedValue = this.get_value(id, column, node);
        const { workdayString } = parsedValue;
        return value.duration_string !== workdayString;
      },

      save: function (id, column, node) {
        const { workdayString, workdays, calendarDayString, calendarDays } = this.get_value(
          id,
          column,
          node
        );
        const task = gantt.getTask(id);
        task.cal_days = calendarDays;
        task.work_days = workdays;
        task.end_date = gantt.calculateEndDate({
          start_date: task.start_date,
          duration: workdays,
          task: task,
        });
        gantt.updateTask(id);
      },
    },
    textEditor
  );
};

export const loadCalendarDayMenuType = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  const textEditor = gantt.config.editor_types.text;
  gantt.config.editor_types.calendar_days = gantt.mixin(
    {
      set_value: function (value, id, column, node) {
        return textEditor.set_value.apply(this, [value.cal_days, id, column, node]);
      },

      get_value: function (id, column, node) {
        const task = gantt.getTask(id);
        const calendarDayString = textEditor.get_value
          .apply(this, [id, column, node])
          .toLowerCase();
        return parseDuration(task, calendarDayString, ganttId, false);
      },

      is_valid: (value, id, column, node) => {
        return true;
      },

      is_changed: function (value, id, column, node) {
        var returnValue = this.get_value(id, column, node);
        return value.cal_days !== returnValue.calendarDayString;
      },

      save: function (id, column, node) {
        const { workdays, calendarDays } = this.get_value(id, column, node);
        const task = gantt.getTask(id);
        task.work_days = workdays;
        task.cal_days = calendarDays;
        task.end_date = addDays(task.start_date, calendarDays);

        if (task.status !== 'active') {
          task.forecasted_end_date = task.end_date;
        }
        gantt.updateTask(id);
      },
    },
    textEditor
  );
};
