/**
 * Generates a unique vertex ID for a task based on its ID and whether it's a start or end node.
 * @param {string} taskId - The ID of the task.
 * @param {boolean} isStartNode - Indicates whether the node is a start node (default is true).
 * @returns {string} The generated vertex ID.
 */
function getVertexId(taskId, isStartNode = true) {
  return taskId + (isStartNode ? '-start' : '-end');
}

/**
 * Checks if there's a circular dependency among tasks and their links.
 * @param {Array} tasks - List of tasks.
 * @param {Array} links - List of links.
 * @param {string|null} taskId - Optional ID of the task to start the search from.
 * @returns {boolean} True if a circular dependency is found, otherwise false.
 */
function hasCircularDependency(tasks = [], links = [], taskId = null) {
  // Convert tasks list to object for efficient lookup
  const taskEntities = tasks.reduce((acc, task) => {
    acc[task.id] = task;
    return acc;
  }, {});

  // Convert links list to object for efficient lookup
  const linkEntities = links.reduce((acc, link) => {
    acc[link.id] = link;
    return acc;
  }, {});

  // Initialize sets to keep track of visited nodes
  const permanentMarks = new Set();
  const temporaryMarks = new Set();
  let circleDetected = false;

  /**
   * Recursive function to visit successors of a task and detect circular dependencies.
   * @param {string} currentTaskId - ID of the current task being visited.
   * @param {boolean} isStartNode - Indicates whether the current node is a start node.
   */
  function visitSuccessors(currentTaskId, isStartNode = true) {
    const vertexId = getVertexId(currentTaskId, isStartNode);
    if (taskEntities[currentTaskId] && !circleDetected) {
      if (permanentMarks.has(vertexId)) {
        return;
      }

      if (temporaryMarks.has(vertexId)) {
        circleDetected = true;
        return;
      }

      temporaryMarks.add(vertexId);

      const task = taskEntities[currentTaskId];

      task.$source.forEach((linkId) => {
        const link = linkEntities[linkId];
        if (isStartNode) {
          if (link.type === 'SS') {
            visitSuccessors(link.target, true);
          }
          if (link.type === 'SF') {
            visitSuccessors(link.target, false);
          }
        } else {
          if (link.type === 'FS') {
            visitSuccessors(link.target, true);
          }
          if (link.type === 'FF') {
            visitSuccessors(link.target, false);
          }
        }
      });

      if (isStartNode) {
        visitSuccessors(currentTaskId, false);
      }

      visitSuccessors(task.parent, false);

      temporaryMarks.delete(vertexId);
      permanentMarks.add(vertexId);
    }
  }

  // Start the visit from the specified task ID or from all tasks
  if (taskId) {
    visitSuccessors(taskId);
  } else {
    tasks.forEach((task) => {
      if (!permanentMarks.has(getVertexId(task.id, true))) {
        visitSuccessors(task.id);
      }
    });
  }

  return circleDetected;
}

/**
 * Checks if a task has a circular dependency within a Gantt chart.
 * @param {object} task - The task to check for circular dependency.
 * @param {object} gantt - The Gantt chart containing the task and links.
 * @returns {boolean} True if a circular dependency is found, otherwise false.
 */
function taskHasCircularDependency(task, gantt) {
  const projectId = task.project_id;
  return hasCircularDependency(
    [...gantt.getTaskBy((task) => (projectId ? task.project_id === projectId : true)), task],
    gantt.getLinks(),
    task.id
  );
}

/**
 * Checks if a link has a circular dependency within a Gantt chart.
 * @param {object} link - The link to check for circular dependency.
 * @param {object} gantt - The Gantt chart containing the link and tasks.
 * @returns {boolean} True if a circular dependency is found, otherwise false.
 */
function linkHasCircularDependency(link, gantt) {
  const sourceTask = gantt.getTask(link.source);
  const projectId = sourceTask.project_id;
  return hasCircularDependency(
    [
      ...gantt.getTaskBy((task) => (projectId ? task.project_id === projectId : true)),
      { ...sourceTask, $source: [...sourceTask.$source, link.id] },
    ],
    [...gantt.getLinks(), link],
    sourceTask.id
  );
}

module.exports = { linkHasCircularDependency, taskHasCircularDependency, hasCircularDependency };
