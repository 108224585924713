import { Autocomplete, Checkbox, Grid, InputLabel, MenuItem, TextField } from '@mui/material';
import { selectCurrentUserRole } from 'features/auth';
import { useGetOfficesQuery } from 'features/offices/api/offices.api';
import { TradesAutocomplete } from 'features/trades/components/TradesAutocomplete';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

export const ContactForm = ({
  companyId,
  form,
  onSubmit,
  disabled,
  isNew,
  enableMessage = false,
  workspaceOwner,
}) => {
  const userRole = useSelector((state) => selectCurrentUserRole(state));

  const { control, handleSubmit, watch } = form;

  const { data: offices = [], isLoading: isLoadingOffices } = useGetOfficesQuery({
    companyId,
  });

  const isNotify = watch('notify');
  const contactRole = watch('role');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputLabel htmlFor="contact-first-name-input">First Name*</InputLabel>
          <Controller
            control={control}
            defaultValue=""
            name="first_name"
            rules={{ required: 'First name is required' }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                required
                disabled={disabled || !isNew}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                id="contact-first-name-input"
                placeholder="First Name"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor="contact-last-name-input">Last Name*</InputLabel>
          <Controller
            control={control}
            defaultValue=""
            name="last_name"
            rules={{ required: 'Last name is required' }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                required
                disabled={disabled || !isNew}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                id="contact-last-name-input"
                placeholder="Last Name"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="contact-email-input">Email*</InputLabel>
          <Controller
            control={control}
            defaultValue=""
            name="email"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                disabled
                fullWidth
                required
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                id="contact-email-input"
                placeholder="Email"
                size="small"
                variant="outlined"
              />
            )}
            rules={{
              required: 'Email is required',
              pattern: { value: /^\S+@\S+$/i, message: 'Invalid email address' },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor="contact-phone-input">Phone</InputLabel>
          <Controller
            control={control}
            defaultValue=""
            name="phone"
            render={({ field, fieldState }) => (
              <MuiTelInput
                {...field}
                focusOnSelectCountry
                fullWidth
                defaultCountry={'US'}
                disabled={disabled || !isNew}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                id="contact-phone-input"
                preferredCountries={['US']}
                size="small"
                variant="outlined"
              />
            )}
            rules={{
              validate: (value) =>
                value === '' || !isNew || matchIsValidTel(value) || 'Phone number is invalid',
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor="contact-title-input">Title</InputLabel>
          <Controller
            control={control}
            defaultValue=""
            name="title"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                required
                disabled={disabled || !isNew}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                id="contact-title-input"
                placeholder="Title"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>

        {workspaceOwner && (
          <Grid item xs={6}>
            <InputLabel htmlFor="contact-role-input">Role</InputLabel>
            <Controller
              control={control}
              defaultValue=""
              name="role"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  select
                  disabled={disabled || (userRole !== 'admin' && userRole !== 'super_admin')}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  id="contact-role-input"
                  placeholder="Role"
                  size="small"
                  variant="outlined"
                >
                  {(contactRole === 'super_admin' || userRole === 'super_admin') && (
                    <MenuItem disabled={userRole !== 'super_admin'} value={'super_admin'}>
                      Super Admin
                    </MenuItem>
                  )}
                  <MenuItem value={'admin'}>Admin</MenuItem>
                  <MenuItem value={'member'}>Member</MenuItem>
                </TextField>
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <InputLabel htmlFor="contact-trade-input">Trade</InputLabel>
          <Controller
            control={control}
            name="trade_ids"
            render={({ field: { onChange, ...field } }) => (
              <TradesAutocomplete
                multiple
                {...field}
                onChange={(event, newValue) => onChange(newValue)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="contact-offices-input">Offices</InputLabel>
          <Controller
            control={control}
            name="offices"
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                autoHighlight
                disableCloseOnSelect
                multiple
                disabled={disabled}
                getOptionLabel={(option) => (option?.name ? option.name : '')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                limitTags={3}
                loading={isLoadingOffices}
                options={offices}
                size={'small'}
                value={field.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="contact-office-input"
                    placeholder="Offices"
                    variant="outlined"
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    {option?.name}
                  </li>
                )}
                onChange={(event, newValue) => {
                  field.onChange(newValue);
                }}
              />
            )}
          />
        </Grid>

        {enableMessage && (
          <>
            <Grid item xs={12}>
              <InputLabel htmlFor="notify-select-input">Notify Via Email</InputLabel>
              <Controller
                control={control}
                defaultValue=""
                name="notify"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    select
                    disabled={disabled}
                    id="notify-select-input"
                    placeholder="Notify via email"
                    size="small"
                    variant={'outlined'}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            {isNotify ? (
              <Grid item xs={12}>
                <InputLabel htmlFor="message-input">Message</InputLabel>
                <Controller
                  control={control}
                  name="message"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      multiline
                      required
                      disabled={disabled}
                      id="message-input"
                      maxRows={4}
                      placeholder="Enter a message to send to the new contact"
                      size="medium"
                      variant={'outlined'}
                    />
                  )}
                />
              </Grid>
            ) : null}
          </>
        )}
      </Grid>
    </form>
  );
};
