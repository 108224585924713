import { theme } from '@blackhyve/common/src/AppTheme';
import AddIcon from '@mui/icons-material/Add';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  ThemeProvider,
} from '@mui/material';
import SearchBox from 'components/search/SearchBox';
import { useGetRecipesQuery } from 'features/recipes/api/recipe.api';
import RecipeDialog from 'features/recipes/components/RecipeDialog';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';
import { RecipeNavList } from '../../../features/recipes/components/RecipeNavList';
import { DrawerFilterMenu, DrawerFilterMenuItem } from '../menus/DrawerFilterMenu';
import { NavListItem } from '../NavListItem';
import { NavScrollBar } from '../NavScrollBar';
import SvgRecipe from '@blackhyve/common/src/icons/Recipe';

export const RecipeDrawer = () => {
  const { workspaceId } = useParams();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useDebounceValue('');

  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState(undefined);
  const [filters, setFilters] = useState({});

  const {
    data: { data: recipes = [] } = {},
    isLoading: isLoadingRecipes,
    isFetching: isFetchingRecipes,
  } = useGetRecipesQuery({
    params: { q: debouncedSearchQuery, page: 1, per_page: 25, order_by: 'name,asc', ...filters },
  });

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    setDebouncedSearchQuery(value);
  };

  /**
   *
   * @param {*} input
   * @param {string} input.filterName - Name of field to match
   * @param {String|Number|Boolean} input.value - Value to match field to
   */
  const handleFilter = ({ filterName, value }) => {
    if (Object.hasOwn(filters, filterName) && filters[filterName] === value) {
      const filtersClone = { ...filters };
      delete filtersClone[filterName];
      setFilters(filtersClone);
    } else {
      setFilters({ ...filters, [filterName]: value });
    }
  };

  return (
    <>
      <List>
        <NavListItem end to={`/workspace/${workspaceId}/recipes`}>
          <ListItemIcon>
            <SvgIcon>
              <SvgRecipe color={'currentColor'} height={undefined} width={undefined} />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary={'Recipes'} />
        </NavListItem>
        <Divider />
        <ListItem button onClick={() => setIsCreateDialogOpen(true)}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={'New Recipe'} />
        </ListItem>

        <ThemeProvider theme={theme}>
          <RecipeDialog
            handleClose={() => setIsCreateDialogOpen(false)}
            open={isCreateDialogOpen}
          />
        </ThemeProvider>

        <DrawerFilterMenu
          anchorEl={filterMenuAnchorEl}
          handleClose={() => setFilterMenuAnchorEl(undefined)}
        >
          <DrawerFilterMenuItem
            checked={filters['type'] === 'master'}
            primaryText={'Master'}
            onClick={() => handleFilter({ filterName: 'type', value: 'master' })}
          />
          <DrawerFilterMenuItem
            checked={filters['type'] === 'phase'}
            primaryText={'Phase'}
            onClick={() => handleFilter({ filterName: 'type', value: 'phase' })}
          />
          <DrawerFilterMenuItem
            checked={filters['type'] === 'lookahead'}
            primaryText={'Lookahead'}
            onClick={() => handleFilter({ filterName: 'type', value: 'lookahead' })}
          />
          <DrawerFilterMenuItem
            checked={filters['type'] === 'wwp'}
            primaryText={'Weekly Work Plan'}
            onClick={() => handleFilter({ filterName: 'type', value: 'wwp' })}
          />
        </DrawerFilterMenu>
        <ListItem>
          <SearchBox borderRadius={1} value={searchQuery} onChange={handleSearch} />
        </ListItem>
      </List>
      <Divider />
      <NavScrollBar>
        <RecipeNavList isLoading={isLoadingRecipes || isFetchingRecipes} recipes={recipes} />
      </NavScrollBar>
    </>
  );
};
