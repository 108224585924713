import { useSelector } from 'react-redux';
import { selectCurrentUserRole } from '../store/authSlice';

export const useRequireRole = ({ roles, exclude = false }) => {
  const userRole = useSelector((state) => selectCurrentUserRole(state));
  return exclude ? !roles.includes(userRole) : roles.includes(userRole);
};
/**
 * Restrict access to children based on roles
 * @param {*} props
 * @param {[string]} props.roles
 * @param {Boolean} props.exclude - Determines if the roles is excluding
 * @param {JSX} props.fallbackComponent - Component to render if user role does not match
 * @param {JSX} props.loadingComponent - Component to render while loading
 * @param {JSX} props.children
 * @returns
 */
export const RequireRole = ({ roles, exclude = false, children, fallbackComponent }) => {
  const isRoleAllowed = useRequireRole({ roles, exclude });
  if (isRoleAllowed) {
    return children;
  } else {
    return fallbackComponent;
  }
};
