import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import isValid from 'date-fns/isValid';
import {
  StyledDrawer,
  StyledLabelValue,
  StyledSectionTitle,
} from 'features/drawers/utils/drawerStyle';
import { parseDate } from '@blackhyve/utilities/dates';

/**
 * Task detail drawer
 * @param {Boolean} open
 * @returns
 */
const TaskDetailDrawer = ({ open, children, title, top, width, loading }) => {
  return (
    <StyledDrawer anchor="right" open={open} top={top} variant="persistent" width={width}>
      {loading && (
        <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
          <CircularProgress className="loading" />
        </Backdrop>
      )}
      {children}
    </StyledDrawer>
  );
};

export const SectionHeading = ({ title, children, isEditing, handleEditing, disabled }) => {
  return (
    <Grid container item alignItems="center" xs={12}>
      {children}
      <StyledSectionTitle fontSize={14}>{title}</StyledSectionTitle>
      {!isEditing && !disabled && (
        <StyledSectionTitle
          fontSize={12}
          style={{ marginLeft: 'auto', cursor: 'pointer' }}
          onClick={handleEditing}
        >
          Edit
        </StyledSectionTitle>
      )}
    </Grid>
  );
};

export const LabelValue = ({ value }) => {
  return <StyledLabelValue>{value || value === 0 ? value : '-'}</StyledLabelValue>;
};

export const DateValue = ({ date }) => {
  const dateObject = !isValid(date) ? parseDate(date) : new Date(date);
  if (dateObject && isValid(dateObject)) {
    const formattedDate = dateObject?.toLocaleDateString();
    return <StyledLabelValue>{formattedDate}</StyledLabelValue>;
  } else {
    return <StyledLabelValue>-</StyledLabelValue>;
  }
};

export const ActionButtons = ({ handleClose, handleUpdate }) => {
  return (
    <Grid item className="flex" gap={2} xs={12}>
      <Button color={'primary'} size="small" onClick={handleClose}>
        Cancel
      </Button>
      <Button
        color={'primary'}
        size="small"
        type="submit"
        variant="contained"
        onClick={handleUpdate}
      >
        Update
      </Button>
    </Grid>
  );
};

export default TaskDetailDrawer;
