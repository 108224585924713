import { axiosRequestHandler } from '../../../helpers/apiHelper';

export const fetchUsersAPI = async ({ includes = '', projectIds }) => {
  try {
    const projectQuery = projectIds?.length ? `project=${projectIds}&` : '';
    const includeQuery = includes ? `,${includes}` : '';
    const url = `users?${projectQuery}include_archived${includeQuery}`;

    return await axiosRequestHandler(url, 'get');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

export const addUserAPI = async (params) => {
  try {
    return await axiosRequestHandler(`users`, 'post', params, null, true);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

export const updateUserAPI = async (formData) => {
  try {
    return await axiosRequestHandler(`users/${formData.id}`, 'patch', formData);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

export const getUserDetailsAPI = async ({ userId }) => {
  try {
    return await axiosRequestHandler(`users/${userId}`, 'get');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

export const updatePasswordAPI = async (params) => {
  try {
    return await axiosRequestHandler(`users/password_reset`, 'post', params);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Fetch User Workspaces
 * @returns {Promise<any>} - Promise object represents the response
 */
export const fetchUserWorkspacesAPI = async () => {
  try {
    return await axiosRequestHandler(`admin/workspaces`, 'get');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Create user
 * @param params
 * @param {String} params.first_name - first name of the user
 * @param {String} params.last_name - last name of the user
 * @param {String} params.email - email of the user
 * @param {String} params.password - password of the user
 * @param {String} params.title - title of the user
 * @param {String} params.role - role of the user
 * @param {Boolean} [params.notify = true] - notify user
 * @param {String} [params.message] - message to be sent to the user
 * @param {String} [params.cellPhone] - cell phone of the user
 * @param {String} [params.officePhone] - office phone of the user
 * @returns {Promise<any>} - Promise object represents the response
 */
export const inviteUserAPI = async (params) => {
  return await axiosRequestHandler(`users`, 'post', params);
};

/**
 * Register user
 * @param {String} params.token
 * @param {String} params.email
 * @param {String} params.new_email
 * @param {String} params.new_password
 * @param {String} params.confirm_password
 * @returns {Promise<any>} - Promise object represents the response
 */
export const registerUserAPI = async (params) => {
  return await axiosRequestHandler(`register`, 'post', params);
};

export const checkRegistrationTokenAPI = async ({ email, token }) => {
  return await axiosRequestHandler(`register/validate?token=${token}&email=${email}
  `);
};

export const checkEmailRegisteredAPI = async ({ email }) => {
  return await axiosRequestHandler(`users/verify/${email}`);
};

/**
 * Resend invite
 * @param {String} params.id
 * @returns {Promise<any>} - Promise object represents the response
 */
export const resendInviteAPI = async (params) => {
  return await axiosRequestHandler(`users/${params.id}/invite`, 'post');
};

/**
 * Upload image
 * @param {FormData} formData
 * @param {String} formData.id
 * @param {String} formData.profile_image
 * @returns {Promise<any>} - Promise object represents the response
 */
export const uploadProfileImageAPI = async (formData) => {
  return await axiosRequestHandler(`users/${formData.get('id')}`, 'post', formData);
};

/**
 * Force delete user api
 * @param {String} userId
 * @returns {Promise<any>} - Promise object represents the response
 */
export const deleteUserAPI = async (userId) => {
  return await axiosRequestHandler(`users/${userId}}/force`, 'delete');
};

/**
 * Archive user api
 * @param {String} userId
 * @returns {Promise<any>} - Promise object represents the response
 */
export const archiveUserAPI = async (params) => {
  return await axiosRequestHandler(`users/${params}`, 'delete');
};

/**
 * Restore user api
 * @param {String} userId
 * @returns {Promise<any>} - Promise object represents the response
 */
export const restoreUserAPI = async (userId) => {
  return await axiosRequestHandler(`users/${userId}/restore`, 'patch');
};

/**
 * Upload file API
 * @param {FormData} formData
 * @param {String} formData.id
 * @param {String} formData.file
 * @returns {Promise<any>} - Promise object represents the response
 */
export const uploadFileAPI = async (formData) => {
  return await axiosRequestHandler('files', 'post', formData, null, true);
};
