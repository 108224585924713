import { MenuButton } from 'assets/style-components/button';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import { subDays, startOfDay } from 'date-fns';

/**
 * Displaying today option
 * @param {String} ganttId
 * @returns
 */
const TodayOption = ({ ganttId }) => {
  const gantt = ganttStore.getGantt(ganttId);

  const handleTodayButton = () => {
    gantt.scrollTo(gantt.posFromDate(startOfDay(subDays(new Date(), 1))));
  };

  return <MenuButton onClick={handleTodayButton}>Today</MenuButton>;
};

export default TodayOption;
