import { UnfoldMore } from '@mui/icons-material';
import { MenuButton } from 'assets/style-components/button';
import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClosedTasks } from 'slices/customViewSlice';

export function ExpandAll({ gantt, projectId }) {
  const dispatch = useDispatch();
  const allProjectsClosedTasks = useSelector(
    (state) => state.customViews?.menus?.closedTasks?.currentValue
  );

  const closedTasks = useMemo(() => {
    return !isEmpty(allProjectsClosedTasks) && allProjectsClosedTasks.hasOwnProperty(projectId)
      ? allProjectsClosedTasks[projectId]
      : [];
  }, [allProjectsClosedTasks, projectId]);

  const handleExpandAll = () => {
    gantt.eachTask((task) => (task.$open = true));
    gantt.render();
    dispatch(setClosedTasks({ ids: [], projectId, type: 'reset' }));
  };

  useEffect(() => {
    const onTaskClosed = gantt?.attachEvent('onTaskClosed', function (id) {
      if (gantt.getTask(id).type === 'placeholder') {
        return;
      }
      dispatch(setClosedTasks({ id: parseInt(id), projectId, type: 'add' }));
    });
    return () => {
      gantt?.detachEvent(onTaskClosed);
    };
  }, [gantt, dispatch, projectId]);

  useEffect(() => {
    const onTaskOpened = gantt?.attachEvent('onTaskOpened', function (id) {
      if (gantt.getTask(id).type === 'placeholder') {
        return;
      }
      dispatch(setClosedTasks({ id: parseInt(id), projectId, type: 'remove' }));
    });
    return () => {
      gantt?.detachEvent(onTaskOpened);
    };
  }, [gantt, dispatch, projectId]);

  useEffect(() => {
    const onAfterTaskDelete = gantt?.attachEvent('onAfterTaskDelete', function (id, item) {
      if (item.type === 'placeholder') {
        return;
      }
      dispatch(setClosedTasks({ id: parseInt(id), projectId, type: 'remove' }));
    });
    return () => {
      gantt?.detachEvent(onAfterTaskDelete);
    };
  }, [projectId, dispatch, gantt]);

  useEffect(() => {
    if (gantt) {
      if (closedTasks?.length) {
        closedTasks.forEach((taskId) => {
          if (gantt.isTaskExists(taskId)) {
            if (gantt.getTask(taskId).$open) {
              gantt.getTask(taskId).$open = false;
            }
          }
        });
        gantt.render();
      }
    }
  }, [gantt, closedTasks]);

  return (
    <MenuButton startIcon={<UnfoldMore />} onClick={handleExpandAll}>
      Expand All
    </MenuButton>
  );
}

export function CollapseAll({ gantt, projectId }) {
  const dispatch = useDispatch();

  const handleCollapseAll = () => {
    const taskIds = new Set();
    gantt.eachTask((task) => {
      task.$open = false;
      if (task.type === 'parent_task' || task.type === 'project_bar') {
        taskIds.add(task.id);
      }
    });
    gantt.render();
    dispatch(setClosedTasks({ ids: [...taskIds], projectId, type: 'reset' }));
  };

  return (
    <MenuButton startIcon={<UnfoldMore />} onClick={handleCollapseAll}>
      Collapse All
    </MenuButton>
  );
}
