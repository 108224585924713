import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
const eventListeners = [];

export const registerEventListeners = (ganttId) => {
  // const gantt = ganttStore.getGantt(ganttId);
  // const taskOpened = gantt.attachEvent('onTaskOpened', (id) => {
  //   store.dispatch(fetchChildrenTasks({ projectIds: [id], taskIds: [0] })).then((response) => {
  //     const apiData = response.payload.entities;
  //     if (!apiData.task) {
  //       return gantt.message({
  //         text: 'Project has no tasks',
  //         expire: 2000,
  //       });
  //     }
  //     const tasks = Object.values(apiData?.task).map((task) => {
  //       return {
  //         ...formatTaskResponse(task),
  //         parent: task.parent_id == 0 ? id : task.parent_id,
  //       };
  //     });
  //     const links = apiData.dependencies ? Object.values(apiData.dependencies) : [];
  //     gantt.parse({ data: tasks, links });
  //   });
  // });
  // const taskClosed = gantt.attachEvent('onTaskClosed', (id) => {
  //   console.log('On Project Closed Id =>', id)
  // });
  // eventListeners.push(taskOpened);
};

export const removeEventListeners = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  while (eventListeners?.length) {
    gantt.detachEvent(eventListeners.pop());
  }
};
