import { api, providesList } from 'api';

export const locationsAPI = api.enhanceEndpoints({ addTagTypes: ['Location'] }).injectEndpoints({
  endpoints: (build) => ({
    getLocations: build.query({
      query: ({ projectId }) => `/locations?project[]=${projectId}&includes[]=zones.areas`,
      providesTags: (results) => providesList(results, 'Location'),
      transformResponse: (response) => response.data,
    }),
  }),
});
export const { useGetLocationsQuery } = locationsAPI;
