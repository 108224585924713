const { getChildren } = require('./getChildren');
const { hasChild } = require('./hasChild');

function calculateTaskActualDates(task, gantt) {
  let latestActualEndDate = -Infinity;
  let earliestActualStartDate = Infinity;
  let hasActualEnd = true;

  if (hasChild(task.id, gantt)) {
    getChildren(task.id, gantt).forEach(function (child) {
      if (!child?.actual_end_date) {
        hasActualEnd = false;
      }
      if (+child.actual_end_date > +latestActualEndDate) {
        latestActualEndDate = child.actual_end_date;
      }
      if (+child.actual_start_date < +earliestActualStartDate) {
        earliestActualStartDate = child.actual_start_date;
      }
    });

    return {
      actualStartDate: isFinite(earliestActualStartDate) ? earliestActualStartDate : null,
      actualEndDate: isFinite(earliestActualStartDate) && hasActualEnd ? latestActualEndDate : null,
    };
  } else {
    return {
      actualStartDate: task?.actual_start_date || null,
      actualEndDate: task?.actual_end_date || null,
    };
  }
}

module.exports = { calculateTaskActualDates };
