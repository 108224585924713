import { Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import RichTextEditor from 'components/common/v3/RichTextEditor';
import { Controller } from 'react-hook-form';

const RecipeForm = ({ form, onSubmit }) => {
  const { handleSubmit, control } = form;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <InputLabel htmlFor="recipe-name-input">Name</InputLabel>
          <Controller
            control={control}
            name="name"
            rules={{ required: 'Name is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                autoFocus
                fullWidth
                error={!!error}
                helperText={error}
                placeholder="Recipe Name"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel htmlFor="recipe-type-select">Type</InputLabel>
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <Select {...field} displayEmpty fullWidth size="small" variant="outlined">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="master">Master</MenuItem>
                <MenuItem value="phase">Phase</MenuItem>
                <MenuItem value="lookahead">Lookahead</MenuItem>
                <MenuItem value="wwp">Weekly Work Plan</MenuItem>
              </Select>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="notes"
            render={({ field }) => (
              <RichTextEditor handleOnChange={field.onChange} readOnly={false} text={field.value} />
            )}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default RecipeForm;
