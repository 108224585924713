const { isDate, isValid } = require('date-fns');
const { hasChild } = require('./hasChild');

function calculateTaskPace(task, gantt) {
  let pace = 0;

  if (
    (task.status !== 'todo' || hasChild(task.id, gantt)) &&
    isDate(task.forecasted_end_date) &&
    isValid(task.forecasted_end_date) &&
    isDate(task.scheduled_end_date) &&
    isValid(task.scheduled_end_date)
  ) {
    pace = gantt.calculateDuration({
      start_date: task.forecasted_end_date,
      end_date: task.scheduled_end_date,
      task,
    });
  }

  return pace;
}

module.exports = { calculateTaskPace };
