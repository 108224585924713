import { Box, Container, Divider, Grid, Paper, Typography } from '@mui/material';
import { OfficeList } from 'features/offices';
import { useParams } from 'react-router-dom';
import CompanyDetails from '../components/CompanyDetails';

export const Company = () => {
  const { companyId } = useParams();

  return (
    <Container>
      <Paper>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CompanyDetails companyId={companyId} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {companyId !== 'new' && (
                <Box pt={2}>
                  <Typography variant={'h4'}>Offices</Typography>
                  <OfficeList companyId={companyId} />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};
