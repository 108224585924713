import { OptionsMenu } from '@blackhyve/common';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Check from '@mui/icons-material/Check';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import SearchBox from 'components/search/SearchBox';
import { debounce } from 'lodash';
import isObject from 'lodash/isObject';
import { useEffect, useRef, useState } from 'react';
import ganttStore from '../../ganttConfig/ganttStore';

/**
 * Search
 * @param {String} ganttId
 * @param {[Object]} searchByOptions
 * @returns
 */
const SearchMenu = ({ searchByOptions, ganttId }) => {
  const [searchState, setSearchState] = useState({
    searchQuery: '',
    searchBy: searchByOptions && searchByOptions.length ? searchByOptions[0] : '',
  });
  const searchRef = useRef();
  const gantt = ganttStore.getGantt(ganttId);

  const handleSearch = (event) => {
    gantt.eachTask((task) => task.$open = true)
    setSearchState({ ...searchState, searchQuery: event.target.value });
  };

  const handleSelectSearchBy = (selectedSearchBy) => {
    setSearchState({ ...searchState, searchBy: selectedSearchBy });
  };

  const handleBeforeTaskDisplay = (id, task) => {
    let isValid = true;
    if (searchRef?.current.searchQuery && searchRef?.current?.searchBy) {
      isValid = searchRef?.current?.searchBy?.compareFunction(task, searchRef?.current.searchQuery);
    }
    return isValid;
  };

  useEffect(() => {
    if (gantt) {
      let eventId = gantt.attachEvent('onBeforeTaskDisplay', handleBeforeTaskDisplay);
      return () => {
        gantt.detachEvent(eventId);
      };
    }
  }, [gantt]);

  useEffect(() => {
    const debouncedSaveSearch = debounce(() => {
      gantt?.render();
    }, 500);

    debouncedSaveSearch();

    return () => {
      debouncedSaveSearch.cancel();
    };
  }, [searchState, gantt]);

  useEffect(() => {
    searchRef.current = searchState;
  }, [searchState]);

  return (
    <Grid container>
      <Grid item>
        <SearchBox fullWidth={false} value={searchState?.searchQuery} onChange={handleSearch} />
      </Grid>
      <Grid item>
        <OptionsMenu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          triggerButton={
            <IconButton size={'small'}>
              <MoreHoriz />
            </IconButton>
          }
        >
          {(handleClose) => {
            return (
              <Grid style={{ padding: '10px 5px 10px 5px' }}>
                <Typography style={{ padding: '0px 5px 0px 5px', fontWeight: 'bold' }}>
                  Search In
                </Typography>
                {searchByOptions?.map(({ name, value, compareFunction }) => {
                  const searchByValue = isObject(searchState.searchBy)
                    ? searchState.searchBy.value
                    : searchState.searchBy;
                  return (
                    <MenuItem
                      key={value}
                      onClick={() => handleSelectSearchBy({ value, compareFunction })}
                    >
                      <div style={{ marginRight: 5, width: 20 }}>
                        {searchByValue === value ? (
                          <Check color={'action'} fontSize="small" style={{ display: 'block' }} />
                        ) : null}
                      </div>
                      <Typography>{name}</Typography>
                    </MenuItem>
                  );
                })}
              </Grid>
            );
          }}
        </OptionsMenu>
      </Grid>
    </Grid>
  );
};

export default SearchMenu;
