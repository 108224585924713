import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import DisplayComment from 'features/comments/components/DisplayComment';
import { fetchUsers } from 'features/users/store/usersSlice';
import times from 'lodash/times';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { clearTaskFeeds, fetchTaskFeeds, selectAllTaskFeeds } from 'slices/taskFeedSlice';
import TaskFeedRow from './TaskFeedRow';

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 55,
});

/**
 * Task feed list
 * @param {Number} id taskId
 * @param {Boolean} withChildren taskId
 * @param {Boolean} updateFeeds if you want to update feed after any update
 * @returns Task feed list
 */
const TaskFeedList = ({ id, withChildren = false, updateFeeds, projectId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const feedListRef = useRef();
  const taskFeeds = useSelector((state) => selectAllTaskFeeds(state));

  const handleCreateCommentSuccess = () => {
    dispatch(fetchTaskFeeds({ id, with_children: true }));
  };

  const fetchFeeds = useCallback(async () => {
    return Promise.all([
      dispatch(
        fetchTaskFeeds({
          id,
          with_children: withChildren,
        })
      ),
      dispatch(fetchUsers({ projectIds: [projectId] })),
    ]);
  }, [id, withChildren, dispatch, projectId]);

  useEffect(() => {
    if (updateFeeds && id) {
      fetchFeeds();
    }
  }, [id, updateFeeds, fetchFeeds]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      fetchFeeds().then(() => {
        setLoading(false);
      });
    }
  }, [id, fetchFeeds]);

  useEffect(() => {
    return () => dispatch(clearTaskFeeds());
  }, [id, dispatch]);

  const rowRenderer = ({ key, index, parent, style }) => {
    const taskFeed = taskFeeds[index];
    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        {({ measure }) => {
          return (
            <div key={taskFeed.id} style={style}>
              {taskFeed.auditable_type === 'Comment' || taskFeed.object_type === 'comment' ? (
                <DisplayComment
                  comment={taskFeed.comment ? taskFeed.comment : taskFeed}
                  key={taskFeed.id}
                  // meta={{ taskFeedId: taskFeed.id, taskId: id }}
                  meta={{ entityId: id, entityType: 'tasks', entityTag: 'Task' }}
                  onLoad={measure}
                  onSuccess={handleCreateCommentSuccess}
                />
              ) : (
                <TaskFeedRow id={taskFeed.id} onLoad={measure} />
              )}
            </div>
          );
        }}
      </CellMeasurer>
    );
  };

  return (
    <Grid container item xs={12}>
      {loading ? (
        <TaskFeedSkeletonLoader />
      ) : (
        <List
          deferredMeasurementCache={cache}
          height={500}
          ref={feedListRef}
          rowCount={taskFeeds.length}
          rowHeight={cache.rowHeight}
          rowRenderer={rowRenderer}
          width={450}
        />
      )}
    </Grid>
  );
};

const TaskFeedSkeletonLoader = () => {
  return times(10, (row) => {
    return <Skeleton className="skeleton-row" key={row} variant="text" />;
  });
};

export default TaskFeedList;
