import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import NameLogo from "../assets/images/blackhyve-logo.png";
import BlackhyveIcon from "../icons/BlackhyveIcon";

const logoComponentStyle = makeStyles((theme) => ({
  main: {
    display: "flex",
  },
  imgStyle: {
    width: 35,
    height: 35,
    display: "flex",
  },
}));

/**
 * BlackHyve logo component
 *  @param {string} color [dark, white]
 *  @param {boolean} isName
 *  @param {object} imgStyle
 */
const BlackHyveLogoComponent = ({
  color,
  isName,
  imgStyle,
  height = 35,
  width = 35,
}) => {
  const classes = logoComponentStyle();

  return (
    <Grid container className={classes.main} justifyContent="space-around">
      <Grid item>
        {isName ? (
          <img
            className={classes.imgStyle}
            style={imgStyle}
            src={NameLogo}
            alt="BlackHyve"
          />
        ) : (
          <BlackhyveIcon
            width={width}
            height={height}
            style={{ display: "flex" }}
            color={color}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default BlackHyveLogoComponent;
