function resetTaskState(task) {
  task.status = 'todo';
  task.progress = 0;
  task.pace = 0;
  task.pr = null;
  task.forecasted_start_date = task.scheduled_start_date;
  task.forecasted_end_date = task.scheduled_end_date;
  task.actual_start_date = null;
  task.actual_end_date = null;
  task.earned_days = null;
  task.earned_planned_days = null;
}

module.exports = { resetTaskState };
