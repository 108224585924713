import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
/**
 * A dialog with a title and a close button.
 * @param {string} title - The title of the dialog.
 * @param {boolean} open - Whether the dialog is open.
 * @param {function} handleClose - Called when the dialog is closed.
 * @param {JSX} actions - Actions Buttons
 * @param {Object} DialogTitleProps
 * @param {Object} DialogContentProps
 * @param {JSX.Element} [children] - The content of the dialog.
 */
const StyledDialog = ({
  open,
  handleClose,
  title = 'Dialog Title',
  children,
  actions,
  DialogTitleProps,
  DialogContentProps,
  ...props
}) => {
  return (
    <Dialog
      fullWidth
      aria-labelledby={`dialog-${title}`}
      open={open}
      onClose={handleClose}
      {...props}
    >
      <DialogTitle {...DialogTitleProps} onClose={handleClose}>
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: (theme) => theme.spacing(1),
          top: (theme) => theme.spacing(1),
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent {...DialogContentProps}>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default StyledDialog;
