
import random from 'lodash/random';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import { TASK_COLORS } from 'helpers/constants';
import { DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { StyledDialog } from '@blackhyve/common';
import LoadingButton from '@mui/lab/LoadingButton';
import { parseDate } from '@blackhyve/utilities/dates';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { ColorSelect } from 'components/form/ColorSelect';
import CropImage from 'features/locations/components/CropImage';
import PdfToImage from 'features/locations/components/PDFToImage';
import { InputFileUpload } from 'components/common/ImageUploadComponent';

const initialState = {
  id: undefined,
  name: '',
  input_sqft: '',
  color: TASK_COLORS[random(0, 44)].value,
  sqft: 0,
  scheduled_start: null,
  scheduled_end: null
};

/**
 * Add / Update Lbs Dialog
 * @param {Boolean} open
 * @param {String} type
 * @param {Boolean} isLoading
 * @param {Object} handleClose
 * @param {String} handleSave
 * @param {Object} entityObj
 * @returns
 */
const ProductionBoardCreateLBSDialog = ({
  open,
  handleClose,
  type,
  handleSave,
  isSaving,
  isError,
  entityObj,
  handleSaveAndAddAnother,
  isMapUploadEnabled,
}) => {
  const formMethods = useForm({
    defaultValues: { ...initialState },
  });

  const [pdf, setPdf] = useState();

  const { control, handleSubmit, watch, reset, setValue, setFocus } = formMethods;

  const originalMap = watch('original_map');
  const startDate = watch('scheduled_start');

  const handleReset = useCallback(() => {
    setFocus('name');
    reset({
      id: entityObj?.id ?? initialState.id,
      name: entityObj?.name ?? initialState.name,
      color: entityObj?.color ?? TASK_COLORS[random(0, 44)].value,
      input_sqft: entityObj?.input_sqft ?? initialState.input_sqft,
      sqft: entityObj?.sqft ?? initialState.sqft,
      map_view_data: entityObj?.map_view_data
        ? JSON.parse(entityObj?.map_view_data)
        : initialState.map_view_data,
      original_map: '',
      scheduled_start: entityObj?.scheduled_start ? parseDate(entityObj?.scheduled_start) : initialState.scheduled_start,
      scheduled_end: entityObj?.scheduled_end ? parseDate(entityObj?.scheduled_end) : initialState.scheduled_end
    });
  }, [
    entityObj?.color,
    entityObj?.id,
    entityObj?.input_sqft,
    entityObj?.map_view_data,
    entityObj?.name,
    entityObj?.sqft,
    entityObj?.scheduled_start,
    entityObj?.scheduled_end,
    reset,
    setFocus,
  ]);

  const handleModifyData = (data, saveMethod, event) => {
    const payload = {
      ...data,
      scheduled_start: data.scheduled_start ? format(data.scheduled_start, "yyyy-MM-dd") : null,
      scheduled_end: data.scheduled_end ? format(data.scheduled_end, "yyyy-MM-dd") : null
    }
    saveMethod(payload, event)
  }

  useEffect(() => {
    handleReset();
  }, [handleReset]);

  return (
    <StyledDialog
      handleClose={handleClose}
      maxWidth={'lg'}
      open={open}
      title={`${entityObj?.id ? 'Edit' : 'Add'} ${type} `}
      actions={
        <>
          <Button size="small" onClick={handleClose}>
            Close
          </Button>
          {handleSaveAndAddAnother && (
            <LoadingButton
              loading={isSaving}
              size="small"
              variant="contained"
              onClick={() => {
                handleSubmit((data, event) => handleModifyData(data, handleSaveAndAddAnother, event))();
                handleReset();
              }}
            >
              Save And Add Another
            </LoadingButton>
          )}
          <LoadingButton
            disabled={!!pdf}
            loading={isSaving}
            size="small"
            variant="contained"
            onClick={handleSubmit((data, event) => handleModifyData(data, handleSave, event))}
          >
            Save
          </LoadingButton>
        </>
      }
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit((data, event) => handleModifyData(data, handleSave, event))(e)
        }}
      >
        <Grid container spacing={1}>
          {isError && (
            <Grid item xs={12}>
              <Alert severity={'error'} variant={'filled'}>
                An error occurred please try again
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormLabel>Name*</FormLabel>
            <Controller
              control={control}
              name="name"
              rules={{ required: 'Name field is required' }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  autoFocus
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  inputRef={ref}
                  placeholder={'Name'}
                  size="small"
                  type={'text'}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel>Start Date</FormLabel>
            <Controller
              control={control}
              name={'scheduled_start'}
              render={({
                field: { onChange, value, ref, ...field },
                fieldState: { error, invalid },
              }) => (
                <DatePicker
                  ref={ref}
                  value={value}
                  slotProps={{
                    textField: {
                      id: 'scheduled_start',
                      fullWidth: true,
                      size: 'small',
                      helperText: error?.message,
                      error: invalid,
                      ...field,
                    },
                    actionBar: {
                      actions: ['today'],
                    },
                  }}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel>End Date</FormLabel>
            <Controller
              control={control}
              name={'scheduled_end'}
              render={({
                field: { onChange, value, ref, ...field },
                fieldState: { error, invalid },
              }) => (
                <DatePicker
                  ref={ref}
                  value={value}
                  minDate={startDate}
                  slotProps={{
                    textField: {
                      id: 'scheduled_end',
                      fullWidth: true,
                      size: 'small',
                      helperText: error?.message,
                      error: invalid,
                      ...field,
                    },
                    actionBar: {
                      actions: ['today'],
                    },
                  }}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormLabel>
              Sqft {entityObj?.sqft && type !== 'area' ? `(${entityObj.sqft} sqft)` : ''}
            </FormLabel>
            <Controller
              control={control}
              name={type === 'area' ? 'sqft' : 'input_sqft'}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  placeholder={'Sqft'}
                  size="small"
                  type={'number'}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormLabel>Color</FormLabel>
            <Controller
              control={control}
              name="color"
              render={({ field }) => <ColorSelect {...field} fullWidth size={'small'} />}
            />
          </Grid>
          {isMapUploadEnabled && (
            <Grid item xs={12}>
              <FormLabel>Image</FormLabel>
              <div>
                {originalMap || (entityObj?.original_map?.original_url && originalMap !== null) ? (
                  <>
                    <Controller
                      control={control}
                      defaultValue={'{}'}
                      name={'map_view_data'}
                      render={({ field: { onChange, value, ...field } }) => (
                        <CropImage
                          cropData={value}
                          image={originalMap || entityObj?.original_map?.original_url}
                          handleCropComplete={(cropData) => {
                            onChange(cropData);
                          }}
                        />
                      )}
                    />
                    <Link
                      color={'error'}
                      component={'button'}
                      type={'button'}
                      onClick={() => setValue('original_map', null)}
                    >
                      Remove
                    </Link>
                  </>
                ) : pdf ? (
                  <PdfToImage
                    pdf={pdf}
                    onSelect={(blob) => {
                      setPdf(undefined);
                      setValue('original_map', blob);
                    }}
                  />
                ) : (
                  <Controller
                    control={control}
                    defaultValue={''}
                    name="original_map"
                    render={({ field: { onChange, ...field } }) => (
                      <InputFileUpload
                        ButtonProps={{ size: 'small' }}
                        label={'Upload Image'}
                        inputProps={{
                          accept: 'image/x-png,image/gif,image/jpeg,application/pdf',
                          onChange: (e) => {
                            const file = e.target.files[0];
                            if (file.type === 'application/pdf') {
                              setPdf(file);
                            } else {
                              onChange(file);
                            }
                          },
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </Grid>
          )}
        </Grid>
        <Button disabled={!!pdf} sx={{ display: 'none' }} type={'submit'}>
          Save
        </Button>
      </form>
    </StyledDialog>
  );
};

export default ProductionBoardCreateLBSDialog;
