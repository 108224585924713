import {
  Box,
  LinearProgress,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
} from '@mui/material';
import { Skeleton } from '@mui/material';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

/**
 * @typedef {Object} Column
 * @property {string} field - The field name in the row data.
 * @property {string} label - The label displayed in the table header.
 * @property {boolean} [sort] - Whether this column is sortable.
 * @property {(a: any, b: any, order: 'asc' | 'desc') => number} [comparator] - Comparator function for sorting.
 * @property {(row: any, meta: any) => React.ReactNode} [render] - Custom render function for the cell content.
 * @property {Object} [cellProps] - Props passed to the TableCell component.
 * @property {React.ReactNode} [skeleton] - Skeleton component to display when loading.
 */

const isFunction = (obj) => typeof obj === 'function';
const isString = (obj) => typeof obj === 'string';

/**
 * @typedef {Object} TableProps
 * @property {Column[]} columns - The column definitions for the table.
 * @property {any[]} rows - The data rows to be displayed in the table.
 * @property {'asc' | 'desc'} order - The sort order ('asc' or 'desc').
 * @property {string} orderBy - The field by which the data is currently sorted.
 * @property {(property: string, order: 'asc' | 'desc') => void} handleSort - Function to handle sorting.
 * @property {'client' | 'server'} [sortMode='client'] - Whether sorting is handled on the client-side or server-side.
 * @property {Function|Object} [rowProps] - Additional props or a function returning props for each row.
 * @property {Object} [headerProps] - Additional props for the table header.
 * @property {Object} [bodyProps] - Additional props for the table body.
 * @property {boolean} [isLoading=false] - Indicates if the table is in a loading state.
 * @property {boolean} [isFetching=false] - Indicates if the table is fetching data.
 * @property {boolean} [disableHeader=false] - Whether to disable the table header.
 * @property {Object} [meta] - Additional metadata passed to row rendering functions.
 * @property {boolean} [isCollapse=false] - Enables row collapsing functionality.
 * @property {Function} [rowCollapse] - Function to render the collapsible content for a row.
 * @property {Object} [tableProps] - Additional props for the MuiTable component.
 */

/**
 * Table component that supports both client-side and server-side sorting.
 *
 * @param {TableProps} props - The props for the Table component.
 * @returns {JSX.Element} The rendered Table component.
 */
export const Table = ({
  columns,
  rows,
  defaultOrder = 'desc',
  defaultOrderBy = columns[0].field,
  rowProps,
  headerProps,
  bodyProps,
  isLoading,
  isFetching,
  disableHeader,
  meta,
  isCollapse = false,
  rowCollapse,
  handleSort,
  ...tableProps
}) => {
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [comparator, setComparator] = useState(undefined);

  const onSortChange = (event, property, compare) => {
    const isDesc = orderBy === property && order === 'desc';
    const newSortOrder = isDesc ? 'asc' : 'desc';
    setOrder(newSortOrder);
    setOrderBy(property);
    if (handleSort) {
      handleSort(property, newSortOrder);
    } else {
      setComparator(compare);
    }
  };

  const sortedRows = handleSort
    ? rows
    : [...rows].sort((a, b) => {
        const aValue = a[orderBy];
        const bValue = b[orderBy];
        let comparison = 0;
        if (comparator) {
          comparison = comparator(aValue, bValue, order, a, b);
        } else {
          if (isString(aValue) && isString(bValue)) {
            comparison = -aValue.localeCompare(bValue);
          } else {
            if (aValue < bValue) {
              comparison = -1;
            } else if (aValue > bValue) {
              comparison = 1;
            }
          }
        }
        return order === 'asc' ? comparison : -comparison;
      });

  return (
    <MuiTable {...tableProps} sx={{ height: '100%' }}>
      {!disableHeader && (
        <Header
          columns={columns}
          handleSort={onSortChange}
          headerProps={headerProps}
          isCollapse={isCollapse}
          order={order}
          orderBy={orderBy}
        />
      )}
      <TableBody {...bodyProps} sx={{ position: 'relative', overflow: 'auto' }}>
        {isFetching && (
          <tr>
            <Box colSpan={columns.length + 1} component={'td'} position={'relative'}>
              <Box left={0} position={'absolute'} top={0} width={'100%'}>
                <LinearProgress />
              </Box>
            </Box>
          </tr>
        )}
        {isLoading ? (
          <RowsLoading columns={columns} />
        ) : sortedRows.length > 0 ? (
          sortedRows.map((row) => (
            <Row
              columns={columns}
              isCollapse={isCollapse}
              key={row.id}
              meta={meta}
              row={row}
              rowCollapse={rowCollapse}
              rowProps={rowProps}
            />
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} sx={{ textAlign: 'center' }}>
              No Data
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </MuiTable>
  );
};

const Header = ({ order, orderBy, handleSort, columns, headerProps, isCollapse }) => {
  const createSortHandler = (property, comparator) => (event) => {
    handleSort(event, property, comparator);
  };

  return (
    <TableHead {...headerProps}>
      <TableRow>
        {isCollapse ? <TableCell /> : null}
        {columns.map((column) => (
          <TableCell
            align={'left'}
            key={`header-${column.field}`}
            padding={'normal'}
            sortDirection={orderBy === column.field ? order : false}
            sx={{ fontWeight: 600 }}
            {...column?.cellProps}
          >
            {column.label &&
              (column.sort ? (
                <TableSortLabel
                  active={orderBy === column.field}
                  direction={orderBy === column.field ? order : 'desc'}
                  onClick={createSortHandler(column.field, column?.comparator)}
                >
                  {column.label}
                </TableSortLabel>
              ) : (
                column.label
              ))}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const Row = ({ row, columns, rowProps, meta, isCollapse, rowCollapse }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box hover component={TableRow} {...(isFunction(rowProps) ? rowProps(row) : rowProps)}>
        {isCollapse ? (
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : null}
        {columns.map((column) => (
          <TableCell align={'left'} key={`${row.id}-${column.field}`} {...column?.cellProps}>
            {column.render ? column.render(row, meta) : row[column.field]}
          </TableCell>
        ))}
      </Box>
      {isCollapse && open ? rowCollapse(row, open) : null}
    </>
  );
};

const RowsLoading = ({ columns, rows = 10 }) => {
  return Array.from({ length: rows }, (_, index) => (
    <TableRow key={index}>
      {columns.map((column) => (
        <TableCell key={`${index}-loading-${column.field}`}>
          {column.skeleton ? column.skeleton : <Skeleton variant="text" />}
        </TableCell>
      ))}
    </TableRow>
  ));
};
