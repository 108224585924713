import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import { memo, useCallback } from 'react';

/**
 * Select filter type component
 * @param {Number} index
 * @param {Object} filter
 * @param {Object} error
 * @param {Number} filter.id
 * @param {Any} filter.value
 * @param {String} filter.type
 * @param {Object} handleUpdateType
 * @param {Object} handleUpdateValue
 * @param {Object} handleDelete
 * @returns
 */
const FilterRow = memo(
  ({ index, id, type, value, projectId, handleUpdate, handleDelete, filterOptions, gantt, error }) => {
    return (
      <Grid container item alignItems={'center'} gap={1} style={{ padding: '5px 0px' }}>
        <Grid item xs={1}>
          <Typography component="label" variant="body1">
            {index > 0 ? 'And' : 'Where'}
          </Typography>
        </Grid>
        <Grid item sm={10} xs={12}>
          <Grid container spacing={1}>
            <Grid item sm={5} xs={12}>
              <Autocomplete
                isOptionEqualToValue={(option, value) => option === value}
                options={Object.keys(filterOptions)}
                size="small"
                value={type}
                getOptionLabel={(option) =>
                  filterOptions[option]?.name ? filterOptions[option]?.name : option
                }
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} fullWidth placeholder="Select filter" />
                )}
                onChange={(_, newValue) =>
                  handleUpdate({
                    id,
                    type: newValue,
                    value: null,
                    name: newValue ? filterOptions[newValue]?.name : '',
                    filterFunction: newValue ? filterOptions[newValue].filterFunction : null,
                  })
                }
              />
            </Grid>
            <Grid item sm={7} xs={12}>
              {filterOptions[type]
                ? filterOptions[type].render({ handleUpdate, id, value, projectId, gantt, error })
                : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item alignItems="center">
          <IconButton
            aria-label="delete"
            size="medium"
            style={{ padding: 5 }}
            onClick={() => handleDelete(id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
);

/**
 * Filter Popover
 * @param {Object} handleClose
 * @param {String} projectId
 * @param {Object} handleSave
 * @param {Object} filters
 * @param {Object} setFilters
 * @param {Object} filterOptions
 * @returns
 */
const FilterPopover = ({ handleClose, projectId, filterOptions, setFilters, filters, gantt }) => {
  const handleAddFilter = useCallback(() => {
    setFilters((prevFilters) => [...prevFilters, { id: Math.random(), type: '', value: null }]);
  }, []);

  const handleUpdateFilter = useCallback((newFilter) => {
    setFilters((prevFilters) => {
      const index = prevFilters.findIndex((condition) => condition.id === newFilter.id);
      const updatedFilters = [...prevFilters];
      updatedFilters[index] = { ...updatedFilters[index], ...newFilter };
      return updatedFilters;
    });
  }, []);

  const handleDeleteFilter = useCallback((id) => {
    setFilters((prevFilters) => {
      const index = prevFilters.findIndex((condition) => condition.id === id);
      const updatedFilters = [...prevFilters];
      updatedFilters.splice(index, 1);
      return updatedFilters;
    });
  }, []);

  const handleClear = useCallback((event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setFilters(() => []);
    }
  }, []);

  return (
    <Grid container style={{ margin: 'auto', padding: '15px', width: 950 }}>
      <Grid container item>
        <Typography style={{ fontWeight: '600', fontSize: '15px', textTransform: 'uppercase' }}>
          Filters
        </Typography>
        <CloseIcon
          style={{ marginLeft: 'auto', opacity: 0.5, cursor: 'pointer' }}
          onClick={handleClose}
        />
      </Grid>
      <Grid
        container
        item
        direction="column"
        spacing={2}
        style={{ padding: '20px 10px 20px 20px' }}
      >
        {filters?.map((filter, index) => {
          return (
            <FilterRow
              error={filter?.error}
              filterOptions={filterOptions}
              gantt={gantt}
              handleDelete={handleDeleteFilter}
              handleUpdate={handleUpdateFilter}
              id={filter.id}
              index={index}
              key={filter.id}
              projectId={projectId}
              type={filter.type}
              value={filter.value}
            />
          );
        })}
      </Grid>
      <Grid container item>
        <Button size={'small'} onClick={handleAddFilter}>
          <AddIcon fontSize="small" style={{ height: '15px' }} />
          <ListItemText style={{ fontSize: '13px', textTransform: 'capitalize' }}>
            Add filters
          </ListItemText>
        </Button>
        {filters && filters.length ? (
          <Button size={'small'} onClick={handleClear}>
            <DeleteIcon fontSize="small" style={{ height: '15px' }} />
            <ListItemText style={{ fontSize: '13px', textTransform: 'capitalize' }}>
              Clear all
            </ListItemText>
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default FilterPopover;
