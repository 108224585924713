import { api, providesList } from 'api';

export const notificationApi = api
  .enhanceEndpoints({ addTagTypes: ['Notification'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getMyNotifications: build.query({
        query: ({ page = 1, userId }) => `/notifications?page=${page}&user=${userId}`,
        providesTags: (result) => providesList(result.data, 'Notification'),
        serializeQueryArgs: ({ endpointName }) => endpointName,
        forceRefetch: ({ currentArg, previousArg }) =>
          currentArg?.page === 1 ||
          currentArg?.page !== previousArg?.page ||
          currentArg?.userId !== previousArg?.userId,
        merge(cache, newItems, { arg }) {
          const page = arg?.page;
          if (page === 1) {
            cache.data = newItems.data;
          } else {
            cache.data.push(...newItems.data);
          }
          cache.meta = newItems.meta;
        },
      }),
      getNotification: build.query({
        query: ({ id, userId }) => ({ url: `/users/${userId}/notifications/${id}` }),
        providesTags: (result, error, arg) => [{ type: 'Notification', id: arg }],
      }),
      getWorkspaceNotificationLog: build.query({
        query: (queryParam) => {
          const queryString = Object.entries(queryParam)
            .filter(([key, value]) => value !== undefined)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
          return `/notifications?${queryString}&order_by=created_at,desc`;
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          const newQueryArgs = { ...queryArgs };
          if (newQueryArgs.hasOwnProperty('page')) {
            delete newQueryArgs.page;
          }
          return newQueryArgs;
        },
        merge: (currentCache, newItems, queryArgs) => {
          if (queryArgs?.arg?.page > 1) {
            currentCache.data.push(...newItems.data);
          } else {
            currentCache.data = newItems.data;
          }
          currentCache.current_page = newItems.current_page;
          currentCache.last_page = newItems.last_page;
        },
        transformResponse: (response) => {
          return {
            data: response.data,
            current_page: response?.meta?.current_page,
            last_page: response?.meta?.last_page,
          };
        },
        providesTags: (result, error) => providesList(result?.data, 'WorkspaceNotificationLog'),
      }),
      readNotification: build.mutation({
        query: (notificationId) => ({
          url: `notifications/${notificationId}/read`,
          method: 'POST',
        }),
        async onQueryStarted(
          notificationId,
          { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
        ) {
          const patchResult = dispatch(
            api.util.updateQueryData('getMyNotifications', undefined, (draft) => {
              // Set notifications value as current date and move to respective index if possible
              draft.meta.unread_count -= 1;
              draft.meta.read_count += 1;
              const notificationIndex = draft?.data?.findIndex(
                (notification) => notification.id === notificationId
              );
              const notification = draft?.data.splice(notificationIndex, 1)[0];
              if (notification) {
                notification.inbox_read_at = new Date().toUTCString();
                if (draft.meta.unread_count < draft.data.length) {
                  draft.data.splice(draft.meta.unread_count, 0, notification);
                }
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      }),
      unreadNotification: build.mutation({
        query: (notificationId) => ({
          url: `notifications/${notificationId}/unread`,
          method: 'POST',
        }),
        async onQueryStarted(
          notificationId,
          { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
        ) {
          const patchResult = dispatch(
            api.util.updateQueryData('getMyNotifications', undefined, (draft) => {
              // Set notifications value as current date and move to respective index if possible
              draft.meta.unread_count += 1;
              draft.meta.read_count -= 1;
              const notificationIndex = draft?.data?.findIndex(
                (notification) => notification.id === notificationId
              );
              const notification = draft?.data.splice(notificationIndex, 1)[0];
              if (notification) {
                notification.inbox_read_at = null;
                //Insert into location based off of created_at date index must be less than unread count
                const unreadNotifications = draft.data.slice(0, draft?.meta.unread_count - 1);
                const insertIndex = unreadNotifications.findIndex(
                  (item) => item.created_at < notification.created_at
                );
                if (insertIndex === -1) {
                  draft.data.splice(draft?.meta?.unread_count - 1, 0, notification);
                } else {
                  draft.data.splice(insertIndex, 0, notification);
                }
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      }),
    }),
  });
export const {
  useGetMyNotificationsQuery,
  useReadNotificationMutation,
  useUnreadNotificationMutation,
  useGetNotificationQuery,
  useGetWorkspaceNotificationLogQuery,
} = notificationApi;
