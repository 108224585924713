import { ArrowDropDown, ArrowDropUp, Check } from '@mui/icons-material';
import { List, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material';
import { MenuButton } from 'assets/style-components/button';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerMenuState, updateMenuState } from 'slices/customViewSlice';
import ganttStore from '../../ganttConfig/ganttStore';

const ZoomMenu = ({ ganttId, text = 'Scale' }) => {
  const dispatch = useDispatch();
  let gantt = ganttStore.getGantt(ganttId);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuItems = gantt ? gantt.ext.zoom.getLevels() : [];
  const selectedZoom = useSelector((state) => state.customViews?.menus?.zoom?.currentValue);

  const handleSelect = (event, option) => {
    dispatch(updateMenuState({ name: 'zoom', value: option.toLowerCase() }));
    // gantt.ext.zoom.setLevel(option.toLowerCase());
  };

  const handleOpen = (event) => {
    gantt = ganttStore.getGantt(ganttId);
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (gantt?.ext?.zoom.getLevels() && selectedZoom) {
      gantt?.ext?.zoom?.setLevel(selectedZoom);
    }
  }, [gantt, selectedZoom, ganttId]);

  useEffect(() => {
    dispatch(registerMenuState({ name: 'zoom', value: 'days' }));
  }, [dispatch]);

  useEffect(() => {
    if (gantt?.ext?.zoom.getLevels()) {
      gantt?.ext?.zoom?.attachEvent('onAfterZoom', function (level, config) {
        dispatch(updateMenuState({ name: 'zoom', value: config.name }));
      });
      return gantt?.ext?.zoom?.detachEvent('onAfterZoom');
    }
  }, [gantt, dispatch]);

  return (
    <>
      <MenuButton
        endIcon={Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />}
        isHighlighted={selectedZoom !== 'days'}
        onClick={handleOpen}
      >
        {text}
      </MenuButton>
      {anchorEl !== null ? (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={Boolean(anchorEl)}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setAnchorEl(null)}
        >
          <List dense>
            {menuItems
              ? menuItems.toReversed().map(({ name }) => {
                  const selected = name === selectedZoom;
                  return (
                    <ListItemButton key={name} onClick={(event) => handleSelect(event, name)}>
                      <ListItemIcon>{selected ? <Check /> : null}</ListItemIcon>
                      <ListItemText primary={name} sx={{ textTransform: 'capitalize' }} />
                    </ListItemButton>
                  );
                })
              : null}
          </List>
        </Popover>
      ) : null}
    </>
  );
};

export default ZoomMenu;
