import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Fab,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  TableContainer,
  TextField,
  Toolbar,
  Tooltip,
} from '@mui/material';
import { Add, Clear, ExpandLess, ExpandMore, Star } from '@mui/icons-material';
import { Alert, Skeleton } from '@mui/material';
import { Popover } from 'components/Popover';
import SearchBox from 'components/search/SearchBox';
import { Table } from 'components/table/Table';
import { useContext, useMemo, useState } from 'react';
import {
  useDeleteProjectCompanyMutation,
  useDeleteProjectCompanyUserMutation,
  useGetProjectCompaniesQuery,
  useUpdateProjectCompanyUserMutation,
} from '../api/projectCompanies.api';
import { AddCompaniesDialog } from './AddCompaniesDialog';
import { AddCompanyUsersDialog } from './AddCompanyUsersDialog';
import DisplayWithEditAccess, { EditContext } from 'components/common/v3/DisplayWithEditAccess';
import { RequireRole } from 'features/auth/components/RequireRole';
import { selectCurrentUserRole } from 'features/auth';
import { useSelector } from 'react-redux';

const userColumns = [
  {
    field: 'profile_image',
    skeleton: <Skeleton height={40} variant="circular" width={40} />,
    render: (user) => (
      <Avatar src={user?.profile_image?.thumb_url}>
        {user?.first_name[0]}
        {user?.last_name[0]}
      </Avatar>
    ),
    cellProps: { style: { width: '40px' } },
  },
  { label: 'First Name', field: 'first_name', sort: true },
  { label: 'Last Name', field: 'last_name', sort: true },
  // { label: 'Email', field: 'email', sort: true },
  // { label: 'Phone', field: 'phone', sort: true },
  {
    label: 'Access Level',
    field: 'user_access_level',
    sort: true,
    render: (user, meta) => (
      <UserAccessCell
        companyId={meta.companyId}
        disabled={meta.disabled}
        initialValue={user?.user_access_level}
        projectId={meta.projectId}
        userId={user.id}
      />
    ),
  },
  {
    label: 'Role',
    field: 'project_role',
    sort: true,
    render: (user, meta) => (
      <UserRoleCell
        companyId={meta.companyId}
        disabled={meta.disabled}
        initialValue={user?.project_role || ''}
        projectId={meta.projectId}
        userId={user.id}
      />
    ),
  },
  {
    field: 'moreOptions',
    cellProps: { align: 'right' },
    render: (user, meta) =>
      !meta.disabled && (
        <RemoveUserButton companyId={meta.companyId} projectId={meta.projectId} userId={user.id} />
      ),
  },
];

const emptyArray = [];

export const ProjectCompaniesTable = ({ projectId }) => {
  const [search, setSearch] = useState('');
  const [isAddCompanyOpen, setIsAddCompanyOpen] = useState(false);

  const {
    data: companies = emptyArray,
    isLoading,
    isFetching,
  } = useGetProjectCompaniesQuery({ projectId });

  const filteredCompanies = companies
    .map(({ ...company }) => {
      company.users = company.users.filter(
        (user) =>
          (user.first_name + user.last_name).toLowerCase().includes(search.toLowerCase()) ||
          company.name.toLowerCase().includes(search.toLowerCase())
      );
      if (search !== '') company.$isOpen = true;
      return company;
    })
    .filter(
      (company) =>
        company.users.length > 0 || company.name.toLowerCase().includes(search.toLowerCase())
    );

  return (
    <TableContainer component={Paper}>
      <Toolbar style={{ position: 'sticky', left: '0px' }}>
        <SearchBox
          autoComplete={'off'}
          bgcolor={'grey.200'}
          borderRadius={'5px'}
          fullWidth={false}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <Box ml={'auto'} />
        <RequireRole exclude roles={['contractor_contact']}>
          <DisplayWithEditAccess>
            <Tooltip placement={'top'} title={'Add Company'}>
              <Fab
                color="primary"
                size="small"
                variant={'extended'}
                onClick={() => setIsAddCompanyOpen(true)}
              >
                Add Company
              </Fab>
            </Tooltip>

            <AddCompaniesDialog
              handleClose={() => setIsAddCompanyOpen(false)}
              open={isAddCompanyOpen}
              projectId={projectId}
            />
          </DisplayWithEditAccess>
        </RequireRole>
      </Toolbar>
      {isLoading ? (
        <Box alignItems={'center'} display={'flex'} justifyContent={'center'} p={8}>
          <CircularProgress />
        </Box>
      ) : filteredCompanies.length > 0 ? (
        <List dense disablePadding>
          {filteredCompanies.map((company) => (
            <CompanyRow company={company} key={company.id} projectId={projectId} />
          ))}
        </List>
      ) : companies.length > 0 ? (
        <Alert severity={'info'}>No Matches Found For Current Search Query</Alert>
      ) : (
        <Alert severity={'info'}>No Companies Added</Alert>
      )}
    </TableContainer>
  );
};

const CompanyRow = ({ projectId, company }) => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const userRole = useSelector((state) => selectCurrentUserRole(state));
  const canEdit = useContext(EditContext) && userRole !== 'contractor_contact';

  const [removeCompany, { isLoading: isRemoving }] = useDeleteProjectCompanyMutation();

  const tableMeta = useMemo(
    () => ({
      projectId: projectId,
      companyId: company.id,
      workspaceOwner: company.workspace_owner,
      disabled: !canEdit,
    }),
    [canEdit, company.id, company.workspace_owner, projectId]
  );

  return (
    <>
      <ListItem divider={!isOpen}>
        <IconButton size="large" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        {company.name}
        {Boolean(company?.workspace_owner) && (
          <Box px={1}>
            <Chip color={'primary'} icon={<Star />} label={'Project Owner'} size={'small'} />
          </Box>
        )}
        <RequireRole exclude roles={['contractor_contact']}>
          <DisplayWithEditAccess>
            <Box px={1}>
              <Button
                color={'secondary'}
                endIcon={<Add />}
                size={'small'}
                style={{ lineHeight: 'unset' }}
                variant={'text'}
                onClick={() => setIsAddOpen(true)}
              >
                Add User
              </Button>
              <AddCompanyUsersDialog
                companyId={company.id}
                handleClose={() => setIsAddOpen(false)}
                open={isAddOpen}
                projectId={projectId}
              />
            </Box>
            <Box ml={'auto'} />
            {!company?.workspace_owner && (
              <ConfirmMenu
                trigger={
                  <IconButton disabled={isRemoving} size="large">
                    <Clear />
                    {isRemoving && (
                      <Box display={'flex'} position={'absolute'}>
                        <CircularProgress size={'2rem'} />
                      </Box>
                    )}
                  </IconButton>
                }
                onConfirm={() => removeCompany({ projectId, companyId: company.id })}
              />
            )}
          </DisplayWithEditAccess>
        </RequireRole>
      </ListItem>
      <Collapse mountOnEnter unmountOnExit in={isOpen || company.$isOpen}>
        <ListItem divider>
          <TableContainer component={Paper}>
            <Table
              columns={userColumns}
              defaultOrderBy={'first_name'}
              disableHeader={false}
              meta={tableMeta}
              rows={company.users}
              size={'small'}
            />
          </TableContainer>
        </ListItem>
      </Collapse>
    </>
  );
};

const UserAccessCell = ({ initialValue = '', projectId, companyId, userId, disabled }) => {
  const [value, setValue] = useState(initialValue);
  const [updateUser, { isLoading: isUpdating }] = useUpdateProjectCompanyUserMutation();

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    updateUser({ projectId, companyId, userId, user: { user_access_level: newValue } });
  };

  return (
    <Box alignItems={'center'} display={'flex'}>
      <Select
        disableUnderline
        disabled={disabled}
        size={'small'}
        value={value}
        onChange={handleChange}
      >
        <MenuItem value={'full_access'}>Full</MenuItem>
        <MenuItem value={'view_only'}>View</MenuItem>
      </Select>
      {isUpdating && <CircularProgress size={'1rem'} />}
    </Box>
  );
};
const UserRoleCell = ({ initialValue = '', projectId, companyId, userId, disabled }) => {
  const [value, setValue] = useState(initialValue);
  const [updateUser, { isLoading: isUpdating }] = useUpdateProjectCompanyUserMutation();

  const handleChange = (event) => {
    event.preventDefault();
    updateUser({ projectId, companyId, userId, user: { project_role: value } });
  };

  return (
    <Box alignItems={'center'} display={'flex'} position={'relative'}>
      <form onSubmit={handleChange}>
        <TextField
          disabled={disabled}
          size={'small'}
          value={value}
          onBlur={handleChange}
          onChange={(event) => setValue(event.target.value)}
        ></TextField>
      </form>
      {isUpdating && (
        <Box component={CircularProgress} position={'absolute'} right={'0px'} size={'1rem'} />
      )}
    </Box>
  );
};

const RemoveUserButton = ({ projectId, companyId, userId }) => {
  const [removeUser, { isLoading }] = useDeleteProjectCompanyUserMutation();

  const handleRemove = () => {
    removeUser({ projectId, companyId, userId });
  };

  return (
    <ConfirmMenu
      trigger={
        <Button disabled={isLoading} size={'small'}>
          Remove Access
          {isLoading && (
            <Box display={'flex'} position={'absolute'}>
              <CircularProgress size={'1.5rem'} />
            </Box>
          )}
        </Button>
      }
      onConfirm={handleRemove}
    />
  );
};

const ConfirmMenu = ({ trigger, onConfirm }) => {
  return (
    <Popover
      trigger={trigger}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      {({ handleClose }) => (
        <>
          <Button onClick={handleClose}>Cancel</Button>
          <Box
            color={'error.main'}
            component={Button}
            onClick={() => {
              onConfirm();
              handleClose();
            }}
          >
            Remove
          </Box>
        </>
      )}
    </Popover>
  );
};
