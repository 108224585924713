import { api, providesList } from 'api';
import { objectToFormData } from 'helpers/objectToFormData';

const zoneAPI = api.enhanceEndpoints({ addTagTypes: ['Zone', 'Location-Zones'] }).injectEndpoints({
  endpoints: (build) => ({
    createZone: build.mutation({
      query: ({ locationId, zone }) => {
        let body = zone;
        if (zone?.map instanceof File || zone?.original_map instanceof File) {
          body = objectToFormData(zone);
        }
        return {
          url: `locations/${locationId}/zones`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, params) => {
        const locationId = params.locationId ? params.locationId : params.get('locationId');
        return result
          ? [
            { type: 'Zone', id: 'LIST' },
            { type: 'Location-Zones', id: locationId },
            { type: 'Location', id: locationId },
          ]
          : [];
      },
    }),
    updateZone: build.mutation({
      query: ({ zoneId, zone }) => {
        let body = zone;
        let method = 'PUT';
        if (zone?.map instanceof File || zone?.original_map instanceof File) {
          method = 'POST';
          body = objectToFormData(zone);
          body.append('_method', 'PATCH');
        }
        return {
          url: `zones/${zoneId}`,
          method,
          body,
        };
      },
      invalidatesTags: (result, error, args) => {
        const isFormData = args instanceof FormData;
        const zoneId = isFormData ? args?.get('zoneId') : args.zoneId;
        const locationId = isFormData ? args?.get('locationId') : args.locationId;
        return result
          ? [
            { type: 'Zone', id: zoneId },
            { type: 'Location', id: locationId },
          ]
          : [];
      },
    }),
    getZones: build.query({
      query: ({ locationId }) => `/locations/${locationId}/zones`,
      providesTags: (result, error, args) =>
        result
          ? [{ type: `Location-Zones`, id: args.locationId }, ...providesList(result, `Zone`)]
          : [],
      transformResponse: (response) => response.data,
    }),
    getZone: build.query({
      query: ({ locationId, zoneId }) => `/locations/${locationId}/zones/${zoneId}`,
      providesTags: (result, error, args) =>
        result
          ? [
            { type: `Location-Zones`, id: args.locationId },
            { type: 'Zone', id: args.zoneId },
          ]
          : [],
      transformResponse: (response) => response.data,
    }),
    deleteZone: build.mutation({
      query: ({ locationId, zoneId }) => ({
        url: `/locations/${locationId}/zones/${zoneId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, params) =>
        result
          ? [
            { type: 'Zone', id: params.zoneId },
            { type: 'Location', id: params.locationId },
          ]
          : [],
      async onQueryStarted({ zoneId, locationId, projectId }, { dispatch, queryFulfilled }) {
        const updateGetZones = dispatch(
          api.util.updateQueryData(
            'getZones',
            { locationId: isNaN(parseInt(locationId)) ? locationId : parseInt(locationId) },
            (zones) => zones.filter((zone) => zone.id !== zoneId)
          )
        );
        const updateGetLbs = dispatch(
          api.util.updateQueryData('getLbs', { projectId }, (locations) => {
            const zones = locations.find((location) => location.id == locationId).zones;
            const index = zones.findIndex((zone) => zone.id === zoneId);
            if (index !== -1) {
              zones.splice(index, 1);
            }
          })
        );
        queryFulfilled.catch(() => {
          updateGetZones.undo();
          updateGetLbs.undo();
        });
      },
    }),
    duplicateZone: build.mutation({
      query: ({ zoneId }) => ({
        url: `/zones/${zoneId}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, params) =>
        result
          ? [
            { type: 'Zone', id: 'LIST' },
            { type: 'Location-Zones', id: params.locationId },
            { type: 'Location', id: params.locationId },
          ]
          : [],
    }),
    reorderZone: build.mutation({
      query: (body) => ({
        url: `locations/${body.locationId}/zones/reorder`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, params) =>
        result ? [{ type: 'Location-Zones', id: params.locationId }] : [],
      async onQueryStarted(
        { sourceIndex, destinationIndex, locationId, projectId },
        { dispatch, queryFulfilled }
      ) {
        const updateGetZones = dispatch(
          api.util.updateQueryData(
            'getZones',
            { locationId: isNaN(parseInt(locationId)) ? locationId : parseInt(locationId) },
            (zones) => {
              const zone = zones.splice(sourceIndex, 1);
              zones.splice(destinationIndex, 0, ...zone);
            }
          )
        );
        const updateGetLbs = dispatch(
          api.util.updateQueryData('getLbs', { projectId }, (locations) => {
            const zones = locations.find((location) => location.id == locationId).zones;
            const zone = zones.splice(sourceIndex, 1);
            zones.splice(destinationIndex, 0, ...zone);
          })
        );
        queryFulfilled.catch(() => {
          updateGetZones.undo();
          updateGetLbs.undo();
        });
      },
    }),
    getPhaseZones: build.query({
      query: ({ phaseId }) => `/phases/${phaseId}/zones`,
      providesTags: (result, error, args) =>
        result
          ? [...providesList(result, `Zone`)]
          : [],
      transformResponse: (response) => response.data,
    }),
    createPhaseZone: build.mutation({
      query: ({ phaseId, zone }) => {
        let body = zone;
        if (zone?.map instanceof File || zone?.original_map instanceof File) {
          body = objectToFormData(zone);
        }
        return {
          url: `phases/${phaseId}/zones`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, params) => {
        const phaseId = params.phaseId ? params.phaseId : params.get('phaseId');
        return result
          ? [
            { type: 'Zone', id: 'LIST' },
            { type: 'Phase', id: phaseId },
          ]
          : [];
      },
    }),
  }),
});

export const {
  useCreateZoneMutation,
  useGetZonesQuery,
  useGetZoneQuery,
  useDeleteZoneMutation,
  useUpdateZoneMutation,
  useDuplicateZoneMutation,
  useReorderZoneMutation,
  usePrefetch,
  useGetPhaseZonesQuery,
  useCreatePhaseZoneMutation
} = zoneAPI;
