export const BlackHyveLogoWithText = (props) => (
  <svg viewBox="0 0 143 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.5318 4.2401L0 8.00876V18.7393L9.11684 24V21.0204L2.58504 17.2512V9.49688L6.5318 7.21913V15.4477L9.11684 16.9358V1.49147L6.5318 0V4.2401Z"
      fill="currentColor"
    />
    <path
      d="M15.5209 4.23954V0L12.9358 1.49147V16.9358L15.5209 15.4477V7.21913L19.4676 9.49688V17.2512L12.9358 21.0204V24L22.0527 18.7393V8.00876L15.5209 4.23954Z"
      fill="currentColor"
    />
    <path
      d="M71.7748 8.61203C68.8876 8.61203 66.7427 10.6081 66.7427 13.3971C66.7427 16.1861 68.8876 18.1809 71.7614 18.1809C73.3772 18.1809 74.7143 17.5993 75.5891 16.5284L74.2118 15.2596C73.5889 15.9857 72.8077 16.356 71.8806 16.356C70.1457 16.356 68.9144 15.1406 68.9144 13.3959C68.9144 11.6523 70.1457 10.4357 71.8806 10.4357C72.8077 10.4357 73.5901 10.806 74.2118 11.52L75.5891 10.2512C74.7143 9.19362 73.3772 8.61203 71.7748 8.61203Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.5992 18.0231C35.0362 18.0231 36.3076 17.0979 36.3076 15.4988C36.3076 14.3356 35.6458 13.5428 34.614 13.2113C35.3684 12.8155 35.8453 12.1016 35.8453 11.1764C35.8453 9.73635 34.653 8.77108 32.3352 8.77108H27.8055V18.0231H32.5992ZM29.9384 12.5642V10.3835H32.07C33.1163 10.3835 33.6857 10.7393 33.6857 11.4665C33.6857 12.1938 33.1163 12.5642 32.07 12.5642H29.9384ZM32.4411 14.1244C33.5531 14.1244 34.1493 14.4947 34.1493 15.2742C34.1493 16.0671 33.5531 16.4107 32.4411 16.4107H29.9384V14.1244H32.4411Z"
      fill="currentColor"
    />
    <path
      d="M43.8303 8.77108H41.6853V18.0231H48.4792V16.2783H43.8303V8.77108Z"
      fill="currentColor"
    />
    <path
      d="M58.4656 8.77108H56.3462L52.2144 18.0231H54.4129L57.3925 10.8728L60.3588 18.0231H62.6108L58.4656 8.77108Z"
      fill="currentColor"
    />
    <path
      d="M84.1581 14.3879L82.9134 15.6834V18.0231H80.7806V8.77108H82.9134V13.0923L87.0185 8.77108H89.4019L85.5609 12.8944L89.627 18.0231H87.1231L84.1581 14.3879Z"
      fill="currentColor"
    />
    <path
      d="M102.832 18.0231V8.77108H100.687V12.4185H96.4756V8.77108H94.3306V18.0231H96.4756V14.23H100.687V18.0231H102.832Z"
      fill="currentColor"
    />
    <path
      d="M113.228 14.7448V18.0219H111.083V14.7181L107.494 8.77108H109.771L112.249 12.8811L114.726 8.77108H116.832L113.228 14.7448Z"
      fill="currentColor"
    />
    <path
      d="M126.38 18.0231L130.393 8.77108H128.26L125.4 15.3786L122.579 8.77108H120.262L124.262 18.0231H126.38Z"
      fill="currentColor"
    />
    <path
      d="M142.286 16.305V18.0231H135.107V8.77108H142.113V10.4891H137.239V12.4986H141.544V14.1644H137.239V16.305H142.286Z"
      fill="currentColor"
    />
  </svg>
);

export default BlackHyveLogoWithText;
