import { useState } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import LbsMapView from './LbsMapView';
import LbsListView from './LbsListView';
import { Zone } from './Zone';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Navigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { ContentLayout } from 'components/layouts';
import Typography from '@mui/material/Typography';
import AddEditLbsDialog from '../components/AddEditLbsDialog';
import GridViewIcon from '@mui/icons-material/GridView';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { SecondaryNav, SecondaryNavLink } from 'components/navigation/SecondaryNav';
import { useCreateLocationMutation } from '../store/location.api';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DisplayWithEditAccess from 'components/common/v3/DisplayWithEditAccess';

export const Locations = () => {
  const { pathname } = useLocation();
  const projectId = Number(useParams().projectId) || undefined;

  const [openAddLocationDialog, setOpenAddLocationDialog] = useState(false);

  const [createLocation, { isLoading: isCreating }] = useCreateLocationMutation();

  const handleAddLocation = () => {
    setOpenAddLocationDialog(true);
  };

  const handleCloseAddLocation = () => setOpenAddLocationDialog(false);
  const handleSaveAndAddAnother = (location, e) => {
    handleCreateLocation(location, e, true);
  };
  const handleCreateLocation = (data, e, preventClose) => {
    createLocation({ ...data, project_id: projectId }).then((response) => {
      if (!preventClose) setOpenAddLocationDialog(false);
    });
  };

  return (
    <>
      <ContentLayout
        header={
          <>
            <SecondaryNav>
              <Grid container item alignItems="center" p={1} xs={12}>
                <Grid item xs='auto'>
                  <DisplayWithEditAccess>
                    <Button
                      size="small"
                      startIcon={<AddIcon fontSize="inherit" />}
                      variant="contained"
                      sx={{
                        '& .MuiButton-startIcon': {
                          marginRight: '5px',
                        },
                      }}
                      onClick={handleAddLocation}
                    >
                      Add Location
                    </Button>
                  </DisplayWithEditAccess>
                </Grid>
                <Grid container item gap={1} justifyContent={'center'} m="auto" xs='auto'>
                  <SecondaryNavLink
                    size="medium"
                    startIcon={<FormatListBulletedIcon fontSize="inherit" />}
                    to='list'
                  >
                    List
                  </SecondaryNavLink>
                  <SecondaryNavLink
                    size="medium"
                    startIcon={<GridViewIcon fontSize="inherit" />}
                    to={'map-view'}
                  >
                    Map
                  </SecondaryNavLink>
                </Grid>

                {openAddLocationDialog && (
                  <AddEditLbsDialog
                    handleClose={handleCloseAddLocation}
                    handleSave={handleCreateLocation}
                    handleSaveAndAddAnother={handleSaveAndAddAnother}
                    isSaving={isCreating}
                    open={openAddLocationDialog}
                    type="location"
                  />
                )}
              </Grid>
            </SecondaryNav>
          </>
        }
      >
        <Box p={3}>
          <Outlet />
        </Box>
      </ContentLayout>
    </>
  );
};

export const locationRoutes = [
  {
    path: '',
    element: <Locations />,
    children: [
      { path: 'list', element: <LbsListView /> },
      { path: 'map-view', element: <LbsMapView /> },
      { index: true, element: <Navigate replace to={`./list`} /> },
    ],
  },
  {
    path: ':locationId/zones/:zoneId',
    element: <Zone />,
  },
];
