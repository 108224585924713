import { addWorkdays, differenceInWorkdays } from '@blackhyve/utilities/dates';

const defaultWorkdayCalendar = {
  sunday: '0',
  monday: '1',
  tuesday: '1',
  wednesday: '1',
  thursday: '1',
  friday: '1',
  saturday: '0',
};

export function getProductionRatingFromPercentComplete({
  percentComplete,
  startDate,
  effectiveDate,
  scheduledDuration,
  daysBlocked = 0,
  workdayCalendar = defaultWorkdayCalendar,
  holidays = []
}) {
  const earnedDays = percentComplete * scheduledDuration;
  const earnedPlannedDays =
    differenceInWorkdays(startDate, effectiveDate, { workdayCalendar, holidays }) + 1 - daysBlocked;
  const productionRating = earnedDays / earnedPlannedDays;
  const forecastedDuration =
    (productionRating > 0
      ? scheduledDuration / productionRating
      : differenceInWorkdays(
        startDate,
        addWorkdays(effectiveDate, scheduledDuration, { workdayCalendar }),
        { workdayCalendar, holidays }
      ) + 1) + daysBlocked;
  const forecastedEndDate = addWorkdays(startDate, forecastedDuration - 1, { workdayCalendar, holidays });

  return { earnedDays, earnedPlannedDays, productionRating, forecastedDuration, forecastedEndDate };
}

export function getProductionRatingFromEndDate({
  endDate,
  startDate,
  effectiveDate,
  scheduledDuration,
  daysBlocked = 0,
  workdayCalendar = defaultWorkdayCalendar,
  holidays
}) {
  const earnedPlannedDays =
    +effectiveDate < +startDate
      ? 0
      : differenceInWorkdays(startDate, effectiveDate, { workdayCalendar, holidays }) + 1 - daysBlocked;
  const forecastedDuration =
    differenceInWorkdays(startDate, endDate, { workdayCalendar, holidays }) + 1 - daysBlocked;
  const productionRating = scheduledDuration / forecastedDuration;
  const earnedDays = productionRating * earnedPlannedDays;
  const percentComplete = earnedDays / scheduledDuration;

  return { earnedDays, earnedPlannedDays, productionRating, forecastedDuration, percentComplete };
}
