import { api, providesList } from 'api';

const budgetAPI = api.enhanceEndpoints({ addTagTypes: ['Budget'] }).injectEndpoints({
  endpoints: (build) => ({
    getBudgets: build.query({
      query: ({ projectId }) => `/projects/${projectId}/budgets`,
      providesTags: (result, _error, _args) => (result ? providesList(result, 'Budget') : []),
      transformResponse: (response) =>
        response.data.map((budget) => ({
          ...budget,
          summaries: response.summaries[budget.id],
        })),
    }),
    createBudgetsByProject: build.mutation({
      query: ({ projectId, ...body }) => ({
        url: `/projects/${projectId}/budgets`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, params) => (result ? [{ type: 'Budget', id: 'LIST' }] : []),
    }),
    getBudgetById: build.query({
      query: ({ budgetId }) => `/budgets/${budgetId}`,
      providesTags: (result, error, { budgetId }) => [{ type: 'Budget', id: budgetId }],
      transformResponse: (response) => {
        return {
          ...response.data,
          summaries: response.summaries,
        };
      },
    }),
    getUsersWithAccess: build.query({
      query: ({ budgetId }) => `/budgets/${budgetId}/users`,
      providesTags: (result, error, { budgetId }) => [{ type: 'BudgetUsers', id: budgetId }],
      transformResponse: (response) => {
        return response.data;
      },
    }),
    giveUserAccess: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/budgets/${id}/users`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result, error, { id }) => {
        return result ? [{ type: 'BudgetUsers', id }] : []
      },
    }),
    removeUserAccess: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/budgets/${id}/users`,
        method: 'DELETE',
        body
      }),
      invalidatesTags: (result, error, { id }) => {
        return result ? [{ type: 'BudgetUsers', id }] : []
      },
    }),
    updateBudgetById: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/budgets/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
            { type: 'Budget', id: id },
            { type: 'BudgetLine', id: 'LIST' },
            { type: 'BudgetForecast', id: id },
            { type: 'ProjectForecast' },
          ]
          : [],
    }),
  }),
});

export const {
  useGetBudgetsQuery,
  useGetBudgetByIdQuery,
  useGiveUserAccessMutation,
  useGetUsersWithAccessQuery,
  useRemoveUserAccessMutation,
  useCreateBudgetsByProjectMutation,
  useUpdateBudgetByIdMutation,
} = budgetAPI;
