import { Box, ListItem, ListItemIcon, Tooltip, useTheme } from '@mui/material';
import { NavLink } from 'react-router-dom';

const NavLinkListItem = ({ component, ...props }) => <ListItem component={NavLink} {...props} />;

export const NavListItem = ({ icon, children, title, TooltipProps, ...props }) => {
  const theme = useTheme();
  return (
    <Tooltip
      arrow
      disableInteractive
      placement="top"
      title={title}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -8],
              },
            },
          ],
        },
      }}
      {...TooltipProps}
    >
      <span>
        <Box
          button
          component={props.to ? NavLinkListItem : ListItem}
          style={{ justifyContent: 'center' }}
          sx={{
            '&.active': { color: theme.palette.primary.main },
            '&.active svg': { color: theme.palette.primary.main },
            '&.Mui-selected': { backgroundColor: theme.palette.background.paper },
            '& > .zeroMinWidth': { minWidth: '0px' },
          }}
          {...props}
        >
          {children ? (
            children
          ) : (
            <>
              <ListItemIcon className={'zeroMinWidth'}>{icon}</ListItemIcon>
              <Box fontSize={theme.typography.body1.fontSize} minHeight={'1.5em'} my={0.5} />
            </>
          )}
        </Box>
      </span>
    </Tooltip>
  );
};
