/**
 * Function to get all connected tasks and links from a given task.
 * @param {Number} taskId - The starting task id
 * @param {Object} gantt - Gantt object
 * @returns {Object} - An object containing an array of task IDs and an array of link IDs.
 */
function getConnectedGroup(taskId, gantt) {
  const connectedTaskIds = new Set();
  const connectedLinkIds = new Set();
  const visitedLinks = new Set();
  const stack = [taskId];

  while (stack.length > 0) {
    const currentTask = gantt.getTask(stack.pop());

    if (connectedTaskIds.has(currentTask.id)) continue;
    connectedTaskIds.add(currentTask.id);

    // Check the source links
    if (currentTask.$source) {
      currentTask.$source.forEach((linkId) => {
        if (!visitedLinks.has(linkId)) {
          visitedLinks.add(linkId);
          connectedLinkIds.add(linkId);
          const link = gantt.getLink(linkId);
          if (link && !connectedTaskIds.has(link.target)) {
            stack.push(link.target);
          }
        }
      });
    }

    // Check the target links
    if (currentTask.$target) {
      currentTask.$target.forEach((linkId) => {
        if (!visitedLinks.has(linkId)) {
          visitedLinks.add(linkId);
          connectedLinkIds.add(linkId);
          const link = gantt.getLink(linkId);
          if (link && !connectedTaskIds.has(link.source)) {
            stack.push(link.source);
          }
        }
      });
    }
  }

  return {
    tasks: Array.from(connectedTaskIds),
    links: Array.from(connectedLinkIds),
  };
}

module.exports = { getConnectedGroup };
