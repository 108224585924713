/**
 * Returns list of tasks children
 * @param {Number || String} taskId
 * @param {Gantt} gantt
 * @returns {[Task]}
 */
function getChildren(taskId, gantt) {
  return gantt
    .getTaskBy('parent', taskId)
    .filter((task) => !task.$deleted && task.type !== 'placeholder');
}

module.exports = { getChildren };
