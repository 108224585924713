const { calculateTaskActualDates } = require('../tasks/calculateTaskActualDates');
const { calculateTaskDurations } = require('../tasks/calculateTaskDurations');
const { calculateTaskPace } = require('../tasks/calculateTaskPace');
const { calculateTaskProductionRating } = require('../tasks/calculateTaskProductionRating');
const { calculateTaskProgress } = require('../tasks/calculateTaskProgress');
const { calculateTaskStatus } = require('../tasks/calculateTaskStatus');
const { calculateTaskType } = require('../tasks/calculateTaskType');
const { topologicalSort } = require('./topologicalSort');

function updateAllTaskProperties(projectId, shouldUpdate, gantt) {
  const updatedTaskIds = [];
  const sortedTasks = topologicalSort(gantt, projectId);
  sortedTasks.forEach((task) => {
    if (updateTaskProperties(task.id, gantt)) {
      updatedTaskIds.push(task.id);
    }
  });

  if (shouldUpdate) {
    gantt.batchUpdate(() => {
      for (let index = 0; index < updatedTaskIds.length; index++) {
        gantt.updateTask(updatedTaskIds[index]);
      }
    });
  }

  gantt.callEvent('onAfterUpdateProperties', [updatedTaskIds]);
  return updatedTaskIds;
}

function updateTaskProperties(taskId, gantt) {
  let updated = false;
  const task = gantt.getTask(taskId);
  const taskClone = { ...task };

  if (task.status !== 'complete') {
    const { calDays, workDays } = calculateTaskDurations(task, gantt);
    if (task.cal_days !== calDays || task.work_days !== workDays) {
      task.cal_days = calDays;
      task.work_days = workDays;
      updated = true;
    }
  }
  const type = calculateTaskType(taskId, gantt);
  if (task.type !== type) {
    task.type = type;
    updated = true;
  }

  const { progress, totalWork } = calculateTaskProgress(task, gantt);
  task.totalWork = totalWork;
  if (task.progress !== progress) {
    task.progress = progress;
    updated = true;
  }

  const status = calculateTaskStatus(task, gantt);
  if (task.status !== status) {
    task.status = status;
    updated = true;
  }

  const { actualStartDate, actualEndDate } = calculateTaskActualDates(task, gantt);
  if (+task.actual_start_date !== +actualStartDate || +task.actual_end_date !== +actualEndDate) {
    task.actual_start_date = actualStartDate;
    task.actual_end_date = actualEndDate;
    updated = true;
  }

  const { pr, earnedDays, earnedPlannedDays } = calculateTaskProductionRating(task, gantt);
  if (
    task.pr !== pr ||
    task.earned_days !== earnedDays ||
    task.earned_planned_days !== earnedPlannedDays
  ) {
    task.pr = pr;
    task.earned_days = earnedDays;
    task.earned_planned_days = earnedPlannedDays;
    updated = true;
  }

  const pace = calculateTaskPace(task, gantt);
  if (task.pace !== pace) {
    task.pace = pace;
    updated = true;
  }

  // const changes = diff(taskClone, task);
  // changes && console.log(task.id + ' :', changes, task);

  return updated;
}

module.exports = { updateAllTaskProperties, updateTaskProperties };

function diff(obj1, obj2) {
  const differences = {};
  Object.entries(obj1).forEach(([key, value]) => {
    if (value !== obj2[key]) {
      differences[key] = { prev: value, next: obj2[key] };
    }
  });
  return Object.keys(differences).length ? differences : false;
}
