

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { Popover } from 'components/Popover';
import { useSelector } from 'react-redux';
import Toolbar from '@mui/material/Toolbar';
import Skeleton from '@mui/material/Skeleton';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import { Table } from 'components/table/Table';
import { GroupedVirtuoso } from 'react-virtuoso';
import TextField from '@mui/material/TextField';
import { StyledDialog } from '@blackhyve/common';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SearchBox from 'components/search/SearchBox';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemText from '@mui/material/ListItemText';
import Autocomplete from '@mui/material/Autocomplete';
import ListItemIcon from '@mui/material/ListItemIcon';
import TableContainer from '@mui/material/TableContainer';
import ListItemButton from '@mui/material/ListItemButton';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetUsersWithAccessQuery, useGiveUserAccessMutation, useRemoveUserAccessMutation } from '../store/budget.api';
import { useState, forwardRef, useRef, cloneElement, useEffect } from 'react';
import { useGetProjectCompaniesQuery } from 'features/projectCompanies/api/projectCompanies.api';


/**
 * Budget users 
 * @param {number} budgetId 
 * @param {number} projectId 
 * @returns 
 */
const BudgetUsers = ({ projectId, budgetId }) => {

  const [search, setSearch] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const { data: users = [], isLoading, isFetching } = useGetUsersWithAccessQuery({ budgetId: +budgetId });
  const userIds = users ? users?.map((user) => user.id) : []

  const filteredUsers = users?.filter((user) =>
    (`${user.first_name} ${user.last_name}`).toLowerCase().includes(search.toLowerCase())
  );

  const handleClose = () => setOpenDialog(false);

  return (
    <>
      <Box pb={2} pt={2}>
        <Typography variant={'h5'}>User with access</Typography>
        <Paper elevation={2}>
          <Toolbar style={{ position: 'sticky', left: '0px' }}>
            <Grid container item>
              <SearchBox
                bgcolor={'grey.200'}
                borderRadius={1}
                fullWidth={false}
                placeholder='Search by name'
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
              <Box ml={'auto'} />
              <Button size="small" variant="text" onClick={() => setOpenDialog(true)}>
                Add New User
              </Button>
              {openDialog &&
                <AddUserDialog
                  budgetId={budgetId}
                  handleClose={handleClose}
                  open={openDialog}
                  projectId={projectId}
                  selectedUserIds={userIds}
                />}
            </Grid>
          </Toolbar>

          <TableContainer sx={{ maxHeight: 400 }}>
            <Table
              columns={userColumns}
              defaultOrderBy={false}
              isFetching={isFetching}
              isLoading={isLoading}
              meta={{ projectId, budgetId }}
              rows={filteredUsers}
              size="small"
              stickyHeader={true}
            />
          </TableContainer>
        </Paper>

      </Box>

    </>
  );
};

const userColumns = [
  {
    field: 'profile_image',
    skeleton: <Skeleton height={20} variant="circular" width={20} />,
    render: (user) => (
      <Avatar src={user?.profile_image?.thumb_url} sx={{ width: 30, height: 30, fontSize: 15 }}>
        {user?.first_name[0]}
        {user?.last_name[0]}
      </Avatar>
    ),
    cellProps: { style: { width: '40px' } },
  },
  { label: 'First Name', field: 'name', render: (value) => value?.first_name, sort: true },
  { label: 'Last Name', field: 'name', render: (value) => value?.last_name, sort: true },
  { label: 'Company', field: 'company_id', render: (value) => value?.company?.name, sort: true },
  { label: 'Email', field: 'email', render: (value) => value?.email, sort: true },
  {
    field: 'moreOptions',
    cellProps: { align: 'right' },
    render: (user, meta) => {
      return <RemoveUserButton budgetId={meta?.budgetId} userId={user.id} />
    }
  }
];

export default BudgetUsers;


const RemoveUserButton = ({ budgetId, userId }) => {

  const [removeUser, { isLoading }] = useRemoveUserAccessMutation();
  const currentUserId = useSelector((state) => state.auth.user.id);

  const isDisabled = currentUserId === userId;

  const handleRemove = () => {
    removeUser({ id: budgetId, users: [userId] });
  };

  return (
    <ConfirmMenu
      trigger={
        <Button disabled={isLoading || isDisabled} size={'small'}>
          Remove Access
          {isLoading && (
            <Box display={'flex'} position={'absolute'}>
              <CircularProgress size={'1.5rem'} />
            </Box>
          )}
        </Button>
      }
      onConfirm={handleRemove}
    />
  );
};

const ConfirmMenu = ({ trigger, onConfirm }) => {
  return (
    <Popover
      trigger={trigger}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      {({ handleClose }) => (
        <>
          <Button onClick={handleClose}>Cancel</Button>
          <Box
            color={'error.main'}
            component={Button}
            onClick={() => {
              onConfirm();
              handleClose();
            }}
          >
            Remove
          </Box>
        </>
      )}
    </Popover>
  );
};


/**
 * Add user dialog
 * @param {Boolean} open
 * @param {Number} projectId
 * @param {Number} budgetId
 * @param {Array} selectedUsers  
 * @param {Object} handleClose
 * @returns 
 */
const AddUserDialog = ({ handleClose, open, projectId, budgetId, selectedUserIds }) => {

  const [selected, setSelected] = useState([]);
  const { data: companies = [], isLoading: isLoadingCompanies } = useGetProjectCompaniesQuery({ projectId });
  const [addUsers, { isLoading: isAddingUser }] = useGiveUserAccessMutation();

  const contacts = companies
    .flatMap(company => company.users)
    .filter((user) => !selectedUserIds.includes(user.id));

  const companyEntities = Object.fromEntries(
    companies?.map(company => [company.id, company]) || []
  );

  const handleRemove = (id) => {
    setSelected((prevSelected) => {
      return prevSelected.filter((user) => user.id !== id);
    })
  }

  const handleAdd = () => {
    addUsers({ projectId, users: selected.map((user) => user.id), id: budgetId })
      .unwrap()
      .then(() => handleClose())
      .catch((error) => console.log(error));
  }


  return (
    <StyledDialog
      dialogContentStyle={{ display: 'flex', flexDirection: 'column' }}
      handleClose={handleClose}
      open={open}
      title={'Add Users'}
      actions={
        <>
          <Button disabled={isAddingUser} onClick={handleClose}>
            Close
          </Button>
          <Button
            disabled={isAddingUser || selected.length < 1}
            endIcon={isAddingUser && <CircularProgress size={'1em'} />}
            variant={'contained'}
            onClick={handleAdd}
          >
            Add
          </Button>
        </>
      }
    >
      <Autocomplete
        disableClearable
        disableCloseOnSelect
        fullWidth
        multiple
        autoFocus={false}
        getOptionDisabled={(option) => (option.deleted_at ? true : false)}
        getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
        groupBy={(option) => companyEntities[option.company_id].name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        limitTags={0}
        ListboxComponent={GroupByCompanyListBoxComponent}
        loading={isLoadingCompanies}
        options={contacts}
        renderTags={() => null}
        size="small"
        value={selected}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder="Search"
            size="small"
            variant="outlined"
          />
        )}
        renderOption={(props, option, { selected }) => {
          return (
            <ListItemButton {...props}>
              <ListItemIcon>
                <Checkbox checked={selected} />
              </ListItemIcon>
              <ListItemText style={option.deleted_at ? { textDecoration: 'line-through' } : {}}>
                {option?.first_name} {option?.last_name}
              </ListItemText>
            </ListItemButton>
          );
        }}
        onChange={(event, newValue) => {
          if (event.key !== 'Backspace') {
            setSelected(newValue);
          }
        }}
      />
      <Box m={0} overflow={'auto'} p={0} width={'100%'}>
        <List>
          {selected?.map((user) => {
            return (
              <ListItem
                disablePadding
                key={user?.id}
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={() => handleRemove(user?.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemButton>
                  <ListItemIcon sx={{ mr: 1 }}>
                    <Avatar src={user?.profile_image?.thumb_url}>
                      {user?.first_name?.[0]}
                      {user?.last_name?.[0]}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText primary={`${user?.first_name} ${user?.last_name}`} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </StyledDialog>
  );

}

const GroupByCompanyListBoxComponent = forwardRef((props, ref) => {
  const { children, role, ...other } = props;
  const localRef = useRef(250);

  const itemData = [];
  const groupCount = [];
  children?.forEach((item) => {
    if (item.props?.children) {
      if (item.props.children[1]?.props?.children?.length) {
        itemData.push(...item.props.children[1].props.children);
      }
      groupCount.push(
        item.props?.children[1]?.props?.children?.length
          ? item.props?.children[1]?.props?.children?.length
          : 0
      );
    }
  });

  return (
    <ul
      role={role}
      ref={(reference) => {
        const maxHeight = reference ? getComputedStyle(reference).maxHeight : null;
        if (maxHeight && maxHeight !== localRef.current) {
          localRef.current = maxHeight;
        }
        if (typeof ref === 'function') {
          ref(reference);
        }
      }}
      {...other}
    >
      <GroupedVirtuoso
        groupCounts={groupCount}
        style={{ height: localRef.current }}
        groupContent={(index) => {
          if (children[index]?.props?.children[0]) {
            return cloneElement(children[index]?.props?.children[0], { index });
          }
        }}
        itemContent={(index, groupIndex) => {
          if (itemData[index]) {
            return cloneElement(itemData[index], {
              index,
            });
          }
        }}
      />
    </ul>
  );
});