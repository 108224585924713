import * as React from "react";

const SvgSqftIcon = (props) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.99641 0.146447C2.80115 -0.0488155 2.48457 -0.0488155 2.2893 0.146447L0.146447 2.2893C-0.0488155 2.48457 -0.0488155 2.80115 0.146447 2.99641C0.341709 3.19167 0.658291 3.19167 0.853553 2.99641L2.14286 1.70711V11.9286V12.4286H2.64286H12.8643L11.575 13.7179C11.3798 13.9131 11.3798 14.2297 11.575 14.425C11.7703 14.6203 12.0869 14.6203 12.2821 14.425L14.425 12.2821C14.6202 12.0869 14.6202 11.7703 14.425 11.575L12.2821 9.43217C12.0869 9.23691 11.7703 9.23691 11.575 9.43217C11.5028 9.50441 11.4573 9.59326 11.4385 9.68642C11.4065 9.84508 11.452 10.0163 11.575 10.1393L12.8643 11.4286H3.14286V1.70711L4.43216 2.99641C4.60715 3.1714 4.87957 3.18957 5.07479 3.05092C5.09741 3.03485 5.11899 3.01668 5.13927 2.99641C5.33453 2.80115 5.33453 2.48457 5.13927 2.2893L2.99641 0.146447Z"
      fill={props.color ? props.color : '#B3B3B3'}
    />
  </svg>
);

export default SvgSqftIcon;
