import { useLocation, useParams } from 'react-router-dom';
import { useGetBudgetByIdQuery } from '../store/budget.api';
import { ContentLayout } from 'components/layouts';
import { Box, Container, Typography } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { useGetBudgetForecastQuery } from '../store/forecast.api';
import { useGetProjectQuery } from 'features/projects/store/project.api';
import Forecast from '../components/Forecast';
import { useState } from 'react';

const ForecastBudgetShow = () => {
  const { workspaceId, projectId, budgetId } = useParams();
  const location = useLocation();

  const { budgetLines } = { budgetLines: location.state?.budgetLines || [] };

  const { data: project } = useGetProjectQuery(+projectId);
  const { data: budget } = useGetBudgetByIdQuery({ budgetId: +budgetId });

  const [filters, setFilters] = useState({
    budgetLines: budgetLines,
    contracts: [],
  });

  const { data: forecast, isLoading: isLoadingForecast } = useGetBudgetForecastQuery({
    budgetId: +budgetId,
    budgetLines: filters.budgetLines,
    contracts: filters.contracts,
  });

  if (!project || !budget || isLoadingForecast) {
    return <>Loading...</>;
  }

  return (
    <ContentLayout>
      <Container maxWidth={'xl'}>
        <Box pt={2}>
          <Typography variant={'h4'}>
            <Breadcrumbs
              links={[
                {
                  to: `/workspace/${workspaceId}/projects/${projectId}/budgets`,
                  text: 'Budgets',
                },
                {
                  to: `/workspace/${workspaceId}/projects/${projectId}/budgets/${budgetId}`,
                  text: budget.name,
                },
                { text: 'Forecast' },
              ]}
            />
          </Typography>
        </Box>

        <Box pt={6}>
          <Forecast
            forecast={forecast}
            forecastFilters={filters}
            onForecastFilterChanged={(state) => {
              setFilters(state)
            }}
            project={project} />
        </Box>
      </Container>
    </ContentLayout>
  );
};

export default ForecastBudgetShow;
