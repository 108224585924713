import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { forwardRef } from 'react';
import { useGetTradesQuery } from '../api/trades.api';

export const TradesAutocomplete = forwardRef(
  ({ TextFieldProps, multiple = false, ...props }, ref) => {
    const {
      data: { entities: tradeEntities = {}, ids: tradeIds = [] } = {},
      isLoading: isLoadingTrades,
    } = useGetTradesQuery();

    return (
      <Autocomplete
        autoHighlight
        disableCloseOnSelect
        getOptionLabel={(option) => tradeEntities?.[option]?.name || 'Unknown'}
        limitTags={multiple ? 3 : -1}
        loading={isLoadingTrades}
        multiple={multiple}
        options={tradeIds}
        ref={ref}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            id="contact-trade-input"
            placeholder="Trade"
            variant="outlined"
            {...TextFieldProps}
          />
        )}
        renderOption={({ key, ...props }, option, { selected }) => (
          <li key={key} {...props}>
            {multiple && <Checkbox checked={selected} edge={'start'} />}
            {tradeEntities[option]?.name}
          </li>
        )}
        {...props}
      />
    );
  }
);
