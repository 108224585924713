import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Skeleton,
} from '@mui/material';
import times from 'lodash/times';

/**
 * A select list component that allows users to select items.
 * @param {[]} options - The list of options to display.
 * @param {Function} onSelect - Callback function to handle selection of an option.
 * @param {Function} getOptionLabel - Function to extract the display label from an option.
 * @param {Function} [groupBy] - Optional function to group options based on a specific criteria.
 * @param {Function} [filterBy] - Optional function to filter options based on a specific criteria.
 * @param {Boolean} [multiple] - Optional boolean to change to multi select
 * @param {Function} [getOptionSelected] - Optional function to get the selected value based on an option.
 * @param {Boolean} isLoading - Optional flag to show loading of the list
 * @returns {JSX.Element} The rendered SelectList component.
 */
export const SelectList = ({
  options,
  onSelect,
  isLoading,
  getOptionLabel,
  groupBy,
  filterBy,
  multiple,
  getOptionSelected,
  allowNone,
  ...props
}) => {

  if (isLoading) {
    return <Box bgcolor={'background.paper'} component={List} {...props}>
      {times(5, (row) => {
        return <ListItemButton key={row}>
          <Skeleton className="skeleton-row" variant="text" />
        </ListItemButton>
      })}
    </Box>
  }

  const filteredOptions = filterBy ? options.filter((option) => filterBy(option)) : options;
  if (filteredOptions && filteredOptions?.length > 0) {
    return (
      <Box bgcolor={'background.paper'} component={List} {...props}>
        {groupBy
          ? Object.entries(
            filteredOptions.reduce((grouped, option) => {
              const group = groupBy(option);
              grouped[group] = [...(grouped[group] || []), option];
              return grouped;
            }, {})
          ).map(([group, options]) => (
            <Box bgcolor={'inherit'} component={'li'} key={group}>
              <Box
                disablePadding
                bgcolor={'inherit'}
                component={List}
                dense={props?.dense || false}
              >
                <ListSubheader color={'inherit'}>{group}</ListSubheader>
                {options.map((option) => (
                  <ListItemButton
                    key={getOptionLabel(option)}
                    selected={!multiple && getOptionSelected ? getOptionSelected(option) : false}
                    sx={{ pl: 4 }}
                    onClick={() => onSelect(option)}
                  >
                    {multiple && (
                      <ListItemIcon>
                        <Box
                          disableRipple
                          checked={getOptionSelected(option) || false}
                          component={Checkbox}
                          p={0}
                          tabIndex={-1}
                        />
                      </ListItemIcon>
                    )}
                    <ListItemText primary={getOptionLabel ? getOptionLabel(option) : option} />
                  </ListItemButton>
                ))}
              </Box>
            </Box>
          ))
          : filteredOptions.map((option) => (
            <ListItemButton
              key={getOptionLabel(option)}
              selected={!multiple && getOptionSelected ? getOptionSelected(option) : false}
              onClick={() => onSelect(option)}
            >
              {multiple && (
                <ListItemIcon>
                  <Box
                    disableRipple
                    checked={getOptionSelected(option) || false}
                    component={Checkbox}
                    p={0}
                    tabIndex={-1}
                  />
                </ListItemIcon>
              )}
              <ListItemText primary={getOptionLabel ? getOptionLabel(option) : option} />
            </ListItemButton>
          ))}
      </Box>
    );
  } else {
    return (
      <Box color={'#c4c4c4'} p={1}>
        No Options
      </Box>
    );
  }
};
