import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import EmailIcon from '@mui/icons-material/Email';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InputAdornment from '@mui/material/InputAdornment';
import {
  StyledErrorAlert, StyledLink, StyledTextField,
  StyledLoginButton, StyledButtonLoading, StyledArrowIcon
} from '../assets/loginStyles';
import { clearAuthErrors, sendResetPasswordLink } from '../store/authSlice';
import { useNavigate } from 'react-router-dom';

/**
 * Forgot password form
 * @returns 
 */
const ForgotPasswordForm = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.loading);
  const resetEmailSent = useSelector((state) => state.auth.resetEmailSent);

  const { control, handleSubmit, watch } = useForm({
    defaultValues: { email: "" }
  });

  const resetEmail = watch("email")

  const handleResetLogin = (data) => {
    dispatch(sendResetPasswordLink(data.email));
  };

  useEffect(() => {
    return () => dispatch(clearAuthErrors());
  }, [dispatch])


  return (
    <Grid container item style={{ marginTop: 25, gap: 20 }} xs={12}>
      {error && <Grid item xs={12}>
        <StyledErrorAlert fullWidth severity="error">
          {error}
        </StyledErrorAlert>
      </Grid>}
      {resetEmailSent === true ? (
        <>
          <Grid item xs={12}>
            <Typography style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>Email Sent</Typography>
            <Typography>Please check your email <b>{resetEmail}</b> for a password reset link.</Typography>
          </Grid>
        </>
      ) : (
        <form onSubmit={handleSubmit(handleResetLogin)}>
          <Grid container style={{ gap: 20 }}>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: 600, marginTop: '5px', fontSize: '18px' }}>
                Let's get this sorted.
              </Typography>
              <Typography style={{ fontSize: '14.5px' }}>
                Submit the email address associated with your account below and we'll send you password reset instructions.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState: { error } }) =>
                  <StyledTextField
                    {...field}
                    autoFocus
                    fullWidth
                    error={error}
                    helperText={error?.message}
                    placeholder="Email Address"
                    size="small"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end" size="large">
                            <EmailIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                }
                rules={{
                  required: 'Email field is required',
                  validate: {
                    matchPattern: (v) =>
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) ||
                      "Email address must be a valid address",
                  },
                }}
              />
            </Grid>
            <Grid item style={{ position: 'relative' }} xs={12}>
              <StyledLoginButton
                disabled={loading}
                fullWidth={true}
                size="medium"
                type="submit"
                variant="contained"
              >
                EMAIL ME INSTRUCTIONS
              </StyledLoginButton>
              {loading && <StyledButtonLoading size={24} />}
            </Grid>
            <Grid item xs={12}>
              <Divider color="#fff" orientation='horizontal' style={{ backgroundColor: '#fff' }} />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontSize: '14px', marginBottom: '2px', textAlign: 'center' }}>Want help from a real person?</Typography>
              <StyledLoginButton fullWidth variant="contained" onClick={() => navigate(`/help`)}>
                CONTACT SUPPORT
              </StyledLoginButton>
            </Grid>
          </Grid>
        </form>
      )}
      <Grid item style={{ justifyContent: 'flex-end', display: 'flex' }} xs={12}>
        <StyledLink to="/login">
          <StyledArrowIcon /> Back to Login
        </StyledLink>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordForm;
