import { formatDecimals, formatMoney, unformatMoney } from '../components/NumericControls';

export const COMBINED = 'combined';
export const LABOR_COST_TYPE = 'labor';
export const MATERIAL_COST_TYPE = 'material';

export const CostTypeOptions = [
  { id: COMBINED, name: 'Combined' },
  { id: LABOR_COST_TYPE, name: 'Labor' },
  { id: MATERIAL_COST_TYPE, name: 'Material' },
];

export const breakdownChanges = (state, changedField) => {
  let [amount, labor_amount, material_amount] = [
    unformatMoney(state.amount),
    unformatMoney(state.labor_amount),
    unformatMoney(state.material_amount),
  ];

  if (changedField === 'amount') {
    return [
      { field: 'labor_amount', value: formatMoney(amount * (state.labor_percent / 100), false) },
      { field: 'material_amount', value: formatMoney(amount * (state.material_percent / 100), false) },
    ];
  }

  if (changedField === 'labor_percent') {
    let newMaterialPercent = Math.max(100 - state.labor_percent * 1, 0);

    return [
      { field: 'labor_amount', value: formatMoney(amount * (state.labor_percent / 100), false) },
      { field: 'material_percent', value: formatDecimals(newMaterialPercent) },
      { field: 'material_amount', value: formatMoney(amount * (newMaterialPercent / 100), false) },
    ];
  }

  if (changedField === 'material_percent') {
    let newLaborPercent = Math.max(100 - state.material_percent * 1, 0);

    return [
      { field: 'material_amount', value: formatMoney(amount * (state.material_percent / 100), false) },
      { field: 'labor_percent', value: formatDecimals(newLaborPercent) },
      { field: 'labor_amount', value: formatMoney(amount * (newLaborPercent / 100), false) },
    ];
  }

  if (changedField === 'labor_amount') {
    let newMaterialAmount = amount - labor_amount;

    return [
      { field: 'labor_percent', value: formatDecimals((labor_amount / Math.max(amount, 1)) * 100) },
      { field: 'material_amount', value: formatMoney(newMaterialAmount, false) },
      {
        field: 'material_percent',
        value: formatDecimals((newMaterialAmount / Math.max(amount, 1)) * 100),
      },
    ];
  }

  if (changedField === 'material_amount') {
    let newLaborAmount = amount - material_amount;

    return [
      {
        field: 'material_percent',
        value: formatDecimals((material_amount / Math.max(amount, 1)) * 100),
      },
      { field: 'labor_amount', value: formatMoney(newLaborAmount, false) },
      {
        field: 'labor_percent',
        value: formatDecimals((newLaborAmount / Math.max(amount, 1)) * 100),
      },
    ];
  }

  return [];
};
