import { CloudUpload } from '@mui/icons-material';
import { Avatar, Box, Button, CircularProgress, Grid, styled } from '@mui/material';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export function InputFileUpload({ ButtonProps, inputProps, label = 'Upload file' }) {
  return (
    <Button component="label" startIcon={<CloudUpload />} variant="contained" {...ButtonProps}>
      {label}
      <VisuallyHiddenInput type="file" {...inputProps} />
    </Button>
  );
}

/**
 *
 * @param {String} image
 * @param {Boolean} isImageBlob
 * @param {Number} imageHeight
 * @param {Number} imageWidth
 * @param {Object} handleUpload
 * @param {Number} placeHolderImageHeight
 * @returns
 */

const ImageUploadComponent = (props) => {
  const {
    image,
    isImageBlob,
    handleUpload,
    imageHeight = 'auto',
    imageWidth = '100%',
    isUploading,
  } = props;

  const handleChange = (event) => {
    if (event.target.files) {
      handleUpload(event.target.files[0]);
    }
    event.target.value = '';
  };

  return (
    <Box alignItems={'center'} display={'flex'} flexDirection={'column'}>
      <Avatar
        alt={'Profile'}
        src={image && isImageBlob ? URL.createObjectURL(image) : image}
        sx={{ width: imageWidth, height: imageHeight, aspectRatio: 1 }}
      ></Avatar>
      <InputFileUpload
        label={'Upload Image'}
        ButtonProps={{
          size: 'small',
          sx: { mt: 1 },
          endIcon: isUploading && <CircularProgress color={'inherit'} size={'1em'} />,
        }}
        inputProps={{
          accept: 'image/x-png,image/gif,image/jpeg',
          onChange: handleChange,
        }}
      ></InputFileUpload>
    </Box>
  );
};

export default ImageUploadComponent;
