import { LoginLayoutComponent } from '@blackhyve/common';
import { RequireNoAuth } from 'features/auth/components/RequireNoAuth';
import { authRoutes } from 'features/auth/routes';
import { Outlet } from 'react-router-dom';

export const publicRoutes = [
  {
    path: '',
    element: (
      <RequireNoAuth>
        <LoginLayoutComponent>
          <Outlet />
        </LoginLayoutComponent>
      </RequireNoAuth>
    ),
    children: authRoutes,
  },
];
