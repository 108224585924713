import axios from 'axios';
import { Mutex } from 'async-mutex';
import { forceLogout, tokenReceived } from 'features/auth/store/extraActions';
import apiErrorHandler from './apiErrorHandler';

let store;

export const injectStore = (_store) => {
  store = _store;
};

const SERVER_URL = `${process.env.REACT_APP_SERVER_URL}/api/`;
const mutex = new Mutex();
let cancelToken = axios.CancelToken;
let source = cancelToken.source();
let additionalHeaders = {};

const defaults = { timeout: 20000 }

const getHeaders = (isFileUpload) => {
  let headers = {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    ...additionalHeaders,
  };

  if (isFileUpload) {
    headers['Content-Type'] = isFileUpload ? 'multipart/form-data;' : 'application/json';
  }

  const workspaceId = store?.getState()?.auth?.workspaceId;
  if (workspaceId) {
    headers['X-Tenant'] = workspaceId;
  }

  const authToken = store?.getState()?.auth?.authToken;
  if (authToken && authToken !== 'undefined') {
    headers['Authorization'] = `Bearer ${authToken}`;
  }

  const socketId = store?.getState()?.auth?.socketId;
  if (socketId) {
    headers['X-Socket-ID'] = socketId;
  }

  return headers;
};

const makeRequest = async (requestObj) => {
  requestObj.headers['X-Socket-ID'] = store.getState().auth.socketId;
  return axios({
    ...requestObj,
    defaults,
    cancelToken: source.token,
  });
};

const refreshAuthToken = async () => {
  const refreshResult = await axios({
    url: `${SERVER_URL}refresh`,
    defaults,
    data: { refresh_token: localStorage.getItem('refreshToken') },
    headers: getHeaders(false, true),
    method: "POST",
    responseType: 'json',
  });
  return refreshResult?.data;
};

// Refresh auth token and continue request
const handleUnauthorizedError = async (requestObj) => {
  if (!mutex.isLocked()) {
    const release = await mutex.acquire();
    try {
      const refreshTokenData = await refreshAuthToken();
      if (refreshTokenData) {
        store.dispatch(tokenReceived(refreshTokenData));
        requestObj.headers['Authorization'] = `Bearer ${refreshTokenData.access_token}`;
        const result = await makeRequest(requestObj);
        return result ? { error: null, response: result.data } : null;
      } else {
        store.dispatch(forceLogout());
        return null;
      }
    } catch (refreshError) {
      console.error('error while retrying request =>', refreshError);
      store.dispatch(forceLogout());
      return null;
    } finally {
      release();
    }
  } else {
    await mutex.waitForUnlock();
    const authToken = store.getState().auth.authToken;
    requestObj.headers['Authorization'] = `Bearer ${authToken}`
    const result = await makeRequest(requestObj);
    return result ? { error: null, response: result.data } : null;
  }
};


// Axios Request Handler
export const axiosRequestHandler = async (
  pathName,
  method,
  body,
  responseType = 'json',
  isFileUpload = false,
) => {

  const requestObject = {
    url: `${SERVER_URL}${pathName}`,
    method,
    headers: getHeaders(isFileUpload),
    data: body,
    responseType
  }
  try {
    await mutex.waitForUnlock();
    const result = await makeRequest(requestObject);
    return result ? { error: null, response: result.data } : null;

  } catch (error) {
    console.error('<- ERROR WHILE MAKING FIRST API CALL ->')
    // If error type is unauthorized
    if (error?.response && error.response.status === 401) {
      return await handleUnauthorizedError(requestObject);
    } else {
      // If different error
      console.error('error in else =>', error)
      const apiError = apiErrorHandler(error);
      return apiError;
    }
  }
};





