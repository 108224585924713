import { OptionsMenu } from '@blackhyve/common';
import { Delete, Edit, MoreVert } from '@mui/icons-material';
import { IconButton, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

export const MenuOptions = ({ entityObj, onEdit, onDelete }) => {
  return (
    <>
      <OptionsMenu
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        triggerButton={
          <IconButton size="small">
            <MoreVert />
          </IconButton>
        }
      >
        {(handleClose) => {
          return (
            <>
              <ListItemButton
                dense
                onClick={() => {
                  handleClose();
                  onEdit(entityObj);
                }}
              >
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </ListItemButton>
              <ListItemButton
                dense
                onClick={() => {
                  handleClose();
                  onDelete(entityObj?.id);
                }}
              >
                <ListItemIcon style={{ color: 'red' }}>
                  <Delete color={'red'} />
                </ListItemIcon>
                <ListItemText style={{ color: 'red' }}>Delete</ListItemText>
              </ListItemButton>
            </>
          );
        }}
      </OptionsMenu>
    </>
  );
};
