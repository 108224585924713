export function autoUpdateLag(gantt) {
  let shiftPressed = false;

  function onKey(event) {
    if (event.key === 'Shift' && event.type === 'keydown') {
      shiftPressed = true;
    } else if (event.key === 'Shift' && event.type === 'keyup') {
      shiftPressed = false;
    }
  }

  window.addEventListener('keydown', onKey, { passive: true });
  window.addEventListener('keyup', onKey, { passive: true });

  gantt.attachEvent('onDestroy', function () {
    window.removeEventListener('keydown', onKey);
    window.removeEventListener('keyup', onKey);
  });

  gantt.attachEvent('onBeforeLinkAdd', (id, dependency) => {
    if (shiftPressed) {
      const sourceTask = gantt.getTask(dependency.source);
      const targetTask = gantt.getTask(dependency.target);

      let start_date, end_date;

      // Change start and end dates depending on the dependency type
      switch (dependency.type) {
        case gantt.config.links.finish_to_start:
          start_date = sourceTask.end_date;
          end_date = targetTask.start_date;
          break;
        case gantt.config.links.finish_to_finish:
          start_date = sourceTask.end_date;
          end_date = targetTask.end_date;
          break;
        case gantt.config.links.start_to_start:
          start_date = sourceTask.start_date;
          end_date = targetTask.start_date;
          break;
        case gantt.config.links.start_to_finish:
          start_date = sourceTask.start_date;
          end_date = targetTask.end_date;
          break;
        default:
          // Default to finish to start
          start_date = sourceTask.end_date;
          end_date = targetTask.start_date;
          break;
      }

      // Get the distance moved
      const daysMoved = gantt.calculateDuration({
        start_date: start_date,
        end_date: end_date,
        task: targetTask,
      });
      dependency.lag = daysMoved;
    }
    return true;
  });

  gantt.attachEvent('onBeforeTaskDrag', function (id) {
    gantt.config.auto_scheduling = false;
    return true;
  });

  gantt.attachEvent('onBeforeTaskChanged', function (id, mode, task) {
    if (!shiftPressed && mode === gantt.config.drag_mode.move && task.$target.length > 0) {
      return false;
    }
    return true;
  });

  gantt.attachEvent('onAfterTaskDrag', function (id, mode) {
    const task = gantt.getTask(id);
    if (shiftPressed) {
      updateLagForDependency([...task.$source, ...task.$target], true);
    }
    gantt._block_auto_schedule = true;
    gantt.config.auto_scheduling = true;

    gantt.attachEvent(
      'onBeforeAutoSchedule',
      function (taskId) {
        if (gantt._block_auto_schedule === true) {
          gantt.getTask(id).constraint_type = 'asap';
          gantt.getTask(id).constraint_date = null;
          gantt._block_auto_schedule = false;
          gantt.autoSchedule(id);
          return false;
        } else {
          return true;
        }
      },
      { once: true }
    );

    return true;
  });

  function updateLagForDependency(dependencyIds, update = false) {
    gantt.batchUpdate(() => {
      dependencyIds.forEach((dependencyId) => {
        const dependency = gantt.getLink(dependencyId);
        const sourceTask = gantt.getTask(dependency.source);
        const targetTask = gantt.getTask(dependency.target);

        let start_date, end_date;

        // Change start and end dates depending on the dependency type
        switch (dependency.type) {
          case gantt.config.links.finish_to_start:
            start_date = sourceTask.end_date;
            end_date = targetTask.start_date;
            break;
          case gantt.config.links.finish_to_finish:
            start_date = sourceTask.end_date;
            end_date = targetTask.end_date;
            break;
          case gantt.config.links.start_to_start:
            start_date = sourceTask.start_date;
            end_date = targetTask.start_date;
            break;
          case gantt.config.links.start_to_finish:
            start_date = sourceTask.start_date;
            end_date = targetTask.end_date;
            break;
          default:
            // Default to finish to start
            start_date = sourceTask.end_date;
            end_date = targetTask.start_date;
            break;
        }

        // Get the distance moved
        const daysMoved = gantt.calculateDuration({
          start_date: start_date,
          end_date: end_date,
          task: targetTask,
        });
        if (gantt?.ext?.undo) {
          gantt.ext.undo.saveState(dependencyId, 'link');
        }
        gantt.getLink(dependencyId).lag = daysMoved;
        update ? gantt.updateLink(dependencyId) : gantt.refreshLink(dependencyId);
      });
    });
  }
}
