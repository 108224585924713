import { useState, Fragment, memo, useCallback, useRef, useEffect } from 'react';
import clsx from 'clsx';
import times from 'lodash/times';
import isEqual from 'lodash/isEqual';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Skeleton from '@mui/material/Skeleton';
import MoreOptionDialog from './MoreOptionDialog';
import IconButton from '@mui/material/IconButton';
import InlineEditCardTitle from './InlineEditCardTitle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import makeStyles from '@mui/styles/makeStyles';
import NoDataAvailableComponent from './NoDataAvailableComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
  },
  row: {
    padding: '8px',
  },
  button: {
    '&:hover': {
      backgroundColor: '#1EB980',
      boxShadow: 'none',
    },
    fontSize: '10px',
    padding: '4px 6px',
    boxShadow: 'none',
    borderRadius: '1px',
    height: '24px',
    marginTop: '4px',
    textTransform: 'capitalize',
    backgroundColor: '#1EB980',
    color: 'white',
    marginLeft: '5px',
  },
  greenButton: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#1eb980',
      boxShadow: 'none',
    },
    border: '#1eb980',
    boxShadow: 'none',
    borderRadius: '0px',
    textTransform: 'capitalize',
    backgroundColor: '#1eb980',
    color: 'white',
    height: '20px',
    fontSize: '14px',
    fontWeight: '700',
  },
  horizontalLine: {
    color: '#b7b6b6',
    paddingTop: '4px',
    paddingRight: '10px',
    fontWeight: 'lighter',
  },
  labelButton: {
    margin: 'auto',
    color: 'rgba(0, 0, 0, 0.6)',
    cursor: 'pointer',
  },
  phasesIconLabel: {
    margin: 'auto',
    color: 'rgba(0, 0, 0, 0.6)',
    cursor: 'pointer',
  },
  justifyCenter: {
    margin: 'auto',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogPaper: {
    borderRadius: '5px',
  },
  gridBorder: {
    alignItems: 'center',
    borderLeft: '1px solid #e7e4e4',
    borderRight: '1px solid #e7e4e4',
    borderBottom: '1px solid #e7e4e4',
    backgroundColor: 'white',
  },
  headingText: {
    textTransform: 'uppercase',
    textAlign: 'center',
    margin: 'auto',
    fontSize: '12px',
    color: 'grey',
  },
  valueText: {
    color: 'black',
    textAlign: 'center',
    fontSize: '13px',
  },
  projectValueText: {
    fontSize: '9px',
    margin: '1px',
    border: '1px solid #B3B3B3',
    borderRadius: '2px',
  },
  gridHeading: {
    justifyContent: 'center',
    borderBottom: '1px solid #e7e4e4',
    padding: '10px 8px 0 8px',
  },
  mainGridContainer: {
    padding: '30px',
    backgroundColor: '#FAFBFC',
  },
  companyButton: {
    '&:hover': {
      background: 'none',
      boxShadow: 'none',
    },
    background: 'none',
    fontSize: '10px',
    boxShadow: 'none',
    color: '#8E8E90',
  },
  textField: {
    [`&.MuiInputBase-input`]: {
      font: 'revert',
      borderRadius: '2px',
    },
    [`& focus`]: {
      outlined: 'none',
    },
    [`& fieldset`]: {
      borderRadius: '2px',
    },
  },
  taskValues: {
    [`&.MuiOutlinedInput-adornedStart`]: {
      paddingLeft: '0px',
    },
    fontSize: '12px',
    height: '31px',
  },
  calenderStyle: {
    padding: '1px',
  },
  skeletonRow: {
    width: '98%',
    height: '41px !important',
    margin: 'auto',
    borderRadius: '0px !important',
  },
}));

const RowComponent = memo(
  (props) => {
    const { row, rowItems, isMenu, optionMenu, handleDetails, index } = props;
    const classes = useStyles();
    const inLineEditRequestParameters = {
      id: row.id,
      isEmpty: row.isEmpty,
      index,
    };
    const menuActionRequestParameters = {
      ...row,
      index,
      isEmpty: row.isEmpty,
    };
    const [openMoreOptionDialog, setOpenMoreOptionDialog] = useState(null);

    let isBeingEdited = false;

    const handleBeingEdited = () => {
      isBeingEdited = !isBeingEdited;
    };

    const handleOpenMoreOptionDialog = useCallback((event) => {
      setOpenMoreOptionDialog(event.target);
    }, []);

    const handleCloseMoreOptionDialog = useCallback(() => {
      setOpenMoreOptionDialog(null);
    }, []);

    return (
      <Grid
        container
        item
        className={clsx(classes.gridBorder, classes.row, 'my-panel')}
        id={row.id}
        style={row.isBeingloaded || row.loading ? { padding: '3px' } : {}}
        onClick={(event) => (!isBeingEdited ? handleDetails && handleDetails(event, row.id) : null)}
      >
        {row.isBeingloaded || row.loading ? (
          <Skeleton className={classes.skeletonRow} variant="text" />
        ) : (
          rowItems.map(
            ({
              id,
              name,
              width,
              fieldName,
              icon,
              style,
              endIcon,
              handleUpdate,
              isEditable,
              isBlankAllowed,
              className,
              defaultValue,
              handleRowClick,
              iconFieldName,
              textToDisplayTransform,
              isSelect,
              selectOptions,
              checkCanEdit,
              isToggle,
            }) => {
              const IconComponent = icon;
              return (
                <Grid
                  item
                  className={className}
                  id={id && id}
                  key={`${fieldName}-${row.id}`}
                  style={style}
                  xs={width}
                  onClick={(event) => {
                    handleRowClick && handleRowClick(event, row);
                  }}
                >
                  {iconFieldName ? (
                    <IconComponent
                      defaultInitials={row[iconFieldName]}
                      id={row.id}
                      name={row[fieldName]}
                    />
                  ) : (
                    icon && icon
                  )}
                  {isToggle ? (
                    <Switch
                      checked={row[fieldName] ? true : false}
                      disabled={!isEditable}
                      onChange={(event) => handleUpdate(event, row)}
                    />
                  ) : (
                    <InlineEditCardTitle
                      checkCanEdit={checkCanEdit}
                      defaultValue={defaultValue}
                      fieldName={fieldName}
                      handleBeingEdited={handleBeingEdited}
                      handleUpdate={handleUpdate}
                      id={row[fieldName] == 'start_condition' && row.id}
                      isBlankAllowed={isBlankAllowed}
                      isDefaultEdit={row.isEmpty && fieldName == 'name'}
                      isEditable={isEditable}
                      isSelect={isSelect}
                      requestParameters={inLineEditRequestParameters}
                      rowData={row}
                      selectOptions={selectOptions}
                      textBoxClass={'inlineEditText'}
                      textToDisplay={row[fieldName]}
                      textToDisplayTransform={textToDisplayTransform}
                    />
                  )}
                  {endIcon && endIcon}
                </Grid>
              );
            }
          )
        )}

        {isMenu &&
        (row.isBeingloaded !== undefined || row.loading !== undefined
          ? row.isBeingloaded === false || row.loading === false
          : true) ? (
          <Grid container item className={classes.valueText} justifyContent="flex-end" xs={1}>
            <IconButton
              aria-label="Filter"
              className="iconImage"
              disableFocusRipple={true}
              disableRipple={true}
              id="more-option-popover"
              size="small"
              style={{ padding: 0 }}
              onClick={handleOpenMoreOptionDialog}
            >
              <MoreHorizIcon className={'moreHoriz'} size="small" />
            </IconButton>
            {openMoreOptionDialog && (
              <MoreOptionDialog
                anchorEl={openMoreOptionDialog}
                handleClose={handleCloseMoreOptionDialog}
                id="more-option-popover"
                open={openMoreOptionDialog}
                optionMenu={optionMenu}
                requestParameter={menuActionRequestParameters}
              />
            )}
          </Grid>
        ) : null}
      </Grid>
    );
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps.row, nextProps.row);
  }
);

const ListComponent = memo((props) => {
  const classes = useStyles();
  const {
    list,
    rowItems,
    isMenu,
    optionMenu,
    isProject,
    handleDetails,
    style,
    isLoading,
    skeletonNoOfRows,
  } = props;

  const loader = useRef(null);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      //setIsNewPageLoading(true)
      console.log('Loading...');
      //   setPage && setPage(prev + 1);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0.5,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  return isLoading ? (
    <SkeletonListComponent
      gridContainerStyle={style}
      noOfRows={skeletonNoOfRows ? skeletonNoOfRows : 15}
    />
  ) : list.length < 1 ? (
    <Grid item xs={12}>
      <NoDataAvailableComponent />
    </Grid>
  ) : (
    <Grid container className={classes.mainGridContainer} style={style && style}>
      <Grid container item className={classes.gridHeading}>
        {rowItems &&
          rowItems.map(({ title, width }) => {
            return <HeaderTitleComponent key={title} title={title} width={width} />;
          })}
        <Grid item className={classes.headingText} xs={1}></Grid>
      </Grid>
      <ListMapComponent
        handleDetails={handleDetails}
        isMenu={isMenu}
        isProject={isProject}
        list={list}
        optionMenu={optionMenu}
        rowItems={rowItems}
      />
      {isNewPageLoading && <div>Loading...</div>}
      <div className="loading-ref-class" ref={loader} />
    </Grid>
  );
});

const HeaderTitleComponent = memo(
  ({ width, title }) => {
    const classes = useStyles();
    return (
      <Grid item className={classes.headingText} key={title} xs={width}>
        {title && title}{' '}
      </Grid>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  }
);

const ListMapComponent = memo(
  ({ list, isMenu, rowItems, isProject, optionMenu, handleDetails }) => {
    return (
      list &&
      list.map((element, index) => {
        return (
          <RowComponent
            handleDetails={handleDetails}
            index={index}
            isMenu={isMenu}
            isProject={isProject}
            key={element.id}
            optionMenu={optionMenu}
            row={element}
            rowItems={rowItems}
          />
        );
      })
    );
  }
);

const SkeletonListComponent = memo(({ noOfRows, gridContainerStyle }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.mainGridContainer}
      direction="column"
      style={gridContainerStyle}
    >
      {times(noOfRows, (row) => {
        return (
          <Grid
            container
            item
            className={clsx(classes.gridBorder, classes.row, 'my-panel')}
            key={`${row}-skeleton`}
            style={{
              padding: 0,
              borderTop: row == 0 ? '1px solid #e7e4e4' : '',
            }}
          >
            <Skeleton className={classes.skeletonRow} variant="text" />
          </Grid>
        );
      })}
    </Grid>
  );
});

export default ListComponent;
