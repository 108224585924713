import { api, providesList } from 'api';

export const BUDGET_CODE_KEY = 'BudgetCode';

const budgetCodesAPI = api.enhanceEndpoints({ addTagTypes: [BUDGET_CODE_KEY] }).injectEndpoints({
  endpoints: (build) => ({
    getBudgetCodes: build.query({
      query: () => `/budget-codes`,
      providesTags: (result, error, args) => (result ? providesList(result, BUDGET_CODE_KEY) : []),
      transformResponse: (response) => response.data,
    }),
    createBudgetCode: build.mutation({
      query: (body) => ({
        url: `/budget-codes`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, _error, params) =>
        result ? [{ type: BUDGET_CODE_KEY, id: 'LIST' }] : [],
    }),
    deleteBudgetCode: build.mutation({
      query: ({ id }) => ({
        url: `/budget-codes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: BUDGET_CODE_KEY }],
    }),
    updateBudgetCode: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/budget-codes/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, _error, params) =>
        result
          ? [
              { type: BUDGET_CODE_KEY, id: 'LIST' },
              { type: BUDGET_CODE_KEY, id: params.id },
            ]
          : [],
    }),
  }),
});

export const {
  useGetBudgetCodesQuery,
  useCreateBudgetCodeMutation,
  useUpdateBudgetCodeMutation,
  useDeleteBudgetCodeMutation,
} = budgetCodesAPI;
