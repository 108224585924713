import { useMemo } from 'react';

const emptyObject = {};

export function useSanitizedObject(inputObject = emptyObject) {
  const sanitizedObject = useMemo(() => {
    return Object.keys(inputObject).reduce((sanitized, key) => {
      const value = inputObject[key];
      sanitized[key] = value === undefined || value === null ? '' : value;
      return sanitized;
    }, {});
  }, [inputObject]);

  return sanitizedObject;
}

export default useSanitizedObject;
