import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import cloneDeep from 'lodash/cloneDeep';
import { Popover, Typography } from '@mui/material';
import { FormLabel } from 'assets/style-components/typography';

const useStyles = makeStyles((theme) => ({
  weekNameButton: {
    [`&.MuiButton-root`]: {
      minWidth: 0,
    },
    '& :disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'auto',
    },
    marginRight: '4px',
    borderRadius: '5px',
    height: '27px',
    fontSize: '10px',
    fontWeight: '700',
  },
  greenButton: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#1eb980',
      boxShadow: 'none',
    },
    border: '#1eb980',
    boxShadow: 'none',
    borderRadius: '2px',
    backgroundColor: '#1eb980',
    color: 'white',
    fontSize: '10px',
    fontWeight: '700',
    width: '84px',
    textTransform: 'uppercase',
  },
  popOverPaper: {
    padding: '15px',
    borderRadius: '6px',
  },
}));

const WorkDaysComponent = (props) => {
  const classes = useStyles();
  const {
    daysObject,
    handleClick,
    handleClose,
    id,
    open,
    anchorEl,
    isPopover,
    verticalPosition,
    horizontalPosition,
    handleSubmit,
    disabled,
  } = props;
  const [days, setDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const handleChange = (day) => {
    const daysClone = cloneDeep(days);
    handleClick
      ? handleClick({
        ...daysObject,
        workday_calendar: { ...daysClone, [day]: daysClone[day] === '1' ? '0' : '1' },
      })
      : setDays({ ...daysClone, [day]: daysClone[day] === '1' ? '0' : '1' });
  };
  const handleOnSubmit = () => {
    handleSubmit(days);
    handleClose();
  };

  useEffect(() => {
    setDays({
      monday: daysObject.workday_calendar.monday,
      tuesday: daysObject.workday_calendar.tuesday,
      wednesday: daysObject.workday_calendar.wednesday,
      thursday: daysObject.workday_calendar.thursday,
      friday: daysObject.workday_calendar.friday,
      saturday: daysObject.workday_calendar.saturday,
      sunday: daysObject.workday_calendar.sunday,
    });
  }, [daysObject]);

  return isPopover ? (
    <Popover
      anchorEl={anchorEl}
      anchorPosition={{ top: 500, left: 512 }}
      classes={{ paper: classes.popOverPaper }}
      id={id}
      open={open}
      anchorOrigin={{
        vertical: verticalPosition ? verticalPosition : 'center',
        horizontal: horizontalPosition ? horizontalPosition : 'left',
      }}
      onClose={handleClose}
    >
      <Grid item style={{ justifyContent: 'center', display: 'flex' }}>
        <FormLabel style={{ fontSize: '13px', fontWeight: '600' }}>Work Days</FormLabel>
      </Grid>
      <Grid item style={{ paddingTop: '10px', paddingBottom: '15px' }}>
        <WorkDaysButtonComponent days={days} disabled={disabled} handleChange={handleChange} />
      </Grid>
      <Grid item style={{ justifyContent: 'center', display: 'flex' }}>
        <Button className={classes.greenButton} onClick={disabled ? handleClose : handleOnSubmit}>
          {disabled ? 'Close' : 'Save'}
        </Button>
      </Grid>
    </Popover>
  ) : (
    <WorkDaysButtonComponent days={days} disabled={disabled} handleChange={handleChange} />
  );
};

const workDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const WorkDaysButtonComponent = (props) => {
  const { handleChange, days, disabled } = props;
  return (
    <Grid container item spacing={0.5}>
      {workDays.map((day) => (
        <Grid item key={day}>
          <Button
            disabled={disabled}
            size={'small'}
            variant={'contained'}
            sx={{
              backgroundColor: days[day] === '0' && 'grey.400',
            }}
            onClick={() => handleChange(day)}
          >
            {day.slice(0, 3)}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default WorkDaysComponent;
