const { calculateTaskForecastedDates, hasChild, getParents } = require('../tasks');
const { topologicalSort } = require('./topologicalSort');

function updateForecastedDates(rootTaskIds, gantt) {
  const allParentIds = new Set();
  const updatedTaskIds = [];
  let sortedTaskIds = [];
  const levels = {};
  if (rootTaskIds) {
    rootTaskIds.forEach((taskId) => {
      const parents = getParents(taskId, gantt);
      levels[String(taskId)] = parents.length + 1;
      parents.forEach((parent, index) => {
        if (!allParentIds.has(parent.id)) {
          allParentIds.add(parent.id);
          levels[String(parent.id)] = parents.length - index;
        } else {
          return;
        }
      });
    });
    // Sort set by WBS so kids are before parents
    sortedTaskIds = [...rootTaskIds, ...Array.from(allParentIds)].sort(
      (a, b) => levels[b] - levels[a]
    );
  } else {
    sortedTaskIds = topologicalSort(gantt).map((task) => task.id);
  }
  // Iterate over tasks updating forecasted dates
  sortedTaskIds.forEach((taskId) => {
    const task = gantt.getTask(taskId);
    const { forecastedStartDate, forecastedEndDate } = calculateTaskForecastedDates(task, gantt);
    if (
      +task.forecasted_start_date !== +forecastedStartDate ||
      +task.forecasted_end_date !== +forecastedEndDate
    ) {
      task.forecasted_start_date = forecastedStartDate;
      task.forecasted_end_date = forecastedEndDate;
      // Update values based on autoschedule date
      if (hasChild(taskId, gantt) && task.autoschedule_date === 'forecasted') {
        task.end_date = task.forecasted_end_date;
        // Need to update the task duration because that is what the auto scheduler uses to generate its plan
        task.duration = gantt.calculateDuration(task);
      }
      // Add id to record that keeps track of all updated task ids between auto schedules
      updatedTaskIds.push(taskId);
    }
  });

  return updatedTaskIds;
}

module.exports = { updateForecastedDates };
