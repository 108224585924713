import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';

const StyledPopover = styled(Popover)(
  {
    '& .MuiPopover-paper': {
      padding: (props) => (props?.variant == 'dark' ? 10 : 0),
      backgroundColor: (props) => (props?.variant == 'dark' ? '#3C3C3C' : ''),
      color: (props) => (props?.variant == 'dark' ? '#FFF' : ''),
    },
  },
  { name: 'Popover' }
);

/**
 * Popover
 * @param {string} variant
 * @param {string} position
 * @param {*} popoverProps
 * @returns
 */
const PopoverMenu = (props) => {
  const { children, ...popoverProps } = props;
  return (
    <StyledPopover
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      {...popoverProps}
    >
      {children}
    </StyledPopover>
  );
};

export default PopoverMenu;
