/**
 * Calculates the distance between a point and a line segment defined by two points.
 * @param {Object} point - The point { x: number, y: number }.
 * @param {Object} lineStart - The start point of the line segment { x: number, y: number }.
 * @param {Object} lineEnd - The end point of the line segment { x: number, y: number }.
 * @returns {number} - The distance between the point and the line segment.
 */
export const pointToLineDistance = (point, lineStart, lineEnd) => {
  const px = point.x;
  const py = point.y;
  const x1 = lineStart.x;
  const y1 = lineStart.y;
  const x2 = lineEnd.x;
  const y2 = lineEnd.y;

  // Calculate the components of the vector
  const A = px - x1;
  const B = py - y1;
  const C = x2 - x1;
  const D = y2 - y1;

  // Calculate dot product and length of the segment squared
  const dot = A * C + B * D;
  const lenSq = C * C + D * D;
  let param = -1;
  if (lenSq !== 0) {
    // Compute parameter value (param is the normalized "distance" from the point to the segment)
    param = dot / lenSq;
  }

  let xx, yy;

  // If the projection falls outside the segment, compute the closest endpoint
  if (param < 0) {
    xx = x1;
    yy = y1;
  } else if (param > 1) {
    xx = x2;
    yy = y2;
  } else {
    // Otherwise, compute the point on the segment closest to the point
    xx = x1 + param * C;
    yy = y1 + param * D;
  }

  // Compute the distance from the point to the closest point on the segment
  const dx = px - xx;
  const dy = py - yy;
  return Math.sqrt(dx * dx + dy * dy);
};

/**
 * Finds the closest edge (line segment) to a given point among a list of points defining a polygon.
 * @param {Array<{ x: number, y: number }>} points - Array of points defining a polygon.
 * @param {{ x: number, y: number }} position - Coordinates of the point to find the closest edge to.
 * @returns {{ start: { x: number, y: number }, end: { x: number, y: number }, startIndex: number, endIndex: number }} - Object containing information about the closest edge.
 */
export const getClosestEdge = (points, position) => {
  let closestEdgeDistance = Infinity;
  let closestEdge = undefined;

  // Iterate over each edge defined by consecutive points
  points.forEach((point, index) => {
    const nextIndex = (index + 1) % points.length;
    const nextPoint = points[nextIndex];

    // Calculate the distance from the point to the current edge
    const distanceToEdge = pointToLineDistance(position, point, nextPoint);

    // Update closest edge if the current edge is closer
    if (distanceToEdge < closestEdgeDistance) {
      closestEdgeDistance = distanceToEdge;
      closestEdge = { start: point, end: nextPoint, startIndex: index, endIndex: nextIndex };
    }
  });

  return closestEdge;
};

/**
 * Finds the closest point to a given point within a polygon.
 * @param {{ x: number, y: number }} position - The coordinates of the point to snap.
 * @param {Array<{ x: number, y: number }>} points - Array of points defining a polygon.
 * @returns {{ point: { x: number, y: number } | null, distance: number }} - Object containing the closest point found and its distance, or null if no points are within the snap distance.
 */
export const getClosestPointInPolygon = (position, points) => {
  let closestPoint = undefined;
  let closestDistance = Infinity;

  points.forEach((point) => {
    const distance = Math.sqrt((point.x - position.x) ** 2 + (point.y - position.y) ** 2);
    if (distance < closestDistance) {
      closestPoint = { x: point.x, y: point.y };
      closestDistance = distance;
    }
  });

  return { point: closestPoint, distance: closestDistance };
};

/**
 * Checks if two points are perpendicular with respect to a specified axis within a specified distance.
 * @param {{ x: number, y: number }} point1 - The first point { x, y }.
 * @param {{ x: number, y: number }} point2 - The second point { x, y }.
 * @param {string} axis - The specified axis ('x' or 'y').
 * @param {number} [tolerance=10] - The tolerance for determining perpendicularity. Default is 10.
 * @returns {boolean} - True if the points are perpendicular with respect to the specified axis within the specified tolerance, otherwise false.
 */
export const arePointsPerpendicular = (point1, point2, axis, tolerance = 10) => {
  // Calculate the distance along the specified axis
  return Math.abs(point2[axis] - point1[axis]) <= tolerance;
};

/**
 * Finds the closest perpendicular points on the x and y axes to a given position among a set of points.
 * @param {{ x: number, y: number }} position - The position to which the closest perpendicular points are sought.
 * @param {Array<{ x: number, y: number }>} points - Array of points among which the closest points are searched.
 * @param {number} [tolerance=10] - The tolerance for determining perpendicularity. Default is 10.
 * @returns {{ x: number, y: number }[]} - Array containing the closest perpendicular points on the x and y axes.
 */
export const getClosestPerpendicularPoints = (position, points, tolerance = 10) => {
  // Initialize variables to store the closest perpendicular points
  let closestX = null;
  let closestY = null;
  let closestDistanceX = Infinity;
  let closestDistanceY = Infinity;

  // Iterate over each point in the array
  points.forEach((point) => {
    // Check if the point is perpendicular to the position along the x axis
    if (arePointsPerpendicular(point, position, 'x', tolerance)) {
      const distanceX = Math.abs(point.x - position.x);
      // Update closestX if this point is closer
      if (distanceX < closestDistanceX) {
        closestX = point;
        closestDistanceX = distanceX;
      } else if (
        distanceX === closestDistanceX &&
        Math.abs(point.y - position.y) < Math.abs(closestX.y - position.y)
      ) {
        closestX = point;
      }
    }

    // Check if the point is perpendicular to the position along the y axis
    if (arePointsPerpendicular(point, position, 'y', tolerance)) {
      const distanceY = Math.abs(point.x - position.x);
      // Update closestX if this point is closer
      if (distanceY < closestDistanceY) {
        closestY = point;
        closestDistanceY = distanceY;
      } else if (
        distanceY === closestDistanceY &&
        Math.abs(point.y - position.y) < Math.abs(closestY.y - position.y)
      ) {
        closestY = point;
      }
    }
  });

  // Return the array containing the closest perpendicular points on the x and y axes
  return [closestX, closestY];
};

/**
 * Checks if there are overlapping polygons among the given set of polygons.
 * @param {Array<Array<{ x: number, y: number }>>} polygons - Array of polygons, where each polygon is represented as an array of points.
 * @returns {boolean} - True if there are overlapping polygons, otherwise false.
 */
export const arePolygonsOverlapping = (polygons) => {
  // Iterate over each pair of polygons
  for (let i = 0; i < polygons.length; i++) {
    for (let j = i + 1; j < polygons.length; j++) {
      // Check if any pair of edges intersect between the two polygons
      if (doEdgesIntersect(polygons[i], polygons[j])) {
        return true; // Overlapping polygons found
      }
      // Check if one polygon is fully encased in the other
      if (isPolygonInside(polygons[i], polygons[j]) || isPolygonInside(polygons[j], polygons[i])) {
        return true; // Overlapping polygons found
      }
    }
  }
  return false; // No overlapping polygons found
};

/**
 * Checks if one polygon is fully inside another polygon.
 * @param {Array<{ x: number, y: number }>} innerPolygon - Polygon that may be inside the outer polygon.
 * @param {Array<{ x: number, y: number }>} outerPolygon - Polygon that may contain the inner polygon.
 * @returns {boolean} - True if the inner polygon is fully inside the outer polygon, otherwise false.
 */
const isPolygonInside = (innerPolygon, outerPolygon) => {
  for (const point of innerPolygon) {
    if (isPointInsidePolygon(point, outerPolygon)) {
      return true;
    }
  }
  return false;
};

/**
 * Checks if a point is strictly inside a polygon. Points on the perimeter of the polygon are NOT considered inside.
 * @param {{ x: number, y: number }} point - Point to check.
 * @param {Array<{ x: number, y: number }>} polygon - Polygon represented as an array of points.
 * @returns {boolean} - True if the point is strictly inside the polygon, otherwise false.
 */
const isPointInsidePolygon = (point, polygon) => {
  let isInside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const vertex1X = polygon[i].x,
      vertex1Y = polygon[i].y;
    const vertex2X = polygon[j].x,
      vertex2Y = polygon[j].y;

    const doesIntersect =
      vertex1Y > point.y !== vertex2Y > point.y &&
      point.x < ((vertex2X - vertex1X) * (point.y - vertex1Y)) / (vertex2Y - vertex1Y) + vertex1X;

    if (doesIntersect) isInside = !isInside;

    // Check if the point is on a vertex of the polygon
    if (
      (point.x === vertex1X && point.y === vertex1Y) ||
      (point.x === vertex2X && point.y === vertex2Y)
    ) {
      return false; // Point is on a vertex of the polygon, not strictly inside
    }

    // Check if the point is on an edge of the polygon
    const isOnEdge = isPointOnLine(
      { x: vertex1X, y: vertex1Y },
      { x: vertex2X, y: vertex2Y },
      point
    );
    if (isOnEdge) {
      return false; // Point is on an edge of the polygon, not strictly inside
    }
  }

  return isInside;
};

/**
 * Checks if a point lies on a line segment.
 * @param {{ x: number, y: number }} start - Start point of the line segment.
 * @param {{ x: number, y: number }} end - End point of the line segment.
 * @param {{ x: number, y: number }} point - Point to check.
 * @returns {boolean} - True if the point lies on the line segment, otherwise false.
 */
const isPointOnLine = (start, end, point) => {
  const dx = point.x - start.x;
  const dy = point.y - start.y;
  const segmentLengthX = end.x - start.x;
  const segmentLengthY = end.y - start.y;
  const crossProduct = dx * segmentLengthY - dy * segmentLengthX;

  // Check if the point is collinear with the line segment
  if (crossProduct !== 0) return false;

  // Check if the point lies within the bounds of the line segment
  if (Math.abs(segmentLengthX) >= Math.abs(segmentLengthY)) {
    return segmentLengthX > 0
      ? start.x <= point.x && point.x <= end.x
      : end.x <= point.x && point.x <= start.x;
  } else {
    return segmentLengthY > 0
      ? start.y <= point.y && point.y <= end.y
      : end.y <= point.y && point.y <= start.y;
  }
};

/**
 * Checks if two polygons intersect.
 * @param {Array<{ x: number, y: number }>} polygon1 - First polygon represented as an array of points.
 * @param {Array<{ x: number, y: number }>} polygon2 - Second polygon represented as an array of points.
 * @returns {boolean} - True if the polygons intersect, otherwise false.
 */
export const doEdgesIntersect = (polygon1, polygon2) => {
  // Check if any pair of edges intersect between the two polygons
  for (let i = 0; i < polygon1.length; i++) {
    for (let j = 0; j < polygon2.length; j++) {
      if (
        doLineSegmentsIntersect(
          polygon1[i],
          polygon1[(i + 1) % polygon1.length],
          polygon2[j],
          polygon2[(j + 1) % polygon2.length]
        )
      ) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Checks if two line segments intersect.
 * @param {{ x: number, y: number }} start1 - Start point of the first line segment.
 * @param {{ x: number, y: number }} end1 - End point of the first line segment.
 * @param {{ x: number, y: number }} start2 - Start point of the second line segment.
 * @param {{ x: number, y: number }} end2 - End point of the second line segment.
 * @returns {boolean} - True if the line segments intersect, otherwise false.
 */
export const doLineSegmentsIntersect = (start1, end1, start2, end2) => {
  const orientation1 = getOrientation(start1, end1, start2);
  const orientation2 = getOrientation(start1, end1, end2);
  const orientation3 = getOrientation(start2, end2, start1);
  const orientation4 = getOrientation(start2, end2, end1);

  if (
    orientation1 &&
    orientation2 &&
    orientation3 &&
    orientation4 &&
    orientation1 !== orientation2 &&
    orientation3 !== orientation4
  ) {
    return true;
  }

  return false;
};

/**
 * Determines the orientation of three points.
 * @param {{ x: number, y: number }} point1 - First point.
 * @param {{ x: number, y: number }} point2 - Second point.
 * @param {{ x: number, y: number }} point3 - Third point.
 * @returns {number} - Orientation value: 0 if collinear, 1 if clockwise, 2 if counterclockwise.
 */
export const getOrientation = (point1, point2, point3) => {
  const val =
    (point2.y - point1.y) * (point3.x - point2.x) - (point2.x - point1.x) * (point3.y - point2.y);
  if (val === 0) {
    return 0; // Collinear
  }
  return val > 0 ? 1 : 2; // Clockwise or counterclockwise
};

/**
 * Calculates the centroid (geometric center) of a polygon defined by its vertices.
 * @param {Array<{ x: number, y: number }>} points - Array of points defining a polygon.
 * @returns {{ x: number, y: number }} - The coordinates of the centroid of the polygon.
 */
export const getPolygonCentroid = (points) => {
  let centerX = 0;
  let centerY = 0;
  let signedArea = 0;

  // Calculate the signed area and centroid coordinates
  for (let i = 0; i < points?.length; i++) {
    const x0 = points[i].x;
    const y0 = points[i].y;
    const x1 = points[(i + 1) % points.length].x;
    const y1 = points[(i + 1) % points.length].y;
    const a = x0 * y1 - x1 * y0;
    signedArea += a;
    centerX += (x0 + x1) * a;
    centerY += (y0 + y1) * a;
  }

  signedArea *= 0.5;
  centerX /= 6 * signedArea;
  centerY /= 6 * signedArea;

  return { x: centerX, y: centerY };
};

/**
 * Calculates the area of a polygon defined by its vertices using the shoelace formula.
 * @param {Array<{ x: number, y: number }>} points - Array of points defining a polygon.
 * @returns {number} - The area of the polygon.
 */
export const getPolygonArea = (points) => {
  let area = 0;
  const numPoints = points.length;

  for (let i = 0; i < numPoints; i++) {
    const x1 = points[i].x;
    const y1 = points[i].y;
    const x2 = points[(i + 1) % numPoints].x;
    const y2 = points[(i + 1) % numPoints].y;

    area += x1 * y2 - x2 * y1;
  }

  return Math.abs(area / 2);
};
