import { SHOW_SNACKBAR } from '../constants/ActionTypes';

const initialState = {
  message: null,
  variant: null,
};

export default function SnackBarReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        message: action.message,
        variant: action.variant,
      };
    default:
      return state;
  }
}
