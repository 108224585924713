import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Polygon } from './Polygon';
import { PolygonViewer } from './PolygonViewer';

export function PolygonEditor({
  polygons,
  handleDrag,
  handleDragEnd,
  handleDragStart,
  selectedPolygon,
  setSelectedPolygon,
  addPointToPolygonAtPosition,
  removePointFromPolygon,
  snapEdgeX,
  snapEdgeY,
  snapPoint,
  invalidPolygonId,
  PolygonViewerProps,
  scale,
  size = { x: 400, y: 300 },
  background,
  disabled,
}) {
  const containerRef = useRef(null);

  const [dragState, setDragState] = useState(undefined);

  const getPositionFromEvent = useCallback(
    (event) => {
      const rect = containerRef.current.getBoundingClientRect();

      // Calculate the aspect ratio-adjusted client coordinates
      const xRatio = size.x / rect.width;
      const yRatio = size.y / rect.height;

      const x = (event.clientX - rect.left) * xRatio;
      const y = (event.clientY - rect.top) * yRatio;

      return { x, y };
    },
    [size.x, size.y]
  );

  const getEventTargetInfo = (target) => {
    const polygonId = target.dataset.polygonId ? Number(target.dataset.polygonId) : undefined;
    const pointIndex = target.dataset.pointIndex ? Number(target.dataset.pointIndex) : undefined;
    return { polygonId, pointIndex };
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (dragState) {
        const position = getPositionFromEvent(e);
        handleDrag(
          dragState.polygonId,
          dragState.pointIndex,
          position,
          (snapDistance) => snapDistance / scale
        );
      }
    };

    const handleMouseUp = (e) => {
      if (dragState) {
        handleDragEnd();
        setDragState(undefined);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragState, getPositionFromEvent, handleDrag, handleDragEnd, scale]);

  const handleMouseDown = (e) => {
    const { polygonId, pointIndex } = getEventTargetInfo(e.target);
    if (selectedPolygon !== undefined && polygonId !== undefined && selectedPolygon === polygonId) {
      const position = getPositionFromEvent(e);
      handleDragStart(polygonId, pointIndex, position);
      setDragState({ polygonId, pointIndex });
    } else if (polygonId === undefined) {
      setSelectedPolygon(undefined);
    }
  };

  const handleClick = (e) => {
    const { polygonId, pointIndex } = getEventTargetInfo(e.target);
    setSelectedPolygon(polygonId);
  };

  const handleDoubleClick = (e) => {
    const position = getPositionFromEvent(e);
    const { polygonId, pointIndex } = getEventTargetInfo(e.target);
    if (selectedPolygon !== undefined) {
      if (
        selectedPolygon !== undefined &&
        selectedPolygon === polygonId &&
        pointIndex !== undefined
      ) {
        removePointFromPolygon(polygonId, pointIndex);
      } else if (polygonId !== undefined) {
        addPointToPolygonAtPosition(polygonId, position);
      }
    }
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    const { polygonId, pointIndex } = getEventTargetInfo(touch.target);
    if (selectedPolygon !== undefined && polygonId !== undefined && selectedPolygon === polygonId) {
      const position = getPositionFromEvent(touch);
      handleDragStart(polygonId, pointIndex, position);
      setDragState({ polygonId, pointIndex });
    } else {
      setSelectedPolygon(undefined);
    }
  };

  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    const position = getPositionFromEvent(touch);
    if (dragState) {
      handleDrag(
        dragState.polygonId,
        dragState.pointIndex,
        position,
        (snapDistance) => snapDistance / scale
      );
    }
  };

  const handleTouchEnd = (e) => {
    if (dragState) {
      handleDragEnd();
      setDragState(undefined);
    }
  };

  const sortedPolygons = useMemo(() => {
    const selectedPolyIndex = polygons.findIndex((poly) => poly.id === selectedPolygon);
    const sorted = [...polygons];
    if (selectedPolyIndex !== -1) {
      const selectedPoly = sorted.splice(selectedPolyIndex, 1)[0];
      sorted.push(selectedPoly);
    }
    return sorted.filter((polygon) => polygon?.points);
  }, [polygons, selectedPolygon]);

  return (
    <PolygonViewer
      background={background}
      ref={containerRef}
      viewBox={`0 0 ${size.x} ${size.y}`}
      onClick={!disabled ? handleClick : undefined}
      onDoubleClick={!disabled ? handleDoubleClick : undefined}
      onMouseDown={!disabled ? handleMouseDown : undefined}
      onTouchEnd={!disabled ? handleTouchEnd : undefined}
      onTouchMove={!disabled ? handleTouchMove : undefined}
      onTouchStart={!disabled ? handleTouchStart : undefined}
      {...PolygonViewerProps}
    >
      {sortedPolygons?.map((polygon) => (
        <Polygon
          color={polygon.color}
          error={invalidPolygonId === polygon.id}
          GroupProps={{ cursor: 'move' }}
          id={polygon.id}
          key={polygon.id}
          labels={polygon.labels}
          name={polygon.name}
          points={polygon.points}
          scale={scale}
          selected={selectedPolygon === polygon.id}
        />
      ))}
      {snapPoint && <SnapPoint {...snapPoint} scale={scale} />}
      {snapEdgeX && <PerpendicularSnapPoint {...snapEdgeX} scale={scale} />}
      {snapEdgeY && <PerpendicularSnapPoint {...snapEdgeY} scale={scale} />}
    </PolygonViewer>
  );
}

function SnapPoint({ x, y, scale = 1 }) {
  return <circle cx={x} cy={y} fill={'#000'} fillOpacity={'.3'} r={6 / scale} />;
}

function PerpendicularSnapPoint({ x1, y1, x2, y2, scale = 1 }) {
  return (
    <>
      <line stroke={'#000'} strokeWidth={1 / scale} x1={x1} x2={x2} y1={y1} y2={y2} />
      <circle cx={x1} cy={y1} fill={'#000'} r={2 / scale} strokeWidth={1 / scale} />
    </>
  );
}
