import { AccordionActions, Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import { SelectTradeDropdown } from 'features/contacts/components/SelectTradeDropdown';
import { useState } from 'react';
import { StyleCounterBadge, StyledAccordionSummary } from '../utils/drawerStyle';

/**
 * Company Accordion
 * @param {String} projectId
 * @param {String} taskId
 * @param {String} ganttId
 * @param {Object} handleSave
 * @returns
 */
const TradeAccordion = ({ taskId, handleSave, disableEdit, ganttId }) => {
  const gantt = ganttStore.getGantt(ganttId);
  const task = gantt.getTask(taskId);
  const [tradeIds, setTradeIds] = useState(task?.trades?.length ? task.trades : []);

  const handleCancelEditing = () => {
    setTradeIds(task?.trades?.length ? task.trades : []);
  };

  const handleUpdate = () => {
    handleSave({ trades: [...tradeIds] });
  };

  return (
    <Accordion
      style={{
        boxShadow:
          'rgba(0, 0, 0, 0.2) 1px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 1px 1px 1px 2px',
      }}
    >
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        slotProps={{ transition: { unmountOnExit: true } }}
      >
        <Typography>Trades</Typography>
        <StyleCounterBadge count={[...tradeIds].length}>{[...tradeIds].length}</StyleCounterBadge>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Box maxHeight={'30vh'} width={'100%'}>
          <SelectTradeDropdown
            disableEdit={disableEdit}
            setTradeIds={setTradeIds}
            tradeIds={tradeIds}
          />
        </Box>
      </AccordionDetails>
      {!disableEdit && (
        <AccordionActions>
          <Button size="small" onClick={handleCancelEditing}>
            Cancel
          </Button>
          <Button
            color="primary"
            size="small"
            type="submit"
            variant="contained"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </AccordionActions>
      )}
    </Accordion>
  );
};

export default TradeAccordion;
