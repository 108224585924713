import { useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { StyledMenu } from 'features/activityFeeds/assets/activityFeedStyle';
import { MenuButton } from 'assets/style-components/button';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

const DashboardSelectionMenu = ({ dashboards, setDashboardId, label, dashboardId }) => {
  const [openMenu, setOpenMenu] = useState(false);

  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleClose = () => {
    setOpenMenu(null);
  };

  const handleSelect = (selectedDashboardId) => {
    setDashboardId(selectedDashboardId);
    handleClose();
  };

  return (
    dashboards.length > 1 && (
      <>
        <MenuButton onClick={handleClick}>
          {label}
          {Boolean(openMenu) ? <ArrowDropUp /> : <ArrowDropDown />}
        </MenuButton>
        <StyledMenu
          anchorEl={openMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={Boolean(openMenu)}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={handleClose}
        >
          {dashboards?.map((dashboard) => (
            <ListItem disablePadding key={dashboard.dashboard_id}>
              <ListItemButton
                dense
                selected={dashboardId === dashboard.dashboard_id}
                onClick={(event) => handleSelect(dashboard.dashboard_id)}
              >
                <ListItemText primary={dashboard.dashboard_name} />
              </ListItemButton>
            </ListItem>
          ))}
        </StyledMenu>
      </>
    )
  );
};

export default DashboardSelectionMenu;
