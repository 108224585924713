import { api, providesList } from 'api';
import { CHANGE_ORDER_LINE_KEY } from './change-order-lines.api';
import { CONTRACT_LINE_KEY } from './contract-lines.api';
import { BUDGET_LINE_KEY } from './budget-lines.api';

export const INVOICE_LINE_KEY = 'InvoiceLine';

const invoiceLinesAPI = api.enhanceEndpoints({ addTagTypes: [INVOICE_LINE_KEY] }).injectEndpoints({
  endpoints: (build) => ({
    getInvoiceLinesByInvoice: build.query({
      query: ({ invoiceId }) => `/invoices/${invoiceId}/invoice-lines`,
      providesTags: (result, error, args) => (result ? providesList(result, INVOICE_LINE_KEY) : []),
      transformResponse: (response) => response.data,
    }),
    updateInvoiceLine: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/invoice-lines/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, _error, params) => {
        if (!result) {
          return [];
        }

        return [
          { type: INVOICE_LINE_KEY, id: 'LIST' },
          { type: INVOICE_LINE_KEY, id: params.id },
          { type: 'Invoice', id: result.data.invoice_id },
          { type: 'Invoice', id: 'LIST' },
          { type: CONTRACT_LINE_KEY, id: 'LIST' },
          { type: CHANGE_ORDER_LINE_KEY, id: 'LIST' },
          { type: BUDGET_LINE_KEY },
          { type: 'Budget' },
          { type: 'Contract', id: 'LIST' },
          { type: 'BudgetForecast' },
          { type: 'ProjectForecast' },
        ];
      },
    }),
  }),
});

export const { useGetInvoiceLinesByInvoiceQuery, useUpdateInvoiceLineMutation } = invoiceLinesAPI;
