import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducer from './reducers';
import { api } from 'api';
import { setupListeners } from '@reduxjs/toolkit/query';
import { injectStore as injectApiHelperStore } from 'helpers/apiHelper';
import { injectStore as injectApiErrorHandlerStore } from 'helpers/apiErrorHandler';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['customViews'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const setUpStore = (preloadedState) => {
  const store = configureStore({
    reducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(api.middleware),
  });
  injectApiHelperStore(store);
  injectApiErrorHandlerStore(store);
  return store;
};

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
});

setupListeners(store.dispatch);

export default store;
