import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import Switch from '@mui/material/Switch';
import makeStyles from '@mui/styles/makeStyles';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import ganttStore from '../../ganttConfig/ganttStore';

import { MenuButton } from 'assets/style-components/button';

import { parseDate } from '@blackhyve/utilities/dates';
import { List, ListItem, ListItemText, ListSubheader, Popover } from '@mui/material';
import { endOfWeek, startOfWeek, subWeeks } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { registerMenuState, updateMenuState } from 'slices/customViewSlice';
import { jobwalkIconString, stuckPointString, warningIconString } from '../../ganttConfig/column/columnConfig';

const useStyles = makeStyles(() => ({
  root: {
    color: '#5F6368',
    '&.Mui-disabled': {
      opacity: 'unset',
      paddingBottom: 'unset',
      '&:not(first-child)': {
        marginTop: '4px',
      },
      '& p': {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#c4c4c4',
      },
    },
  },
}));

const initialState = {
  missingDependencies: {
    displayText: 'Missing Dependencies',
    enabled: false,
    render: (task) => {
      if ((!task.$source.length || !task.$target.length) && task.type === 'task') {
        return `<span style="display: flex; align-items: center;" class="link-missing-warning">${warningIconString}</span>`;
      } else {
        return '';
      }
    },
  },
  jobwalkOpen: {
    displayText: 'Jobwalk Open',
    enabled: false,
    render: (task, gantt) => {
      // A jobwalk is due if it has not been jobwalked this week and over due if it hasn't been jobwalked in over a week.
      const today = new Date();
      const endOfThisWeek = endOfWeek(today, { weekStartsOn: 1 });
      const startOfThisWeek = startOfWeek(today, { weekStartsOn: 1 });
      const latestJobwalk = task.latest_job_walk ? parseDate(task.latest_job_walk) : 0;

      if (
        task.type === 'task' &&
        task.status !== 'complete' &&
        +task.forecasted_start_date < +endOfThisWeek &&
        +latestJobwalk < +startOfThisWeek
      ) {
        const pastDue =
          +task.forecasted_start_date < +startOfThisWeek &&
          +latestJobwalk < +subWeeks(startOfThisWeek, 1);

        const iconClass = pastDue ? 'jobwalk-past-due-icon' : 'jobwalk-due-icon';
        return `<span style="display: flex; align-items: center;" class="${iconClass}">${jobwalkIconString}</span>`;
      } else {
        return '';
      }
    },
  },
  openStuckPoints: {
    displayText: 'Open Stuck Points',
    enabled: false,
    render: (task) => {
      if (task?.open_stuck_points_count) {
        return `<span class="open-stuck-points" style="display: flex; align-items: center; height: 18px; background-color: red; border-radius: 2px; padding-left: 1px; padding-right: 1px; color: white; margin: auto;">${stuckPointString} <span style="margin-left: 2px; margin-right: 2px; font-size: 12px;"> ${task.open_stuck_points_count} </span></span>`;
      } else {
        return ''
      }

    },
  },
};

const options = {};
Object.keys(initialState).forEach((key) => {
  if (initialState[key].enabled !== undefined) {
    options[key] = initialState[key].enabled;
  }
});

const ToolsMenu = ({ ganttId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const gantt = ganttStore.getGantt(ganttId);

  const selectedOptions = useSelector((state) => state.customViews?.menus?.tools?.currentValue);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (gantt && selectedOptions) {
      const tools = Object.assign({ ...selectedOptions }, initialState);
      Object.entries(selectedOptions).forEach(([key, value]) => {
        if (!value) {
          delete tools[key];
        }
      });
      gantt.config.tools = tools;
      gantt.render();
    }
  }, [selectedOptions, gantt, ganttId]);

  const handleToggleTools = (event, optionKey) => {
    dispatch(
      updateMenuState({
        name: 'tools',
        value: { ...selectedOptions, [optionKey]: !selectedOptions[optionKey] },
      })
    );
  };

  useEffect(() => {
    dispatch(
      registerMenuState({
        name: 'tools',
        value: options,
      })
    );
  }, [dispatch]);

  return (
    <>
      <MenuButton
        endIcon={Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />}
        isHighlighted={!isEqual(options, selectedOptions)}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        Tools
      </MenuButton>
      {anchorEl !== null ? (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={Boolean(anchorEl)}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setAnchorEl(null)}
        >
          <List dense>
            {Object.keys(initialState).map((optionKey) => {
              const option = initialState[optionKey];
              const isEnabled = selectedOptions[optionKey];
              return (
                <>
                  {option.groupTitle ? (
                    <ListSubheader sx={{ fontWeight: 'bold' }}>{option.displayText}</ListSubheader>
                  ) : (
                    <ListItem
                      key={option.displayText}
                      secondaryAction={
                        !option.groupTitle ? (
                          <Switch
                            checked={isEnabled}
                            color="primary"
                            edge="end"
                            size="small"
                            onClick={(event) => handleToggleTools(event, optionKey)}
                          />
                        ) : (
                          ''
                        )
                      }
                    >
                      <ListItemText primary={option.displayText} />
                    </ListItem>
                  )}
                </>
              );
            })}
          </List>
        </Popover>
      ) : null}
    </>
  );
};

export default ToolsMenu;
