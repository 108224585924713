import React from 'react';
import { api } from 'api';
import ReactQuill from 'react-quill';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import isEmpty from 'lodash/isEmpty';
import { SectionHeading, FormHeading, FormValueLabel } from '.';
import { fieldLabels } from 'components/taskFeeds/utils/taskFeedHelper';
import { useSelector } from 'react-redux';
import { selectCurrentUserWorkspaceId } from 'features/auth';

/**
 * Notification content
 * @param {*} notificationDetails
 * @returns
 */
const NotificationContent = ({ notificationDetails }) => {
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { project } = api.endpoints.getProjects.useQueryState({ workspaceId }, {
    selectFromResult: ({ data: projects, isFetching, isLoading }) => {
      return {
        project: projects?.find((project) => project.id === notificationDetails?.project_id),
        isFetching,
        isLoading,
      };
    },
  })

  const notificationAudit = notificationDetails?.audit;
  const taskDetails = notificationAudit?.task;

  if (notificationDetails.type === 'NewComment') {
    const comment = notificationAudit?.new_values?.comment;
    return (
      <>
        <ReactQuill
          bounds="quill"
          className="comment"
          readOnly={true}
          theme="bubble"
          value={comment}
        />
        {!isEmpty(taskDetails) && (
          <Box style={{ marginTop: 10 }}>
            <NotificationTaskDetails
              endDate={taskDetails?.end_date}
              projectName={project?.name}
              startDate={taskDetails?.start_date}
              taskName={taskDetails?.name}
            />
          </Box>
        )}
      </>
    );
  }

  if (
    notificationDetails.type === 'UnassignedTask' ||
    notificationDetails.type === 'AssignedTask'
  ) {
    return (
      <NotificationTaskDetails
        endDate={taskDetails?.end_date}
        projectName={project?.name}
        startDate={taskDetails?.start_date}
        taskName={taskDetails?.name}
      />
    );
  }

  if (
    notificationDetails.type === 'TaskUpdated' ||
    notificationDetails.type === 'TaskStartDateChanged'
  ) {
    return (
      <>
        <SectionHeading>Updated Changes</SectionHeading>
        <Grid container item style={{ marginLeft: 10 }} xs={9}>
          {!isEmpty(notificationAudit?.new_values) &&
            Object.keys(notificationAudit?.new_values)?.map((key) => {
              return (
                <Grid container item style={{ padding: 5 }} xs={6}>
                  <Grid item xs={5}>
                    <FormHeading>{fieldLabels[key]}</FormHeading>
                  </Grid>
                  <Grid item xs={7}>
                    <FormValueLabel>{notificationAudit?.new_values[key]}</FormValueLabel>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </>
    );
  }
};

/**
 * Notification task details
 * @param {string} projectName
 * @param {string} taskName
 * @param {string} startDate
 * @param {string} endDate
 * @returns
 */
const NotificationTaskDetails = ({ projectName, taskName, startDate, endDate }) => {
  return (
    <>
      <SectionHeading>Task Details</SectionHeading>
      <Grid container item style={{ marginLeft: 10 }} xs={9}>
        <Grid container item style={{ padding: 5 }} xs={6}>
          <Grid item xs={4}>
            <FormHeading>Project </FormHeading>
          </Grid>
          <Grid item xs={8}>
            <FormValueLabel>{projectName}</FormValueLabel>
          </Grid>
        </Grid>
        <Grid container item style={{ padding: 5 }} xs={6}>
          <Grid item xs={4}>
            <FormHeading>Start Date </FormHeading>
          </Grid>
          <Grid item xs={8}>
            <FormValueLabel>{startDate}</FormValueLabel>
          </Grid>
        </Grid>
        <Grid container item style={{ padding: 5 }} xs={6}>
          <Grid item xs={4}>
            <FormHeading>Task </FormHeading>
          </Grid>
          <Grid item xs={8}>
            <FormValueLabel>{taskName}</FormValueLabel>
          </Grid>
        </Grid>
        <Grid container item style={{ padding: 5 }} xs={6}>
          <Grid item xs={4}>
            <FormHeading>End Date </FormHeading>
          </Grid>
          <Grid item xs={8}>
            <FormValueLabel>{endDate}</FormValueLabel>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NotificationContent;
