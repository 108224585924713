const projectGanttColumns = [
  { name: 'text', label: 'Name', align: 'left', width: '200', tree: true },
  {
    align: 'left',
    width: '200',
    name: 'previous_week_jobwalk_rating',
    label: '<div class="gantt_grid_head_cell_two_lines">Last Week<br/>On Time Job Walk</div>',
    template: (task) =>
      task.previous_week_jobwalk_rating || task.previous_week_jobwalk_rating == 0
        ? task.previous_week_jobwalk_rating + '%'
        : '-',
  },
  {
    align: 'left',
    width: '200',
    name: 'current_week_jobwalk_rating',
    label: '<div class="gantt_grid_head_cell_two_lines">This Week<br/>On Time Job Walk</div>',
    template: (task) =>
      task.current_week_jobwalk_rating || task.current_week_jobwalk_rating == 0
        ? task.current_week_jobwalk_rating + '%'
        : '-',
  },
  {
    align: 'left',
    name: 'pace',
    label: 'Pace',
    width: '100',
    template: (task) => {
      return `<span style="color: ${task.pace >= 0 ? '#1EB980' : '#FE6969'}">${task.pace}</span>`;
    },
  },
  {
    align: 'left',
    name: 'pr',
    label: 'PR',
    width: '100',
    template: (task) => {
      if (task?.pr) {
        let color = '#FE6969'; //red
        if (task.pr >= 1) {
          color = '#1EB980'; //green
        } else if (task.pr >= 0.9 && task.pr <= 0.99) {
          color = '#FCC034'; //yellow
        }
        return `<span style="color: ${color}">${task.pr}</span>`;
      } else {
        return '-';
      }
    },
  },
  {
    align: 'left',
    width: '200',
    name: 'percent_complete_by_date',
    label: 'Progress By Date',
    template: (task) =>
      task.percent_complete_by_date ? Number(task.percent_complete_by_date).toFixed(0) + '%' : '-',
  },
];

export default projectGanttColumns;
