import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FeedTypeMenu from './menus/FeedTypeMenu';
import { useSearchParams } from 'react-router-dom';
import UserFilterMenu from './menus/UserFilterMenu';
import ProjectFilterMenu from './menus/ProjectFilterMenu';
import { MenuSeparator } from 'assets/style-components/button';

const filterOptions = [
  { name: 'All', value: undefined },
  { name: 'Jobwalks', value: 'jobwalk' },
  { name: 'Comments', value: 'comment' },
  { name: 'Tasks', value: 'task' },
];

/**
 * Activity feed action bar
 * @param {Object} filters
 * @param {Object} setFilters
 * @returns
 */
const ActivityFeedActionBar = ({ filters, setFilters }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSelectType = (value) => {
    setFilters((prevState) => {
      return { ...prevState, type: value };
    });
    setSearchParams((prevParams) => {
      value ? prevParams.set('type', value) : prevParams.delete('type');
      return prevParams;
    });
  };

  return (
    <Box>
      <Grid container item alignItems="center" style={{ gap: 5 }}>
        <Grid item>
          <FeedTypeMenu
            filterOptions={filterOptions}
            handleSelectType={handleSelectType}
            label="Select feed type"
            selectedType={filters.type}
          />
        </Grid>
        <MenuSeparator />
        <Grid item>
          <UserFilterMenu
            label="Someone's Activity"
            setFilters={setFilters}
            userId={filters?.user}
          />
        </Grid>
        <MenuSeparator />
        <Grid item>
          <ProjectFilterMenu projectIds={filters.project} setFilters={setFilters} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ActivityFeedActionBar;
