import { StyledDialog } from '@blackhyve/common';
import { Alert, Backdrop, Button, CircularProgress, DialogContentText } from '@mui/material';
import useSanitizedObject from 'hooks/useSantizedObject';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateRecipeMutation, useUpdateRecipeMutation } from '../api/recipe.api';
import RecipeForm from './RecipeForm';

const defaultValues = { name: '', type: '', notes: '' };

const RecipeDialog = ({ open, handleClose, recipe }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { workspaceId } = params;
  const form = useForm({ defaultValues, values: useSanitizedObject(recipe) });

  const [createRecipe, { isLoading: isCreatingRecipe, isError: isErrorCreating }] =
    useCreateRecipeMutation();
  const [updateRecipe, { isLoading: isUpdatingRecipe, isError: isErrorUpdating }] =
    useUpdateRecipeMutation();

  const isUpdate = Boolean(recipe);
  const isLoading = isCreatingRecipe || isUpdatingRecipe;
  const isError = isErrorCreating || isErrorUpdating;

  const onSubmit = async (data) => {
    if (isUpdate) {
      updateRecipe({ recipeId: recipe.id, recipe: data })
        .unwrap()
        .then(() => {
          handleClose();
        });
    } else {
      createRecipe({ recipe: data })
        .unwrap()
        .then((payload) => {
          if (payload?.data?.id) {
            navigate(`/workspace/${workspaceId}/recipes/${payload.data.id}`);
          }
          handleClose();
          form.reset({ defaultValues });
        });
    }
  };

  return (
    <StyledDialog
      handleClose={handleClose}
      open={open}
      title={recipe ? 'Update Recipe' : 'Create Recipe'}
      actions={
        <>
          <Button onClick={handleClose}>Close</Button>
          <Button variant="contained" onClick={form.handleSubmit(onSubmit)}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </>
      }
    >
      {isLoading && (
        <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
          <CircularProgress />
        </Backdrop>
      )}
      <DialogContentText>
        Recipes allow commonly used task sequences to be inserted into projects
      </DialogContentText>
      {isError && <Alert severity="error">An error occurred please try again</Alert>}
      <RecipeForm form={form} onSubmit={onSubmit} />
    </StyledDialog>
  );
};

export default RecipeDialog;
