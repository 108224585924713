import { axiosRequestHandler } from '../../../helpers/apiHelper';

export const FetchProjectCompaniesAPI = async (id) => {
  try {
    // return await axiosRequestHandler(
    //   `companies?projects[]=${data.id}&include=tasks,projects&order_by=name`,
    //   'get'
    // );
    return await axiosRequestHandler(
      `projects/${id}/companies?include=tasks_count,contacts&order_by=name`,
      'get'
    );
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

export const FetchCompaniesOfProjectsAPI = async (projectIds) => {
  try {
    return await axiosRequestHandler(
      `companies?projects=${projectIds.join(',')}&include=projects`,
      'get'
    );
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

export const FetchCompaniesAPI = async ({ queryParam }) => {
  try {
    return await axiosRequestHandler(`companies`, 'get');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

export const AddCompanyAPI = async (data) => {
  try {
    return await axiosRequestHandler(`companies`, 'post', data, null, true);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

export const fetchCompaniesAndContactsAPI = async ({ queryParam }) => {
  return await axiosRequestHandler(
    `companies?order_by=name,desc&include=contacts${queryParam ? queryParam : ''}`,
    'get'
  );
};

export const UpdateCompanyAPI = async (formData) => {
  try {
    return await axiosRequestHandler(
      `companies/${formData.get('id')}`,
      'post',
      formData,
      null,
      true
    );
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

export const AttachCompanyToProjectAPI = async (data) => {
  try {
    return await axiosRequestHandler(`projects/${data.projectId}/companies`, 'post', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

export const DetachCompanyFromProjectAPI = async (data) => {
  try {
    let response = await axiosRequestHandler(
      `projects/${data.projectId}/companies/detach`,
      'delete',
      data
    );
    return response;
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Toggle company report
 * @param {number} projectId
 * @param {number} companyId
 * @returns {Promise}
 */
export const toggleCompanyReportAPI = async ({ projectId, companyId }) => {
  try {
    return await axiosRequestHandler(
      `projects/${projectId}/companies/${companyId}/toggle_send_report`,
      'post'
    );
  } catch {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Get company details
 * @param {Number} id
 * @returns
 */
export const fetchCompanyAPI = async (id) => {
  try {
    return await axiosRequestHandler(`companies/${id}`, 'get');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

;

/**
 * Upload company image
 */
export const UploadCompanyImageAPI = async (formData) => {
  try {
    return await axiosRequestHandler(
      `companies/${formData.get('id')}/logo`,
      'post',
      formData,
      null,
      true
    );
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Remove company from project
 * @param {number} projectId
 * @param {[number]} companies
 */
export const RemoveCompanyFromProjectAPI = async ({ projectId, companies }) => {
  try {
    return await axiosRequestHandler(`projects/${projectId}/companies`, 'delete', {
      companies,
    });
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Archive company
 * @param {number} id
 */
export const ArchiveCompanyAPI = async (id) => {
  try {
    return await axiosRequestHandler(`companies/${id}`, 'delete');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Restore company
 * @param {number} id
 */
export const RestoreCompanyAPI = async (id) => {
  try {
    return await axiosRequestHandler(`companies/${id}/restore`, 'patch');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Delete company
 * @param {number} id
 */
export const DeleteCompanyAPI = async (id) => {
  try {
    return await axiosRequestHandler(`companies/${id}/force`, 'delete');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};
