import { Button, CircularProgress, Grid, Popover } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { SelectCompaniesList } from 'features/companies/components/SelectCompaniesList';
import { useGetProjectCompaniesQuery, useGetWorkspaceCompanyQuery } from 'features/projectCompanies/api/projectCompanies.api';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  ArrowIcon: {
    fontSize: 18,
  },
  paper: {
    width: 500,
    overflowY: 'auto',
    borderRadius: '8px',
    padding: '10px 10px',
  },
}));

const TaskBulkEditCompanyMenuItem = ({ handleUpdate, projectId }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [selected, setSelected] = useState([]);

  const { data: companies = [], isLoading } = useGetProjectCompaniesQuery({ projectId });
  const { data: company = { users: [] }, isLoading: isLoadingUserCompany } = useGetWorkspaceCompanyQuery({ projectId });

  const filteredCompanies = companies.filter((obj) => obj.id !== company.id)


  const handleSelect = (company, isSelected) => {
    if (isSelected) {
      setSelected(selected.filter((selectedCompany) => selectedCompany.id !== company.id));
    } else {
      setSelected([...selected, company]);
    }
  };

  const handleSubmit = () => {
    handleUpdate(selected);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(undefined);
    setSelected([]);
  };

  return (
    <>
      <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
        Company
        {anchorEl ? (
          <ArrowDropDown className={classes.ArrowIcon} />
        ) : (
          <ArrowDropUp className={classes.ArrowIcon} />
        )}
      </Button>
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          classes={{ paper: classes.paper }}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
        >
          <div style={{ height: '300px' }}>
            {isLoading || isLoadingUserCompany ? (
              <CircularProgress />
            ) : (
              <SelectCompaniesList
                companies={filteredCompanies}
                handleSelect={handleSelect}
                selected={selected}
              />
            )}
          </div>
          <Grid container justifyContent={'flex-end'} spacing={1}>
            <Grid item>
              <Button color={'primary'} size={'small'} onClick={handleSubmit}>
                Close
              </Button>
            </Grid>
            <Grid item>
              <Button color={'primary'} size={'small'} variant="contained" onClick={handleSubmit}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Popover>
      )}
    </>
  );
};

export default TaskBulkEditCompanyMenuItem;
