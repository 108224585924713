import {
  Avatar,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from '@mui/material';
import Box from '@mui/system/Box';
import { Clear } from '@mui/icons-material';
import { Autocomplete } from '@mui/material';
import { cloneElement, forwardRef, useRef } from 'react';
import { GroupedVirtuoso } from 'react-virtuoso';

/**
 * @param {*} props
 * @param {Set} props.selectedContacts
 * @param {Function} props.setSelectedContacts
 * @returns JSX List of contacts with checkboxes
 */
export const SelectContactList = ({ selected, setSelected, disableEdit, isLoading, companies }) => {
  const companyEntities = companies.reduce((result, obj) => {
    result[obj.id] = obj;
    return result;
  }, {});
  const contacts = companies.reduce((result, company) => {
    result.push(...company.users);
    return result;
  }, []);

  return (
    <Box display={'flex'} flexDirection={'column'} height={'100%'}>
      <Autocomplete
        disableClearable
        disableCloseOnSelect
        fullWidth
        multiple
        autoFocus={false}
        disabled={disableEdit}
        getOptionDisabled={(option) => (option.deleted_at ? true : false)}
        getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        groupBy={(option) => companyEntities[option.company_id].name}
        limitTags={0}
        ListboxComponent={GroupByCompanyListBoxComponent}
        loading={isLoading}
        options={contacts}
        renderTags={() => null}
        size="small"
        value={selected}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder="Select Contacts"
            size="small"
            variant="outlined"
          />
        )}
        renderOption={(props, option, { selected }) => {
          return (
            <ListItemButton {...props}>
              <ListItemIcon>
                <Checkbox checked={selected} />
              </ListItemIcon>
              <ListItemText style={option.deleted_at ? { textDecoration: 'line-through' } : {}}>
                {option?.first_name + ' ' + option?.last_name}
              </ListItemText>
            </ListItemButton>
          );
        }}
        onChange={(event, newValue) => {
          if (event.key !== 'Backspace') {
            setSelected(newValue);
          }
        }}
      />
      <Box dense component={List} maxHeight={'25vh'} sx={{ overflowY: 'auto', padding: '5px' }}>
        {selected?.map((user) => {
          const companyName = user.company_name || '';
          return (
            <ListItem disableGutters key={user.id}>
              <ListItemAvatar>
                <Avatar src={user?.profile_image?.thumb_url} />
              </ListItemAvatar>
              <ListItemText
                primary={`${user?.first_name} ${user?.last_name}`}
                secondary={companyName}
              />
              {!disableEdit && (
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => {
                      setSelected(selected.filter((selectedUser) => selectedUser.id !== user.id));
                    }}
                    size="large"
                  >
                    <Clear />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
      </Box>
    </Box>
  );
};

export const GroupByCompanyListBoxComponent = forwardRef((props, ref) => {
  const { children, role, ...other } = props;
  const localRef = useRef(250);

  const itemData = [];
  const groupCount = [];
  children?.forEach((item) => {
    if (item.props?.children) {
      if (item.props.children[1]?.props?.children?.length) {
        itemData.push(...item.props.children[1].props.children);
      }
      groupCount.push(
        item.props?.children[1]?.props?.children?.length
          ? item.props?.children[1]?.props?.children?.length
          : 0
      );
    }
  });

  return (
    <ul
      role={role}
      ref={(reference) => {
        const maxHeight = reference ? getComputedStyle(reference).maxHeight : null;
        if (maxHeight && maxHeight !== localRef.current) {
          localRef.current = maxHeight;
        }
        if (typeof ref === 'function') {
          ref(reference);
        }
      }}
      {...other}
    >
      <GroupedVirtuoso
        groupCounts={groupCount}
        style={{ height: localRef.current }}
        groupContent={(index) => {
          if (children[index]?.props?.children[0]) {
            return cloneElement(children[index]?.props?.children[0], { index });
          }
        }}
        itemContent={(index, groupIndex) => {
          if (itemData[index]) {
            return cloneElement(itemData[index], {
              index,
            });
          }
        }}
      />
    </ul>
  );
});
