import { axiosRequestHandler } from '../helpers/apiHelper';

export const SignIn = async (data) => {
  try {
    let response = await axiosRequestHandler('login', 'post', data);
    return response;
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

export const SignOut = async (data) => {
  try {
    const response = await axiosRequestHandler('logout', 'post');
    return response;
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

export const resetPasswordAPI = async (data) => {
  try {
    let response = await axiosRequestHandler('password/reset', 'post', data);
    return response;
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

export const SendResetPasswordLink = async (data) => {
  try {
    let response = await axiosRequestHandler('password/forgot', 'post', data);
    return response;
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

export const CreateSupportTicketAPI = async (data) => {
  try {
    return await axiosRequestHandler('support', 'post', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Issue with API..!!' });
  }
};

export const getAuthTokenAPI = async (refreshToken) => {
  return await axiosRequestHandler('refresh', 'post', { refresh_token: refreshToken });
};
