import {
  Box,
  Container,
  LinearProgress,
  List,
  ListSubheader,
  Paper,
  Typography,
} from '@mui/material';
import { ContentLayout } from 'components/layouts';
import { times } from 'lodash';
import React, { useRef, useState } from 'react';
import { GroupedVirtuoso } from 'react-virtuoso';
import { useGetMyNotificationsQuery } from '../api/notification.api';
import { Notification, NotificationSkeleton } from '../components/Notification';
import { useSelector } from 'react-redux';
import { selectCurrentUserId } from 'features/auth';

const thirtySecondsInMS = 30000;

const MUIComponents = {
  List: React.forwardRef((props, listRef) => {
    return <Box bgcolor={'inherit'} component={List} {...props} ref={listRef} />;
  }),
  Item: ({ context, ...props }) => {
    return <div {...props} />;
  },
  Group: ({ style, context, ...props }) => {
    return <ListSubheader disableSticky color={'inherit'} {...props} />;
  },
  TopItemList: ({ children }) => children,
  ScrollSeekPlaceholder: () => <NotificationSkeleton />,
};

export const Notifications = () => {
  const scrollRef = useRef(null);
  const [page, setPage] = useState(1);
  const currentUserId = useSelector((state) => selectCurrentUserId(state));

  const {
    data: { data: notifications = [], meta } = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetMyNotificationsQuery({
    page: page,
    userId: currentUserId,
  });

  const lastPage = meta?.last_page;
  const currentPage = meta?.current_page;
  const unreadCount = meta?.unread_count;

  const groups = ['Unread', 'Read'];
  function loadMore(atBottom) {
    if (atBottom && currentPage < lastPage) {
      setPage(currentPage + 1);
    }
  }

  function refresh(atTop) {
    if (atTop) {
      if (page !== 1) {
        setPage(1);
      } else {
        refetch();
      }
    }
  }

  return (
    <ContentLayout>
      <Box height={'100%'} overflow={'auto'} ref={scrollRef}>
        <Box component={Container} marginTop={3}>
          <Box component={Paper} position={'relative'}>
            {isLoading ? (
              <List>
                {times(10).map((value, index) => (
                  <NotificationSkeleton key={index} />
                ))}
              </List>
            ) : (
              <>
                {isFetching && <Box component={LinearProgress} position={'absolute'} width={1} />}
                {notifications.length > 0 ? (
                  <GroupedVirtuoso
                    atBottomStateChange={loadMore}
                    atBottomThreshold={50}
                    atTopStateChange={refresh}
                    atTopThreshold={50}
                    components={MUIComponents}
                    customScrollParent={scrollRef?.current}
                    style={{ height: '100%' }}
                    groupContent={(index) => {
                      return groups[index];
                    }}
                    groupCounts={
                      notifications.length > unreadCount
                        ? [unreadCount, notifications.length - unreadCount]
                        : [notifications.length]
                    }
                    itemContent={(index) => {
                      const notification = notifications?.[index];
                      if (notification) {
                        return <Notification disabled={isFetching} notification={notification} />;
                      }
                    }}
                  />
                ) : (
                  <Box height={1}>
                    <Typography align="center" variant="h5">
                      No Notifications
                    </Typography>
                  </Box>
                )}
                {isFetching && <Box component={LinearProgress} position={'absolute'} width={1} />}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </ContentLayout>
  );
};
