import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { ValueWithLabel } from 'components/ValueChange';
import { differenceInWorkdays, parseDate } from '@blackhyve/utilities/dates';

/**
 * Jobwalk Task Details
 * @param {Object} task
 * @returns
 */
export const JobwalkTaskDetails = ({ task, meta }) => {
  const scheduledStartDate = task.scheduled_start && parseDate(task.scheduled_start);
  const scheduledEndDate = task.scheduled_end && parseDate(task.scheduled_end);
  const forecastedStartDate = task.forecasted_start && parseDate(task.forecasted_start);
  const forecastedEndDate = task.forecasted_end && parseDate(task.forecasted_end);
  const actualStartDate = task.actual_start && parseDate(task.actual_start);
  const actualEndDate = task.actual_end && parseDate(task.actual_end);
  const notes = task?.latest_jobwalk?.notes;

  return (
    <>
      <Typography variant={'subtitle1'}>Task Details:</Typography>
      <Grid container spacing={2}>
        <Grid container item alignItems={'flex-end'} spacing={1}>
          <Grid item xs>
            <ValueWithLabel
              label={'Scheduled Start'}
              value={scheduledStartDate?.toLocaleDateString()}
            />
          </Grid>
          <Grid item xs>
            <ValueWithLabel
              label={'Scheduled End'}
              value={scheduledEndDate?.toLocaleDateString()}
            />
          </Grid>
          <Grid item xs>
            <ValueWithLabel label={'Scheduled Duration'} value={task.duration_days} />
          </Grid>
        </Grid>
        <Grid container item alignItems={'flex-end'} spacing={1}>
          <Grid item xs>
            <ValueWithLabel
              label={actualStartDate ? 'Actual Start' : 'Forecasted Start'}
              value={(actualStartDate ?? forecastedStartDate)?.toLocaleDateString()}
            />
          </Grid>
          <Grid item xs>
            <ValueWithLabel
              label={actualEndDate ? 'Actual End' : 'Forecasted End'}
              value={(actualEndDate ?? forecastedEndDate)?.toLocaleDateString()}
            />
          </Grid>
          <Grid item xs>
            <ValueWithLabel
              label={actualStartDate && actualEndDate ? 'Actual Duration' : 'Forecasted Duration'}
              value={
                (actualStartDate || forecastedStartDate) &&
                (actualEndDate || forecastedEndDate) &&
                differenceInWorkdays(
                  actualStartDate ?? forecastedStartDate,
                  actualEndDate ?? forecastedEndDate,
                  {
                    workdayCalendar: task.workday_calendar,
                    holidays: meta?.holidays
                  }
                )
              }
            />
          </Grid>
        </Grid>
        <Grid item xs>
          <ValueWithLabel label={'Previous Jobwalk Notes'} value={notes} />
        </Grid>
      </Grid>
    </>
  );
};
