import { api, providesList } from 'api';

const projectCompaniesAPI = api.enhanceEndpoints({ addTagTypes: ['Company'] }).injectEndpoints({
  endpoints: (build) => ({
    getProjectCompanyUsers: build.query({
      query: ({ projectId, companyId }) => `/projects/${projectId}/companies/${companyId}/users`,
      providesTags: (result, error, args) => (result ? providesList(result, 'Company') : []),
      transformResponse: (response) => response.data,
    }),
    updateProjectCompanyUser: build.mutation({
      query: ({ projectId, companyId, userId, user }) => ({
        url: `/projects/${projectId}/companies/${companyId}/users/${userId}`,
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: (result, error, args) =>
        result ? [{ type: 'Company', id: args.companyId }] : [],
      transformResponse: (response) => response?.message,
    }),
    deleteProjectCompanyUser: build.mutation({
      query: ({ projectId, companyId, userId }) => ({
        url: `/projects/${projectId}/companies/${companyId}/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { companyId }) =>
        result ? [{ type: 'Company', id: companyId }] : [],
    }),

    addProjectCompanies: build.mutation({
      query: ({ projectId, companies }) => ({
        url: `/projects/${projectId}/companies`,
        method: 'POST',
        body: { companies },
      }),
      invalidatesTags: (result, error, args) =>
        providesList(args.companies, 'Company', 'company_id'),
      transformResponse: (response) => response?.message,
    }),
    getProjectCompanies: build.query({
      query: ({ projectId }) => ({
        url: `/projects/${projectId}/companies`,
        params: { order_by: 'name,asc;created_at,desc' },
      }),
      providesTags: (result, error, args) => (result ? providesList(result, 'Company') : []),
      transformResponse: (response) => response.data,
    }),
    deleteProjectCompany: build.mutation({
      query: ({ projectId, companyId }) => ({
        url: `/projects/${projectId}/companies/${companyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { companyId }) =>
        result ? [{ type: 'Company', id: companyId }] : [],
    }),

    getProjectCompaniesUsersNotOnProject: build.query({
      query: ({ projectId, companyId }) =>
        `projects/${projectId}/companies/${companyId}/available_users`,
      providesTags: (result, error, args) => [{ type: 'Company', id: args.companyId }],
      transformResponse: (response) => response.data,
    }),
    getProjectCompaniesNotOnProject: build.query({
      query: ({ projectId }) => `projects/${projectId}/available_companies`,
      providesTags: (result, error, args) => (result ? providesList(result.data, 'Company') : []),
      transformResponse: (response) => response.data,
    }),

    getWorkspaceCompany: build.query({
      query: ({ projectId }) => `projects/${projectId}/workspace_company`,
      providesTags: (result, error, args) => (result ? [{ type: 'Company', id: result.id }] : []),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetProjectCompaniesQuery,
  useGetProjectCompanyUsersQuery,
  useAddProjectCompaniesMutation,
  useDeleteProjectCompanyUserMutation,
  useUpdateProjectCompanyUserMutation,
  useDeleteProjectCompanyMutation,
  useGetProjectCompaniesNotOnProjectQuery,
  useGetProjectCompaniesUsersNotOnProjectQuery,
  useGetWorkspaceCompanyQuery,
} = projectCompaniesAPI;
