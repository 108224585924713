import { CloudDoneOutlined, ErrorOutline, Sync } from '@mui/icons-material';
import { Alert, Button, IconButton, Stack, Typography } from '@mui/material';
import { Popover } from 'components/Popover';
import { useEffect, useState } from 'react';
import { useTimeout } from 'usehooks-ts';

const StatusPopover = ({ icon, message, severity, action, processChanges, children }) => (
  <Stack alignItems={'center'} direction={'row'}>
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      trigger={<IconButton size={'small'}>{icon}</IconButton>}
    >
      <Alert action={action} icon={icon} severity={severity}>
        {message}
      </Alert>
    </Popover>
    {children}
  </Stack>
);

export const SaveStatus = ({ isSaving, isError, isSuccess, processChanges }) => {
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    if (!isSaving && !isError && isSuccess) {
      setIsSaved(true);
    }
  }, [isSaving, isError, isSuccess]);

  useTimeout(() => setIsSaved(false), isSaved ? 2000 : null);

  return (
    <Stack alignItems={'center'} direction={'row'}>
      {isSaving ? (
        <StatusPopover icon={<Sync />} message="Saving changes..." severity="info">
          <Typography color={'inherit'}>Saving...</Typography>
        </StatusPopover>
      ) : isError ? (
        <StatusPopover
          icon={<ErrorOutline color={'error'} />}
          message={'Error while saving changes'}
          severity="error"
          action={
            <Button color={'inherit'} size={'small'} onClick={processChanges}>
              Retry
            </Button>
          }
        >
          <Typography color={'error'}>Save Error</Typography>
        </StatusPopover>
      ) : (
        <StatusPopover icon={<CloudDoneOutlined />} message="All changes saved" severity="success">
          {isSaved && <Typography>Saved</Typography>}
        </StatusPopover>
      )}
    </Stack>
  );
};

export default SaveStatus;
