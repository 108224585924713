import { ArrowBack, SentimentVeryDissatisfied } from '@mui/icons-material';
import { Button, Container, Link, Paper, Stack, Typography } from '@mui/material';
import { logout } from 'features/auth';
import { BugsnagErrorBoundary } from 'features/bugsnag';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, Link as RouteLink, createBrowserRouter, useNavigate } from 'react-router-dom';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

const Fallback = ({ error, info, clearError }) => {
  const [showErrorDetail, setShowErrorDetail] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleHardReload = () => {
    dispatch(logout());
    navigate('/login', { replace: true });
    clearError();
  };

  const handleGoHome = () => {
    clearError();
    navigate('/', { replace: true });
  };

  return (
    <Container
      sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Stack
        as={Paper}
        spacing={1}
        sx={{
          p: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          minHeight: '50vh',
          boxSizing: 'border-box',
        }}
      >
        <SentimentVeryDissatisfied color="error" sx={{ fontSize: 80 }} />
        <Typography fontSize={24} fontWeight={600} variant="h1">
          Oops!
        </Typography>
        <Typography fontSize={24} variant="h2">
          Something went wrong
        </Typography>
        <Typography>
          Sorry for the inconvenience.The error has been reported. Please try again later.
        </Typography>
        <Link
          component="button"
          sx={{ cursor: 'pointer', color: '#1eb980' }}
          onClick={() => setShowErrorDetail(!showErrorDetail)}
        >
          {showErrorDetail ? 'Less ' : 'More '} Information...
        </Link>
        {showErrorDetail && error && (
          <Paper sx={{ p: 2, bgcolor: 'grey.100' }}>
            <Typography>{error?.message}</Typography>
            <Typography>{info?.componentStack ? info?.componentStack : info}</Typography>
          </Paper>
        )}
        <Button
          component={'a'}
          size={'small'}
          startIcon={<ArrowBack />}
          variant="contained"
          onClick={handleGoHome}
        >
          Back To Homepage
        </Button>
        <Typography>or</Typography>
        <Button
          component={'a'}
          role={'button'}
          size={'small'}
          variant={'contained'}
          onClick={handleHardReload}
        >
          Clear cache and logout
        </Button>
      </Stack>
    </Container>
  );
};

export const appRouter = createBrowserRouter([
  {
    path: '/',
    element: (
      <BugsnagErrorBoundary FallbackComponent={Fallback}>
        <Outlet />
      </BugsnagErrorBoundary>
    ),
    children: [...protectedRoutes, ...publicRoutes],
  },
]);
