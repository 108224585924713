import { StyledDialog } from '@blackhyve/common';
import { Delete, Edit, Redo, Save, Undo } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  Grow,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { ContentLayout } from 'components/layouts';
import { ActionBar } from 'components/navigation/ActionBar';
import { Map } from 'features/map';
import { useDragPolygons } from 'features/polygons/hooks/useDragPolygons';
import useUndoRedo from 'hooks/useUndoRedo';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, useBlocker, useNavigate } from 'react-router-dom';
import { useGetAreasQuery, useSyncAreasMutation } from '../store/area.api';
import { useGetZoneQuery, useUpdateZoneMutation } from '../store/zone.api';
import AddEditLbsDialog from './AddEditLbsDialog';
import { MapImage } from './MapImage';
import { EditContext } from 'components/common/v3/DisplayWithEditAccess';

const defaultPoints = [
  { x: 100, y: 100 },
  { x: 100, y: 200 },
  { x: 200, y: 200 },
  { x: 200, y: 100 },
];

const emptyArray = [];
const size = { x: 400, y: 300 };
export function ZoneMapEditor({ zoneId, locationId, projectId }) {
  const navigate = useNavigate();
  const canEdit = useContext(EditContext);
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: areas = emptyArray,
    isLoading: isLoadingAreas,
    isFetching: isFetchingAreas,
    isError: isErrorAreas,
  } = useGetAreasQuery({ zoneId, locationId });
  const {
    data: zone,
    isLoading: isLoadingZone,
    isFetching: isFetchingZone,
    isError: isErrorZone,
  } = useGetZoneQuery({ zoneId, locationId });

  const isError = isErrorZone || isErrorAreas;

  const [syncAreas, { isLoading: isSyncingAreas }] = useSyncAreasMutation();
  const [updateZone, { isLoading: isUpdatingZone }] = useUpdateZoneMutation();

  const polygons = useMemo(
    () => ({
      zones: zone ? [{ ...zone, points: zone?.points || defaultPoints }] : [],
      areas,
    }),
    [areas, zone]
  );
  const [areaPolygons, setAreaPolygons, { undo, undoStack, redo, redoStack }] =
    useUndoRedo(polygons);

  const isAreasChange = areas !== areaPolygons.areas;
  const isZoneChange = zone?.points !== areaPolygons.zones[0]?.points;
  const isSaveDisabled = !isAreasChange && !isZoneChange;

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !isSaveDisabled && currentLocation.pathname !== nextLocation.pathname && canEdit
  );

  const mappingZone = areaPolygons.areas.length === 0;

  const onChange = (updatedItems) => {
    setAreaPolygons({ ...areaPolygons, [mappingZone ? 'zones' : 'areas']: updatedItems });
  };

  const dragPolygonUtils = useDragPolygons(mappingZone ? areaPolygons.zones : areaPolygons.areas, {
    onChange: onChange,
    size,
    snapDistance: 5,
  });

  const { setSelectedPolygon: setSelectedAreaId, selectedPolygon: selectedAreaId } =
    dragPolygonUtils;

  const selectedArea = areaPolygons.areas.find((area) => area.id === selectedAreaId);

  const unmappedAreas = areaPolygons.areas.filter((area) => !area.points);

  const handleUpdate = (updatedArea) => {
    setAreaPolygons({
      ...areaPolygons,
      areas: areaPolygons.areas.map((area) =>
        area.id === updatedArea.id ? Object.assign({ ...area }, updatedArea) : area
      ),
    });
  };

  const handleCreate = (area) => {
    area.points = defaultPoints;
    area.id = +new Date();
    setAreaPolygons({ ...areaPolygons, areas: [...areaPolygons.areas, area] });
  };
  const handleDelete = (areaId) => {
    setAreaPolygons({
      ...areaPolygons,
      areas: areaPolygons.areas.filter((area) => area.id !== areaId),
    });
  };

  const handleSave = async () => {
    let success = true;
    if (isZoneChange) {
      await updateZone({ zoneId, zone: areaPolygons.zones[0] })
        .unwrap()
        .then()
        .catch((e) => {
          success = false;
        });
    }
    if (isAreasChange) {
      await syncAreas({ zoneId, locationId, projectId, areas: areaPolygons.areas })
        .unwrap()
        .then()
        .catch((e) => {
          success = false;
        });
    }
    if (!success) {
      enqueueSnackbar({ variant: 'error', message: 'Save failed please try again' });
    }
    return success;
  };

  useEffect(() => {
    if (isError) {
      enqueueSnackbar({ variant: 'error', message: 'Zone not found' });
      navigate('..', { replace: true });
    }
  }, [enqueueSnackbar, isError, navigate]);

  return (
    <>
      <ContentLayout
        header={
          canEdit ? (
            <ZoneMapActionBar
              disableRedo={redoStack.length === 0}
              disableSave={isSaveDisabled}
              disableUndo={undoStack.length === 0}
              handleRedo={redo}
              handleSave={handleSave}
              handleUndo={undo}
              isSaving={isSyncingAreas || isUpdatingZone}
            />
          ) : undefined
        }
      >
        <Box height={'100%'}>
          {!isLoadingAreas && !isLoadingZone && !isError && (
            <Map
              disabled={!canEdit}
              PolygonEditorProps={dragPolygonUtils}
              background={
                <MapImage
                  alt={`${zone.name}`}
                  backgroundColor={'#fff'}
                  maxWidth={'90vh'}
                  src={zone?.map?.original_url}
                  width={'70vw'}
                />
              }
            />
          )}
          {canEdit && (
            <AreaActions
              handleCreate={handleCreate}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              selectedArea={selectedArea}
              unmappedAreas={unmappedAreas}
              zoneId={zoneId}
            />
          )}
        </Box>
      </ContentLayout>
      <Backdrop
        mountOnEnter
        unmountOnExit
        open={isSyncingAreas || isFetchingAreas || isUpdatingZone || isFetchingZone}
        sx={{ zIndex: 3 }}
      >
        <Stack alignItems={'center'} color={'#fff'}>
          <CircularProgress />
          <Typography fontSize={'1.5em'} textAlign={'center'}>
            {(isSyncingAreas || isUpdatingZone) && 'Saving'}
            {(isLoadingAreas || isLoadingZone) && 'Loading'}
            {((isFetchingAreas && !isLoadingAreas) || (isFetchingZone && !isLoadingZone)) &&
              'Fetching'}
          </Typography>
        </Stack>
      </Backdrop>
      <StyledDialog
        open={blocker.state === 'blocked'}
        title={'Unsaved Changed'}
        actions={
          <>
            <Button onClick={() => blocker.reset()}>cancel</Button>
            <Button color={'error'} variant={'contained'} onClick={() => blocker.proceed()}>
              Discard
            </Button>
            <LoadingButton
              loading={isSyncingAreas}
              variant={'contained'}
              onClick={async () => {
                const saveSuccess = await handleSave();
                if (saveSuccess) {
                  blocker.proceed();
                } else {
                  blocker.reset();
                }
              }}
            >
              Save
            </LoadingButton>
          </>
        }
      >
        You have unsaved changes. Are you sure you want to leave?
      </StyledDialog>
    </>
  );
}

function AreaActions({ handleUpdate, selectedArea, unmappedAreas, handleCreate, handleDelete }) {
  return (
    <Stack
      alignItems={'flex-start'}
      direction={'row'}
      left={'16px'}
      maxHeight={'calc(100% - 32px)'}
      position={'absolute'}
      spacing={2}
      top={'16px'}
    >
      <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
        <Stack height={'100%'}>
          <CreateArea handleCreate={handleCreate} />
          <Box height={'100%'} maxHeight={'100%'} overflow={'auto'}>
            <AddArea handleUpdate={handleUpdate} unmappedAreas={unmappedAreas} />
          </Box>
        </Stack>
      </Paper>
      <EditArea area={selectedArea} handleUpdate={handleUpdate} />
      <DeleteArea areaId={selectedArea?.id} handleDelete={handleDelete} />
    </Stack>
  );
}

function DeleteArea({ areaId, handleDelete }) {
  return (
    <Grow in={Boolean(areaId)}>
      <Button
        color={'error'}
        endIcon={<Delete />}
        variant={'contained'}
        onClick={() => handleDelete(areaId)}
      >
        Delete
      </Button>
    </Grow>
  );
}

function AddArea({ unmappedAreas, handleUpdate }) {
  const handleAdd = (area) => {
    handleUpdate({ ...area, points: defaultPoints });
  };
  return (
    <Collapse in={unmappedAreas.length > 0}>
      <List subheader={<ListSubheader>Unmapped Areas</ListSubheader>}>
        {unmappedAreas &&
          unmappedAreas.map((area) => (
            <ListItemButton key={area.id} onClick={() => handleAdd(area)}>
              <ListItemText primary={area.name} />
            </ListItemButton>
          ))}
      </List>
    </Collapse>
  );
}

function CreateArea({ handleCreate }) {
  const [isAddAreaOpen, setIsAddAreaOpen] = useState(false);
  return (
    <>
      <Button
        disableElevation
        fullWidth
        variant={'contained'}
        onClick={() => setIsAddAreaOpen(true)}
      >
        Create Area
      </Button>
      {isAddAreaOpen && (
        <AddEditLbsDialog
          handleClose={() => setIsAddAreaOpen(false)}
          open={isAddAreaOpen}
          type={'Area'}
          handleSave={(area) => {
            handleCreate(area);
            setIsAddAreaOpen(false);
          }}
        />
      )}
    </>
  );
}

function EditArea({ handleUpdate, area }) {
  const [isEditAreaOpen, setIsEditAreaOpen] = useState(false);
  return (
    <>
      <Grow in={Boolean(area)}>
        <Button endIcon={<Edit />} variant={'contained'} onClick={() => setIsEditAreaOpen(true)}>
          Edit
        </Button>
      </Grow>
      <AddEditLbsDialog
        entityObj={area}
        handleClose={() => setIsEditAreaOpen(false)}
        open={isEditAreaOpen}
        type={'Area'}
        handleSave={(area) => {
          handleUpdate(area);
          setIsEditAreaOpen(false);
        }}
      />
    </>
  );
}

function ZoneMapActionBar({
  isSaving,
  handleSave,
  handleUndo,
  handleRedo,
  disableUndo,
  disableRedo,
  disableSave,
}) {
  return (
    <ActionBar py={1}>
      <Grid container direction={'row'} justifyContent={'space-between'}>
        <Grid item></Grid>
        <Grid item>
          <Stack direction={'row'} spacing={1}>
            <span>
              <Tooltip arrow placement="top" title={'Undo'}>
                <span>
                  <IconButton color={'primary'} disabled={disableUndo} onClick={handleUndo}>
                    <Undo />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip arrow placement={'top'} title={'Redo'}>
                <span>
                  <IconButton color={'primary'} disabled={disableRedo} onClick={handleRedo}>
                    <Redo />
                  </IconButton>
                </span>
              </Tooltip>
            </span>
            <Tooltip arrow placement={'top'} title={'Save'}>
              <span>
                <LoadingButton
                  disabled={disableSave}
                  endIcon={<Save />}
                  loading={isSaving}
                  variant={'contained'}
                  onClick={handleSave}
                >
                  Save
                </LoadingButton>
              </span>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </ActionBar>
  );
}
