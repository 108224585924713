import { Box } from '@mui/material';
import { forwardRef } from 'react';
import { Polygon } from './Polygon';

export const PolygonViewer = forwardRef(function PolygonViewer(
  { background, polygons, children, PolygonProps, ...props },
  ref
) {
  return (
    <>
      <Box
        as={'svg'}
        ref={ref}
        sx={{ position: 'absolute', left: 0 }}
        viewBox={`0 0 400 300`}
        width={'100%'}
        {...props}
      >
        {polygons &&
          polygons.map((polygon) => (
            <Polygon
              color={polygon.color}
              id={polygon.id}
              key={polygon.id}
              labels={polygon?.labels}
              name={polygon.name}
              points={polygon.points}
              {...PolygonProps}
            />
          ))}
        {children}
      </Box>
      {background}
    </>
  );
});
