import { api, providesList } from 'api';
import { BUDGET_LINE_KEY } from './budget-lines.api';

export const CONTRACT_LINE_KEY = 'ContractLine';

const contractLinesAPI = api
  .enhanceEndpoints({ addTagTypes: [CONTRACT_LINE_KEY] })
  .injectEndpoints({
    endpoints: (build) => ({
      getContractLinesByContract: build.query({
        query: ({ contractId }) => `/contracts/${contractId}/contract-lines`,
        providesTags: (result, error, args) =>
          result ? providesList(result, CONTRACT_LINE_KEY) : [],
        transformResponse: (response) => response.data,
      }),
      createContractLine: build.mutation({
        query: ({ contractId, ...body }) => ({
          url: `/contracts/${contractId}/contract-lines`,
          method: 'POST',
          body: body,
        }),
        invalidatesTags: (result, _error, params) =>
          result
            ? [
                { type: CONTRACT_LINE_KEY, id: 'LIST' },
                { type: BUDGET_LINE_KEY },
                { type: 'Contract', id: 'LIST' },
                { type: 'Budget' },
                { type: 'BudgetForecast' },
                { type: 'ProjectForecast' },
              ]
            : [],
      }),
      updateContractLine: build.mutation({
        query: ({ contractLineId, ...body }) => ({
          url: `/contract-lines/${contractLineId}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: (result, _error, params) =>
          result
            ? [
                { type: CONTRACT_LINE_KEY, id: 'LIST' },
                { type: CONTRACT_LINE_KEY, id: params.id },
                { type: BUDGET_LINE_KEY },
                { type: 'Contract', id: 'LIST' },
                { type: 'Budget' },
                { type: 'BudgetForecast' },
                { type: 'ProjectForecast' },
              ]
            : [],
      }),
      deleteContractLine: build.mutation({
        query: ({ line }) => ({
          url: `/contract-lines/${line.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_result, error, params) =>
          error
            ? []
            : [
                { type: CONTRACT_LINE_KEY, id: 'LIST' },
                { type: BUDGET_LINE_KEY },
                { type: 'Contract', id: 'LIST' },
                { type: 'Budget' },
                { type: 'BudgetForecast' },
                { type: 'ProjectForecast' },
              ],
      }),
    }),
  });

export const {
  useGetContractLinesByContractQuery,
  useCreateContractLineMutation,
  useUpdateContractLineMutation,
  useDeleteContractLineMutation,
} = contractLinesAPI;
