import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import JobwalkRow from './JobwalkRow';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';

const ListContainer = styled(Grid)(() => ({
  // maxHeight: 'calc(100vh - 180px)',
  overflowY: 'auto',
  width: '100%',
}));

/**
 * Jobwalk List Page
 * @param {Array} list
 * @param {String} currentPage
 * @param {String} lastPage
 * @param {Object} setFilters
 * @param {Boolean} isFetching
 * @returns
 */
const JobwalkList = ({ list, currentPage, lastPage, setFilters, isFetching, meta }) => {
  const handlePaginationChange = (event, page) => {
    setFilters((prev) => ({ ...prev, page }));
  };

  return list?.length ? (
    <>
      <ListContainer>
        {list.map((task) => (
          <JobwalkRow key={task.id} task={task} meta={meta} />
        ))}
      </ListContainer>

      <Paper square sx={{ py: 1, borderTop: '1px solid', color: 'grey.300' }}>
        <Pagination
          color="primary"
          count={lastPage}
          disabled={isFetching}
          page={currentPage}
          shape="rounded"
          onChange={handlePaginationChange}
        />
      </Paper>
    </>
  ) : (
    <Alert severity="info" sx={{ margin: '16px' }}>
      There are no tasks scheduled for Jobwalk
    </Alert>
  );
};

export default JobwalkList;
