import {
  TableContainer,
  Button,
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  ListItem,
  List,
  ListItemAvatar,
  CircularProgress,
} from '@mui/material';
import { useEffect } from 'react';
import { ContentLayout } from 'components/layouts/ContentLayout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import BudgetsShowContracts from './BudgetsShowContracts';
import { useGetBudgetByIdQuery } from '../store/budget.api';
import { useGetUsersWithAccessQuery } from '../store/budget.api';
import {
  useDeleteBudgetLineMutation,
  useGetBudgetLinesByBudgetQuery,
} from '../store/budget-lines.api';
import { formatMoney, formatPercent } from './NumericControls';
import { BudgetFormDialog } from './BudgetFormDialog';

import Breadcrumbs from 'components/Breadcrumbs';
import { BudgetLineFormDialog } from './BudgetLineFormDialog';
import { CurveOptions } from '../models/curves';
import { SummaryCard, SummaryItem } from './BudgetSummary';
import { useGetBudgetForecastQuery } from '../store/forecast.api';
import Forecast from './Forecast';
import { useGetProjectQuery } from 'features/projects/store/project.api';
import { Delete, MoreHoriz, Edit, DatasetLinkedOutlined, Timeline } from '@mui/icons-material';
import { ConfirmDeleteDialog, OptionsMenu, StyledDialog } from '@blackhyve/common';
import BudgetLineDeleteDialog from './BudgetLineDeleteDialog';
import BudgetLineContractsDialog from './BudgetLineContractsDialog';
import AvatarComponent from 'components/common/v3/AvatarComponent';
import BudgetUsers from './BudgetUsers';
import { useSnackbar } from 'notistack';

export const MenuOptions = ({ budgetLine, viewOnForecast, onEdit, onDelete }) => {
  return (
    <>
      <OptionsMenu
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        triggerButton={
          <IconButton size="small">
            <MoreHoriz />
          </IconButton>
        }
      >
        {(handleClose) => {
          return (
            <>
              <ListItemButton
                dense
                onClick={() => {
                  handleClose();
                  viewOnForecast(budgetLine);
                }}
              >
                <ListItemIcon>
                  <Timeline />
                </ListItemIcon>
                <ListItemText>View Forecast</ListItemText>
              </ListItemButton>

              <ListItemButton
                dense
                onClick={() => {
                  handleClose();
                  onEdit(budgetLine);
                }}
              >
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </ListItemButton>
              <ListItemButton
                dense
                onClick={() => {
                  handleClose();
                  onDelete(budgetLine);
                }}
              >
                <ListItemIcon style={{ color: 'red' }}>
                  <Delete color={'red'} />
                </ListItemIcon>
                <ListItemText style={{ color: 'red' }}>Delete</ListItemText>
              </ListItemButton>
            </>
          );
        }}
      </OptionsMenu>
    </>
  );
};

const ShowUsersWithAccess = ({ users }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Grid container alignItems={'center'} gap={0.5}>
        <Typography sx={{ fontWeight: 'bold' }}>Users with access: </Typography>
        {users?.map((user) => {
          return (
            <AvatarComponent
              key={user.id}
              name={user.name}
              sx={{ width: 24, height: 24, fontSize: '12px' }}
            ></AvatarComponent>
          );
        })}
        <Button variant="outlined" onClick={handleOpenDialog}>
          Edit
        </Button>
      </Grid>
      <StyledDialog handleClose={handleClose} open={openDialog} title={'Users with access'}>
        <List>
          {users?.map((user) => {
            return (
              <ListItem key={user.id}>
                <ListItemAvatar>
                  <AvatarComponent name={user?.name}></AvatarComponent>
                </ListItemAvatar>
                <ListItemText primary={user?.name}></ListItemText>
                <ListItemButton
                  onClick={() => {
                    setConfirmDeleteDialogOpen(true);
                  }}
                >
                  <ListItemText>Remove Access</ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </StyledDialog>
    </>
  );
};

const LinkedContractsCell = ({ budgetLine, onClick }) => {
  const contractCount = budgetLine.contracts.length;

  if (contractCount === 0) {
    return (
      <TableCell sx={{ whiteSpace: 'nowrap', fontStyle: 'italic' }}>No linked contracts</TableCell>
    );
  }
  return (
    <TableCell onClick={(event) => event.stopPropagation()}>
      <Button startIcon={<DatasetLinkedOutlined />} variant="text" onClick={onClick}>
        {contractCount}
      </Button>
    </TableCell>
  );
};

const BudgetLinesList = ({ rows, onDelete, onEdit, viewOnForecast }) => {
  const [budgetLineContractDialog, setBudgetLineContractDialog] = useState({
    show: false,
    budgetLine: null,
  });

  return (
    <>
      <BudgetLineContractsDialog
        budgetLine={budgetLineContractDialog.budgetLine}
        handleClose={() => setBudgetLineContractDialog({ show: false, budgetLine: null })}
        open={budgetLineContractDialog.show}
      />

      <TableContainer>
        <Table rows={rows} stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Division</TableCell>
              <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Cost Code</TableCell>
              <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Description</TableCell>
              <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Trade</TableCell>
              <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Contracts</TableCell>
              <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Estimated</TableCell>
              <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                Estimated Labor
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                Estimated Material
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Labor Curve</TableCell>
              <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                Material Curve
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                Scheduled Amount
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                Scheduled Labor
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                Scheduled Material
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                Invoiced Amount
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                Balance to Finish
              </TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((item) => (
              <TableRow key={item.id} sx={{ cursor: 'pointer' }} onClick={() => onEdit(item)}>
                <TableCell>{item.division}</TableCell>
                <TableCell>{item.cost_code}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.description}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.trade?.name}</TableCell>
                <LinkedContractsCell
                  budgetLine={item}
                  onClick={() => setBudgetLineContractDialog({ show: true, budgetLine: item })}
                />
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  ${formatMoney(item.amount)} ({formatPercent(item.percent)}%)
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  ${formatMoney(item.labor_amount)} ({formatPercent(item.labor_percent)}%)
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  ${formatMoney(item.material_amount)} ({formatPercent(item.material_percent)}%)
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  {CurveOptions.find((curve) => curve.id === item.labor_curve).name}
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  {CurveOptions.find((curve) => curve.id === item.material_curve).name}
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  ${formatMoney(item.contracted_summary.amount)} (
                  {formatPercent(item.contracted_summary.percent)}%)
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  ${formatMoney(item.contracted_summary.labor_amount)} (
                  {formatPercent(item.contracted_summary.labor_percent)}%)
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  ${formatMoney(item.contracted_summary.material_amount)} (
                  {formatPercent(item.contracted_summary.material_percent)}%)
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  ${formatMoney(item.invoiced_summary.amount)} (
                  {formatPercent(item.invoiced_summary.percent)}%)
                </TableCell>
                <TableCell>${formatMoney(item.invoiced_summary.balance_to_finish)}</TableCell>
                <TableCell onClick={(event) => event.stopPropagation()}>
                  <MenuOptions
                    budgetLine={item}
                    viewOnForecast={() => viewOnForecast(item)}
                    onDelete={() => onDelete(item)}
                    onEdit={() => onEdit(item)}
                  />
                </TableCell>
              </TableRow>
            ))}
            {rows.length === 0 && (
              <TableRow key="empty-state">
                <TableCell colSpan={16} sx={{ textAlign: 'center' }}>
                  No Data
                </TableCell>
              </TableRow>
            )}
            {rows.length > 0 && (
              <TableRow
                key="budget-lines-summary"
                sx={{ backgroundColor: '#18181b', color: 'white' }}
              >
                <TableCell colSpan={5} sx={{ color: 'white', textAlign: 'right' }}>
                  Totals
                </TableCell>
                <TableCell sx={{ color: 'white' }}>
                  ${formatMoney(rows.reduce((acc, item) => acc + item.amount, 0))}
                </TableCell>
                <TableCell sx={{ color: 'white' }}>
                  ${formatMoney(rows.reduce((acc, item) => acc + item.labor_amount, 0))}
                </TableCell>
                <TableCell sx={{ color: 'white' }}>
                  ${formatMoney(rows.reduce((acc, item) => acc + item.material_amount, 0))}
                </TableCell>
                <TableCell colSpan={2}>&nbsp;</TableCell>
                <TableCell sx={{ color: 'white' }}>
                  $
                  {formatMoney(rows.reduce((acc, item) => acc + item.contracted_summary.amount, 0))}
                </TableCell>
                <TableCell sx={{ color: 'white' }}>
                  $
                  {formatMoney(
                    rows.reduce((acc, item) => acc + item.contracted_summary.labor_amount, 0)
                  )}
                </TableCell>
                <TableCell sx={{ color: 'white' }}>
                  $
                  {formatMoney(
                    rows.reduce((acc, item) => acc + item.contracted_summary.material_amount, 0)
                  )}
                </TableCell>
                <TableCell sx={{ color: 'white' }}>
                  ${formatMoney(rows.reduce((acc, item) => acc + item.invoiced_summary.amount, 0))}
                </TableCell>
                <TableCell sx={{ color: 'white' }}>
                  $
                  {formatMoney(
                    rows.reduce((acc, item) => acc + item.invoiced_summary.balance_to_finish, 0)
                  )}
                </TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const BudgetsShow = () => {
  const [dialog, setDialog] = useState(false);
  const [budgetDialog, setBudgetDialog] = useState(false);
  const [editBudgetLineDialog, setEditBudgetLineDialog] = useState({ show: false, line: null });
  const [deleteBudgetLineDialog, setDeleteBudgetLineDialog] = useState({ show: false, line: null });
  const [deleteBudgetLine, { isLoading: isDeletingBudgetLine }] = useDeleteBudgetLineMutation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { workspaceId, projectId, budgetId } = useParams();

  const { data: project } = useGetProjectQuery(+projectId);
  const { data: budget, isLoading, isError } = useGetBudgetByIdQuery({ budgetId: +budgetId });
  const { data: lines = [] } = useGetBudgetLinesByBudgetQuery({ budgetId });
  const { data: forecast } = useGetBudgetForecastQuery({
    budgetId: +budgetId,
  });

  useEffect(() => {
    if (isError) {
      enqueueSnackbar({ variant: 'error', message: 'Access prohibited.' });
      navigate('/', { replace: true });
    }
  }, [isError, navigate, enqueueSnackbar]);


  return (
    isLoading ? <CircularProgress className='loading' /> :
      !isError &&
      <ContentLayout>
        <Container maxWidth={'xl'}>
          <Grid container item alignItems="center">
            <Box pt={2}>
              <Typography variant={'h4'}>
                <Breadcrumbs
                  links={[
                    {
                      to: `/workspace/${workspaceId}/projects/${projectId}/budgets`,
                      text: 'Budgets',
                    },
                    { text: budget?.name },
                  ]}
                />
              </Typography>
            </Box>
            <Box ml={'auto'} />
            <Box>
              <Button size="small" variant="text" onClick={() => setBudgetDialog(true)}>
                Edit
              </Button>

              <BudgetFormDialog
                handleClose={() => setBudgetDialog(false)}
                open={budgetDialog}
                projectId={projectId}
                budget={{
                  ...budget,
                  amount: formatMoney(budget.amount),
                  material_amount: formatMoney(budget.material_amount),
                  labor_amount: formatMoney(budget.labor_amount),
                }}
              />
            </Box>
          </Grid>
          <BudgetUsers
            budgetId={budget?.id}
            projectId={projectId}
          />
          <Box pt={2}>
            <Grid
              container
              item
              sx={{ display: 'grid', gap: '2em', gridTemplateColumns: 'repeat(4, minmax(0, 1fr));' }}
              xs={12}
            >
              <SummaryCard item title="Estimated">
                <SummaryItem label="Amount">
                  ${formatMoney(budget.summaries.estimated.amount)}
                </SummaryItem>
                <SummaryItem label="Labor">
                  <span className="summary-card--value__muted">
                    ({formatPercent(budget.summaries.estimated.labor_percent)}%)
                  </span>{' '}
                  <span>${formatMoney(budget.summaries.estimated.labor_amount)}</span>
                </SummaryItem>
                <SummaryItem label="Material">
                  <span className="summary-card--value__muted">
                    ({formatPercent(budget.summaries.estimated.material_percent)}%)
                  </span>{' '}
                  <span>${formatMoney(budget.summaries.estimated.material_amount)}</span>
                </SummaryItem>
              </SummaryCard>

              <SummaryCard item title="Budgeted">
                <SummaryItem label="Amount">
                  ${formatMoney(budget.summaries.forecasted.amount)}
                </SummaryItem>
                <SummaryItem label="Labor">
                  <span className="summary-card--value__muted">
                    ({formatPercent(budget.summaries.forecasted.labor_percent)}%)
                  </span>{' '}
                  <span>${formatMoney(budget.summaries.forecasted.labor_amount)}</span>
                </SummaryItem>
                <SummaryItem label="Material">
                  <span className="summary-card--value__muted">
                    ({formatPercent(budget.summaries.forecasted.material_percent)}%)
                  </span>{' '}
                  <span>${formatMoney(budget.summaries.forecasted.material_amount)}</span>
                </SummaryItem>
              </SummaryCard>

              <SummaryCard item title="Contracted">
                <SummaryItem label="Amount">
                  ${formatMoney(budget.summaries.contracted.amount)}
                </SummaryItem>
                <SummaryItem label="Labor">
                  <span className="summary-card--value__muted">
                    ({formatPercent(budget.summaries.contracted.labor_percent)}%)
                  </span>{' '}
                  <span>${formatMoney(budget.summaries.contracted.labor_amount)}</span>
                </SummaryItem>
                <SummaryItem label="Material">
                  <span className="summary-card--value__muted">
                    ({formatPercent(budget.summaries.contracted.material_percent)}%)
                  </span>{' '}
                  <span>${formatMoney(budget.summaries.contracted.material_amount)}</span>
                </SummaryItem>
              </SummaryCard>

              <SummaryCard item title="Invoiced">
                <SummaryItem label="Amount">
                  ${formatMoney(budget.summaries.invoiced.amount)}
                </SummaryItem>
              </SummaryCard>
            </Grid>
          </Box>

          <Box pt={6} style={{ position: 'relative' }}>
            <Toolbar style={{ position: 'absolute', right: '0px' }}>
              <Grid container item>
                <Box ml={'auto'} />

                <Button
                  LinkComponent={Link}
                  to={`/workspace/${workspaceId}/projects/${projectId}/budgets/${budgetId}/forecast`}
                >
                  View Forecasts
                </Button>
              </Grid>
            </Toolbar>

            {forecast && project && (
              <Forecast forecast={forecast} project={project} showFilters={false} showTable={false} />
            )}
          </Box>

          <Box pt={6}>
            <Typography variant={'h5'}>Schedule of values</Typography>
            <Paper elevation={2}>
              <Toolbar style={{ position: 'sticky', left: '0px' }}>
                <Grid container item>
                  <Box ml={'auto'} />

                  <Button size="small" variant="text" onClick={() => setDialog(true)}>
                    New Budget Line
                  </Button>

                  <BudgetLineFormDialog
                    budget={budget}
                    creating={true}
                    handleClose={() => setDialog(false)}
                    open={dialog}
                  />
                </Grid>
              </Toolbar>

              <BudgetLinesList
                rows={lines}
                viewOnForecast={(line) => {
                  navigate(
                    `/workspace/${workspaceId}/projects/${projectId}/budgets/${budgetId}/forecast`,
                    {
                      state: { budgetLines: [line.id] },
                    }
                  );
                }}
                onDelete={(line) => {
                  setDeleteBudgetLineDialog({ show: true, line });
                }}
                onEdit={(line) => {
                  setEditBudgetLineDialog({ show: true, line });
                }}
              />

              <BudgetLineFormDialog
                budget={budget}
                handleClose={() => setEditBudgetLineDialog({ show: false, line: null })}
                open={editBudgetLineDialog.show}
                line={{
                  ...editBudgetLineDialog.line,
                  amount: formatMoney(editBudgetLineDialog.line?.amount),
                  material_amount: formatMoney(editBudgetLineDialog.line?.material_amount),
                  labor_amount: formatMoney(editBudgetLineDialog.line?.labor_amount),
                }}
              />

              <BudgetLineDeleteDialog
                handleClose={() => setDeleteBudgetLineDialog({ show: false, line: null })}
                line={deleteBudgetLineDialog.line}
                loading={isDeletingBudgetLine}
                open={deleteBudgetLineDialog.show}
                handleDelete={async () => {
                  await deleteBudgetLine({ budgetLine: deleteBudgetLineDialog.line })
                    .unwrap()
                    .then(() => {
                      setDeleteBudgetLineDialog({ show: false, line: null });
                    });
                }}
              />
            </Paper>
          </Box>
          <Box pb={6} pt={6}>
            <BudgetsShowContracts
              budgetId={budgetId}
              projectId={projectId}
              workspaceId={workspaceId}
            />
          </Box>
        </Container>
      </ContentLayout>
  );
};

export default BudgetsShow;
