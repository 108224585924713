import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';

export const initProjectGanttSettings = (context, ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  gantt.plugins({
    marker: true,
    keyboard_navigation: true,
    grouping: true,
    auto_scheduling: false,
    click_drag: false,
  });

  gantt.config = {
    ...gantt.config,
    auto_scheduling: false,
    auto_types: false,
    date_format: '%Y-%m-%d',
    date_grid: '%m-%d-%Y',

    start_date: undefined,
    end_date: undefined,
    project_start: undefined,

    work_time: false,
    correct_work_time: false,

    smart_scales: true,
    smart_rendering: true,
    static_background: true,
    static_background_cells: true,

    show_tasks_outside_timescale: true,
    fit_tasks: true,

    scale_height: 35,
    task_height: 16,
    row_height: 35,
    bar_height: 16,

    min_duration: 24 * 60 * 60 * 1000, // (1 day)

    placeholder_task: false,

    readonly: true,

    branch_loading: true,

    grid_elastic_columns: true,

    links: {
      finish_to_start: 'FS',
      start_to_start: 'SS',
      finish_to_finish: 'FF',
      start_to_finish: 'SF',
    },

    sort: true,
  };

  gantt.templates.task_end_date = function (date) {
    return gantt.templates.task_date(new Date(date.valueOf() - 1));
  };
};
