import { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetAreaQuery, useGetAreasQuery } from 'features/locations/store/area.api';
import { useDeleteTasksMutation, useGetTasksByAreaQuery } from 'features/tasks/store/task.api';
import ProductionBoardCreateTaskDialog from './ProductionBoardCreateTaskDialog';
import { ProductionBoardTaskRow, ProductionBoardSkeletonList } from './ProductionBoardRow';

/**
 * Production Board Area
 * @returns 
 */
const ProductionBoardArea = () => {

  const workspaceId = Number(useParams().workspaceId) || undefined;
  const projectId = Number(useParams().projectId) || undefined;
  const locationId = Number(useParams().locationId) || undefined;
  const phaseId = Number(useParams().phaseId) || undefined;
  const zoneId = Number(useParams().zoneId) || undefined;
  const areaId = Number(useParams().areaId) || undefined;
  const [openCreateTaskDialog, setOpenCreateTaskDialog] = useState(false);

  // const {
  //   data: area = {},
  //   isLoading
  // } = useGetAreaQuery({ zoneId, locationId, areaId });

  const { area, isLoading } = useGetAreasQuery({ zoneId }, {
    selectFromResult: ({ data: areas, isLoading }) => ({
      area: areas?.find((area) => area.id === areaId),
      isLoading
    }),
  });

  const { data: tasks = [], isLoading: isLoadingTasks, isFetching: isFetchingTasks } =
    useGetTasksByAreaQuery({ projectId, areaId })

  const handleOpenCreateTaskDialog = () => setOpenCreateTaskDialog(true);
  const handleCloseCreateTaskDialog = () => setOpenCreateTaskDialog(false);


  return isLoading ? <CircularProgress className='loading' /> :
    <Container maxWidth={'xl'}>
      <Grid container gap={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }} align="center">{area?.name}</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button variant="contained" onClick={handleOpenCreateTaskDialog}>+ New Task</Button>
        </Grid>
        <Grid container item xs={12} gap={2}>
          {(!isLoadingTasks && isFetchingTasks && (
            <Box sx={{ width: '100%', }}>
              <LinearProgress />
            </Box>
          ))}
          {isLoadingTasks ? <ProductionBoardSkeletonList /> :
            tasks?.length ? tasks?.map((task) => {
              const pageLink = `/workspace/${workspaceId}/projects/${projectId}/production-board/locations/${locationId}/phases/${phaseId}/zones/${zoneId}/areas/${areaId}/tasks/${task.id}`
              return <ProductionBoardTaskRow
                key={task?.id}
                task={task}
                areaId={areaId}
                projectId={projectId}
                pageLink={pageLink}
                rowProps={{ component: Paper, elevation: 3 }}
              />
            }) : <Alert sx={{ textAlign: 'center', m: 'auto' }} severity="warning">No tasks have been added.</Alert>}
        </Grid>
        {openCreateTaskDialog &&
          <ProductionBoardCreateTaskDialog
            open={openCreateTaskDialog}
            areaId={areaId}
            projectId={projectId}
            handleClose={handleCloseCreateTaskDialog}
          />}
      </Grid>
    </Container>
}

export default ProductionBoardArea;