import { ConfirmDeleteDialog, OptionsMenu, StyledDialog } from '@blackhyve/common';
import { parseDate } from '@blackhyve/utilities/dates';
import { Delete, Edit, MoreHoriz } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TableContainer from '@mui/material/TableContainer';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { api } from 'api';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import useAutoSchedulerJob from 'features/gantt/hooks/useAutoSchedulerJob';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Table } from '../../../components/table/Table';
import {
  useCreateProjectHolidayMutation,
  useDeleteProjectHolidayMutation,
  useFillProjectHolidaysMutation,
  useGetProjectHolidaysQuery,
  useUpdateProjectHolidayMutation,
} from '../store/project.api';
import { useSnackbar } from 'notistack';

const initialState = {
  name: '',
  date: null,
};

/**
 * Project Holiday Component
 * @param {Boolean} open
 * @param {Object} handleClose
 * @returns
 */
const HolidaySettings = ({ handleClose, open, projectId }) => {
  const [openHolidayForm, setOpenHolidayForm] = useState(false);
  const workspaceId = Number(useParams().workspaceId) || undefined;
  const { enqueueSnackbar } = useSnackbar();

  const { projectDetails } = api.endpoints.getProjects.useQueryState(
    { workspaceId },
    {
      selectFromResult: ({ data: projects, isFetching, isLoading }) => {
        return {
          projectDetails: projects?.find((project) => project.id === projectId),
          isFetching,
          isLoading,
        };
      },
    }
  );
  const projectName = projectDetails?.name;

  const [fillProjectHolidays, { isLoading }] = useFillProjectHolidaysMutation();

  const handleFillProjectHolidays = () => {
    fillProjectHolidays(projectId).then((response) => {
      if (response?.data?.message) {
        enqueueSnackbar(response?.data?.message, { variant: 'info' });
      }
    });
  };

  return (
    <StyledDialog
      handleClose={handleClose}
      maxWidth={'md'}
      open={open}
      title={`Manage Holidays Of ${projectName}`}
      sx={{
        '& .MuiPaper-root': { backgroundColor: '#fafafa' },
      }}
    >
      <Stack direction={'row'}>
        {!openHolidayForm && (
          <LoadingButton variant="text" onClick={() => setOpenHolidayForm(true)}>
            + ADD HOLIDAY
          </LoadingButton>
        )}
        <LoadingButton
          loading={isLoading}
          startIcon={<CloudUploadIcon />}
          sx={{ justifyContent: 'flex-end', marginLeft: 'auto' }}
          variant="outlined"
          onClick={handleFillProjectHolidays}
        >
          Import USA Holidays
        </LoadingButton>
      </Stack>
      {openHolidayForm && (
        <AddHolidayForm handleClose={() => setOpenHolidayForm(false)} projectId={projectId} />
      )}
      <HolidayList projectId={projectId} />
    </StyledDialog>
  );
};

const columns = [
  {
    label: 'Holiday Name',
    field: 'name',
    sort: true,
  },
  {
    label: 'Date',
    field: 'date',
    render: ({ date }) => parseDate(date).toLocaleDateString(),
    sort: true,
  },
  {
    field: 'moreOptions',
    cellProps: { align: 'right' },
    render: (holiday, { projectId, onEdit, onDelete }) => (
      <MenuOptions holiday={holiday} projectId={projectId} onDelete={onDelete} onEdit={onEdit} />
    ),
  },
];

/**
 * Holiday list
 * @param {String} projectId
 * @returns
 */
const HolidayList = ({ projectId }) => {
  const {
    data: { listOfHolidays = [], holidays = [] } = {},
    isLoading,
    isFetching,
  } = useGetProjectHolidaysQuery(projectId);
  const runAutoSchedulerJob = useAutoSchedulerJob();
  const [openEditDialog, setOpenEditDialog] = useState({ open: false });
  const [onDelete, { isLoading: isGettingDeleted }] = useDeleteProjectHolidayMutation();

  const handleEdit = (holiday) => setOpenEditDialog({ open: true, holiday });

  const handleDelete = (id) => {
    onDelete(id).then(() => {
      runAutoSchedulerJob(projectId);
    });
  };

  return (
    <>
      {openEditDialog?.open && (
        <AddHolidayForm
          handleClose={() => setOpenEditDialog({ open: false, holiday: null })}
          holiday={openEditDialog?.holiday}
          projectId={projectId}
        />
      )}
      <Paper elevation={3} sx={{ width: '100%', overflow: 'hidden', mt: 3 }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            columns={columns}
            defaultOrderBy={'date'}
            isFetching={isFetching}
            isLoading={isLoading}
            meta={{ projectId, onEdit: handleEdit, onDelete: handleDelete }}
            rowProps={{ backgroundColor: 'white' }}
            rows={listOfHolidays}
            stickyHeader={true}
          />
        </TableContainer>
      </Paper>
    </>
  );
};

/**
 * Menu options
 * @param {Object} holiday
 * @param {Object} onDelete
 * @param {Object} onEdit
 * @returns
 */
const MenuOptions = ({ holiday, onDelete, onEdit }) => {
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

  const handleDelete = () => onDelete(holiday?.id);

  return (
    <>
      <OptionsMenu
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        triggerButton={
          <IconButton size="small" sx={{ p: 0 }}>
            <MoreHoriz fontSize="small" />
          </IconButton>
        }
      >
        {(handleClose) => {
          return (
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  dense
                  onClick={() => {
                    handleClose();
                    onEdit(holiday);
                  }}
                >
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  dense
                  onClick={() => {
                    handleClose();
                    setConfirmDeleteDialogOpen(true);
                  }}
                >
                  <ListItemIcon style={{ color: 'red' }}>
                    <Delete color={'red'} />
                  </ListItemIcon>
                  <ListItemText style={{ color: 'red' }}>Delete</ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          );
        }}
      </OptionsMenu>
      {confirmDeleteDialogOpen && (
        <ConfirmDeleteDialog
          handleClose={() => setConfirmDeleteDialogOpen(false)}
          handleDelete={handleDelete}
          item={'holiday'}
          open={true}
        />
      )}
    </>
  );
};

/**
 * Add holiday form
 * @param {String} projectId
 * @param {Object} handleClose
 * @param {Object} holiday
 * @returns
 */
const AddHolidayForm = ({ holiday, handleClose, projectId }) => {
  const [createHoliday, { isLoading: isCreatingHoliday }] = useCreateProjectHolidayMutation();
  const [updateHoliday, { isLoading: isUpdatingHoliday }] = useUpdateProjectHolidayMutation();
  const runAutoSchedulerJob = useAutoSchedulerJob();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: initialState,
  });

  const handleSave = (data) => {
    const payload = { ...data, date: format(data.date, 'yyyy-MM-dd'), projectId };
    const holidayAction = isEmpty(holiday) ? createHoliday : updateHoliday;
    holidayAction(payload)
      .then(() => reset(initialState))
      .finally(() => handleClose());
    runAutoSchedulerJob(projectId);
  };

  useEffect(() => {
    if (!isEmpty(holiday)) {
      reset({ ...holiday, date: parseDate(holiday.date) });
    }
  }, [holiday, reset]);

  return (
    <Container component={Paper} elevation={3} sx={{ p: 2, mt: 2 }}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Stack gap={1}>
          <Typography sx={{ fontWeight: 'bold' }} variant="h6">
            {isEmpty(holiday) ? 'Add' : 'Edit'} Holiday
          </Typography>

          <FormLabel>Holiday Name</FormLabel>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState: { error, invalid } }) => (
              <TextField
                {...field}
                error={invalid}
                helperText={error ? error.message : null}
                size="small"
                variant="outlined"
              />
            )}
            rules={{
              required: 'Name is required',
            }}
          />

          <FormLabel>Select Date</FormLabel>
          <Controller
            control={control}
            name={'date'}
            render={({
              field: { onChange, value, ref, ...field },
              fieldState: { error, invalid },
            }) => (
              <DatePicker
                ref={ref}
                value={value}
                slotProps={{
                  textField: {
                    ...field,
                    id: 'date',
                    fullWidth: true,
                    size: 'small',
                    helperText: error?.message,
                    error: invalid,
                  },
                  actionBar: {
                    actions: ['today'],
                  },
                }}
                onChange={onChange}
              />
            )}
            rules={{
              required: 'Date is required',
              validate: {
                isDate: (v) => isValid(v) || 'Invalid Date',
              },
            }}
          />

          <Stack direction="row" spacing={2}>
            <LoadingButton
              color="primary"
              loading={isCreatingHoliday || isUpdatingHoliday}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton >
            <Button type="submit" onClick={handleClose}>
              Close
            </Button>
          </Stack >
        </Stack >
      </form >
    </Container >
  );
};

export default HolidaySettings;
