import * as React from "react";

const SvgEditIcon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      R="evenodd"
      clip-rule="evenodd"
      d="M11.3743 0.581746C11.7471 0.209246 12.2525 0 12.7795 0C13.3064 0 13.8119 0.209246 14.1846 0.581746L15.4183 1.81538C15.7908 2.18814 16 2.69356 16 3.22054C16 3.74752 15.7908 4.25294 15.4183 4.6257L5.63779 14.4062C5.39924 14.6447 5.1039 14.8196 4.77902 14.9117L1.08607 15.9681C0.939899 16.0099 0.785213 16.0118 0.63806 15.9736C0.490906 15.9354 0.356646 15.8586 0.249208 15.751C0.141769 15.6435 0.065067 15.5091 0.027058 15.3619C-0.010951 15.2147 -0.00888192 15.0601 0.0330505 14.9139L1.08834 11.2221C1.18084 10.8968 1.35483 10.6004 1.59384 10.3611L11.3743 0.58061V0.581746ZM12.9805 1.78584C12.9541 1.7594 12.9228 1.73842 12.8883 1.7241C12.8538 1.70978 12.8168 1.70241 12.7795 1.70241C12.7421 1.70241 12.7051 1.70978 12.6706 1.7241C12.6361 1.73842 12.6048 1.7594 12.5784 1.78584L11.1448 3.22054L12.7795 4.8563L14.2142 3.4216C14.2406 3.39522 14.2616 3.36388 14.2759 3.32938C14.2902 3.29488 14.2976 3.25789 14.2976 3.22054C14.2976 3.18319 14.2902 3.1462 14.2759 3.1117C14.2616 3.0772 14.2406 3.04586 14.2142 3.01948L12.9805 1.78584ZM11.5742 6.0604L9.9396 4.42464L2.79907 11.5663C2.7648 11.6004 2.73977 11.6426 2.72637 11.689L2.09251 13.9075L4.31101 13.2736C4.35744 13.2603 4.39968 13.2352 4.43369 13.2009L11.5742 6.0604Z"
      fill={props.color ? props.color : 'rgba(0, 0, 0, 0.54)'}
    />
  </svg>
);

export default SvgEditIcon;
