export const BELL_CURVE = 'bell';
export const FRONT_HEAVY_CURVE = 'front_heavy';
export const BACK_HEAVY_CURVE = 'back_heavy';
export const EVEN_CURVE = 'even';

export const CurveOptions = [
  { id: BELL_CURVE, name: 'Bell' },
  { id: FRONT_HEAVY_CURVE, name: 'Front Heavy' },
  { id: BACK_HEAVY_CURVE, name: 'Back Heavy' },
  { id: EVEN_CURVE, name: 'Even' },
];

export const FLAT_DISTRIBUTION = 'flat';
export const NORMAL_DISTRIBUTION = 'normal';
export const STEEP_DISTRIBUTION = 'steep';

export const DistributionOptions = [
  { id: FLAT_DISTRIBUTION, name: 'Flat' },
  { id: NORMAL_DISTRIBUTION, name: 'Normal' },
  { id: STEEP_DISTRIBUTION, name: 'Steep' },
];
