import { ArrowDropDown, ArrowDropUp, Check } from '@mui/icons-material';
import { Menu, MenuItem, Typography } from '@mui/material';
import { MenuButton } from 'assets/style-components/button';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const options = {
  '': 'None',
  region: 'Region',
  division: 'Division',
  office: 'Office',
};

/**
 * Project Group By Menu
 * @param {Object} handleUpdate 
 * @returns 
 */
const ProjectGroupByMenu = ({ handleUpdate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [groupBy, setGroupBy] = useState('');

  const handleUpdateGroupBy = (event, value) => {
    setAnchorEl(null);
    setGroupBy(value)
    handleUpdate && handleUpdate(value);
  };

  return (
    <>
      <MenuButton
        isHighlighted={groupBy}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        Group By: {groupBy}
        {Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />}
      </MenuButton>
      {anchorEl !== null ? (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setAnchorEl(null)}
        >
          {Object.keys(options).map((option) => {
            const selected = option === groupBy;
            return (
              <MenuItem
                key={option}
                selected={selected}
                onClick={(event) => handleUpdateGroupBy(event, option)}
              >
                <div style={{ width: 20, paddingRight: 4 }}>
                  {selected ? <Check color={'action'} style={{ display: 'block' }} /> : null}
                </div>
                <Typography>{options[option]}</Typography>
              </MenuItem>
            );
          })}
        </Menu>
      ) : null}
    </>
  );
};

export default ProjectGroupByMenu;
