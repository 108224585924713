import clsx from 'clsx';
import times from 'lodash/times';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FAFBFC',
  },
  gridBorder: {
    alignItems: 'center',
    borderLeft: '1px solid #e7e4e4',
    borderRight: '1px solid #e7e4e4',
    borderBottom: '1px solid #e7e4e4',
    backgroundColor: 'white',
  },
}));

const SkeletonListComponent = ({ noOfRows }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container} direction="column">
      {times(noOfRows ? noOfRows : 10, (row) => {
        return (
          <Grid
            container
            item
            className={clsx(classes.gridBorder, 'location-row', 'my-panel')}
            key={`${row}-skeleton`}
            style={{ padding: 0, borderTop: row == 0 ? '1px solid #e7e4e4' : '' }}
          >
            <Skeleton className="skeleton-row" variant="text" />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SkeletonListComponent;
