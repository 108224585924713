import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import { selectContactEntities } from 'features/contacts/store/contactsSlice';
import { selectCompanyEntities } from 'features/companies/store/companiesSlice';
import store from 'store';

export function drawCompanyColorLayer(ganttId) {
  const gantt = ganttId ? ganttStore.getGantt(ganttId) : this;
  const state = store.getState();

  gantt.addTaskLayer({
    renderer: {
      render: (task) => {
        if (task.type === 'placeholder' || !gantt.config.show_company_color) {
          return false;
        }
        const companies = selectCompanyEntities(state);
        const contacts = selectContactEntities(state);
        const taskCompanies = task.users?.map((id) => companies[contacts[id]?.company_id]);

        let backgroundColor = '#757575';
        if (taskCompanies?.length) {
          let color = '';
          taskCompanies.forEach((element, index) => {
            color += `${element.color ? element.color : '#757575'} ${
              index === 0 ? '' : `${index * 10}px`
            }, ${element.color ? element.color : '#757575'} ${(index + 1) * 10}px ${
              index === taskCompanies.length - 1 ? '' : ','
            } `;
          });
          backgroundColor = `repeating-linear-gradient(135deg,${color})`;
        }
        const end =
          task.forecasted_end_date && task.forecasted_end_date > task.end_date
            ? task.forecasted_end_date
            : task.end_date;
        const sizes = gantt.getTaskPosition(task, task.start_date, end);
        const el = document.createElement('div');
        el.style.left = sizes.left + 3 + 'px';
        el.style.width = sizes.width - 6 + 'px';
        el.style.top = sizes.top + 9 + 'px';
        el.style.height = sizes.height + 'px';
        el.style.position = 'absolute';
        el.style.zIndex = 2;
        el.style.background = backgroundColor;

        return el;
      },
      getRectangle: function (task, view) {
        return gantt.getTaskPosition(
          task,
          task.start_date,
          task.end_date > task.forecasted_end_date ? task.end_date : task.forecasted_end_date
        );
      },
    },
  });
}
