export const mapRoutes = [
  {
    path: '',
    lazy: async () => {
      const { TaskMap } = await import('./TaskMap');
      return { Component: TaskMap };
    },
    children: [
      {
        index: true,
        lazy: async () => {
          const { TaskMapGrid } = await import('./TaskMapGrid');
          return { Component: TaskMapGrid };
        },
      },
      {
        path: ':zoneId',
        lazy: async () => {
          const { TaskMapZone } = await import('./TaskMapZone');
          return { Component: TaskMapZone };
        },
      },
    ],
  },
];
