import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import Gantt from 'features/gantt/components/Gantt';
import {
  fetchRecipeDependencies,
  selectAllRecipeDependencies,
} from 'features/recipes/store/recipeDependenciesSlice';
import { fetchRecipeTasks, selectAllRecipeTask } from 'features/recipes/store/recipeTasksSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetRecipeQuery } from '../api/recipe.api';
import { getRecipeGanttColumns } from '../config/RecipeGanttColumns';
import { initRecipeGanttDataProcessor } from '../config/RecipeGanttDataProcessor';
import { recipeGanttEvents, recipeGanttInlineEvents } from '../config/RecipeGanttEvent';
import { formatRecipeResponse } from '../config/RecipeGanttFormatter';
import { getRecipeGanttZoomConfig } from '../config/RecipeGanttZoomConfig';

export const RecipeGantt = ({ recipeId }) => {
  const dispatch = useDispatch();
  const { data: recipe } = useGetRecipeQuery({ recipeId });
  const recipeTasks = useSelector((state) => selectAllRecipeTask(state, recipeId));
  const dependencies = useSelector((state) => selectAllRecipeDependencies(state));
  const [ganttId, setGanttId] = useState(`recipe-gantt-${recipeId}`);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const ganttId = `recipe-gantt-${recipeId}`;
    setGanttId(ganttId);
    ganttStore.createGantt(ganttId);
  }, [ganttId, recipeId]);

  useEffect(() => {
    if (recipeId) {
      setIsLoading(true);
      Promise.all([
        dispatch(fetchRecipeTasks({ recipeId })),
        dispatch(fetchRecipeDependencies({ recipeId })),
      ]).then(() => setIsLoading(false));
    }
  }, [dispatch, recipeId]);

  return (
    <>
      {!isLoading ? (
        <Gantt
          columns={getRecipeGanttColumns(ganttId)}
          events={recipeGanttEvents}
          ganttId={ganttId}
          initDataProcessor={(gantt) => initRecipeGanttDataProcessor(recipeId, gantt)}
          inlineEvents={recipeGanttInlineEvents}
          plugins={{ tooltip: false }}
          zoomConfig={getRecipeGanttZoomConfig}
          config={{
            work_time: false,
            auto_types: true,
            auto_scheduling_strict: true,
            project_start: new Date('1900-01-01 12:00:00 AM'),
          }}
          data={{
            tasks: [
              {
                id: -1,
                text: recipe?.name,
                percentage: null,
                start_date: '1900-01-01',
                open: true,
                readonly: true,
              },
              ...recipeTasks.map((recipeTask) => formatRecipeResponse(recipeTask)),
            ],
            links: dependencies,
          }}
          templates={{
            task_text: function () {
              return '';
            },
            rightside_text: function (start, end, task) {
              return task.text;
            },
          }}
        />
      ) : (
        <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
          <CircularProgress className="loading" />
        </Backdrop>
      )}
    </>
  );
};
