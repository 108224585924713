import SvgIcon from '@mui/material/SvgIcon';

export const ProductionRating = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        clipRule="evenodd"
        d="M3 0.5C1.34315 0.5 0 1.84315 0 3.5V21.5C0 23.1569 1.34315 24.5 3 24.5H21C22.6569 24.5 24 23.1569 24 21.5V3.5C24 1.84315 22.6569 0.5 21 0.5H3ZM6.42937 14.1903H8.29656C9.04656 14.1903 9.68719 14.0497 10.2184 13.7685C10.7497 13.482 11.1559 13.0758 11.4372 12.5497C11.7184 12.0237 11.8591 11.3935 11.8591 10.6591C11.8591 9.95597 11.7184 9.33357 11.4372 8.7919C11.1559 8.24503 10.7497 7.81534 10.2184 7.50284C9.68719 7.19034 9.04656 7.03409 8.29656 7.03409H4.53875V18.4091H6.42937V14.1903ZM6.42937 12.6044V8.62784H8.29656C8.69239 8.62784 9.0101 8.7242 9.24969 8.9169C9.48927 9.10961 9.66375 9.36222 9.77312 9.67472C9.88771 9.98722 9.945 10.3205 9.945 10.6747C9.945 11.0445 9.88771 11.3752 9.77312 11.6669C9.66375 11.9586 9.48666 12.1877 9.24187 12.3544C9.00229 12.5211 8.68719 12.6044 8.29656 12.6044H6.42937ZM16.3441 7.03409H12.9066V18.4091H14.805V14.0419H16.3537L18.2034 18.4091H20.2347V18.3075L18.1343 13.5596C18.4084 13.4219 18.6528 13.2546 18.8675 13.0575C19.1852 12.7607 19.4274 12.3961 19.5941 11.9638C19.7659 11.5315 19.8519 11.0315 19.8519 10.4638C19.8519 9.71378 19.7139 9.08617 19.4378 8.58097C19.167 8.07055 18.7711 7.68513 18.2503 7.42472C17.7295 7.1643 17.0941 7.03409 16.3441 7.03409ZM14.805 12.4482H16.2972C16.667 12.4482 16.9743 12.3674 17.2191 12.206C17.4639 12.0445 17.6461 11.8205 17.7659 11.5341C17.8909 11.2476 17.9534 10.9221 17.9534 10.5575C17.9534 10.1669 17.8987 9.82836 17.7894 9.5419C17.68 9.25024 17.5081 9.02628 17.2737 8.87003C17.0394 8.70857 16.7295 8.62784 16.3441 8.62784H14.805V12.4482Z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};
