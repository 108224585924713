import { useState } from 'react';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 'bold',
  },
  marginAuto: {
    margin: 'auto',
  },
  renameBtn: {
    padding: '5px 10px',
    fontWeight: '600',
    fontSize: 12,
    textTransform: 'inherit',
  },
}));

/**
 *
 * @param {string} props.id
 * @param {string} props.open
 * @param {string} props.handleClose
 * @param {string} props.defaultValue
 * @param {string} props.displayLabel
 * @param {string} props.handleUpdate
 * @returns
 */

const RenameCustomView = (props) => {
  const classes = useStyles();
  const { id, open, handleClose, defaultValue, handleUpdate } = props;
  const [value, setValue] = useState();

  const handleOnChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = () => {
    handleUpdate({ value, id });
  };

  const handleKeyPress = (event) => {
    if (event.key !== 'Enter') return;
    handleUpdate({ value, id });
  };

  return (
    <Dialog id={id} open={open} onClose={handleClose}>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={3} className={classes.marginAuto}>
              <Typography variant="subtitle2" className={classes.label}>
                Name:
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                autoFocus
                fullWidth
                size="small"
                value={value}
                variant="outlined"
                onBlur={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
                onChange={handleOnChange}
                defaultValue={defaultValue}
                onKeyPress={handleKeyPress}
              />
            </Grid>
          </Grid>
          <Grid item className="flex" justifyContent="center">
            <Button
              size="small"
              onClick={handleSubmit}
              className={clsx('green-button', classes.renameBtn)}
            >
              Rename
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RenameCustomView;
