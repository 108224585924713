import { useState, useImperativeHandle } from 'react';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Button, Grid, Popover } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SelectTradeDropdown } from 'features/contacts/components/SelectTradeDropdown';

const useStyles = makeStyles((theme) => ({
  ArrowIcon: {
    fontSize: 18,
  },
  paper: {
    width: 500,
    overflowY: 'auto',
    borderRadius: '8px',
    padding: '10px 10px',
  },
}));

const TaskBulkEditTradeMenuItem = ({ handleUpdate }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [tradeIds, setTradeIds] = useState([]);

  const handleSubmit = () => {
    handleUpdate(tradeIds);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(undefined);
    setTradeIds([]);
  };

  return (
    <>
      <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
        Trade
        {anchorEl ? (
          <ArrowDropDown className={classes.ArrowIcon} />
        ) : (
          <ArrowDropUp className={classes.ArrowIcon} />
        )}
      </Button>
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          classes={{ paper: classes.paper }}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
        >
          <div style={{ height: '300px' }}>
            <SelectTradeDropdown setTradeIds={setTradeIds} tradeIds={tradeIds} />
          </div>
          <Grid container justifyContent={'flex-end'} spacing={1}>
            <Grid item>
              <Button color={'primary'} size={'small'} onClick={handleSubmit}>
                Close
              </Button>
            </Grid>
            <Grid item>
              <Button color={'primary'} size={'small'} variant="contained" onClick={handleSubmit}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Popover>
      )}
    </>
  );
};

export default TaskBulkEditTradeMenuItem;
