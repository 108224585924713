import { StyledDialog } from '@blackhyve/common';
import { CircularProgress, Box } from '@mui/material';
import { useModalProvider } from './ModalProvider';

export const LoadingSpinner = () => {
  const { openModalMeta } = useModalProvider();
  console.log('hi loading spinner');

  return (
    <StyledDialog maxWidth={'lg'} open={true} title={''}>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        width="100%"
      >
        {openModalMeta?.message ? <h1>{openModalMeta?.message}...</h1> : null}
        <CircularProgress size={80} />
      </Box>
    </StyledDialog>
  );
};
