import { useState } from 'react';
import List from '@mui/material/List';
import Backdrop from '@mui/material/Backdrop';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import ListItemButton from '@mui/material/ListItemButton';
import CircularProgress from '@mui/material/CircularProgress';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ConfirmDeleteDialog, OptionsMenu } from '@blackhyve/common';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import AddEditLbsDialog from './AddEditLbsDialog';

/**
 * LBS More Options
 * @param {String} type
 * @param {Object} handleSave
 * @param {Object} entityObj
 * @param {Object} handleDelete
 * @param {Boolean} isLoading
 * @param {Object} handleDuplicate
 * @returns
 */
const LbsMoreOptions = ({
  handleDelete,
  handleDuplicate,
  isLoading,
  type,
  handleSave,
  entityObj,
}) => {
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleCloseEditDialog = () => setOpenEditDialog(false);

  const handleEdit = (data) => {
    setOpenEditDialog(false);
    handleSave(data);
  };

  return (
    <>
      {isLoading && (
        <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
          <CircularProgress className="loading" />
        </Backdrop>
      )}
      <OptionsMenu
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        triggerButton={
          <IconButton size={'small'}>
            <MoreHoriz />
          </IconButton>
        }
      >
        {(handleClose) => (
          <List dense>
            <>
              <ListItemButton
                onClick={() => {
                  handleClose();
                  setOpenEditDialog(true);
                }}
              >
                <ListItemIcon>
                  <EditOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  handleClose();
                  handleDuplicate();
                }}
              >
                <ListItemIcon>
                  <ContentCopyOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Duplicate</ListItemText>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  handleClose();
                  setDeleteConfirmationDialog(true);
                }}
              >
                <ListItemIcon>
                  <Delete color={'error'} />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </ListItemButton>
            </>
          </List>
        )}
      </OptionsMenu>

      {deleteConfirmationDialog ? (
        <ConfirmDeleteDialog
          handleDelete={handleDelete}
          item={type}
          open={deleteConfirmationDialog}
          handleClose={() => {
            setDeleteConfirmationDialog(false);
          }}
        />
      ) : null}

      {openEditDialog ? (
        <AddEditLbsDialog
          entityObj={entityObj}
          handleClose={handleCloseEditDialog}
          handleSave={handleEdit}
          isMapUploadEnabled={type === 'Zone'}
          open={openEditDialog}
          type={type}
        />
      ) : null}
    </>
  );
};

export default LbsMoreOptions;
