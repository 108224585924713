import { Popover } from '@blackhyve/common';
import { FilterList } from '@mui/icons-material';
import {
  Checkbox,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material';
import { useState } from 'react';

export const DrawerFilterMenu = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(undefined);
  return (
    <>
      <ListItemButton onClick={(event) => setAnchorEl(event.currentTarget)}>
        <ListItemIcon>
          <FilterList />
        </ListItemIcon>
        <ListItemText primary={'Filters'} />
      </ListItemButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={() => setAnchorEl(undefined)}
      >
        <MenuList>{children}</MenuList>
      </Popover>
    </>
  );
};
export const DrawerFilterMenuItem = ({ checked, onClick, primaryText, secondaryText }) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <Checkbox disableRipple checked={checked} color={'primary'} />
      </ListItemIcon>
      <ListItemText primary={primaryText} secondary={secondaryText} />
    </MenuItem>
  );
};
