import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import isEmpty from 'lodash/isEmpty';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import FormControl from '@mui/material/FormControl';
import { useEffect, useMemo, useState } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import BuildingIcon from '@blackhyve/common/src/icons/Building';
import { StyledLabel } from 'features/drawers/utils/drawerStyle';
import { useGetNormalizedLbsQuery } from 'features/locations/store/location.api';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import {
  ActionButtons,
  LabelValue,
  SectionHeading,
} from 'features/gantt/components/TaskDetailDrawer';

/**
 * Display Location break down information
 * @param {String} taskId
 * @returns
 */
const SelectLBS = ({ taskId, handleSave, ganttId, projectId, disableEdit }) => {
  const gantt = ganttStore.getGantt(ganttId);
  const task = gantt.getTask(taskId);

  const [isEditing, setIsEditing] = useState(false);

  const { data: lbsData, isLoading: isLoadingLbs } = useGetNormalizedLbsQuery({ projectId });

  const locationEntities = isEmpty(lbsData?.locations) ? {} : lbsData?.locations;
  const zoneEntities = isEmpty(lbsData?.zones) ? {} : lbsData?.zones;
  const areaEntities = isEmpty(lbsData?.areas) ? {} : lbsData?.areas;

  const initialState = useMemo(
    () => ({
      location_id: task?.location_id || '',
      zone_id: task?.zone_id || '',
      area_id: task?.area_id || '',
    }),
    [task]
  );

  const [formState, setFormState] = useState(initialState);

  const handleStartEditing = () => {
    setIsEditing(true);
  };
  const handleCloseEditing = () => {
    setFormState(initialState);
    setIsEditing(false);
  };

  const handleOnChange = (event) => {
    const name = event.target.name;
    const id = event.target.value;

    const formStateToUpdate = { ...formState, [name]: id };
    if (!id) {
      return;
    }

    switch (name) {
      case 'location_id': {
        formStateToUpdate.zone_id = '';
        formStateToUpdate.area_id = '';
        break;
      }
      case 'zone_id': {
        if (id) {
          const zone = zoneEntities[id];
          formStateToUpdate['location_id'] = zone.location_id;
        }
        formStateToUpdate.area_id = '';
        break;
      }
      case 'area_id': {
        if (id) {
          const area = areaEntities[id];
          formStateToUpdate['zone_id'] = area.zone_id;
          formStateToUpdate['location_id'] = zoneEntities[area.zone_id].location_id;
        }
        break;
      }
      default:
        break;
    }

    setFormState(formStateToUpdate);
  };

  const handleUpdate = async () => {
    handleSave({ ...formState });
    setIsEditing(false);
  };

  useEffect(() => {
    setFormState({
      location_id: task?.location_id || '',
      zone_id: task?.zone_id || '',
      area_id: task?.area_id || '',
    });
  }, [task]);

  return (
    <>
      <SectionHeading
        disabled={disableEdit}
        handleEditing={handleStartEditing}
        isEditing={isEditing}
        title="Locations"
      >
        <Box color={'grey.400'}>
          <BuildingIcon style={{ marginRight: 5 }}></BuildingIcon>
        </Box>
      </SectionHeading>
      <Grid container item spacing={1} xs={12}>
        <Grid item xs={4}>
          <StyledLabel>Location</StyledLabel>
        </Grid>
        <Grid item xs={4}>
          <StyledLabel>Zone</StyledLabel>
        </Grid>
        <Grid item xs={4}>
          <StyledLabel>Area</StyledLabel>
        </Grid>
      </Grid>
      {isLoadingLbs ? (
        <Grid item xs={12}>
          <Skeleton height="40px" width="100%" />
        </Grid>
      ) : !isEditing ? (
        <Grid container item spacing={1} xs={12}>
          <Grid item xs={4}>
            <LabelValue value={locationEntities[formState?.location_id]?.name} />
          </Grid>
          <Grid item xs={4}>
            <LabelValue value={zoneEntities[formState?.zone_id]?.name} />
          </Grid>
          <Grid item xs={4}>
            <LabelValue value={areaEntities[formState?.area_id]?.name} />
          </Grid>
        </Grid>
      ) : (
        <Grid container item spacing={1} xs={12}>
          <Grid item xs={4}>
            <LocationDropdown
              handleOnChange={handleOnChange}
              locationId={formState.location_id}
              locations={Object.values(locationEntities)}
            />
          </Grid>
          <Grid item xs={4}>
            <ZoneDropdown
              handleOnChange={handleOnChange}
              locationId={formState.location_id}
              locations={Object.values(locationEntities)}
              zoneEntities={zoneEntities}
              zoneId={formState.zone_id}
            />
          </Grid>
          <Grid item xs={4}>
            <AreaDropdown
              areaEntities={areaEntities}
              areaId={formState.area_id}
              handleOnChange={handleOnChange}
              locationId={formState.location_id}
              locations={Object.values(locationEntities)}
              projectId={projectId}
              zoneEntities={zoneEntities}
              zoneId={formState.zone_id}
            />
          </Grid>
          <ActionButtons handleClose={handleCloseEditing} handleUpdate={handleUpdate} />
        </Grid>
      )}
    </>
  );
};

/**
 * Location Dropdown
 * @param {String} projectId
 * @param {String} locationId
 * @param {Object} handleOnChange
 * @returns
 */
const LocationDropdown = ({ handleOnChange, locations, locationId }) => {
  return (
    <Dropdown list={locations} name={'location_id'} value={locationId} onChange={handleOnChange} />
  );
};

/**
 * Zone Dropdown
 * @param {String} zoneId
 * @param {String} locationId
 * @param {Object} handleOnChange
 * @returns
 */
const ZoneDropdown = ({ zoneId, handleOnChange, locationId, locations, zoneEntities }) => {
  let dropdownList;

  if (locationId) {
    const location = locations?.find((location) => location.id == locationId);
    dropdownList = [
      {
        id: location.id,
        name: location.name,
        children: (location.zones || []).map((id) => zoneEntities[id]),
      },
    ];
  } else {
    dropdownList = locations.map((location) => ({
      id: location.id,
      name: location.name,
      children: (location.zones || []).map((id) => zoneEntities[id]),
    }));
  }

  return <Dropdown list={dropdownList} name={'zone_id'} value={zoneId} onChange={handleOnChange} />;
};

/**
 * Area Dropdown
 * @param {String} areaId
 * @param {String} zoneId
 * @param {String} locationId
 * @param {String} projectId
 * @param {Object} handleOnChange
 * @returns
 */
const AreaDropdown = ({
  areaId,
  handleOnChange,
  zoneId,
  locationId,
  locations,
  zoneEntities,
  areaEntities,
}) => {
  let dropdownList = [];

  if (locationId) {
    const location = locations.find((location) => location.id == locationId);
    if (location) {
      const zonesToProcess = zoneId
        ? [zoneEntities[zoneId]]
        : location.zones.map((id) => zoneEntities[id]);
      dropdownList = zonesToProcess.map((zone) => ({
        id: `${location.id} - ${zone.id}`,
        name: `${location.name} - ${zone.name}`,
        locationId: location.id,
        zoneId: zone.id,
        children: zone.areas?.map((areaId) => areaEntities[areaId]),
      }));
    }
  } else {
    dropdownList = locations.flatMap((location) =>
      location.zones.map((zoneId) => {
        const zone = zoneEntities[zoneId];
        const children = zone.areas?.map((areaId) => areaEntities[areaId]);
        return {
          id: `${location.id} - ${zone.id}`,
          name: `${location.name} - ${zone.name}`,
          locationId: location.id,
          zoneId: zone.id,
          children,
        };
      })
    );
  }

  return <Dropdown list={dropdownList} name={'area_id'} value={areaId} onChange={handleOnChange} />;
};

/**
 * Dropdown
 * @param {String} name
 * @param {String} value
 * @param {Object} onChange
 * @param {[Object]} list
 * @returns
 */
const Dropdown = ({ name, value, onChange, list }) => {
  return (
    <FormControl size="small" sx={{ minWidth: 120 }}>
      <Select fullWidth name={name} value={value} onChange={onChange}>
        {list && list.length && (
          <MenuItem value={undefined}>
            <em>None</em>
          </MenuItem>
        )}
        {list && list.length ? (
          list.map((obj) =>
            obj?.children ? (
              renderGroup(obj)
            ) : (
              <MenuItem key={obj.id} value={obj.id}>
                {obj.name}
              </MenuItem>
            )
          )
        ) : (
          <MenuItem value={undefined}>No Options</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

/**
 * Render group which has children
 * @param {Object} obj
 * @returns
 */
const renderGroup = (obj) => {
  const items = obj?.children.map(({ id, name }) => {
    return (
      <MenuItem key={id} value={id}>
        {name}
      </MenuItem>
    );
  });
  return [
    <ListSubheader style={{ lineHeight: 2, fontWeight: 'bold', fontSize: '14px', color: 'black' }}>
      {obj.name}
    </ListSubheader>,
    items,
  ];
};

export default SelectLBS;