import { ConfirmDeleteDialog } from '@blackhyve/common';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import { useState } from 'react';

const ContextMenuComponent = ({ anchor, handleClose, id, ganttId }) => {
  const gantt = ganttStore.getGantt(ganttId);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDelete = () => {
    if (gantt.isTaskExists(id)) {
      setTimeout(() => {
        gantt.deleteTask(id);
      });
    }
    handleClose();
  };

  return (
    <>
      <Menu
        autoFocus
        anchorEl={anchor}
        getContentAnchorEl={null}
        MenuListProps={{ dense: true }}
        open={Boolean(anchor)}
        role={'menu'}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <MenuItem dense disabled={id < 0} onClick={() => setDeleteDialogOpen(true)}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon color="error" />
          </ListItemIcon>
          <ListItemText primary={'Delete'} sx={{ color: 'error.main' }} />
        </MenuItem>
      </Menu>
      <ConfirmDeleteDialog
        handleClose={() => setDeleteDialogOpen(false)}
        handleDelete={handleDelete}
        item={'task'}
        open={deleteDialogOpen}
      />
    </>
  );
};

export default ContextMenuComponent;
