import {
  TableContainer,
  Button,
  Box,
  Grid,
  Paper,
  Toolbar,
  Typography,
  CircularProgress,
  FormLabel,
  TextField,
  Autocomplete,
} from '@mui/material';
import { Table } from 'components/table/Table';
import { useEffect, useState } from 'react';
import { StyledDialog } from '@blackhyve/common';
import { Controller, useForm } from 'react-hook-form';
import { useGetLocationsQuery } from 'features/locations/store/locations.api';
import { useGetCompaniesQuery } from 'features/companies/api/companies.api';
import {
  useCreateContractMutation,
  useGetContractsByBudgetQuery,
  useUpdateContractByIdMutation,
} from '../store/contracts.api';
import { useNavigate } from 'react-router-dom';
import { formatMoney, formatPercent } from './NumericControls';
const initialState = {
  title: '',
  description_of_work: '',
  company_id: null,
  location_id: null,
};

export const ContractFormDialog = ({
  open,
  handleClose,
  budgetId,
  projectId,
  contract,
  creating,
  afterCreate = () => {},
}) => {
  const { data: locations = [], isLoading: isLoadingLocations } = useGetLocationsQuery({
    projectId,
  });
  const { data: companies = [], isLoading: isLoadingCompanies } = useGetCompaniesQuery();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: { ...initialState, ...(contract ?? {}) },
  });

  const [updateContract, { isLoading: isLoadingUpdatingBudget }] = useUpdateContractByIdMutation();
  const [createContract, { isLoading: isLoadingCreatingBudget }] = useCreateContractMutation();

  const isLoading = isLoadingCreatingBudget || isLoadingUpdatingBudget;

  useEffect(() => {
    reset({ ...initialState, ...(contract ?? {}) });
  }, [contract, reset]);

  async function handleStore(data) {
    if (contract?.id) {
      await updateContract({
        ...data,
      })
        .unwrap()
        .then(() => {
          reset();
          handleClose();
        });
    } else {
      await createContract({ budgetId, ...data })
        .unwrap()
        .then(({ data: contract }) => {
          reset();
          handleClose();
          afterCreate(contract);
        });
    }
  }

  return (
    <StyledDialog
      DialogContentProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
      handleClose={() => handleClose({ reload: false })}
      open={open}
      title={creating ? 'Create Contract' : 'Edit Contract'}
      actions={
        <>
          <Button disabled={isLoading} onClick={() => handleClose({ reload: false })}>
            Close
          </Button>
          <Button
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={'1rem'} />}
            variant={'contained'}
            onClick={handleSubmit(handleStore)}
          >
            {creating ? 'Add' : 'Save'}
          </Button>
        </>
      }
    >
      <Grid container item spacing={1} xs={12}>
        <Grid container item xs={12}>
          <FormLabel>Title</FormLabel>
          <Controller
            control={control}
            name="title"
            rules={{ required: 'Title field is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={error}
                helperText={error?.message}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid container item xs={12}>
          <FormLabel>Description of Work</FormLabel>
          <Controller
            control={control}
            name="description_of_work"
            rules={{ required: 'Description field is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={error}
                helperText={error?.message}
                size="small"
              />
            )}
          />
        </Grid>

        <Grid container item xs={12}>
          <FormLabel>Location</FormLabel>
          <Box width={'100%'}>
            <Controller
              control={control}
              name="location_id"
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  autoHighlight
                  isOptionEqualToValue={(option, value) => option.id === value}
                  loading={isLoadingLocations}
                  options={locations}
                  renderOption={(props, option) => <li {...props}>{option?.name}</li>}
                  size="small"
                  getOptionLabel={(option) =>
                    option?.name ?? (locations && locations.find((row) => row.id === option)?.name)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="location-input"
                      placeholder="Location"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    field.onChange(newValue?.id ?? null);
                  }}
                />
              )}
            />
          </Box>
        </Grid>

        <Grid container item xs={12}>
          <FormLabel>Company</FormLabel>
          <Box width={'100%'}>
            <Controller
              control={control}
              name="company_id"
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  autoHighlight
                  isOptionEqualToValue={(option, value) => option.id === value}
                  loading={isLoadingCompanies}
                  options={companies}
                  renderOption={(props, option) => <li {...props}>{option?.name}</li>}
                  size="small"
                  getOptionLabel={(option) =>
                    option?.name ?? (companies && companies.find((row) => row.id === option)?.name)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="company-input"
                      placeholder="Company"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    field.onChange(newValue?.id ?? null);
                  }}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};

const contractsColumns = [
  { label: 'Contract number', field: 'id', render: (value) => `#${value.id}`, sort: true },
  { label: 'Title', field: 'title', sort: true },
  { label: 'Company', field: 'company_id', render: (value) => value.company.name, sort: true },
  { label: 'Location', field: 'location_id', render: (value) => value.location.name, sort: true },
  {
    label: 'Contract amount',
    field: 'contracted_amount',
    render: (value) => `$${formatMoney(value.contracted_amount || 0)}`,
    sort: true,
  },
  {
    label: 'Invoiced amount',
    field: 'invoiced_amount',
    render: (value) => `$${formatMoney(value.invoiced_amount || 0)}`,
    sort: true,
  },
  {
    label: 'Balance to finish',
    field: 'balance_to_finish_amount',
    render: (value) => `$${formatMoney(value.balance_to_finish_amount || 0)}`,
    sort: true,
  },
  {
    label: '% complete by invoice',
    field: 'contracted_invoice_percent',
    render: (value) => `${formatPercent(value.contracted_invoice_percent || 0)}%`,
    sort: true,
  },
];
const ContractsList = ({ rows, onClick }) => {
  return (
    <TableContainer>
      <Table
        columns={contractsColumns}
        defaultOrderBy={false}
        isFetching={false}
        isLoading={false}
        rows={rows}
        stickyHeader={true}
        rowProps={(contract) => ({
          sx: { cursor: 'pointer' },
          role: 'button',
          onClick: () => onClick(contract),
        })}
      />
    </TableContainer>
  );
};

const BudgetsShowContracts = ({ budgetId, projectId, workspaceId }) => {
  const [dialog, setDialog] = useState(false);
  const { data: contracts = [] } = useGetContractsByBudgetQuery({ budgetId });

  const navigate = useNavigate();

  return (
    <>
      <Typography variant={'h5'}>Contracts</Typography>
      <Paper elevation={2}>
        <Toolbar style={{ position: 'sticky', left: '0px' }}>
          <Grid container item>
            <Box ml={'auto'} />

            <Button size="small" variant="text" onClick={() => setDialog(true)}>
              New Contract
            </Button>

            <ContractFormDialog
              budgetId={budgetId}
              creating={true}
              handleClose={() => setDialog(false)}
              open={dialog}
              projectId={projectId}
              afterCreate={(contract) => {
                navigate(
                  `/workspace/${workspaceId}/projects/${projectId}/budgets/${budgetId}/contracts/${contract.id}`
                );
              }}
            />
          </Grid>
        </Toolbar>

        <ContractsList
          budgetId={budgetId}
          projectId={projectId}
          rows={contracts}
          workspaceId={workspaceId}
          onClick={(contract) => {
            navigate(
              `/workspace/${workspaceId}/projects/${projectId}/budgets/${budgetId}/contracts/${contract.id}`
            );
          }}
        />
      </Paper>
    </>
  );
};

export default BudgetsShowContracts;
