import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  LineElement,
  PointElement,
  BarController,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Button, Typography } from '@mui/material';
import { VisibilityOff, VisibilityOutlined } from '@mui/icons-material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
    },
    title: {
      display: false,
      text: 'Forecast',
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || '';

          if (label) {
            label += ': ';
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
              context.parsed.y
            );
          }
          return label;
        },
      },
    },
  },
  scales: {
    y: {
      ticks: {
        callback: (value) => {
          return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
            value
          );
        },
      },
    },
  },
};

export const labelPerType = {
  budget_total: 'Estimated',
  budget_labor: 'Estimated Labor',
  budget_material: 'Estimated Material',
  summary_budget_total: 'Budgeted',
  summary_budget_labor: 'Budgeted Labor',
  summary_budget_material: 'Budgeted Material',
  summary_contract_total: 'Contracted',
  summary_contract_labor: 'Contracted Labor',
  summary_contract_material: 'Contracted Material',
  summary_invoice_total: 'Invoiced',
};

export const ForecastGraph = ({ forecast, setVisibleEntries, visibleEntries, resetVisibleEntries }) => {
  const { showEstimated, showSummary, showContracts, showInvoiced, showLaborAndMaterial } = visibleEntries;

  const firstDataset = Object.keys(forecast.datasets)[0] || null;

  const data = {
    labels: firstDataset ? forecast.datasets[firstDataset].map((item) => item.label) : [],
    datasets: [].concat(
      showEstimated
        ? [
          {
            label: labelPerType['budget_total'],
            data: forecast.datasets.budget_total.map((item) => item.value),
            borderColor: 'rgb(14, 165, 233)',
            backgroundColor: 'rgb(14, 165, 233, 0.5)',
            fill: false,
            borderDash: [3, 5],
          },
        ]
        : [],
      showEstimated && showLaborAndMaterial ? [
        {
          label: labelPerType['budget_labor'],
          data: forecast.datasets.budget_labor.map((item) => item.value),
          borderColor: 'rgb(244, 64, 94)',
          backgroundColor: 'rgb(244, 63, 94, 0.5)',
          fill: false,
          borderDash: [3, 5],
        },
        {
          label: labelPerType['budget_material'],
          data: forecast.datasets.budget_material.map((item) => item.value),
          borderColor: 'rgb(234, 179, 8)',
          backgroundColor: 'rgb(234, 179, 8, 0.5)',
          fill: false,
          borderDash: [3, 5],
        },
      ] : [],
      showSummary
        ? [
          {
            label: labelPerType['summary_budget_total'],
            data: forecast.datasets.summary_budget_total.map((item) => item.value),
            borderColor: 'rgb(22, 163, 74)',
            backgroundColor: 'rgb(22, 163, 74, 0.5)',
            fill: false,
            borderDash: [10, 5],
          },
        ]
        : [],
      showSummary && showLaborAndMaterial ? [
        {
          label: labelPerType['summary_budget_labor'],
          data: forecast.datasets.summary_budget_labor.map((item) => item.value),
          borderColor: 'rgb(249, 115, 22)',
          backgroundColor: 'rgb(249, 115, 22, 0.5)',
          fill: false,
          borderDash: [10, 5],
        },
        {
          label: labelPerType['summary_budget_material'],
          data: forecast.datasets.summary_budget_material.map((item) => item.value),
          borderColor: 'rgb(34, 211, 238)',
          backgroundColor: 'rgb(34, 211, 238, 0.5)',
          fill: false,
          borderDash: [10, 5],
        },
      ] : [],
      showContracts
        ? [
          {
            label: labelPerType['summary_contract_total'],
            data: forecast.datasets.summary_contract_total.map((item) => item.value),
            borderColor: 'rgb(99, 102, 241)',
            backgroundColor: 'rgb(99, 102, 241, 0.5)',
          },
        ]
        : [],
      showContracts && showLaborAndMaterial ? [
        {
          label: labelPerType['summary_contract_labor'],
          data: forecast.datasets.summary_contract_labor.map((item) => item.value),
          borderColor: 'rgb(139, 92, 246)',
          backgroundColor: 'rgb(139, 92, 246, 0.5)',
        },
        {
          label: labelPerType['summary_contract_material'],
          data: forecast.datasets.summary_contract_material.map((item) => item.value),
          borderColor: 'rgb(217, 70, 239)',
          backgroundColor: 'rgb(217, 70, 239, 0.5)',
        },
      ] : [],
      showInvoiced
        ? [
          {
            label: labelPerType['summary_invoice_total'],
            data: forecast.datasets.summary_invoice_total.map((item) => item.value),
            type: 'bar',
            backgroundColor: 'rgb(29, 78, 216, 0.8)',
          },
        ]
        : []
    ),
  };

  return (
    <div style={{ padding: '0 15px 15px' }}>
      <div style={{ padding: '15px 0' }}>
        <Typography style={{ textAlign: 'center' }} variant="h6">
          Quick Visibility Actions
        </Typography>

        <div style={{ gap: '0.5rem', display: 'flex', justifyContent: 'center' }}>
          <Button
            size="small"
            startIcon={showEstimated ? <VisibilityOutlined /> : <VisibilityOff />}
            onClick={() =>
              setVisibleEntries((state) => ({ ...state, showEstimated: !state.showEstimated }))
            }
          >
            Estimated
          </Button>

          <Button
            size="small"
            startIcon={showSummary ? <VisibilityOutlined /> : <VisibilityOff />}
            onClick={() =>
              setVisibleEntries((state) => ({ ...state, showSummary: !state.showSummary }))
            }
          >
            Budgeted
          </Button>

          <Button
            size="small"
            startIcon={showContracts ? <VisibilityOutlined /> : <VisibilityOff />}
            onClick={() =>
              setVisibleEntries((state) => ({ ...state, showContracts: !state.showContracts }))
            }
          >
            Contracted
          </Button>

          <Button
            size="small"
            startIcon={showInvoiced ? <VisibilityOutlined /> : <VisibilityOff />}
            onClick={() =>
              setVisibleEntries((state) => ({ ...state, showInvoiced: !state.showInvoiced }))
            }
          >
            Invoiced
          </Button>

          <Button
            size="small"
            startIcon={showLaborAndMaterial ? <VisibilityOutlined /> : <VisibilityOff />}
            onClick={() =>
              setVisibleEntries((state) => ({ ...state, showLaborAndMaterial: !state.showLaborAndMaterial }))
            }
          >
            Labor / Material
          </Button>

          <Button size="small" onClick={() => resetVisibleEntries()}>
            Reset
          </Button>
        </div>
      </div>
      <Line data={data} options={options} />
    </div>
  );
};
