import { useState, memo } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { TASK_COLORS } from 'helpers/constants';
import { styled } from '@mui/material';
import { Check } from '@mui/icons-material';

export const StyleContainer = memo(
  styled(Grid)(() => ({
    display: 'flex',
    marginTop: '15px',
    marginBottom: '15px',
    justifyContent: 'center',
  }))
);

export const StyleColorBox = memo(
  styled(Grid)(({ theme, color, selected }) => ({
    cursor: 'pointer',
    position: 'relative',
    boxSizing: 'border-box',
    display: 'inline-block',
    width: '35px',
    height: '35px',
    marginTop: '5px',
    marginRight: '5px',
    borderRadius: '5px',
    backgroundColor: `${color}`,

    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
      boxShadow: `inset 0 0 0 2px #fff`,
    },

    '&>svg': {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      position: 'absolute',
      margin: 'auto',
    },
  }))
);

/**
 * Color Palate
 * @param {HTML} anchorEl
 * @param {String} btnTxt
 * @param {Object} handleClose
 * @param {Object} handleSave
 * @param {String} defaultColor
 * @returns
 */
const ColorPalate = ({ anchorEl, handleClose, handleSave, defaultColor, btnTxt = 'Save' }) => {
  const [selectedColor, setSelectedColor] = useState(defaultColor);

  const handleSelectColor = (color) => {
    selectedColor === color ? setSelectedColor(null) : setSelectedColor(color);
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      onClose={handleClose}
    >
      <Grid container style={{ margin: 'auto', padding: '15px', width: 400 }}>
        <Typography style={{ fontWeight: '600', fontSize: '15px', textTransform: 'uppercase' }}>
          Select Color
        </Typography>
        <StyleContainer container spacing={1} xs={12}>
          {TASK_COLORS.map(({ value }) => {
            return (
              <StyleColorBox
                color={value}
                key={value}
                selected={selectedColor === value}
                onClick={() => handleSelectColor(value)}
              >
                {selectedColor === value ? <Check htmlColor={'#fff'} /> : null}
              </StyleColorBox>
            );
          })}
        </StyleContainer>
        <Grid container item justifyContent={'flex-end'} style={{ gap: 10 }}>
          <Button color={'primary'} size="small" onClick={handleClose}>
            Close
          </Button>
          <Button
            color={'primary'}
            size="small"
            variant="contained"
            onClick={() => handleSave(selectedColor)}
          >
            {btnTxt}
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default ColorPalate;
