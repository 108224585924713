import { api, providesList } from 'api';

export const companiesAPI = api.enhanceEndpoints({ addTagTypes: ['Company'] }).injectEndpoints({
  endpoints: (build) => ({
    createCompany: build.mutation({
      query: ({ company }) => ({
        url: `/companies`,
        method: 'POST',
        body: company,
      }),
      invalidatesTags: (result, error, args) => (result ? [{ id: 'LIST', type: 'Company' }] : []),
      transformResponse: (response) => response.data,
    }),
    getCompanies: build.query({
      query: () => `/companies?include_archived`,
      providesTags: (result, error, args) => (result ? providesList(result, 'Company') : []),
      transformResponse: (response) => response.data,
    }),
    getCompanyById: build.query({
      query: ({ companyId }) => `/companies/${companyId}`,
      providesTags: (result, error, args) => (result ? [{ type: 'Company', id: result.id }] : []),
      transformResponse: (response) => response.data,
    }),
    getCompanyByUserId: build.query({
      query: ({ userId }) => `/users/${userId}/company`,
      providesTags: (result, error, args) => [{ type: 'Company', id: result?.id }],
      transformResponse: (response) => response?.data,
    }),
    updateCompany: build.mutation({
      query: ({ company }) => ({
        url: `/companies/${company.id}`,
        method: 'PATCH',
        body: company,
      }),
      invalidatesTags: (result, error, { company }) =>
        result ? [{ type: 'Company', id: company.id }] : [],
    }),
    uploadCompanyLogo: build.mutation({
      query: ({ companyId, formData }) => ({
        url: `/companies/${companyId}/logo`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: (result, error, { companyId }) =>
        result ? [{ type: 'Company', id: companyId }] : [],
    }),
    restoreCompany: build.mutation({
      query: ({ companyId }) => ({
        url: `/companies/${companyId}/restore`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, { companyId }) =>
        result ? [{ type: 'Company', id: companyId }] : [],
    }),
    archiveCompany: build.mutation({
      query: ({ companyId }) => ({
        url: `/companies/${companyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { companyId }) =>
        result ? [{ type: 'Company', id: companyId }] : [],
    }),
    deleteCompany: build.mutation({
      query: ({ companyId }) => ({
        url: `/companies/${companyId}/force`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { companyId }) =>
        result ? [{ type: 'Company', id: companyId }] : [],
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useGetCompanyByIdQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useUploadCompanyLogoMutation,
  useRestoreCompanyMutation,
  useArchiveCompanyMutation,
  useDeleteCompanyMutation,
  useGetCompanyByUserIdQuery,
} = companiesAPI;
