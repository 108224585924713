import { StyledDialog } from '@blackhyve/common';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

/**
 * Auto scheduler date dialog
 * @param {String} id
 * @returns
 */
const SelectAutoSchedulerDateDialog = ({ id, open, handleClose, handleSave, excitingValue }) => {
  const [selectDateType, setSelectedDateType] = useState(excitingValue);

  const onClose = (event, reason) => {
    if (reason !== 'escapeKeyDown' && reason !== 'backdropClick') {
      handleClose();
    }
  };

  return (
    <StyledDialog
      dialogOverrides={{ maxWidth: 'xs' }}
      handleClose={onClose}
      open={open}
      title={'Settings'}
      actions={
        <>
          <Button size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              handleSave(selectDateType);
              handleClose();
            }}
          >
            Save
          </Button>
        </>
      }
    >
      <Grid container justifyContent={'center'} spacing={1}>
        <Grid container item justifyContent="center" xs={12}>
          <Typography fontWeight={'600'} variant={'h6'}>
            Automatically shift tasks based on
          </Typography>
          <RadioGroup
            row
            name="autoschedulerDates"
            style={{ justifyContent: 'center' }}
            value={selectDateType}
            onChange={(event) => {
              setSelectedDateType(event.target.value);
            }}
          >
            <FormControlLabel control={<Radio />} label="Forecasted Dates" value="forecasted" />
            <FormControlLabel control={<Radio />} label="Scheduled Dates." value="schedule" />
          </RadioGroup>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};

export default SelectAutoSchedulerDateDialog;
