const { getChildren } = require('./getChildren');
const { hasChild } = require('./hasChild');

function calculateTaskProgress(task, gantt) {
  if (hasChild(task.id, gantt)) {
    const children = getChildren(task.id, gantt).filter((task) => task.type !== 'placeholder');
    const { totalWork, completedWork } = children.reduce(
      ({ completedWork, totalWork }, child) => ({
        completedWork: completedWork + (parseFloat(child.earned_days) || 0),
        totalWork: totalWork + (parseFloat(child.totalWork) || 0),
      }),
      {
        completedWork: 0,
        totalWork: 0,
      }
    );
    const progress = completedWork / totalWork;
    return {
      progress: progress && isFinite(progress) ? parseFloat(progress.toFixed(2)) : 0,
      totalWork,
      completedWork,
    };
  } else {
    return {
      progress: task.progress || 0,
      totalWork: task.work_days || 0,
    };
  }
}
module.exports = { calculateTaskProgress };
