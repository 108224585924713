import { createEntityAdapter } from '@reduxjs/toolkit';
import { getSocket } from 'helpers/websockets';
const { api, providesList } = require('api');

const dependencyAdapter = createEntityAdapter();
const dependencyAPI = api.enhanceEndpoints({ addTagTypes: ['Dependency'] }).injectEndpoints({
  endpoints: (build) => ({
    getDependency: build.query({
      query: ({ dependencyId }) => `/dependencies/${dependencyId}`,
      providesTags: (result, error, args) =>
        result ? [{ type: 'Dependency', id: result?.id }] : [],
      transformResponse: (response) => response.data,
    }),

    subscribeDependencies: build.query({
      query: ({ projectId } = {}) => ({ url: `/dependencies`, params: { project: projectId } }),
      transformResponse: (response) => {
        return dependencyAdapter.setAll(dependencyAdapter.getInitialState(), response.data);
      },
      providesTags: (response) => (response ? providesList(response.ids, 'Dependency') : []),
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState }
      ) {
        const { workspaceId } = getState().auth;
        await cacheDataLoaded;
        const socket = getSocket();
        const channelNames = arg?.projectId
          ? [`workspaces.${workspaceId}.projects.${arg?.projectId}.tasks`]
          : arg?.projectIds?.map(
            (projectId) => `workspaces.${workspaceId}.projects.${projectId}.tasks`
          );

        try {

          const handleCreateDependency = (data) => {
            updateCachedData((draft) => {
              dependencyAdapter.upsertMany(draft, data);
              api.util.updateQueryData('subscribeTasks', { projectId: arg?.projectId }, (draft) => {
                draft.isTasksOutdated = false;
              });
            });
          };
          const handleUpdateDependency = (data) => {
            updateCachedData((draft) => {
              dependencyAdapter.upsertMany(draft, data);
              api.util.updateQueryData('subscribeTasks', { projectId: arg?.projectId }, (draft) => {
                draft.isTasksOutdated = false;
              });
            });
          };
          const handleDeleteDependency = (data) => {
            updateCachedData((draft) => {
              dependencyAdapter.removeMany(draft, data);
              api.util.updateQueryData('subscribeTasks', { projectId: arg?.projectId }, (draft) => {
                draft.isTasksOutdated = false;
              });
            });
          };
          const eventListeners = {
            DependencyCreated: handleCreateDependency,
            DependencyUpdated: handleUpdateDependency,
            DependencyDeleted: handleDeleteDependency,
          };
          channelNames?.forEach((channelName) => {
            const connectedChannel = socket.join(channelName);
            for (const [event, listener] of Object.entries(eventListeners)) {
              const eventListener = (data) => {
                listener(data);
              };
              connectedChannel.listen(event, eventListener);
            }
          });
        } catch (error) {
          console.log('Error while connecting to sockets =>', error);
        }
        await cacheEntryRemoved;
        channelNames?.forEach((channelName) => {
          socket?.leave(channelName);
        });
      },
    }),

    createDependency: build.mutation({
      query: ({ dependency }) => ({
        url: `/dependencies`,
        method: 'POST',
        body: dependency,
      }),
      invalidatesTags: (result, error, args) =>
        result ? [{ type: 'Dependency', id: 'LIST' }] : [],
      transformResponse: (response) => response.data,
    }),

    createDependencies: build.mutation({
      query: ({ dependencies }) => ({
        url: `/dependencies/bulk`,
        method: 'POST',
        body: dependencies,
      }),
      invalidatesTags: (result, error, args) =>
        result ? [{ type: 'Dependency', id: 'LIST' }] : [],
      transformResponse: (response) => response.data,
    }),

    updateDependencies: build.mutation({
      query: ({ dependencies }) => ({
        url: `/dependencies`,
        method: 'PATCH',
        body: dependencies,
      }),
      invalidatesTags: (result, error, args) =>
        result ? providesList(args.dependencies, 'Dependency') : [],
      transformResponse: (response) => response.data,
    }),

    deleteDependencies: build.mutation({
      query: ({ dependencies }) => ({
        url: `/dependencies`,
        method: 'DELETE',
        body: { ids: dependencies },
      }),
      invalidatesTags: (result, error, args) =>
        result ? [{ type: 'Dependency', id: 'LIST' }] : [],
    }),

    syncDependencies: build.mutation({
      query: ({ taskId, dependencies }) => ({
        url: `tasks/${taskId}/dependencies/sync`,
        method: 'POST',
        body: { dependencies },
      }),
      invalidatesTags: (result, error, args) =>
        result ? providesList(args.dependencies, 'Dependency') : [],
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetDependencyQuery,
  useSubscribeDependenciesQuery,
  useLazySubscribeDependenciesQuery,
  useCreateDependencyMutation,
  useCreateDependenciesMutation,
  useDeleteDependenciesMutation,
  useUpdateDependenciesMutation,
  useSyncDependenciesMutation,
} = dependencyAPI;
