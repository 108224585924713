import { Button, DialogActions } from '@mui/material';
import Popover from '@mui/material/Popover';
import makeStyles from '@mui/styles/makeStyles';
import { useGetWorkspaceCompanyQuery } from 'features/projectCompanies/api/projectCompanies.api';
import { SelectUsersAutocompleteList } from 'features/users/components/SelectUsersList';
import { createRef, forwardRef, useImperativeHandle, useState } from 'react';
import ganttStore from '../../ganttStore';

export const loadResponsibleUsersMenuType = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  let companyEditorRef;
  gantt.config.editor_types.responsible_users = {
    show: function (id, column, config, placeholder) {
      const task = gantt.getTask(id);
      companyEditorRef = createRef();
      gantt.config.external_render.renderElement(
        <CompanyMenuPopover
          anchorEl={placeholder}
          initialSelected={task.responsible_users}
          key={`${task.id}-responsible-users-menu`}
          projectId={task.project_id}
          ref={companyEditorRef}
          onClose={() => gantt.ext.inlineEditors.save()}
        ></CompanyMenuPopover>,
        placeholder
      );
    },
    hide: function () {
      // called when input is hidden
      // destroy any complex editors or detach event listeners from here
    },

    set_value: function (value, id, column, node) {
      // set input value
      if (companyEditorRef.current) {
        companyEditorRef?.current?.setValue(new Set(value || []));
      }
    },

    get_value: function (id, column, node) {
      return companyEditorRef?.current?.getValue();
      // return input value
    },

    is_changed: function (value, id, column, node) {
      //called before save/close. Return true if new value differs from the original one
      //returning true will trigger saving changes, returning false will skip saving
      const currentValue = this.get_value() || [];
      const oldValue = value || [];
      if (currentValue.length !== oldValue.length) {
        return true;
      }
      currentValue.sort();
      oldValue.sort();
      return !currentValue.every((element, index) => element === oldValue[index]);
    },

    is_valid: function (value, id, column, node) {
      // validate, changes will be discarded if the method returns false
      return Array.isArray(this.get_value());
    },
    focus: function (node) {},
  };
};

const useStyles = makeStyles(() => ({
  paper: {
    width: 400,
    overflowY: 'auto',
    borderRadius: '8px',
    padding: '10px 10px',
  },
}));

const CompanyMenuPopover = forwardRef(({ initialSelected, anchorEl, onClose, projectId }, ref) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState(initialSelected || []);

  const { data: company = { users: [] }, isLoading } = useGetWorkspaceCompanyQuery({ projectId });

  const handleClose = () => {
    setOpen(false);
    onClose(selected);
  };

  const handleSubmit = () => {
    handleClose();
  };

  const handleCancel = () => {
    setOpen(false);
    setSelected(initialSelected);
    onClose(initialSelected);
  };

  useImperativeHandle(ref, () => ({
    getValue: () => [...selected],
    setValue: setSelected,
  }));

  return (
    <>
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          classes={{ paper: classes.paper }}
          open={open}
          onClose={handleSubmit}
        >
          <SelectUsersAutocompleteList
            isLoading={isLoading}
            selected={selected}
            setSelected={setSelected}
            users={company.users}
          />
          <DialogActions>
            <Button size="small" onClick={handleCancel}>
              Cancel
            </Button>
            <Button size="small" variant="contained" onClick={handleSubmit}>
              Update
            </Button>
          </DialogActions>
        </Popover>
      )}
    </>
  );
});
