
import { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import format from 'date-fns/format';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetPhaseZonesQuery, useGetZonesQuery } from 'features/locations/store/zone.api';
import { useCreatePhaseMutation, useDeletePhaseMutation, useGetPhasesQuery, useUpdatePhaseMutation } from 'features/locations/store/phase.api';
import { useGetLocationsQuery } from 'features/locations/store/location.api';
import { ProductionBoardRow, ProductionBoardRowChildren, ProductionBoardSkeletonList } from './ProductionBoardRow';
import ProductionBoardCreateLBSDialog from './ProductionBoardCreateLBSDialog';

/**
 * Production Board Locations Details
 * @returns 
 */
const ProductionBoardLocation = () => {

  const workspaceId = Number(useParams().workspaceId) || undefined;
  const projectId = Number(useParams().projectId) || undefined;
  const locationId = Number(useParams().locationId) || undefined;
  const [openEditPhaseDialog, setOpenEditPhaseDialog] = useState({ open: false });
  const [openCreatePhaseDialog, setOpeCreatePhaseDialog] = useState(false);

  const { location, isLoading: isLoadingLocation } = useGetLocationsQuery({ projectId }, {
    selectFromResult: ({ data: locations, isFetching, isLoading }) => ({
      location: locations?.find((location) => location.id === locationId),
      isFetching,
      isLoading
    }),
  });

  const { data: phases, isLoading: isLoadingPhase, isFetching: isFetchingPhases } = useGetPhasesQuery({ locationId })
  const [createPhase, { isLoading: isCreating }] = useCreatePhaseMutation();
  const [updatePhase, { isLoading: isUpdatingPhase }] = useUpdatePhaseMutation();
  const [deletePhase] = useDeletePhaseMutation();

  const handleSaveAndAddAnother = (phase, e) => handleCreatePhase(phase, e, true);
  const handleCreatePhase = (data, e, preventClose) => {
    createPhase({
      ...data, locationId
    }).then((response) => {
      if (!preventClose) setOpeCreatePhaseDialog(false);
    });
  };

  const handleUpdatePhase = (data) => updatePhase({ ...data, locationId }).then(() => setOpenEditPhaseDialog(false));
  const handleDelete = (id) => deletePhase({ locationId: locationId, phaseId: id })

  const handleOpenCreatePhaseDialog = () => setOpeCreatePhaseDialog(true)
  const handleClosCreatePhaseDialog = () => setOpeCreatePhaseDialog(false);

  const handleOpenEditPhase = (entityObj) => setOpenEditPhaseDialog({ entityObj, open: true })
  const handleCloseEditPhase = () => setOpenEditPhaseDialog({ entityObj: null, open: false })

  return isLoadingLocation ? <CircularProgress className='loading' /> :
    <Container maxWidth={'xl'}>
      <Grid container item xs={12} gap={1}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }} align="center">{location?.name}</Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button variant="contained" onClick={handleOpenCreatePhaseDialog}>+ Add Phase</Button>
        </Grid>
        <Grid container item xs={12} gap={3}>
          {(!isLoadingPhase && isFetchingPhases && (
            <Box sx={{ width: '100%', }}>
              <LinearProgress />
            </Box>
          ))}
          {isLoadingPhase ? <ProductionBoardSkeletonList /> :
            phases?.length ?
              phases?.map((phase) => {
                const link = `/workspace/${workspaceId}/projects/${projectId}/production-board/locations/${locationId}/phases/${phase?.id}`
                return <ProductionBoardRow
                  key={phase?.id}
                  entityObj={phase}
                  pageLink={link}
                  onDelete={handleDelete}
                  onEdit={handleOpenEditPhase}
                >
                  <ProductionBoardPhaseZones phaseId={phase.id} parentLink={link} />
                </ProductionBoardRow>
              }) : <Alert sx={{ textAlign: 'center', m: 'auto' }} severity="warning">No phases have been added.</Alert>}

        </Grid>
        {openCreatePhaseDialog &&
          <ProductionBoardCreateLBSDialog
            open={openCreatePhaseDialog}
            handleClose={handleClosCreatePhaseDialog}
            type="Phase"
            handleSave={handleCreatePhase}
            handleSaveAndAddAnother={handleSaveAndAddAnother}
            isSaving={isCreating}
          />}
        {openEditPhaseDialog?.open &&
          <ProductionBoardCreateLBSDialog
            open={openEditPhaseDialog?.open}
            handleClose={handleCloseEditPhase}
            entityObj={openEditPhaseDialog?.entityObj}
            type="Phase"
            handleSave={handleUpdatePhase}
            isSaving={isUpdatingPhase}
          />}

      </Grid>
    </Container>
}


/**
 * Production Board Phase Zones
 * @param {*} param0 
 * @returns 
 */
const ProductionBoardPhaseZones = ({ phaseId, parentLink }) => {

  const { data: zones = [], isLoading } = useGetPhaseZonesQuery({ phaseId });

  return <Grid container item xs={12} gap={1}>
    {isLoading ?
      <ProductionBoardSkeletonList isChildrenList={false} /> :
      zones?.map((zone) => {
        const pageLink = `${parentLink}/zones/${zone.id}`
        return <ProductionBoardRowChildren key={zone.id} {...zone} pageLink={pageLink} />
      })}
  </Grid>

}


export default ProductionBoardLocation;