import { useGetWorkspaceFeaturesQuery } from 'features/workspace/api/workspace.api';

const ANALYTICS = 'analytics';
const BUDGETS = 'budgets';
const UNDO = 'undo';

export const features = { BUDGETS, ANALYTICS, UNDO };

// Mapping of feature name and env variable...
const featureToEnv = {
  [BUDGETS]: 'REACT_APP_FEATURE_BUDGETS',
  [UNDO]: 'REACT_APP_FEATURE_UNDO',
};

export const useFeatureFlag = (name) => {
  const { data: workspaceFeatures = {} } = useGetWorkspaceFeaturesQuery();

  return (
    [true, 1, 'true', '1'].includes(process.env[featureToEnv[name]]) ||
    [true, 1, 'true', '1'].includes(workspaceFeatures[name])
  );
};

const FeatureFlag = ({ name, children }) => {
  const isEnabled = useFeatureFlag({ name });

  if (isEnabled) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default FeatureFlag;
