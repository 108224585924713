import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const HeaderGrid = styled(Grid)(
  ({ theme }) => ({
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#fff',
  }),
  { name: 'HeaderGrid' }
);

/**
 * Page panel header
 * @param {[object]} buttonItems
 * @param {string} buttonItems.title
 * @param {object} buttonItems.handleClick
 * @param {number} buttonItems.width
 * @param {object} searchHandler
 * @param {string} searchPlaceHolder
 * @param {boolean} isSearchEnabled
 * @param {JSX Element} children
 * @returns
 */

const PagePanelHeaderComponent = (props) => {
  const { buttonItems } = props;

  return (
    <HeaderGrid container justifyContent="space-between">
      <Grid item xs={12} container alignItems="center">
        {buttonItems &&
          buttonItems.map(({ title, handleClick, width, size = 'small' }, index) => {
            return (
              <Grid key={`${title}-${index}`} item xs={width} paddingLeft={3} my={1}>
                <Button
                  key={title}
                  color="primary"
                  style={{ fontSize: '10px' }}
                  onClick={handleClick}
                  size={size}
                  disableElevation
                  variant="contained"
                >
                  {title}
                </Button>
              </Grid>
            );
          })}
        {props.children}
      </Grid>
    </HeaderGrid>
  );
};

export default PagePanelHeaderComponent;
