const { api, providesList } = require('api');

const recipeAPI = api.enhanceEndpoints({ addTagTypes: ['Recipe'] }).injectEndpoints({
  endpoints: (build) => ({
    getRecipe: build.query({
      query: ({ recipeId }) => `/recipes/${recipeId}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, args) => (!error ? [{ type: 'Recipe', id: result.id }] : []),
    }),

    getRecipes: build.query({
      query: ({ params: { order_by, per_page, q, page, ...params } } = {}) => ({
        url: `/recipes`,
        params: { order_by, per_page, page, q, ...params },
      }),
      transformResponse: ({ data, meta }) => ({
        data,
        meta: {
          from: meta.from,
          lastPage: meta.lastPage,
          perPage: meta.per_page,
          to: meta.to,
          total: meta.total,
        },
      }),
      providesTags: (result, error, args) => (!error ? providesList(result.data, 'Recipe') : []),
    }),

    createRecipe: build.mutation({
      query: ({ recipe }) => ({
        url: `/recipes`,
        method: 'POST',
        body: recipe,
      }),
      invalidatesTags: (result, error, args) => (!error ? [{ type: 'Recipe', id: 'LIST' }] : []),
    }),

    duplicateRecipe: build.mutation({
      query: ({ recipeId }) => ({
        url: `recipes/${recipeId}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, args) => (!error ? [{ type: 'Recipe', id: 'LIST' }] : []),
    }),

    updateRecipe: build.mutation({
      query: ({ recipeId, recipe }) => ({
        url: `/recipes/${recipeId}`,
        method: 'PATCH',
        body: recipe,
      }),
      invalidatesTags: (result, error, args) =>
        !error ? [{ type: 'Recipe', id: args.recipeId }] : [],
    }),

    deleteRecipe: build.mutation({
      query: ({ recipeId }) => ({
        url: `/recipes/${recipeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, args) => (!error ? [{ type: 'Recipe', id: 'LIST' }] : []),
    }),
  }),
});

export const {
  useCreateRecipeMutation,
  useDuplicateRecipeMutation,
  useGetRecipeQuery,
  useGetRecipesQuery,
  useUpdateRecipeMutation,
  useDeleteRecipeMutation,
} = recipeAPI;
