import LocationLevelIconComponent from '@blackhyve/common/src/icons/LocationLevel';
import SqftIconComponent from '@blackhyve/common/src/icons/SqftIcon';
import TaskIconComponent from '@blackhyve/common/src/icons/Task';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DragIndicator from '@mui/icons-material/DragIndicator';
import FunctionsIcon from '@mui/icons-material/Functions';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import styled from '@mui/styles/styled';
import Box from '@mui/system/Box';
import DisplayWithEditAccess, { EditContext } from 'components/common/v3/DisplayWithEditAccess';
import InlineEditCardTitle from 'components/common/v3/InlineEditCardTitle';
import SkeletonListComponent from 'components/common/v3/SkeletonListComponent';
import isEmpty from 'lodash/isEmpty';
import { Fragment, memo, useContext, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ColorDropdown from '../../../components/ColorDropdown';
import { useCreateAreaMutation, useGetAreasQuery, useReorderAreaMutation } from '../store/area.api';
import {
  useDeleteZoneMutation,
  useDuplicateZoneMutation,
  useUpdateZoneMutation,
} from '../store/zone.api';
import { getCroppedImg } from '../utils/cropImageHelper';
import AddEditLbsDialog from './AddEditLbsDialog';
import AreaRow from './AreaRow';
import LbsMoreOptions from './LbsMoreOptions';

const TaskListCountBox = styled(Box)(({ theme }) => ({
  marginTop: 'auto',
  marginBottom: 'auto',
  fontWeight: 'bold',
  cursor: 'pointer',
  verticalAlign: 'middle',
  display: 'inline-flex',
  paddingLeft: '0px',
  paddingRight: '5px',
  borderRadius: '3px',
  marginLeft: '15px',
  paddingTop: '0px',
  paddingBottom: '0px',
  height: '19px',
  border: '0.5px solid',
  borderColor: '#b3b3b3',
  boxSizing: 'border-box',
}));

export const RowContainer = styled(Grid)(({ theme }) => ({
  height: '40px',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

const ZoneContainer = styled(Grid)(({ theme }) => ({
  border: '.5px solid #b3b3b3',
  borderTop: 'none',
  borderRadius: '.5px',
  backgroundColor: 'white',
  boxShadow: theme.shadows[2],
  '&:first-child': {
    borderTop: '.5px solid #b3b3b3',
  },
}));

const TaskCountLabel = styled('span')(({ theme }) => ({
  fontSize: '10px',
  padding: '1px',
  color: 'rgba(0, 0, 0, 0.6)',
  fontWeight: '500',
  cursor: 'pointer',
  margin: 'auto',
}));

const ZoneRow = memo(({ zone, dragHandleProps, locationId, projectId }) => {
  const canEdit = useContext(EditContext);
  const [isOpen, setIsOpen] = useState(false);

  const {
    data: zoneAreas = [],
    isLoading,
    isFetching,
  } = useGetAreasQuery({ zoneId: zone?.id }, { skip: zone?.areas });

  const areas = zone?.areas || zoneAreas;
  const [reorderArea, { isLoading: isGettingReordered }] = useReorderAreaMutation();

  const handleUpdateAreaPriority = (data) => {
    if (isEmpty(data.destination)) {
      return false;
    }
    const parentId = data.destination.droppableId.split('-')[1];
    const areaIds = [...areas].map((area) => area.id);
    const destinationIndex = data.destination.index;
    const sourceIndex = data.source.index;
    const id = areaIds.splice(sourceIndex, 1);
    areaIds.splice(destinationIndex, 0, ...id);
    reorderArea({
      areas: areaIds,
      zoneId: parentId,
      locationId,
      projectId,
      sourceIndex,
      destinationIndex,
    });
  };

  return (
    <ZoneContainer container>
      <HeadingComponent
        dragHandleProps={dragHandleProps}
        isOpen={isOpen}
        locationId={locationId}
        noOfAreas={areas?.length}
        projectId={projectId}
        setIsOpen={setIsOpen}
        zone={zone}
      />
      {isLoading ? (
        <SkeletonListComponent />
      ) : (
        <DragDropContext onDragEnd={handleUpdateAreaPriority}>
          <Droppable droppableId={'zone-' + zone?.id} type={`AREA-` + zone?.id}>
            {(provided) => (
              <Grid container {...provided.droppableProps} ref={provided.innerRef}>
                {!isLoading && isFetching && (
                  <Box position={'relative'} width={'100%'}>
                    <Box sx={{ width: '100%', position: 'absolute', top: '0px', left: '0px' }}>
                      <LinearProgress />
                    </Box>
                  </Box>
                )}
                {isOpen && areas && areas.length > 0
                  ? areas.map((area, index) => {
                      return (
                        <Draggable
                          draggableId={`area-${area?.id}`}
                          index={index}
                          isDragDisabled={!canEdit || isFetching || isGettingReordered}
                          key={`area-${area?.id}`}
                        >
                          {(provided, snapshot) => (
                            <Grid
                              {...provided.draggableProps}
                              container
                              ref={provided.innerRef}
                              style={
                                snapshot.isDragging
                                  ? {
                                      border: '.5px solid #b3b3b3',
                                      ...provided.draggableProps.style,
                                    }
                                  : {
                                      borderTop: '.5px solid #b3b3b3',
                                      borderBottom: '.5px solid transparent',
                                      ...provided.draggableProps.style,
                                    }
                              }
                            >
                              <AreaRow
                                area={area}
                                dragHandleProps={provided.dragHandleProps}
                                key={`area-row-items-${area?.id}`}
                                locationId={locationId}
                                projectId={projectId}
                                zoneId={zone.id}
                              />
                            </Grid>
                          )}
                        </Draggable>
                      );
                    })
                  : null}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </ZoneContainer>
  );
});

const HeadingComponent = ({
  zone,
  dragHandleProps,
  isOpen,
  setIsOpen,
  noOfAreas,
  locationId,
  projectId,
}) => {
  const canEdit = useContext(EditContext);

  const [deleteZone, { isLoading: isDeleting }] = useDeleteZoneMutation();
  const [duplicateZone, { isLoading: isDuplicating }] = useDuplicateZoneMutation();
  const [updateZone, { isLoading: isUpdating }] = useUpdateZoneMutation();
  const [createArea, { isLoading: isAreaGettingCreated }] = useCreateAreaMutation();

  const [openAddAreaDialog, setOpenAddAreaDialog] = useState(false);

  const id = zone?.id;

  const handleUpdate = async (updatedZone) => {
    let croppedImage = undefined;
    const mapViewData = JSON.stringify(updatedZone.map_view_data);
    const zoneOriginalMap = updatedZone.original_map || zone.original_map;
    if (mapViewData && zoneOriginalMap && zone.map_view_data !== mapViewData) {
      croppedImage = await getCroppedImg(
        zoneOriginalMap?.original_url || URL.createObjectURL(zoneOriginalMap),
        updatedZone.map_view_data.croppedAreaPixels,
        updatedZone.map_view_data.rotate,
        zoneOriginalMap?.file_name || zoneOriginalMap?.name || `${zone.id}-map.png`
      );
    }
    updateZone({
      zoneId: zone.id,
      locationId: zone.location_id,
      zone: {
        ...updatedZone,
        map_view_data: mapViewData,
        map: croppedImage?.croppedImageFile,
        sqft: noOfAreas ? updatedZone.sqft : updatedZone.input_sqft,
      },
    });
  };

  const handleDelete = () => {
    deleteZone({ zoneId: zone?.id, locationId, projectId });
  };

  const handleDuplicate = () => {
    duplicateZone({ zoneId: zone?.id, locationId, projectId });
  };

  const handleUpdateColor = (selectedColor) => {
    updateZone({
      zoneId: zone.id,
      locationId,
      projectId,
      zone: { id, color: selectedColor, locationId: zone.location_id },
    });
  };

  const handleSaveAndAddAnother = (area, e) => {
    handleSave(area, e, true);
  };

  const handleSave = (data, e, preventClose = false) => {
    createArea({
      ...data,
      zoneId: zone?.id,
      locationId: zone.location_id,
      sqft: data.input_sqft,
    }).then(() => {
      setIsOpen(true);
      if (!preventClose) setOpenAddAreaDialog(false);
    });
  };

  return (
    <RowContainer container item>
      {isDeleting || isDuplicating || isUpdating ? (
        <Skeleton className="skeleton-row" variant="text" />
      ) : (
        <Fragment>
          <Grid container item alignItems={'center'} xs={7}>
            <Grid item {...dragHandleProps}>
              <DragIndicator sx={{ color: 'grey.600' }} />
            </Grid>
            <Grid item>
              {noOfAreas > 0 ? (
                <IconButton
                  aria-label="Filter"
                  className="iconImage"
                  disableFocusRipple={true}
                  disableRipple={true}
                  size="medium"
                  sx={{ padding: 0 }}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {isOpen ? <ArrowDropDown /> : <ArrowRightIcon />}
                </IconButton>
              ) : null}
            </Grid>
            <Grid item>
              <LocationLevelIconComponent
                height={16}
                style={{ padding: '5px', display: 'block' }}
              />
            </Grid>
            <Grid item className="card-title">
              <InlineEditCardTitle
                fieldName={'name'}
                handleUpdate={handleUpdate}
                isDefaultEdit={id < 0}
                isEditable={canEdit}
                requestParameters={{ id: zone?.id }}
                textToDisplay={zone?.name}
              />
              <TaskListCountBox>
                <IconButton
                  className={'task-list-icon'}
                  disableFocusRipple={true}
                  disableRipple={true}
                  size="small"
                >
                  <TaskIconComponent className="task-image" height={11} width={11} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: '10px',
                    color: 'grey.700',
                    cursor: 'pointer',
                    margin: 'auto',
                  }}
                >
                  {noOfAreas}
                </Typography>
                <DisplayWithEditAccess>
                  <Divider
                    className={'area-divider'}
                    orientation="vertical"
                    style={{ marginLeft: 2 }}
                  />
                  <TaskCountLabel
                    onClick={() => {
                      setOpenAddAreaDialog(true);
                    }}
                  >
                    + New Area
                  </TaskCountLabel>
                </DisplayWithEditAccess>
              </TaskListCountBox>
            </Grid>
          </Grid>
          <Grid container item alignItems={'center'} justifyContent={'center'} xs={5}>
            <Grid container item justifyContent={'center'} xs={3}>
              <ColorDropdown
                color={zone?.color}
                disabled={!canEdit}
                handleSaveColor={handleUpdateColor}
              />
            </Grid>
            <Grid container item justifyContent={'center'} xs={2}>
              <Typography variant="subtitle2"> {zone?.priority}</Typography>
            </Grid>
            <Grid container item justifyContent={'center'} xs={3}>
              {zone?.input_sqft ? (
                <Box alignItems={'center'} display="flex">
                  <SqftIconComponent style={{ marginRight: '2px' }} />
                  <Typography variant="subtitle2">{zone?.input_sqft}</Typography>
                </Box>
              ) : (
                '-'
              )}
            </Grid>
            <Grid container item justifyContent={'center'} xs={3}>
              {zone?.sqft ? (
                <Box alignItems={'center'} display="flex">
                  <FunctionsIcon htmlColor={'#B3B3B3'} />
                  <Typography sx={{ fontWeight: 'bold' }} variant="subtitle2">
                    {' '}
                    {zone?.sqft}
                  </Typography>
                </Box>
              ) : (
                '-'
              )}
            </Grid>
            <Grid container item alignItems={'center'} justifyContent={'flex-end'} xs={1}>
              <DisplayWithEditAccess>
                <LbsMoreOptions
                  entityObj={zone}
                  handleDelete={handleDelete}
                  handleDuplicate={handleDuplicate}
                  handleSave={handleUpdate}
                  isLoading={isDeleting || isDuplicating}
                  type="Zone"
                />
              </DisplayWithEditAccess>
            </Grid>
          </Grid>
        </Fragment>
      )}
      {openAddAreaDialog && (
        <AddEditLbsDialog
          handleClose={() => setOpenAddAreaDialog(false)}
          handleSave={handleSave}
          handleSaveAndAddAnother={handleSaveAndAddAnother}
          isSaving={isAreaGettingCreated}
          open={openAddAreaDialog}
          type="Area"
        />
      )}
    </RowContainer>
  );
};

export default ZoneRow;
