export const EFFORT_TAGS = {
  'x-small': '#B7E1CD',
  small: '#6D9EEB',
  medium: '#E69138',
  large: '#C27BA0',
  'x-large': '#7BC289',
};

export const EFFORT_TAG_PERCENTAGE = {
  'x-small': 0.003,
  small: 0.005,
  medium: 0.008,
  large: 0.013,
  'x-large': 0.034,
};

export const STATES = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const TASK_COLORS = [
  { name: "Carpenter Succo", value: "#1637a2" },
  { name: "Carpenter Finish", value: "#2B65EC" },
  { name: "Carpenter Framing", value: "#4b70e7" },
  { name: "Carpenter Siding", value: "#819bee" },
  { name: "Block Mason", value: "#437C17" },
  { name: "Stone Mason", value: "#6AA121" },
  { name: "Cement Mason", value: "#8FCA5A" },
  { name: "Concrete Finisher", value: "#99C68E" },
  { name: "Terrazzo Installer", value: "#829F82" },
  { name: "Acoustical Ceiling Installer", value: "#08A04B" },
  { name: "Brick Mason", value: "#54C571" },
  { name: "Construction Laborer", value: "#a0dfb0" },
  { name: "IT Installer", value: "#e2b33c" },
  { name: "Electrician", value: "#c3941d" },
  { name: "HVAC Controls Installer", value: "#8e6c15" },
  { name: "Equipment Operator Crane", value: "#FF8585" },
  { name: "Equipment Operator Material", value: "#ff3333" },
  { name: "Equipment Operator Excavation", value: "#B80000" },
  { name: "Carpet Installer", value: "#F2D58E" },
  { name: "Wood Floor Installer", value: "#C0A35C" },
  { name: "Insulation Bldg Installer", value: "#BB61A0" },
  { name: "Insulation Pipe / Duct Installer", value: "#892F6E" },
  { name: "Boilermaker", value: "#E08E45" },
  { name: "Iron Worker", value: "#EA984F" },
  { name: "Specialty Welder", value: "#B8661D" },
  { name: "Landscaper", value: "#d8c600" },
  { name: "Painter", value: "#ac9e00" },
  { name: "Plumber", value: "#007AA3" },
  { name: "Pool Plumber", value: "#00a8e0" },
  { name: "Pipefitter", value: "#1FC7FF" },
  { name: "Roofer Rubber", value: "#56494c" },
  { name: "Roofer Shingle", value: "#79676b" },
  { name: "Drywaller", value: "#94999e" },
  { name: "Fence Erector", value: "#6b7076" },
  { name: "Security System Installer", value: "#9B7EDE" },
  { name: "Steamfitter", value: "#462692" },
  { name: "Sheet Metal Worker", value: "#371d72" },
  { name: "Glazier", value: "#6A4E60" },
  { name: "Tile Installer", value: "#EB6D1E" },
  { name: "Well Driller", value: "#0EAA9A" },
  { name: "Asphalt Paver", value: "#272838" },
  { name: "Civil Utilizes Installer", value: "#5E3A31" },
  { name: "Drywall Taper", value: "#38304A" },
  { name: "Elevator Mechanic", value: "#FC368F" },
  { name: "Specialty Installer", value: "#859992" },
]

export const COLORS = [
  { name: "Sapphire", value: "#1637a2" },
  { name: "Cerulean", value: "#2B65EC" },
  { name: "Cobalt", value: "#4b70e7" },
  { name: "Periwinkle", value: "#819bee" },
  { name: "Forest", value: "#437C17" },
  { name: "Lime", value: "#6AA121" },
  { name: "Fern", value: "#8FCA5A" },
  { name: "Moss", value: "#99C68E" },
  { name: "Pewter", value: "#829F82" },
  { name: "Emerald", value: "#08A04B" },
  { name: "Jade", value: "#54C571" },
  { name: "Seagreen", value: "#a0dfb0" },
  { name: "Gold", value: "#e2b33c" },
  { name: "Brass", value: "#c3941d" },
  { name: "Bronze", value: "#8e6c15" },
  { name: "Coral", value: "#FF8585" },
  { name: "Red", value: "#ff3333" },
  { name: "Crimson", value: "#B80000" },
  { name: "Sand", value: "#F2D58E" },
  { name: "Beige", value: "#C0A35C" },
  { name: "Plum", value: "#BB61A0" },
  { name: "Mauve", value: "#892F6E" },
  { name: "Copper", value: "#E08E45" },
  { name: "Apricot", value: "#EA984F" },
  { name: "Tawny", value: "#B8661D" },
  { name: "Yellow", value: "#d8c600" },
  { name: "Goldenrod", value: "#ac9e00" },
  { name: "Azure", value: "#007AA3" },
  { name: "Skyblue", value: "#00a8e0" },
  { name: "Cyan", value: "#1FC7FF" },
  { name: "Charcoal", value: "#56494c" },
  { name: "Slate", value: "#79676b" },
  { name: "Silver", value: "#94999e" },
  { name: "Steel", value: "#6b7076" },
  { name: "Lilac", value: "#9B7EDE" },
  { name: "Indigo", value: "#462692" },
  { name: "Navy", value: "#371d72" },
  { name: "Plum", value: "#6A4E60" },
  { name: "Tangerine", value: "#EB6D1E" },
  { name: "Turquoise", value: "#0EAA9A" },
  { name: "Slategray", value: "#272838" },
  { name: "Mahogany", value: "#5E3A31" },
  { name: "Eggplant", value: "#38304A" },
  { name: "Magenta", value: "#FC368F" },
  { name: "Taupe", value: "#859992" },

]

export const ACCEPT_INPUT_IMAGE = "image/png, image/jpeg"

