import ganttStore from './ganttStore';

export const defaultLayoutConfig = {
  css: 'gantt_container',
  cols: [
    {
      width: 600,
      rows: [
        {
          view: 'grid',
          scrollX: 'gridScroll',
          scrollable: true,
          scrollY: 'scrollVer',
        },
        { view: 'scrollbar', id: 'gridScroll', group: 'horizontal' },
      ],
    },
    { resizer: true, width: 1 },
    {
      rows: [
        { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
        { view: 'scrollbar', id: 'scrollHor', group: 'horizontal' },
      ],
    },
    { view: 'scrollbar', id: 'scrollVer' },
  ],
};

/* Set gantt layout
 * @param {Object} [layoutConfig=defaultLayoutConfig]
 */
export const setGanttLayout = (layoutConfig, ganttId) => {
  const gantt = ganttStore.getGantt(ganttId)
  if (layoutConfig) {
    gantt.config.layout = layoutConfig;
  } else {
    gantt.config.layout = defaultLayoutConfig;
  }
};
