import { axiosRequestHandler } from '../../../helpers/apiHelper';

/**
 * Fetch Recipes
 * @returns
 */
export const FetchRecipesAPI = async () => {
  try {
    return await axiosRequestHandler(`recipes`, 'get');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Fetch Recipes
 * @param {*} data
 * @param {Number} data.recipeId
 * @returns
 */
export const FetchRecipeAPI = async (data) => {
  try {
    return await axiosRequestHandler(`recipes/${data.recipeId}`, 'get');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Create Recipes
 * @param {*} data
 * * @param {*} data.name
 * * @param {*} data.percent
 * @returns
 */
export const CreateRecipeAPI = async (data) => {
  try {
    return await axiosRequestHandler(`recipes`, 'post', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Update Recipes
 * @param {*} data
 * * @param {*} data.id
 * * @param {*} data.name
 * * @param {*} data.percent
 * @returns
 */
export const UpdateRecipeAPI = async (data) => {
  try {
    return await axiosRequestHandler(`recipes/${data.id}`, 'patch', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Delete Recipes
 * @param {Number} id
 * @returns
 */
export const DeleteRecipeAPI = async (id) => {
  try {
    return await axiosRequestHandler(`recipes/${id}`, 'delete');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Reorder Recipe Tasks
 * @param {*} requestData
 * @param {Number} requestData.recipeId - Id of recipe
 * @param {Number[]} requestData.tasks - New order of recipe tasks
 * @returns
 */
export const ReorderRecipeTasksAPI = async (requestData) => {
  try {
    return await axiosRequestHandler(`recipes/${requestData.recipeId}/tasks/reorder`, 'POST', {
      tasks: requestData.tasks,
    });
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Create Recipe Tasks
 * @param {*} data
 * * @param {*} data.recipeId
 * * @param {*} data.name
 * * @param {*} data.percent
 * @returns
 */
export const CreateRecipeTaskAPI = async (data) => {
  try {
    return await axiosRequestHandler(`recipes/${data.recipeId}/tasks`, 'post', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Update Recipe Tasks
 * @param {*} data
 * * @param {*} data.id
 * * @param {*} data.name
 * * @param {*} data.percent
 * * @param {*} data.recipeId
 * @returns
 */
export const UpdateRecipeTaskAPI = async (data) => {
  try {
    return await axiosRequestHandler(`recipes/${data.recipeId}/tasks/${data.id}`, 'patch', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Update Recipe Tasks
 * @param {*} data
 * @param {Number} data.recipeId
 * @param {[Recipe]}
 * * @param {*} Recipe.id
 * * @param {*} Recipe.name
 * * @param {*} Recipe.percent
 * * @param {*} Recipe.recipe_id
 * @returns
 */
export const UpdateRecipeTasksAPI = async (data) => {
  try {
    return await axiosRequestHandler(`recipes/${data.recipeId}/tasks`, 'patch', data?.recipes);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Delete Recipe Task
 * @param {*} requestParameter
 * * @param {Number} requestParameter.id
 * * @param {Number} requestParameter.recipeId
 */
export const DeleteRecipeTaskAPI = async (data) => {
  try {
    return await axiosRequestHandler(`recipes/${data.recipeId}/tasks/${data.id}`, 'delete');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Delete Recipe Tasks
 * @param {*} requestParameter
 * * @param {Number} requestParameter.recipeId
 * * @param {[Number]} requestParameter.ids
 */
export const DeleteRecipeTasksAPI = async (data) => {
  try {
    return await axiosRequestHandler(`recipes/${data.recipeId}/tasks`, 'delete', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Fetch Recipe Tasks
 * @param {*} requestParameter
 * * @param {Number} requestParameter.recipeId
 */
export const FetchRecipeTasksAPI = async (data) => {
  try {
    return await axiosRequestHandler(`recipes/${data.recipeId}/tasks`, 'get');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Fetch Recipe Dependencies
 * @param {*} requestParameter
 * * @param {Number} requestParameter.recipeId
 */
export const FetchRecipeDependenciesAPI = async (data) => {
  try {
    return await axiosRequestHandler(`recipes/${data.recipeId}/dependencies`, 'get');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Create Recipe Task Dependency
 * @param {*} requestParameter
 * * @param {Number} requestParameter.recipeId
 */
export const CreateRecipeDependencyAPI = async (data) => {
  try {
    return await axiosRequestHandler(`recipes/${data.recipeId}/dependencies`, 'post', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Update Recipe Task Dependency
 * @param {*} requestParameter
 * * @param {Number} requestParameter.recipeId
 */
export const UpdateRecipeDependencyAPI = async (data) => {
  try {
    return await axiosRequestHandler(`recipe_dependencies/${data.id}`, 'patch', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Update Recipe Task Dependency
 * @param {*} requestParameter
 * * @param {Number} requestParameter.recipeId
 */
export const UpdateRecipeDependenciesAPI = async (data) => {
  try {
    return await axiosRequestHandler(
      `recipes/${data.recipeId}/dependencies`,
      'patch',
      data.dependencies
    );
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Delete Recipe Task Dependencies
 * @param {*} requestParameter
 * * @param {Number} requestParameter.id
 */
export const DeleteRecipeDependencyAPI = async (data) => {
  try {
    return await axiosRequestHandler(`recipe_dependencies/${data.id}`, 'delete');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Delete Recipe Task Dependencies
 * @param {*} requestParameter
 * * @param {Number} requestParameter.recipeId
 * * @param {[Number]} requestParameter.ids
 */
export const DeleteRecipeDependenciesAPI = async (data) => {
  try {
    return await axiosRequestHandler(`recipes/${data.recipeId}/dependencies`, 'delete', {
      ids: data.dependencies,
    });
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Sync Recipe Dependency
 * @param {*} data
 * * @param {[Object]} data.dependency
 */
export const SyncRecipeDependencyAPI = async (data) => {
  try {
    return await axiosRequestHandler(
      `recipe_tasks/${data.recipeTaskId}/dependencies/sync`,
      'post',
      data
    );
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Duplicate Recipe
 * @param {Number} id
 */
export const DuplicateRecipeAPI = async (id) => {
  try {
    return await axiosRequestHandler(`recipes/${id}/duplicate`, 'post');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Upload Recipe Images
 * @param {FormData} formData
 * @param {String} formData.recipeId
 * @param {File} formData.file
 */
export const UploadRecipeImageAPI = async (formData) => {
  try {
    return await axiosRequestHandler(`files`, 'post', formData, null, true);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};
