import { Button } from '@mui/material';
import ConfirmDeleteDialog from 'components/common/popovers/ConfirmDeleteDialog';
import { useState } from 'react';

const TaskBulkEditDeleteLinks = (props) => {
  const { id, type, handleDelete } = props;

  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleOpenConfirm = (event) => {
    event.stopPropagation();
    setConfirmDelete(true);
  };

  const handleClick = () => {
    handleDelete(id);
    setConfirmDelete(false);
  };

  return (
    <>
      <Button sx={{ whiteSpace: 'nowrap', width: 'fit-content' }} onClick={handleOpenConfirm}>
        Delete {type}
      </Button>
      <ConfirmDeleteDialog
        handleClose={() => setConfirmDelete(false)}
        handleDelete={handleClick}
        item={`${type}`}
        open={confirmDelete}
        plural={'true'}
      />
    </>
  );
};

export default TaskBulkEditDeleteLinks;
