import store from '../../../../../../store';
import Chip from '@mui/material/Chip';
import ganttStore from '../../ganttStore';
import { createRoot } from 'react-dom/client';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import React, { useCallback, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { calculateEffortHours } from '../../../../../../helpers/taskCalculations';
import { EFFORT_TAGS, EFFORT_TAG_PERCENTAGE } from '../../../../../../helpers/constants';


const useStyles = makeStyles((theme) => ({
  effortPaper: {
    padding: '10px',
    width: '120px',
    overflowY: 'hidden',
    borderRadius: '4px',
    boxShadow:
      '0px 5px 5px 5px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
  },
  effortTag: {
    color: 'black',
    width: '100%',
    height: '35px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  menuItem: {
    padding: 0,
    margin: '5px 0',
    borderRadius: '15px',
    '&:hover, &:focus': {
      backgroundColor: 'unset',
      overflow: 'visible',
      opacity: 0.95,
      transform: 'scale(1.04)',
    },
  },
}));

export const loadEffortMenuType = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);

  gantt.config.editor_types.effort = {
    show: function (id, column, config, placeholder) {
      // called when input is displayed, put html markup of the editor into placeholder
      // and initialize your editor if needed:
      const task = gantt.getTask(id);
      const effortTag = task.effort_tag;
      const root = createRoot(placeholder);
      const element = (
        <EffortMenuPopover
          anchorEl={placeholder}
          handleCloseCallback={() => gantt.ext.inlineEditors.save()}
          initialEffortTag={effortTag}
        >
          <div style={{ height: '100%', width: '100%' }} />
        </EffortMenuPopover>
      );
      root.render(element);
    },
    hide: function (node) {
      // unmountComponentAtNode(node);
      // node.unmount();
      // called when input is hidden
      // destroy any complex editors or detach event listeners from here
    },

    set_value: function (value, id, column, node) {
      //   let effortTag = value?.effort_tag;
      //   document.getElementById('effort-menu-placeholder').dataset.effortTag = effortTag;
    },

    get_value: function (id, column, node) {
      return node.firstChild.dataset.effortTag;
    },

    is_changed: function (value, id, column, node) {
      const effortTag = this.get_value(id, column, node);
      //called before save/close. Return true if new value differs from the original one
      //returning true will trigger saving changes, returning false will skip saving
      return value !== effortTag;
    },

    is_valid: function (value, id, column, node) {
      // validate, changes will be discarded if the method returns false
      return true;
    },

    save: function (id, column, node) {
      const effortTag = this.get_value(id, column, node);
      if (this.is_valid(effortTag)) {
        const task = gantt.getTask(id);
        const project = store.getState().projects.entities[task.project_id];
        const effortHours = calculateEffortHours(
          project.budget,
          project.labor_percentage,
          project.labor_rate,
          EFFORT_TAG_PERCENTAGE[effortTag]
        );
        task.effort_hours = !isNaN(effortHours) ? effortHours.toFixed(2) : undefined;
        task.effort_tag = effortTag;
        gantt.updateTask(id);
      }
    },

    focus: function (node) { },
  };
};

const EffortMenuPopover = ({ anchorEl, initialEffortTag, handleCloseCallback, children }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const placeholder = useRef(undefined);

  const handleClose = useCallback(() => {
    setOpen(false);
    if (handleCloseCallback) {
      handleCloseCallback();
    }
  }, [handleCloseCallback]);

  const handleSelect = (value) => {
    placeholder.current.dataset.effortTag = value;
    handleClose();
  };

  return (
    <>
      {children
        ? React.cloneElement(children, {
          onClick: () => setOpen(true),
          id: 'effort-menu-placeholder',
          'data-effort-tag': initialEffortTag,
          ref: placeholder,
        })
        : undefined}
      <Popover
        anchorEl={anchorEl}
        classes={{ paper: classes.effortPaper }}
        open={open}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'center',
        }}
        onClose={handleClose}
      >
        <MenuList autoFocusItem id={'effort-tag-input'} variant={'selectedMenu'}>
          {Object.keys(EFFORT_TAGS).map((effortTag, index) => (
            <MenuItem
              className={classes.menuItem}
              key={`${effortTag}-${index}`}
              selected={initialEffortTag === effortTag}
              value={effortTag}
              onClick={() => {
                handleSelect(effortTag);
              }}
            >
              <Chip
                className={classes.effortTag}
                label={effortTag}
                size="medium"
                style={{
                  background: EFFORT_TAGS[effortTag],
                }}
              />
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </>
  );
};
