import { Grid, Link, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { FormLabel } from 'assets/style-components/typography';
import { updatePassword } from 'features/users/store/usersSlice';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

const initialState = {
  newPassword: '',
  oldPassword: '',
  confirmPassword: '',
  showPassword: false,
  loading: false,
};

const ChangePasswordForm = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, watch, setValue, reset } = useForm({
    defaultValues: initialState,
  });

  const showPassword = watch('showPassword');
  const newPassword = watch('newPassword');
  const loading = watch('loading');

  const handleResetPassword = async (data) => {
    setValue('loading', true);
    dispatch(
      updatePassword({
        old_password: data.oldPassword,
        new_password: data.newPassword,
        confirm_password: data.confirmPassword,
      })
    )
      .then((response) => {
        setValue('loading', false);
        if (!isEmpty(response.error)) {
          return enqueueSnackbar(response.payload, { variant: 'error' });
        }
        reset(initialState);
        enqueueSnackbar('Password updated successfully', { variant: 'success' });
      })
      .catch((error) => {
        setValue('loading', false);
        enqueueSnackbar('Not able to update password, try again later', { variant: 'error' });
      });
  };

  return (
    <form onSubmit={handleSubmit(handleResetPassword)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h5'}>Change Password</Typography>
          <FormLabel>Current Password</FormLabel>
          <Controller
            control={control}
            name="oldPassword"
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                autoFocus
                fullWidth
                autoComplete="current-password"
                error={error}
                helperText={error?.message}
                placeholder="Old Password"
                size="small"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
              />
            )}
            rules={{
              required: 'Old password field is required',
            }}
          ></Controller>
        </Grid>
        <Grid item xs={12}>
          <FormLabel>New Password</FormLabel>
          <Controller
            control={control}
            name="newPassword"
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                autoFocus
                fullWidth
                autoComplete="new-password"
                error={error}
                helperText={error?.message}
                placeholder="New Password"
                size="small"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
              />
            )}
            rules={{
              required: 'New password field is required',
              validate: {
                minLength: (value) => value.length >= 8 || 'Password must be at least 8 characters',
              },
            }}
          ></Controller>
        </Grid>
        <Grid item xs={12}>
          <FormLabel>Confirm Password</FormLabel>
          <Controller
            control={control}
            name="confirmPassword"
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                autoFocus
                fullWidth
                autoComplete="new-password"
                error={error}
                helperText={error?.message}
                placeholder="Confirm Password"
                size="small"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
              />
            )}
            rules={{
              required: 'Confirm password field is required',
              validate: (value) =>
                newPassword === value || 'Confirm password does not match new password',
            }}
          ></Controller>
          <Link
            component={'button'}
            onClick={(e) => {
              e.preventDefault();
              setValue('showPassword', !showPassword);
            }}
          >
            {showPassword ? 'Hide' : 'Show'} Passwords
          </Link>
        </Grid>
        <Grid item>
          <Button disabled={loading} type="submit" variant="contained">
            Update Password
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ChangePasswordForm;
