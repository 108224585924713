import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import EntityUpdateMenu from '../../EntityUpdateMenu';

const useStyles = makeStyles((theme) => ({
  ArrowIcon: {
    fontSize: 18,
  },
}));

const TaskBulkEditHoursMenuItem = (props) => {
  const classes = useStyles();

  const { handleUpdate } = props;

  const [anchorEl, setAnchorEl] = useState(undefined);

  const handleSave = (effortHours) => {
    if (effortHours !== '') {
      handleUpdate({ effort_hours: effortHours });
    }
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <>
      <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
        Hours{' '}
        {anchorEl ? (
          <ArrowDropDown className={classes.ArrowIcon} />
        ) : (
          <ArrowDropUp className={classes.ArrowIcon} />
        )}
      </Button>
      {anchorEl ? (
        <EntityUpdateMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleUpdate={handleSave}
          labelText={'Effort Hours:'}
          textBoxType={'number'}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
        />
      ) : null}
    </>
  );
};

export default TaskBulkEditHoursMenuItem;
