import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineContent from '@mui/lab/TimelineContent';

export const TimelineGroupLabel = styled(Typography)(() => ({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  justifyContent: 'center',
  display: 'flex',
}));

export const CustomTimelineItem = styled(TimelineItem)(({ direction }) => ({
  flexDirection: direction,
}));

export const ProjectLabel = styled(Typography)(() => ({
  fontSize: '0.9rem',
  display: 'flex',
  color: 'grey',
  fontWeight: 500,
  borderBottom: '1px solid lightGray',
  // marginBottom: '0.5em',
}));

export const ActivityFeedUserName = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  marginLeft: '5px',
  marginRight: '5px',
  fontSize: '15px',
}));

export const ActivityFeedCardContainer = styled(Grid)(({ theme }) => ({
  cursor: 'pointer',
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.borderColor,
  padding: '10px',
  transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  '&:hover': {
    backgroundColor: '#F5F5F570',
  },
}));

export const ActivityFeedDrawerSubTitle = styled(Typography)(() => ({
  fontWeight: 600,
  color: '#9E9C9C',
  fontSize: '12px',
}));

export const CustomBadgeIcon = styled(Badge)(({ color }) => ({
  '& .MuiBadge-badge': {
    padding: 2,
    backgroundColor: color,
    color: '#fff',
  },
}));

export const ActivityFeedCardSubHeading = styled(Typography)(({ theme }) => ({
  marginRight: '5px',
  fontSize: '14px',
}));

export const StyledMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    maxWidth: '300px',
    maxHeight: '500px',
  },
}));

export const StyledMenuName = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const CheckBoxContainer = styled('div')(() => ({
  width: 20,
  paddingRight: 4,
}));
