import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectCurrentUser } from '../store/authSlice';

export const RequireNoAuth = ({ children }) => {
  const authToken = useSelector((state) => state.auth.authToken);
  const refreshToken = useSelector((state) => state.auth.refreshToken);
  const user = useSelector((state) => selectCurrentUser(state));

  if (!refreshToken) {
    return children;
  } else {
    return <Navigate replace to={`/`} />;
  }
};
