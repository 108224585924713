import AreaPolygonIconComponent from '@blackhyve/common/src/icons/AreaPolygon';
import SqftIconComponent from '@blackhyve/common/src/icons/SqftIcon';
import DragIndicator from '@mui/icons-material/DragIndicator';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ColorDropdown from 'components/ColorDropdown';
import DisplayWithEditAccess, { EditContext } from 'components/common/v3/DisplayWithEditAccess';
import InlineEditCardTitle from 'components/common/v3/InlineEditCardTitle';
import { memo, useContext, useState } from 'react';
import {
  useDeleteAreaMutation,
  useDuplicateAreaMutation,
  useSyncAreasMutation,
  useUpdateAreaMutation,
} from '../store/area.api';
import LbsMoreOptions from './LbsMoreOptions';
import PercentAndSqft from './PercentAndSqft';
import { RowContainer } from './ZoneRow';

const AreaRow = memo(({ area, dragHandleProps, locationId, zoneId, projectId }) => {
  const canEdit = useContext(EditContext);

  const id = area?.id;
  const [deleteArea, { isLoading: isDeleting }] = useDeleteAreaMutation();
  const [duplicateArea, { isLoading: isDuplicating }] = useDuplicateAreaMutation();
  const [updateArea, { isLoading: isUpdating }] = useUpdateAreaMutation();
  const [openPercentAndSqftDialog, setOpenPercentAndSqftDialog] = useState({});

  const [syncAreas, { isLoading: isSyncingAreas }] = useSyncAreasMutation();

  const requestParameters = {
    name: area?.name,
    id: id,
    density: area?.density,
    percent: area?.percent,
    sqft: area?.sqft,
  };

  const handleDelete = () => {
    deleteArea({ areaId: area?.id, zoneId, locationId, projectId });
  };

  const handleDuplicate = () => {
    duplicateArea({ areaId: area?.id, zoneId, locationId, projectId });
  };

  const handleOpenPercentAndSqftDialog = (event, tab) => {
    setOpenPercentAndSqftDialog({ anchorEl: event.target, tab });
  };

  const handleClosePercentAndSqftDialog = () => {
    setOpenPercentAndSqftDialog({});
  };

  const handleUpdate = (data) => {
    updateArea({ areaId: area?.id, zoneId, locationId, projectId, area: data });
  };

  const handleSaveColor = (selectedColor) => {
    handleUpdate({ color: selectedColor });
  };

  const handleSyncAreas = (areas) => {
    syncAreas({ zoneId: area?.zone_id, areas, locationId });
  };

  return (
    <RowContainer container>
      {isDeleting || isDuplicating || isUpdating ? (
        <Skeleton className="skeleton-row" variant="text" />
      ) : (
        <>
          <Grid container item alignItems={'center'} xs={7}>
            <Grid item {...dragHandleProps}>
              <DragIndicator sx={{ color: 'grey.600' }} />
            </Grid>
            <Grid item>
              <AreaPolygonIconComponent
                style={{
                  marginLeft: '40px',
                  padding: '5px',
                  display: 'block',
                }}
              />
            </Grid>
            <Grid item className="card-title">
              <InlineEditCardTitle
                fieldName={'name'}
                handleUpdate={handleUpdate}
                isDefaultEdit={id < 0}
                isEditable={canEdit}
                requestParameters={requestParameters}
                textToDisplay={area?.name}
              />
            </Grid>
          </Grid>
          <Grid container item alignItems={'center'} justifyContent={'center'} xs={5}>
            <Grid container item justifyContent="center" xs={3}>
              <Grid
                item
                style={{
                  display: 'flex',
                  marginLeft: '10px',
                  marginRight: '10px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ColorDropdown
                  color={area?.color}
                  disabled={!canEdit}
                  handleSaveColor={handleSaveColor}
                />
              </Grid>
            </Grid>
            <Grid container item justifyContent="center" xs={2}>
              <Typography variant="subtitle2"> {area?.priority}</Typography>
            </Grid>
            <Grid container item justifyContent="center" xs={3}></Grid>

            <Grid container item justifyContent="center" xs={3}>
              <Button onClick={(event) => handleOpenPercentAndSqftDialog(event, 1)}>
                {area?.sqft ? (
                  <Box alignItems={'center'} display="flex">
                    <SqftIconComponent style={{ marginRight: '2px' }} />
                    <Typography variant="subtitle2"> {area?.sqft}</Typography>
                  </Box>
                ) : (
                  '-'
                )}
              </Button>
            </Grid>

            <Grid
              container
              item
              justifyContent="center"
              style={{ justifyContent: 'flex-end' }}
              xs={1}
            >
              <DisplayWithEditAccess>
                <LbsMoreOptions
                  entityObj={area}
                  handleDelete={handleDelete}
                  handleDuplicate={handleDuplicate}
                  handleSave={handleUpdate}
                  isLoading={isDeleting || isDuplicating}
                  type="area"
                />
              </DisplayWithEditAccess>
            </Grid>
          </Grid>
        </>
      )}
      {openPercentAndSqftDialog && openPercentAndSqftDialog.anchorEl && (
        <PercentAndSqft
          anchorEl={openPercentAndSqftDialog.anchorEl}
          defaultTab={openPercentAndSqftDialog.tab}
          handleClose={handleClosePercentAndSqftDialog}
          handleUpdate={handleSyncAreas}
          isLoading={isSyncingAreas}
          zoneId={area.zone_id}
        />
      )}
    </RowContainer>
  );
});

export default AreaRow;