import { useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import LinearProgress from "@mui/material/LinearProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function LinearDeterminate({ setCompleted, progressBarStatus }) {
  const classes = useStyles();

  useEffect(() => {
    function progress() {
      setCompleted((oldCompleted) => {
        if (oldCompleted === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldCompleted + diff, 100);
      });
    }

    const timer = setInterval(progress, 500);
    return () => {
      clearInterval(timer);
    };
  }, [setCompleted]);

  return (
    <div className={classes.root}>
      <LinearProgress variant="determinate" value={progressBarStatus} />
    </div>
  );
}
