import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '20px',
  marginRight: '15px',
}));

export const StyledSectionTitle = styled(Typography)(({ theme, fontSize }) => ({
  color: '#9E9C9C',
  fontWeight: 600,
  fontSize,
}));

export const StyledLabel = styled(Typography)(({ theme, fontSize }) => ({
  fontWeight: 600,
  fontSize: fontSize ? fontSize : 13,
  lineHeight: '1',
}));

export const StyledLabelValue = styled(Typography)(({ theme, fontSize }) => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: '1',
  // justifyContent: 'center',
  fontSize: fontSize ? fontSize : 13,
}));

export const StyledChip = styled('div')(({ theme, backgroundColor }) => ({
  color: '#fff',
  fontSize: 10,
  backgroundColor,
  padding: 4,
  textTransform: 'uppercase',
  borderRadius: 2,
  fontWeight: 600,
}));

export const StyledDrawer = styled(Drawer)(({ theme, top, width }) => ({
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: width,
    padding: 15,
    paddingRight: 15,
    top,
    height: `calc(100% - ${top})`,
    overflowY: 'auto',
    overflowX: 'hidden',
    boxSizing: 'border-box',
    borderLeft: '1px solid #cccfd1',
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
}));

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-colorSecondary.Mui-disabled.Mui-checked': {
    color: '#1eb980 !important',
    opacity: 0.5,
  },
  '& .MuiSwitch-colorSecondary.Mui-disabled.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#1eb980 !important',
  },
}));

export const StyleSubHeading = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  textTransform: 'uppercase',
}));

export const StyledInlineDatePicker = styled(DatePicker)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    paddingLeft: '8px',
    fontSize: '15px',
  },
  '& .MuiButtonBase-root': {
    padding: '2px',
  },
  '& .MuiInputAdornment-root': {
    marginLeft: '2px',
  },
}));

export const StyledColorBox = styled('div')(({ theme, background }) => ({
  height: '15px',
  width: '15px',
  borderRadius: '2px',
  backgroundColor: background,
  borderColor: 'rgba(108,190,212,.31) !important',
  border: '1px solid',
  verticalAlign: 'top',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledEllipsisText = styled(Typography)(({ theme, background }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme, background }) => ({
  '& .MuiAccordionSummary-content': {
    margin: '5px 0px',
  },
  minHeight: '20px',
}));

export const StyleCounterBadge = styled('div')(({ count }) => ({
  backgroundColor: count > 0 ? '#1eb980' : '#eb5a46',
  color: '#fff',
  paddingBottom: '3px',
  fontWeight: 'bold',
  marginLeft: 'auto',
  paddingTop: '3px',
  paddingLeft: '6px',
  paddingRight: '6px',
  borderRadius: '4px',
  fontSize: '13px',
}));
