import { StyledDialog } from '@blackhyve/common';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { Alert } from '@mui/material';
import SearchBox from 'components/search/SearchBox';
import { useState } from 'react';
import {
  useAddProjectCompaniesMutation,
  useGetProjectCompaniesUsersNotOnProjectQuery,
} from '../api/projectCompanies.api';

export const AddCompanyUsersDialog = ({ open, handleClose, projectId, companyId }) => {
  const { data: users = [], isLoading } = useGetProjectCompaniesUsersNotOnProjectQuery(
    {
      projectId,
      companyId,
    },
    { skip: !open, refetchOnMountOrArgChange: true }
  );
  const [addUsers, { isLoading: isAdding }] = useAddProjectCompaniesMutation();

  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');

  const handleSelect = (id, isSelected) => {
    setSelected((prevSelected) => {
      if (isSelected) {
        return prevSelected.filter((userId) => userId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const onSelectAll = () => {
    selected.length ? setSelected([]) : setSelected(users.map((user) => user.id));
  };

  const close = () => {
    setSelected([]);
    handleClose();
  };

  const handleAdd = async () => {
    addUsers({ projectId, companies: [{ company_id: companyId, users: selected }] })
      .unwrap()
      .then(() => close())
      .catch((error) => console.log(error));
  };

  const filteredUsers = users.filter((user) =>
    (user.first_name + user.last_name).toLowerCase().includes(search.toLowerCase())
  );

  return (
    <StyledDialog
      dialogContentStyle={{ display: 'flex', flexDirection: 'column' }}
      handleClose={handleClose}
      open={open}
      title={'Add Users'}
      actions={
        <>
          <Button disabled={isAdding} onClick={close}>
            Close
          </Button>
          <Button
            disabled={isAdding || selected.length < 1}
            endIcon={isAdding && <CircularProgress size={'1em'} />}
            variant={'contained'}
            onClick={handleAdd}
          >
            Add
          </Button>
        </>
      }
    >
      <SearchBox
        fullWidth
        autoComplete={'off'}
        bgcolor={'grey.200'}
        borderRadius={1}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />
      {filteredUsers.length ? (
        <Box mb={1} mt={1}>
          <Box px={1}>
            <Link component={'button'} onClick={onSelectAll}>
              {selected.length ? 'Unselect' : 'Select'} All
            </Link>
          </Box>
        </Box>
      ) : null}
      {isLoading ? (
        <Grid container justifyContent={'center'}>
          <Box p={6}>
            <CircularProgress />
          </Box>
        </Grid>
      ) : (
        <Box m={0} overflow={'auto'} p={0} width={'100%'}>
          <List>
            {filteredUsers.length > 0 ? (
              filteredUsers.map((user) => {
                const isSelected = selected.includes(user.id);
                return (
                  <ListItem
                    button
                    disabled={isAdding}
                    key={user.id}
                    selected={isSelected}
                    onClick={() => handleSelect(user.id, isSelected)}
                  >
                    <ListItemAvatar>
                      <Avatar src={user?.profile_image?.thumb_url}>
                        {user?.first_name?.[0]}
                        {user?.last_name?.[0]}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={user?.first_name + ' ' + user?.last_name} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        checked={isSelected}
                        disabled={isAdding}
                        onClick={() => handleSelect(user.id, isSelected)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })
            ) : (
              <Alert severity={'info'}>No Users Available To Add</Alert>
            )}
          </List>
        </Box>
      )}
    </StyledDialog>
  );
};
