import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import Stack from '@mui/material/Stack';
import cloneDeep from 'lodash/cloneDeep';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import TabContext from '@mui/lab/TabContext';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import SkeletonListComponent from 'components/common/v3/SkeletonListComponent';
import { useGetAreasQuery } from '../store/area.api';

/**
 * Percent And Sqft Dialog
 * @param {String} zoneId
 * @param {Number} defaultTab
 * @param {Object} handleClose
 * @param {[Object]} children
 * @param {Object} handleBatchPercentUpdate
 * @returns
 */

const PercentAndSqft = (props) => {
  const [tabValue, setTabValue] = useState('1');
  const { anchorEl, defaultTab, handleClose, zoneId, handleUpdate, isLoading } = props;

  const { data: areas, isLoading: isLoadingZoneAreas } = useGetAreasQuery({
    zoneId: zoneId,
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setTabValue(defaultTab ? defaultTab.toString() : '1');
  }, [defaultTab]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorPosition={{ top: 500, left: 0 }}
      disableRestoreFocus={true}
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      onClose={handleClose}
    >
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange}>
            <Tab label="By Sqft" value="1" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <PanelContent
            children={areas}
            handleClose={handleClose}
            handleUpdate={handleUpdate}
            isLoading={isLoading || isLoadingZoneAreas}
            tabValue={tabValue}
          />
        </TabPanel>
      </TabContext>
    </Popover>
  );
};

const PanelContent = (props) => {
  const { tabValue, handleClose, handleUpdate, isLoading, children } = props;
  const [total, setTotal] = useState(0);
  const [isError, setIsError] = useState(false);
  const [list, setList] = useState(children);

  const handelUpdateValue = (event) => {
    const listClone = cloneDeep(list);
    const areaIndex = listClone.findIndex((area) => area.id == event.target.id);
    if (tabValue === '1') {
      listClone[areaIndex].sqft = parseInt(event.target.value);
      const allSqft = listClone.map(({ sqft }) => parseFloat(sqft));
      setList(listClone);
      setTotal(
        allSqft.reduce((total, item) => (!isNaN(item) ? total + item : total)),
        0
      );
    }
  };

  const handleSave = (event) => {
    event.preventDefault();
    const listClone = cloneDeep(list);
    tabValue === '1' &&
      listClone.map((item) => {
        delete item.percent;
        delete item.name;
        delete item.type;
        return item;
      });

    handleUpdate(listClone);
    handleClose();
  };

  useEffect(() => {
    if (tabValue === '1') {
      setTotal(children?.reduce((sum, obj) => (sum += parseFloat(obj.sqft)), 0));
    }
    setList(children);
  }, [children]);

  return (
    <Stack spacing={2}>
      <Typography sx={{ fontWeight: 'bold', color: isError ? 'red' : 'black' }} variant="button">
        Total {total ? total : 0}
        {tabValue === '1' ? ' sqft' : ' '}
      </Typography>
      {isLoading ? (
        <SkeletonListComponent noOfRows={3} />
      ) : (
        list?.map((area, index) => {
          return (
            <Stack alignItems={'center'} direction="row" key={index} spacing={2}>
              <Typography variant="subtitle1">{area.name}</Typography>
              <TextField
                defaultValue={area.sqft}
                id={area.id}
                size="small"
                type="number"
                variant="outlined"
                inputProps={
                  tabValue === '1'
                    ? {
                      maxLength: 13,
                      step: '1',
                    }
                    : {}
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {tabValue === '1' ? 'Sqft' : ''}
                    </InputAdornment>
                  ),
                }}
                onChange={handelUpdateValue}
              />
            </Stack>
          );
        })
      )}
      <Button
        color="primary"
        disabled={isError}
        size="small"
        variant="contained"
        onClick={handleSave}
      >
        Save
      </Button>
    </Stack>
  );
};

export default PercentAndSqft;