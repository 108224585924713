import * as React from 'react';

const SvgRecipe = (props) => (
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6006_81692)">
      <path
        d="M22.0176 10.0176C21.793 10.2572 21.5609 10.5117 21.3213 10.7812C21.0817 11.0508 20.7785 11.4102 20.4116 11.8594C20.0448 12.3086 19.749 12.7578 19.5244 13.207C19.2998 13.6562 19.1875 14.0456 19.1875 14.375C19.1875 14.7793 18.4875 15.12 17.0874 15.397C15.6873 15.674 13.9915 15.8125 12 15.8125C10.0085 15.8125 8.31266 15.674 6.9126 15.397C5.51253 15.12 4.8125 14.7793 4.8125 14.375C4.8125 13.9408 4.61035 13.3979 4.20605 12.7466C3.80176 12.0952 3.42367 11.5711 3.07178 11.1743C2.71989 10.7775 2.37174 10.4069 2.02734 10.0625C1.00911 9.07422 0.5 7.8763 0.5 6.46875C0.5 5.07617 0.990397 3.88949 1.97119 2.90869C2.95199 1.9279 4.13867 1.4375 5.53125 1.4375C5.87565 1.4375 6.23503 1.47493 6.60938 1.5498C6.36979 2.16374 6.25 2.84505 6.25 3.59375C6.25 5.19596 6.74414 6.52865 7.73242 7.5918C7.70247 6.7832 7.6875 5.92969 7.6875 5.03125C7.6875 3.63867 8.10677 2.45199 8.94531 1.47119C9.78385 0.490397 10.8021 0 12 0C13.1979 0 14.2161 0.490397 15.0547 1.47119C15.8932 2.45199 16.3125 3.63867 16.3125 5.03125C16.3125 5.94466 16.2975 6.79818 16.2676 7.5918C17.2559 6.52865 17.75 5.19596 17.75 3.59375C17.75 2.84505 17.6302 2.16374 17.3906 1.5498C17.765 1.47493 18.1243 1.4375 18.4688 1.4375C19.8613 1.4375 21.048 1.9279 22.0288 2.90869C23.0096 3.88949 23.5 5.07617 23.5 6.46875C23.5 7.86133 23.0059 9.04427 22.0176 10.0176ZM11.9888 17.25C13.9728 17.25 15.6686 17.1077 17.0762 16.8232C18.4837 16.5387 19.1875 16.2018 19.1875 15.8125V21.5625C19.1875 21.9518 18.4837 22.2887 17.0762 22.5732C15.6686 22.8577 13.9728 23 11.9888 23C10.0047 23 8.31266 22.8577 6.9126 22.5732C5.51253 22.2887 4.8125 21.9518 4.8125 21.5625V15.8125C4.8125 16.2168 5.51253 16.5575 6.9126 16.8345C8.31266 17.1115 10.0047 17.25 11.9888 17.25Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6006_81692">
        <rect width="23" height="23" fill="currentColor" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgRecipe;
