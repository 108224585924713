import { transformRawData } from 'features/drawers/components/FlowAiDialog';
import { useGetLbsQuery } from 'features/locations/store/location.api';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { useEffect, useState } from 'react';
import { useGetProjectJobwalkLBSQuery } from '../store/jobwalk.api';
import JobwalkLbsFilter from './JobwalkLbsFilter';

/**
 * Jobwalk LBS Left Nav
 * @param {String} setFilters
 * @param {String} projectId
 * @param {String} filters
 * @returns
 */
const JobwalkLbsLeftNav = ({ setFilters, projectId, filters }) => {
  const [locations, setLocations] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const lbsFilterQuery = { projectId, ...filters };
  delete lbsFilterQuery.order_by;

  const { data: lbsData, isLoading: isLoadingLbs } = useGetLbsQuery({ projectId });
  const { data: lbsJobwalkCount = {} } = useGetProjectJobwalkLBSQuery(lbsFilterQuery, {
    refetchOnMountOrArgChange: true,
  });

  const handleSelect = (ids) => {
    const selectedLBSObj = { location: [], zone: [], area: [] };
    uniq(ids).forEach((value) => {
      const [type, id] = value.split('-');
      selectedLBSObj[type] = [...selectedLBSObj[type], id];
    });
    setFilters((filters) => {
      return { ...filters, ...selectedLBSObj };
    });
    setFilters((prevFilters) => ({ ...prevFilters, ...selectedLBSObj }));
    setSelectedIds(ids);
  };

  useEffect(() => {
    const ids = [];
    const extractSelectedIds = (element) => {
      ids.push(`${element.type}-${element.id}`);
      element.children?.length &&
        element.children?.forEach((nextElement) => {
          extractSelectedIds(nextElement);
        });
    };

    if (!isEmpty(lbsData)) {
      const transformedData = transformRawData(lbsData);
      transformedData?.unshift({ id: 0, name: 'Unassigned', type: 'location' });
      transformedData.forEach((element) => extractSelectedIds(element));
      setLocations(transformedData);
      setSelectedIds(ids);
    }
  }, [lbsData]);

  return (
    <JobwalkLbsFilter
      handleSelect={handleSelect}
      lbsJobwalkCount={lbsJobwalkCount}
      locations={locations}
      selectedIds={selectedIds}
    />
  );
};

export default JobwalkLbsLeftNav;