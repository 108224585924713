import BuildingIconComponent from '@blackhyve/common/src/icons/Building';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  LinearProgress
} from '@mui/material';
import { Skeleton } from '@mui/material';
import { selectCurrentUserWorkspaceId } from 'features/auth';
import MoreOptions from 'features/projects/components/MoreOptions';
import { times } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from 'api';

export const ProjectNavList = ({ projectIds, loading, isFetching }) => {
  return (
    <List>
      {isFetching && <LinearProgress />}
      {loading ? (
        times(10, (key) => (
          <ListItem key={key}>
            <ListItemIcon>
              <Skeleton height={'1.5em'} variant="circular" width={'1.5em'} />
            </ListItemIcon>
            <ListItemText disableTypography>
              <Skeleton variant="rectangular" />
            </ListItemText>
          </ListItem>
        ))
      ) : projectIds && projectIds.length > 0 ? (
        projectIds.map((projectId) => {
          return <ProjectNavListRow key={projectId} projectId={projectId} />;
        })
      ) : (
        <Typography>No Projects.</Typography>
      )}
    </List>
  );
};

/**
 * Project row
 * @param {number} id
 * @param {boolean} isSelected
 * @returns
 */
export const ProjectNavListRow = ({ projectId }) => {
  const { projectId: selectedProjectId } = useParams();
  const navigate = useNavigate();

  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { projectDetails } = api.endpoints.getProjects.useQueryState({ workspaceId }, {
    selectFromResult: ({ data: projects, isFetching, isLoading }) => {
      return {
        projectDetails: projects?.find((project) => project.id === projectId),
        isFetching,
        isLoading,
      };
    },
  })
  const projectName = projectDetails?.name;

  return (
    <ListItem disablePadding secondaryAction={<MoreOptions projectId={projectId} />}>
      <Tooltip
        arrow
        aria-label={projectName}
        enterDelay={200}
        enterNextDelay={200}
        leaveDelay={0}
        placement={'top'}
        title={projectName}
      >
        <ListItemButton
          selected={projectId == selectedProjectId}
          onClick={() => {
            navigate(`/workspace/${workspaceId}/projects/${projectId}`);
          }}
        >
          <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
            <BuildingIconComponent color={'currentColor'} height={20} width={20} />
          </ListItemIcon>
          <ListItemText primary={projectName} primaryTypographyProps={{ noWrap: true }} />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
};
