import Box from '@mui/material/Box';
import { Button, CircularProgress, Grid, Popover } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { SelectCompaniesList } from 'features/companies/components/SelectCompaniesList';
import { useGetWorkspaceCompanyQuery } from 'features/projectCompanies/api/projectCompanies.api';
import { useState } from 'react';

/**
 * Task bulk edit responsible menu item
 * @param {string} projectId
 * @param {object} handleUpdate
 * @returns
 */
const TaskBulkEditResponsibleUserMenuItem = ({ handleUpdate, projectId }) => {
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [selected, setSelected] = useState([]);

  const { data: company = { users: [] }, isLoading } = useGetWorkspaceCompanyQuery({ projectId });

  const handleSelect = (user, isSelected) => {
    if (isSelected) {
      setSelected(selected.filter((selectedCompany) => selectedCompany.id !== user.id));
    } else {
      setSelected([...selected, user]);
    }
  };

  const handleSubmit = () => {
    handleUpdate(selected);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(undefined);
    setSelected([]);
  };

  return (
    <>
      <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
        Responsible
        {anchorEl ? <ArrowDropDown sx={{ fontSize: 18 }} /> : <ArrowDropUp sx={{ fontSize: 18 }} />}
      </Button>
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
        >
          <Box style={{ width: '500px', padding: '10px 10px' }}>
            <div style={{ maxHeight: '300px' }}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <SelectCompaniesList
                  companies={company.users}
                  handleSelect={handleSelect}
                  selected={selected}
                />
              )}
            </div>
            <Grid container justifyContent={'flex-end'} spacing={1}>
              <Grid item>
                <Button color={'primary'} size={'small'} onClick={handleSubmit}>
                  Close
                </Button>
              </Grid>
              <Grid item>
                <Button color={'primary'} size={'small'} variant="contained" onClick={handleSubmit}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Popover>
      )}
    </>
  );
};



export default TaskBulkEditResponsibleUserMenuItem;
