import { Box, useMediaQuery } from '@mui/material';
import { MobileNav } from 'components/navigation/MobileNav';
import { Nav } from 'components/navigation/Nav';

export const MainLayout = ({ children }) => {
  const isSmallDisplay = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <Box
      display={'flex'}
      flexDirection={isSmallDisplay ? 'column' : 'row'}
      height={'100dvh'}
      minHeight={'100dvh'}
    >
      {isSmallDisplay ? <MobileNav /> : <Nav />}
      <Box flex={1} overflow={'auto'}>
        {children}
      </Box>
    </Box>
  );
};
