import * as React from "react";

const SvgCalender = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0.5C4 0.367392 3.94732 0.240215 3.85355 0.146447C3.75979 0.0526784 3.63261 0 3.5 0C3.36739 0 3.24021 0.0526784 3.14645 0.146447C3.05268 0.240215 3 0.367392 3 0.5V1H2C1.46957 1 0.960859 1.21071 0.585786 1.58579C0.210714 1.96086 0 2.46957 0 3L0 4H16V3C16 2.46957 15.7893 1.96086 15.4142 1.58579C15.0391 1.21071 14.5304 1 14 1H13V0.5C13 0.367392 12.9473 0.240215 12.8536 0.146447C12.7598 0.0526784 12.6326 0 12.5 0C12.3674 0 12.2402 0.0526784 12.1464 0.146447C12.0527 0.240215 12 0.367392 12 0.5V1H4V0.5ZM0 5H16V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5ZM12.5 7C12.3674 7 12.2402 7.05268 12.1464 7.14645C12.0527 7.24021 12 7.36739 12 7.5V8.5C12 8.63261 12.0527 8.75979 12.1464 8.85355C12.2402 8.94732 12.3674 9 12.5 9H13.5C13.6326 9 13.7598 8.94732 13.8536 8.85355C13.9473 8.75979 14 8.63261 14 8.5V7.5C14 7.36739 13.9473 7.24021 13.8536 7.14645C13.7598 7.05268 13.6326 7 13.5 7H12.5Z"
      fill={props.color ? props.color : '#B3B3B3'}
    />
  </svg>
);

export default SvgCalender;
