import { StyledDialog } from '@blackhyve/common';
import {
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import RichTextEditor from 'components/common/v3/RichTextEditor';
import { times } from 'lodash';
import { memo, useState } from 'react';
import { useGetRecipeTasksQuery } from '../api/recipeTask.api';

const RecipeDetailsDialog = ({ recipe, open, handleClose }) => {
  const { data: recipeTasks = [], isLoading: isLoadingRecipeTasks } = useGetRecipeTasksQuery(
    { recipeId: recipe.id },
    {
      skip: !open,
    }
  );

  const [readMore, setReadMore] = useState(false);
  const contentHeight = document.querySelector('.quill')?.clientHeight;
  const linkName = readMore ? 'Read Less << ' : 'Read More >> ';

  if (document.querySelector('.quill')?.classList) {
    readMore
      ? document.querySelector('.quill').classList.remove('truncate-content')
      : document.querySelector('.quill').classList.add('truncate-content');
  }

  return (
    <StyledDialog
      actions={<Button onClick={handleClose}>Close</Button>}
      handleClose={handleClose}
      open={open}
      title={recipe?.name}
    >
      <Grid container>
        <Grid item xs={12}>
          <RichTextEditor
            className="truncate-content"
            readOnly={true}
            text={recipe.notes ? recipe.notes : '<b>No notes added.</b>'}
            theme="bubble"
          />
        </Grid>
        {recipe.notes && contentHeight >= 305 && (
          <Grid item>
            <Link
              component={Button}
              onClick={() => {
                setReadMore(!readMore);
              }}
            >
              {linkName}
            </Link>
          </Grid>
        )}
        <Divider orientation="horizontal" />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Percentage</TableCell>
                <TableCell>Insert as</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoadingRecipeTasks && recipeTasks && recipeTasks.length > 0 ? (
                recipeTasks.map((task, index) => {
                  return <RecipeRow hierarchy={`${index + 1}`} key={task.id} task={task} />;
                })
              ) : !isLoadingRecipeTasks ? (
                <Grid item>This Recipe Has No Tasks</Grid>
              ) : isLoadingRecipeTasks ? (
                times(10, (index) => (
                  <TableRow key={index}>
                    <Skeleton
                      colSpan={3}
                      component={TableCell}
                      sx={{ display: 'table-cell' }}
                      variant={'text'}
                    />
                  </TableRow>
                ))
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </StyledDialog>
  );
};

const RecipeRow = memo(({ task }) => {
  return (
    <TableRow>
      <TableCell>{task.name}</TableCell>
      <TableCell>{(Number(task.percentage) * 100).toFixed(2)}</TableCell>
      <TableCell>{task.fixed_duration ? 'Duration' : 'Percentage'}</TableCell>
    </TableRow>
  );
});

export default RecipeDetailsDialog;
