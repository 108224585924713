import { Pace } from 'Pace';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import { Status } from 'features/tasks';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import Container from "@mui/material/Container";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useState, useRef, useEffect } from 'react';
import { ProductionRating } from 'ProductionRating';
import LoadingButton from '@mui/lab/LoadingButton';
import ListItemText from '@mui/material/ListItemText';
import { ConfirmDeleteDialog } from '@blackhyve/common';
import ListItemButton from '@mui/material/ListItemButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MenuOptions } from './ProductionBoardMenuOptions';
import { useNavigate, useParams } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import { useDeleteTasksMutation, useGetTaskQuery, useUpdateTasksMutation } from 'features/tasks/store/task.api';




import Share from '@mui/icons-material/Share';
import Settings from '@mui/icons-material/Settings';
import CommentIcon from '@mui/icons-material/Comment';
import ListItemIcon from '@mui/material/ListItemIcon';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import DirectionsWalk from '@mui/icons-material/DirectionsWalk';
import PendingActions from '@mui/icons-material/PendingActions';




import DetailTab from './ProductionBoardCard/TaskDetailTab';
import StuckPointTab from './ProductionBoardCard/StuckPointTab';
import CommentTab from './ProductionBoardCard/CommentTab';
import ActivitiesTab from './ProductionBoardCard/ActivitiesTab';
import StepTab from './ProductionBoardCard/StepTab';
import DependencyTab from './ProductionBoardCard/DependencyTab';
import JobwalkTab from './ProductionBoardCard/JobwalkTab';





/**
 * Production Board Task Details
 * @returns 
 */
const ProductionBoardTaskDetails = () => {

  const theme = useTheme();
  const sectionRefs = useRef([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [editTask, setEditTask] = useState(undefined);
  const [activeSection, setActiveSection] = useState('details-tab');
  const workspaceId = Number(useParams().workspaceId) || undefined;
  const taskId = Number(useParams().taskId) || undefined;
  const projectId = Number(useParams().projectId) || undefined;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [updateTasks, { isLoading: isUpdatingTask }] = useUpdateTasksMutation();
  const [deleteTasks, { isLoading: isDeletingTask }] = useDeleteTasksMutation();
  const { data: task, isLoading: isLoadingTask, isFetching: isFetchingTasks,
    isError: isErrorFindingTask } = useGetTaskQuery({ taskId });

  const sections = [
    { name: "Details", id: "details-tab", icon: <Settings />, component: <DetailTab task={task} /> },
    { name: "Steps", id: "step-tab", icon: <PlaylistAddIcon />, component: <StepTab projectId={projectId} task={task} taskId={task?.id} /> },
    { name: "Stuck Points", id: "stuckPoint-tab", icon: <PendingActions />, component: <StuckPointTab projectId={projectId} taskId={taskId} /> },
    { name: "Comments", id: "comment-tab", icon: <CommentIcon />, component: <CommentTab taskId={taskId} /> },
    { name: "Dependencies", id: "dependency-tab", icon: <Share />, component: <DependencyTab taskId={taskId} /> },
    { name: "Jobwalk", id: "jobwalk-tab", icon: <DirectionsWalk />, component: <JobwalkTab taskId={taskId} /> },
  ]

  const handleScrollToTab = (tabId) => {
    const element = document.getElementById(tabId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setActiveSection(tabId);
    }
  };

  const handleOnEdit = () => setEditTask(task?.name);

  const handleOpenConfirmDelete = () => setOpenConfirmDelete(true);

  const handleOnCancel = () => setEditTask(undefined)

  const handleOnKeyDown = (event) => event.keyCode === 13 ? handleOnSave() : null;

  const handleOnSave = () => {
    if (task.name === editTask) {
      handleOnCancel();
      return
    }
    updateTasks({
      tasks: [{
        id: taskId,
        name: editTask,
        project_id: projectId
      }]
    }).then(() => {
      handleOnCancel();
    })
  }

  const handleOnDelete = () => {
    deleteTasks({ tasks: [taskId] })
      .then(() => {
        navigate(`/workspace/${workspaceId}/projects/${projectId}`);
        enqueueSnackbar('Task deleted successfully', { variant: 'success' });
      })
  }

  useEffect(() => {
    if (isErrorFindingTask) {
      enqueueSnackbar('Task not found', { variant: 'error' });
      navigate(`/workspace/${workspaceId}/projects/${projectId}`);
    }
  }, [isErrorFindingTask, projectId, workspaceId, enqueueSnackbar, navigate])

  return <Container maxWidth='xl' sx={{ mt: 2 }}>
    {isLoadingTask ? <CircularProgress className='loading' /> :
      <Grid container>
        <Grid item md={2} xs={0}></Grid>
        <Grid container item alignItems="center" md={10} xs={12}>
          <Grid container item alignItems="center" px={isSmallScreen ? 0 : 1} xs={12}>
            <Grid container item xs gap={1}>
              {editTask ?
                <Grid container item alignItems={'center'} gap={1}>
                  <Grid item xs>
                    <TextField
                      autoFocus
                      fullWidth
                      size='small'
                      value={editTask}
                      variant="outlined"
                      onChange={(event) => setEditTask(event.target.value)}
                      onKeyDown={handleOnKeyDown}
                    />
                  </Grid>
                  <Grid container item gap={1} xs="auto">
                    <LoadingButton loading={isUpdatingTask} size='small' variant="contained" onClick={handleOnSave}>Save</LoadingButton>
                    <Button size='small' variant="outlined" onClick={handleOnCancel}>Cancel</Button>
                  </Grid>
                </Grid> :
                <>
                  <Typography sx={{ fontWeight: 'bold' }} variant={isSmallScreen ? "h5" : "h4"}>
                    {task?.name}
                  </Typography>
                  <Box alignItems={'center'} display="flex" gap={1} >
                    <Status value={task?.status} />
                    <ProductionRating value={task?.production_rate} />
                    <Pace value={task?.pace} />
                  </Box>
                </>}
            </Grid>

            {!editTask && <Grid container item justifyContent={'flex-end'} xs="auto">
              <MenuOptions entityObj={task} onDelete={handleOpenConfirmDelete} onEdit={handleOnEdit} />
            </Grid>}
          </Grid>
        </Grid>
        <Grid container item mt={1} spacing={1} xs={12}>
          {!isSmallScreen &&
            <Grid item md={2} sx={{ borderBottom: 1, borderColor: 'divider' }} xs={12}>
              <nav aria-label="main mailbox folders" style={{ position: 'sticky', top: '0px' }}>
                <List>
                  {sections.map((section, index) => {
                    const isSelected = activeSection === section.id
                    return <ListItem disablePadding key={section.id} ref={(el) => (sectionRefs.current[index] = el)} sx={{ borderRightColor: isSelected ? 'primary' : 'none' }}>
                      <ListItemButton selected={isSelected} onClick={() => handleScrollToTab(section.id)}>
                        <ListItemIcon>
                          {section.icon}
                        </ListItemIcon>
                        <ListItemText primary={section.name} sx={{
                          '& .MuiTypography-root':
                            { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
                        }}
                        />
                      </ListItemButton>
                    </ListItem>
                  })}

                </List>
              </nav>
            </Grid>}
          <Grid container item gap={2} md={10} xs={12}>
            {isFetchingTasks && <LinearProgress sx={{ mx: 1, width: '100%' }} />}
            {sections.map((section) => {
              return <Grid item id={section.id} key={section.id} xs={12}>
                {section.component}
              </Grid>
            })}
          </Grid>
        </Grid>
      </Grid >}

    {openConfirmDelete &&
      <ConfirmDeleteDialog
        handleClose={() => setOpenConfirmDelete(false)}
        handleDelete={handleOnDelete}
        isDeleting={isDeletingTask}
        item={'task'}
        open={openConfirmDelete}
      />}

  </Container >
}






export default ProductionBoardTaskDetails;