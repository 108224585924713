import { api } from 'api';

export const authAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAuthToken: build.query({
      query: ({ refreshToken }) => ({
        url: 'refresh',
        method: 'POST',
        body: { refresh_token: refreshToken },
      }),
      providesTags: (result, error, { refreshToken }) => (result ? ['User'] : []),
    }),
  }),
});
export const { useGetAuthTokenQuery } = authAPI;
