import Chip from '@mui/material/Chip';
import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import { useParams } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import { selectCurrentUser } from 'features/auth';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import InputAdornment from '@mui/material/InputAdornment';
import { StyledMenu } from 'features/activityFeeds/assets/activityFeedStyle';
import { useGetWorkspaceCompanyQuery } from 'features/projectCompanies/api/projectCompanies.api';

export const matchText = (stringToMatch, searchText) =>
  stringToMatch?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;

/**
Jobwalk Filter User Menu
* @param {Array} selectedIds
* @param {Object} handleSelect
* @param {String} label
* @returns 
*/
const JobwalkUserFilterMenu = ({ selectedIds, handleSelect, label }) => {
  const projectId = Number(useParams().projectId) || undefined;
  const [openMenu, setOpenMenu] = useState(false);
  const [searchText, setSearchText] = useState('');

  const currentUser = useSelector((state) => selectCurrentUser(state));

  const { data: company = { users: [] } } = useGetWorkspaceCompanyQuery({ projectId });

  const displayedOptions = useMemo(() => {
    const users =
      currentUser.role === 'super_admin'
        ? [{ ...currentUser, isCurrentUser: true }, ...company.users]
        : [...company.users];
    users.unshift({ id: -1, first_name: 'Unassigned' });
    return users?.filter((option) =>
      matchText(`${option.first_name} ${option.last_name}`, searchText)
    );
  }, [searchText, company.users, currentUser]);

  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleClose = () => {
    setOpenMenu(null);
    setSearchText('');
  };

  let displayLabel = `Someone's ${label}`;

  if (selectedIds.length === 1) {
    const user = displayedOptions.find((user) => user.id === selectedIds[0]);
    if (user) {
      displayLabel =
        user?.id === currentUser?.id
          ? `My ${label}`
          : `${user.first_name} ${user.last_name || ''} - ${label}`;
    }
  } else if (selectedIds.length > 1) {
    displayLabel = `${selectedIds.length} Selected Persons - ${label}`;
  }

  return (
    <>
      <Chip
        color={selectedIds?.length ? 'secondary' : 'default'}
        icon={<PersonIcon fontSize="small" />}
        label={displayLabel}
        variant={'filled'}
        onClick={handleClick}
      />
      <StyledMenu
        anchorEl={openMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={Boolean(openMenu)}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
      >
        <ListSubheader>
          <TextField
            autoFocus
            fullWidth
            placeholder="Type to search..."
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {displayedOptions?.map((user) => (
          <ListItem
            disablePadding
            key={user.id}
            secondaryAction={
              user.isCurrentUser ? <Chip color="secondary" label="Logged in" size="small" /> : null
            }
          >
            <ListItemButton dense onClick={(event) => handleSelect(user.id)}>
              <ListItemIcon>
                <Checkbox checked={selectedIds?.includes(user.id)} />
              </ListItemIcon>
              <ListItemText primary={`${user.first_name} ${user.last_name || ''}`} />
            </ListItemButton>
          </ListItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default JobwalkUserFilterMenu;
