import { Box, Grid } from '@mui/material';
import { memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import BlackHyveLogoComponent from './BlackHyveLogoComponent';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: '100%',
    width: '50px',
    backgroundColor: '#2C2C2C',
    zIndex: '1',
    flexWrap: 'nowrap',
  },
}));

/**
 * Sidebar component
 */
const SideBarComponent = memo(({ children }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Box padding={'12px'}>
        <BlackHyveLogoComponent />
      </Box>
      {children}
    </Grid>
  );
});

export default SideBarComponent;
