import { api, providesList } from 'api';
import { getSocket } from 'helpers/websockets';

const projectAPI = api.enhanceEndpoints({ addTagTypes: ['Project', 'ProjectHolidays', 'Task'] }).injectEndpoints({
  endpoints: (build) => ({
    createProject: build.mutation({
      query: (data) => {
        return {
          url: `/projects`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: (result, error, params) => (result ? [{ type: 'Project', id: 'LIST' }] : []),
    }),

    getProjects: build.query({
      query: () => `projects?include=jobWalkPerformance,holidays`,
      providesTags: (data) => providesList(data, 'Project'),
      transformResponse: (response) => response.data,
    }),

    getProject: build.query({
      query: (id) => `/projects/${id}`,
      providesTags: (result, error, id) => {
        return result ? [{ type: 'Project', id }] : [];
      },
      transformResponse: (response) => response.data,
      async onCacheEntryAdded(
        id,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState, dispatch }
      ) {
        await cacheDataLoaded;;
        const socket = getSocket();
        const { workspaceId } = getState().auth;
        const channelName = `workspaces.${workspaceId}.projects.${id}`;

        try {
          await cacheDataLoaded;
          const handleUpdateProject = (data) => {
            updateCachedData((draft) => {
              Object.assign(draft, data);
              dispatch(
                api.util.updateQueryData('getProjects', { workspaceId: '35' }, (draft) => {
                  const index = draft.findIndex((project) => project.id === data.id);
                  if (index !== -1) {
                    draft[index] = { ...draft[index], ...data };
                  }
                })
              );
            });
          };

          const eventListeners = {
            ProjectUpdated: handleUpdateProject,
          };

          const connectedChannel = socket.join(channelName);
          for (const [event, listener] of Object.entries(eventListeners)) {
            const eventListener = (data) => {
              listener(data);
            };
            connectedChannel.listen(event, eventListener);
          }
        } catch (error) {
          console.log('Error while connecting to sockets while subscribing project =>', error);
        }
        await cacheEntryRemoved;
        socket?.leave(channelName);
      },
    }),

    updateProject: build.mutation({
      query: ({ id, ...data }) => ({
        url: `/projects/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, args) => (result ? [{ type: 'Project', id: args?.id }] : []),
    }),

    deleteProject: build.mutation({
      query: (id) => ({
        url: `/projects/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => (result ? [{ type: 'Project', id }] : []),
    }),

    duplicateProject: build.mutation({
      query: (id) => ({
        url: `projects/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, id) => (result ? [{ type: 'Project', id }] : []),
    }),

    updateProjectKpi: build.mutation({
      query: (id) => ({
        url: `projects/${id}/kpi`,
        method: 'POST',
      }),
    }),

    getProjectHolidays: build.query({
      query: (id) => `projects/${id}/holidays`,
      providesTags: (data) => {
        return providesList(data?.listOfHolidays, 'ProjectHolidays')
      },
      transformResponse: (response) => ({
        listOfHolidays: response.data, holidays:
          response.data.map((holiday) => holiday.date)
      }),
    }),

    createProjectHoliday: build.mutation({
      query: (data) => {
        return {
          url: `/projects/${data.projectId}/holidays`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: (result, error, params) => (result ? [{ type: 'ProjectHolidays' }] : []),
    }),

    updateProjectHoliday: build.mutation({
      query: ({ id, ...data }) => ({
        url: `/holidays/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error, args) => (result ? [{ type: 'ProjectHolidays', id: args?.id }] : []),
    }),

    deleteProjectHoliday: build.mutation({
      query: (id) => ({
        url: `/holidays/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => (result ? [{ type: 'ProjectHolidays' }] : []),
    }),

    fillProjectHolidays: build.mutation({
      query: (id) => ({
        url: `projects/${id}/holidays/fill`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, id) => (result ? [{ type: 'ProjectHolidays' }] : []),
    }),

    runAutoSchedulerJob: build.mutation({
      query: (id) => ({
        url: `projects/${id}/autoschedule`,
        method: 'POST'
      }),
      invalidatesTags: () => [{ type: 'Task' }]
    })

  }),
});

export const {
  useGetProjectQuery,
  useGetProjectsQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useDuplicateProjectMutation,
  useUpdateProjectKpiMutation,
  useGetProjectHolidaysQuery,
  useCreateProjectHolidayMutation,
  useUpdateProjectHolidayMutation,
  useDeleteProjectHolidayMutation,
  useFillProjectHolidaysMutation,
  useRunAutoSchedulerJobMutation

} = projectAPI;
