import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import { useForm, Controller } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import InputAdornment from '@mui/material/InputAdornment';
import {
  checkEmailRegistered,
  checkRegistrationToken,
  clearRegisterUser,
  registerUser,
} from 'features/users';
import {
  StyledErrorAlert,
  StyledFormLabel,
  StyledHeadingLabel,
  StyledTextField,
  StyledLoginButton,
  StyledButtonLoading,
  ShowPasswordLink,
} from '../assets/loginStyles';
import { Alert, Box, CircularProgress } from '@mui/material';

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  confirm_password: '',
};

const SignupForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const urlParams = new URL(window.location.href).searchParams;
  const token = urlParams.get('token');
  const email = urlParams.get('email');
  const workspaceName = urlParams.get('workspace');
  const firstName = urlParams.get('first_name');
  const lastName = urlParams.get('last_name');
  const loading = useSelector((state) => state.users.loading);
  const success = useSelector((state) => state.users.success);
  const error = useSelector((state) => state.users.error);

  const [isCheckingEmailAndToken, setIsCheckingEmailAndToken] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      ...initialState,
      email: email,
      first_name: firstName ?? '',
      last_name: lastName ?? '',
    },
  });

  useEffect(() => {
    dispatch(checkEmailRegistered({ email }))
      .unwrap()
      .then((response) => {
        if (response?.email_verified === false) {
          dispatch(checkRegistrationToken({ email, token }))
            .unwrap()
            .then((response) => {
              setIsCheckingEmailAndToken(false);
              setIsTokenValid(response.token_valid);
            })
            .catch((error) => {
              setIsCheckingEmailAndToken(false);
              console.log(error);
            });
        } else {
          navigate('/login', { replace: true });
        }
      })
      .catch((error) => {
        setIsCheckingEmailAndToken(false);
        console.log(error);
      });
  }, [dispatch, email, navigate, token]);

  const handleSignup = (data) => {
    const requestPayload = {
      token,
      first_name: data.first_name,
      last_name: data.last_name,
      email: email,
      new_email: data.email,
      new_password: data.password,
      confirm_password: data.confirmPassword,
    };
    dispatch(registerUser(requestPayload));
  };

  const password = watch('password');

  useEffect(() => {
    if (success) {
      dispatch(clearRegisterUser());
      navigate('/');
    }
  }, [success, dispatch]);

  if (isCheckingEmailAndToken) {
    return (
      <Box m={'auto'} py={5}>
        <CircularProgress />
      </Box>
    );
  }

  if (isTokenValid) {
    return (
      <form onSubmit={handleSubmit(handleSignup)}>
        <Grid container item style={{ marginTop: 25, gap: 15 }} xs={12}>
          {error && (
            <Grid item xs={12}>
              <StyledErrorAlert fullWidth severity="error">
                {error}
              </StyledErrorAlert>
            </Grid>
          )}
          <Grid container item justifyContent="center" xs={12}>
            <StyledHeadingLabel>
              Join {workspaceName} On <i style={{ margin: '0px 2px' }}>BlackHyve</i>
            </StyledHeadingLabel>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item sm={6} xs={12}>
              <StyledFormLabel>First Name*</StyledFormLabel>
              <Controller
                control={control}
                name="first_name"
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    {...field}
                    autoFocus
                    fullWidth
                    error={error}
                    helperText={error?.message}
                    placeholder="First Name"
                    size="small"
                    variant="outlined"
                  />
                )}
                rules={{
                  required: 'First name is required',
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <StyledFormLabel>Last Name*</StyledFormLabel>
              <Controller
                control={control}
                name="last_name"
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    {...field}
                    fullWidth
                    error={error}
                    helperText={error?.message}
                    placeholder="Last Name"
                    size="small"
                    variant="outlined"
                  />
                )}
                rules={{
                  required: 'Last name is required',
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <StyledFormLabel>Email*</StyledFormLabel>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  {...field}
                  fullWidth
                  disabled={email}
                  error={error}
                  helperText={error?.message}
                  placeholder="Email"
                  size="small"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" size="large">
                          <EmailIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              rules={{
                disabled: !!email,
                required: 'Email is required',
              }}
            />
          </Grid>
          <Grid container item spacing={1}>
            <Grid item sm={6} xs={12}>
              <StyledFormLabel>Password*</StyledFormLabel>
              <Controller
                control={control}
                name="password"
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    {...field}
                    fullWidth
                    error={error}
                    helperText={error?.message}
                    placeholder="Password"
                    size="small"
                    type={!isPasswordHidden ? 'text' : 'password'}
                    variant="outlined"
                  />
                )}
                rules={{
                  required: 'Password is required',
                  validate: {
                    minLength: (value) =>
                      value.length >= 8 || 'Password must be at least 8 characters',
                  },
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <StyledFormLabel>Confirm password*</StyledFormLabel>
              <Controller
                control={control}
                name="confirmPassword"
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    {...field}
                    fullWidth
                    error={error}
                    helperText={error?.message}
                    placeholder="Confirm Password"
                    size="small"
                    type={!isPasswordHidden ? 'text' : 'password'}
                    variant="outlined"
                  />
                )}
                rules={{
                  required: 'Confirm password field is required',
                  validate: (value) =>
                    password === value || 'Confirm password does not match new password',
                }}
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="flex-end" xs={12}>
            <ShowPasswordLink onClick={() => setIsPasswordHidden(!isPasswordHidden)}>
              {!isPasswordHidden ? 'Hide' : 'Show'} Password
            </ShowPasswordLink>
          </Grid>
          <Grid item style={{ position: 'relative' }} xs={12}>
            <StyledLoginButton fullWidth disabled={loading} type="submit" variant="contained">
              Ok, Let's Go
            </StyledLoginButton>
            {loading && <StyledButtonLoading size={24} />}
          </Grid>
        </Grid>
      </form>
    );
  } else {
    return (
      <Alert severity={'error'} sx={{ my: 3 }}>
        Invite has expired or is invalid. Please contact the workspace owner to request a new one
      </Alert>
    );
  }
};

export default SignupForm;
