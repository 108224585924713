import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const MenuButton = styled(({ isHighlighted, ...other }) => <Button {...other} />)(
  ({ isHighlighted, theme }) => ({
    textTransform: 'uppercase',
    color: isHighlighted ? theme.palette.primary.main : theme.palette.text.primary,
  })
);

export const MenuSeparator = styled('div')(({ theme }) => ({
  borderRight: '1px solid',
  margin: '0 2.5px',
  height: 17,
}));
