import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { NavLink, useParams } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useGetZonesQuery } from 'features/locations/store/zone.api';
import { useGetLocationsQuery } from "features/locations/store/locations.api";
import { useGetProjectQuery } from 'features/projects/store/project.api';
import { Typography } from '@mui/material';
import { useGetAreasQuery } from 'features/locations/store/area.api';
import { useGetPhasesQuery } from 'features/locations/store/phase.api';
import { useGetTaskQuery } from 'features/tasks/store/task.api';


const ProductionBoardBreadCrumps = () => {

    const workspaceId = Number(useParams().workspaceId) || undefined;
    const projectId = Number(useParams().projectId) || undefined;
    const locationId = Number(useParams().locationId) || undefined;
    const phaseId = Number(useParams().phaseId) || undefined;
    const zoneId = Number(useParams().zoneId) || undefined;
    const areaId = Number(useParams().areaId) || undefined;
    const taskId = Number(useParams().taskId) || undefined;


    const { data: project, isLoading: isProjectLoading } = useGetProjectQuery(projectId);

    const { location, isLocationLoading } = useGetLocationsQuery({ projectId }, {
        selectFromResult: ({ data: locations, isLoading }) => ({
            location: locations?.find((location) => location.id === locationId),
            isLocationLoading: isLoading
        }),
        skip: !projectId
    });

    const { phase, isPhaseLoading } = useGetPhasesQuery({ locationId }, {
        selectFromResult: ({ data: phases, isLoading }) => ({
            phase: phases?.find((phase) => phase.id === phaseId),
            isPhaseLoading: isLoading
        }),
        skip: !locationId
    });

    const { zone, isZoneLoading } = useGetZonesQuery({ locationId }, {
        selectFromResult: ({ data: zones, isLoading }) => ({
            zone: zones?.find((zone) => zone.id === zoneId),
            isZoneLoading: isLoading
        }),
        skip: !zoneId
    });

    const { area, isAreaLoading } = useGetAreasQuery({ zoneId }, {
        selectFromResult: ({ data: zones, isLoading }) => ({
            area: zones?.find((zone) => zone.id === areaId),
            isAreaLoading: isLoading
        }),
        skip: !areaId
    });


    const { data: task, isLoading: isLoadingTasks } = useGetTaskQuery({ taskId }, { skip: !taskId });


    if (isLocationLoading || isZoneLoading || isProjectLoading || isAreaLoading || isPhaseLoading || isLoadingTasks) {
        return <Skeleton width={'50%'} height={40} />
    }

    const breadcrumbs = [
        projectId && project && { name: project?.name, link: `/workspace/${workspaceId}/projects/${projectId}/production-board/locations` },
        locationId && location && { name: location?.name, link: `/workspace/${workspaceId}/projects/${projectId}/production-board/locations/${location.id}` },
        phaseId && phase && { name: phase?.name, link: `/workspace/${workspaceId}/projects/${projectId}/production-board/locations/${locationId}/phases/${phase.id}` },
        zoneId && zone && { name: zone?.name, link: `/workspace/${workspaceId}/projects/${projectId}/production-board/locations/${locationId}/phases/${phaseId}/zones/${zoneId}` },
        areaId && area && { name: area?.name, link: `/workspace/${workspaceId}/projects/${projectId}/production-board/locations/${locationId}/phases/${phaseId}/zones/${zoneId}/areas/${areaId}` },
        taskId && task && { name: task?.name, link: `/workspace/${workspaceId}/projects/${projectId}/production-board/locations/${locationId}/phases/${phaseId}/zones/${zoneId}/areas/${areaId}/tasks/${taskId}` }
    ].filter(Boolean);


    return <Breadcrumbs maxItems={2} separator={<NavigateNextIcon fontSize="small" />}>
        {breadcrumbs.map((breadcrumb) => <Link key={breadcrumb?.name}
            underline="hover"
            component={NavLink}
            to={breadcrumb?.link}>
            <Typography> {breadcrumb?.name}</Typography>
        </Link>)}
    </Breadcrumbs>

}

export default ProductionBoardBreadCrumps