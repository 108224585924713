import { autoUpdateLag } from 'features/gantt/helpers/autoUpdateLag';

export const recipeGanttEvents = {
  onGanttReady: function () {
    const el = document.querySelector('.gantt_grid');
    if (el) {
      el.addEventListener('scroll', function () {
        document.documentElement.style.setProperty(
          '--gantt-frozen-column-scroll-left',
          el.scrollLeft + 'px'
        );
      });
    }
    autoUpdateLag(this);
  },
  onBeforeLightBox: function (id) {
    return false;
  },
  onBeforeTaskMultiSelect: function (id, state, event) {
    if (id && state === false && !event) {
      return true;
    }
    if (event?.target?.id.includes('checkbox-')) {
      return true;
    } else {
      return false;
    }
  },
  // onBeforeTaskDrag: function (id, mode, e) {
  //   let task = this.getTask(id);
  //   if (mode === this.config.drag_mode.move && task.$target.length > 0) {
  //     return false;
  //   }
  //   return true;
  // },
  onBeforeTaskAdd: function (id, task) {
    if (task.type && task.type != this.config.types.placeholder && id !== 0) {
      task.parent = -1;
      task.fixed_duration = 'false';
    }
    return true;
  },
  onAfterTaskAdd: function (id, task) {
    if (task.type !== this.config.types.placeholder && !task.$virtual) {
      updatePercentageOfChildren(-1, this);
    }
  },
  onBeforeTaskUpdate: function (id, task) {
    //if the task has no incoming links, set the constraint to asap so it moves to Day 1.
    if (!task.$target.length) {
      task.constraint_type = 'asap';
      task.constraint_date = undefined;
    }
    return true;
  },
  onAfterLinkDelete: function (id, link) {
    this.autoSchedule(link.target);
    this.autoSchedule(link.source);
  },
  onAfterTaskDrag: function (id, mode, task, original) {
    // this.autoSchedule(id);
  },
  onAfterAutoSchedule: function (taskId, updatedTasks) {
    updatePercentageOfChildren(-1, this);
  },
  onAfterTaskDelete: function (id, task) {
    const parentId = task.parent;
    updatePercentageOfChildren(parentId, this);
  },
};

export const recipeGanttInlineEvents = {
  onBeforeEditStart: function ({ id, columnName }) {
    //Prevent inline editor for start date if active
    const task = this.getTask(id);
    if (task.id === -1) {
      return false;
    }
    switch (columnName) {
      case 'fixed_duration':
        if (task.type === 'placeholder') {
          return false;
        }
        break;
      case 'percentage':
        if (task.type === 'placeholder') {
          return false;
        }
        break;
      default:
        return true;
    }
  },

  onSave: function ({ id, columnName, oldValue, newValue }) {
    if (columnName == 'duration') {
      this.autoSchedule();
    }
    return true;
  },
};

const updatePercentageOfChildren = (parentId, gantt) => {
  if (!gantt.isTaskExists(parentId) && !gantt.hasChildren(parentId)) {
    return false;
  }
  const parent = gantt.getTask(parentId);
  const children = gantt.getChildren(parentId);
  let total = 0;
  gantt.batchUpdate(() => {
    children.forEach((childId) => {
      const child = gantt.getTask(childId);
      const percentage = (child.duration / parent.duration) * 100;
      if (child.percentage !== percentage.toFixed(2)) {
        child.percentage = percentage.toFixed(2);
        gantt.updateTask(childId);
      }
      total += percentage;
    });
    //parent.percentage = total.toFixed(2);
    //gantt.refreshTask(parentId);
  });
};
