import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import isValid from 'date-fns/isValid';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import { useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import { selectCurrentUser } from 'features/auth';
import TextField from '@mui/material/TextField';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  useGetProjectCompaniesQuery,
  useGetWorkspaceCompanyQuery,
} from 'features/projectCompanies/api/projectCompanies.api';
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import { HighlighterLinkLabel } from 'assets/style-components/typography';


/**
 * Stuck Point Form
 * @param {String} projectId
 * @returns
 */
export const StuckPointForm = ({ projectId, form, handleSave, error }) => {
  const { handleSubmit, control } = form;
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const currentUser = useSelector((state) => selectCurrentUser(state));
  const { data: userCompany = { users: [] } } = useGetWorkspaceCompanyQuery({ projectId });
  const { data: companies = [] } = useGetProjectCompaniesQuery({ projectId });

  const users = useMemo(() => {
    const users =
      currentUser.role === 'super_admin'
        ? [{ ...currentUser, isCurrentUser: true }, ...userCompany.users]
        : [...userCompany.users];
    return users;
  }, [userCompany, currentUser]);

  const handleToggleDescription = (event) => {
    event.preventDefault();
    setIsDescriptionVisible(!isDescriptionVisible);
  };

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Grid container spacing={1}>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        <Grid container item xs={12}>
          <FormLabel>Title</FormLabel>
          <Controller
            control={control}
            name="title"
            rules={{ required: 'Title field is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={error}
                helperText={error?.message}
                placeholder="Title for the issue"
                size="small"
              />
            )}
          />

          <HighlighterLinkLabel style={{ paddingTop: '5px' }}>
            <Link component={'button'} onClick={handleToggleDescription}>
              {isDescriptionVisible ? 'Hide' : 'Add'} Description
            </Link>
          </HighlighterLinkLabel>
        </Grid>
        {isDescriptionVisible && (
          <Grid container item xs={12}>
            <FormLabel>Description</FormLabel>
            <Controller
              control={control}
              name="description"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  error={error}
                  helperText={error?.message}
                  minRows={3}
                  placeholder="Describe the issue"
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormLabel>Stuck By</FormLabel>
          <Controller
            control={control}
            name="companies_to_blame"
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                disableCloseOnSelect
                fullWidth
                multiple
                isOptionEqualToValue={(option, value) => option.id === value}
                options={companies}
                getOptionLabel={(option) =>
                  option?.name ??
                  (companies && companies.find((company) => company.id === option)?.name)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={error}
                    helperText={error?.message}
                    placeholder="Select Company"
                    size="small"
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    {option?.name}
                  </li>
                )}
                onChange={(event, newValue) => {
                  field.onChange(newValue.map((company) => company?.id ?? company));
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel>Responsible</FormLabel>
          <Controller
            control={control}
            name="responsible_users"
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                disableCloseOnSelect
                fullWidth
                multiple
                isOptionEqualToValue={(option, value) => option.id === value}
                options={users}
                getOptionLabel={(option) =>
                  option?.first_name ??
                  (users?.length && users.find((user) => user.id === option)?.first_name)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={error}
                    helperText={error?.message}
                    placeholder="Select User"
                    size="small"
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <ListItem
                    {...props}
                    disablePadding
                    key={option.id}
                    secondaryAction={
                      option.isCurrentUser ? (
                        <Chip color="secondary" label="Logged in" size="small" />
                      ) : null
                    }
                  >
                    <ListItemIcon>
                      <Checkbox checked={selected} />
                    </ListItemIcon>
                    <ListItemText primary={`${option.first_name} ${option.last_name}`} />
                  </ListItem>
                )}
                onChange={(event, newValue) => {
                  field.onChange(newValue.map((user) => user?.id ?? user));
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <FormLabel>Opened On</FormLabel>
          <Controller
            control={control}
            id="opened_on"
            name="opened_on"
            render={({
              field: { onChange, value, ref, ...field },
              fieldState: { error, invalid },
            }) => (
              <DatePicker
                maxDate={new Date()}
                ref={ref}
                value={value}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                    helperText: error?.message,
                    error: error,
                    ...field,
                  },
                  actionBar: {
                    actions: ['today'],
                  },
                }}
                onChange={onChange}
              />
            )}
            rules={{
              required: 'Opened on date is required',
              validate: {
                isDate: (v) => isValid(v) || 'Invalid Date',
              },
            }}
          />
        </Grid>
        <Grid container item md={4} xs={12}>
          <FormLabel>Send Notification</FormLabel>
          <Controller
            control={control}
            name="is_notify"
            render={({ field, fieldState: { error } }) => {
              return (
                <Select {...field} fullWidth size="small">
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
              );
            }}
          />
        </Grid>
        {/* <Grid item md={8} xs={12}>
          <Controller
            control={control}
            name="affects_pr"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label={<FormLabel>No work can be done until this is resolved</FormLabel>}
                />
              );
            }}
          />
        </Grid> */}
      </Grid>
    </form>
  );
};
