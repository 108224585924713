import * as React from "react";

const SvgArrowRight = (props) => (
  <svg
    fill="none"
    height={10}
    width={8}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.912 8.825 2.087 10l5-5-5-5L.912 1.175 4.73 5 .912 8.825Z"
      fill={props.fill ? props.fill : "#F2F2F3"}
    />
  </svg>
);

export default SvgArrowRight;
