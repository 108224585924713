import * as React from "react";

const SvgCopyIcon = (props) => (
  <svg
    width={14}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 .167H2C1.267.167.667.767.667 1.5v9.333H2V1.5h8V.167Zm2 2.666H4.667c-.734 0-1.334.6-1.334 1.334V13.5c0 .733.6 1.333 1.334 1.333H12c.733 0 1.333-.6 1.333-1.333V4.167c0-.734-.6-1.334-1.333-1.334ZM4.667 13.5H12V4.167H4.667V13.5Z"
      fill={props.color ? props.color : '#757575'}
    />
  </svg>
);

export default SvgCopyIcon;
