import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Settings from '@mui/icons-material/Settings';
import { Fragment, memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BlackHyveLogoComponent from './BlackHyveLogoComponent';

/**
 * MainLayoutComponent
 * @param {JSX} children
 * @param {Array} workspaces // Optional
 * @param {Array} headerMenus
 * @param {Object} workspaceId
 * @param {Array} settingMenuOptions
 * @returns User layout
 */

const MainLayoutComponent = (props) => {
  const {
    children,
    workspaces,
    headerMenus,
    workspaceId,
    settingMenuOptions,
    handleSelectWorkspace,
  } = props;

  return (
    <div>
      <Grid
        container
        direction="column"
        style={{
          backgroundColor: '#fafbfc',
          minHeight: '100vh',
        }}
      >
        <Grid item>
          <SideBarComponent
            workspaces={workspaces}
            headerMenus={headerMenus}
            workspaceId={workspaceId}
            settingMenuOptions={settingMenuOptions}
            handleSelectWorkspace={handleSelectWorkspace}
          />
        </Grid>
        <Grid item style={{ height: `calc(100%-60px)`, width: '100%' }}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default MainLayoutComponent;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '50px',
    backgroundColor: '#151B26',
  },
  start: { marginLeft: '20px', marginTop: '10px' },
  end: { display: 'flex', justifyContent: 'flex-end' },
}));

/**
 * Sidebar component
 * @param {Array} settingMenuItems
 * @param {Array} headerMenus
 * @param {Array} workspaces
 * @param {Number} workspaceId
 */
const SideBarComponent = memo(
  ({ settingMenuOptions, headerMenus, workspaces, workspaceId, handleSelectWorkspace }) => {
    const classes = useStyles();

    return (
      <Grid container className={classes.root}>
        <Grid item className={classes.start}>
          <Grid container>
            <Grid item>
              <BlackHyveLogoComponent />
            </Grid>
            {headerMenus &&
              headerMenus.map(({ path, name }, index) => {
                return <NavOptionComponent key={name} path={path} labelName={name} />;
              })}
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid container className={classes.end}>
            {workspaceId && (
              <Grid item style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <WorkspaceDropdownMenu
                  workspaces={workspaces}
                  workspaceId={workspaceId}
                  handleSelectWorkspace={handleSelectWorkspace}
                />
              </Grid>
            )}
            <Grid item>
              <SettingMenuDropdown menuList={settingMenuOptions} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

const navOptionStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 600,
    color: 'white',
    textTransform: 'none',
    '&:active': {
      backgroundColor: 'rgba(0,0,0,0.3)',
    },
    '&:focus': {
      backgroundColor: 'rgba(0,0,0,0.3)',
    },
  },
}));

/**
 *
 * @param {String} path
 * @param {String} labelName
 */
const NavOptionComponent = ({ path, labelName }) => {
  const classes = navOptionStyle();
  return (
    <Grid item key={labelName}>
      <Button
        className={classes.root}
        component={Link}
        to={{
          pathname: path,
        }}
      >
        {labelName}
      </Button>
    </Grid>
  );
};

/**
 * Icon dropdown component
 * @param {Array} menuList
 */

const settingMenuDropdownStyle = makeStyles((theme) => ({
  menu: {
    fontSize: '12px',
    color: '#666666',
  },
  menuStyle: {
    marginTop: '18px',
  },
  iconButton: {
    color: 'white',
  },
}));

/**
 * Showing setting icon and click showing dropdown menu
 * @param {Array} menuList
 */
const SettingMenuDropdown = ({ menuList }) => {
  const classes = settingMenuDropdownStyle();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton
        className={classes.iconButton}
        aria-label="Settings"
        onClick={handleMenuClick}
        size="large">
        <Settings />
      </IconButton>
      <Menu
        elevation={2}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleMenuClose}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            borderRadius: '0px',
            width: '20ch',
            marginLeft: '20px',
            backgroundColor: '#F2F2F3',
          },
        }}
        className={classes.menuStyle}
      >
        {menuList &&
          menuList.map(({ path, name, action }, index) => {
            return (
              <div key={name}>
                <MenuItem
                  key={`${name}_menu`}
                  aria-label="edit"
                  size="small"
                  component={Link}
                  className={classes.menu}
                  onClose={handleMenuClose}
                  to={
                    path
                      ? {
                          pathname: path,
                        }
                      : {}
                  }
                  onClick={action ? action : handleMenuClose}
                >
                  {name}
                </MenuItem>
                {index !== menuList.length - 1 ? <Divider variant="middle" /> : null}
              </div>
            );
          })}
      </Menu>
    </Fragment>
  );
};

const profileStyle = makeStyles((theme) => ({
  icon: { color: 'white', padding: '12px', paddingTop: '8px' },
  user: {
    width: 30,
    height: 30,
    borderRadius: '22px',
    border: '2px solid white',
  },
}));

const ProfileComponent = () => {
  const classes = profileStyle();
  let profileImage = localStorage.getItem('profileImage');
  profileImage = profileImage ? profileImage : '/assets/images/upload-image.svg';
  return (
    <div className={classes.icon} aria-label="Avatar">
      <img className={classes.user} src={profileImage} />
    </div>
  );
};

const WorkspaceDropdownMenu = ({ workspaceId, workspaces, handleSelectWorkspace }) => {
  const classes = settingMenuDropdownStyle();

  const [selectedWorkSpace, setSelectedWorkspace] = useState(workspaceId);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleSelect = (workspace) => {
    setSelectedWorkspace(workspace);
    handleMenuClose();
    handleSelectWorkspace(workspace);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (workspaceId && workspaces && workspaces.length) {
      const selected = workspaces.find((workspace) => workspace.id == workspaceId);
      if (selected) setSelectedWorkspace(selected);
    }
  }, [workspaceId, workspaces]);

  return (
    <Fragment>
      <IconButton
        style={{ paddingBottom: '0px', paddingTop: '0px' }}
        className={classes.iconButton}
        aria-label="Settings"
        onClick={handleMenuClick}
        size="large">
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          style={{
            color: '#FFFFFF',
            fontSize: '15px',
            fontWeight: 700,
            marginBottom: '0px',
          }}
        >
          {selectedWorkSpace ? selectedWorkSpace.company : 'Select Workspace'}
        </Typography>
      </IconButton>
      {workspaces && Array.isArray(workspaces) && (
        <Menu
          elevation={2}
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              borderRadius: '5px',
              marginLeft: '10px',
              backgroundColor: '#FFFFFF',
            },
          }}
          className={classes.menuStyle}
        >
          {workspaces &&
            workspaces.map((workspace) => {
              return (
                <MenuItem onClick={() => handleSelect(workspace)}>{workspace.company}</MenuItem>
              );
            })}
        </Menu>
      )}
    </Fragment>
  );
};
