import { Box } from '@mui/material';
import { memo } from 'react';
import { getPolygonCentroid } from '../utils/polygonUtils';

export const Polygon = memo(function Polygon({
  points,
  name,
  id,
  color,
  selected,
  error,
  scale = 1,
  GroupProps,
  labels,
  maxLabels = 3,
}) {
  const labelPosition = getPolygonCentroid(points);
  const fill = Array.isArray(color)
    ? color.length > 1
      ? `url(#stripe-${id})`
      : color[0]?.color
    : color;
  return (
    <Box
      as={'g'}
      className={selected ? 'selected' : undefined}
      sx={{ zIndex: selected ? 5 : 0 }}
      {...GroupProps}
    >
      <polygon
        data-polygon-id={id}
        fill={fill}
        fillOpacity={0.3}
        points={points?.map((point) => `${point.x},${point.y}`).join(' ')}
        stroke={error ? '#f00' : color}
        strokeDasharray={error ? '5,5' : undefined}
        strokeOpacity={0.8}
        strokeWidth={1 / scale}
      />
      <defs>
        {Array.isArray(color) && (
          <pattern
            height="10"
            id={`stripe-${id}`}
            patternTransform="rotate(45)"
            patternUnits="userSpaceOnUse"
            width="50"
          >
            {color.map((colorObj, index) => (
              <rect
                fill={colorObj.color || colorObj}
                height="100%"
                key={index}
                width={`${50 / color.length}`}
                x={`${(50 / color.length) * index}`}
              />
            ))}
          </pattern>
        )}
      </defs>
      {labelPosition &&
        !isNaN(labelPosition.x) &&
        isFinite(labelPosition.x) &&
        !isNaN(labelPosition.y) &&
        isFinite(labelPosition.y) && (
          <g fontSize={8 / scale} paintOrder={'stroke'} stroke={'#FFF'} strokeWidth={1.5 / scale}>
            <text
              {...labelPosition}
              fill={'#000'}
              fontWeight={'900'}
              pointerEvents={'none'}
              textAnchor={'middle'}
            >
              <tspan>{name}</tspan>
            </text>
            {labels &&
              labels.slice(0, maxLabels).map((label, index) => (
                <text
                  dy={((index + 1) * 12) / scale}
                  textAnchor="middle"
                  {...labelPosition}
                  fill={'#000'}
                  fontWeight={'600'}
                  key={label + index}
                >
                  <tspan>{label}</tspan>
                  {/* <tspan>{task.crew_size} </tspan> */}
                  {/* {task?.companies &&
                    task.companies.map((company) => <tspan key={company.id}>{company.name}</tspan>)} */}
                </text>
              ))}
            {labels && labels.length > maxLabels && (
              <text
                dy={((maxLabels + 1) * 12) / scale}
                textAnchor="middle"
                {...labelPosition}
                fill={'#000'}
                fontWeight={'600'}
              >
                +{labels.length - maxLabels}
              </text>
            )}
          </g>
        )}
      {selected &&
        points?.map((point, index) => (
          <Point color={color} index={index} key={index} polygonId={id} {...point} scale={scale} />
        ))}
    </Box>
  );
});

const Point = memo(function Point({ polygonId, index, x, y, color, scale = 1 }) {
  return (
    <Box
      as={'circle'}
      cursor={'grab'}
      cx={x}
      cy={y}
      data-point-index={index}
      data-polygon-id={polygonId}
      fill="white"
      r={3 / scale}
      stroke={color}
      strokeWidth={1 / scale}
      sx={{
        ':hover': {
          fill: color,
        },
      }}
    />
  );
});
