import * as React from "react";

const SvgDeleteIconRed = (props) => (
  <svg
    width={16}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 2.25A.25.25 0 0 1 6.75 2h2.5a.25.25 0 0 1 .25.25V3.5h-3V2.25Zm4.5 0V3.5h2.25a.75.75 0 1 1 0 1.5H2.75a.75.75 0 0 1 0-1.5H5V2.25C5 1.284 5.784.5 6.75.5h2.5c.966 0 1.75.784 1.75 1.75ZM4.496 7.175a.75.75 0 1 0-1.492.15l.66 6.6A1.75 1.75 0 0 0 5.405 15.5h5.19c.9 0 1.652-.681 1.741-1.576l.66-6.6a.75.75 0 0 0-1.492-.149l-.66 6.6a.25.25 0 0 1-.249.225h-5.19a.25.25 0 0 1-.249-.225l-.66-6.6Z"
      fill={props.color ? props.color : "#B3B3B3"}
    />
  </svg>
);

export default SvgDeleteIconRed;
