import { hasChild } from '@blackhyve/utilities/tasks';
import { addDays, differenceInDays, subDays } from 'date-fns';

export function dndCustomDates(gantt) {
  const helpers = gantt.utils.dom;
  const drag = {};

  gantt.attachEvent('onBeforeTaskDrag', function (id, mode, event) {
    const pos = helpers.getRelativeEventPosition(event, gantt.$task_data);
    drag.start_x = pos.x;
    drag.start_y = pos.y;
    drag.object = { ...gantt.getTask(id) };
    drag.drag_end_date =
      mode === gantt.config.drag_mode.resize && event.target.classList.contains('task_end_date');
    return true;
  });

  gantt.attachEvent('onTaskDrag', function (id, mode, task, original, event) {
    if (task.status !== 'todo' || task.type === 'parent_task') {
      const pos = helpers.getRelativeEventPosition(event, gantt.$task_data);
      const shift = pos.x - drag.start_x;
      if (
        mode === gantt.config.drag_mode.move ||
        (mode === gantt.config.drag_mode.resize && drag.drag_end_date)
      ) {
        const newEndDate = gantt.dateFromPos(
          gantt.posFromDate(drag.object.scheduled_end_date) + shift
        );
        task.scheduled_end_date = newEndDate;
      }
      task.end_date =
        task.autoschedule_date === 'schedule' ? task.scheduled_end_date : original.end_date;
    } else {
      task.forecasted_start_date = task.start_date;
      task.scheduled_end_date = task.end_date;
      task.forecasted_end_date = task.end_date;
    }

    if (+subDays(task.scheduled_end_date, 1) < +task.start_date) {
      task.scheduled_end_date = addDays(task.start_date, 1);
    }
    task.work_days = this.calculateDuration({
      start_date: task.start_date,
      end_date: task.scheduled_end_date,
      task: task,
    });
    task.cal_days = differenceInDays(task.scheduled_end_date, task.start_date);

    return true;
  });

  gantt.attachEvent('onBeforeTaskChanged', function (id, mode, original) {
    const task = gantt.getTask(id);
    if (task.status !== 'todo' || hasChild(id, gantt)) {
      if (mode === gantt.config.drag_mode.resize) {
        if (drag.drag_end_date) {
          if (!gantt.isWorkTime({ date: addDays(task.scheduled_end_date, -1), task })) {
            task.scheduled_end_date = gantt.getClosestWorkTime({
              date: task.scheduled_end_date,
              dir: 'past',
              task: task,
            });
          }
        }
      } else if (mode === gantt.config.drag_mode.move) {
        task.scheduled_end_date = gantt.calculateEndDate({
          start_date: task.start_date,
          duration: gantt.calculateDuration({
            start_date: drag.object.start_date,
            end_date: drag.object.scheduled_end_date,
            task: task,
          }),
          task: task,
        });
      }

      task.scheduled_end_date = gantt.roundDate({
        date: task.scheduled_end_date,
        unit: 'day',
        step: 1,
      });
      task.end_date =
        task.autoschedule_date === 'schedule' ? task.scheduled_end_date : original.end_date;
    } else {
      const startDate = this.getClosestWorkTime({ date: task.start_date, task });
      const endDate = this.getClosestWorkTime({ date: task.end_date, task });
      task.forecasted_start_date = startDate;
      task.scheduled_end_date = endDate;
      task.forecasted_end_date = endDate;
    }

    // Return true only if dates changed
    if (
      +task.start_date !== +original.start_date ||
      +task.scheduled_end_date !== +original.scheduled_end_date
    ) {
      return true;
    } else {
      return false;
    }
  });
}
