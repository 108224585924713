import { Table } from 'components/table/Table';
import { MoreOptions } from './MoreOptions';
import { Avatar, Chip, Stack } from '@mui/material';
import { Star } from '@mui/icons-material';

const columns = [
  {
    field: 'name',
    label: 'Name',
    sort: true,
    render: (company) => (
      <Stack alignItems={'center'} direction={'row'} spacing={1}>
        <Avatar src={company?.logo?.thumb_url}>{company.name.slice(0, 2)}</Avatar>
        <Stack alignItems={'flex-start'}>
          {company.name}
          {Boolean(company.workspace_owner) && (
            <Chip icon={<Star />} label={'Workspace Owner'} size={'small'} sx={{ mr: 1 }} />
          )}
        </Stack>
      </Stack>
    ),
  },
  // { field: 'archived', label: 'Archived', render: (company) => company.deleted_at && 'true' },
  {
    field: 'moreOptions',
    cellProps: { align: 'right' },
    render: (company) => (
      <div onClick={(event) => event.stopPropagation()}>
        <MoreOptions company={company} />
      </div>
    ),
  },
];

export const CompaniesTable = ({ isFetching, isLoading, companies = [], TableProps }) => {
  return (
    <Table
      columns={columns}
      defaultOrderBy={false}
      isFetching={isFetching}
      isLoading={isLoading}
      rows={companies}
      stickyHeader={true}
      {...TableProps}
    />
  );
};
