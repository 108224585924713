const { differenceInDays, isDate, isValid } = require('date-fns');

function calculateTaskDurations(task, gantt) {
  if (
    !isDate(task.start_date) ||
    !isDate(task.scheduled_end_date) ||
    !isValid(task.start_date) ||
    !isValid(task.scheduled_end_date)
  ) {
    return {
      calDays: null,
      workDays: null,
    };
  } else {
    return {
      calDays: differenceInDays(task.scheduled_end_date, task.start_date),
      workDays: gantt.calculateDuration({
        start_date: task.start_date,
        end_date: task.scheduled_end_date,
        task: task,
      }),
    };
  }
}

module.exports = { calculateTaskDurations };
