import { Grid, InputLabel, TextField } from '@mui/material';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { Controller } from 'react-hook-form';

export const OfficeForm = ({ form, onSubmit, disabled }) => {
  const { control, handleSubmit } = form;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel htmlFor="office-name-input">Name*</InputLabel>
          <Controller
            control={control}
            name="name"
            rules={{ required: 'Name is required' }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                required
                disabled={disabled}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                id="office-name-input"
                placeholder="Name"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="office-email-input">Email</InputLabel>
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                required
                disabled={disabled}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                id="office-email-input"
                placeholder="Email"
                size="small"
                variant="outlined"
              />
            )}
            rules={{
              pattern: { value: /^\S+@\S+$/i, message: 'Invalid email address' },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="office-address-input">Address</InputLabel>
          <Controller
            control={control}
            defaultValue=""
            name="address"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                required
                disabled={disabled}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                id="office-address-input"
                placeholder="Address"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor="office-phone-input">Phone</InputLabel>
          <Controller
            control={control}
            defaultValue=""
            name="phone"
            render={({ field, fieldState }) => (
              <MuiTelInput
                {...field}
                fullWidth
                defaultCountry={'US'}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                id="contact-phone-input"
                preferredCountries={['US']}
                size="small"
                variant="outlined"
              />
            )}
            rules={{
              validate: (value) =>
                value === '' || matchIsValidTel(value) || 'Phone number is invalid',
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};
