import { Table } from 'components/table/Table';
import { IconButton, TableContainer } from '@mui/material';
import { formatMoney } from './NumericControls';
import { labelPerType } from './BudgetOverviewGraph';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const nestedLookUp = {
  budget_total: ['budget_labor', 'budget_material'],
  summary_budget_total: ['summary_budget_labor', 'summary_budget_material'],
  summary_contract_total: ['summary_contract_labor', 'summary_contract_material'],
};

const ForecastBreakdownTable = ({ forecast }) => {
  const [showRelatedRows, setShowRelatedRows] = useState({
    budget_labor: false,
    budget_material: false,
    summary_budget_labor: false,
    summary_budget_material: false,
    summary_contract_labor: false,
    summary_contract_material: false,
  });

  const firstDataset = Object.keys(forecast.datasets)[0] || null;

  const columns = [
    {
      label: 'Forecast',
      field: 'title',
      sort: false,
      render: (value) => {
        const isParent = value.id.includes('_total');
        const childKeys = nestedLookUp[value.id];
        const isOpen = childKeys?.some((key) => showRelatedRows[key]);
        return (
          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            <span
              style={{
                whiteSpace: 'nowrap',
                paddingLeft: !isParent ? '1rem' : '',
              }}
            >
              {value.title}
            </span>
            {isParent && childKeys && (
              <IconButton
                size="small"
                style={{ marginLeft: '1rem' }}
                onClick={() =>
                  setShowRelatedRows((state) => {
                    const nextState = { ...state };

                    childKeys.forEach((key) => {
                      nextState[key] = !nextState[key];
                    });

                    return nextState;
                  })
                }
              >
                {isOpen ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )}
          </span>
        );
      },
    },
  ].concat(
    firstDataset
      ? forecast.datasets[firstDataset].map(({ label }, index) => ({
          label,
          field: `${label}_${index}`,
          sort: false,
          render: (value) => `$${formatMoney(value[`${label}_${index}`], false)}`,
        }))
      : []
  );

  const rows = Object.entries(forecast.datasets)
    .map(([type, data], index) => {
      return data.reduce(
        (acc, { label, value }, index) => {
          acc[`${label}_${index}`] = value;
          return acc;
        },
        { id: type, title: labelPerType[type] || type }
      );
    })
    .filter((row) => row.id.includes('_total') || showRelatedRows[row.id]);

  return (
    <>
      <TableContainer>
        <Table
          columns={columns}
          defaultOrderBy={false}
          isFetching={false}
          isLoading={false}
          rows={rows}
          stickyHeader={true}
        />
      </TableContainer>
    </>
  );
};

export default ForecastBreakdownTable;
