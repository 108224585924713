import ArrowRight from '@blackhyve/common/src/icons/ArrowRight';
import { Button, MenuItem, Popover, Typography } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { SelectLocation } from 'components/projectOverview/gantt/ganttConfig/column/menu/locationMenu';
import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useGetNormalizedLbsQuery } from 'features/locations/store/location.api';

const TaskBulkEditAreasMenuItem = ({ handleUpdate, handleMultiUpdate, projectId, taskIds }) => {
  const { data: lbsData, isLoading: isLoadingLbs } = useGetNormalizedLbsQuery();

  const zoneEntities = !isEmpty(lbsData?.zones) ? lbsData?.zones : {};
  const areaEntities = !isEmpty(lbsData?.areas) ? lbsData?.areas : {};
  const [selectedMenu, setSelectedMenu] = useState({ list: [] });

  const [anchorEl, setAnchorEl] = useState(undefined);

  const handleSelectMenu = (event, type) => {
    setSelectedMenu({ anchorEl: event.currentTarget, type: type });
  };

  const handleClose = () => {
    setAnchorEl(undefined);
    setSelectedMenu({});
  };

  const handleCloseMenu = () => {
    setSelectedMenu({});
  };

  const handleSplitLocation = ({ tasks, dependencies }) => {
    handleMultiUpdate({ tasks, dependencies });
  };

  const handleSaveLocation = (entityId) => {
    if (selectedMenu.type === 'location') {
      handleUpdate({ location_id: entityId, type: 'location' });
    } else if (selectedMenu.type === 'zone') {
      const updateParameters = { zone_id: entityId, type: 'zone' };
      if (entityId) {
        updateParameters['location_id'] = zoneEntities[entityId].location_id;
      }
      handleUpdate(updateParameters);
    } else {
      const updateParameters = { area_id: entityId, type: 'area' };
      if (entityId) {
        const zoneId = areaEntities[entityId].zone_id;
        const locationId = zoneEntities[zoneId].location_id;
        updateParameters['location_id'] = locationId;
        updateParameters['zone_id'] = zoneId;
      }
      handleUpdate(updateParameters);
    }
  };

  return (
    <>
      <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
        Locations
        {anchorEl ? <ArrowDropDown /> : <ArrowDropUp />}
      </Button>
      {anchorEl ? (
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
          onClose={handleClose}
        >
          <MenuItemComponent handleSelectMenu={handleSelectMenu} type="location" />
          <MenuItemComponent handleSelectMenu={handleSelectMenu} type="zone" />
          <MenuItemComponent handleSelectMenu={handleSelectMenu} type="area" />
        </Popover>
      ) : null}

      <SelectLocation
        anchorEl={selectedMenu.anchorEl}
        handleClose={handleCloseMenu}
        handleSave={handleSaveLocation}
        handleSaveSplit={handleSplitLocation}
        key={'location-menu'}
        projectId={projectId}
        taskIds={taskIds}
        type={selectedMenu.type}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    </>
  );
};

const MenuItemComponent = ({ handleSelectMenu, type }) => {
  return (
    <MenuItem name="Location" onClick={(event) => handleSelectMenu(event, type)}>
      <div className="flex" style={{ alignItems: 'center' }}>
        <Typography style={{ textTransform: 'capitalize' }}>{type}</Typography>
        <ArrowRight fill={'rgba(0, 0, 0, 0.54)'} style={{ marginLeft: 'auto', paddingLeft: 10 }} />
      </div>
    </MenuItem>
  );
};

export default TaskBulkEditAreasMenuItem;
