import { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ColorPalate from 'components/common/ColorPalate';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

export const ColorContainer = styled(Grid)(() => ({
  display: 'flex',
  cursor: 'pointer',
  paddingLeft: '5px',
  paddingRight: '2px',
  paddingTop: '2px',
  paddingBottom: '2px',
  border: '1px solid #b3b3b3',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
}));

export const ColorBox = styled(Box)(({ color }) => ({
  backgroundColor: color,
  height: '12px',
  width: '12px',
  padding: '0px',
  borderRadius: '2px',
  border: `1px solid ${color}`,
}));

/**
 * @param {String} color
 * @param {String} btnTxt
 * @param {Object} handleSaveColor
 * @returns
 */
const ColorDropdown = ({ handleSaveColor, color, btnTxt, disabled }) => {
  const [openColorPalate, setOpenColorPalate] = useState(false);

  const handleSave = (selectedColor) => {
    setOpenColorPalate(false);
    handleSaveColor(selectedColor);
  };

  return (
    <>
      <ColorContainer>
        <IconButton
          disabled={disabled}
          disableFocusRipple={true}
          disableRipple={true}
          size="medium"
          style={{ padding: 0 }}
          onClick={(event) => setOpenColorPalate(event.target)}
        >
          <ColorBox color={color ? color : '#2C2C2C'} p={2} />
          <ArrowDropDown style={{ fontSize: '20px' }} />
        </IconButton>
      </ColorContainer>

      {openColorPalate && (
        <ColorPalate
          anchorEl={openColorPalate}
          btnTxt={btnTxt}
          defaultColor={color}
          handleClose={() => setOpenColorPalate(false)}
          handleSave={handleSave}
          open={Boolean(openColorPalate)}
        />
      )}
    </>
  );
};

export default ColorDropdown;
