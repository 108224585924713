import * as React from 'react';

const SvgWorkspace = (props) => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 24 23"
    color={'#fff'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.25 2C0.25 0.89543 1.14543 0 2.25 0H21.25C22.3546 0 23.25 0.895431 23.25 2V5C23.25 6.10457 22.3546 7 21.25 7H2.25C1.14543 7 0.25 6.10457 0.25 5V2ZM4.25 10C4.25 8.89543 5.14543 8 6.25 8H21.25C22.3546 8 23.25 8.89543 23.25 10V13C23.25 14.1046 22.3546 15 21.25 15H6.25C5.14543 15 4.25 14.1046 4.25 13V10ZM10.25 16C9.14543 16 8.25 16.8954 8.25 18V21C8.25 22.1046 9.14543 23 10.25 23H21.25C22.3546 23 23.25 22.1046 23.25 21V18C23.25 16.8954 22.3546 16 21.25 16H10.25Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgWorkspace;
