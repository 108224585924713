import { api } from 'api';

export const analyticsAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getJWT: build.query({
      query: ({ workspaceId }) => ({
        url: `/analytics/jwt`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.token,
    }),

    getDashboards: build.query({
      query: ({ workspaceId }) => ({
        url: `/analytics/dashboards`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const { useGetJWTQuery, useLazyGetJWTQuery, useGetDashboardsQuery } = analyticsAPI;
