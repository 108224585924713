import { Box, useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const NavScrollBar = forwardRef((props, ref) => {
  const theme = useTheme();
  return (
    <Box
      ref={ref}
      {...props}
      overflow={'auto'}
      sx={{
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.primary.main,
          borderRadius: theme.shape.borderRadius,
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: theme.palette.primary.light,
        },
        '&::-webkit-scrollbar': {
          width: '5px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
      }}
    />
  );
});
