import isEmpty from 'lodash/isEmpty';
import parseISO from 'date-fns/parseISO';
import { useSelector } from 'react-redux';
import TaskFeedCard from './TaskFeedCard';
import { selectTaskFeedById } from 'slices/taskFeedSlice';
import { TranslateAuditContent, fieldsToIgnore } from './utils/taskFeedHelper';
import { useLayoutEffect } from 'react';

/**
 * Task feed row
 * @param {Number} id
 * @param {Object} onLoad
 * @returns
 */
export const TaskFeedRow = ({ id, onLoad }) => {
  const taskFeed = useSelector((state) => selectTaskFeedById(state, id));

  useLayoutEffect(() => {
    onLoad && onLoad();
  }, []);

  if (
    taskFeed?.new_values &&
    Object.keys(taskFeed?.new_values)?.length === 1 &&
    fieldsToIgnore.includes(Object.keys(taskFeed?.new_values)[0])
  ) {
    return;
  }

  const dateTime =
    parseISO(taskFeed.created_at).toLocaleDateString() +
    ' ' +
    parseISO(taskFeed.created_at).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });

  const content = (
    <TranslateAuditContent
      auditType={taskFeed?.auditable_type}
      event={taskFeed?.event}
      meta={{ stuckPoint: taskFeed?.stuck_point }}
      newValues={taskFeed?.new_values}
      oldValues={taskFeed?.old_values}
      task={{ ...taskFeed.task, project_id: taskFeed.project_id }}
    />
  );

  return (
    <TaskFeedCard
      content={content}
      dateTime={dateTime}
      userId={taskFeed?.user_id}
      onLoad={onLoad}
    />
  );
};

export default TaskFeedRow;
