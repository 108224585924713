import { axiosRequestHandler } from '../helpers/apiHelper';

/**
 * Save custom view API
 * @param {object} data
 * @param {object} data.id
 * @param {object} data.parameters
 */
export const updateCustomViewAPI = async (data) => {
  try {
    return await axiosRequestHandler(`custom_views/${data.id}`, 'put', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Duplicate custom views API
 * @param {string} id
 */
export const duplicateCustomViewAPI = async (id) => {
  try {
    return await axiosRequestHandler(`custom_views/${id}/duplicate`, 'post');
  } catch (error) {
    throw new Error({ error: true, message: 'Issue with API..!!' });
  }
};

/**
 * Delete custom views API
 * @param {string} id
 */
export const deleteCustomViewAPI = async (id) => {
  try {
    return await axiosRequestHandler(`custom_views/${id}`, 'delete');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Get project custom views API
 */
export const fetchProjectCustomViewsAPI = async () => {
  try {
    return await axiosRequestHandler(`custom_views?view=project_list`, 'get');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Save project custom view API
 * @param {object} data
 * @param {object} data.parameters
 */
export const saveProjectCustomViewAPI = async (data) => {
  try {
    return await axiosRequestHandler(`custom_views?view=project_list`, 'post', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Save schedule custom view API
 * @param {object} data
 * @param {string} data.view
 * @param {string} data.name
 * @param {object} data.parameters
 */
export const createCustomViewAPI = async (data) => {
  try {
    return await axiosRequestHandler(`custom_views`, 'post', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Issue with API..!!' });
  }
};

/**
 * Fetch custom view according to view type
 * @param {string} viewType
 */
export const fetchCustomViewsAPI = async (viewType) => {
  try {
    return await axiosRequestHandler(`custom_views?view=${viewType}`, 'get');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};
