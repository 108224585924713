import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import { useSyncDependenciesMutation } from 'features/dependencies/api/dependency.api';
import SelectDependency, {
  handleSyncDependency,
} from 'features/drawers/components/SelectDependency';
import { StyleCounterBadge, StyledAccordionSummary } from 'features/drawers/utils/drawerStyle';
import { useState } from 'react';

/**
 * Dependency Row
 * @param {String} taskId
 * @param {String} ganttId
 * @param {Object} setLoading
 * @returns
 */
const DependencyAccordion = ({ taskId, ganttId, projectId, disableEdit }) => {
  const gantt = ganttStore.getGantt(ganttId);

  const [syncDependencies] = useSyncDependenciesMutation();

  const tasks = gantt.getTaskBy('project_id', projectId);
  const task = gantt.getTask(taskId);
  const dependencies = task?.$target.map((linkId) => gantt.getLink(linkId));
  const [selectedDependencies, setSelectedDependencies] = useState(dependencies);

  const handleUpdate = async () => {
    const response = await syncDependencies({
      dependencies: selectedDependencies,
      taskId,
    }).unwrap();
    handleSyncDependency(gantt, response);
  };

  const handleCancel = () => {
    setSelectedDependencies(dependencies);
  };

  return (
    <Accordion
      style={{
        boxShadow:
          'rgba(0, 0, 0, 0.2) 1px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 1px 1px 1px 2px',
      }}
    >
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Dependency</Typography>
        <StyleCounterBadge count={selectedDependencies?.length}>
          {selectedDependencies?.length}
        </StyleCounterBadge>
      </StyledAccordionSummary>
      <AccordionDetails>
        <SelectDependency
          allTasks={tasks}
          disableEdit={disableEdit}
          gantt={gantt}
          selectedDependencies={selectedDependencies}
          setSelectedDependencies={setSelectedDependencies}
          taskId={taskId}
        />
      </AccordionDetails>
      {!disableEdit && (
        <AccordionActions>
          <Button size="small" onClick={handleCancel}>
            Cancel
          </Button>
          <Button color="primary" size="small" variant="contained" onClick={handleUpdate}>
            Update
          </Button>
        </AccordionActions>
      )}
    </Accordion>
  );
};

export default DependencyAccordion;
