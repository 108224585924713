import { addDays } from 'date-fns';
import ganttStore from './ganttStore';

export function drawFloatLayer(ganttId) {
  const gantt = ganttId ? ganttStore.getGantt(ganttId) : this;
  gantt.addTaskLayer({
    renderer: {
      render: (task) => {
        if (!gantt.config.show_slack) {
          return null;
        }
        if (!task) {
          return false;
        }

        if (task.placeholder) {
          return false;
        }

        const slack = task.free_float;
        if (!slack) {
          return false;
        }

        const state = gantt.getState().drag_mode;
        if (state == 'resize' || state == 'move') {
          return false;
        }

        const slackStart = new Date(task.end_date);
        // const slackEnd = gantt.calculateEndDate({
        //   start_date: slackStart,
        //   duration: slack,
        //   task,
        // });
        const slackEnd = addDays(slackStart, slack);
        const sizes = gantt.getTaskPosition(task, slackStart, slackEnd);

        const el = document.createElement('div');
        el.className = 'slack';
        el.style.left = sizes.left + 'px';
        el.style.top = sizes.top + 8 + 'px';
        el.style.width = sizes.width + 'px';
        el.style.height = sizes.height + 'px';

        return el;
      },
      getRectangle: function (task, view) {
        if (!task) {
          return false;
        }

        if (task.placeholder) {
          return false;
        }
        const slack = task.free_float;
        const slackStart = new Date(task.end_date);
        const slackEnd = addDays(slackStart, slack);
        return gantt.getTaskPosition(task, slackStart, slackEnd);
      },
    },
    filter: function (task) {
      return !!task.free_float;
    },
  });
}
