const {
  addDays,
  addWeeks,
  differenceInWeeks,
  eachDayOfInterval,
  endOfWeek,
  isBefore,
  isSameWeek,
  parse,
  format,
  isSameYear,
  startOfWeek,
  isWithinInterval,
  differenceInHours,
  differenceInDays,
  differenceInMinutes,
} = require('date-fns');

function parseDate(dateString) {
  if (dateString instanceof Date) {
    return dateString;
  } else if (typeof dateString === 'string') {
    if (dateString.endsWith('Z') || dateString.includes('T')) {
      return new Date(dateString);
    }
    return parse(dateString, 'yyyy-MM-dd', new Date());
  } else {
    return new Date(dateString);
  }
}

const defaultWorkdayCalendar = {
  sunday: '0',
  monday: '1',
  tuesday: '1',
  wednesday: '1',
  thursday: '1',
  friday: '1',
  saturday: '0',
};

const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

function differenceInWorkdays(
  startDate,
  endDate,
  calendarObj
) {
  const workdayCalendar = calendarObj?.workdayCalendar ? calendarObj?.workdayCalendar : defaultWorkdayCalendar;
  const holidays = calendarObj?.holidays?.length ? calendarObj?.holidays : []
  const start = addDays(new Date(Math.min(+startDate, +endDate)), 1);
  const end = addDays(new Date(Math.max(+startDate, +endDate)), 0);

  let workdayCount = 0;
  if (isBefore(end, start)) {
    return 0;
  } else if (isSameWeek(start, end)) {
    workdayCount = eachDayOfInterval({ start: start, end: end }).filter(
      (date) => workdayCalendar[weekdays[date.getDay()]] === '1'
    ).length;
  } else {
    const endOfStartWeek = endOfWeek(start);
    const startOfEndWeek = startOfWeek(end);
    const fullWeek = differenceInWeeks(startOfEndWeek, endOfStartWeek);
    let startWeekWorkdays = eachDayOfInterval({ start: start, end: endOfStartWeek }).filter(
      (date) => workdayCalendar[weekdays[date.getDay()]] === '1'
    ).length;
    let fullWeekWorkdays = fullWeek * weekdays.filter((day) => workdayCalendar[day] === '1').length;
    let endWeekWorkdays = eachDayOfInterval({ start: startOfEndWeek, end: end }).filter(
      (date) => workdayCalendar[weekdays[date.getDay()]] === '1'
    ).length;
    workdayCount = startWeekWorkdays + fullWeekWorkdays + endWeekWorkdays;
  }

  // Subtracting holidays from workdayCount
  let holidayCount = 0;
  for (const holiday of holidays) {
    const parsedDate = parseDate(holiday);
    if (workdayCalendar[weekdays[parsedDate.getDay()]] === '1' &&
      isWithinInterval(parsedDate, { start, end })) {
      holidayCount++;
    }
  }
  workdayCount -= holidayCount
  return +startDate <= +endDate ? workdayCount : (workdayCount + 1) * -1;
}

function addWorkdays(
  startDate,
  workdays,
  calendarObj
) {
  const workdayCalendar = calendarObj?.workdayCalendar ? calendarObj?.workdayCalendar : defaultWorkdayCalendar;
  const holidays = calendarObj?.holidays?.length ? calendarObj.holidays : []
  // return date;
  if (!(startDate instanceof Date) || isNaN(startDate)) {
    throw new Error('Invalid startDate');
  }

  // Calculate full weeks and remaining days
  const fullWeeks = Math.floor(
    workdays / weekdays.filter((day) => workdayCalendar[day] === '1').length
  );
  const remainingDays = workdays % weekdays.filter((day) => workdayCalendar[day] === '1').length;

  let date = new Date(startDate);

  // Add full weeks
  date = addWeeks(date, fullWeeks);

  // Add remaining days
  let daysAdded = 0;
  while (daysAdded < remainingDays) {
    date = addDays(date, 1);
    const dayOfWeek = weekdays[date.getDay()];

    if (workdayCalendar[dayOfWeek] === '1') {
      daysAdded += 1;
    }
  }

  // Adding holidays
  let holidayCount = 0;
  for (const holiday of holidays) {
    const parsedDate = parseDate(holiday);
    if (workdayCalendar[weekdays[parsedDate.getDay()]] === '1' &&
      isWithinInterval(parsedDate, { start: startDate, end: date })) {
      holidayCount++;
    }
  }
  if (holidayCount > 0) {
    date = addWorkdays(date, holidayCount, { workdayCalendar, holidays });
  }

  return date;
}

function formatTime(date) {
  const now = new Date();

  const minutesDifference = differenceInMinutes(now, date);
  const hoursDifference = differenceInHours(now, date);
  const daysDifference = differenceInDays(now, date);

  if (minutesDifference < 60) {
    return `${minutesDifference} minute${minutesDifference === 1 ? '' : 's'} ago`;
  } else if (hoursDifference < 24) {
    return `${hoursDifference} hour${hoursDifference === 1 ? '' : 's'} ago`;
  } else if (daysDifference <= 7) {
    return `${daysDifference} day${daysDifference === 1 ? '' : 's'} ago`;
  } else {
    const dateFormat = isSameYear(date, now) ? 'MMMM d h:mm a' : 'MMMM d, yyyy h:mm a';
    return format(date, dateFormat);
  }
}

module.exports = { parseDate, addWorkdays, differenceInWorkdays, formatTime };
