import moment from 'moment';

export const formatRecipePayload = (data) => {
  const payload = {
    id: data.id,
    name: data.text,
    start: data.start_date,
    end: moment(data.end_date, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD'),
    parent_id: data.parent <= 0 ? undefined : data.parent,
    duration: data.duration,
    percentage: data.percentage ? data.percentage / 100 : data.percentage,
    target: data.target,
    fixed_duration: data.fixed_duration === 'true' ? 1 : 0,
  };

  delete payload.type;
  delete payload['!nativeeditor_status'];
  return payload;
};

export const formatRecipeResponse = (task) => {
  const taskObj = {
    recipe_id: task.recipe_id,
    id: task.id,
    text: task.name,
    percentage: task.percentage ? (task.percentage * 100).toFixed(2) : task.percentage,
    parent: task.parent_id ? task.parent_id : -1,
    start_date: task.start ? task.start : '2000-01-01',
    end_date: task.end
      ? moment(task.end, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD')
      : undefined,
    duration: task.duration,
    target: task.target,
    fixed_duration: Boolean(task?.fixed_duration) ? 'true' : 'false',
  };
  return taskObj;
};
