export const squareUpTask = (taskId, gantt) => {
  const task = gantt.getTask(taskId);
  if (gantt?.ext?.undo) {
    gantt.ext.undo.saveState(task.id, 'task');
  }
  task.start_date = task.forecasted_start_date;
  task.scheduled_end_date = task.forecasted_end_date;
  gantt.updateTask(taskId);
  gantt.autoSchedule(taskId);
};
