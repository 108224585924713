import React from 'react';
import Grid from '@mui/material/Grid';
import { parseISO, format } from 'date-fns';
import EmailIcon from '@mui/icons-material/Email';
import ListItem from '@mui/material/ListItem';
import MessageIcon from '@mui/icons-material/Message';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SvgBlackhyveIcon from '@blackhyve/common/src/icons/BlackhyveIcon';
import { Done, Close, PriorityHigh, Email } from '@mui/icons-material';

const ChannelIcons = {
  email: <EmailIcon />,
  sms: <MessageIcon />,
  inbox: <SvgBlackhyveIcon color="black" style={{ height: 22, width: 22 }} />,
};

const ChannelText = {
  email: 'Sent on email',
  sms: 'Sent on sms',
  inbox: 'Sent on BlackHyve inbox',
};

const NotificationStatus = styled(Typography)(({ theme, type = 'disabled' }) => ({
  backgroundColor: type === 'success' ? '#cdf1ea' : type === 'failure' ? '#fbeced' : '#e0e0e0',
  padding: '2px 8px',
  borderRadius: '3px',
  fontSize: '12px',
  fontWeight: 'bold',
  height: 'max-height',
  marginLeft: '10px',
  textTransform: 'uppercase',
  color: type === 'success' ? '#6bc0ac' : type === 'failure' ? '#d77474' : '#414141',
}));

const NotificationChannelRow = ({ channel, notificationDetails }) => {
  const channelIcon = ChannelIcons[channel] || null;
  const channelText = ChannelText[channel] || 'Unknown Channel';
  let notificationStatus = 'disabled';
  let notificationText = 'No Receipt';
  let readOn = '';

  const readAtKey = `${channel}_read_at`;

  if (notificationDetails.hasOwnProperty(readAtKey)) {
    if (notificationDetails[readAtKey]) {
      notificationStatus = 'success';
      notificationText = 'Read';
      readOn = `${format(parseISO(notificationDetails[readAtKey]), 'MMMM dd, yyyy hh:mm a')}`;
    } else {
      notificationStatus = 'failure';
      notificationText = 'Unread';
    }
  }

  return (
    <ListItem>
      <ListItemIcon>{channelIcon}</ListItemIcon>
      <ListItemText
        primary={
          <Grid item container>
            <Grid item xs={3}>
              <Typography style={{ marginRight: '5px' }}>{channelText}</Typography>
              <Typography style={{ fontSize: '12px' }}>{readOn}</Typography>
            </Grid>
            <Grid item style={{ alignItems: 'center', display: 'flex' }}>
              <NotificationStatus type={notificationStatus}>{notificationText}</NotificationStatus>
            </Grid>
          </Grid>
        }
      />
    </ListItem>
  );
};

export default NotificationChannelRow;
