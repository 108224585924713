import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import debounce from 'lodash/debounce';
import JobwalkLbsMenu from './JobwalkLbsMenu';
import { isMobile } from 'react-device-detect';
import JobwalkSortMenu from './JobwalkSortMenu';
import SearchBox from 'components/search/SearchBox';
import { ActionBar } from 'components/navigation/ActionBar';
import UserFilterMenu from './UserFilterMenu';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';


/**
 * Jobwalk Action Bar
 * @param {object} setFilters
 * @param {object} filters
 * @returns
 */
const JobwalkActionBar = ({ setFilters, filters, projectId }) => {
  const handleSearch = debounce((event) => {
    const searchQuery = event.target.value.trim();
    setFilters((filters) => ({ ...filters, search: searchQuery || undefined, page: 0 }));
  }, 500);

  const handleSelectUsers = (userId) => {
    setFilters((filters) => ({
      ...filters,
      responsible: filters.responsible.includes(userId)
        ? filters.responsible.filter((id) => id != userId)
        : [...filters.responsible, userId],
      page: 0,
    }));
  };

  const handleAllJobwalks = () => {
    setFilters((prevFilters) => ({ ...prevFilters, responsible: [] }));
  };

  return (
    <ActionBar pl={0} pr={0} sx={{ py: 1, px: 1, position: 'sticky', top: 0, zIndex: 1 }}>
      <Grid container alignItems="center" flexDirection={'row-reverse'}>
        <Grid container item gap={1} justifyContent="flex-end" xs={8}>
          <Grid item>
            <Chip
              icon={<AllInclusiveIcon fontSize="small" />}
              label={'All Jobwalks'}
              variant={filters?.responsible?.length ? 'outlined' : 'filled'}
              onClick={handleAllJobwalks}
            />
          </Grid>
          <Grid item>
            <UserFilterMenu
              handleSelect={handleSelectUsers}
              label="Jobwalks"
              selectedIds={filters?.responsible}
            />
          </Grid>
          {isMobile &&
            <Grid item>
              <JobwalkLbsMenu filters={filters} projectId={projectId} setFilters={setFilters} />
            </Grid>}
          <Grid item>
            <JobwalkSortMenu filters={filters} setFilters={setFilters} />
          </Grid>
        </Grid>
        <Grid container item xs alignItems={'center'}>
          <Grid item>
            <SearchBox
              bgcolor="grey.200"
              borderRadius={1}
              fullWidth={false}
              placeholder="Search By Task Name"
              onChange={handleSearch}
            />
          </Grid>
        </Grid>
      </Grid>
    </ActionBar>
  );
};

export default JobwalkActionBar;
