import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { ContentLayout } from 'components/layouts';
import { SecondaryNav } from 'components/navigation/SecondaryNav';
import ProjectNotificationActionBar from './ProjectNotificationActionBar';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import NotificationLogList from 'features/notifications/components/NotificationLog/NotificationLogList';

/**
 * Project notification log
 * @returns
 */
const ProjectNotificationLog = () => {
  const navigate = useNavigate();
  const { workspaceId, projectId } = useParams();
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState();

  const handleRedirectToNotificationLog = (event) => {
    event.preventDefault();
    navigate(`/workspace/${workspaceId}/projects/${projectId}/activity-feeds`);
  };

  useEffect(() => {
    const queryParams = {};
    if (!searchParams.size) {
      return;
    }
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      queryParams[param] = value;
    }
    setFilters(queryParams);
  }, []);

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      project: projectId,
    }));
  }, [projectId]);

  return (
    <ContentLayout
      header={
        <>
          <SecondaryNav>
            <ProjectNotificationActionBar filters={filters} setFilters={setFilters} />
          </SecondaryNav>
        </>
      }
    >
      <>
        <Button
          color="primary"
          style={{ width: 'fit-content', color: '#4B70E7', padding: '10px 24px' }}
          onClick={handleRedirectToNotificationLog}
        >
          View as activity log
        </Button>

        <NotificationLogList filters={filters} />
      </>
    </ContentLayout>
  );
};

export default ProjectNotificationLog;
