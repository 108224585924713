import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import { scaleConfig } from 'components/projectOverview/gantt/ganttConfig/zoom';

export const getProjectGanttZoomConfig = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  const scales = scaleConfig(ganttId);
  const zoomConfig = {
    levels: [
      {
        name: 'year',
        ...scales['Years'],
        min_column_width: 125,
        max_column_width: 125,
      },
      {
        name: 'quarter',
        ...scales['Quarters'],
        min_column_width: 100,
        max_column_width: 100,
      },
      {
        name: 'month',
        ...scales['Months'],
        min_column_width: 75,
        max_column_width: 75,
      },
    ],
    activeLevelIndex: 2,
    useKey: 'ctrlKey',
    trigger: 'wheel',
    element: function () {
      return gantt.$root.querySelector('.gantt_task');
    },
  };
  return zoomConfig
}