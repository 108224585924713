import { StyledDialog } from '@blackhyve/common';
import { Button, Typography } from '@mui/material';

const ContractLineDeleteDialog = ({ open, handleClose, handleDelete, id, loading }) => {
  const onClose = (event, reason) => {
    if (reason !== 'escapeKeyDown' && reason !== 'backdropClick') {
      handleClose();
    }
  };

  return (
    <StyledDialog
      dialogOverrides={{ maxWidth: 'xs' }}
      handleClose={onClose}
      open={open}
      title="Confirm Delete"
      actions={
        <>
          <Button disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant={'contained'}
            onClick={() => {
              handleDelete(id);
              handleClose();
            }}
          >
            {loading ? 'Deleting...' : 'Yes, delete it'}
          </Button>
        </>
      }
    >
      <>
        <Typography fontWeight={'bold'} textAlign={'center'} variant={'h6'}>
          Are you sure you want to delete this item?
        </Typography>
        <Typography textAlign={'center'}>This action cannot be undone.</Typography>
      </>
    </StyledDialog>
  );
};

export default ContractLineDeleteDialog;
