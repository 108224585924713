import { useSnackbar } from 'notistack';
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Grid';
import { Controller } from "react-hook-form";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { createSupportTicket } from "../store/authSlice";
import OutlinedInput from '@mui/material/OutlinedInput';
import { StyledLink, StyledTextField, StyledLoginButton, StyledArrowIcon, StyledButtonLoading } from '../assets/loginStyles';

const initialState = { return_email: "", body: "", subject: "Login issue", loading: false }

/**
 * Contact support form
 */
const HelpForm = () => {

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, watch, setValue, reset } = useForm({
    defaultValues: initialState
  });

  const isLoading = watch('loading')

  const handleSendFeedback = (data) => {
    setValue('loading', true)
    dispatch(createSupportTicket(data))
      .then(() => {
        setValue('loading', false);
        reset(initialState);
        enqueueSnackbar('Ticket Received. We\'re on it! Expect a response soon. Thank you.', { variant: 'success' })
      }).catch((error) => {
        setValue('loading', false);
      })
  }



  return (
    <Grid container item style={{ marginTop: 25, gap: 20 }} xs={12}>
      <form onSubmit={handleSubmit(handleSendFeedback)}>
        <Grid container item style={{ gap: 20 }}>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: 600, marginTop: '5px', fontSize: '18px' }}>
              What can we help with?
            </Typography>
            <Typography style={{ fontSize: '14.5px' }}>
              Give us your email and brief description of the problem you're experiencing.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="return_email"
              render={({ field, fieldState: { error } }) =>
                <StyledTextField
                  {...field}
                  autoFocus
                  fullWidth
                  error={error}
                  helperText={error?.message}
                  placeholder="Your email address"
                  size="small"
                  variant="outlined"
                />
              }
              rules={{
                required: 'Email field is required',
                validate: {
                  matchPattern: (v) =>
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) ||
                    "Email address must be a valid address",
                },
              }}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Controller
              control={control}
              name="subject"
              render={({ field, fieldState: { error } }) =>
                <Select
                  {...field}
                  fullWidth
                  disableUnderline={true}
                  error={error}
                  size="small"
                  helperText={error?.message}
                  input={<OutlinedInput margin="dense" />}
                >
                  <MenuItem value={'Login issue'}>Login Issue</MenuItem>
                  <MenuItem value={'Login issue'}>Other Issue</MenuItem>
                  <MenuItem value={'Login issue'}>Forgot Password</MenuItem>
                </Select>
              }
              rules={{
                required: 'Email field is required',
                validate: {
                  matchPattern: (v) =>
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) ||
                    "Email address must be a valid address",
                },
              }}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Controller
              control={control}
              name="body"
              render={({ field, fieldState: { error } }) =>
                <StyledTextField
                  {...field}
                  fullWidth
                  multiline
                  error={error}
                  helperText={error?.message}
                  maxRows={10}
                  minRows={4}
                  placeholder="Description of your problem"
                  size="small"
                  style={{ fontFamily: 'inherit' }}
                  variant="outlined"
                />
              }
              rules={{
                required: 'Description field is required',
                validate: {
                  maxLength: (value) => value.length < 3000 || 'Description can not be more than 3000 characters'
                },
              }}
            />
          </Grid>
          <Grid item style={{ position: 'relative' }} xs={12}>
            <StyledLoginButton fullWidth disabled={isLoading} type="submit" variant="contained">
              SEND REQUEST
            </StyledLoginButton>
            {isLoading && <StyledButtonLoading size={24} />}
          </Grid>
          <Grid item style={{ justifyContent: 'center', display: 'flex' }} xs={12}>
            <StyledLink to="/login">
              <StyledArrowIcon /> Go back to sign in screen
            </StyledLink>
          </Grid>
        </Grid>
      </form >
    </Grid >
  );

}

export default HelpForm