import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { CostTypeOptions } from '../models/breakdown';
import { formatDecimals, formatMoney } from './NumericControls';
import { MenuOptions } from './BudgetsMenuOptions';

const ContractLinesList = ({
  rows,
  onRowClicked = () => {},
  withOptions = true,
  onDelete = () => {},
  onEdit = () => {},
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>ID</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Division</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Cost Code</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Cost Type</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Description</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Trade</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Amount</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Labor</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Material</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Work Retention</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
              Invoiced to Date
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
              Balance to Finish
            </TableCell>
            {withOptions && <TableCell>&nbsp;</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((item) => (
            <TableRow
              hover
              key={item.id}
              sx={{ cursor: 'pointer' }}
              onClick={() => onRowClicked(item)}
            >
              <TableCell sx={{ whiteSpace: 'nowrap' }}>#{item.id}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.budget_line.division}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.cost_code}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {CostTypeOptions.find((c) => c.id === item.cost_type).name}
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.description}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.budget_line.trade.name}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>${formatMoney(item.amount)}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                ${formatMoney(item.labor_amount)} ({formatDecimals(item.labor_percent)}%)
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                ${formatMoney(item.material_amount)} ({formatDecimals(item.material_percent)}%)
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {formatDecimals(item.work_retention_percent || 0)}%
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                ${formatMoney(item.invoiced_amount)} ({formatDecimals(item.invoiced_percent)}%)
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                ${formatMoney(item.balance_to_finish)}
              </TableCell>
              {withOptions && (
                <TableCell onClick={(event) => event.stopPropagation()}>
                  {(Object.keys(item).includes('can_edit') && !item.can_edit) || (
                    <MenuOptions
                      budgetLine={item}
                      onDelete={() => onDelete(item)}
                      onEdit={() => onEdit(item)}
                    />
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
          {rows.length === 0 && (
            <TableRow key="empty">
              <TableCell colSpan={withOptions ? 13 : 12} sx={{ textAlign: 'center' }}>
                No Data
              </TableCell>
            </TableRow>
          )}
          {rows.length > 0 && (
            <TableRow key="summary" sx={{ backgroundColor: '#18181b' }}>
              <TableCell
                colSpan={6}
                sx={{ whiteSpace: 'nowrap', color: 'white', textAlign: 'right' }}
              >
                Totals
              </TableCell>
              <TableCell sx={{ color: 'white' }}>
                ${formatMoney(rows.reduce((acc, item) => acc + item.amount, 0))}
              </TableCell>
              <TableCell sx={{ color: 'white' }}>
                ${formatMoney(rows.reduce((acc, item) => acc + item.labor_amount, 0))}
              </TableCell>
              <TableCell sx={{ color: 'white' }}>
                ${formatMoney(rows.reduce((acc, item) => acc + item.material_amount, 0))}
              </TableCell>
              <TableCell sx={{ color: 'white' }}>─</TableCell>
              <TableCell sx={{ color: 'white' }}>
                ${formatMoney(rows.reduce((acc, item) => acc + item.invoiced_amount, 0))}
              </TableCell>
              <TableCell sx={{ color: 'white' }}>
                ${formatMoney(rows.reduce((acc, item) => acc + item.balance_to_finish, 0))}
              </TableCell>
              {withOptions && <TableCell>&nbsp;</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContractLinesList;
