import { StyledDialog } from '@blackhyve/common';
import { Box, Button, CircularProgress, TextField } from '@mui/material';
import { Alert } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  useAddUserMutation,
  useCreateUserMutation,
  useLazyGetUserByEmailQuery,
} from '../api/users.api';
import { ContactForm } from './ContactForm';

const initialFormState = {
  first_name: '',
  last_name: '',
  title: '',
  phone: '',
  email: '',
  role: 'member',
  offices: [],
  trade_ids: [],
  notify: true,
};

const InviteContactDialog = ({ companyId, open, handleClose, workspaceOwner }) => {
  const [createUser, { isLoading: isCreating }] = useCreateUserMutation();
  const [addUser, { isLoading: isAdding }] = useAddUserMutation();
  const [getUserByEmail, { data: user, isLoading: isLoadingUserByEmail, isError: isEmailInvalid }] =
    useLazyGetUserByEmailQuery();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(undefined);
  const [step, setStep] = useState(0);

  const form = useForm({
    defaultValues: initialFormState,
  });

  const { handleSubmit, reset, setError, setValue } = form;

  const onSubmit = (data) => {
    data.office_ids = data.offices.map((office) => office.id);
    if (isEmailInvalid) {
      createUser({ companyId, user: data })
        .unwrap()
        .then((response) => onClose())
        .catch((response) => {
          Object.entries(response.data.errors).forEach(([field, error]) => {
            setError(field, {
              type: 'manual',
              message: error,
            });
          });
        });
    } else {
      addUser({ companyId, userId: user.id, user: data })
        .unwrap()
        .then((response) => onClose())
        .catch((response) => {
          Object.entries(response.data.errors).forEach(([field, error]) => {
            setError(field, {
              type: 'manual',
              message: error,
            });
          });
        });
    }
  };

  const onClose = () => {
    setEmail('');
    reset(initialFormState);
    setStep(0);
    handleClose();
  };

  const checkEmail = () => {
    const emailRegex = /^\S+@\S+$/i;
    if (emailRegex.test(email)) {
      getUserByEmail({ email })
        .unwrap()
        .then((response) => {
          reset(Object.assign({ ...initialFormState }, response));
          setStep(step + 1);
        })
        .catch((response) => {
          console.log(response);
          if (response.status === 409) {
            setEmailError('Email is already in use');
          } else {
            setStep(step + 1);
            setEmailError(undefined);
            setValue('email', email);
          }
        });
    } else {
      setEmailError('Please enter a valid email');
    }
  };

  const handleBack = () => {
    reset(initialFormState);
    setStep(step - 1);
  };

  return (
    <StyledDialog
      open={open}
      title={'Add User'}
      actions={
        <>
          {step === 0 && (
            <>
              <Button disabled={isCreating} onClick={onClose}>
                Close
              </Button>
              <Button disabled={isCreating} variant="contained" onClick={checkEmail}>
                Next
              </Button>
            </>
          )}
          {step === 1 && (
            <>
              <Button disabled={isCreating} onClick={handleBack}>
                Back
              </Button>
              <Button disabled={isCreating} variant="contained" onClick={handleSubmit(onSubmit)}>
                Add
              </Button>
            </>
          )}
        </>
      }
      handleClose={(_, reason) => {
        if (reason && reason === 'backdropClick') return;
        onClose();
      }}
    >
      {isCreating || isLoadingUserByEmail || isAdding ? (
        <Box
          component={CircularProgress}
          left={'50%'}
          position="absolute"
          style={{ translate: '-50%' }}
          top="50%"
        />
      ) : null}
      {step === 0 && (
        <Box columnGap={'10px'}>
          <TextField
            fullWidth
            error={Boolean(emailError)}
            helperText={emailError}
            placeholder="Enter email"
            value={email}
            variant="outlined"
            onChange={(event) => setEmail(event.target.value)}
            onKeyDown={(event) => event.key === 'Enter' && checkEmail()}
          />
        </Box>
      )}
      {step === 1 && (
        <>
          {user && !isEmailInvalid && (
            <Box pb={2}>
              <Alert severity="success">User Found With Matching Email </Alert>
            </Box>
          )}
          <ContactForm
            companyId={companyId}
            disabled={isCreating}
            enableMessage={true}
            form={form}
            isNew={isEmailInvalid}
            workspaceOwner={workspaceOwner}
            onSubmit={onSubmit}
          />
        </>
      )}
    </StyledDialog>
  );
};

export default InviteContactDialog;
