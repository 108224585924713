import { api, providesList } from 'api';

const areaAPI = api.enhanceEndpoints({ addTagTypes: ['Area', 'Zone-Areas'] }).injectEndpoints({
  endpoints: (build) => ({
    createArea: build.mutation({
      query: (data) => {
        return {
          url: `/zones/${data.zoneId}/areas`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: (result, error, params) =>
        result
          ? [
            { type: 'Area', id: 'LIST' },
            { type: 'Zone-Areas', id: params.zoneId },
            { type: `Zone`, id: params.zoneId },
            { type: `Location`, id: params.locationId },
          ]
          : [],
    }),

    getAreas: build.query({
      query: ({ zoneId }) => `/zones/${zoneId}/areas`,
      providesTags: (result, error, args) => {
        return result
          ? [
            { type: 'Location', id: args.locationId },
            { type: `Zone`, id: args.zoneId },
            { type: `Zone-Areas`, id: args.zoneId },
            ...providesList(result, `Area`),
          ]
          : [];
      },
      transformResponse: (response) => response.data,
    }),

    getArea: build.query({
      query: ({ zoneId, areaId }) => `/zones/${zoneId}/areas/${areaId}`,
      providesTags: (result, error, args) => {
        return result
          ? [
            { type: 'Location', id: args.locationId },
            { type: `Zone`, id: args.zoneId },
            { type: `Zone-Areas`, id: args.zoneId },
            { type: 'Area', id: args.areaId },
          ]
          : [];
      },
      transformResponse: (response) => response.data,
    }),

    syncAreas: build.mutation({
      query: ({ zoneId, areas }) => ({
        url: `/zones/${zoneId}/areas`,
        method: 'PATCH',
        body: { areas },
      }),
      invalidatesTags: (result, error, args) => {
        return result
          ? [
            { type: `Zone`, id: args.zoneId },
            { type: `Zone-Areas`, id: args.zoneId },
            { type: `Location`, id: args.locationId },
            ...args.areas.map((area) => ({ type: 'Area', id: area.id })),
          ]
          : [];
      },
      async onQueryStarted(
        { areaId, zoneId, locationId, projectId, areas },
        { dispatch, queryFulfilled }
      ) {
        const updateGetAreas = dispatch(
          api.util.updateQueryData('getAreas', { zoneId: parseInt(zoneId) }, () => areas)
        );
        const updateGetLbs = dispatch(
          api.util.updateQueryData('getLbs', { projectId }, (locations) => {
            locations
              .find((location) => location.id == locationId)
              .zones.find((zone) => zone.id == zoneId).areas = areas;
          })
        );
        queryFulfilled.catch(() => {
          updateGetAreas.undo();
          updateGetLbs.undo();
        });
      },
    }),

    updateArea: build.mutation({
      query: ({ areaId, area }) => ({
        url: `/areas/${areaId}`,
        method: 'PUT',
        body: area,
      }),
      invalidatesTags: (result, error, args) =>
        result
          ? [
            { type: 'Area', id: args.areaId },
            { type: `Zone`, id: args.zoneId },
            { type: `Location`, id: args.locationId },
          ]
          : [],
      async onQueryStarted(
        { areaId, zoneId, locationId, projectId, area },
        { dispatch, queryFulfilled }
      ) {
        const updateGetAreas = dispatch(
          api.util.updateQueryData('getAreas', { zoneId: parseInt(zoneId) }, (areas) => {
            const index = areas.findIndex((area) => area.id === areaId);
            if (index !== -1) {
              areas.splice(index, 1, Object.assign(areas[index], area));
            }
          })
        );
        const updateGetLbs = dispatch(
          api.util.updateQueryData('getLbs', { projectId }, (locations) => {
            const areas = locations
              .find((location) => location.id == locationId)
              .zones.find((zone) => zone.id == zoneId).areas;
            const index = areas.findIndex((area) => area.id === areaId);
            if (index !== -1) {
              areas.splice(index, 1, Object.assign(areas[index], area));
            }
          })
        );
        queryFulfilled.catch(() => {
          updateGetAreas.undo();
          updateGetLbs.undo();
        });
      },
    }),

    duplicateArea: build.mutation({
      query: ({ areaId }) => ({
        url: `/areas/${areaId}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, params) =>
        result
          ? [
            { type: 'Area', id: 'LIST' },
            { type: 'Zone-Areas', id: params.zoneId },
            { type: 'Zone', id: params.zoneId },
            { type: `Location`, id: params.locationId },
          ]
          : [],
    }),

    deleteArea: build.mutation({
      query: ({ areaId }) => ({
        url: `/areas/${areaId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, params) => {
        return result
          ? [
            { type: `Area`, id: params.areaId },
            { type: `Zone`, id: params.zoneId },
            { type: `Zone-Areas`, id: params.zoneId },
            { type: `Location`, id: params.locationId },
          ]
          : [];
      },
      async onQueryStarted(
        { areaId, zoneId, locationId, projectId },
        { dispatch, queryFulfilled }
      ) {
        const updateGetAreas = dispatch(
          api.util.updateQueryData('getAreas', { zoneId: parseInt(zoneId) }, (areas) => {
            const index = areas.findIndex((area) => area.id === areaId);
            if (index !== -1) {
              areas.splice(index, 1);
            }
          })
        );
        const updateGetLbs = dispatch(
          api.util.updateQueryData('getLbs', { projectId }, (locations) => {
            const areas = locations
              .find((location) => location.id == locationId)
              .zones.find((zone) => zone.id == zoneId).areas;
            const index = areas.findIndex((area) => area.id === areaId);
            if (index !== -1) {
              areas.splice(index, 1);
            }
          })
        );
        queryFulfilled.catch(() => {
          updateGetAreas.undo();
          updateGetLbs.undo();
        });
      },
    }),

    reorderArea: build.mutation({
      query: (body) => ({
        url: `zones/${body.zoneId}/areas/reorder`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, params) =>
        result
          ? [{ type: 'Zone-Areas', id: params.zoneId }, ...providesList(params.areas, 'Area')]
          : [],
      async onQueryStarted(
        { sourceIndex, destinationIndex, zoneId, locationId, projectId },
        { dispatch, queryFulfilled }
      ) {
        const updateGetAreas = dispatch(
          api.util.updateQueryData(
            'getAreas',
            { zoneId: isNaN(parseInt(zoneId)) ? zoneId : parseInt(zoneId) },
            (areas) => {
              const area = areas.splice(sourceIndex, 1);
              areas.splice(destinationIndex, 0, ...area);
            }
          )
        );
        const updateGetLbs = dispatch(
          api.util.updateQueryData('getLbs', { projectId }, (locations) => {
            const areas = locations
              .find((location) => location.id == locationId)
              .zones.find((zone) => zone.id == zoneId).areas;
            const area = areas.splice(sourceIndex, 1);
            areas.splice(destinationIndex, 0, ...area);
          })
        );
        queryFulfilled.catch(() => {
          updateGetAreas.undo();
          updateGetLbs.undo();
        });
      },
    }),
  }),
});

export const {
  useCreateAreaMutation,
  useGetAreasQuery,
  useDeleteAreaMutation,
  useUpdateAreaMutation,
  useDuplicateAreaMutation,
  useSyncAreasMutation,
  useGetAreaQuery,
  useReorderAreaMutation,
} = areaAPI;