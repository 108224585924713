import { Button, Grid, Link } from '@mui/material';
import { bytesForHumans } from '../models/files';
import { Delete, Download } from '@mui/icons-material';

const FileList = ({ files, onDelete, linkTo = null }) => (
  <Grid item sx={{ border: '1px solid #e3e3e3', borderRadius: '5px' }}>
    {files.map((file) => {
      return (
        <Grid item key={file.id} p={1} sx={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              flex: 1,
            }}
          >
            {file.file_name}
          </div>
          <div>{bytesForHumans(file.size)}</div>
          <div style={{ paddingLeft: 1, display: 'flex', alignItems: 'center' }}>
            <Link
              download
              href={linkTo ? linkTo(file) : file.original_url}
              sx={{ p: '6px 8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              target="_blank"
              variant="button"
            >
              <Download />
            </Link>
            <Button onClick={() => onDelete(file)}>
              <Delete />
            </Button>
          </div>
        </Grid>
      );
    })}
  </Grid>
);
export default FileList;
