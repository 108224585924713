import Backdrop from '@mui/material/Backdrop';
import { ContentLayout } from 'components/layouts/ContentLayout';
import { SecondaryNav } from 'components/navigation/SecondaryNav';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { RecipeGantt } from '../components/RecipeGantt';

import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';
import { useGetRecipeQuery } from '../api/recipe.api';

export const Recipe = () => {
  const { recipeId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { data: recipe, isLoading, isError, error } = useGetRecipeQuery({ recipeId });

  useEffect(() => {
    if (isError) {
      console.log(error);
      if (error.status === 404) {
        enqueueSnackbar('Recipe not found', { variant: 'error' });
      } else {
        enqueueSnackbar('An error occurred while loading', { variant: 'error' });
      }
      navigate('/', { replace: true });
    }
  }, [isError, error, enqueueSnackbar, navigate]);

  return (
    <ContentLayout header={<SecondaryNav title={recipe?.name} />}>
      {isLoading ? (
        <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
          <CircularProgress className="loading" />
        </Backdrop>
      ) : (
        <RecipeGantt recipeId={recipeId}></RecipeGantt>
      )}
    </ContentLayout>
  );
};
