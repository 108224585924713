import { addDays, eachDayOfInterval } from 'date-fns';
import ganttStore from '../../../components/projectOverview/gantt/ganttConfig/ganttStore';
import { hasChild } from '@blackhyve/utilities/tasks';

export function addWeekdaysLayer(ganttId) {
  const gantt = ganttId ? ganttStore.getGantt(ganttId) : this;
  gantt.addTaskLayer({
    renderer: {
      render: (task, view, config, pos) => {
        if (task.type == 'placeholder' || hasChild(task.id, gantt) || task.type === 'project_bar') {
          return false;
        }
        const scaleUnit = gantt.getState().scale_unit;
        if (
          task.start_date &&
          task.scheduled_end_date &&
          +task.start_date <= +addDays(task.scheduled_end_date, -1) &&
          scaleUnit == 'day'
        ) {
          const sizes = gantt.getTaskPosition(task, task.start_date, task.scheduled_end_date);
          const dayWidth = gantt.getTaskPosition(
            task,
            task.start_date,
            gantt.date.add(task.start_date, 1, 'day')
          ).width;
          const el = document.createElement('div');
          el.setAttribute('data-testid', `${task.id}-workdays-div`);
          eachDayOfInterval({
            start: task.start_date,
            end: addDays(task.scheduled_end_date, -1),
          }).forEach((day, index) => {
            if (gantt.isWorkTime({ date: day, task: task })) {
              const highLighterPosition = (index + 1) * dayWidth - dayWidth / 2 - 13 / 2;
              const leftPosition = sizes.left + highLighterPosition;
              const workDayElement = createBox(
                {
                  height: sizes.height - 3 + 'px',
                  top: sizes.top + (config.row_height - config.bar_height) / 2 + 1,
                  left: leftPosition,
                  width: sizes.width - 5 + 'px',
                },
                'workday-circle'
              );
              el.appendChild(workDayElement);
            }
          });
          return el;
        }
        return false;
      },
      getRectangle: function (task, view) {
        return gantt.getTaskPosition(
          task,
          task.start_date,
          task.scheduled_end_date > task.end_date ? task.scheduled_end_date : task.end_date
        );
      },
    },
  });
}

function createBox(sizes, class_name) {
  var box = document.createElement('div');
  box.style.cssText = [
    'height:' + sizes.height + 'px',
    'line-height:' + sizes.height + 'px',
    'width:' + sizes.width + 'px',
    'top:' + sizes.top + 'px',
    'left:' + sizes.left + 'px',
    'position:absolute',
  ].join(';');
  box.className = class_name;
  return box;
}
