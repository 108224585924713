export function taskGanttRenderer(item, defaultRender) {
  const sizes = this.getTaskPosition(item, item.start_date, item.scheduled_end_date);

  let defaultDom = defaultRender(item);
  if (defaultDom) {
    defaultDom.style.backgroundImage = item.$color;
    defaultDom.style.backgroundColor = item.$color;
    defaultDom.style.left = sizes.left + 3 + 'px';
    // defaultDom.style.width = sizes.width - 6 + 'px';
    defaultDom.style.width = sizes.width > 8 ? sizes.width - 6 + 'px' : sizes.width + 'px';
    if (item.scheduled_end_date < item.forecasted_end_date) {
      const targetSizes = this.getTaskPosition(
        item,
        item.scheduled_end_date,
        item.forecasted_end_date
      );
      let textDom = defaultDom.querySelector('.gantt_side_content.gantt_right');
      if (textDom) {
        textDom.style.left = sizes.width + targetSizes.width + 'px';
      }
    }
  }

  return defaultDom;
}
