import { ConfirmDeleteDialog } from '@blackhyve/common';
import { theme } from '@blackhyve/common/src/AppTheme';
import { Delete, Edit, FileCopy, MoreVert } from '@mui/icons-material';
import { Box, ListItem, ListItemIcon, ListItemText, MenuList, ThemeProvider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Popover } from 'components/Popover';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteRecipeMutation, useDuplicateRecipeMutation } from '../api/recipe.api';
import RecipeDialog from './RecipeDialog';

export const RecipeMoreOptions = ({ recipe }) => {
  const navigate = useNavigate();
  const [deleteRecipe] = useDeleteRecipeMutation();
  const [duplicateRecipe] = useDuplicateRecipeMutation();
  const { workspaceId, recipeId: currentRecipeId } = useParams();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

  const handleDelete = () => {
    if (currentRecipeId == recipe.id) {
      navigate(`/workspace/${workspaceId}/projects`);
    }
    deleteRecipe({ recipeId: recipe.id });
  };

  const handleDuplicate = () => {
    duplicateRecipe({ recipeId: recipe.id });
  };

  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        trigger={
          <IconButton edge="end" size={'small'}>
            <MoreVert />
          </IconButton>
        }
      >
        {({ handleClose }) => {
          return (
            <MenuList>
              <ListItem
                button
                onClick={() => {
                  setEditDialogOpen(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={'Edit'} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  handleDuplicate();
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <FileCopy />
                </ListItemIcon>
                <ListItemText primary={'Duplicate'} />
              </ListItem>
              <Box
                button
                color={'error.main'}
                component={ListItem}
                onClick={() => {
                  setConfirmDeleteDialogOpen(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <Delete color={'error'} />
                </ListItemIcon>
                <ListItemText color={'error'} primary={'Delete'} />
              </Box>
            </MenuList>
          );
        }}
      </Popover>
      <ThemeProvider theme={theme}>
        <ConfirmDeleteDialog
          handleClose={() => setConfirmDeleteDialogOpen(false)}
          handleDelete={handleDelete}
          item={'recipe'}
          open={confirmDeleteDialogOpen}
        />
        <RecipeDialog
          handleClose={() => setEditDialogOpen(false)}
          open={editDialogOpen}
          recipe={recipe}
        />
      </ThemeProvider>
    </>
  );
};

export default RecipeMoreOptions;
