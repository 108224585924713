// import { Box } from '@material-ui/core';
import Box from '@mui/system/Box';
/**
 * A layout component that displays content with a header.
 * @param {React.ReactNode} header - The content to be displayed as the header.
 * @param {object} headerProps - Additional props to be applied to the header Box component.
 * @param {object} props - Additional props to be applied to the content Box component.
 * @returns {JSX.Element} The rendered ContentLayout component.
 */
export const ContentLayout = ({ header, headerProps, containerProps, ...props }) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      height={'100%'}
      width={'100%'}
      {...containerProps}
    >
      <Box {...headerProps}>{header}</Box>
      <Box flexGrow={1} overflow={'auto'} {...props} position={'relative'} />
    </Box>
  );
};
