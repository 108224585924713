import { ConfirmDeleteDialog, OptionsMenu } from '@blackhyve/common';
import { Box, List, ListItemButton } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Archive, Delete, Edit, Restore } from '@mui/icons-material';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { resendInvite } from 'features/users';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useArchiveUserMutation,
  useDeleteUserMutation,
  useRestoreUserMutation,
} from '../api/users.api';
import UpdateContactDialog from './UpdateContactDialog';

const ContactMoreOptions = ({ contact, workspaceOwner }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [deleteContactDialogOpen, setDeleteContactDialogOpen] = useState(false);

  const [deleteCompanyContact] = useDeleteUserMutation();
  const [archiveCompanyContact] = useArchiveUserMutation();
  const [restoreCompanyContact] = useRestoreUserMutation();

  const isArchived = contact?.deleted_at;

  const { company_id: companyId, id: userId } = contact;

  const handleDelete = () => {
    deleteCompanyContact({ companyId, userId });
  };

  const handleRestore = () => {
    restoreCompanyContact({ companyId, userId });
  };

  const handleArchive = () => {
    archiveCompanyContact({ companyId, userId });
  };

  const handleResendInvitation = () => {
    setLoading(true);
    dispatch(resendInvite({ id: userId }))
      .then(() => {
        setLoading(false);
        enqueueSnackbar('Invitation Sent successfully', { variant: 'success' });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && (
        <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
          <CircularProgress className="loading" />
        </Backdrop>
      )}
      <OptionsMenu
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        triggerButton={
          <IconButton size={'small'}>
            <MoreHoriz />
          </IconButton>
        }
      >
        {(handleClose) => (
          <List dense>
            {!isArchived ? (
              <>
                <ListItemButton
                  onClick={() => {
                    handleClose();
                    setContactDialogOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    handleClose();
                    handleArchive();
                  }}
                >
                  <ListItemIcon>
                    <Archive />
                  </ListItemIcon>
                  <ListItemText>Archive</ListItemText>
                </ListItemButton>
              </>
            ) : (
              <ListItemButton
                onClick={() => {
                  handleClose();
                  handleRestore();
                }}
              >
                <ListItemIcon>
                  <Restore />
                </ListItemIcon>
                <ListItemText>Restore</ListItemText>
              </ListItemButton>
            )}
            {!contact?.email_verified_at && !contact?.deleted_at ? (
              <ListItemButton
                onClick={() => {
                  handleClose();
                  handleResendInvitation();
                }}
              >
                <ListItemIcon>
                  <EmailOutlined />
                </ListItemIcon>
                <ListItemText>Resend</ListItemText>
              </ListItemButton>
            ) : null}
            <ListItemButton
              onClick={() => {
                handleClose();
                setDeleteContactDialogOpen(true);
              }}
            >
              <ListItemIcon>
                <Delete color={'error'} />
              </ListItemIcon>
              <ListItemText sx={{ color: 'error.main' }}>Delete</ListItemText>
            </ListItemButton>
          </List>
        )}
      </OptionsMenu>
      {contactDialogOpen ? (
        <UpdateContactDialog
          contact={contact}
          handleClose={() => setContactDialogOpen(false)}
          open={contactDialogOpen}
          workspaceOwner={workspaceOwner}
        />
      ) : null}
      {deleteContactDialogOpen ? (
        <ConfirmDeleteDialog
          handleDelete={handleDelete}
          item={'contact'}
          open={deleteContactDialogOpen}
          handleClose={() => {
            setDeleteContactDialogOpen(false);
          }}
        ></ConfirmDeleteDialog>
      ) : null}
    </>
  );
};

export default ContactMoreOptions;
