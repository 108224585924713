import Button from '@mui/material/Button';
import { ContentLayout } from 'components/layouts';
import { SecondaryNav } from 'components/navigation/SecondaryNav';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ActivityFeedActionBar from '../components/ActivityFeedActionBar';
import ActivityFeedTimelineView from '../components/ActivityFeedTimelineView';

export const ActivityFeed = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const workspaces = useSelector((state) => state.users.workspaces);
  const workspaceName = workspaces ? workspaces[workspaceId]?.company : 'Workspace';

  const handleRedirectToNotificationLog = (event) => {
    event.preventDefault();
    navigate(`/workspace/${workspaceId}/notification-log`);
  };

  const [filters, setFilters] = useState({
    type: undefined,
    userId: undefined,
    project: undefined,
  });

  useEffect(() => {
    const queryParams = {};
    if (searchParams.size) {
      for (const entry of searchParams.entries()) {
        const [param, value] = entry;
        if (value) {
          queryParams[param] =
            param === 'project' ? value.split(',').map((id) => parseInt(id)) : value;
        }
      }
      setFilters(queryParams);
    }
  }, []);

  return (
    <ContentLayout
      header={
        <>
          <SecondaryNav title={`${workspaceName} Activity`}>
            <ActivityFeedActionBar filters={filters} setFilters={setFilters} />
          </SecondaryNav>
        </>
      }
    >
      <>
        <Button
          color="primary"
          style={{ width: 'fit-content', color: '#4B70E7', padding: '10px 24px' }}
          onClick={handleRedirectToNotificationLog}
        >
          View as notification log
        </Button>
        <ActivityFeedTimelineView filters={filters} />
      </>
    </ContentLayout>
  );
};
