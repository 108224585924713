import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, ListItemText, MenuItem, MenuList, Popover } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { selectCurrentUserId, selectCurrentUserWorkspaceId } from 'features/auth';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetWorkspacesQuery, useSetWorkspaceMutation } from '../api/workspace.api';
import { useDispatch } from 'react-redux';
import { resetToDefaultView } from 'slices/customViewSlice';

const useStyles = makeStyles({
  slide: {
    transition: 'all .5s ease',
  },
});

export const WorkspaceSwitcherBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [setWorkspace] = useSetWorkspaceMutation();
  const currentWorkspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const userId = useSelector((state) => selectCurrentUserId(state));
  const { data: workspaces = [], isLoading } = useGetWorkspacesQuery({ userId: userId });

  const [anchorEl, setAnchorEl] = useState(undefined);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(undefined);
  };

  const handleChangeWorkspace = ({ workspaceId, workspaceName, workspaceLogo }) => {
    setWorkspace({ workspaceId, workspaceName, workspaceLogo });
    navigate({ to: `/workspace/${workspaceId}`, options: { replace: true } });
    handleCloseMenu();
    dispatch(resetToDefaultView());
  };

  if (!isLoading && workspaces.length < 2) {
    return null;
  } else {
    return (
      <Box bgcolor={'grey.50'} display={'flex'} justifyContent={'center'}>
        <div className={classes.slide} style={{ maxHeight: isLoading ? '0px' : '50px' }}>
          <Button
            color={'primary'}
            endIcon={anchorEl ? <ExpandLess /> : <ExpandMore />}
            size={'small'}
            style={{ lineHeight: 'unset' }}
            variant={'text'}
            onClick={handleOpenMenu}
          >
            {workspaces.find((workspace) => parseInt(workspace.id) === parseInt(currentWorkspaceId))
              ?.company || currentWorkspaceId}
          </Button>
        </div>
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handleCloseMenu}
        >
          <MenuList dense>
            {workspaces
              .filter((workspace) => parseInt(workspace.id) !== parseInt(currentWorkspaceId))
              .map((workspace) => (
                <MenuItem
                  key={workspace.id}
                  onClick={() =>
                    handleChangeWorkspace({
                      workspaceId: workspace.id,
                      workspaceName: workspace.company,
                      workspaceLogo: workspace?.logo
                    })
                  }
                >
                  <ListItemText primary={workspace.company} />
                </MenuItem>
              ))}
          </MenuList>
        </Popover>
      </Box>
    );
  }
};
