import { Add } from '@mui/icons-material';
import { Container, Fab, Paper, Stack, TableContainer, Toolbar, Typography } from '@mui/material';
import SearchBox from 'components/search/SearchBox';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCompaniesQuery } from '../api/companies.api';
import { CompaniesTable } from '../components/CompaniesTable';
import { CreateCompanyDialog } from '../components/CreateCompanyDialog';

export const Companies = () => {
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const {
    data: companies = [],
    isLoading: isLoadingCompanies,
    isFetching: isFetchingCompanies,
  } = useGetCompaniesQuery();

  const filteredSortedCompanies = useMemo(
    () =>
      companies
        .filter((company) => company.name.toLowerCase().includes(searchQuery.toLowerCase()))
        .sort((a, b) => b.workspace_owner - a.workspace_owner || a.name.localeCompare(b.name)),
    [companies, searchQuery]
  );

  return (
    <Container sx={{ p: { xs: 1, md: 3 } }}>
      <Typography variant={'h4'}>Companies</Typography>
      <TableContainer component={Paper}>
        <Toolbar>
          <Stack
            useFlexGap
            alignItems={'center'}
            direction={'row'}
            justifyContent={'space-between'}
            spacing={1}
            width={1}
          >
            <SearchBox
              bgcolor={'grey.200'}
              borderRadius={1}
              fullWidth={false}
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
            <Fab
              color={'secondary'}
              size={'small'}
              sx={{ flexShrink: 0 }}
              variant={'extended'}
              onClick={() => setIsCreateDialogOpen(true)}
            >
              <Add /> Company
            </Fab>
            <CreateCompanyDialog
              handleClose={() => setIsCreateDialogOpen(false)}
              open={isCreateDialogOpen}
            />
          </Stack>
        </Toolbar>
        <CompaniesTable
          companies={filteredSortedCompanies}
          isFetching={isFetchingCompanies}
          isLoading={isLoadingCompanies}
          TableProps={{
            rowProps: (company) => ({
              sx: { cursor: 'pointer', opacity: company.deleted_at && 0.5 },
              role: 'button',
              onClick: () => navigate(`/workspace/${workspaceId}/companies/${company.id}`),
            }),
          }}
        />
      </TableContainer>
    </Container>
  );
};
