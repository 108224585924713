import * as React from 'react';

const SvgAreaPolygon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.79425 2.5H12.0622L15.1962 7.9282L12.0622 13.3564H5.79425L2.66027 7.9282L5.79425 2.5Z"
      stroke={props.color ? props.color : '#B3B3B3'}
      strokeWidth="3"
    />
  </svg>
);

export default SvgAreaPolygon;
