import { StyledDialog } from '@blackhyve/common';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Link } from '@mui/material';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { InputFileUpload } from 'components/common/ImageUploadComponent';
import { ColorSelect } from 'components/form/ColorSelect';
import { TASK_COLORS } from 'helpers/constants';
import random from 'lodash/random';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CropImage from './CropImage';
import PdfToImage from './PDFToImage';

const initialState = {
  id: undefined,
  name: '',
  input_sqft: '',
  color: TASK_COLORS[random(0, 44)].value,
  sqft: 0,
};

/**
 * Add / Update Lbs Dialog
 * @param {Boolean} open
 * @param {String} type
 * @param {Boolean} isLoading
 * @param {Object} handleClose
 * @param {String} handleSave
 * @param {Object} entityObj
 * @returns
 */
const AddEditLbsDialog = ({
  open,
  handleClose,
  type,
  handleSave,
  isSaving,
  isError,
  entityObj,
  handleSaveAndAddAnother,
  isMapUploadEnabled,
}) => {
  const formMethods = useForm({
    defaultValues: { ...initialState },
  });

  const [pdf, setPdf] = useState();

  const { control, handleSubmit, watch, reset, setValue, setFocus } = formMethods;

  const originalMap = watch('original_map');

  const handleReset = useCallback(() => {
    setFocus('name');
    reset({
      id: entityObj?.id ?? initialState.id,
      name: entityObj?.name ?? initialState.name,
      color: entityObj?.color ?? TASK_COLORS[random(0, 44)].value,
      input_sqft: entityObj?.input_sqft ?? initialState.input_sqft,
      sqft: entityObj?.sqft ?? initialState.sqft,
      map_view_data: entityObj?.map_view_data
        ? JSON.parse(entityObj?.map_view_data)
        : initialState.map_view_data,
      original_map: '',
    });
  }, [
    entityObj?.color,
    entityObj?.id,
    entityObj?.input_sqft,
    entityObj?.map_view_data,
    entityObj?.name,
    entityObj?.sqft,
    reset,
    setFocus,
  ]);

  useEffect(() => {
    handleReset();
  }, [handleReset]);

  return (
    <StyledDialog
      handleClose={handleClose}
      maxWidth={'lg'}
      open={open}
      title={`${entityObj?.id ? 'Edit' : 'Add'} ${type} `}
      actions={
        <>
          <Button size="small" onClick={handleClose}>
            Close
          </Button>
          {handleSaveAndAddAnother && (
            <LoadingButton
              loading={isSaving}
              size="small"
              variant="contained"
              onClick={() => {
                handleSubmit(handleSaveAndAddAnother)();
                handleReset();
              }}
            >
              Save And Add Another
            </LoadingButton>
          )}
          <LoadingButton
            disabled={!!pdf}
            loading={isSaving}
            size="small"
            variant="contained"
            onClick={handleSubmit(handleSave)}
          >
            Save
          </LoadingButton>
        </>
      }
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(handleSave)(e);
        }}
      >
        <Grid container spacing={1}>
          {isError && (
            <Grid item xs={12}>
              <Alert severity={'error'} variant={'filled'}>
                An error occurred please try again
              </Alert>
            </Grid>
          )}
          <Grid item xs={7}>
            <FormLabel>Name*</FormLabel>
            <Controller
              control={control}
              name="name"
              rules={{ required: 'Name field is required' }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  autoFocus
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  inputRef={ref}
                  placeholder={'Name'}
                  size="small"
                  type={'text'}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <FormLabel>
              Sqft {entityObj?.sqft && type !== 'area' ? `(${entityObj.sqft} sqft)` : ''}
            </FormLabel>
            <Controller
              control={control}
              name={type === 'area' ? 'sqft' : 'input_sqft'}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  placeholder={'Sqft'}
                  size="small"
                  type={'number'}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel>Color</FormLabel>
            <Controller
              control={control}
              name="color"
              render={({ field }) => <ColorSelect {...field} fullWidth size={'small'} />}
            />
          </Grid>
          {isMapUploadEnabled && (
            <Grid item xs={12}>
              <FormLabel>Image</FormLabel>
              <div>
                {originalMap || (entityObj?.original_map?.original_url && originalMap !== null) ? (
                  <>
                    <Controller
                      control={control}
                      defaultValue={'{}'}
                      name={'map_view_data'}
                      render={({ field: { onChange, value, ...field } }) => (
                        <CropImage
                          cropData={value}
                          image={originalMap || entityObj?.original_map?.original_url}
                          handleCropComplete={(cropData) => {
                            onChange(cropData);
                          }}
                        />
                      )}
                    />
                    <Link
                      color={'error'}
                      component={'button'}
                      type={'button'}
                      onClick={() => setValue('original_map', null)}
                    >
                      Remove
                    </Link>
                  </>
                ) : pdf ? (
                  <PdfToImage
                    pdf={pdf}
                    onSelect={(blob) => {
                      setPdf(undefined);
                      setValue('original_map', blob);
                    }}
                  />
                ) : (
                  <Controller
                    control={control}
                    defaultValue={''}
                    name="original_map"
                    render={({ field: { onChange, ...field } }) => (
                      <InputFileUpload
                        ButtonProps={{ size: 'small' }}
                        label={'Upload Image'}
                        inputProps={{
                          accept: 'image/x-png,image/gif,image/jpeg,application/pdf',
                          onChange: (e) => {
                            const file = e.target.files[0];
                            if (file.type === 'application/pdf') {
                              setPdf(file);
                            } else {
                              onChange(file);
                            }
                          },
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </Grid>
          )}
        </Grid>
        <Button disabled={!!pdf} sx={{ display: 'none' }} type={'submit'}>
          Save
        </Button>
      </form>
    </StyledDialog>
  );
};

export default AddEditLbsDialog;
