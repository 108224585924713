import { theme } from '@blackhyve/common/src/AppTheme';
import PortfolioIconComponent from '@blackhyve/common/src/icons/Portfolio';
import { Add } from '@mui/icons-material';
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
} from '@mui/material';
import SearchBox from 'components/search/SearchBox';
import { selectCurrentUserWorkspaceId } from 'features/auth';
import CreateProjectDialog from 'features/projects/components/CreateProjectDialog';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ProjectNavList } from '../../../features/projects/components/ProjectNavList';
import { NavScrollBar } from '../NavScrollBar';
import { RequireRole } from 'features/auth/components/RequireRole';
import { useGetProjectsQuery } from 'features/projects/store/project.api';
import { NavListItem } from '../NavListItem';

export const ProjectDrawer = () => {
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { data: projects = [], isLoading, isFetching } = useGetProjectsQuery({ workspaceId: Number(workspaceId) });

  const [searchQuery, setSearchQuery] = useState('');

  const getFiltered = () => {
    return projects
      .filter((project) => project.name.toLowerCase().includes(searchQuery.toLowerCase()))
      .map((project) => project.id);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <List>
        <NavListItem end to={`/workspace/${workspaceId}/projects`}>
          <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
            <PortfolioIconComponent color={'currentColor'} height={20} width={20} />
          </ListItemIcon>
          <ListItemText primary={'Portfolio'} />
        </NavListItem>
      </List>
      <Divider />
      <List>
        <RequireRole exclude roles={['contractor_contact']}>
          <AddProjectButton />
        </RequireRole>
        <ListItem>
          <SearchBox borderRadius={1} value={searchQuery} onChange={handleSearch} />
        </ListItem>
      </List>
      <Divider />
      <NavScrollBar>
        <ProjectNavList isFetching={isFetching} loading={isLoading} projectIds={getFiltered()} />
      </NavScrollBar>
    </>
  );
};

const AddProjectButton = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <>
      <ListItemButton onClick={() => setIsDialogOpen(true)}>
        <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
          <Add />
        </ListItemIcon>
        <ListItemText primary={'New Project'} />
      </ListItemButton>
      {isDialogOpen && (
        <ThemeProvider theme={theme}>
          <CreateProjectDialog handleClose={() => setIsDialogOpen(false)} open={isDialogOpen} />
        </ThemeProvider>
      )}
    </>
  );
};
