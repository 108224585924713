import isArray from 'lodash/isArray';
const { api, providesList } = require('api');

const stuckPointAPI = api.enhanceEndpoints({ addTagTypes: ['StuckPoint'] }).injectEndpoints({
  endpoints: (build) => ({
    createStuckPoint: build.mutation({
      query: ({ taskId, ...body }) => ({
        url: `/tasks/${taskId}/stuck_points`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, args) =>
        result
          ? [
              { id: 'LIST', type: 'StuckPoint' },
              { type: 'StuckPoint', id: 'StuckPointCount' },
            ]
          : [],
    }),
    updateStuckPoint: build.mutation({
      query: ({ id, ...stuckPoint }) => ({
        url: `/stuck_points/${id}`,
        method: 'PATCH',
        body: stuckPoint,
      }),
      invalidatesTags: (result, error, params) =>
        result
          ? [
              { type: 'StuckPoint', id: params.id },
              { type: 'StuckPoint', id: 'StuckPointCount' },
            ]
          : [],
    }),
    getTaskActiveStuckPoints: build.query({
      query: ({ taskId }) =>
        `/tasks/${taskId}/stuck_points?order_by=created_at,desc&resolved=false`,
      providesTags: (result, error, args) => (result ? providesList(result, 'StuckPoint') : []),
      transformResponse: (response) => response.data,
    }),
    getTaskStuckPoints: build.query({
      query: ({ taskId }) => `/tasks/${taskId}/stuck_points?order_by=created_at,desc;is_resolved`,
      providesTags: (result, error, args) => (result ? providesList(result, 'StuckPoint') : []),
      transformResponse: (response) => response.data,
    }),
    deleteStuckPoint: build.mutation({
      query: ({ id }) => ({
        url: `/stuck_points/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, params) =>
        result
          ? [
              { type: 'StuckPoint', id: params.id },
              { type: 'StuckPoint', id: 'StuckPointCount' },
            ]
          : [],
    }),
    getProjectStuckPoints: build.query({
      query: (queryParam) => {
        const queryString = Object.entries(queryParam)
          .filter(
            ([key, value]) =>
              (value !== undefined && !isArray(value)) || (isArray(value) && value.length > 0)
          )
          .map(([key, value]) =>
            ['location', 'zone', 'area'].includes(key) ? `lbs[${key}]=${value}` : `${key}=${value}`
          )
          .join('&');
        return `/projects/${queryParam.projectId}/stuck_points?${queryString}&order_by=created_at,desc;is_resolved`;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const newQueryArg = Object.fromEntries(
          Object.entries(queryArgs).filter(([key, value]) => {
            return (
              value !== false && (Array.isArray(value) ? value.length > 0 : value !== undefined)
            );
          })
        );
        return newQueryArg;
      },
      providesTags: (result, error, args) =>
        !error ? providesList(result.data, 'StuckPoint') : [],
      transformResponse: (response) => {
        return {
          data: response?.data,
          perPage: response?.meta?.per_page,
          lastPage: response?.meta?.last_page,
          total: response?.meta?.total,
        };
      },
    }),
    getAllProjectStuckPoints: build.query({
      query: ({ projectId }) => {
        return `/stuck_points?project=${projectId}&resolved=0`;
      },
      providesTags: (result, error, args) =>
        !error ? [{ type: 'StuckPoint', id: 'StuckPointCount' }] : [],
      transformResponse: (response) => response?.data?.length,
    }),
    getStuckPointDetails: build.query({
      query: ({ id }) => `/stuck_points/${id}`,
      providesTags: (result, error, args) =>
        result ? [{ type: 'StuckPoint', id: result.id }] : [],
      transformResponse: (response) => response.data,
    }),
    resolveStuckPoint: build.mutation({
      query: ({ id, ...stuckPoint }) => ({
        url: `/stuck_points/${id}/close`,
        method: 'POST',
        body: stuckPoint,
      }),
      invalidatesTags: (result, error, params) =>
        result
          ? [
              { type: 'StuckPoint', id: params.id },
              { type: 'StuckPoint', id: 'StuckPointCount' },
              { id: 'LIST', type: 'StuckPoint' },
            ]
          : [],
    }),
    reopenStuckPoint: build.mutation({
      query: ({ id, ...stuckPoint }) => ({
        url: `/stuck_points/${id}/reopen`,
        method: 'POST',
        body: stuckPoint,
      }),
      invalidatesTags: (result, error, params) =>
        result
          ? [
              { type: 'StuckPoint', id: params.id },
              { type: 'StuckPoint', id: 'StuckPointCount' },

              { id: 'LIST', type: 'StuckPoint' },
            ]
          : [],
    }),
    resendStuckPointNotification: build.mutation({
      query: ({ id }) => ({
        url: `/stuck_points/${id}/notify`,
        method: 'POST',
      }),
    }),
    getCompaniesStuckPoints: build.query({
      query: (queryParam) => {
        const queryString = Object.entries(queryParam)
          .filter(
            ([key, value]) =>
              (value !== undefined && !isArray(value)) || (isArray(value) && value.length > 0)
          )
          .map(([key, value]) =>
            ['location', 'zone', 'area'].includes(key) ? `lbs[${key}]=${value}` : `${key}=${value}`
          )
          .join('&');
        return `/companies/${queryParam.id}/stuck_points?${queryString}&order_by=created_at,desc;is_resolved`;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const newQueryArg = Object.fromEntries(
          Object.entries(queryArgs).filter(([key, value]) => {
            return (
              value !== false && (Array.isArray(value) ? value.length > 0 : value !== undefined)
            );
          })
        );
        return newQueryArg;
      },
      providesTags: (result, error, args) =>
        result?.data?.length ? providesList(result.data, 'StuckPoint') : [],
      transformResponse: (response) => {
        return {
          data: response?.data,
          perPage: response?.meta?.per_page,
          lastPage: response?.meta?.last_page,
          total: response?.meta?.total,
        };
      },
    }),
    getProjectStuckPointLBS: build.query({
      query: ({ projectId, ...params }) => {
        delete params.resolved;

        return {
          url: `projects/${projectId}/stuck_points_lbs`,
          params: params,
        };
      },
      providesTags: (response, error, args) => [
        { type: 'Jobwalk' },
        { type: 'Location' },
        { type: 'Zone' },
        { type: 'Area' },
      ],
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useCreateStuckPointMutation,
  useGetTaskActiveStuckPointsQuery,
  useDeleteStuckPointMutation,
  useUpdateStuckPointMutation,
  useGetTaskStuckPointsQuery,
  useGetProjectStuckPointsQuery,
  useResolveStuckPointMutation,
  useReopenStuckPointMutation,
  useGetStuckPointDetailsQuery,
  useGetAllProjectStuckPointsQuery,
  useGetCompaniesStuckPointsQuery,
  useResendStuckPointNotificationMutation,
  useGetProjectStuckPointLBSQuery,
} = stuckPointAPI;
