import { StyledDialog } from '@blackhyve/common';
import {
  Box,
  Button,
  CircularProgress,
  FormLabel,
  Grid,
  Paper,
  TableContainer,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { Table } from 'components/table/Table';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCreateInvoiceMutation,
  useGetBillingPeriodsByContractQuery,
  useUpdateInvoiceByIdMutation,
} from '../store/invoices.api';
import { format, parse, startOfMonth } from 'date-fns';
import { formatMoney } from './NumericControls';
import { BudgetMonthPicker } from './BudgetMonthPicker';

function parseMonthYear(date) {
  return parse(date, 'MMMM yyyy', new Date());
}

const initialState = {
  invoice_number: '',
  billing_period: new Date(),
};

function computeInvoiceInitialState(invoice) {
  return {
    ...invoice,
    billing_period: invoice ? parseMonthYear(invoice.billing_period) : new Date(),
  };
}

export const InvoiceFormDialog = ({
  open,
  handleClose,
  contractId,
  invoice,
  creating,
  afterCreate = () => {},
}) => {
  const { control, handleSubmit, reset, setError } = useForm({
    defaultValues: {
      ...initialState,
      ...computeInvoiceInitialState(invoice),
    },
  });

  const [updateInvoice, { isLoading: isLoadingUpdatingInvoice }] = useUpdateInvoiceByIdMutation();
  const [createInvoice, { isLoading: isLoadingCreatingInvoice }] = useCreateInvoiceMutation();

  const isLoading = isLoadingCreatingInvoice || isLoadingUpdatingInvoice;

  useEffect(() => {
    reset({
      ...initialState,
      ...computeInvoiceInitialState(invoice),
    });
  }, [invoice, reset]);

  async function handleStore(data) {
    const billing_period = format(new Date(data.billing_period), 'yyyy-MM-dd');
    if (invoice?.id) {
      await updateInvoice({
        ...data,
        billing_period,
      })
        .unwrap()
        .then(() => {
          reset();
          handleClose();
        })
        .catch((response) => {
          Object.entries(response.data.errors).forEach(([field, error]) => {
            setError(field, {
              type: 'manual',
              message: error?.at(0),
            });
          });
        });
    } else {
      await createInvoice({
        contractId,
        ...data,
        billing_period,
      })
        .unwrap()
        .then(({ data: invoice }) => {
          reset();
          handleClose();
          afterCreate(invoice);
        })
        .catch((response) => {
          Object.entries(response.data.errors).forEach(([field, error]) => {
            setError(field, {
              type: 'manual',
              message: error?.at(0),
            });
          });
        });
    }
  }

  return (
    <StyledDialog
      DialogContentProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
      handleClose={() => handleClose({ reload: false })}
      open={open}
      title={creating ? 'Create Invoice' : 'Edit Invoice'}
      actions={
        <>
          <Button disabled={isLoading} onClick={() => handleClose({ reload: false })}>
            Close
          </Button>
          <Button
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={'1rem'} />}
            variant={'contained'}
            onClick={handleSubmit(handleStore)}
          >
            {creating ? 'Add' : 'Save'}
          </Button>
        </>
      }
    >
      <Grid container item spacing={2} xs={12}>
        <Grid item xs={6}>
          <FormLabel>Invoice Number</FormLabel>
          <Controller
            control={control}
            name="invoice_number"
            rules={{ required: 'Invoice Number field is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={error}
                helperText={error?.message}
                size="small"
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <FormLabel>Billing period</FormLabel>
          <Controller
            control={control}
            name="billing_period"
            rules={{ required: 'Billing period field is required' }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <BudgetMonthPicker
                clearable={false}
                field={field}
                fieldState={fieldState}
                ref={ref}
              />
            )}
          />
        </Grid>
      </Grid>
    </StyledDialog>
  );
};

const columns = [
  {
    label: 'Billing Period',
    field: 'period',
    sort: true,
  },
  {
    label: 'Invoice number',
    field: 'invoice_number',
    render: ({ invoice }) => (invoice ? `${invoice.invoice_number}` : '-'),
    sort: true,
  },

  {
    label: 'Submission Date',
    field: 'submitted_on',
    render: ({ invoice }) => (invoice ? `${invoice.submitted_on}` : '-'),
    sort: true,
  },
  {
    label: 'Total Billed',
    field: 'total_billed',
    render: ({ invoice }) => (invoice ? `$${formatMoney(invoice.total)}` : '-'),
    sort: true,
  },
];
const InvoicesList = ({ rows, onClick }) => {
  return (
    <TableContainer>
      <Table
        columns={columns}
        defaultOrderBy={false}
        isFetching={false}
        isLoading={false}
        rows={rows}
        stickyHeader={true}
        rowProps={(invoice) => ({
          sx: { cursor: 'pointer' },
          role: 'button',
          onClick: () => onClick(invoice),
        })}
      />
    </TableContainer>
  );
};

const ContractsShowInvoices = ({ budgetId, projectId, workspaceId, contractId }) => {
  const navigate = useNavigate();
  const [invoiceFormDialog, setInvoiceFormDialog] = useState({ show: false, invoice: null });

  const { data: rows = [] } = useGetBillingPeriodsByContractQuery({ contractId });

  return (
    <>
      <Typography variant={'h5'}>Invoices</Typography>
      <Paper elevation={2}>
        <Toolbar style={{ position: 'sticky', left: '0px' }}>
          <Grid container item>
            <Box ml={'auto'} />

            <Button
              size="small"
              variant="text"
              onClick={() => setInvoiceFormDialog({ show: true, invoice: null })}
            >
              New Invoice
            </Button>

            <InvoiceFormDialog
              contractId={contractId}
              creating={true}
              handleClose={() => setInvoiceFormDialog({ show: false, invoice: null })}
              invoice={invoiceFormDialog.invoice}
              open={invoiceFormDialog.show}
              afterCreate={(invoice) => {
                navigate(
                  `/workspace/${workspaceId}/projects/${projectId}/budgets/${budgetId}/contracts/${contractId}/invoices/${invoice.id}`
                );
              }}
            />
          </Grid>
        </Toolbar>

        <InvoicesList
          budgetId={budgetId}
          contractId={contractId}
          projectId={projectId}
          rows={rows}
          workspaceId={workspaceId}
          onClick={({ period, invoice }) => {
            if (invoice?.id) {
              navigate(
                `/workspace/${workspaceId}/projects/${projectId}/budgets/${budgetId}/contracts/${contractId}/invoices/${invoice.id}`
              );
              return;
            }
            setInvoiceFormDialog({
              show: true,
              invoice: {
                billing_period: period,
              },
            });
          }}
        />
      </Paper>
    </>
  );
};

export default ContractsShowInvoices;
