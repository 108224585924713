import { Navigate } from 'react-router-dom';
import HelpForm from '../components/HelpForm';
import LoginForm from '../components/LoginForm';
import SignupForm from '../components/SignUpForm';
import SetPasswordForm from '../components/SetPasswordForm';
import ForgotPasswordForm from '../components/ForgotPasswordForm';

export const authRoutes = [
  { path: 'forgot-password', element: <ForgotPasswordForm /> },
  { path: 'reset-password', element: <SetPasswordForm /> },
  { path: 'login', element: <LoginForm /> },
  { path: 'help', element: <HelpForm /> },
  { path: 'invitee-signup', element: <SignupForm /> },
  { index: true, element: <Navigate replace to={'login'} /> },
];
