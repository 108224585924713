import ReactQuill from 'react-quill';
import format from 'date-fns/format';
import Grid from '@mui/material/Grid';
import parseISO from 'date-fns/parseISO';
import { useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import Avatar from 'components/common/v3/Avatar';
import IconButton from '@mui/material/IconButton';
import { useLayoutEffect, useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { MoreVert } from '@mui/icons-material';
import ListItemButton from '@mui/material/ListItemButton';
import EditIconComponent from '@blackhyve/common/src/icons/Edit';
import { ConfirmDeleteDialog, OptionsMenu } from '@blackhyve/common';
import DeleteIconComponent from '@blackhyve/common/src/icons/Delete';
import {
  TaskFeedDateStyle,
  TaskFeedRowStyle,
  TaskFeedUserName,
} from 'components/taskFeeds/utils/taskFeedStyles';
import Typography from '@mui/material/Typography';
import { useDeleteCommentMutation } from 'features/comments/store/comment.api';
import CreateComment from 'features/comments/components/CreateComment';

/**
 * Display Comments
 * @param {Object} comment
 * @param {Object} onLoad 
 * @param {Object} meta other information required like entityId, type 
 * @returns 
 */
const DisplayComment = ({ comment, onLoad, meta, onSuccess }) => {
  // const [readMore, setReadMore] = useState(false);
  // const linkName = readMore ? 'Read Less << ' : 'Read More >> ';

  const [isEditing, setIsEditing] = useState(false);
  const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState(false);
  const currentUserId = useSelector((state) => state.auth.user.id);
  const isCommentOwner = comment?.user_id == currentUserId;
  const user = comment?.user ? comment?.user : {};

  const [deleteComment] = useDeleteCommentMutation();

  const handleDeleteComment = () => {
    deleteComment({ ...meta, id: comment.id })
  };

  useLayoutEffect(() => {
    onLoad && onLoad();
  }, [isEditing]);

  return (
    <TaskFeedRowStyle container item gap={1}>
      <Grid item>
        <Avatar
          avatarStyle={{ fontSize: '13px' }}
          firstName={user?.first_name ? user?.first_name : 'Deleted User'}
          icon={user?.profile_image?.thumb_url}
          lastName={user?.last_name}
        />
      </Grid>
      <Grid container item xs>
        <Grid gap={1} container item alignItems="center" xs={6}>
          <Typography>{user?.first_name ? user?.first_name : 'Deleted User'} {user?.last_name}</Typography>
          <Typography variant='body2' color={'text.secondary'}>
            {format(
              parseISO(comment?.created_at, 'yyyy-MM-dd hh:mm a', new Date()),
              'MM-dd-yyyy hh:mm a'
            )}
          </Typography>

          {/* <Textsms fontSize="small" style={{ color: '#bdbdbd' }} /> */}
        </Grid>
        <Grid container item alignItems={'center'} gap={1} justifyContent="flex-end" xs={6}>
          {isCommentOwner && (
            <OptionsMenu
              anchorOrigin={{
                vertical: 'middle',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'middle',
                horizontal: 'right',
              }}
              triggerButton={
                <IconButton style={{ padding: '0px' }}>
                  <MoreVert
                    style={{
                      cursor: 'pointer',
                      color: '#757575',
                      marginLeft: 'auto',
                      fontSize: '18px',
                    }}
                  />
                </IconButton>
              }
            >
              {(handleClose) => (
                <>
                  <ListItemButton
                    dense
                    onClick={() => {
                      handleClose();
                      setIsEditing(true);
                    }}
                  >
                    <ListItemIcon>
                      <EditIconComponent />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </ListItemButton>
                  <ListItemButton
                    dense
                    onClick={() => {
                      handleClose();
                      setDeleteConfirmationPopup(true);
                    }}
                  >
                    <ListItemIcon>
                      <DeleteIconComponent color={'red'} />
                    </ListItemIcon>
                    <ListItemText style={{ color: 'red' }}>Delete</ListItemText>
                  </ListItemButton>
                </>
              )}
            </OptionsMenu>

          )}
        </Grid>
        <Grid item xs={12}>
          {comment.loading ? (
            <Skeleton className="skeleton-row" />
          ) : isEditing ? (
            <CreateComment
              defaultComment={comment?.comment}
              handleClose={() => setIsEditing(false)}
              id={comment.id}
              meta={{ ...meta }}
              onLoad={onLoad}
              onSuccess={onSuccess}
            />
          ) : (
            <ReactQuill
              bounds="quill"
              className={'comment'}
              readOnly={true}
              theme={'bubble'}
              value={comment?.comment}
            />)}

        </Grid>
        {/* {comment.comment && contentHeight >= 305 &&
          <Grid item>
            <a className="read-more-link" href="#" onClick={() => { setReadMore(!readMore) }}>{linkName}</a>
          </Grid>} */}
      </Grid>
      {deleteConfirmationPopup && (
        <ConfirmDeleteDialog
          handleClose={() => setDeleteConfirmationPopup(false)}
          handleDelete={handleDeleteComment}
          id={comment?.id}
          item="Comment"
          open={true}
        />
      )}
    </TaskFeedRowStyle>
  );
};


export default DisplayComment;