import { Box, Typography } from '@mui/material';
import { CROP_AREA_ASPECT } from '../utils/locationStyle';

export const MapImage = (props) => {
  if (props.src) {
    return (
      <Box
        alt={'map background'}
        as={'img'}
        width={'100%'}
        sx={{
          aspectRatio: CROP_AREA_ASPECT,
        }}
        {...props}
      />
    );
  } else {
    return (
      <Box
        alignItems={'center'}
        display={'flex'}
        justifyContent={'center'}
        width={'100%'}
        sx={{
          aspectRatio: CROP_AREA_ASPECT,
        }}
        {...props}
      >
        <Typography>No Map Found</Typography>
      </Box>
    );
  }
};
