import { StyledDialog } from '@blackhyve/common';
import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useAddOfficeMutation, useUpdateOfficeMutation } from '../api/offices.api';
import { OfficeForm } from './OfficeForm';

const initialFormState = {
  name: '',
  address: '',
  phone: '',
  email: '',
};

export const AddUpdateOfficeDialog = ({ companyId, office, open, handleClose }) => {
  const [updateOffice, { isLoading: isUpdating }] = useUpdateOfficeMutation();
  const [addOffice, { isLoading: isAdding }] = useAddOfficeMutation();

  const form = useForm({
    defaultValues: Object.assign({ ...initialFormState }, office),
  });

  const { reset, handleSubmit, setError } = form;

  const onClose = () => {
    if (!office) {
      reset(Object.assign({ ...initialFormState }, office));
    }
    handleClose();
  };

  const onSubmit = async (data) => {
    (office
      ? updateOffice({ companyId: companyId, officeId: office.id, office: data })
      : addOffice({ companyId, office: data })
    )
      .unwrap()
      .then((payload) => {
        onClose();
      })
      .catch((response) => {
        Object.entries(response.data.errors).forEach(([field, error]) => {
          setError(field, {
            type: 'manual',
            message: error,
          });
        });
      });
  };

  return (
    <StyledDialog
      open={open}
      title={office ? 'Edit Office' : 'Add Office'}
      actions={
        <>
          <Button onClick={onClose}>Close</Button>
          <Button
            disabled={isUpdating || isAdding}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {office ? 'Update' : 'Add'}
          </Button>
        </>
      }
      handleClose={(_, reason) => {
        if (reason && reason === 'backdropClick') return;
        handleClose();
      }}
    >
      <OfficeForm form={form} onSubmit={onSubmit} />
    </StyledDialog>
  );
};
