import React from 'react';
import { api } from 'api';
import isEmpty from 'lodash/isEmpty';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import { useSelector } from 'react-redux';
import { parseISO, format } from 'date-fns';
import { selectUserOrContactById } from 'features/users';
import Avatar from 'components/common/v3/Avatar';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { getNotificationText } from './NotificationLogListItem';
import NotificationTaskDetails from './NotificationTaskDetails';
import NotificationChannelRow from './NotificationChannelRow';
import { SectionHeading } from '.';
import { StyledLabelForNoResult } from 'assets/style-components/typography';
import { selectCurrentUserRole, selectCurrentUserWorkspaceId } from 'features/auth';

/**
 * Notification log details
 * @param {string} id
 * @returns
 */
const NotificationLogDetail = ({ id, queryParams }) => {
  const { notificationDetails } = api.endpoints.getWorkspaceNotificationLog.useQueryState(
    queryParams,
    {
      selectFromResult: ({ data: notifications, isFetching, isLoading }) => {
        return {
          notificationDetails: notifications?.data?.find((notification) => notification.id === id),
          isFetching,
          isLoading,
        };
      },
    }
  );
  const userId = notificationDetails?.user_id;
  const notificationAudit = notificationDetails?.audit;
  // const actorId = notificationDetails?.acting_user_id;

  const user = useSelector((state) => selectUserOrContactById(state, userId));
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const userRole = useSelector((state) => selectCurrentUserRole(state));

  // const actingUser = useSelector((state) => selectUserOrContactById(state, actorId));
  const actingUser = notificationAudit?.acting_user;

  if (isEmpty(notificationDetails)) {
    return null;
  }
  const userName = user?.first_name ? `${user?.first_name} ${user?.last_name}` : 'Deleted User';
  const actingUserName = actingUser?.first_name
    ? `${actingUser?.first_name} ${actingUser?.last_name}`
    : 'Deleted User';

  const createdAt = notificationDetails?.created_at
    ? format(
        parseISO(notificationDetails?.created_at, 'yyyy-MM-dd hh:mm a', new Date()),
        'MMMM dd, yyyy'
      )
    : '';

  const toUrl = notificationAudit?.task?.deleted_at
    ? undefined
    : userRole === 'contractor_contact'
      ? `/workspace/${workspaceId}/my-tasks?task=${notificationAudit?.task?.id}`
      : `/workspace/${workspaceId}/projects/${notificationDetails?.project_id}/planning-board/gantt?task=${notificationAudit?.task?.id}`;

  const { notificationTitle } = getNotificationText(notificationDetails, userName, toUrl);

  return (
    <Card style={{ border: 'none', boxShadow: 'none' }}>
      <CardHeader
        style={{ fontSize: '1rem' }}
        subheader={createdAt}
        avatar={
          <Avatar
            avatarStyle={{ height: '40px', width: '40px', fontSize: '13px' }}
            firstName={actingUser?.first_name ? actingUser?.first_name : 'Deleted User'}
            icon={actingUser?.profile_image?.thumb_url}
            lastName={actingUser?.last_name}
          />
        }
        title={
          <Typography style={{ fontSize: '1rem' }}>
            <b>{actingUserName}</b> {notificationTitle}
          </Typography>
        }
      />
      <CardContent>
        <NotificationTaskDetails notificationDetails={notificationDetails} />
      </CardContent>
      <CardContent style={{ paddingTop: 0 }}>
        <SectionHeading>Notification Information</SectionHeading>
        <Box alignItems={'center'} className="flex" style={{ gap: 5, marginTop: '20px' }}>
          <Avatar
            avatarStyle={{ height: '30px', width: '30px', fontSize: '12px' }}
            firstName={user?.first_name ? user?.first_name : 'Deleted User'}
            icon={user?.profile_image?.thumb_url}
            lastName={user?.last_name}
          />
          <Typography style={{ fontWeight: 600 }} variant="subtitle1">
            {userName}
          </Typography>
        </Box>
        <List aria-label="main mailbox folders" component="nav">
          {notificationDetails?.channels ? (
            notificationDetails?.channels?.map((channel) => {
              return (
                <NotificationChannelRow
                  channel={channel}
                  key={channel}
                  notificationDetails={notificationDetails}
                />
              );
            })
          ) : (
            <StyledLabelForNoResult>Chanel information is not present.</StyledLabelForNoResult>
          )}
        </List>
      </CardContent>
    </Card>
  );
};

export default NotificationLogDetail;
