import axios from 'axios';
import { forceLogout } from 'features/auth/store/extraActions';

let store;

export const injectStore = (_store) => {
  store = _store;
};

const apiErrorHandler = (error) => {
  if (axios.isCancel(error)) {
    return;
  }
  if (!error.response && !error.message) {
    return;
  }
  if (!error.response) {
    // store.dispatch({
    //   type: SHOW_SNACKBAR,
    //   message: messages.networkError,
    //   variant: 'error',
    // });
    return {};
  } else if (error.response.status === 401) {
    store.dispatch(forceLogout());
    // store.dispatch({
    //   type: SHOW_SNACKBAR,
    //   message: messages.unAuthenticatedError,
    //   variant: 'error',
    // });
    return {
      error: error.response.data.message,
      response: error.response.data,
      message: error.response.data.message,
    };
  } else if (error.response.status === 500) {
    // store.dispatch({
    //   type: SHOW_SNACKBAR,
    //   message: messages.internalServerError,
    //   variant: 'error',
    // });
    return {
      error: error.response.data.message,
      response: error.response.data,
      message: error.response.data.message,
    };
  } else if (error.response.status === 404) {
    return {
      error: error,
      response: {
        message: error.response.data.message,
        errors: [error.response.data.message],
        statusCode: 404,
      },
    };
  } else if (error.response.status === 408) {
    // store.dispatch({
    //   type: SHOW_SNACKBAR,
    //   message: messages.serverTimeOutError,
    //   variant: 'error',
    // });
    return {
      error: error.response.data.message,
      response: error.response.data,
      message: error.response.data.message,
    };
  } else {
    if (error.response && error.response.data) {
      return {
        error: error.response.data.message,
        response: error.response.data,
        message: error.response.data.message,
      };
    } else {
      return;
    }
  }
};
export default apiErrorHandler;
