import { parseDate } from '@blackhyve/utilities/dates';
import format from 'date-fns/format';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ActionBar } from 'components/navigation/ActionBar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { startOfWeek, endOfWeek, isWithinInterval } from 'date-fns';


const JobwalkHeader = ({
  startDate,
  endDate,
  projectWeek,
  setFilters,
  filters,
  completedJobwalks,
  totalJobwalks,
}) => {
  const formattedStartDate = startDate ? format(parseDate(startDate), 'MMMM-dd') : '';
  const formattedEndDate = endDate ? format(parseDate(endDate), 'MMMM-dd') : '';

  const startOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
  const endOfCurrentWeek = endOfWeek(new Date(), { weekStartsOn: 1 });


  const isCurrentWeek = isWithinInterval(parseDate(startDate), { start: startOfCurrentWeek, end: endOfCurrentWeek }) &&
    isWithinInterval(parseDate(endDate), { start: startOfCurrentWeek, end: endOfCurrentWeek });


  const alertType =
    totalJobwalks === completedJobwalks
      ? 'success'
      : completedJobwalks === 0 && totalJobwalks > 0
        ? 'error'
        : completedJobwalks !== totalJobwalks
          ? 'warning'
          : 'info';

  return (
    <ActionBar pl={0} pr={0} sx={{ py: 1, px: 1, position: 'sticky', top: 0, zIndex: 1 }}>
      <Grid container alignItems="center">
        <Grid container item xs alignItems={'center'}>
          <IconButton
            size="inherit"
            onClick={() => setFilters((filters) => ({ ...filters, week: filters.week - 1 }))}
          >
            <ArrowBackIosIcon sx={{ height: 18, width: 18 }} />
          </IconButton>
          <IconButton
            size="inherit"
            onClick={() => setFilters((filters) => ({ ...filters, week: filters.week + 1 }))}
          >
            <ArrowForwardIosIcon sx={{ height: 18, width: 18 }} />
          </IconButton>

          <Typography variant="subtitle1">
            {`${formattedStartDate} - ${formattedEndDate}`}
          </Typography>
          <Chip
            color={isCurrentWeek ? "secondary" : "primary"}
            label={isCurrentWeek ? 'Current Week' : `Week ${projectWeek || 0}`}
            size="small"
            sx={{ marginLeft: '5px' }}
            variant={isCurrentWeek ? "filled" : "outlined"}
          />
        </Grid>
        <Grid item>
          {totalJobwalks ? (
            <Alert severity={alertType} sx={{ py: '1px', }}>
              {completedJobwalks} / {totalJobwalks} Closed
            </Alert>
          ) : null}
        </Grid>
      </Grid>
    </ActionBar>
  );
};

export default JobwalkHeader;
