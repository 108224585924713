import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box, Button, Slider, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useState, useMemo } from 'react';
import Cropper from 'react-easy-crop';
import { CROP_AREA_ASPECT } from '../utils/locationStyle';

const zoomValues = { minZoom: 0.5, maxZoom: 6 };
const defaultCropData = { crop: { x: 0, y: 0 }, zoom: 0.8, rotate: 0 };

/**
 * Crop image component
 * @param {URL} image
 * @param {Object} mapViewData
 * @returns
 */
const CropImage = ({ image, cropData, handleCropComplete }) => {
  const [crop, setCrop] = useState(cropData?.crop || defaultCropData?.crop);
  const [zoom, setZoom] = useState(cropData?.zoom || defaultCropData?.zoom);
  const [rotate, setRotate] = useState(cropData?.rotate || defaultCropData?.rotate);
  const [initialCropData] = useState(cropData);

  const imageUrl = useMemo(
    () => (image instanceof File ? URL.createObjectURL(image) : image),
    [image]
  );

  const reset = () => {
    setCrop(initialCropData?.crop || defaultCropData.crop);
    setZoom(initialCropData?.zoom || defaultCropData.zoom);
    setRotate(initialCropData?.rotate || defaultCropData.rotate);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    handleCropComplete &&
      handleCropComplete({ croppedArea, croppedAreaPixels, crop, zoom, rotate });
  };

  const handleRotate = () => {
    setRotate((prevRotate) => (prevRotate === 180 ? 0 : prevRotate + 90));
  };

  return (
    <Grid container gap={1}>
      <Grid item xs={12}>
        <Box
          bgcolor={'rgb(189, 189, 189)'}
          position={'relative'}
          width={'100%'}
          sx={{
            aspectRatio: CROP_AREA_ASPECT,
          }}
        >
          <Cropper
            aspect={CROP_AREA_ASPECT}
            crop={crop}
            image={imageUrl}
            maxZoom={zoomValues.maxZoom}
            minZoom={zoomValues.minZoom}
            objectFit="contain"
            restrictPosition={false}
            rotation={rotate}
            showGrid={true}
            zoom={zoom}
            zoomSpeed={0.8}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Box>
      </Grid>
      <Stack alignItems={'center'} direction={'row'} spacing={1} width={'100%'}>
        <ZoomInIcon color={'primary'} />
        <Slider
          max={zoomValues.maxZoom}
          min={zoomValues.minZoom}
          size="medium"
          step={0.1}
          sx={{ width: '50%' }}
          value={zoom}
          onChange={(e) => {
            setZoom(e.target.value);
          }}
        />
        <IconButton color="primary" size="small" onClick={handleRotate}>
          <Rotate90DegreesCwIcon />
        </IconButton>
        <Button color="primary" size="small" onClick={reset}>
          Reset
        </Button>
      </Stack>
    </Grid>
  );
};

export default CropImage;
