import { api, providesList } from 'api';

export const officesAPI = api.enhanceEndpoints({ addTagTypes: ['Office'] }).injectEndpoints({
  endpoints: (build) => ({
    addOffice: build.mutation({
      query: ({ companyId, office }) => ({
        url: `/companies/${companyId}/offices`,
        method: 'POST',
        body: office,
      }),
      invalidatesTags: [{ type: 'Office', id: 'LIST' }],
      transformResponse: (response) => response.data,
    }),
    getOffices: build.query({
      query: ({ companyId }) => `/companies/${companyId}/offices`,
      providesTags: (result, error, args) => (result ? providesList(result, 'Office') : []),
      transformResponse: (response) => response.data,
    }),
    deleteOffice: build.mutation({
      query: ({ companyId, officeId }) => ({
        url: `/companies/${companyId}/offices/${officeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, args) => (result ? [{ type: 'Office', id: args.id }] : []),
      transformResponse: (response) => response.data,
    }),
    updateOffice: build.mutation({
      query: ({ companyId, officeId, office }) => ({
        url: `/companies/${companyId}/offices/${officeId}`,
        method: 'PATCH',
        body: office,
      }),
      invalidatesTags: (result, error, args) =>
        result ? [{ type: 'Office', id: args.officeId }] : [],
      transformResponse: (response) => response.data,
    }),
  }),
});
export const {
  useAddOfficeMutation,
  useGetOfficesQuery,
  useUpdateOfficeMutation,
  useDeleteOfficeMutation,
} = officesAPI;
