import { ConfirmDeleteDialog, OptionsMenu } from '@blackhyve/common';
import { Box, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Delete, Edit, MoreHoriz } from '@mui/icons-material';
import { useState } from 'react';
import { useDeleteOfficeMutation } from '../api/offices.api';
import { AddUpdateOfficeDialog } from './AddUpdateOfficeDialog';

export const OfficeMenuOption = ({ office }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);

  const [deleteOffice] = useDeleteOfficeMutation();

  const handleDeleteConfirmation = () => {
    deleteOffice({ companyId: office.company_id, officeId: office.id });
  };

  return (
    <>
      <OptionsMenu
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        triggerButton={
          <IconButton size="small">
            <MoreHoriz />
          </IconButton>
        }
      >
        {(handleClose) => {
          return (
            <>
              <ListItem
                button
                dense
                onClick={() => {
                  handleClose();
                  setIsUpdateDialogOpen(true);
                }}
              >
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </ListItem>
              <Box
                button
                dense
                color={'red'}
                component={ListItem}
                onClick={() => {
                  handleClose();
                  setIsDeleteDialogOpen(true);
                }}
              >
                <ListItemIcon style={{ color: 'inherit' }}>
                  <Delete color={'inherit'} />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </Box>
            </>
          );
        }}
      </OptionsMenu>
      <ConfirmDeleteDialog
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleDelete={handleDeleteConfirmation}
        item={'office'}
        open={isDeleteDialogOpen}
      />
      <AddUpdateOfficeDialog
        companyId={office.company_id}
        handleClose={() => setIsUpdateDialogOpen(false)}
        office={office}
        open={isUpdateDialogOpen}
      />
    </>
  );
};
