import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  TableContainer,
  Toolbar,
  Typography,
} from '@mui/material';
import { Table } from 'components/table/Table';
import { useGetBudgetCodesQuery, useDeleteBudgetCodeMutation } from '../store/budget-codes.api';
import { BudgetCodeFormDialog } from './BudgetCodeFormDialog';
import { useState } from 'react';
import { CurveOptions } from '../models/curves';
import { Delete, Edit, MoreHoriz } from '@mui/icons-material';
import { OptionsMenu } from '@blackhyve/common';

export const MenuOptions = ({ budgetCode, onEdit, onDelete }) => {
  return (
    <>
      <OptionsMenu
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        triggerButton={
          <IconButton size="small">
            <MoreHoriz />
          </IconButton>
        }
      >
        {(handleClose) => {
          return (
            <>
              <ListItemButton
                dense
                onClick={() => {
                  handleClose();
                  onEdit(budgetCode);
                }}
              >
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </ListItemButton>
              <ListItemButton
                dense
                onClick={() => {
                  handleClose();
                  onDelete(budgetCode);
                }}
              >
                <ListItemIcon style={{ color: 'red' }}>
                  <Delete color={'red'} />
                </ListItemIcon>
                <ListItemText style={{ color: 'red' }}>Delete</ListItemText>
              </ListItemButton>
            </>
          );
        }}
      </OptionsMenu>
    </>
  );
};
const columns = [
  { label: 'Division', field: 'division', sort: true },
  { label: 'Cost Code', field: 'cost_code', sort: true },
  { label: 'Description', field: 'description', sort: true },
  { label: 'Trade', field: 'trade_id', sort: true, render: (value) => value.trade.name },
  { label: 'Estimated Budget Percent', field: 'percent', sort: true },
  { label: 'Estimated Labor Percent', field: 'labor_percent', sort: true },
  { label: 'Estimated Material Percent', field: 'material_percent', sort: true },
  {
    label: 'Labor Curve',
    field: 'labor_curve',
    sort: true,
    render: (value) => CurveOptions.find((curve) => curve.id === value.labor_curve).name,
  },
  {
    label: 'Material Curve',
    field: 'material_curve',
    sort: true,
    render: (value) => CurveOptions.find((curve) => curve.id === value.material_curve).name,
  },
  {
    Label: '',
    fields: 'actions',
    cellProps: { align: 'right' },
    render: (budgetCode, meta) => (
      <MenuOptions budgetCode={budgetCode} onDelete={meta.onDelete} onEdit={meta.onEdit} />
    ),
  },
];

const BudgetCodesList = ({ onEdit, onDelete }) => {
  const { data: budgetCodes = [], isLoading } = useGetBudgetCodesQuery();

  const [dialog, setDialog] = useState(false);

  return (
    <TableContainer component={Paper}>
      <Toolbar
        style={{
          position: 'sticky',
          left: '0px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div></div>
        <Button size="small" variant="text" onClick={() => setDialog(true)}>
          New Budget
        </Button>
        <BudgetCodeFormDialog creating handleClose={() => setDialog(false)} open={dialog} />
      </Toolbar>
      <Table
        columns={columns}
        isFetching={isLoading}
        isLoading={isLoading}
        meta={{ onEdit, onDelete }}
        rows={budgetCodes}
      />
    </TableContainer>
  );
};

const BudgetCodesIndex = () => {
  const [editingDialog, setEditingDialog] = useState({
    open: false,
    budgetCode: null,
  });

  const [deleteBudgetCode] = useDeleteBudgetCodeMutation();

  const onDeleteBudgetCode = async (budgetCode) => {
    if (window.confirm('Are you sure you want to delete this Budget Code?')) {
      await deleteBudgetCode({ id: budgetCode.id }).unwrap();
    }
  };

  return (
    <Container>
      <Paper>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box pt={2}>
                <Typography variant={'h4'}>Budget Codes</Typography>

                <BudgetCodesList
                  onDelete={(budgetCode) => onDeleteBudgetCode(budgetCode)}
                  onEdit={(budgetCode) => setEditingDialog({ open: true, budgetCode })}
                />

                <BudgetCodeFormDialog
                  budgetCode={editingDialog.budgetCode}
                  handleClose={() => setEditingDialog({ open: false, budgetCode: null })}
                  open={editingDialog.open}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default BudgetCodesIndex;
