import { Box, Typography, getContrastRatio } from '@mui/material';
import { blue, green, grey } from '@mui/material/colors';

const statusColor = { todo: grey[600], active: green[700], complete: blue[700] };

export const Status = ({ value, ...props }) => {
  return (
    <Box
      bgcolor={statusColor?.[value] || grey[600]}
      borderRadius={1}
      color={getContrastRatio(statusColor?.[value] || grey[600], '#fff') > 4 ? '#fff' : '#000'}
      px={1}
      py={0.5}
      textAlign={'center'}
      textTransform={'capitalize'}
      {...props}
    >
      <Typography variant='subtitle2'>{value}</Typography>
    </Box>
  );
};
