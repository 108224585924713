import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

const SnackBarContainer = () => {
  const { message, variant } = useSelector((state) => state.snackBarReducer);
  const { enqueueSnackbar } = useSnackbar();

  if (message) {
    enqueueSnackbar(message, { variant: variant });
  }

  return <div></div>;
};

export default SnackBarContainer;
