import { LoginLayoutComponent } from '@blackhyve/common';
import {
  Avatar,
  Box,
  CircularProgress,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import { selectCurrentUserId } from 'features/auth';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetWorkspacesQuery, useSetWorkspaceMutation } from '../api/workspace.api';

export const Workspace = () => {
  const navigate = useNavigate();
  const [setWorkspace] = useSetWorkspaceMutation();
  const userId = useSelector((state) => selectCurrentUserId(state));
  const { data: workspaces = [], isLoading } = useGetWorkspacesQuery({ userId: userId });

  const handleChangeWorkspace = useCallback(
    ({ workspaceId, workspaceName, workspaceLogo }) => {
      setWorkspace({ workspaceId, workspaceName, workspaceLogo });
      navigate(`/workspace/${workspaceId}`, { replace: true });
    },
    [navigate, setWorkspace]
  );

  useEffect(() => {
    if (!isLoading && workspaces.length === 1) {
      handleChangeWorkspace({
        workspaceId: workspaces[0].id,
        workspaceName: workspaces[0].company,
        workspaceLogo: workspaces[0].logo
      });
    }
  }, [handleChangeWorkspace, isLoading, workspaces]);

  return (
    <LoginLayoutComponent>
      <Box padding={3} width={1}>
        {isLoading ? (
          <Box w={1}>
            <CircularProgress sx={{ m: 'auto', display: 'block' }} />
          </Box>
        ) : (
          <>
            <Typography textAlign={'center'} variant={'h6'}>
              Select a workspace
            </Typography>
            <MenuList dense>
              {workspaces.map((workspace) => (
                <MenuItem
                  key={workspace.id}
                  onClick={() =>
                    handleChangeWorkspace({
                      workspaceId: workspace.id,
                      workspaceName: workspace.company,
                      workspaceLogo: workspace.logo
                    })
                  }
                >
                  <ListItemAvatar>
                    <Avatar>{workspace.company.slice(0, 2)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={workspace.company} />
                </MenuItem>
              ))}
            </MenuList>
          </>
        )}
      </Box>
    </LoginLayoutComponent>
  );
};
