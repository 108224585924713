import Avatar from '@mui/material/Avatar';
import { forwardRef } from 'react';

const AvatarComponent = forwardRef(({ name, ...props }, ref) => {
  const firstName = name && name.includes(' ') ? name.split(' ')[0] : name;
  const lastName = name && name.includes(' ') ? name.split(' ')[1] : name;

  return (
    <Avatar ref={ref} {...props}>
      {firstName[0] + lastName[0]}
    </Avatar>
  );
});

export default AvatarComponent;
