import * as React from "react";

const SvgCheckIcon = (props) => (
  <svg
    width={10}
    height={9}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.817.933a.626.626 0 0 1 0 .885L3.765 7.869a.626.626 0 0 1-.884 0L.168 5.157a.626.626 0 0 1 .885-.885l2.27 2.27L8.933.933a.626.626 0 0 1 .884 0Z"
      fill="black"
      fill-opacity="0.54"
    />
  </svg>
);

export default SvgCheckIcon;
