import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';


export const FormTextField = styled(TextField)(() => ({
  '&.MuiInputBase-root': {
    fontSize: '14px',
    height: '34px',
  },
  '&.MuiOutlinedInput-root': {
    borderRadius: '2px',
  },
  '& textArea': {
    font: 'inherit',
    height: '79px !important',
  },
  '& .MuiSelect-select ': {
    textTransform: 'Capitalize',
  },
}));


export const StyledRowContainer = styled(Grid)(({ theme }) => ({
  '&:hover:not(.isSelected), &:focus-within:not(.isSelected)': {
    backgroundColor: '#F5F5F570',
  },
  alignItems: 'center',
  borderLeft: '1px solid #e7e4e4',
  borderRight: '1px solid #e7e4e4',
  borderBottom: '1px solid #e7e4e4',
  backgroundColor: '#fff',
  [`&:first-child`]: {
    borderTop: '1px solid #e7e4e4',
  },
  padding: '5px',
}));
