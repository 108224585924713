import SqftIconComponent from '@blackhyve/common/src/icons/SqftIcon';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DragIndicator from '@mui/icons-material/DragIndicator';
import FunctionsIcon from '@mui/icons-material/Functions';
import { Alert, Button, Divider, Paper, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import DisplayWithEditAccess, { EditContext } from 'components/common/v3/DisplayWithEditAccess';
import InlineEditCardTitle from 'components/common/v3/InlineEditCardTitle';
import SkeletonListComponent from 'components/common/v3/SkeletonListComponent';
import isEmpty from 'lodash/isEmpty';
import { Fragment, memo, useContext, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ColorDropdown from '../../../components/ColorDropdown';
import {
  useDeleteLocationMutation,
  useDuplicateLocationMutation,
  useUpdateLocationMutation,
} from '../store/location.api';
import { useCreateZoneMutation, useGetZonesQuery, useReorderZoneMutation } from '../store/zone.api';
import { getCroppedImg } from '../utils/cropImageHelper';
import AddEditLbsDialog from './AddEditLbsDialog';
import LbsMoreOptions from './LbsMoreOptions';
import ZoneRow from './ZoneRow';

const LocationRow = memo(({ location, dragHandleProps, projectId }) => {
  const canEdit = useContext(EditContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isAddZoneDialogOpen, setIsAddZoneDialogOpen] = useState(false);

  const {
    data: locationZones = [],
    isLoading,
    isFetching,
  } = useGetZonesQuery({ locationId: location?.id }, { skip: location.zones });

  const zones = location.zones || locationZones;

  const [createZone, { isLoading: isZoneGettingCreated, isError: isErrorCreatingZone }] =
    useCreateZoneMutation();

  const [reorderZone, { isLoading: isGettingReordered }] = useReorderZoneMutation();

  const handleSaveAndAddAnother = (zone, e) => {
    handleCreateZone(zone, e, true);
  };

  const handleCreateZone = async (zone, e, preventClose = false) => {
    let croppedImage = undefined;
    if (zone.original_map) {
      croppedImage = await getCroppedImg(
        URL.createObjectURL(zone.original_map),
        zone.map_view_data.croppedAreaPixels,
        zone.map_view_data.rotate,
        zone.original_map.name
      );
    }
    createZone({
      locationId: location.id,
      zone: {
        ...zone,
        sqft: zone.input_sqft,
        map_view_data: JSON.stringify(zone.map_view_data),
        map: croppedImage?.croppedImageFile,
      },
    })
      .unwrap()
      .then(() => {
        if (!preventClose) {
          setIsAddZoneDialogOpen(false);
        }
        setIsOpen(true);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleUpdateZonePriority = (data) => {
    if (isEmpty(data.destination)) {
      return false;
    }
    const parentId = data.destination.droppableId.split('-')[1];
    const zoneIds = [...zones].map((zone) => zone.id);
    const destinationIndex = data.destination.index;
    const sourceIndex = data.source.index;
    const id = zoneIds.splice(sourceIndex, 1);
    zoneIds.splice(destinationIndex, 0, ...id);
    reorderZone({
      zones: zoneIds,
      locationId: parentId,
      projectId,
      sourceIndex,
      destinationIndex,
    });
  };

  return (
    <Stack as={Paper} elevation={2} p={3} spacing={1}>
      <HeadingComponent
        dragHandleProps={dragHandleProps}
        isOpen={isOpen}
        location={location}
        noOfZones={zones?.length}
        projectId={projectId}
        setIsOpen={setIsOpen}
      />

      {isLoading ? (
        <SkeletonListComponent />
      ) : (
        <DragDropContext onDragEnd={handleUpdateZonePriority}>
          <Droppable droppableId={'location-' + location?.id} type={`ZONE-` + location?.id}>
            {(provided) => (
              <Grid
                container
                flexDirection={'column'}
                {...provided.droppableProps}
                justifyContent={'center'}
                ref={provided.innerRef}
              >
                {!isLoading && isFetching && (
                  <Box position={'relative'} width={'100%'}>
                    <Box sx={{ width: '100%', position: 'absolute', top: '0px', left: '0px' }}>
                      <LinearProgress />
                    </Box>
                  </Box>
                )}
                <Divider flexItem />
                <Grid item xs={12}>
                  {isOpen &&
                    zones &&
                    zones?.length > 0 &&
                    zones?.map((zone, index) => (
                      <Draggable
                        draggableId={`zone-${zone?.id}`}
                        index={index}
                        isDragDisabled={!canEdit || isFetching || isGettingReordered}
                        key={`zone-${zone?.id}`}
                      >
                        {(provided, snapshot) => (
                          <div
                            index={index}
                            key={`zone-${zone?.id}`}
                            ref={provided.innerRef}
                            style={{ ...provided.draggableProps.style }}
                            {...provided.draggableProps}
                          >
                            <ZoneRow
                              dragHandleProps={provided.dragHandleProps}
                              locationId={location.id}
                              projectId={projectId}
                              zone={zone}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                </Grid>
                {provided.placeholder}
                {zones?.length === 0 ? (
                  <Alert severity={'info'} sx={{ py: 0 }}>
                    No Zones
                  </Alert>
                ) : (
                  <Button
                    endIcon={isOpen ? <ExpandLess /> : <ExpandMore />}
                    size={'small'}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {isOpen ? 'Close' : 'Open'}
                  </Button>
                )}
                <Divider flexItem></Divider>
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <DisplayWithEditAccess>
        <Box mt={1}>
          <Button onClick={() => setIsAddZoneDialogOpen(true)}>+ New zone</Button>
        </Box>
        {isAddZoneDialogOpen && (
          <AddEditLbsDialog
            isMapUploadEnabled
            handleClose={() => setIsAddZoneDialogOpen(false)}
            handleSave={handleCreateZone}
            handleSaveAndAddAnother={handleSaveAndAddAnother}
            isError={isErrorCreatingZone}
            isSaving={isZoneGettingCreated}
            open={isAddZoneDialogOpen}
            type={'Zone'}
          />
        )}
      </DisplayWithEditAccess>
    </Stack>
  );
});

const HeadingComponent = (props) => {
  const canEdit = useContext(EditContext);

  const { location, dragHandleProps, setIsOpen, isOpen, noOfZones, projectId } = props;

  const [deleteLocation, { isLoading: isDeleting }] = useDeleteLocationMutation();
  const [duplicateLocation, { isLoading: isDuplicating }] = useDuplicateLocationMutation();
  const [updateLocation, { isLoading: isUpdating }] = useUpdateLocationMutation();

  const handleUpdate = (data) => {
    updateLocation({ locationId: location.id, projectId, location: data });
  };

  const handleDuplicate = () => {
    duplicateLocation({ locationId: location.id, projectId });
  };

  const handleDelete = () => {
    deleteLocation({ locationId: location.id, projectId });
  };

  const handleSaveColor = (newColor) => {
    handleUpdate({
      id: location?.id,
      color: newColor,
    });
  };

  const requestParameters = {
    id: location?.id,
  };

  const inlineEditStyle = {
    textAlign: 'initial',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#000000',
    marginBottom: '0px !important',
  };

  return (
    <Grid container item>
      <Grid container item alignItems={'center'} xs={12}>
        {isDeleting || isDuplicating || isUpdating ? (
          <Skeleton className="skeleton-row" variant="text" />
        ) : (
          <Fragment>
            <Grid item {...dragHandleProps}>
              <DragIndicator sx={{ color: 'grey.600' }} />
            </Grid>
            <Grid item>
              <IconButton
                disableFocusRipple={true}
                disableRipple={true}
                size="medium"
                sx={{ padding: 0 }}
                onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
              </IconButton>
            </Grid>
            <Grid item>
              <InlineEditCardTitle
                fieldName={'name'}
                handleUpdate={handleUpdate}
                isDefaultEdit={location?.id < 0}
                isEditable={canEdit}
                locationLabelStyle={inlineEditStyle}
                requestParameters={requestParameters}
                textToDisplay={location?.name}
              />
            </Grid>
            <Grid item mx={1}>
              {location?.sqft ? (
                <Box alignItems={'center'} display={'flex'}>
                  <FunctionsIcon htmlColor={'#B3B3B3'} />
                  <Typography sx={{ fontWeight: 'bold' }} variant="subtitle2">
                    {location?.sqft}
                  </Typography>
                </Box>
              ) : (
                '-'
              )}
            </Grid>
            <Grid item mx={1}>
              {location?.input_sqft ? (
                <Box alignItems={'center'} display={'flex'}>
                  <SqftIconComponent />
                  <Typography>{location?.input_sqft}</Typography>
                </Box>
              ) : (
                '-'
              )}
            </Grid>
            <Grid item mx={1}>
              <ColorDropdown
                color={location?.color}
                disabled={!canEdit}
                handleSaveColor={handleSaveColor}
              />
            </Grid>
            <Grid item>
              <DisplayWithEditAccess>
                <LbsMoreOptions
                  entityObj={location}
                  handleDelete={handleDelete}
                  handleDuplicate={handleDuplicate}
                  handleSave={handleUpdate}
                  isLoading={isDeleting || isDuplicating}
                  type="location"
                />
              </DisplayWithEditAccess>
            </Grid>
          </Fragment>
        )}
      </Grid>
      <Grid container item ml={'auto'} xs={5}>
        <Grid item xs={3}>
          <Typography display="block" textAlign="center" variant="overline">
            Color
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography display="block" textAlign="center" variant="overline">
            Priority
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography display="block" textAlign="center" variant="overline">
            Manual Sqft
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography display="block" textAlign="center" variant="overline">
            Sqft
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LocationRow;