import { StyledDialog } from '@blackhyve/common';
import BuildingIcon from '@blackhyve/common/src/icons/Building';
import { Avatar, Box, Chip, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { useGetTaskQuery } from 'features/tasks/store/task.api';
import { JobwalkInput } from './JobwalkInput';
import { ValueWithLabel } from 'components/ValueChange';
import { differenceInWorkdays, parseDate } from '@blackhyve/utilities/dates';
import { Status } from 'features/tasks';
import { JobwalkHistory } from './JobwalkHistory';
import { Pace } from 'Pace';
import { ProductionRating } from 'ProductionRating';
import { useGetProjectHolidaysQuery } from 'features/projects/store/project.api';

const emptyObject = {};

export const JobwalkDialog = ({ open, handleClose, taskId, handleUpdate, disabled, projectId }) => {
  const { data: task = emptyObject, isLoading } = useGetTaskQuery(
    { taskId },
    { skip: !open, refetchOnMountOrArgChange: true }
  );

  const { data: { holidays = [] } = {}, isLoading: isLoadingProjectHolidays } =
    useGetProjectHolidaysQuery(projectId);

  const scheduledStartDate = task.scheduled_start && parseDate(task.scheduled_start);
  const scheduledEndDate = task.scheduled_end && parseDate(task.scheduled_end);
  const forecastedStartDate = task.forecasted_start && parseDate(task.forecasted_start);
  const forecastedEndDate = task.forecasted_end && parseDate(task.forecasted_end);
  const actualStartDate = task.actual_start && parseDate(task.actual_start);
  const actualEndDate = task.actual_end && parseDate(task.actual_end);

  return (
    <StyledDialog
      handleClose={handleClose}
      maxWidth={'lg'}
      open={open}
      title={`Jobwalk ${task.name ? task.name : ''}`}
    >
      {isLoading || isLoadingProjectHolidays ? (
        <Box alignContent={'center'} display={'flex'} justifyContent={'center'} m={'auto'} p={4}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container direction={'column'} spacing={2}>
          <Grid container item spacing={1}>
            {task.location && (
              <Grid item>
                <Chip icon={<BuildingIcon />} label={task.location.name} />
              </Grid>
            )}
            {task.zone && (
              <Grid item>
                <Chip icon={<BuildingIcon />} label={task.zone.name} />
              </Grid>
            )}
            {task.area && (
              <Grid item>
                <Chip icon={<BuildingIcon />} label={task.area.name} />
              </Grid>
            )}
          </Grid>
          <Grid container item alignItems={'center'} spacing={1}>
            <Grid item>
              <Status value={task?.status} />
            </Grid>
            {task?.status !== 'todo' && (
              <>
                <Grid item>
                  <Pace value={task?.pace} />
                </Grid>
                <Grid item>
                  <ProductionRating value={task?.pr} />
                </Grid>
              </>
            )}
            {task.responsible_users &&
              task.responsible_users.length > 1 &&
              task.responsible_users.map((user) => (
                <Grid item key={user.id}>
                  <Chip
                    label={`${user.first_name} ${user.last_name}`}
                    variant={'outlined'}
                    avatar={
                      <Avatar src={user?.profile_image?.thumb_url}>
                        {user?.first_name?.[0]}
                        {user?.last_name?.[0]}
                      </Avatar>
                    }
                  />
                </Grid>
              ))}
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs>
              <Typography variant={'subtitle1'}>Task Details:</Typography>
              <Grid container spacing={2}>
                <Grid container item alignItems={'flex-end'} spacing={1}>
                  <Grid item xs>
                    <ValueWithLabel
                      label={'Scheduled Start'}
                      value={scheduledStartDate?.toLocaleDateString()}
                    />
                  </Grid>
                  <Grid item xs>
                    <ValueWithLabel
                      label={'Scheduled End'}
                      value={scheduledEndDate?.toLocaleDateString()}
                    />
                  </Grid>
                  <Grid item xs>
                    <ValueWithLabel label={'Scheduled Duration'} value={task.duration_days} />
                  </Grid>
                </Grid>
                <Grid container item alignItems={'flex-end'} spacing={1}>
                  <Grid item xs>
                    <ValueWithLabel
                      label={actualStartDate ? 'Actual Start' : 'Forecasted Start'}
                      value={(actualStartDate ?? forecastedStartDate)?.toLocaleDateString()}
                    />
                  </Grid>
                  <Grid item xs>
                    <ValueWithLabel
                      label={actualEndDate ? 'Actual End' : 'Forecasted End'}
                      value={(actualEndDate ?? forecastedEndDate)?.toLocaleDateString()}
                    />
                  </Grid>
                  <Grid item xs>
                    <ValueWithLabel
                      label={
                        actualStartDate && actualEndDate ? 'Actual Duration' : 'Forecasted Duration'
                      }
                      value={
                        (actualStartDate || forecastedStartDate) &&
                        (actualEndDate || forecastedEndDate) &&
                        differenceInWorkdays(
                          actualStartDate ?? forecastedStartDate,
                          actualEndDate ?? forecastedEndDate,
                          {
                            workdayCalendar: task.workday_calendar,
                            holidays,
                          }
                        ) + 1
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} sm={7} xs={12}>
              {!disabled && (
                <Paper elevation={3}>
                  <JobwalkInput task={task} onSuccess={handleUpdate} holidays={holidays} />
                </Paper>
              )}
            </Grid>
          </Grid>
          <Grid item width={'100%'}>
            <Paper>
              <JobwalkHistory
                disabled={disabled}
                TableContainerProps={{ sx: { maxHeight: '400px', overflow: 'auto' } }}
                TableProps={{ stickyHeader: true }}
                taskId={taskId}
                onDelete={handleUpdate}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </StyledDialog>
  );
};
