export function formatNotificationDate(date) {
  const currentDate = new Date();
  const inputDate = new Date(date);
  const inputYear = inputDate.getFullYear();
  const currentYear = currentDate.getFullYear();

  const isToday = inputDate.toDateString() === currentDate.toDateString();

  if (isToday) {
    const formattedTime = inputDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' });
    return formattedTime;
  } else {
    const formattedDate = inputDate.toLocaleString('en-US', { month: 'short', day: 'numeric' });
    if (inputYear === currentYear) {
      return formattedDate;
    } else {
      return `${formattedDate}, ${inputYear}`;
    }
  }
}
