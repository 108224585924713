import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  textField: {
    '& .MuiInputBase-root': {
      fontSize: '14px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '2px',
    },
    '& .MuiFormHelperText-root': {
      marginTop: '2px',
      marginLeft: '5px',
      fontSize: '11px',
    },
    //marginTop: '7px',
    verticalAlign: 'middle',
    flexDirection: 'row',
    alignItems: 'center',
    //marginBottom: '7px',
  },
  labelName: {
    fontSize: 13,
    cursor: 'pointer',
    display: 'inline-flex',
    margin: 'auto',
  },
  helperText: {
    fontSize: 12,
  },
}));

const InlineEditCardTitle = (props) => {
  const classes = useStyles();
  const {
    id,
    defaultValue,
    fieldName,
    isEditable,
    isBlankAllowed,
    handleUpdate,
    handleBeingEdited,
    isDefaultEdit,
    textBoxClass,
    textToDisplay,
    requestParameters,
    locationLabelStyle,
    editableTextBoxStyle,
    inputError,
  } = props;

  const [isEditing, setIsEditing] = useState(isDefaultEdit);
  const [error, setError] = useState(inputError ? inputError : false);

  useEffect(() => {
    if (isDefaultEdit) {
      setIsEditing(isDefaultEdit);
    }
  }, [isDefaultEdit]);

  useEffect(() => {
    if (inputError) {
      setError(inputError);
      setIsEditing(inputError ? true : false);
    }
  }, [inputError]);

  const handleIsEditing = () => {
    setIsEditing(!isEditing);

    // Check if is defined. Needs to be defined in parent component to if parent has onClick to prevent highlighting triggering
    if (handleBeingEdited) {
      handleBeingEdited();
    }
  };

  const handleUpdateName = (event) => {
    if (event.target && ((event.target.value && event.target.value.length) || isBlankAllowed)) {
      handleUpdate({ ...requestParameters, [fieldName]: event.target.value });
      setIsEditing(false);
      setError(false);
    } else {
      setError('Cannot be empty');
    }
  };

  return isEditing && isEditable ? (
    <TextField
      autoFocus
      className={clsx(classes.textField, textBoxClass)}
      defaultValue={textToDisplay}
      error={error}
      helperText={error}
      placeholder="Type a name..."
      size="small"
      variant="outlined"
      inputProps={{
        style: editableTextBoxStyle
          ? editableTextBoxStyle
          : {
            padding: '7px 6px 6px 10px',
            fontSize: 13,
          },
      }}
      onBlur={handleUpdateName}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleUpdateName(event);
        }
      }}
    />
  ) : (
    <Typography
      className={textBoxClass}
      variant="body1"
      style={
        locationLabelStyle
          ? locationLabelStyle
          : {
            fontSize: 13,
            cursor: isEditable ? 'pointer' : 'default',
            display: 'inline-flex',
            // margin: "auto"
          }
      }
      onClick={(event) => {
        id ? handleUpdate(event, id) : handleIsEditing();
      }}
    >
      {textToDisplay
        ? textToDisplay.includes('000000Z')
          ? moment(textToDisplay).format('MM/DD/YYYY')
          : textToDisplay
        : defaultValue != undefined
          ? defaultValue
          : `-----`}
    </Typography>
  );
};

export default InlineEditCardTitle;
