import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Switch from '@mui/material/Switch';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { cloneElement, isValidElement, useEffect, useMemo, useState } from 'react';
import ganttStore from '../../ganttConfig/ganttStore';
import { MenuButton } from 'assets/style-components/button';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { registerMenuState, updateMenuState } from 'slices/customViewSlice';

const getHiddenColumnMap = (columns) => {
  return columns.reduce((acc, column) => {
    if (column?.label !== '' && column?.canToggle) {
      acc[column.name] = Boolean(column.hide);
    }
    return acc;
  }, {});
};

const GanttColumnSelector = ({ triggerButton, ganttId }) => {
  const gantt = ganttStore.getGantt(ganttId);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const columns = useSelector((state) => state.customViews?.menus?.columns?.currentValue);
  const columnsDefault = useSelector((state) => state.customViews?.menus?.columns?.initialValue);

  const handleToggleColumn = (columnName) => {
    const column = gantt.getGridColumn(columnName);
    column.hide = !column?.hide;
    dispatch(
      updateMenuState({
        name: 'columns',
        value: getHiddenColumnMap(gantt.config.columns),
      })
    );
  };

  useEffect(() => {
    if (!gantt) {
      return;
    }
    if (!isEmpty(columns)) {
      Object.keys(columns).forEach((columnName) => {
        const ganttColumn = gantt?.getGridColumn(columnName);
        if (ganttColumn) {
          ganttColumn.hide = columns[columnName];
        }
      });
    }
    gantt.render();
  }, [columns, gantt]);

  useEffect(() => {
    if (gantt) {
      dispatch(
        registerMenuState({ name: 'columns', value: getHiddenColumnMap(gantt.config.columns) })
      );
    }
  }, [dispatch, gantt]);

  const isDefaultSelected = useMemo(
    () =>
      columnsDefault
        ? Object.entries(columnsDefault).every(([key, value]) => columns?.[key] === value)
        : true,
    [columnsDefault, columns]
  );

  return (
    <>
      {triggerButton && isValidElement(triggerButton) ? (
        cloneElement(triggerButton, { onClick: (event) => setAnchorEl(event.currentTarget) })
      ) : (
        <MenuButton
          endIcon={Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />}
          isHighlighted={!isDefaultSelected}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          Columns
        </MenuButton>
      )}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={Boolean(anchorEl)}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={() => setAnchorEl(null)}
      >
        <List dense>
          {gantt?.config?.columns
            ? gantt.config.columns
                .filter((column) => column?.label !== '' && column?.canToggle)
                .map((column, index) => (
                  <ListItem
                    key={`column-selector-list-item-${index}`}
                    secondaryAction={
                      <Switch
                        checked={column.hasOwnProperty('hide') ? !column.hide : true}
                        color="primary"
                        edge="end"
                        size="small"
                        onClick={() => handleToggleColumn(column.name)}
                      />
                    }
                  >
                    <ListItemText>{column?.label}</ListItemText>
                  </ListItem>
                ))
            : null}
        </List>
      </Popover>
    </>
  );
};

export default GanttColumnSelector;
