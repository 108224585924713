import { useCallback, useEffect, useState } from 'react';

const useUndoRedo = (initialState) => {
  const [state, setState] = useState(initialState);
  const [redoStack, setRedoStack] = useState([]);
  const [undoStack, setUndoStack] = useState([]);

  useEffect(() => {
    setState(initialState);
  }, [initialState]);

  const undo = () => {
    if (undoStack.length > 0) {
      const previousState = undoStack.pop();
      setRedoStack([...redoStack, state]);
      setState(previousState);
    }
  };

  const redo = () => {
    if (redoStack.length > 0) {
      const nextState = redoStack.pop();
      setUndoStack([...undoStack, state]);
      setState(nextState);
    }
  };

  const addToUndoStack = useCallback((newState) => {
    setState((prevState) => {
      setUndoStack((prevUndoStack) => [...prevUndoStack, prevState]);
      return newState;
    });
  }, []);

  const clearUndoRedo = () => {
    setUndoStack([]);
    setRedoStack([]);
  };

  return [
    state,
    addToUndoStack,
    {
      undo,
      undoStack,
      redo,
      redoStack,
      clearUndoRedo,
    },
  ];
};

export default useUndoRedo;
