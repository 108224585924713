const { hasChild, getChildren } = require('../tasks');

/**
 * Updates the critical path information for tasks in the Gantt chart.
 * @param {number} projectId - The ID of the project.
 * @param {object} gantt - The Gantt chart object.
 * @returns {number[]} - An array of updated task IDs.
 */
function updateCriticalPath(projectId, shouldUpdate, gantt) {
  const tasks = gantt.getTaskBy(
    (task) =>
      (projectId ? task?.project_id == projectId : task.type !== 'placeholder') && task.id > 0
  );
  // Get root tasks for the project
  const rootTasks = getRoots(tasks);
  // Set to store critical tasks
  const criticalTasks = new Set();

  // Traverse each root task and mark critical tasks
  rootTasks.forEach((taskId) => checkPredecessors(taskId));

  // Update critical path information for tasks and collect updated task IDs
  const updatedTaskIds = [];
  tasks.forEach((task) => {
    const isCritical = criticalTasks.has(task.id);
    if (Boolean(task.critical_path) !== Boolean(isCritical)) {
      updatedTaskIds.push(task.id);
    }
    task.critical_path = isCritical;
  });

  if (shouldUpdate) {
    gantt.batchUpdate(() => {
      for (let index = 0; index < updatedTaskIds.length; index++) {
        gantt.updateTask(updatedTaskIds[index]);
      }
    });
  }
  gantt.callEvent('onAfterUpdateCriticalPath', [updatedTaskIds]);
  return updatedTaskIds;

  /**
   * Recursively checks predecessors of a task and marks them as critical.
   * @param {number} id - The ID of the task.
   */
  function checkPredecessors(id) {
    if (!criticalTasks.has(id)) {
      let task = gantt.getTask(id);
      let predecessors = task.$target;
      criticalTasks.add(id);

      // Mark all predecessors as critical
      predecessors.forEach((linkId) => {
        let link = gantt.getLink(linkId);
        let predecessor = gantt.getTask(link.source);
        checkPredecessors(predecessor.id);
      });

      // Mark children as critical
      if (hasChild(id, gantt) && task.autoschedule_date === 'forecasted') {
        const rootTasks = getRoots(getChildren(id, gantt));
        rootTasks.forEach((taskId) => checkPredecessors(taskId));
      }
    }
  }
}

/**
 * Gets the root tasks from the given tasks.
 * @param {object[]} tasks - An array of tasks.
 * @returns {number[]} - An array of root task IDs.
 */
function getRoots(tasks) {
  let rootTasks = [];
  let maxEndDate = -Infinity;
  tasks.forEach(function (child) {
    if (+child.end_date == +maxEndDate && child.type !== 'project_bar') {
      rootTasks.push(child.id);
    }
    if (+child.end_date > +maxEndDate) {
      maxEndDate = new Date(child.end_date);
      rootTasks = [child.id];
    }
  });
  return rootTasks;
}

module.exports = { updateCriticalPath };
