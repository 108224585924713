import * as React from "react";

const SvgArrowLeftGrey = (props) => (
  <svg
    width={12}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.835 1.87 10.055.1.165 10l9.9 9.9 1.77-1.77L3.705 10l8.13-8.13Z"
      fill="#000"
      fillOpacity={0.54}
    />
  </svg>
);

export default SvgArrowLeftGrey;
