import * as React from 'react';

const SvgAvatarIconGrey = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={'#B3B3B3'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4405 4.91618C11.4405 5.73125 11.1167 6.51292 10.5404 7.08926C9.96407 7.66559 9.1824 7.98938 8.36733 7.98938C7.55227 7.98938 6.7706 7.66559 6.19426 7.08926C5.61792 6.51292 5.29414 5.73125 5.29414 4.91618C5.29414 4.10112 5.61792 3.31944 6.19426 2.74311C6.7706 2.16677 7.55227 1.84299 8.36733 1.84299C9.1824 1.84299 9.96407 2.16677 10.5404 2.74311C11.1167 3.31944 11.4405 4.10112 11.4405 4.91618ZM11.5155 8.69375C12.2916 8.04671 12.8495 7.17631 13.1133 6.20095C13.3771 5.22559 13.334 4.19264 12.9899 3.24263C12.6458 2.29261 12.0174 1.47166 11.1902 0.891468C10.363 0.311272 9.37713 0 8.36672 0C7.35631 0 6.37044 0.311272 5.54322 0.891468C4.716 1.47166 4.0876 2.29261 3.74351 3.24263C3.39943 4.19264 3.35636 5.22559 3.62018 6.20095C3.88399 7.17631 4.44187 8.04671 5.21793 8.69375C4.00781 9.26586 2.97606 10.1563 2.23312 11.2697C1.49019 12.3832 1.06403 13.6777 1.00028 15.0147C0.994346 15.2556 1.08298 15.4893 1.24721 15.6657C1.41143 15.842 1.63819 15.9471 1.87892 15.9583C2.11965 15.9696 2.35522 15.8861 2.53518 15.7258C2.71514 15.5655 2.82518 15.3412 2.84174 15.1007C2.90875 13.6803 3.52017 12.3403 4.54908 11.3588C5.57799 10.3773 6.94536 9.82969 8.36733 9.82969C9.78931 9.82969 11.1567 10.3773 12.1856 11.3588C13.2145 12.3403 13.8259 13.6803 13.8929 15.1007C13.896 15.2235 13.9235 15.3444 13.9739 15.4564C14.0242 15.5683 14.0965 15.6691 14.1863 15.7528C14.2762 15.8365 14.3819 15.9013 14.4972 15.9436C14.6124 15.9859 14.735 16.0047 14.8576 15.999C14.9803 15.9933 15.1006 15.9631 15.2114 15.9102C15.3222 15.8574 15.4214 15.783 15.5031 15.6913C15.5847 15.5996 15.6473 15.4925 15.687 15.3763C15.7267 15.2601 15.7428 15.1372 15.7344 15.0147C15.6704 13.6776 15.244 12.383 14.5009 11.2696C13.7577 10.1562 12.7258 9.2658 11.5155 8.69375Z"
      fill={'currentColor'}
    />
  </svg>
);

export default SvgAvatarIconGrey;
