import { Book } from '@mui/icons-material';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { RecipeMoreOptions } from 'features/recipes/components/RecipeMoreOptions';
import { times } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

export const RecipeNavList = ({ recipes = [], isLoading }) => {
  return (
    <Box component={List} maxWidth={1}>
      {isLoading ? (
        times(10, (index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <Skeleton height={'1.5em'} variant="circular" width={'1.5em'} />
            </ListItemIcon>
            <ListItemText disableTypography>
              <Skeleton variant="rectangular" />
            </ListItemText>
          </ListItem>
        ))
      ) : recipes && recipes.length > 0 ? (
        recipes.map((recipe) => {
          return <RecipeDrawerListRow key={recipe.id} recipe={recipe} />;
        })
      ) : (
        <Typography>No Recipes.</Typography>
      )}
    </Box>
  );
};

/**
 * Recipe row
 * @param {number} id
 * @param {boolean} isSelected
 * @returns
 */
export const RecipeDrawerListRow = ({ recipe }) => {
  const { recipeId: activeRecipeId, workspaceId } = useParams();
  const navigate = useNavigate();

  return (
    <ListItem
      button
      selected={activeRecipeId == recipe.id}
      onClick={() => {
        navigate(`/workspace/${workspaceId}/recipes/${recipe.id}`);
      }}
    >
      <ListItemIcon>
        <Book />
      </ListItemIcon>
      <Tooltip
        arrow
        aria-label={recipe.name}
        enterDelay={200}
        enterNextDelay={200}
        leaveDelay={0}
        placement={'top'}
        PopperProps={{ disablePortal: true }}
        title={recipe.name}
      >
        <ListItemText primary={recipe.name} />
      </Tooltip>
      <ListItemSecondaryAction>
        <RecipeMoreOptions recipe={recipe} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};
