import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import { fetchUsers } from 'features/users';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentLayout } from 'components/layouts';
import JobwalkList from '../components/JobwalkList';
import JobwalkHeader from '../components/JobwalkHeader';
import LinearProgress from '@mui/material/LinearProgress';
import JobwalkActionBar from '../components/JobwalkActionBar';
import JobwalkLbsFilter from '../components/JobwalkLbsFilter';
import { useGetProjectJobwalkListQuery } from '../store/jobwalk.api';
import SkeletonListComponent from 'components/common/v3/SkeletonListComponent';
import JobwalkLbsLeftNav from '../components/JobwalkLbsLeftNav';
import { useGetProjectHolidaysQuery } from 'features/projects/store/project.api';

const Jobwalk = () => {
  const dispatch = useDispatch();
  const projectId = Number(useParams().projectId) || undefined;
  ;
  // const currentUser = useSelector((state) => selectCurrentUser(state));
  const [filters, setFilters] = useState({
    week: 0,
    search: undefined,
    responsible: [],
    area: [],
    // order_by: { key: "jobwalk", direction: "asc" }
    // order_by: "scheduled_date, asc;"
  });

  const {
    data: jobwalkData,
    isLoading,
    isFetching,
  } = useGetProjectJobwalkListQuery(
    {
      projectId,
      ...filters,
    },
    { refetchOnMountOrArgChange: true }
  );

  const { data: { holidays = [] } = {}, isLoading: isLoadingProjectHolidays } = useGetProjectHolidaysQuery(projectId);

  let jobwalkList = jobwalkData?.data || [];
  // const sortedDates = jobwalkList
  //   .filter((item) => item.latest_jobwalk_date !== null)
  //   .sort((a, b) => new Date(b.latest_jobwalk_date) - new Date(a.latest_jobwalk_date));

  // const nullDates = jobwalkList.filter((item) => item.latest_jobwalk_date === null);
  // jobwalkList = [...nullDates, ...sortedDates];

  useEffect(() => {
    dispatch(fetchUsers({ projectIds: [projectId] }));
  }, [dispatch, projectId]);

  return (
    <ContentLayout>
      <Grid container>
        <Grid
          item
          lg={3}
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'none',
              lg: 'block',
              overflowY: 'auto',
              borderRight: '1px solid lightGray',
            },
          }}
        >
          <JobwalkLbsLeftNav filters={filters} projectId={projectId} setFilters={setFilters} />
        </Grid>
        <Grid item lg={9} xs={12}>
          <Grid item xs={12}>
            <JobwalkHeader
              completedJobwalks={jobwalkData?.completed_jobwalks}
              endDate={jobwalkData?.date_range_end}
              filters={filters}
              projectWeek={jobwalkData?.project_week}
              setFilters={setFilters}
              startDate={jobwalkData?.date_range_start}
              totalJobwalks={jobwalkData?.total_jobwalks}
            />
          </Grid>
          <Grid item xs={12}>
            <JobwalkActionBar filters={filters} projectId={projectId} setFilters={setFilters} />
          </Grid>
          <Grid item xs={12}>
            {isFetching && <LinearProgress />}
            {isLoading || isLoadingProjectHolidays ? (
              <SkeletonListComponent />
            ) : (
              <JobwalkList
                currentPage={jobwalkData?.current_page}
                isFetching={isFetching}
                lastPage={jobwalkData?.last_page}
                list={jobwalkList}
                meta={{ holidays }}
                setFilters={setFilters}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </ContentLayout>
  );
};

export default Jobwalk;
