import { Box, Fab, Paper, TableContainer, Toolbar, Tooltip } from '@mui/material';
import { Add } from '@mui/icons-material';
import SearchBox from 'components/search/SearchBox';
import { Table } from 'components/table/Table';
import { useState } from 'react';
import { useGetOfficesQuery } from '../api/offices.api';
import { OfficeMenuOption } from './OfficeMenuOption';
import { AddUpdateOfficeDialog } from './AddUpdateOfficeDialog';

const columns = [
  { field: 'name', label: 'Name', sort: true },
  { label: 'Address', field: 'address', sort: true },
  { label: 'Phone', field: 'phone', sort: true },
  { label: 'Email', field: 'email', sort: true },
  {
    field: 'moreOptions',
    cellProps: { align: 'right' },
    render: (office) => <OfficeMenuOption office={office} />,
  },
];

/**
 * Company office list
 * @param {Number} companyId
 * @returns
 */
export const OfficeList = ({ companyId }) => {
  const [isAddDialogOpen, setIsAddDIalogOpen] = useState(false);
  const [search, setSearch] = useState('');

  const {
    data: offices = [],
    isLoading: isLoadingOffices,
    isFetching: isFetchingOffices,
  } = useGetOfficesQuery({ companyId });

  const filteredOffices = offices.filter((contact) =>
    contact.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <TableContainer component={Paper}>
      <Toolbar style={{ position: 'sticky', left: '0px' }}>
        <SearchBox
          bgcolor={'grey.200'}
          borderRadius={1}
          fullWidth={false}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <Box ml={'auto'} />
        <Tooltip arrow placement={'top'} title={'Add'}>
          <Fab color="primary" size="small" onClick={() => setIsAddDIalogOpen(true)}>
            <Add />
          </Fab>
        </Tooltip>
        <AddUpdateOfficeDialog
          companyId={companyId}
          handleClose={() => setIsAddDIalogOpen(false)}
          open={isAddDialogOpen}
        />
      </Toolbar>
      <Table
        columns={columns}
        isFetching={isFetchingOffices}
        isLoading={isLoadingOffices}
        rows={filteredOffices}
      />
    </TableContainer>
  );
};
