import { theme } from '@blackhyve/common/src/AppTheme';
import CompanyIcon from '@blackhyve/common/src/icons/CompanyIcon';
import AddIcon from '@mui/icons-material/Add';
import { Divider, List, ListItem, ListItemIcon, ListItemText, ThemeProvider } from '@mui/material';
import SearchBox from 'components/search/SearchBox';
import { useGetCompaniesQuery } from 'features/companies/api/companies.api';
import { CompanyNavList } from 'features/companies/components/CompanyNavList';
import { CreateCompanyDialog } from 'features/companies/components/CreateCompanyDialog';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DrawerFilterMenu, DrawerFilterMenuItem } from '../menus/DrawerFilterMenu';
import { NavListItem } from '../NavListItem';
import { NavScrollBar } from '../NavScrollBar';

export const CompanyDrawer = () => {
  const { workspaceId } = useParams();
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { data: companies = [], isLoading } = useGetCompaniesQuery();

  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState(undefined);
  const [filters, setFilters] = useState({});

  const filteredCompanies = useMemo(() => {
    let filterInProgress = companies;
    //Filter
    Object.entries(filters).forEach(([key, value]) => {
      // Switch for expandability if key does not match objects field
      switch (key) {
        case 'archived':
          filterInProgress = filterInProgress.filter((company) => company['deleted_at']);
          break;
        case 'active':
          filterInProgress = filterInProgress.filter((company) => !company['deleted_at']);
          break;
        default:
          filterInProgress = filterInProgress.filter((company) => company[key] === value);
      }
    });
    // Search
    filterInProgress = filterInProgress.filter((company) => {
      return company.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
    return filterInProgress.sort(
      (a, b) => b.workspace_owner - a.workspace_owner || a.name.localeCompare(b.name)
    );
  }, [companies, filters, searchQuery]);

  /**
   *
   * @param {*} input
   * @param {string} input.filterName - Name of field to match
   * @param {String|Number|Boolean} input.value - Value to match field to
   */
  const handleFilter = ({ filterName, value }) => {
    if (Object.hasOwn(filters, filterName)) {
      const filtersClone = { ...filters };
      delete filtersClone[filterName];
      setFilters(filtersClone);
    } else {
      setFilters({ ...filters, [filterName]: value });
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <List>
        <NavListItem end to={`/workspace/${workspaceId}/companies`}>
          <ListItemIcon>
            <CompanyIcon />
          </ListItemIcon>
          <ListItemText primary={'Companies'} />
        </NavListItem>
        <Divider />
        <NavListItem onClick={() => setIsCreateOpen(true)}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={'New Company'} />
        </NavListItem>
        <ThemeProvider theme={theme}>
          {isCreateOpen && (
            <CreateCompanyDialog handleClose={() => setIsCreateOpen(false)} open={isCreateOpen} />
          )}
        </ThemeProvider>
      </List>
      <Divider />
      <List>
        <DrawerFilterMenu
          anchorEl={filterMenuAnchorEl}
          handleClose={() => setFilterMenuAnchorEl(undefined)}
        >
          <DrawerFilterMenuItem
            checked={!!filters['archived']}
            primaryText={'Archived'}
            onClick={() => handleFilter({ filterName: 'archived', value: 1 })}
          />
          <DrawerFilterMenuItem
            checked={!!filters['active']}
            primaryText="Active"
            onClick={() => handleFilter({ filterName: 'active', value: 1 })}
          />
        </DrawerFilterMenu>
        <ListItem>
          <SearchBox borderRadius={1} value={searchQuery} onChange={handleSearch} />
        </ListItem>
      </List>
      <Divider />
      <NavScrollBar>
        <CompanyNavList companies={filteredCompanies} isLoading={isLoading} />
      </NavScrollBar>
    </>
  );
};
