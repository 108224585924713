import { AccountCircle, Contacts } from '@mui/icons-material';
import { ContentLayout } from 'components/layouts/ContentLayout';
import { SecondaryNav } from 'components/navigation/SecondaryNav';
import { contactsRoutes } from 'features/contacts/routes';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useGetCompanyByIdQuery } from '../api/companies.api';
import { Company } from './Company';
import { Companies } from './Comapnies';

const CompanyLayout = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { data: company = {}, isError } = useGetCompanyByIdQuery({ companyId: companyId });

  if (isError) {
    navigate('/', { replace: true });
  }
  return (
    <ContentLayout
      header={
        <SecondaryNav
          title={company?.name}
          tabs={[
            { to: '', icon: <AccountCircle />, label: 'Profile', end: true },
            { to: 'contacts', icon: <Contacts />, label: 'contacts' },
          ]}
        />
      }
    >
      <Outlet />
    </ContentLayout>
  );
};

export const companiesRoutes = [
  { index: true, element: <Companies /> },
  {
    path: ':companyId',
    element: <CompanyLayout />,
    children: [
      { index: true, element: <Company /> },
      { path: 'contacts', children: contactsRoutes },
    ],
  },
  { index: true, element: <Navigate replace to="/" /> },
];
