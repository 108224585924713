import { StyledDialog } from '@blackhyve/common';
import {
  Box,
  Grid,
  Container,
  Button,
  Paper,
  Toolbar,
  Typography,
  CircularProgress,
  FormLabel,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { ContentLayout } from 'components/layouts';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useGetBudgetByIdQuery } from '../store/budget.api';
import {
  useCreateContractLineMutation,
  useDeleteContractLineMutation,
  useGetContractLinesByContractQuery,
  useUpdateContractLineMutation,
} from '../store/contract-lines.api';
import { useDeleteContractFileMutation, useGetContractByIdQuery } from '../store/contracts.api';
import {
  formatDecimals,
  formatMoney,
  MoneyInput,
  PercentInput,
  unformatMoney,
} from './NumericControls';
import { ContractFormDialog } from './BudgetsShowContracts';
import Breadcrumbs from 'components/Breadcrumbs';
import { useGetBudgetLinesByBudgetQuery } from '../store/budget-lines.api';
import {
  breakdownChanges,
  CostTypeOptions,
  COMBINED,
  LABOR_COST_TYPE,
  MATERIAL_COST_TYPE,
} from '../models/breakdown';
import ContractsShowInvoices from './ContractsShowInvoices';
import { FileUploadDialog } from './ContractFileUploadDialog';
import FileList from './FileList';
import { format, parseISO } from 'date-fns';
import {
  BELL_CURVE,
  CurveOptions,
  DistributionOptions,
  NORMAL_DISTRIBUTION,
} from '../models/curves';
import { parseDate } from '@blackhyve/utilities/dates';
import { BudgetMonthPicker, isAfterStart } from './BudgetMonthPicker';
import ContractsShowChangeOrdersLines from './ContractsShowChangeOrdersLines';
import ContractLinesList from './ContractLinesList';
import ContractLineDeleteDialog from './ContractLineDeleteDialog';

const initialState = {
  budget_line_id: null,

  cost_code: '',
  description: '',
  cost_type: COMBINED,
  start_date: null,
  end_date: null,

  amount: '',
  labor_amount: '',
  labor_percent: '50',
  labor_curve: BELL_CURVE,
  labor_curve_distribution: NORMAL_DISTRIBUTION,
  material_amount: '',
  material_percent: '50',
  material_curve: BELL_CURVE,
  material_curve_distribution: NORMAL_DISTRIBUTION,
  work_retention_percent: '0',
};

function buildContractLineInitialState(line) {
  return {
    ...line,
    start_date: line?.start_date ? parseISO(line.start_date) : null,
    end_date: line?.end_date ? parseISO(line.end_date) : null,
  };
}

export const ContractLineFormDialog = ({ creating = false, open, handleClose, contract, line }) => {
  const { data: budgetLines = [], isLoading: isLoadingBudgetLines } =
    useGetBudgetLinesByBudgetQuery({ budgetId: contract.budget_id });

  const budgetLineOptions = budgetLines.map((line) => ({
    id: line.id,
    name: `${line.division} - ${line.description}`,
  }));

  const { id: contractId } = contract;
  const { watch, control, handleSubmit, reset, setValue } = useForm({
    defaultValues: { ...initialState, ...buildContractLineInitialState(line ?? {}) },
  });

  useEffect(() => {
    reset({ ...initialState, ...buildContractLineInitialState(line ?? {}) });
  }, [line, reset]);

  const [createContractLine, { isLoading: isLoadingCreatingBudgetLine }] =
    useCreateContractLineMutation();

  const [updateContractLine, { isLoading: isLoadingUpdatingBudgetLine }] =
    useUpdateContractLineMutation();

  const isLoading = isLoadingCreatingBudgetLine || isLoadingUpdatingBudgetLine;

  async function handleStore(data) {
    if (line?.id) {
      await updateContractLine({
        contractLineId: line?.id,
        ...data,
        start_date: data.start_date ? format(data.start_date, 'yyyy-MM-dd') : null,
        end_date: data.end_date ? format(data.end_date, 'yyyy-MM-dd') : null,
        amount: unformatMoney(data.amount, true),
        labor_amount: unformatMoney(data.labor_amount, true),
        material_amount: unformatMoney(data.material_amount, true),
      })
        .unwrap()
        .then(() => {
          reset();
          handleClose();
        });
    } else {
      await createContractLine({
        contractId,
        ...data,
        start_date: data.start_date ? format(data.start_date, 'yyyy-MM-dd') : null,
        end_date: data.end_date ? format(data.end_date, 'yyyy-MM-dd') : null,
        amount: unformatMoney(data.amount, true),
        labor_amount: unformatMoney(data.labor_amount, true),
        material_amount: unformatMoney(data.material_amount, true),
      })
        .unwrap()
        .then(() => {
          reset();
          handleClose();
        });
    }
  }

  useEffect(() => {
    const laborWatcher = watch((state, { name, type }) => {
      if (!type) return;

      if (['budget_line_id'].includes(name)) {
        const budgetLine = budgetLines.find((row) => row.id === state.budget_line_id);

        if (!budgetLine) {
          return null;
        }

        setValue('start_date', budgetLine.start_date ? parseDate(budgetLine.start_date) : null);
        setValue('end_date', budgetLine.end_date ? parseDate(budgetLine.end_date) : null);
        setValue('description', budgetLine.description);
        setValue('cost_code', budgetLine.cost_code);
        setValue('amount', formatMoney(budgetLine.amount));
        setValue('material_amount', formatMoney(budgetLine.material_amount));
        setValue('material_percent', formatDecimals(budgetLine.material_percent));
        setValue('material_curve', budgetLine.material_curve || BELL_CURVE);
        setValue(
          'material_curve_distribution',
          budgetLine.material_curve_distribution || NORMAL_DISTRIBUTION
        );
        setValue('labor_amount', formatMoney(budgetLine.labor_amount));
        setValue('labor_percent', formatDecimals(budgetLine.labor_percent));
        setValue('labor_curve', budgetLine.labor_curve || BELL_CURVE);
        setValue(
          'labor_curve_distribution',
          budgetLine.labor_curve_distribution || NORMAL_DISTRIBUTION
        );
        let newCostType = COMBINED;
        if (budgetLine.labor_amount === 0) {
          newCostType = MATERIAL_COST_TYPE;
        }
        if (budgetLine.material_amount === 0) {
          newCostType = LABOR_COST_TYPE;
        }
        setValue('cost_type', newCostType);
      }

      if (name === 'cost_type') {
        if (state.cost_type === LABOR_COST_TYPE) {
          setValue('labor_amount', state.amount);
          setValue('labor_percent', formatDecimals(100));
          setValue('material_amount', formatMoney(0));
          setValue('material_percent', formatDecimals(0));
        } else if (state.cost_type === MATERIAL_COST_TYPE) {
          setValue('material_amount', state.amount);
          setValue('material_percent', formatDecimals(100));
          setValue('labor_amount', formatMoney(0));
          setValue('labor_percent', formatDecimals(0));
        } else {
          let half = unformatMoney(state.amount) / 2;
          setValue('material_amount', formatMoney(half));
          setValue('material_percent', formatDecimals(50));
          setValue('labor_amount', formatMoney(half));
          setValue('labor_percent', formatDecimals(50));
        }
      }

      breakdownChanges(state, name).forEach(({ field, value }) => {
        setValue(field, value);
      });
    });

    return () => {
      laborWatcher.unsubscribe();
    };
  }, [watch, setValue, budgetLines]);

  const { amount, cost_type, start_date } = watch();

  return (
    <StyledDialog
      DialogContentProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
      handleClose={() => handleClose()}
      open={open}
      title={creating ? 'Create Contract Line' : 'Edit Contract Line'}
      actions={
        <>
          <Button disabled={isLoading} onClick={() => handleClose()}>
            Close
          </Button>
          <Button
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={'1rem'} />}
            variant={'contained'}
            onClick={handleSubmit(handleStore)}
          >
            {creating ? 'Add' : 'Save'}
          </Button>
        </>
      }
    >
      <Grid container item spacing={1} xs={12}>
        <Grid container item xs={12}>
          <FormLabel>Budget Line</FormLabel>
          <Box width={'100%'}>
            <Controller
              control={control}
              name="budget_line_id"
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  autoHighlight
                  isOptionEqualToValue={(option, value) => option.id === value}
                  loading={isLoadingBudgetLines}
                  options={budgetLineOptions}
                  renderOption={(props, option, { selected }) => <li {...props}>{option?.name}</li>}
                  size="small"
                  getOptionLabel={(option) =>
                    option?.name ??
                    (budgetLineOptions &&
                      budgetLineOptions.find((trade) => trade.id === option)?.name)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="budget-line-input"
                      placeholder="Budget Line"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    field.onChange(newValue?.id ?? null);
                  }}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid container item xs={12}>
          <FormLabel>Code Code</FormLabel>
          <Controller
            control={control}
            name="cost_code"
            rules={{ required: 'Cost Code is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={error}
                helperText={error?.message}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid container item xs={12}>
          <FormLabel>Cost Type</FormLabel>
          <Controller
            control={control}
            name="cost_type"
            rules={{ required: 'Cost type field is required' }}
            render={({ field, fieldState: { error } }) => (
              <Select {...field} fullWidth name={'name'} size={'small'}>
                {CostTypeOptions.map((obj) => (
                  <MenuItem key={obj.id} value={obj.id}>
                    {obj.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid container item xs={12}>
          <FormLabel>Description</FormLabel>
          <Controller
            control={control}
            name="description"
            rules={{ required: 'Description field is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={error}
                helperText={error?.message}
                size="small"
              />
            )}
          />
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid item xs={6}>
            <FormLabel>Start Date (optional)</FormLabel>
            <Controller
              control={control}
              name="start_date"
              render={({ field: { ref, ...field }, fieldState }) => (
                <BudgetMonthPicker field={field} fieldState={fieldState} ref={ref} />
              )}
            />
            <FormHelperText>Leave blank to use the project start date.</FormHelperText>
          </Grid>

          <Grid item xs={6}>
            <FormLabel>End Date (optional)</FormLabel>
            <Controller
              control={control}
              name="end_date"
              render={({ field: { ref, ...field }, fieldState }) => (
                <BudgetMonthPicker
                  field={field}
                  fieldState={fieldState}
                  minDate={start_date}
                  ref={ref}
                />
              )}
              rules={{
                validate: {
                  isAfterStart: (value) => isAfterStart(value, start_date),
                },
              }}
            />
            <FormHelperText>Leave blank to use the project estimated end date.</FormHelperText>
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Amount ($)</FormLabel>
            <Controller
              control={control}
              name="amount"
              rules={{ required: 'Value field is required' }}
              render={({ field, fieldState: { error } }) => (
                <MoneyInput {...field} error={error} setValue={setValue} />
              )}
            />
          </Grid>
          <Grid container item xs={6}>
            <FormLabel>Work Retention (%)</FormLabel>
            <Controller
              control={control}
              name="work_retention_percent"
              rules={{ required: 'Work Retention field is required' }}
              render={({ field, fieldState: { error } }) => (
                <PercentInput {...field} fullWidth error={error} />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Labor (%)</FormLabel>
            <Controller
              control={control}
              name="labor_percent"
              rules={{ required: 'Labor Percent field is required' }}
              render={({ field, fieldState: { error } }) => (
                <PercentInput
                  {...field}
                  fullWidth
                  disabled={cost_type !== COMBINED}
                  error={error}
                />
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Material (%)</FormLabel>
            <Controller
              control={control}
              name="material_percent"
              rules={{ required: 'Material Percent field is required' }}
              render={({ field, fieldState: { error } }) => (
                <PercentInput
                  {...field}
                  fullWidth
                  disabled={cost_type !== COMBINED}
                  error={error}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Labor ($)</FormLabel>
            <Controller
              control={control}
              name="labor_amount"
              rules={{ required: 'Labor Amount field is required' }}
              render={({ field, fieldState: { error } }) => (
                <MoneyInput
                  {...field}
                  defaultMax={unformatMoney(amount)}
                  disabled={cost_type !== COMBINED}
                  error={error}
                  setValue={setValue}
                />
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Material ($)</FormLabel>
            <Controller
              control={control}
              name="material_amount"
              rules={{ required: 'Material Amount field is required' }}
              render={({ field, fieldState: { error } }) => (
                <MoneyInput
                  {...field}
                  defaultMax={unformatMoney(amount)}
                  disabled={cost_type !== COMBINED}
                  error={error}
                  setValue={setValue}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Labor Curve</FormLabel>
            <Controller
              control={control}
              name="labor_curve"
              rules={{ required: 'Labor Curve field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select {...field} fullWidth name={'name'} size={'small'}>
                  {CurveOptions.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Material Curve</FormLabel>
            <Controller
              control={control}
              name="material_curve"
              rules={{ required: 'Material Curve field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select {...field} fullWidth name={'name'} size={'small'}>
                  {CurveOptions.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Labor Curve Distribution</FormLabel>
            <Controller
              control={control}
              name="labor_curve_distribution"
              rules={{ required: 'Labor Curve Distribution field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select {...field} fullWidth name={'name'} size={'small'}>
                  {DistributionOptions.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Material Curve Distribution</FormLabel>
            <Controller
              control={control}
              name="material_curve_distribution"
              rules={{ required: 'Material Curve Distribution field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select {...field} fullWidth name={'name'} size={'small'}>
                  {DistributionOptions.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};

const ContractDetails = ({ contract }) => {
  const { id, title, company, location, description_of_work, media } = contract;

  const [deleteContractFile] = useDeleteContractFileMutation();

  const deleteFile = async (file) => {
    if (window.confirm('Are you sure you want to delete this file?')) {
      await deleteContractFile({ id, file: file.id }).unwrap();
    }
  };

  return (
    <Grid container item p={2} xs={12}>
      <Grid container item display="block" xs={6}>
        <div>
          <Typography gutterBottom variant="h6">
            Contract #
          </Typography>
          <Typography gutterBottom pl={2} variant="body1">
            {id}
          </Typography>
        </div>
        <div>
          <Typography gutterBottom variant="h6">
            Location
          </Typography>
          <Typography gutterBottom pl={2} variant="body1">
            {location.name}
          </Typography>
        </div>
        <div>
          <Typography gutterBottom variant="h6">
            Company
          </Typography>
          <Typography gutterBottom pl={2} variant="body1">
            {company.name}
          </Typography>
        </div>
      </Grid>
      <Grid container item display="block" xs={6}>
        <div>
          <Typography gutterBottom variant="h6">
            Title
          </Typography>
          <Typography gutterBottom pl={2} variant="body1">
            {title}
          </Typography>
        </div>
        <div>
          <Typography gutterBottom variant="h6">
            Description of Work
          </Typography>
          <Typography gutterBottom pl={2} variant="body1">
            {description_of_work}
          </Typography>
        </div>
      </Grid>

      {media.length > 0 && (
        <Grid item xs={12}>
          <Typography gutterBottom variant="h6">
            Files
          </Typography>
          <FileList files={media} onDelete={deleteFile} />
        </Grid>
      )}
    </Grid>
  );
};
const ContractsShow = () => {
  const { workspaceId, projectId, budgetId, contractId } = useParams();
  const { data: budget, isLoading: isLoadingBudget } = useGetBudgetByIdQuery({
    budgetId: +budgetId,
  });
  const { data: contract, isLoading: isLoadingContract } = useGetContractByIdQuery({
    contractId: +contractId,
  });

  const { data: lines = [] } = useGetContractLinesByContractQuery({ contractId });

  const [contractLineDialog, setContractLineDialog] = useState(false);
  const [editContractLineDialog, setEditContractLineDialog] = useState({ show: false, line: null });
  const [contractDialog, setContractDialog] = useState(false);
  const [fileUploadDialog, setFileUploadsDialog] = useState(false);

  const [deleteLineDialog, setDeleteLineDialog] = useState({ show: false, line: null });

  const [deleteLine, { isLoading: isDeletingLine }] = useDeleteContractLineMutation();

  if (isLoadingBudget || isLoadingContract) {
    return <>Loading...</>;
  }

  return (
    <ContentLayout>
      <Container maxWidth={'xl'}>
        <Grid container item alignItems="center">
          <Box pt={2}>
            <Typography variant={'h4'}>
              <Breadcrumbs
                links={[
                  {
                    to: `/workspace/${workspaceId}/projects/${projectId}/budgets`,
                    text: 'Budgets',
                  },
                  {
                    to: `/workspace/${workspaceId}/projects/${projectId}/budgets/${budgetId}`,
                    text: budget.name,
                  },
                  { text: contract.title },
                ]}
              />
            </Typography>
          </Box>
          <Box ml={'auto'} />

          <Box>
            <Button size="small" variant="text" onClick={() => setFileUploadsDialog(true)}>
              Upload Files
            </Button>

            <Button size="small" variant="text" onClick={() => setContractDialog(true)}>
              Edit
            </Button>

            <ContractFormDialog
              budgetId={budgetId}
              contract={contract}
              handleClose={() => setContractDialog(false)}
              open={contractDialog}
              projectId={projectId}
            />

            <FileUploadDialog
              contract={contract}
              handleClose={() => setFileUploadsDialog(false)}
              open={fileUploadDialog}
            />
          </Box>
        </Grid>
        <Box maxWidth={600} pt={2}>
          <Paper elevation={2}>
            <ContractDetails contract={contract} />
          </Paper>
        </Box>
        <Box pt={6}>
          <Typography variant={'h5'}>Schedule of values</Typography>
          <Paper elevation={2}>
            <Toolbar style={{ position: 'sticky', left: '0px' }}>
              <Grid container item>
                <Box ml={'auto'} />

                {contract.can_add_lines && (
                  <Button size="small" variant="text" onClick={() => setContractLineDialog(true)}>
                    New Contract Line
                  </Button>
                )}

                <ContractLineFormDialog
                  contract={contract}
                  creating={true}
                  handleClose={() => setContractLineDialog(false)}
                  open={contractLineDialog}
                />
              </Grid>
            </Toolbar>
            <ContractLinesList
              rows={lines}
              withOptions={contract.can_add_lines}
              onDelete={(line) => {
                setDeleteLineDialog({ show: true, line });
              }}
              onEdit={(line) => {
                setEditContractLineDialog({ show: true, line });
              }}
              onRowClicked={(line) => {
                if (!contract.can_add_lines) {
                  return;
                }
                setEditContractLineDialog({ show: true, line });
              }}
            />

            <ContractLineFormDialog
              contract={contract}
              handleClose={() => setEditContractLineDialog({ show: false, line: null })}
              open={editContractLineDialog.show}
              line={{
                ...editContractLineDialog.line,
                amount: formatMoney(editContractLineDialog.line?.amount),
                material_amount: formatMoney(editContractLineDialog.line?.material_amount),
                labor_amount: formatMoney(editContractLineDialog.line?.labor_amount),
              }}
            />

            <ContractLineDeleteDialog
              handleClose={() => setDeleteLineDialog({ show: false, line: null })}
              line={deleteLineDialog.line}
              loading={isDeletingLine}
              open={deleteLineDialog.show}
              handleDelete={async () => {
                await deleteLine({ line: deleteLineDialog.line })
                  .unwrap()
                  .then(() => {
                    setDeleteLineDialog({ show: false, line: null });
                  });
              }}
            />
          </Paper>
        </Box>
        <Box pt={6}>
          <ContractsShowChangeOrdersLines contract={contract} contractId={contractId} />
        </Box>
        <Box pb={6} pt={6}>
          <ContractsShowInvoices
            budgetId={budgetId}
            contractId={contractId}
            projectId={projectId}
            workspaceId={workspaceId}
          />
        </Box>
      </Container>
    </ContentLayout>
  );
};

export default ContractsShow;
