const { api, providesList } = require('api');

const recipeTaskAPI = api.enhanceEndpoints({ addTagTypes: ['RecipeTask'] }).injectEndpoints({
  endpoints: (build) => ({
    getRecipeTasks: build.query({
      query: ({ recipeId }) => `/recipes/${recipeId}/tasks`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, args) => (result ? providesList(result, 'RecipeTask') : []),
    }),
  }),
});

export const { useGetRecipeTasksQuery } = recipeTaskAPI;
