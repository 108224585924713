import { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import format from 'date-fns/format';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import ProductionBoardPhase from '../components/ProductionBoardPhase';
import ProductionBoardZone from '../components/ProductionBoardZone';
import ProductionBoardArea from '../components/ProductionBoardArea';
import ProductionBoardTask from '../components/ProductionBoardCreateTaskDialog';
import { useGetPhasesQuery } from 'features/locations/store/phase.api';
import ProductionBoardLocation from '../components/ProductionBoardLocation';
import { useGetLocationsQuery } from 'features/locations/store/locations.api';
import ProductionBoardBreadCrumps from '../components/ProductionBoardBreadcrumb';
import ProductionBoardCreateLBSDialog from '../components/ProductionBoardCreateLBSDialog';
import { ProductionBoardRow, ProductionBoardSkeletonList, ProductionBoardRowChildren } from '../components/ProductionBoardRow';
import { useCreateLocationMutation, useDeleteLocationMutation, useUpdateLocationMutation } from 'features/locations/store/location.api';
import ProductionBoardTaskDetails from '../components/ProductionBoardTaskDetails';



/**
 * Production Board
 * @returns 
 */
const ProductionBoard = () => {

  return <Box p={2}>
    <Box sx={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
      <ProductionBoardBreadCrumps />
    </Box>
    <Outlet />
  </Box >
}


/**
 * Production Board Location List
 * @returns 
 */
const ProductionBoardLocationList = () => {

  const workspaceId = Number(useParams().workspaceId) || undefined;
  const projectId = Number(useParams().projectId) || undefined;
  const [openLocationDialog, setOpeLocationDialog] = useState(false);
  const [openEditLocationDialog, setOpenEditLocationDialog] = useState({ open: false });
  const { data: locations, isLoading, isFetching } = useGetLocationsQuery({ projectId });

  const [createLocation, { isLoading: isCreating }] = useCreateLocationMutation();
  const [updateLocation, { isLoading: isUpdating }] = useUpdateLocationMutation();
  const [deleteLocation] = useDeleteLocationMutation();

  const handleSaveAndAddAnother = (location, e) => handleCreateLocation(location, e, true);

  const handleCreateLocation = (data, e, preventClose) => {
    createLocation({ ...data, project_id: projectId }).then((response) => {
      if (!preventClose) setOpeLocationDialog(false);
    });
  };

  const handleUpdateLocation = (data) => {
    updateLocation({ location: data, locationId: data.id })
      .then((response) => setOpenEditLocationDialog({ open: false }));
  }

  const handleDelete = (id) => deleteLocation({ locationId: id });

  const handleOpenCreateLocationDialog = () => setOpeLocationDialog(true);
  const handleCloseCreateLocationDialog = () => setOpeLocationDialog(false);

  const handleOpenEditLocationDialog = (entityObj) => setOpenEditLocationDialog({ entityObj, open: true });
  const handleCloseEditLocationDialog = () => setOpenEditLocationDialog({ open: false });

  return <Container maxWidth={'xl'}>
    <Grid container gap={1} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }} align="center">Production Schedule</Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Button variant="contained" onClick={handleOpenCreateLocationDialog}>+ New Location</Button>
      </Grid>
      <Grid container item xs={12} gap={3}>
        {(!isLoading && isFetching && (
          <Box sx={{ width: '100%', }}>
            <LinearProgress />
          </Box>
        ))}

        {isLoading ? <ProductionBoardSkeletonList /> :
          locations?.map((location) => {
            const pageLink = `/workspace/${workspaceId}/projects/${projectId}/production-board/locations/${location.id}`
            return <ProductionBoardRow
              key={location.id}
              entityObj={location}
              pageLink={pageLink}
              onDelete={handleDelete}
              onEdit={handleOpenEditLocationDialog}
            >
              <ProductionBoardLocationPhases locationId={location.id} parentLink={pageLink} />
            </ProductionBoardRow>
          })}
      </Grid>
      {openLocationDialog &&
        <ProductionBoardCreateLBSDialog
          type="Location"
          open={openLocationDialog}
          isSaving={isCreating}
          handleSave={handleCreateLocation}
          handleClose={handleCloseCreateLocationDialog}
          handleSaveAndAddAnother={handleSaveAndAddAnother}
        />}
      {openEditLocationDialog?.open &&
        <ProductionBoardCreateLBSDialog
          type="Location"
          isSaving={isUpdating}
          open={openEditLocationDialog?.open}
          handleSave={handleUpdateLocation}
          entityObj={openEditLocationDialog?.entityObj}
          handleClose={handleCloseEditLocationDialog}
        />}
    </Grid>
  </Container>
}

const ProductionBoardLocationPhases = ({ locationId, parentLink }) => {

  const { data: phases, isLoading: isLoadingPhase } = useGetPhasesQuery({ locationId })

  return <Grid container item xs={12} gap={1}>
    {isLoadingPhase ? <ProductionBoardSkeletonList isChildrenList={true} /> :
      phases?.map((phase) => {
        const pageLink = `${parentLink}/phases/${phase.id}`
        return <ProductionBoardRowChildren key={phase.id} {...phase} pageLink={pageLink} />
      })}
  </Grid >

}



export const ProductionBoardRoutes = [
  {
    path: '',
    element: <ProductionBoard />,
    children: [
      { path: 'locations', element: <ProductionBoardLocationList />, },
      { path: 'locations/:locationId', element: <ProductionBoardLocation />, },
      { path: 'locations/:locationId/phases/:phaseId', element: <ProductionBoardPhase /> },
      { path: 'locations/:locationId/phases/:phaseId/zones/:zoneId', element: <ProductionBoardZone /> },
      { path: 'locations/:locationId/phases/:phaseId/zones/:zoneId/areas/:areaId', element: <ProductionBoardArea /> },
      { path: 'locations/:locationId/phases/:phaseId/zones/:zoneId/areas/:areaId/tasks/:taskId', element: <ProductionBoardTaskDetails /> },
      { index: true, element: <Navigate replace to={`./locations`} /> },
    ],
  },


]