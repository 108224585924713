import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { StyledDialog } from '@blackhyve/common';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  largeText: {
    fontWeight: 600,
    color: '#000',
    textTransform: 'none',
    textAlign: 'center',
  },
  smallText: {
    textAlign: 'center',
  },
  cancelButton: {
    fontWeight: 800,
    color: '#fff',
    textTransform: 'Capitalize',
    backgroundColor: '#C4C4C4',
    '&:hover': {
      backgroundColor: '#a7a7a7',
    },
  },
  confirmButton: {
    fontWeight: 800,
    color: '#fff',
    textTransform: 'Capitalize',
    backgroundColor: '#1eb980',
    '&:hover': {
      backgroundColor: '#1eb980',
    },
  },
}));

/**
 * Confirmation Popup
 * @param {String} title
 * @param {String} confirmationMessage
 * @param {Object} handleClose
 * @param {Object} handleConfirm
 * @returns
 */
const ConfirmationPopup = ({
  id,
  open,
  title,
  confirmationMessage,
  handleClose,
  handleConfirm,
}) => {
  const classes = useStyles();
  const onClose = (event, reason) => {
    if (reason !== 'escapeKeyDown' && reason !== 'backdropClick') {
      handleClose();
    }
  };

  return (
    <StyledDialog
      dialogOverrides={{ maxWidth: 'xs' }}
      handleClose={onClose}
      open={open}
      title={title}
    >
      <Grid container justifyContent={'center'} spacing={1}>
        <Grid item xs={12}>
          <Typography className={classes.largeText} variant={'h6'}>
            {confirmationMessage}
          </Typography>
          <Typography className={classes.smallText}>This action cannot be undone.</Typography>
        </Grid>
        <Grid item>
          <Button size="small" onClick={handleClose}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              handleConfirm(id);
              handleClose();
            }}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};

export default ConfirmationPopup;
