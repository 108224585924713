import { createEntityAdapter } from '@reduxjs/toolkit';
import { api, providesList } from 'api';

const tradesAdapter = createEntityAdapter();
const initialState = tradesAdapter.getInitialState();

export const tradesAPI = api.enhanceEndpoints({ addTagTypes: ['Trade'] }).injectEndpoints({
  endpoints: (build) => ({
    getTrades: build.query({
      query: () => `/trades`,
      providesTags: (data) => providesList(data.ids, 'Trade'),
      transformResponse: (response) => tradesAdapter.setAll(initialState, response.data),
    }),
  }),
});
export const { useGetTradesQuery } = tradesAPI;
