import { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ links }) => {
  let separator = (link) => {
    if (link === links.at(-1)) return null;

    return ` / `;
  };

  return (
    <>
      {links.map((link) => {
        return (
          <Fragment key={`${link.text}`}>
            {link?.to ? <Link to={link.to}>{link.text}</Link> : <span>{link.text}</span>}
            {separator(link)}
          </Fragment>
        );
      })}
    </>
  );
};

export default Breadcrumbs;
