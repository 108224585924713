import { Checkbox, CircularProgress, FormControlLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { updateUser } from 'features/users/store/usersSlice';
import { pick } from 'lodash';
import forIn from 'lodash/forIn';
import isEmpty from 'lodash/isEmpty';
import { MuiTelInput } from 'mui-tel-input';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
const initialState = {
  id: undefined,
  first_name: '',
  last_name: '',
  title: '',
  phone: '',
  email: '',
  profile_image: '',
  email_notifications: true,
  sms_notifications: false,
};

const UserDetails = (props) => {
  const { userDetails, errors } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(initialState);

  const handleOnChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const handleToggle = (event) => {
    setFormState({ ...formState, [event.target.name]: !formState[event.target.name] });
  };

  const handleSetPhone = (phoneNumber) => {
    setFormState({ ...formState, phone: phoneNumber });
  };

  const handleUpdate = () => {
    setLoading(true);
    if (!isEmpty(formState)) {
      const formData = new FormData();
      forIn(formState, (value, key) => {
        formData.append(key, value);
      });
      dispatch(updateUser(formState))
        .unwrap()
        .then((response) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!isEmpty(userDetails)) {
      setFormState({ ...initialState, ...pick(userDetails, Object.keys(initialState)) });
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [userDetails]);

  return (
    <>
      {loading && <CircularProgress className="loading" />}
      <Grid container spacing={2}>
        <Grid container item>
          <Grid item xs={12}>
            <Typography variant={'h5'}>Account Settings</Typography>
          </Grid>
          <Grid container item spacing={2} xs={12}>
            <Grid item sm={6} xs={12}>
              <Typography>First Name</Typography>
              <TextField
                fullWidth
                errors={errors && errors.first_name}
                helperText={errors && errors.first_name}
                name="first_name"
                required={true}
                size={'small'}
                type="text"
                value={formState.first_name}
                variant={'outlined'}
                onChange={handleOnChange}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography>Last Name</Typography>
              <TextField
                fullWidth
                errors={errors && errors.last_name}
                helperText={errors && errors.last_name}
                name="last_name"
                required={true}
                size="small"
                type="text"
                value={formState.last_name}
                variant="outlined"
                onChange={handleOnChange}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography>Title</Typography>
              <TextField
                fullWidth
                errors={errors && errors.title}
                helperText={errors && errors.title}
                name="title"
                required={true}
                size="small"
                type="text"
                value={formState.title}
                variant="outlined"
                onChange={handleOnChange}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography>Email Address</Typography>
              <TextField
                fullWidth
                disabled={true}
                errors={errors && errors.email}
                helperText={errors && errors.email}
                name="email_address"
                required={true}
                size="small"
                type="text"
                value={formState.email}
                variant="outlined"
                onChange={handleOnChange}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography>Cell Phone</Typography>
              <MuiTelInput
                name={'phone'}
                size="small"
                value={formState.phone}
                onChange={handleSetPhone}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item>
            <Typography variant={'h5'}>Notifications Settings</Typography>
          </Grid>
          <Grid container item>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Send me notifications via email'}
                control={
                  <Checkbox
                    checked={Boolean(formState?.email_notifications)}
                    name={'email_notifications'}
                    onClick={handleToggle}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(formState?.sms_notifications)}
                    disabled={!formState.phone}
                    name={'sms_notifications'}
                    onClick={handleToggle}
                  />
                }
                label={`Send me notifications via SMS ${
                  !formState.phone ? '(Add Phone Number To Enable)' : ''
                }`}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button type="submit" variant="contained" onClick={handleUpdate}>
            {formState.id ? 'Update' : 'Create'}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UserDetails;
