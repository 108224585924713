import { Grid } from '@mui/material';
import styled from 'styled-components';
const SummaryGrid = styled(Grid)(() => ({
  '&': {
    width: '100%',
    borderRadius: '0.25rem',
    overflow: 'hidden',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  '& .summary-card--title': {
    fontWeight: 500,
    fontSize: '16px',
    padding: '1.5em',
    backgroundColor: '#F9FAFB',
    margin: 0,
  },
  '& .summary-card--item': {
    fontSize: '14px',
    padding: '1em 1.5em',
    borderTop: '1px solid #E5E7EB',
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& .summary-card--label': {
    color: '#6b7280',
  },
  '& .summary-card--value': {
    color: '#111827',
    fontWeight: 500,
  },
  '& .summary-card--value__muted': {
    color: '#6b7280',
    fontWeight: 'normal',
    opacity: 0.75,
  },
}));

export const SummaryCard = ({ title, children, ...props }) => {
  return (
    <SummaryGrid {...props}>
      <h3 className="summary-card--title">{title}</h3>
      {children}
    </SummaryGrid>
  );
};

export const SummaryItem = ({ label, children }) => (
  <dl className="summary-card--item">
    <dt className="summary-card--label">{label}</dt>
    <dd className="summary-card--value">{children}</dd>
  </dl>
);
