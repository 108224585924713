import { api, providesList } from 'api';

const contractsAPI = api.enhanceEndpoints({ addTagTypes: ['Contract'] }).injectEndpoints({
  endpoints: (build) => ({
    getContractsByBudget: build.query({
      query: ({ budgetId }) => `/budgets/${budgetId}/contracts`,
      providesTags: (result, error, args) => (result ? providesList(result, 'Contract') : []),
      transformResponse: (response) => response.data,
    }),
    createContract: build.mutation({
      query: ({ budgetId, ...body }) => ({
        url: `/budgets/${budgetId}/contracts`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, params) =>
        result ? [{ type: 'Contract', id: 'LIST' }] : [],
    }),
    getContractById: build.query({
      query: ({ contractId }) => `/contracts/${contractId}`,
      providesTags: (result, error, { contractId }) => [{ type: 'Contract', id: contractId }],
      transformResponse: (response) => response.data,
    }),
    updateContractById: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/contracts/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: 'Contract', id: id },
              { type: 'Contract', id: 'LIST' },
            ]
          : [],
    }),
    uploadContractFiles: build.mutation({
      query: ({ id, formData }) => ({
        url: `/contracts/${id}/files`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: (result, error, { id }) => (result ? [{ type: 'Contract', id }] : []),
    }),
    deleteContractFile: build.mutation({
      query: ({ id, file }) => ({
        url: `/contracts/${id}/files/${file}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => {
        return [{ type: 'Contract', id }];
      },
    }),
  }),
});

export const {
  useGetContractsByBudgetQuery,
  useGetContractByIdQuery,
  useCreateContractMutation,
  useUpdateContractByIdMutation,
  useUploadContractFilesMutation,
  useDeleteContractFileMutation,
} = contractsAPI;
