import { parseDate } from '@blackhyve/utilities/dates';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { ContentLayout } from 'components/layouts';
import differenceInDays from 'date-fns/differenceInDays';
import { selectCurrentUser } from 'features/auth';
import CreateComment from 'features/comments/components/CreateComment';
import { useGetCommentsQuery } from 'features/comments/store/comment.api';
import { useGetCompaniesQuery } from 'features/companies/api/companies.api';
import { useGetWorkspaceCompanyQuery } from 'features/projectCompanies/api/projectCompanies.api';
import { useSelector } from 'react-redux';
import DisplayComment from '../../comments/components/DisplayComment';
import { useGetStuckPointDetailsQuery } from '../store/stuckPoint.api';
import StuckPointMoreOptions from './StuckPointMoreOptions';
import LocationLevelIconComponent from '@blackhyve/common/src/icons/LocationLevel';
import AreaPolygonIconComponent from '@blackhyve/common/src/icons/AreaPolygon';
import BuildingIcon from '@blackhyve/common/src/icons/Building';
import { Stack } from '@mui/material';

/**
 * Stuck Point Details
 * @returns
 */
const StuckPointDetails = ({ projectId, id }) => {
  const skip = !projectId || !id;
  const { data: companies = [] } = useGetCompaniesQuery(undefined, { skip });
  const {
    data: stuckPoint = {},
    isLoading,
    isFetching,
  } = useGetStuckPointDetailsQuery({ id }, { skip });
  const { data: responsibleUsers = { users: [] } } = useGetWorkspaceCompanyQuery(
    { projectId },
    { skip }
  );

  const currentUser = useSelector((state) => selectCurrentUser(state));
  const users = [...responsibleUsers?.users, currentUser];

  const companyDetails = stuckPoint?.companies_to_blame
    ?.map((companyId) => {
      return companies.find((company) => company.id === companyId);
    })
    .filter((company) => company);

  const members = stuckPoint?.responsible_users
    ?.map((userId) => {
      return users?.find((user) => user.id === userId);
    })
    .filter((member) => member);

  const daysStuck = stuckPoint?.is_resolved
    ? differenceInDays(parseDate(stuckPoint?.resolved_on), parseDate(stuckPoint?.opened_on))
    : differenceInDays(new Date(), parseDate(stuckPoint?.opened_on));

  return (
    <ContentLayout>
      <Container maxWidth="md" sx={{ paddingTop: '20px', paddingBottom: '10px' }}>
        <Paper elevation={3}>
          {isFetching && <LinearProgress />}
          {isLoading ? (
            <StuckPointCardSkeleton />
          ) : (
            <Grid container px={2} py={1} spacing={1}>
              <Grid container item xs={12}>
                <Grid container item alignItems={'center'} xs={11}>
                  <Typography sx={{ fontWeight: 'bold' }} variant={'h5'}>
                    {stuckPoint?.title}
                  </Typography>
                </Grid>
                <Grid container item xs justifyContent={'flex-end'}>
                  <StuckPointMoreOptions stuckPoint={stuckPoint} />
                </Grid>
              </Grid>
              <Grid container item gap={1} xs={12}>
                {stuckPoint?.is_resolved ? (
                  <Chip color="success" label="Resolved" variant="outlined" />
                ) : (
                  <Chip color="error" label="Pending" variant="outlined" />
                )}
                {stuckPoint?.resolved_on && !stuckPoint?.is_resolved && (
                  <Chip color="error" label="Reopened" variant="outlined" />
                )}
                {stuckPoint?.affects_pr ? (
                  <Chip
                    color="error"
                    label={`Work ${stuckPoint?.is_resolved ? 'was' : 'is'} paused`}
                    variant="outlined"
                  />
                ) : null}
                {members?.map((member) => (
                  <Chip
                    key={member?.id}
                    label={`${member?.first_name} ${member?.last_name}`}
                    avatar={
                      <Avatar src={member?.profile_image?.thumb_url}>
                        {member?.first_name?.[0]}
                        {member?.last_name?.[0]}
                      </Avatar>
                    }
                  />
                ))}
              </Grid>
              <Grid container item xs={12}>
                <Grid item marginBottom={1} xs={6}>
                  <Typography component="div" style={{ display: 'flex', alignItems: 'center' }}>
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <BuildingIcon style={{ color: '#B3B3B3' }} />
                      <span>{stuckPoint?.task?.location_name}</span>
                      <LocationLevelIconComponent height={16} style={{ marginLeft: 8 }} />
                      <span>{stuckPoint?.task?.zone_name}</span>
                      <AreaPolygonIconComponent style={{ marginLeft: 8 }} />
                      <span>{stuckPoint?.task?.area_name}</span>
                    </Stack>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>
                    <FormLabel>Task Name: </FormLabel>
                    {stuckPoint?.task?.name}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item xs={6}>
                <Grid item xs={4}>
                  <FormLabel>Opened By:</FormLabel>
                </Grid>
                <Grid item xs={6}>
                  {stuckPoint?.opened_by ? (
                    <Typography>
                      {`${stuckPoint?.opened_by?.first_name} ${stuckPoint?.opened_by?.last_name}`}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>

              <Grid container item xs={6}>
                <Grid item xs={4}>
                  <FormLabel>Opened On:</FormLabel>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{parseDate(stuckPoint?.opened_on).toLocaleDateString()}</Typography>
                </Grid>
              </Grid>

              <Grid container item xs={6}>
                <Grid item xs={4}>
                  <FormLabel>Days Stuck:</FormLabel>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{daysStuck}</Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={2}>
                  <FormLabel>Stuck By:</FormLabel>
                </Grid>
                <Grid container item gap={1} xs={10}>
                  {companyDetails?.map((company) => (
                    <Chip key={company?.id} label={company?.name} variant="subtitle2" />
                  ))}
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={2}>
                  <FormLabel>Description:</FormLabel>
                </Grid>
                <Grid item xs={10}>
                  <Typography color="text.secondary">
                    {stuckPoint?.description ? stuckPoint?.description : '-'}
                  </Typography>
                </Grid>
              </Grid>
              {stuckPoint?.resolved_by ? (
                <Grid container item xs={6}>
                  <Grid item xs={4}>
                    <FormLabel>Resolved By:</FormLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {`${stuckPoint?.resolved_by?.first_name} ${stuckPoint?.resolved_by?.last_name}`}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
              {stuckPoint?.resolved_on ? (
                <Grid container item xs={6}>
                  <Grid item xs={4}>
                    <FormLabel>Resolved On:</FormLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {parseDate(stuckPoint?.resolved_on).toLocaleDateString()}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}

              {stuckPoint?.resolved_on ? (
                <Grid container item xs={12}>
                  <Grid item xs={2}>
                    <FormLabel>Resolved Note:</FormLabel>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography color="text.secondary">
                      {stuckPoint?.resolution_notes ? stuckPoint?.resolution_notes : '-'}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <CreateComment
                  meta={{
                    entityId: stuckPoint?.id,
                    entityType: 'stuck_points',
                    entityTag: 'StuckPoint',
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <StuckPointComments
                  entityId={stuckPoint?.id}
                  entityTag={'StuckPoint'}
                  entityType="stuck_points"
                />
              </Grid>
            </Grid>
          )}
        </Paper>
      </Container>
    </ContentLayout>
  );
};

/**
 * Stuck Point Comment List
 * @param {String} entityId
 * @param {String} type
 * @returns
 */
export const StuckPointComments = ({ entityId, entityType, entityTag }) => {
  const {
    data: comments = [],
    isLoading,
    isFetching,
  } = useGetCommentsQuery({ entityId, entityType, order_by: 'created_at,desc;' });

  return (
    <>
      {isLoading || isFetching ? <LinearProgress /> : null}
      {comments?.map((comment) => (
        <DisplayComment
          comment={comment}
          key={comment?.id}
          meta={{ entityId, entityType, entityTag }}
        />
      ))}
    </>
  );
};

const StuckPointCardSkeleton = () => {
  return (
    <Grid container px={2} py={1} spacing={1}>
      <Grid item xs={12}>
        <Skeleton height={40} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton height={40} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton height={40} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton height={40} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton height={40} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton height={200} variant="rectangular" />
      </Grid>
    </Grid>
  );
};

export default StuckPointDetails;
