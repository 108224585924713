import { Box, Container, Typography } from '@mui/material';
import { ContentLayout } from 'components/layouts';
import { useParams } from 'react-router-dom';
import { ProjectCompaniesTable } from '../components/ProjectCompaniesTable';

export const ProjectUsers = () => {
  const projectId = Number(useParams().projectId) || undefined;
  ;

  return (
    <ContentLayout>
      <Container maxWidth={'xl'}>
        <Box pt={2}>
          <Typography variant={'h4'}>Companies Who Can Access This Project</Typography>
          <ProjectCompaniesTable projectId={projectId} />
        </Box>
      </Container>
    </ContentLayout>
  );
};
