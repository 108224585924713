import { StyledDialog } from '@blackhyve/common';
import {
  Box,
  Grid,
  Container,
  Button,
  Paper,
  Typography,
  TableContainer,
  CircularProgress,
  FormLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { ContentLayout } from 'components/layouts';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { InvoiceFormDialog } from '../components/ContractsShowInvoices';
import FileList from '../components/FileList';
import { FileUploadDialog } from '../components/FileUploadDialog';
import {
  formatDecimals,
  formatMoney,
  MoneyInput,
  PercentInput,
  unformatMoney,
} from '../components/NumericControls';
import { CostTypeOptions } from '../models/breakdown';
import { useGetBudgetByIdQuery } from '../store/budget.api';
import { useGetContractByIdQuery } from '../store/contracts.api';
import {
  useGetInvoiceLinesByInvoiceQuery,
  useUpdateInvoiceLineMutation,
} from '../store/invoice-lines.api';
import { useDeleteInvoiceFileMutation, useGetInvoiceByIdQuery } from '../store/invoices.api';
import { useUploadInvoiceFilesMutation } from '../store/invoices.api';

const InvoiceLinesList = ({ rows, onRowClicked }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Division</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Cost Code</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Cost Type</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Description</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Scheduled Amount</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Work Completed (Previous)</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Work Completed</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>New Stored Materials</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Total Completed to Date</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Balance to Finish</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Work Retention this Period</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Work Retention to Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((item) => (
            <TableRow key={item.id} hover sx={{ cursor: 'pointer' }} onClick={() => onRowClicked(item)}>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.invoiceable.budget_line.division}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.invoiceable.cost_code}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{CostTypeOptions.find(c => c.id === item.invoiceable.cost_type).name}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.invoiceable.description}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>${formatMoney(item.invoiceable.amount)}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>${formatMoney(item.previous_period_amount)}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>${formatMoney(item.work_completed_amount)}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>${formatMoney(item.stored_material_amount)}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>${formatMoney(item.completed_to_date_amount)}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>${formatMoney(item.balance_to_finish)}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>${formatMoney(item.work_retention_amount)} ({formatDecimals(item.work_retention_percent)}%)</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>${formatMoney(item.work_retention_date)}</TableCell>
            </TableRow>
          ))}
          {rows.length === 0 && (
            <TableRow><TableCell colSpan={12}>No Data</TableCell></TableRow>
          )}
          {rows.length > 0 && (
            <TableRow key="summary" sx={{ backgroundColor: '#18181b' }}>
              <TableCell colSpan={4} sx={{ color: 'white', textAlign: 'right' }}>Totals</TableCell>
              <TableCell sx={{ color: 'white' }}>${formatMoney(rows.reduce((acc, item) => acc + item.invoiceable.amount, 0))}</TableCell>
              <TableCell sx={{ color: 'white' }}>${formatMoney(rows.reduce((acc, item) => acc + item.previous_period_amount, 0))}</TableCell>
              <TableCell sx={{ color: 'white' }}>${formatMoney(rows.reduce((acc, item) => acc + item.work_completed_amount, 0))}</TableCell>
              <TableCell sx={{ color: 'white' }}>${formatMoney(rows.reduce((acc, item) => acc + item.stored_material_amount, 0))}</TableCell>
              <TableCell sx={{ color: 'white' }}>${formatMoney(rows.reduce((acc, item) => acc + item.completed_to_date_amount, 0))}</TableCell>
              <TableCell sx={{ color: 'white' }}>${formatMoney(rows.reduce((acc, item) => acc + item.balance_to_finish, 0))}</TableCell>
              <TableCell sx={{ color: 'white' }}>${formatMoney(rows.reduce((acc, item) => acc + item.work_retention_amount, 0))}</TableCell>
              <TableCell sx={{ color: 'white' }}>${formatMoney(rows.reduce((acc, item) => acc + item.work_retention_date, 0))}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const InvoiceDetails = ({ invoice }) => {
  const { id, invoice_number, submitted_on, billing_period, total, media } = invoice;

  const [deleteInvoiceFile] = useDeleteInvoiceFileMutation();

  const deleteFile = async (file) => {
    if (window.confirm('Are you sure you want to delete this file?')) {
      await deleteInvoiceFile({ id, file: file.id }).unwrap();
    }
  };
  return (
    <Grid container item p={2} xs={12}>
      <Grid container item display="block" xs={6}>
        <div>
          <Typography gutterBottom variant="h6">
            Invoice #
          </Typography>
          <Typography gutterBottom pl={2} variant="body1">
            {invoice_number}
          </Typography>
        </div>
        <div>
          <Typography gutterBottom variant="h6">
            Submission Date
          </Typography>
          <Typography gutterBottom pl={2} variant="body1">
            {submitted_on}
          </Typography>
        </div>
      </Grid>
      <Grid container item display="block" xs={6}>
        <div>
          <Typography gutterBottom variant="h6">
            Billing Period
          </Typography>
          <Typography gutterBottom pl={2} variant="body1">
            {billing_period}
          </Typography>
        </div>
        <div>
          <Typography gutterBottom variant="h6">
            Total
          </Typography>
          <Typography gutterBottom pl={2} variant="body1">
            {`$${formatMoney(total)}`}
          </Typography>
        </div>
      </Grid>

      {media.length > 0 && (
        <Grid item xs={12}>
          <Typography gutterBottom variant="h6">
            Files
          </Typography>
          <FileList files={media} onDelete={deleteFile} />
        </Grid>
      )}
    </Grid>
  );
};

const initialState = {
  amount: '',
  work_completed_amount: '',
  stored_material_amount: '',
  work_retention_amount: '',
  work_retention_percent: '0',
};

const InvoiceLineFormDialog = ({ creating = false, open, handleClose, line }) => {
  const { watch, control, handleSubmit, reset, setValue } = useForm({
    defaultValues: { ...initialState, ...(line ?? {}) },
  });

  useEffect(() => {
    reset({ ...initialState, ...(line ?? {}) });
  }, [line, reset]);

  const cost_type = line?.invoiceable?.cost_type;

  const [updateInvoiceLine, { isLoading }] = useUpdateInvoiceLineMutation();

  async function handleSave(data) {
    if (line?.id) {
      await updateInvoiceLine({
        ...data,
        amount: unformatMoney(data.amount, true),
        work_completed_amount: unformatMoney(data.work_completed_amount, true),
        stored_material_amount: unformatMoney(data.stored_material_amount, true),
        work_retention_amount: unformatMoney(data.work_retention_amount, true),
      })
        .unwrap()
        .then(() => {
          reset();
          handleClose();
        });
    }
  }

  useEffect(() => {
    const watcher = watch((state, { name, type }) => {
      if (!type) return;

      let [amount, work_retention_amount, work_completed_amount, stored_material_amount] = [
        unformatMoney(state.amount),
        unformatMoney(state.work_retention_amount),
        unformatMoney(state.work_completed_amount),
        unformatMoney(state.stored_material_amount),
      ];

      if (['work_completed_amount', 'stored_material_amount'].includes(name)) {
        setValue('amount', formatMoney(work_completed_amount + stored_material_amount, false));
      }

      if (['work_retention_percent'].includes(name)) {
        setValue(
          'work_retention_amount',
          formatMoney(amount * (state.work_retention_percent / 100), false)
        );
      }

      if (['work_retention_amount'].includes(name)) {
        setValue(
          'work_retention_percent',
          formatDecimals((work_retention_amount / Math.max(amount, 1)) * 100)
        );
      }
    });

    return () => {
      watcher.unsubscribe();
    };
  }, [watch, setValue, cost_type]);

  const { amount } = watch();

  return (
    <StyledDialog
      DialogContentProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
      handleClose={() => handleClose()}
      open={open}
      title={creating ? 'Create Invoice Line' : 'Edit Invoice Line'}
      actions={
        <>
          <Button disabled={isLoading} onClick={() => handleClose()}>
            Close
          </Button>
          <Button
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={'1rem'} />}
            variant={'contained'}
            onClick={handleSubmit(handleSave)}
          >
            {creating ? 'Add' : 'Save'}
          </Button>
        </>
      }
    >
      <Grid container item spacing={1} xs={12}>
        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Work Completed Amount ($)</FormLabel>
            <Controller
              control={control}
              name="work_completed_amount"
              rules={{ required: 'Cost Code is required' }}
              render={({ field, fieldState: { error } }) => (
                <MoneyInput {...field} error={error} setValue={setValue} />
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>New Stored Materials ($)</FormLabel>
            <Controller
              control={control}
              name="stored_material_amount"
              rules={{ required: 'New Stored Materials is required' }}
              render={({ field, fieldState: { error } }) => (
                <MoneyInput {...field} error={error} setValue={setValue} />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <FormLabel>Amount ($)</FormLabel>
          <Controller
            control={control}
            name="amount"
            rules={{ required: 'Amount is required' }}
            render={({ field, fieldState: { error } }) => (
              <MoneyInput {...field} disabled error={error} setValue={setValue} />
            )}
          />
        </Grid>
        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Work Retention ($)</FormLabel>
            <Controller
              control={control}
              name="work_retention_amount"
              rules={{ required: 'Work Retention Amount field is required' }}
              render={({ field, fieldState: { error } }) => (
                <MoneyInput
                  {...field}
                  defaultMax={unformatMoney(amount)}
                  error={error}
                  setValue={setValue}
                />
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Work Retention (%)</FormLabel>
            <Controller
              control={control}
              name="work_retention_percent"
              rules={{ required: 'Work Retention % field is required' }}
              render={({ field, fieldState: { error } }) => (
                <PercentInput {...field} fullWidth error={error} />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};
const InvoicesShow = () => {
  const { workspaceId, projectId, budgetId, contractId, invoiceId } = useParams();
  const { data: budget, isLoading: isLoadingBudget } = useGetBudgetByIdQuery({
    budgetId: +budgetId,
  });
  const { data: contract, isLoading: isLoadingContract } = useGetContractByIdQuery({
    contractId: +contractId,
  });
  const { data: invoice, isLoading: isLoadingInvoice } = useGetInvoiceByIdQuery({
    id: +invoiceId,
  });

  const [editInvoiceDialog, setEditInvoiceDialog] = useState(false);

  const { data: lines = [] } = useGetInvoiceLinesByInvoiceQuery({ invoiceId });

  const changeOrderLines = lines.filter((line) => line.invoiceable_type === 'change_order_lines');
  const contractLines = lines.filter((line) => line.invoiceable_type === 'contract_line');

  const [editInvoiceLineDialog, setEditInvoiceLineDialog] = useState({ show: false, line: null });

  const [fileUploadDialog, setFileUploadsDialog] = useState(false);

  if (isLoadingBudget || isLoadingContract || isLoadingInvoice) {
    return <>Loading...</>;
  }
  return (
    <ContentLayout>
      <Container maxWidth={'xl'}>
        <Grid container item alignItems="center">
          <Box pt={2}>
            <Typography variant={'h4'}>
              <Breadcrumbs
                links={[
                  {
                    to: `/workspace/${workspaceId}/projects/${projectId}/budgets`,
                    text: 'Budgets',
                  },
                  {
                    to: `/workspace/${workspaceId}/projects/${projectId}/budgets/${budgetId}`,
                    text: budget.name,
                  },
                  {
                    to: `/workspace/${workspaceId}/projects/${projectId}/budgets/${budgetId}/contracts/${contractId}`,
                    text: contract.title,
                  },
                  { text: invoice.invoice_number },
                ]}
              />
            </Typography>
          </Box>
          <Box ml={'auto'} />

          <Box>
            <Button size="small" variant="text" onClick={() => setFileUploadsDialog(true)}>
              Upload Files
            </Button>

            <Button size="small" variant="text" onClick={() => setEditInvoiceDialog(true)}>
              Edit
            </Button>

            <InvoiceFormDialog
              contractId={contractId}
              handleClose={() => setEditInvoiceDialog(false)}
              invoice={invoice}
              open={editInvoiceDialog}
            />

            <FileUploadDialog
              handleClose={() => setFileUploadsDialog(false)}
              model={invoice}
              mutation={useUploadInvoiceFilesMutation}
              open={fileUploadDialog}
            />
          </Box>
        </Grid>
        <Box maxWidth={600} pt={2}>
          <Paper elevation={2}>
            <InvoiceDetails invoice={invoice} />
          </Paper>
        </Box>
        <Box pb={6} pt={6}>
          <Typography variant={'h5'}> Schedule of values </Typography>

          <Paper elevation={2}>
            <InvoiceLinesList
              rows={contractLines}
              onRowClicked={(line) => {
                setEditInvoiceLineDialog({ show: true, line });
              }}
            />
          </Paper>
        </Box>

        {changeOrderLines.length !== 0 && (
          <Box pb={6} pt={6}>
            <Typography variant={'h5'}> Change Orders </Typography>

            <Paper elevation={2}>
              <InvoiceLinesList
                rows={changeOrderLines}
                onRowClicked={(line) => {
                  setEditInvoiceLineDialog({ show: true, line });
                }}
              />
            </Paper>
          </Box>
        )}

        <InvoiceLineFormDialog
          handleClose={() => setEditInvoiceLineDialog({ show: false, line: null })}
          invoice={invoice}
          open={editInvoiceLineDialog.show}
          line={{
            ...editInvoiceLineDialog.line,
            amount: formatMoney(editInvoiceLineDialog.line?.amount),
            work_completed_amount: formatMoney(editInvoiceLineDialog.line?.work_completed_amount),
            stored_material_amount: formatMoney(editInvoiceLineDialog.line?.stored_material_amount),
            work_retention_amount: formatMoney(editInvoiceLineDialog.line?.work_retention_amount),
          }}
        />
      </Container>
    </ContentLayout>
  );
};

export default InvoicesShow;
