import { useState } from "react";
import SvgDeleteIconRed from "../../icons/Delete";
import { MenuItem } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ConfirmDeleteDialog from "../DialogBox/ConfirmDeleteDialog";

const useStyles = makeStyles({
  deleteMenu: {
    color: "red",
    fontSize: "13px",
    textTransform: "capitalize",
    "& svg": {
      width: 20,
      marginRight: 5,
    },
  },
});

/**
 * Delete menu item with confirmation
 * @param {*} props
 * @param {'task' | 'package' | 'phase'} props.type - Type of object to delete
 * @param {Number} props.id - Id of object to delete
 * @param {Object} props.handleCloseMenu - Close option menu popover
 * @param {Object} props.handleDelete - Close option menu popover
 * @returns
 */
const DeleteMenuItem = (props) => {
  const classes = useStyles();

  const { id, disabled, type, handleCloseMenu, handleDelete } = props;

  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleOpenConfirm = (event) => {
    event.stopPropagation();
    setConfirmDelete(true);
  };

  const handleClick = () => {
    handleDelete(id);
    handleCloseMenu();
  };

  if (!disabled) {
    return (
      <>
        <MenuItem className={classes.deleteMenu} onClick={handleOpenConfirm}>
          <SvgDeleteIconRed color={"red"} />
          Delete {type}
        </MenuItem>
        <ConfirmDeleteDialog
          handleClose={() => setConfirmDelete(false)}
          handleDelete={handleClick}
          item={`${type}`}
          open={confirmDelete}
        />
      </>
    );
  }
};

export default DeleteMenuItem;
