
import { useRunAutoSchedulerJobMutation } from 'features/projects/store/project.api';
import { useLocation } from 'react-router-dom';

const useAutoSchedulerJob = () => {
  const [runAutoSchedulerJob] = useRunAutoSchedulerJobMutation();
  const pageLocationRoute = useLocation();

  const checkAndRunJob = (projectId) => {
    if (!pageLocationRoute.pathname.includes(`projects/${projectId}/planning-board/gantt`)) {
      runAutoSchedulerJob(projectId);
    }
  };

  return checkAndRunJob;
};

export default useAutoSchedulerJob;