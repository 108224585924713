import ganttStore from '../ganttStore';

export const defaultTaskRender = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  return function (item, defaultRender) {
    const sizes = gantt.getTaskPosition(item, item.start_date, item.scheduled_end_date);

    let defaultDom = defaultRender(item);
    if (defaultDom) {
      defaultDom.style.left = sizes.left + 3 + 'px';
      defaultDom.style.width = sizes.width > 8 ? sizes.width - 6 + 'px' : sizes.width + 'px';
      // if (item.end_date < item.forecasted_end_date) {
      //   const targetSizes = gantt.getTaskPosition(item, item.end_date, item.forecasted_end_date);
      //   let textDom = defaultDom.querySelector('.gantt_side_content.gantt_right');
      //   if (textDom) {
      //     textDom.style.left = sizes.width + targetSizes.width + 'px';
      //   }
      // }
    }

    return defaultDom;
  };
};

export const setTypeRender = (type, typeRender, ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  if (type && typeRender) {
    gantt.config.type_renderers[gantt.config.types[type]] = typeRender(ganttId);
  } else {
    gantt.config.type_renderers[gantt.config.types.task] = defaultTaskRender(ganttId);
  }
};

export const setTaskTemplate = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  gantt.templates.task_end_date = function (date) {
    return gantt.templates.task_date(new Date(date.valueOf() - 1));
  };

  gantt.templates.task_text = function () {
    return '';
  };

  gantt.templates.format_date = function (date) {
    return date;
  };

  gantt.templates.rightside_text = function (start, end, task) {
    const displayOptions = gantt?.config?.display;

    return `<div class='task-bar-label'>
      ${
        displayOptions
          ? Object.values(displayOptions)
              .filter((option) => Boolean(option?.enabled))
              .map(({ render }) => {
                return render(task);
              })
              .filter((display) => display !== '')
              .join('')
          : ''
      }
    </div > `;
  };
};

export const projectTaskRenderer = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  return function (item, defaultRender) {
    const sizes = gantt.getTaskPosition(item, item.start_date, item.scheduled_end_date);

    const defaultDom = defaultRender(item);
    if (defaultDom) {
      defaultDom.style.left = sizes.left + 3 + 'px';
      console.log('Class =>', defaultDom.classList);
      defaultDom.classList.add('custom-project');

      defaultDom.insertAdjacentHTML(
        'afterbegin',
        "<div class='project-left'></div><div class='project-right'></div>"
      );
    }

    return defaultDom;
  };
};
