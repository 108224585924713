import SvgPercentIcon from '@blackhyve/common/src/icons/Percent';
import TimelineIcon from '@mui/icons-material/Timeline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import DependencyAccordion from 'components/drawer/DependencyAccordion';
import SettingRow from 'components/drawer/SettingRow';
import TaskDates from 'components/drawer/TaskDates';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import TaskFeedList from 'components/taskFeeds/TaskFeedList';
import CreateComment from 'features/comments/components/CreateComment';
import { StyledChip, StyledDivider, StyledSectionTitle } from 'features/drawers/utils/drawerStyle';
import InlineEdit from 'features/gantt/components/InlineEdit';
import TaskDetailDrawer from 'features/gantt/components/TaskDetailDrawer';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { fetchTaskFeeds } from 'slices/taskFeedSlice';
import { STATUS_COLOR } from '../utils/drawerHelper';
import CompanyAccordion from './CompanyAccordion';
import SelectLBS from './SelectLBS';
import TradeAccordion from './TradeAccordion';

/**
 * Planning board task drawer
 * @param {String} ganttId
 * @returns
 */
const PlanningBoardTaskDrawer = ({ ganttId, disableEdit }) => {
  const dispatch = useDispatch();
  const [taskId, setTaskId] = useState(null);
  const [updateFeeds, setUpdateFeeds] = useState(false);
  const [gantt, setGantt] = useState(ganttStore.getGantt(ganttId));
  const [searchParams, setSearchParams] = useSearchParams();
  const goToTaskId = searchParams.get('task');

  useEffect(() => {
    setTimeout(() => {
      if (gantt?.isTaskExists(goToTaskId)) {
        gantt.showTask(goToTaskId);
        gantt.selectTask(goToTaskId);
        setTaskId(goToTaskId);
        setSearchParams({}, { replace: true });
      } else if (!!goToTaskId) {
        setTaskId(null);
        gantt.message({
          type: 'error',
          text: 'Task not found. It may have been deleted or you no longer have access to it',
          expire: 10000,
        });
        setSearchParams({}, { replace: true });
      }
    }, 100);
  }, [gantt, goToTaskId, searchParams, setSearchParams, taskId]);

  const handleTaskClick = (event) => {
    if (
      typeof event.target.className !== 'object' &&
      event.target?.className?.includes('targetEndDate')
    ) {
      const taskId = event.target?.getAttribute('data-id');
      setTaskId(taskId);
    }
  };

  useEffect(() => {
    setGantt(ganttStore.getGantt(ganttId));
  }, [ganttId]);

  useEffect(() => {
    if (gantt) {
      const handleClose = () => setTaskId(null);
      gantt.attachEvent(
        'onTaskClick',
        (id, event) => {
          const node = event.target;
          if (
            typeof node?.className === 'string' &&
            (node?.className?.includes('gantt_task_content') ||
              node?.className?.includes('task-name') ||
              node?.className?.includes('task-location') ||
              node?.className?.includes('task-zone') ||
              node?.className?.includes('task-area') ||
              node?.className?.includes('task-status'))
          ) {
            setTaskId(parseInt(id));
            gantt.attachEvent(
              'onBeforeTaskDrag',
              () => {
                handleClose();
                return true;
              },
              { id: 'onBeforeTaskDrag' }
            );
            return true;
          } else {
            handleClose();
            return true;
          }
        },
        { id: 'onTaskClick' }
      );

      gantt.attachEvent(
        'onEmptyClick',
        (id, e) => {
          handleClose();
        },
        { id: 'onEmptyClick' }
      );

      gantt.attachEvent(
        'onBeforeLinkAdd',
        (id, e) => {
          handleClose();
        },
        { id: 'onBeforeLinkAdd' }
      );

      gantt.attachEvent(
        'onTaskIdChange',
        function (id, new_id) {
          if (parseInt(id) === parseInt(taskId)) {
            setTaskId(parseInt(new_id));
          }
        },
        { id: 'onTaskIdChange' }
      );

      document.addEventListener('click', handleTaskClick);
      return () => {
        gantt.detachEvent('onTaskClick');
        gantt.detachEvent('onEmptyClick');
        gantt.detachEvent('onBeforeLinkAdd');
        gantt.detachEvent('onTaskIdChange');
        document.removeEventListener('click', handleTaskClick);
      };
    }
  }, [gantt, taskId]);

  useEffect(() => {
    if (updateFeeds) {
      setUpdateFeeds(false);
    }
  }, [updateFeeds]);

  const handleSaveName = (newValue) => {
    const task = gantt.getTask(taskId);
    if (gantt?.ext?.undo) {
      gantt.ext.undo.saveState(taskId, 'task');
    }
    task.text = newValue;
    gantt.updateTask(task.id);
    setUpdateFeeds(true);
  };

  const handleUpdate = useCallback(
    (newTaskValues) => {
      const task = gantt.getTask(taskId);
      if (gantt?.ext?.undo) {
        gantt.ext.undo.saveState(taskId, 'task');
      }
      const requestParameter = { id: taskId, ...newTaskValues, project_id: task.project_id };
      for (const key in requestParameter) {
        task[key] = requestParameter[key];
      }
      if (requestParameter.color) {
        task.task_color = requestParameter.color;
      }
      const dateFields = ['start_date', 'end_date', 'scheduled_end_date'];
      dateFields.forEach((field) => {
        if (requestParameter[field]) {
          task[field] = gantt.date.parseDate(requestParameter[field], '%Y-%m-%d');
        }
      });

      gantt.updateTask(task.id);
      setUpdateFeeds(true);
    },
    [gantt, taskId]
  );

  const handleUpdateCompanies = ({ companies = [] }) => {
    const task = gantt.getTask(taskId);
    if (gantt?.ext?.undo) {
      gantt.ext.undo.saveState(taskId, 'task');
    }
    task.companies = companies;
    task.contacts = task.contacts.filter((contact) =>
      Boolean(companies.find(({ id }) => id === contact.company_id))
    );
    gantt.updateTask(task.id);
    setUpdateFeeds(true);
  };

  const handleUpdateTrades = ({ trades }) => {
    const task = gantt.getTask(taskId);
    if (gantt?.ext?.undo) {
      gantt.ext.undo.saveState(taskId, 'task');
    }
    task.trades = trades;
    gantt.updateTask(task.id);
    setUpdateFeeds(true);
  };

  const handleCreateCommentSuccess = () => {
    dispatch(fetchTaskFeeds({ id: taskId, with_children: true }));
  };

  if (!gantt || !taskId || !gantt.getTaskNode(taskId) || taskId === -1) {
    return null;
  }
  const task = gantt.getTask(taskId);
  if (task.$new) {
    gantt.message({
      type: 'info',
      text: 'Task is being is still being created, please wait',
    });
    return null;
  }

  if (task.$virtual) {
    return null;
  }

  const isDisabled = disableEdit || task?.readonly || gantt?.config?.readonly;

  return (
    <TaskDetailDrawer open={taskId} top={'50px'} width={450}>
      <Grid container spacing={1}>
        <Grid container item alignItems="center" xs={12}>
          <StyledSectionTitle fontSize={11}>{task?.uuid}</StyledSectionTitle>
          <StyledChip
            backgroundColor={STATUS_COLOR[task?.status ? task?.status : 'todo']}
            style={{ marginLeft: '5px' }}
          >
            {task?.status}
          </StyledChip>
        </Grid>
        <Grid item xs={12}>
          <InlineEdit
            disabled={isDisabled}
            handleSave={handleSaveName}
            isMultiline={false}
            value={task?.text}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12}>
          <SvgPercentIcon style={{ marginRight: 5 }} />
          <StyledSectionTitle fontSize={14}>Progress</StyledSectionTitle>
          <Box alignItems="center" display="flex" width={'100%'}>
            <Box mr={1} width="70%">
              <LinearProgress
                style={{ height: 7, borderRadius: 3 }}
                value={task?.percent_complete ? Math.round(task?.percent_complete * 100) : 0}
                variant="determinate"
              />
            </Box>
            <StyledSectionTitle color="textSecondary" variant="body2">{`${
              task?.percent_complete ? Math.round(task?.percent_complete * 100) : 0
            }%`}</StyledSectionTitle>
          </Box>
        </Grid>
        <Grid container item spacing={1} xs={12}>
          <TaskDates disableEdit={isDisabled} ganttId={ganttId} taskId={taskId} />
        </Grid>
        <Grid item xs={12}>
          <StyledDivider orientation="horizontal" />
        </Grid>
        <Grid container item spacing={1} xs={12}>
          <SelectLBS
            disableEdit={isDisabled}
            ganttId={ganttId}
            handleSave={handleUpdate}
            projectId={task.project_id}
            taskId={taskId}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledDivider orientation="horizontal" />
        </Grid>
        <Grid container item spacing={1} xs={12}>
          <SettingRow
            disableEdit={isDisabled}
            ganttId={ganttId}
            handleSave={handleUpdate}
            taskId={taskId}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledDivider orientation="horizontal" />
        </Grid>
        {gantt.getTask(taskId).type !== gantt.config.types.project && (
          <Grid item xs={12}>
            <DependencyAccordion
              disableEdit={isDisabled}
              ganttId={ganttId}
              projectId={task.project_id}
              taskId={taskId}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TradeAccordion
            disableEdit={isDisabled}
            ganttId={ganttId}
            handleSave={handleUpdateTrades}
            taskId={taskId}
          />
        </Grid>
        <Grid item xs={12}>
          <CompanyAccordion
            disableEdit={isDisabled}
            gantt={gantt}
            handleSave={handleUpdateCompanies}
            taskId={taskId}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledDivider orientation="horizontal" />
        </Grid>
        <Grid item xs={12}>
          <CreateComment
            meta={{ entityId: taskId, entityType: 'tasks', entityTag: 'Task' }}
            onSuccess={handleCreateCommentSuccess}
          />
        </Grid>
        <Grid item style={{ marginTop: 5 }} xs={12}>
          <Grid container>
            <TimelineIcon style={{ marginRight: 5, color: '#9E9C9C' }} />
            <StyledSectionTitle>Activities</StyledSectionTitle>
            <Grid container item spacing={1} style={{ marginTop: 10 }}>
              <TaskFeedList
                id={taskId}
                projectId={task.project_id}
                updateFeeds={updateFeeds}
                withChildren={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TaskDetailDrawer>
  );
};

export default PlanningBoardTaskDrawer;
