import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import store from 'store';
import React from 'react';
import { selectCurrentUser } from 'features/auth';

function setBugsnagUser(bugsnagObject) {
  const state = store.getState();
  const user = selectCurrentUser(state);
  if (user) {
    bugsnagObject.setUser(user.id, user?.email, user?.first_name + ' ' + user?.last_name);
  }
}

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  appVersion: process.env.REACT_APP_VERSION,
  releaseStage: process.env.REACT_APP_RELEASE_STAGE,
  plugins: [new BugsnagPluginReact()],
  onError: function (event) {
    setBugsnagUser(event);
  },
  onSession: function (session) {
    setBugsnagUser(session);
  },
});

export const BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
