import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import flatMapDeep from 'lodash/flatMapDeep';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import ExpandIcon from '@mui/icons-material/Expand';
import { useEffect, useState, memo } from 'react';
import SearchBox from 'components/search/SearchBox';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ActionBar } from 'components/navigation/ActionBar';
import FormControlLabel from '@mui/material/FormControlLabel';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function getAllNodeIds(locations) {
  const allIds = [];

  function selectChildren(parent) {
    allIds.push(`${parent.type}-${parent.id}`);
    if (parent?.children?.length) {
      parent.children?.forEach(child => selectChildren(child));
    }
  }

  locations?.forEach(location => selectChildren(location));

  return allIds;
}

function searchByName(data, name) {
  return flatMapDeep(data, (item) => {
    if (item.name.toLowerCase().includes(name.toLowerCase())) {
      return item;
    } else if (isArray(item.children)) {
      return searchByName(item.children, name);
    }
    return [];
  });
};

/**
 * Jobwalk LBS Filter
 * @param {Object} handleSelect
 * @param {[String]} selectedIds
 * @param {[Object]} locations
 * @param {[Object]} lbsJobwalkCount
 * @returns 
 */
const JobwalkLbsFilter = ({ selectedIds, locations, handleSelect, lbsJobwalkCount }) => {

  const [searchLbs, setSearchLbs] = useState(undefined);
  const [expanded, setExpanded] = useState([]);
  const [selectAll, setSelectAll] = useState(true);


  const handleCheck = (event, type, id, isSelected) => {
    event.stopPropagation();
    const cloneLocations = [...locations];
    let cloneSelectedIds = [...selectedIds]

    const findAndUpdate = (element, nodeId, nodeType, isSelected) => {
      if (element.id === nodeId && element.type === nodeType) {
        cloneSelectedIds = isSelected ? [...cloneSelectedIds, `${element.type}-${element.id}`] : cloneSelectedIds?.filter((id) => id !== `${element.type}-${element.id}`)
        element.children?.length &&
          element.children.forEach((child) => updateChildren(child, isSelected));
        return;
      }

      element?.children?.length &&
        element?.children?.forEach((child) => findAndUpdate(child, nodeId, nodeType, isSelected));
    };

    const updateChildren = (child, isSelected) => {
      cloneSelectedIds = isSelected ? [...cloneSelectedIds, `${child.type}-${child.id}`] : cloneSelectedIds?.filter(id => id !== `${child.type}-${child.id}`)
      child?.children?.length &&
        child?.children?.forEach((nextChild) => updateChildren(nextChild, isSelected));
    };

    cloneLocations.forEach((location) => findAndUpdate(location, id, type, isSelected));
    handleSelect(cloneSelectedIds)

  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleExpandClick = () => {
    const allIds = getAllNodeIds(locations)
    setExpanded(expanded.length === 0 ? allIds : []);
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value.trim();
    setSearchLbs(searchQuery);
  };

  const handleSelectAll = (event) => {
    const allIds = getAllNodeIds(locations);
    setSelectAll(event.target.checked);
    handleSelect(event.target.checked ? allIds : [])
  }

  const filteredLocations = searchLbs ? searchByName(locations, searchLbs) : locations;

  useEffect(() => {
    const allIds = getAllNodeIds(locations)
    if (allIds?.length === selectedIds?.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }

  }, [selectedIds, locations])



  return (
    <>
      <ActionBar pl={0} pr={0} sx={{ py: 1, px: 1 }}>
        <Grid container item alignContent={'center'} spacing={1}>
          <Grid gap={1} item xs={12}>
            <FormControlLabel control={<Checkbox checked={selectAll} />} label="Select All" onClick={handleSelectAll} />
            <Button
              size="small"
              startIcon={<ExpandIcon fontSize="inherit" />}
              variant="outlined"
              onClick={handleExpandClick}
            >
              {expanded.length ? 'Collpase' : 'Expand'}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <SearchBox
              bgcolor="grey.200"
              borderRadius={1}
              fullWidth={true}
              placeholder="Search By LBS"
              value={searchLbs}
              onChange={handleSearch}
            />
          </Grid>
        </Grid>
      </ActionBar>
      <Grid>
        <TreeView
          multiSelect
          aria-label="controlled"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          onNodeToggle={handleToggle}
        >
          {filteredLocations?.map((location) => {
            return <TreeViewRow handleCheck={handleCheck} item={location} key={location?.id} lbsJobwalkCount={lbsJobwalkCount} selectedIds={selectedIds} />;
          })}
        </TreeView>
      </Grid>
    </>
  );
};

const TreeViewRow = memo(({ item, handleCheck, selectedIds, lbsJobwalkCount }) => {
  const key = `${item.type}-${item.id}`
  const isSelected = selectedIds && selectedIds.includes(key);
  let completedJobwalks = 0;
  let totalJobwalks = 0;

  if (!isEmpty(lbsJobwalkCount)) {
    const data = item.id === 0 ? lbsJobwalkCount['unassigned'] : lbsJobwalkCount[key];
    [completedJobwalks, totalJobwalks] = data ? data.split('/') : [0, 0]
  }

  return (
    <TreeItem
      nodeId={key}
      label={
        <Grid container alignItems={'center'}>
          <Grid item xs={2}>
            <Checkbox
              disableRipple
              checked={isSelected}
              onClick={(event) => handleCheck(event, item?.type, item?.id, !isSelected)}
            />
          </Grid>
          <Grid item xs={8}>
            <Tooltip title={item?.name}>
              <Typography sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
              >{item?.name}</Typography>
            </Tooltip>
          </Grid>
          <Grid container item justifyContent={'flex-end'} xs={2}>
            <Typography><span style={{ fontWeight: 'bold' }}>{completedJobwalks || 0}</span> of <span style={{ fontWeight: 'bold' }}>{totalJobwalks || 0}</span></Typography>
          </Grid>
        </Grid>
      }
      onClick={(event) => event.stopPropagation()}
    >

      {item?.children
        ? item?.children?.map((nextItem) => {
          return <TreeViewRow handleCheck={handleCheck} item={nextItem} key={nextItem?.id} lbsJobwalkCount={lbsJobwalkCount} selectedIds={selectedIds} />;
        })
        : null}
    </TreeItem>
  );
});




export default memo(JobwalkLbsFilter);
