
import { useState } from 'react';
import Box from '@mui/material/Box';
import format from 'date-fns/format';
import Chip from '@mui/material/Chip';
import isEmpty from 'lodash/isEmpty';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import ReactQuill from 'react-quill';
import getYear from 'date-fns/getYear';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Edit from '@mui/icons-material/Edit';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import TaskFormComponent from './TaskFormComponent';
import { useTheme, useMediaQuery } from '@mui/material'
import { parseDate } from '@blackhyve/utilities/dates';
import PaletteIcon from '@mui/icons-material/Palette';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { ColorSelect } from 'components/form/ColorSelect';
import TradeIcon from '@blackhyve/common/src/icons/Trade';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import DescriptionIcon from '@mui/icons-material/Description';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CompanyIcon from '@blackhyve/common/src/icons/CompanyIcon';
import { useGetTradesQuery } from 'features/trades/api/trades.api';

const formatDate = (dateString) => {
  const date = parseDate(dateString);
  return getYear(date) === getYear(new Date()) ? format(date, 'MMM dd') : format(date, 'MM D yyyy');
};

const formFields = [
  {
    label: 'Scheduled Dates',
    icon: <DateRangeIcon fontSize="inherit" />,
    content: (task) => (
      <Typography>
        {task?.scheduled_start ? formatDate(task.scheduled_start) : ''} - {task?.scheduled_end ? formatDate(task.scheduled_end) : '-'}
      </Typography>
    ),
  },
  {
    label: 'Forecasted Dates',
    icon: <DateRangeIcon fontSize="inherit" />,
    content: (task) => (
      <Typography>
        {task?.forecasted_start ? formatDate(task.forecasted_start) : ''} - {task?.forecasted_end ? formatDate(task.forecasted_end) : '-'}
      </Typography>
    ),
  },
  {
    label: 'Responsible',
    icon: <PersonIcon fontSize="inherit" />,
    content: (task) => (
      <>
        {task?.responsible_users?.map(user => (
          <Chip
            avatar={<Avatar alt={user?.name} src={user?.profile_image?.original_url} />}
            key={user?.id}
            label={user?.name}
            variant="outlined"
          />
        ))}
      </>
    ),
  },
  {
    label: 'Contacts',
    icon: <CompanyIcon fontSize="inherit" />,
    content: (task) => (
      <>
        {task?.contacts?.length
          ? task.contacts.map(contact => (
            <Chip
              avatar={<CompanyIcon />}
              key={contact?.id}
              label={contact?.name}
              variant="outlined"
              sx={{
                '& .MuiChip-avatar': {
                  height: 20,
                  width: 20,
                  color: 'grey.500',
                },
              }}
            />
          ))
          : ''}
      </>
    ),
  },
  {
    label: 'Crew',
    icon: <GroupsIcon fontSize="inherit" />,
    content: (task) => <Typography>{task?.crew_size}</Typography>,
  },
  {
    label: 'Trades',
    icon: <TradeIcon fontSize="inherit" />,
    content: (task, meta) => (
      <Typography>
        {!isEmpty(meta?.tradeEntities) && task?.trades?.length ? task.trades?.map(tradeId => meta?.tradeEntities[tradeId]?.name)?.join(', ') : ''}
      </Typography>
    ),
  },
  {
    label: 'Color',
    icon: <PaletteIcon fontSize="inherit" />,
    content: (task) => <Box sx={{ bgcolor: task.color, height: '8px', p: 1, width: '8px' }}></Box>,
  },
  {
    label: 'Constraint Type',
    icon: <ZoomInMapIcon fontSize="inherit" />,
    content: (task) => <Typography>{task?.constraint_type}</Typography>,
  },
  {
    label: 'Constraint Date',
    icon: <CalendarMonthIcon fontSize="inherit" />,
    content: (task) => (
      <Typography>{task?.constraint_date ? formatDate(task.constraint_date) : '-'}</Typography>
    ),
  },
];



/**
 * Detail Tab Component
 * @param {Object} task 
 * @returns 
 */
const DetailTabComponent = ({ task }) => {

  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleCloseEdit = () => setIsEditing(false);

  const { data: { entities: tradeEntities = {} } = {} } = useGetTradesQuery();


  return <Grid container item component={Paper} elevation={3} gap={1.5} pb={2} pt={1} px={2}>
    {isEditing ? <TaskFormComponent handleClose={handleCloseEdit} task={task} /> :
      <>
        <Grid container item xs={12}>
          <Grid container item xs={12}>
            <Grid container item justifyContent='space-between' xs={12}>
              <Box alignItems={'center'} display={'flex'}>
                {!isSmallScreen ? <IconButton sx={{ py: 0 }}><DescriptionIcon /></IconButton> : null}
                <FormLabel>Description:</FormLabel>
              </Box>
              <Button size='small' startIcon={<Edit />} variant='outlined' onClick={() => setIsEditing(true)}>Edit</Button>
            </Grid>
            <Grid item xs={12}>
              {isEmpty(task?.description) ? null :
                <ReactQuill
                  bounds="quill"
                  className={'comment'}
                  readOnly={true}
                  theme={'bubble'}
                  value={task?.description}
                />}
            </Grid>
          </Grid>
        </Grid>
        {formFields?.map((field, index) => (
          <Grid container item alignItems="center" key={index} xs={12}>
            <Grid container item alignItems="center" md={4} xs={6}>
              {!isSmallScreen ? <IconButton sx={{ py: 0 }}>{field.icon}</IconButton> : null}
              <FormLabel>{field.label}:</FormLabel>
            </Grid>
            <Grid item md={8} xs={6}>
              {field.content(task, { tradeEntities })}
            </Grid>
          </Grid>
        ))}
      </>}

  </Grid>
}




export default DetailTabComponent;