import isEqual from 'lodash/isEqual';
import Switch from '@mui/material/Switch';
import { useEffect, useState } from 'react';
import ganttStore from '../../ganttConfig/ganttStore';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { MenuButton } from 'assets/style-components/button';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { registerMenuState, updateMenuState } from 'slices/customViewSlice';
import { List, ListItem, ListItemText, ListSubheader, Popover } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

const initialState = {
  // scope: {
  //   displayText: 'Add Scope',
  //   enabled: true,
  //   render: (task) => {
  //     return task.is_scope ?
  //       reactElementToJSXString(ScopeIcon({ style: "margin-right: 5px" }))
  //       : ''
  //   }
  // },
  company: {
    displayText: 'Company',
    enabled: true,
    render: (task) => {
      return task?.companies?.length > 0
        ? `<div>
        ${task?.companies
          .map((company, index) => {
            return company?.logo?.thumb_url || company?.profile_image?.thumb_url
              ? `<img src="${
                  company?.logo?.thumb_url || company?.profile_image?.thumb_url
                }" style="width: 16px; height: 16px; border-radius: 20px; position: relative; vertical-align: middle; z-index:${
                  task?.companies?.length - index
                }; margin-left:${index > 0 ? '-8px' : '0px'}"/>`
              : `<div class="company-avatar" style="z-index:${
                  task?.companies?.length - index
                }; margin-left:${index > 0 ? '-8px' : '0px'}">
              ${company?.name?.charAt(0).toUpperCase()}${company?.name.charAt(1).toUpperCase()}
              </div>`;
          })
          .join('')}
      </div>`
        : '';
    },
  },
  taskName: {
    displayText: 'Task Name',
    groupTitle: true,
  },
  status: {
    displayText: 'Status',
    enabled: true,
    render: (task) => {
      if (task.status && task.status !== 'todo') {
        const capitalizedStatus =
          task.status.charAt(0).toUpperCase() + task.status.slice(1).toLowerCase();
        return `<span class='task-status'> - ${capitalizedStatus} - </span>`;
      } else {
        return '';
      }
    },
  },
  name: {
    displayText: 'Name',
    enabled: true,
    render: (task) => `<span class='task-name'>${task.text}</span>`,
  },
  location: {
    displayText: 'Location',
    enabled: true,
    render: (task, gantt) => {
      const lbsData = gantt?.constants?.lbsData;
      const locationName =
        task.location_id && !isEmpty(lbsData?.locations)
          ? lbsData?.locations[task.location_id]?.name
          : '';
      return locationName ? `<span class='task-location'> - ${locationName}</span>` : '';
    },
  },
  zone: {
    displayText: 'Zone',
    enabled: true,
    render: (task, gantt) => {
      const lbsData = gantt.constants.lbsData;
      const zoneName =
        task.zone_id && !isEmpty(lbsData?.zones) ? lbsData.zones[task.zone_id]?.name : '';
      return zoneName ? `<span class='task-zone'> - ${zoneName}</span>` : '';
    },
  },
  area: {
    displayText: 'Area',
    enabled: true,
    render: (task, gantt) => {
      const lbsData = gantt.constants.lbsData;
      const areaName =
        task.area_id && !isEmpty(lbsData?.areas) ? lbsData.areas[task.area_id]?.name : '';
      return areaName ? `<span class='task-area'> - ${areaName}</span>` : '';
    },
  },
};

const options = {};
Object.keys(initialState).forEach((key) => {
  if (initialState[key].enabled !== undefined) {
    options[key] = initialState[key].enabled;
  }
});

const DisplayMenu = ({ ganttId }) => {
  const dispatch = useDispatch();
  const gantt = ganttStore.getGantt(ganttId);

  const selectedOptions = useSelector((state) => state.customViews?.menus?.display?.currentValue);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (gantt && selectedOptions) {
      const display = Object.assign({ ...selectedOptions }, initialState);
      Object.entries(selectedOptions).forEach(([key, value]) => {
        if (!value) {
          delete display[key];
        }
      });
      gantt.config.display = display;
      gantt.render();
    }
  }, [selectedOptions, gantt, ganttId]);

  const handleToggleDisplayOption = (event, optionKey) => {
    dispatch(
      updateMenuState({
        name: 'display',
        value: { ...selectedOptions, [optionKey]: !selectedOptions[optionKey] },
      })
    );
  };

  useEffect(() => {
    dispatch(
      registerMenuState({
        name: 'display',
        value: options,
      })
    );
  }, [dispatch]);

  return (
    <>
      <MenuButton
        endIcon={Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />}
        isHighlighted={!isEqual(options, selectedOptions)}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        Display
      </MenuButton>
      {anchorEl !== null ? (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={Boolean(anchorEl)}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setAnchorEl(null)}
        >
          <List dense>
            {Object.keys(initialState).map((optionKey) => {
              const option = initialState[optionKey];
              const isEnabled = selectedOptions[optionKey];
              return (
                <>
                  {option.groupTitle ? (
                    <ListSubheader sx={{ fontWeight: 'bold' }}>{option.displayText}</ListSubheader>
                  ) : (
                    <ListItem
                      key={option.displayText}
                      secondaryAction={
                        !option.groupTitle ? (
                          <Switch
                            checked={isEnabled}
                            color="primary"
                            edge="end"
                            size="small"
                            onClick={(event) => handleToggleDisplayOption(event, optionKey)}
                          />
                        ) : (
                          ''
                        )
                      }
                    >
                      <ListItemText primary={option.displayText} />
                    </ListItem>
                  )}
                </>
              );
            })}
          </List>
        </Popover>
      ) : null}
    </>
  );
};

export default DisplayMenu;
