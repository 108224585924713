import { ContentLayout } from 'components/layouts';
import BackendProvider, { useBackend } from '../components/BackendProvider';
import GoodDataDashboard from '../components/GoodDataDashboard';
import { SecondaryNav } from 'components/navigation/SecondaryNav';
import DashboardSelectionMenu from '../components/DashboardSelectionMenu';
import { selectCurrentUserWorkspaceId } from 'features/auth';
import { useGetDashboardsQuery } from '../api/analytics';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const AnalyticsWindow = () => {
  const backend = useBackend();
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { data: dashboardData, isLoading } = useGetDashboardsQuery({ workspaceId });
  const [analyticsDashboardId, setAnalyticsDashboardId] = useState(null);
  const [analyticsWorkspaceId, setAnalyticsWorkspaceId] = useState(null);

  useEffect(() => {
    if (dashboardData?.length) {
      setAnalyticsDashboardId(dashboardData[0].dashboard_id);
      setAnalyticsWorkspaceId(dashboardData[0].workspace_id);
    }
  }, [dashboardData]);

  if (!isLoading && dashboardData) {
    return (
      <BackendProvider backend={backend}>
        <ContentLayout>
          <SecondaryNav>
            <DashboardSelectionMenu
              dashboardId={analyticsDashboardId}
              dashboards={dashboardData}
              label="Select Dashboard"
              setDashboardId={setAnalyticsDashboardId}
            />
          </SecondaryNav>

          <GoodDataDashboard
            dashboardId={analyticsDashboardId}
            workspaceId={analyticsWorkspaceId}
          />
        </ContentLayout>
      </BackendProvider>
    );
  }
};
