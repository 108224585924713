import { useState } from 'react';
import Button from '@mui/material/Button';
import ColorPalate from 'components/common/ColorPalate';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

/**
 * Task bulk edit color menu
 * @param {Object} handleUpdate
 * @returns
 */
const TaskBulkEditColorMenu = ({ handleUpdate }) => {
  const [anchorEl, setAnchorEl] = useState(undefined);

  const handleSave = (color) => {
    handleUpdate(color);
    setAnchorEl(undefined);
  };

  return (
    <>
      <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
        Color
        {anchorEl ? (
          <ArrowDropDown style={{ fontSize: 18 }} />
        ) : (
          <ArrowDropUp style={{ fontSize: 18 }} />
        )}
      </Button>
      {anchorEl ? (
        <ColorPalate
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(undefined)}
          handleSave={handleSave}
          open={Boolean(anchorEl)}
        />
      ) : null}
    </>
  );
};

export default TaskBulkEditColorMenu;
