import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { selectContactEntities } from 'features/contacts/store/contactsSlice';
import { workspaceAPI } from 'features/workspace/api/workspace.api';
import cloneDeep from 'lodash/cloneDeep';
import { selectCurrentUser } from '../../auth/store/authSlice';
import {
  checkEmailRegisteredAPI,
  checkRegistrationTokenAPI,
  fetchUsersAPI,
  getUserDetailsAPI,
  registerUserAPI,
  resendInviteAPI,
  updatePasswordAPI,
  updateUserAPI,
  uploadFileAPI,
  uploadProfileImageAPI,
} from '../api/UserServices';

/**
 * Fetch the list of all users from api
 */
export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (args = {}, { rejectWithValue }) => {
    const apiResponse = await fetchUsersAPI(args);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.error);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * Get user details
 * @param {Number} userId - user id
 */
export const fetchUser = createAsyncThunk(
  'users/fetchUser',
  async (userId, { rejectWithValue, requestId }) => {
    const apiResponse = await getUserDetailsAPI({ userId });
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.error);
    } else {
      return apiResponse.response.data;
    }
  },
  {
    condition: (userId, { getState, extra }) => {
      const { users } = getState();
      if (users.loading) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  }
);

/**
 * Update user details
 * @param {Object} userData - user object
 * @param {Number} userData.id - user id
 * @param {String} userData.first_name - first name of the user
 * @param {String} userData.last_name - last name of the user
 */
export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (userData, { rejectWithValue }) => {
    // userData.append('_method', 'patch');
    const apiResponse = await updateUserAPI(userData);
    console.log(apiResponse);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.error);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * Update password
 * @param {Object} requestParameters
 * @param {String} requestParameters.old_password
 * @param {String} requestParameters.new_password
 * @param {String} requestParameters.confirm_password
 */
export const updatePassword = createAsyncThunk(
  'users/updatePassword',
  async ({ old_password, new_password, confirm_password }, { rejectWithValue }) => {
    const apiResponse = await updatePasswordAPI({
      old_password,
      new_password,
      confirm_password,
      _method: 'patch',
    });
    if (apiResponse.error || apiResponse.response.errors) {
      return rejectWithValue(apiResponse.error ? apiResponse.error : apiResponse.response.errors);
    } else {
      return apiResponse.response;
    }
  }
);

/**
 * Register User
 */
export const registerUser = createAsyncThunk(
  'users/registerUser',
  async (requestParameter, { rejectWithValue }) => {
    const apiResponse = await registerUserAPI(requestParameter);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * Validate auth token and email
 */
export const checkRegistrationToken = createAsyncThunk(
  'users/checkRegistrationToken',
  async ({ token, email }, { rejectWithValue }) => {
    const apiResponse = await checkRegistrationTokenAPI({ token, email });
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      return apiResponse.response;
    }
  }
);

/**
 * Is email signed up
 */
export const checkEmailRegistered = createAsyncThunk(
  'users/checkEmailRegistered',
  async ({ email }, { rejectWithValue }) => {
    const apiResponse = await checkEmailRegisteredAPI({ email });
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      return apiResponse.response;
    }
  }
);

/**
 * Resend Invite
 * @param {String} requestParameter.id
 */
export const resendInvite = createAsyncThunk(
  'users/resendInvite',
  async (requestParameter, { rejectWithValue }) => {
    const apiResponse = await resendInviteAPI(requestParameter);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.error);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * Upload image
 * @param {FormData} formData
 * @param {String} formData.id
 * @param {String} formData.profile_image
 */
export const uploadProfileImage = createAsyncThunk(
  'users/uploadProfileImage',
  async (formData, { rejectWithValue }) => {
    formData.append('_method', 'patch');
    const apiResponse = await uploadProfileImageAPI(formData);
    if (apiResponse.error || apiResponse.response.errors) {
      return rejectWithValue(apiResponse.error ? apiResponse.error : apiResponse.response.errors);
    } else {
      return apiResponse.response;
    }
  }
);

/**
 * Upload file or media
 * @param {FormData} formData
 * @param {String} formData.id
 * @param {String} formData.file
 */
export const uploadFile = createAsyncThunk(
  'users/uploadFile',
  async (formData, { rejectWithValue }) => {
    formData.append('_method', 'post');
    const apiResponse = await uploadFileAPI(formData);
    if (apiResponse.error || apiResponse.response.errors) {
      return rejectWithValue(apiResponse.error ? apiResponse.error : apiResponse.response.errors);
    } else {
      return apiResponse.response.data;
    }
  }
);



const usersAdapter = createEntityAdapter({
  sortComparer: (prev, curr) => new Date(curr.last_name) - new Date(prev.last_name),
});

const initialState = usersAdapter.getInitialState({
  loading: false,
  success: false,
  error: null,
  invitedUserId: undefined,
  workspaces: undefined,
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearUsers: usersAdapter.removeAll,
    clearRegisterUser: (state) => {
      state.success = false;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    // Fetch users reducers
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      const data = action.payload.map((user) => {
        return { ...user, name: `${user.first_name} ${user.last_name}` };
      });
      usersAdapter.upsertMany(state, data);
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchUsers.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.loading = false;
      state.error =
        action.payload !== undefined
          ? action.payload
          : action?.error.message
            ? action.error.message
            : 'Error occurred while fetching users';
    });

    // Fetch user reducers
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      usersAdapter.upsertOne(state, {
        ...action.payload,
        name: `${action.payload.first_name} ${action.payload.last_name}`,
      });
      state.loading = false;
    });
    builder.addCase(fetchUser.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // Update user reducers
    builder.addCase(updateUser.fulfilled, (state, action) => {
      usersAdapter.upsertOne(state, {
        ...action.payload,
        name: `${action.payload.first_name} ${action.payload.last_name}`,
      });
      state.error = null;
      // state.loading = false;
    });
    builder.addCase(updateUser.pending, (state, action) => {
      // state.loading = true;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.error = action.payload;
      // state.loading = false;
    });

    // Register User
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(registerUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Resend Invite
    builder.addCase(resendInvite.fulfilled, (state, action) => {
      state.resendInvite = true;
    });

    // Upload Image
    builder.addCase(uploadProfileImage.fulfilled, (state, action) => {
      usersAdapter.upsertOne(state, action.payload.data);
    });

    builder.addMatcher(workspaceAPI.endpoints.setWorkspace.matchFulfilled, (state, action) => {
      usersAdapter.removeAll(state);
    });
  },
});

const usersReducer = usersSlice.reducer;
export default usersReducer;

export const { clearUsers, clearRegisterUser } = usersSlice.actions;

/** Selectors **/

export const {
  selectById: selectUserById,
  selectIds: selectUserIds,
  selectEntities: selectUsersEntities,
  selectAll: selectAllUsers,
  selectTotal: selectTotalUsers,
} = usersAdapter.getSelectors((state) => state.users);

export const selectUserOrContactById = createSelector(
  [
    (state) => selectUsersEntities(state),
    (state) => selectContactEntities(state),
    (state) => selectCurrentUser(state),
    (state, userId) => userId,
  ],
  (users, contacts, currentUser, userId) => {
    const allUsers = { ...users, ...contacts, [currentUser.id]: currentUser };
    return allUsers[userId] ? allUsers[userId] : {};
  }
);
