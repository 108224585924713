import {
  Avatar,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from '@mui/material';
import Box from '@mui/system/Box';
import { Clear } from '@mui/icons-material';
import { Alert, Autocomplete } from '@mui/material';
import SearchBox from 'components/search/SearchBox';
import { useState } from 'react';

export const SelectUsersList = ({ selected, handleSelect, users }) => {
  const [search, setSearch] = useState('');
  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().contains(search.toLowerCase())
  );

  return (
    <>
      <SearchBox
        fullWidth
        autoComplete={'off'}
        bgcolor={'grey.200'}
        borderRadius={1}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />
      <List>
        {filteredUsers.length > 0 ? (
          filteredUsers.map((user) => {
            const isSelected = selected.includes(user.id);
            return (
              <ListItemButton
                key={user.id}
                selected={isSelected}
                onClick={(event) => handleSelect(event.target.value, isSelected)}
              >
                <ListItemAvatar>
                  <Avatar src={user?.profile_image?.thumb_url}>{user.name[2]}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={user?.first_name + ' ' + user?.last_name} />
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={isSelected}
                    onClick={(event) => handleSelect(event.target.value, isSelected)}
                  />
                </ListItemSecondaryAction>
              </ListItemButton>
            );
          })
        ) : search === '' ? (
          <Alert severity={'info'}>No Users To Assign</Alert>
        ) : (
          <Alert severity={'info'}>No Matches Found For Current Search Query</Alert>
        )}
      </List>
    </>
  );
};

/**
 * @param {*} props
 * @param {Set} props.selectedContacts
 * @param {Function} props.setSelectedContacts
 * @returns JSX List of contacts with checkboxes
 */
export const SelectUsersAutocompleteList = ({
  selected,
  setSelected,
  disabled,
  users,
  isLoading,
}) => {
  return (
    <Box display={'flex'} flexDirection={'column'} height={'100%'}>
      <Autocomplete
        disableClearable
        disableCloseOnSelect
        fullWidth
        multiple
        autoFocus={false}
        disabled={disabled}
        getOptionLabel={(option) => option?.first_name + ' ' + option?.last_name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        limitTags={0}
        loading={isLoading}
        options={users}
        renderTags={() => null}
        value={selected}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder="Select Users"
            size="small"
            variant="outlined"
          />
        )}
        renderOption={(props, option, { selected }) => {
          return (
            <ListItemButton {...props}>
              <ListItemIcon>
                <Checkbox checked={selected} />
              </ListItemIcon>
              <ListItemText>{option?.first_name + ' ' + option?.last_name}</ListItemText>
            </ListItemButton>
          );
        }}
        onChange={(event, newValue) => {
          if (event.key !== 'Backspace') {
            setSelected(newValue);
          }
        }}
      />

      <Box dense component={List} maxHeight={'25vh'} sx={{ overflowY: 'auto', padding: '5px' }}>
        {selected?.map((user) => {
          return (
            <ListItem disableGutters key={user.id}>
              <ListItemAvatar>
                <Avatar src={user?.profile_image?.thumb_url} />
              </ListItemAvatar>
              <ListItemText primary={user?.first_name + ' ' + user?.last_name} />

              {!disabled && (
                <ListItemSecondaryAction>
                  <IconButton
                    size="large"
                    onClick={() => {
                      setSelected(selected.filter((selectedUser) => selectedUser.id !== user.id));
                    }}
                  >
                    <Clear />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
      </Box>
    </Box>
  );
};
