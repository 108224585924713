import { StyledDialog } from '@blackhyve/common';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Alert } from '@mui/material';
import SearchBox from 'components/search/SearchBox';
import { useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import {
  useAddProjectCompaniesMutation,
  useGetProjectCompaniesNotOnProjectQuery,
} from '../api/projectCompanies.api';

export const AddCompaniesDialog = ({ open, handleClose, projectId }) => {
  const { data: companies = [], isLoading } = useGetProjectCompaniesNotOnProjectQuery(
    { projectId },
    { skip: !open, refetchOnMountOrArgChange: true }
  );
  const [addCompanies, { isLoading: isAdding }] = useAddProjectCompaniesMutation();

  const [selected, setSelected] = useState({});
  const [search, setSearch] = useState('');

  const handleSelect = (selected) => {
    setSelected((prevState) => ({ ...prevState, ...selected }));
  };

  const onClose = () => {
    handleClose();
    setSelected({});
  };

  const handleAdd = async () => {
    const payload = Object.entries(selected).map(([companyId, users]) => ({
      company_id: companyId,
      users: Object.keys(users),
    }));
    await addCompanies({ projectId, companies: payload });
    onClose();
  };

  const filteredCompanies = companies
    .map(({ ...company }) => {
      company.users = company.users.filter((user) =>
        (user.first_name + user.last_name).toLowerCase().includes(search.toLowerCase())
      );
      if (search !== '') company.$isOpen = true;
      return company;
    })
    .filter(
      (company) =>
        company.users.length > 0 || company.name.toLowerCase().includes(search.toLowerCase())
    );

  return (
    <StyledDialog
      DialogContentProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
      handleClose={onClose}
      open={open}
      PaperProps={{ sx: { height: '100%' } }}
      title={'Add Companies'}
      actions={
        <>
          <Button disabled={isAdding} onClick={onClose}>
            Close
          </Button>
          <Button
            disabled={isAdding || Object.keys(selected).length < 1}
            endIcon={isAdding && <CircularProgress size={'1rem'} />}
            variant={'contained'}
            onClick={handleAdd}
          >
            Add
          </Button>
        </>
      }
    >
      <SearchBox
        autoComplete={'off'}
        bgcolor={'grey.200'}
        borderRadius={1}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />
      <Box flexGrow={1} height={'100%'} pt={1}>
        {isLoading ? (
          <Box alignItems={'center'} display={'flex'} height={'100%'} justifyContent={'center'}>
            <CircularProgress />
          </Box>
        ) : filteredCompanies.length > 0 ? (
          <Virtuoso
            data={filteredCompanies}
            style={{ height: '100%' }}
            itemContent={(index, company) => (
              <CompanyRow
                company={company}
                disabled={isAdding}
                handleSelect={handleSelect}
                selected={selected?.[company.id]}
              />
            )}
          />
        ) : companies.length > 0 ? (
          <Alert severity={'info'}>No Matches Found For Current Search Query</Alert>
        ) : (
          <Alert severity={'info'}>No Companies Available To Add</Alert>
        )}
      </Box>
    </StyledDialog>
  );
};

const CompanyRow = ({ company, handleSelect, selected, disabled }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onSelectAll = () => {
    handleSelect({
      [company.id]: company.users.reduce((selected, user) => {
        selected[user.id] = true;
        return selected;
      }, {}),
    });
  };

  const onSelect = (userId) => {
    const newSelected = selected ? { ...selected } : {};
    if (Object.hasOwn(newSelected, userId)) {
      delete newSelected[userId];
    } else {
      newSelected[userId] = true;
    }
    handleSelect({ [company.id]: newSelected });
  };

  const onSelectCompany = () => {
    if (selected) {
      handleSelect({ [company.id]: undefined });
    } else {
      handleSelect({ [company.id]: {} });
    }
  };

  return (
    <ListItem
      dense
      disableGutters
      divider
      component={'div'}
      key={company.id}
      style={{ padding: '0' }}
    >
      <Box disablePadding bgcolor={'background.paper'} component={List} width={'100%'}>
        <Box bgcolor={'background.paper'} position={'sticky'} top={'0px'} zIndex={1}>
          <ListItem disableGutters>
            <ListItemIcon>
              <Checkbox checked={Boolean(selected)} onClick={onSelectCompany} />
            </ListItemIcon>
            {company.name}
            <Box px={1}>
              <Link component={'button'} onClick={onSelectAll}>
                Select All
              </Link>
            </Box>
            {!company.$isOpen && (
              <ListItemSecondaryAction onClick={() => setIsExpanded(!isExpanded)}>
                <IconButton>{isExpanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        </Box>
        <Collapse mountOnEnter unmountOnExit in={isExpanded || company.$isOpen}>
          {company.users.map((user) => (
            <ListItemButton
              disabled={disabled}
              key={user.id}
              // selected={selected ? Object.hasOwn(selected, user.id) : false}
              onClick={() => onSelect(user.id)}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemIcon>
                <Checkbox checked={selected ? Object.hasOwn(selected, user.id) : false} />
              </ListItemIcon>
              <ListItemText primary={user.first_name + ' ' + user.last_name} />
            </ListItemButton>
          ))}
        </Collapse>
      </Box>
    </ListItem>
  );
};
