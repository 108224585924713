import { api } from 'api';
import times from 'lodash/times';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import { useSelector } from 'react-redux';
import { toDate, format } from 'date-fns';
import DoneIcon from '@mui/icons-material/Done';
import Divider from '@mui/material/Divider';
import Avatar from 'components/common/v3/Avatar';
import Skeleton from '@mui/material/Skeleton';
import ListItem from '@mui/material/ListItem';
import { CloseOutlined } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { selectUserOrContactById } from 'features/users';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { StyleCounterBadge, StyledDrawer } from 'features/drawers/utils/drawerStyle';
import { useGetActivityFeedNotificationsQuery } from '../api/activityFeed.api';
import { ActivityFeedDrawerSubTitle, CustomBadgeIcon } from '../assets/activityFeedStyle';
import { StyledLabelForNoResult } from 'assets/style-components/typography';
import { TranslateAuditContent } from 'components/taskFeeds/utils/taskFeedHelper';
import Tooltip from '@mui/material/Tooltip';
import { selectCurrentUserWorkspaceId } from 'features/auth';

/**
 * Activity Feed Drawer
 * @param {Boolean} open
 * @param {String} id
 * @param {Object} handleClose
 * @param {Object} queryParams
 * @returns
 */
const ActivityFeedDrawer = ({ open, id, handleClose, queryParams }) => {
  const { activityFeed } = api.endpoints.getActivityFeeds.useQueryState(queryParams, {
    selectFromResult: ({ data: activityFeeds, isFetching, isLoading }) => {
      return {
        activityFeed: activityFeeds?.data?.find((activityObj) => activityObj.id === id),
        isFetching,
        isLoading,
      };
    },
  });

  const { data: subscriberList, isFetching, isLoading } = useGetActivityFeedNotificationsQuery(id);
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { project } = api.endpoints.getProjects.useQueryState(
    { workspaceId },
    {
      selectFromResult: ({ data: projects, isFetching, isLoading }) => {
        return {
          project: projects?.find((project) => project.id === activityFeed?.project_id),
          isFetching,
          isLoading,
        };
      },
    }
  );

  const user = useSelector((state) => selectUserOrContactById(state, activityFeed?.user_id));

  const dateTime = activityFeed?.created_at
    ? format(toDate(new Date(activityFeed?.created_at)), 'MMMM dd, yyyy h:mm a')
    : '';

  let subHeading =
    activityFeed?.auditable_type === 'Comment'
      ? `Added a comment on ${activityFeed?.task?.name}`
      : activityFeed?.event === 'updated'
        ? `Updated task ${activityFeed?.task?.name}`
        : activityFeed?.event === 'sync'
          ? `Updated ${Object.hasOwn(activityFeed?.old_values, 'users') ? `assignment on ` : ''} ${
              activityFeed?.task?.name
            }`
          : activityFeed?.event === 'created'
            ? `Created a new task ${activityFeed?.task?.name}`
            : '';

  return (
    <StyledDrawer anchor="right" open={open} top={'50px'} variant="persistent" width={450}>
      <Grid container style={{ gap: 10 }}>
        <Grid item xs={12}>
          <Box alignItems="center" className="flex" justifyContent="space-between">
            <ActivityFeedDrawerSubTitle>
              {subHeading} from {project?.name ? project?.name : 'Project is Deleted'}
            </ActivityFeedDrawerSubTitle>
            <CloseOutlined color="disabled" style={{ cursor: 'pointer' }} onClick={handleClose} />
          </Box>
        </Grid>
        <Grid container item alignItems="center" xs={12}>
          <ActivityFeedDrawerHeader
            date={dateTime}
            firstName={user?.first_name}
            lastName={user?.last_name}
            profileImage={user?.profile_image?.original_url}
          />
        </Grid>
        <Grid item xs={12}>
          <TranslateAuditContent
            auditType={activityFeed?.auditable_type}
            event={activityFeed?.event}
            meta={{ stuckPoint: activityFeed?.stuck_point }}
            newValues={activityFeed?.new_values}
            oldValues={activityFeed?.old_values}
            task={{ ...activityFeed.task, project_id: activityFeed.project_id }}
          />
        </Grid>
        <Divider orientation="horizontal" style={{ width: '100%' }} />
        <Grid item xs={12}>
          <Box className="flex">
            <Typography style={{ fontWeight: 600 }}>Subscribers</Typography>
            <StyleCounterBadge
              count={subscriberList?.length ? subscriberList?.length : 0}
              style={{ marginLeft: '10px' }}
            >
              {subscriberList?.length}
            </StyleCounterBadge>
          </Box>

          <List>
            {isLoading || isFetching ? (
              times(10, (row) => {
                return <Skeleton className="skeleton-row" key={row} variant="text" />;
              })
            ) : subscriberList?.length ? (
              subscriberList?.map((subscriber) => {
                return (
                  <SubscriberListItem
                    activityFeedId={activityFeed?.id}
                    id={subscriber?.id}
                    key={subscriber?.id}
                  />
                );
              })
            ) : (
              <StyledLabelForNoResult style={{ justifyContent: 'flex-start' }}>
                No one is notified.
              </StyledLabelForNoResult>
            )}
          </List>
        </Grid>
      </Grid>
    </StyledDrawer>
  );
};

/**
 * Activity feed drawer header
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} profileImage
 * @param {String} date
 * @returns
 */
const ActivityFeedDrawerHeader = ({ firstName, lastName, profileImage, date }) => {
  return (
    <>
      <Box alignItems="center" className="flex" justifyContent="space-between">
        <Avatar
          firstName={firstName || 'Deleted User'}
          icon={profileImage}
          lastName={lastName}
          avatarStyle={{
            height: '40px',
            width: '40px',
            fontSize: '14px',
            marginRight: '5px',
          }}
        />
      </Box>
      <Box style={{ marginLeft: '5px' }}>
        <Typography variant="inherit">
          {firstName ? firstName : 'Deleted User'} {lastName}
        </Typography>
        <Typography style={{ color: '#9E9C9C' }} variant="body2">
          {date}
        </Typography>
      </Box>
    </>
  );
};

/**
 * Subscriber list item
 * @returns
 */
const SubscriberListItem = ({ id, activityFeedId }) => {
  const { subscriberDetails } = api.endpoints.getActivityFeedNotifications.useQueryState(
    activityFeedId,
    {
      selectFromResult: ({ data }) => {
        return {
          subscriberDetails: data?.find((subscriber) => subscriber?.id === id),
        };
      },
    }
  );

  const user = useSelector((state) => selectUserOrContactById(state, subscriberDetails?.user_id));
  const userName = user?.first_name ? `${user?.first_name} ${user?.last_name}` : 'Deleted User';

  const notificationStatusColor =
    subscriberDetails?.inbox_read_at || subscriberDetails?.email_read_at
      ? 'secondary'
      : subscriberDetails?.sent_at && subscriberDetails?.channels?.length
        ? 'red'
        : 'grey';

  return (
    <ListItem>
      <ListItemAvatar>
        <CustomBadgeIcon
          badgeContent={<DoneIcon fontSize="small" style={{ fontSize: '13px' }} />}
          color={notificationStatusColor}
          overlap="circle"
        >
          <Avatar
            firstName={user?.first_name || 'Deleted User'}
            icon={user?.original_url?.profile_image}
            lastName={user?.last_name}
            avatarStyle={{
              height: '40px',
              width: '40px',
              fontSize: '14px',
              marginRight: '5px',
            }}
          />
        </CustomBadgeIcon>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography>
            {userName} was send an{' '}
            {subscriberDetails?.channels?.map((channel, index) => {
              const channelLabel = channelLabels[channel] || channel;
              return (
                <>
                  {subscriberDetails[`${channel}_read_at`] ? (
                    <Tooltip arrow title={`${channelLabel} Read`}>
                      <b>{channelLabel}</b>
                    </Tooltip>
                  ) : (
                    channelLabel
                  )}
                  {index !== subscriberDetails?.channels.length - 1 ? ', ' : ''}
                </>
              );
            })}
          </Typography>
        }
      />
    </ListItem>
  );
};

const channelLabels = {
  inbox: 'Inbox Message',
  email: 'Email',
  sms: 'Sms',
};

export default ActivityFeedDrawer;
