import BudgetsIndex from '../components/BudgetsIndex';
import BudgetsShow from '../components/BudgetsShow';
import ContractsShow from '../components/ContractsShow';
import ForecastBudgetShow from '../pages/ForecastBudgetShow';
import ForecastsIndex from '../pages/ForecastsIndex';
import InvoicesShow from '../pages/InvoicesShow';

export const budgetsRoutes = [
  { path: '', element: <BudgetsIndex /> },
  { path: 'forecasts', element: <ForecastsIndex /> },
  { path: ':budgetId', element: <BudgetsShow /> },
  { path: ':budgetId/forecast', element: <ForecastBudgetShow /> },
  { path: ':budgetId/contracts/:contractId', element: <ContractsShow /> },
  { path: ':budgetId/contracts/:contractId/invoices/:invoiceId', element: <InvoicesShow /> },
];
