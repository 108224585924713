import { StyledDialog } from '@blackhyve/common';
import { Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import ContractLinesList from './ContractLinesList';
import { OpenInNew } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';

const BudgetLineContractsDialog = ({ open, handleClose, budgetLine }) => {
  const { workspaceId, projectId, budgetId } = useParams();

  const contracts = (budgetLine?.contracts || []).map((contract) => {
    return {
      ...contract,
      contract_lines: budgetLine.contract_lines.filter((line) => {
        return contract.id === line.contract_id;
      }),
      change_order_lines: budgetLine.change_order_lines.filter((line) => {
        return contract.id === line.contract_id;
      }),
    };
  });

  return (
    <StyledDialog handleClose={handleClose} maxWidth="xl" open={open} title="Related Contracts">
      {budgetLine && (
        <>
          {contracts.map((contract) => {
            return (
              <>
                <Box sx={{ display: 'flex' }}>
                  <IconButton
                    LinkComponent={Link}
                    size="small"
                    to={`/workspace/${workspaceId}/projects/${projectId}/budgets/${budgetId}/contracts/${contract.id}`}
                  >
                    <OpenInNew />
                  </IconButton>
                  <Typography style={{ marginLeft: '.5rem' }} variant={'h5'}>
                    {contract.title}
                  </Typography>
                </Box>

                <Box pb={6} pt={2}>
                  <Paper elevation={2}>
                    {contract.contract_lines.length > 0 && (
                      <>
                        <Box pb={2} pl={2} pt={2}>
                          <Typography variant={'h6'}>Schedule of values</Typography>
                        </Box>
                        <ContractLinesList rows={contract.contract_lines} withOptions={false} />
                      </>
                    )}
                    {contract.change_order_lines.length > 0 && (
                      <>
                        <Box pb={2} pl={2} pt={2}>
                          <Typography variant={'h6'}>Change orders</Typography>
                        </Box>
                        <ContractLinesList rows={contract.change_order_lines} withOptions={false} />
                      </>
                    )}
                  </Paper>
                </Box>
              </>
            );
          })}
        </>
      )}
    </StyledDialog>
  );
};

export default BudgetLineContractsDialog;
