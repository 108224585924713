import { StyledDialog } from '@blackhyve/common';
import { parseDate } from '@blackhyve/utilities/dates';
import { Button } from '@mui/material';
import startOfDay from 'date-fns/startOfDay';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { StuckPointForm } from './StuckPointForm';
import { LoadingButton } from '@mui/lab';

const initialState = {
  id: undefined,
  title: '',
  description: '',
  companies_to_blame: [],
  responsible_users: [],
  affects_pr: false,
  resolved_on: null,
  is_notify: 1,
  opened_on: startOfDay(new Date()),
};

/**
 * StuckPoint Form
 * @param {Boolean} open
 * @param {Object} stuckPoint
 * @param {String} projectId
 * @param {Object} handleClose
 * @param {String} taskId
 * @returns
 */
export const AddUpdateStuckPointDialog = ({
  label,
  open,
  stuckPoint,
  projectId,
  handleClose,
  handleSave,
}) => {
  const form = useForm({
    defaultValues: { ...initialState },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const onSave = (data) => {
    setIsLoading(true);
    handleSave(data)
      .then(() => {
        onClose();
      })
      .catch(() => {
        setIsLoading(false);
        setError('An error occured. Please try again.');
      });
  };

  const onClose = () => {
    form.reset({ ...initialState });
    handleClose();
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isEmpty(stuckPoint)) {
      form.reset({
        ...initialState,
        ...stuckPoint,
        affects_pr: stuckPoint.affects_pr ? true : false,
        resolved_on: stuckPoint.resolved_on ? parseDate(stuckPoint.resolved_on) : null,
        opened_on: stuckPoint.opened_on ? parseDate(stuckPoint.opened_on) : initialState.opened_on,
      });
    }
  }, [stuckPoint, form.reset]);

  return (
    <StyledDialog
      handleClose={handleClose}
      open={open}
      title={`${label} Stuck Point`}
      actions={
        <>
          <Button onClick={onClose}>Close</Button>
          <LoadingButton
            color="primary"
            loading={isLoading}
            variant="contained"
            onClick={form.handleSubmit(onSave)}
          >
            {label}
          </LoadingButton>
        </>
      }
    >
      <StuckPointForm error={error} form={form} handleSave={onSave} projectId={projectId} />
    </StyledDialog>
  );
};
