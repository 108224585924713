import { parseDate } from '@blackhyve/utilities/dates';
import { Table } from 'components/table/Table';
import MoreOptions from '../components/RecipeMoreOptions';

const columns = [
  { field: 'name', label: 'Name', sort: true },
  { field: 'type', label: 'Type', sort: true },
  {
    field: 'updated_at',
    label: 'Updated At',
    sort: true,
    render: (recipe) => recipe.updated_at && parseDate(recipe.updated_at).toLocaleDateString(),
  },
  {
    field: 'moreOptions',
    cellProps: { align: 'right' },
    render: (recipe) => (
      <div onClick={(event) => event.stopPropagation()}>
        <MoreOptions recipe={recipe} />
      </div>
    ),
  },
];

export const RecipesTable = ({ recipes, isFetching, isLoading, TableProps }) => {
  return <Table columns={columns} rows={recipes} {...TableProps} />;
};

export default RecipesTable;
