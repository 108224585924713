
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

const DrawerListItemStyle = styled(MenuItem)(
    ({ theme }) => ({
        width: '100%',
        paddingTop: theme.spacing(1) / 2,
        paddingLeft: theme.spacing(1) / 2,
        paddingBottom: theme.spacing(1) / 2,
        paddingRight: theme.spacing(4),
        overflow: 'visible',
        borderRadius: '3px',
        '&:hover': {
            // borderRadius: '3px',
            backgroundColor: '#6a6a6d',
        },
        '&.Mui-selected': {
            backgroundColor: '#D1A8FF90',
        },
        '&.Mui-selected:hover': {
            backgroundColor: '#D1A8FF',
        },
    }),
    { name: 'ProjectListRowGrid' }
);


const DrawerListItem = ({ children, ...other }) => {
    return <DrawerListItemStyle {...other}>
        {children}
    </DrawerListItemStyle>
}

export default DrawerListItem