import { AccountCircleOutlined, ExitToAppOutlined } from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Tooltip,
} from '@mui/material';
import { Popover } from 'components/Popover';
import { logout, selectCurrentUser } from 'features/auth';
import { disconnectSocketConnection } from 'helpers/websockets';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const COLORS = ['red', 'green', 'orange', 'blue', 'purple', 'teal'];

export const UserMenu = ({ PopoverProps, IconButtonProps, size }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [avatarColor, setAvatarColor] = useState(COLORS[Math.floor(Math.random() * COLORS.length)]);

  const user = useSelector((state) => selectCurrentUser(state));

  const handleLogout = () => {
    dispatch(logout()).then(() => {
      disconnectSocketConnection();
    });
  };

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      trigger={
        <IconButton size={size} {...IconButtonProps}>
          <Tooltip arrow placement="top" title={'Settings'}>
            <Avatar sx={{ backgroundColor: avatarColor, color: '#fff' }} variant={'rounded'}>
              {user?.first_name[0]}
              {user?.last_name[0]}
            </Avatar>
          </Tooltip>
        </IconButton>
      }
      {...PopoverProps}
    >
      {({ handleClose }) => (
        <MenuList>
          <MenuItem
            onClick={() => {
              navigate(`user-profile`);
              handleClose();
            }}
          >
            <ListItemIcon>
              <AccountCircleOutlined />
            </ListItemIcon>
            <ListItemText primary={'My Profile'} />
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppOutlined />
            </ListItemIcon>
            <ListItemText primary={'Logout'} />
          </MenuItem>
        </MenuList>
      )}
    </Popover>
  );
};
