import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(
  {
    cursor: 'pointer',
    display: 'flex',
    textAlign: 'left',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: '#6a6a6d',
      borderRadius: '3px',
    },
    '&.active': {
      color: '#D1A8FF90',
    },
  },
  { name: 'NavDrawerButton' }
);

export const NavDrawerButton = (props) => {
  const { children, ...other } = props;
  return (
    <StyledButton size={'small'} fullWidth {...other}>
      {children}
    </StyledButton>
  );
};
