import SvgMeterIcon from '@blackhyve/common/src/icons/Meter';
import { Box, Typography } from '@mui/material';

export const Pace = ({ value, ...props }) => {
  return (
    <Box
      alignItems={'center'}
      bgcolor={parseFloat(value) >= 0 ? 'success.light' : 'error.light'}
      borderRadius={1}
      color={'white'}
      component={'span'}
      display={'inline-flex'}
      gap={0.5}
      p={0.5}
      {...props}
    >
      <SvgMeterIcon />
      <Typography variant='subtitle2'> {value > 0 && '+'}{value}</Typography>
    </Box>
  );
};
