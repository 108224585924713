import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  CreateRecipeDependencyAPI,
  DeleteRecipeDependenciesAPI,
  DeleteRecipeDependencyAPI,
  FetchRecipeDependenciesAPI,
  SyncRecipeDependencyAPI,
  UpdateRecipeDependenciesAPI,
} from 'features/recipes/api/RecipeService';
import { workspaceAPI } from 'features/workspace/api/workspace.api';
import { cloneDeep } from 'lodash';

/**
 * Fetch Recipes
 * @param {*} requestParameters
 * @param {Number} requestParameters.recipeId
 */
export const fetchRecipeDependencies = createAsyncThunk(
  'recipeDependencies/fetchRecipeDependencies',
  async ({ recipeId }, { rejectWithValue }) => {
    const apiResponse = await FetchRecipeDependenciesAPI({ recipeId });
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response?.message);
    } else {
      return apiResponse.response;
    }
  }
);

/**
 * Add Recipe
 * @param {*} requestParameter
 * * @param {Number} requestParameter.id
 * * @param {String} requestParameter.name
 */
export const addRecipeDependency = createAsyncThunk(
  'recipeDependencies/addRecipeDependency',
  async (requestParameters, { rejectWithValue }) => {
    const data = cloneDeep(requestParameters);
    data.id && delete data.id;
    const apiResponse = await CreateRecipeDependencyAPI(requestParameters);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response?.message);
    } else {
      return { data: apiResponse?.response.data, removeId: requestParameters.id };
    }
  }
);

/**
 * Update Recipe dependency
 * @param {*} requestParameter
 * * @param {Number} requestParameter.id
 * * @param {String} requestParameter.name
 */
export const updateRecipeDependency = createAsyncThunk(
  'recipeDependencies/updateRecipeDependency',
  async (requestParameters, { rejectWithValue }) => {
    const apiResponse = await UpdateRecipeDependenciesAPI(requestParameters);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response?.message);
    } else {
      return apiResponse.response;
    }
  }
);
/**
 * Update Recipe
 * @param {*} requestParameter
 * @param {Number} requestParameter.recipeId
 * @param {Number} requestParameter.dependencies
 * * @param {Number} dependencies.id
 * * @param {String} dependencies.name

 */
export const updateRecipeDependencies = createAsyncThunk(
  'recipeDependencies/updateRecipeDependencies',
  async (requestParameters, { rejectWithValue }) => {
    const apiResponse = await UpdateRecipeDependenciesAPI(requestParameters);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response?.message);
    } else {
      return apiResponse.response;
    }
  }
);

/**
 * Delete Recipe Dependency
 * @param {Number} id
 */
export const deleteRecipeDependency = createAsyncThunk(
  'recipeDependencies/deleteRecipeDependency',
  async (id, { rejectWithValue }) => {
    const apiResponse = await DeleteRecipeDependencyAPI(id);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response?.message);
    } else {
      return id;
    }
  }
);

/**
 * Delete Recipe Dependencies
 * @param {*} requestParameters
 * @param {Number} requestParameters.recipeId
 * @param {[Number]} requestParameters.dependencies
 */
export const deleteRecipeDependencies = createAsyncThunk(
  'recipeDependencies/deleteRecipeDependencies',
  async (requestParameters, { rejectWithValue }) => {
    const apiResponse = await DeleteRecipeDependenciesAPI(requestParameters);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response?.message);
    } else {
      return requestParameters;
    }
  }
);

/**
 * Sync Dependency API
 * @param {*} requestParameters
 * @param {[Object]} requestParameters.dependencies
 */
export const syncDependency = createAsyncThunk(
  'recipeDependencies/syncDependency',
  async (requestParameters, { rejectWithValue }) => {
    const dependencies = requestParameters?.selectedDependency?.map((dependency) => {
      if (dependency.isNew) {
        delete dependency['id'];
      }
      return dependency;
    });

    const apiResponse = await SyncRecipeDependencyAPI({
      dependencies: dependencies,
      recipeTaskId: requestParameters.taskId,
    });
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response?.message);
    } else {
      return apiResponse.response.data;
    }
  }
);

export const recipeDependencyAdapter = createEntityAdapter();

const initialState = recipeDependencyAdapter.getInitialState({
  error: undefined,
});

const recipeDependenciesSlice = createSlice({
  name: 'Recipe Dependencies',
  initialState,
  reducers: {
    addEmptyRecipe: (state, action) => {
      recipeDependencyAdapter.addOne(state, {
        id: Math.random(),
        isEmpty: true,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecipeDependencies.fulfilled, (state, action) => {
      recipeDependencyAdapter.setAll(state, action.payload?.data);
    });

    // Update Recipe Dependency
    builder.addCase(updateRecipeDependency.fulfilled, (state, action) => {
      recipeDependencyAdapter.upsertOne(state, { ...action.payload.data });
    });
    // Update Recipe dependencies
    builder.addCase(updateRecipeDependencies.fulfilled, (state, action) => {
      recipeDependencyAdapter.upsertMany(state, action.payload.data);
    });

    // Delete Recipe dependency
    builder.addCase(deleteRecipeDependency.fulfilled, (state, action) => {
      recipeDependencyAdapter.removeOne(state, action.payload);
    });
    builder.addCase(deleteRecipeDependency.rejected, (state, action) => {
      recipeDependencyAdapter.upsertOne(state, { id: action.meta?.arg });
    });

    // Delete Recipe dependency
    builder.addCase(deleteRecipeDependencies.fulfilled, (state, action) => {
      recipeDependencyAdapter.removeMany(state, action?.meta.arg?.dependencies);
    });
    // builder.addCase(deleteRecipeDependency.rejected, (state, action) => {
    //   recipeDependencyAdapter.upsertMany(state, { id: action.meta?.arg });
    // });

    // Add Recipe dependency
    builder.addCase(addRecipeDependency.fulfilled, (state, action) => {
      recipeDependencyAdapter.upsertOne(state, action.payload.data);
    });

    // Sync Dependency
    builder.addCase(syncDependency.fulfilled, (state, action) => {
      if (action.payload?.created_dependencies) {
        recipeDependencyAdapter.upsertMany(state, action.payload.created_dependencies);
      }
      if (action.payload?.deleted_dependencies) {
        recipeDependencyAdapter.removeMany(
          state,
          action.payload.deleted_dependencies.map((dep) => dep.id)
        );
      }
      if (action.payload?.updated_dependencies) {
        recipeDependencyAdapter.upsertMany(state, action.payload.updated_dependencies);
      }
    });

    builder.addCase(syncDependency.rejected, (state, action) => {
      const errorDependencyList = [];
      if (action?.payload?.errors) {
        const errorKeys = Object.keys(action.payload.errors);
        errorKeys.forEach((key) => {
          errorDependencyList.push({
            ...action.payload.dependencyWithIds[key],
            error: action.payload.errors[key],
          });
        });
      }
      if (errorDependencyList && errorDependencyList?.length) {
        recipeDependencyAdapter.upsertMany(
          state,
          errorDependencyList?.map((obj) => {
            return { id: obj.id, error: obj.error };
          })
        );
      }
    });

    builder.addMatcher(workspaceAPI.endpoints.setWorkspace.matchFulfilled, (state, action) => {
      recipeDependencyAdapter.removeAll(state);
    });
  },
});

export default recipeDependenciesSlice.reducer;
export const { addEmptyRecipe } = recipeDependenciesSlice.actions;

/** Selectors **/
export const {
  selectById: selectRecipeDependencyById,
  selectIds: selectRecipeDependencyIds,
  selectEntities: selectRecipeDependencyEntities,
  selectAll: selectAllRecipeDependencies,
  selectTotal: selectTotalRecipeDependencies,
} = recipeDependencyAdapter.getSelectors((state) => state.recipeDependencies);
