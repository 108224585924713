import { parseDate } from '@blackhyve/utilities/dates';
import { DatePicker } from '@mui/x-date-pickers';
import { startOfMonth, addMonths } from 'date-fns';
import { useGetProjectQuery } from 'features/projects/store/project.api';
import { forwardRef } from 'react';
import { useParams } from 'react-router-dom';

export const isAfterStart = (value, startDate) => {
  if (!startDate || !value) {
    return;
  }

  return startDate < value || 'Cannot be before start';
};

export const BudgetMonthPicker = forwardRef(
  (
    {
      clearable = true,
      minDate,
      field: { onChange, value, ...field },
      fieldState: { error, invalid },
    },
    ref
  ) => {
    const { projectId } = useParams();
    const { data: projectDetails, isLoading } = useGetProjectQuery(projectId);

    let endDate = parseDate(projectDetails?.sub_completion_date);
    let startDate = minDate ? addMonths(minDate, 1) : parseDate(projectDetails?.start_date);

    return (
      <DatePicker
        disabled={isLoading}
        maxDate={endDate}
        minDate={startDate}
        ref={ref}
        value={value}
        views={['month', 'year']}
        slotProps={{
          textField: {
            ...field,
            size: 'small',
            fullWidth: true,
            error: invalid,
            helperText: error?.message,
            clearable,
          },
        }}
        onChange={(value) => {
          onChange(value ? startOfMonth(new Date(value)) : null);
        }}
      />
    );
  }
);
