import { Gantt } from '@blackhyve/dhtmlx-gantt';
const ganttStore = (function () {
  const gantts = {};
  return {
    createGantt: function (name) {
      if (!this.getGantt(name)) {
        gantts[name] = Gantt.getGanttInstance();
      }
      return this.getGantt(name);
    },
    getGantt: function (name) {
      return gantts[name];
    },
    deleteInstance: function (name) {
      if (gantts.hasOwnProperty(name)) {
        delete gantts[name];
      }
    },
  };
})();

export default ganttStore;
