const calculateTaskActualDates = require('./calculateTaskActualDates');
const calculateTaskForecastedDates = require('./calculateTaskForecastedDates');
const calculateTaskPace = require('./calculateTaskPace');
const calculateTaskProductionRating = require('./calculateTaskProductionRating');
const calculateTaskProductionVelocity = require('./calculateTaskProductionVelocity');
const calculateTaskProgress = require('./calculateTaskProgress');
const calculateTaskType = require('./calculateTaskType');
const calculateTaskStatus = require('./calculateTaskStatus');
const calculateTaskDurations = require('./calculateTaskDurations');
const calculateLinkLag = require('./calculateLinkLag');
const resetTaskState = require('./resetTaskState');
const hasChild = require('./hasChild');
const getParents = require('./getParents');
const getChildren = require('./getChildren');
const getConnectedGroup = require('./getConnectedGroup');

module.exports = {
  ...calculateTaskActualDates,
  ...calculateTaskForecastedDates,
  ...calculateTaskPace,
  ...calculateTaskProductionRating,
  ...calculateTaskProductionVelocity,
  ...calculateTaskProgress,
  ...calculateTaskType,
  ...calculateTaskStatus,
  ...calculateTaskDurations,
  ...calculateLinkLag,
  ...resetTaskState,
  ...hasChild,
  ...getParents,
  ...getChildren,
  ...getConnectedGroup,
};
