import { ConfirmDeleteDialog } from '@blackhyve/common';
import { theme } from '@blackhyve/common/src/AppTheme';
import { Delete, Edit, FileCopy, MoreVert, Settings } from '@mui/icons-material';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuList,
  Popover,
  ThemeProvider,
} from '@mui/material';
import { useState } from 'react';
import HolidaySettings from './HolidaySettings';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useCanEditProject } from 'hooks/useCanEditProject';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import { RequireRole } from 'features/auth/components/RequireRole';
import CreateProjectDialog from 'features/projects/components/CreateProjectDialog';
import ProjectSettingDialog from 'features/projects/components/ProjectSettingDialog';
import { useDeleteProjectMutation, useDuplicateProjectMutation } from '../store/project.api';


const MoreOptions = ({ projectId }) => {
  const { workspaceId, projectId: openedProjectId } = useParams();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(undefined);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [openHolidayDialog, setOpenHolidayDialog] = useState(false);
  const [projectSettingDialogOpen, setProjectSettingDialogOpen] = useState(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [deleteProject] = useDeleteProjectMutation();
  const [duplicateProject] = useDuplicateProjectMutation();

  const canEdit = useCanEditProject(projectId, { skip: !anchorEl });

  const handleDelete = () => {
    if (openedProjectId == projectId) {
      navigate(`/workspace/${workspaceId}/projects`);
    }
    deleteProject(projectId);
  };

  const handleDuplicate = () => {
    duplicateProject(projectId);
  };

  const anchorOrigin = {
    vertical: 'center',
    horizontal: 'center',
  };
  const transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <RequireRole exclude roles={['contractor_contact']}>
      <IconButton edge="end" size={'small'} onClick={handleOpen}>
        <MoreVert />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        open={Boolean(anchorEl)}
        transformOrigin={transformOrigin}
        onClose={handleClose}
      >
        <MenuList>
          <ListItemButton
            onClick={() => {
              setEditDialogOpen(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              setProjectSettingDialogOpen(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              setOpenHolidayDialog(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <HolidayVillageIcon />
            </ListItemIcon>
            <ListItemText primary="Holiday Settings" />
          </ListItemButton>
          <RequireRole exclude roles={['contractor_contact']}>
            <ListItemButton
              onClick={() => {
                handleDuplicate();
                handleClose();
              }}
            >
              <ListItemIcon>
                <FileCopy />
              </ListItemIcon>
              <ListItemText primary="Duplicate" />
            </ListItemButton>
          </RequireRole>
          <RequireRole exclude roles={['contractor_contact']}>
            <ListItemButton
              color={'error.main'}
              component={ListItem}
              disabled={!canEdit}
              onClick={() => {
                setConfirmDeleteDialogOpen(true);
                handleClose();
              }}
            >
              <ListItemIcon>
                <Delete color={'error'} />
              </ListItemIcon>
              <ListItemText primary="Delete" sx={{ color: 'error.main' }} />
            </ListItemButton>
          </RequireRole>
        </MenuList>
      </Popover>
      <ThemeProvider theme={theme}>
        {editDialogOpen && (
          <CreateProjectDialog
            handleClose={() => setEditDialogOpen(false)}
            id={projectId}
            open={editDialogOpen}
          />
        )}
        {projectSettingDialogOpen && (
          <ProjectSettingDialog
            handleClose={() => setProjectSettingDialogOpen(false)}
            id={projectId}
            open={projectSettingDialogOpen}
          />
        )}
        {confirmDeleteDialogOpen && (
          <ConfirmDeleteDialog
            handleClose={() => setConfirmDeleteDialogOpen(false)}
            handleDelete={handleDelete}
            item={'project'}
            open={confirmDeleteDialogOpen}
          />
        )}
        {openHolidayDialog &&
          <HolidaySettings
            projectId={projectId}
            open={openHolidayDialog}
            handleClose={() => setOpenHolidayDialog(false)} />}
      </ThemeProvider>
    </RequireRole>
  );
};

export default MoreOptions;
