import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const FormLabel = styled(Typography)(({ theme, fontSize = 12 }) => ({
  fontSize: fontSize,
  textTransform: 'uppercase',
}));

export const PageHeadingLabel = styled(Typography)(() => ({
  textTransform: 'uppercase',
  color: '#C4C4C4',
}));

export const HighlighterLinkLabel = styled(Typography)(() => ({
  color: '#4B70E7',
  cursor: 'pointer',
  fontSize: 13,
}));

export const SectionHeadingLabel = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 600,
}));

export const StyledArchivedLabel = styled(Typography)(() => ({
  color: 'red',
  padding: '2px',
  fontSize: '11px',
  fontWeight: 600,
  borderRadius: '2px',
  textTransform: 'uppercase',
  // border: '1px solid'
}));

export const StyledLabelForNoResult = styled(Typography)(() => ({
  display: 'flex',
  fontSize: '14px',
  // padding: '20px',
  color: '#8E8E90',
  fontWeight: 'bold',
}));

export const StyledPageLoadingContainer = styled('div')(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  margin: 'auto',
  flexDirection: 'column-reverse',
}));
