import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import AvatarGroup from '@mui/material/AvatarGroup';
import ListItemIcon from '@mui/material/ListItemIcon';
import { isEmpty } from 'lodash';
import Menu from '@mui/material/Menu';
import { getSocket } from 'helpers/websockets';

const OnlineUserBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));

/**
 * Gantt Online Users
 * @param {[String]} channelNames
 * @returns
 */
const GanttOnlineUsers = ({ channelNames }) => {

  const [onlineUsers, setOnlineUsers] = useState({});
  const socket = getSocket();

  const [openList, setOpenList] = useState(null);

  const users =
    !isEmpty(onlineUsers) && Object.values(onlineUsers) ? Object.values(onlineUsers) : [];


  const handleOpenList = (event) => {
    event.stopPropagation();
    if (users.length > 4) {
      setOpenList(openList ? undefined : event.currentTarget);
    }
  };

  const handleCloseList = () => setOpenList(false)

  useEffect(() => {
    channelNames?.forEach((channelName, index) => {
      socket
        .join(channelName)
        .here((users) => {
          const newUsersObj = {};
          users?.forEach((user) => (newUsersObj[user.id] = user));
          setOnlineUsers((prevUsers) => ({ ...prevUsers, ...newUsersObj }));
        })
        .joining((user) => {
          setOnlineUsers((prevUsers) => ({ ...prevUsers, [user.id]: user }));
        })
        .leaving((user) => {
          setOnlineUsers((prevUsers) => {
            const cloneDeep = { ...prevUsers };
            delete cloneDeep[user.id];
            return cloneDeep;
          });
        });
    });

    return () => {
      channelNames?.forEach((channelName) => {
        socket.leave(channelName);
      });
    };
  }, [socket, channelNames]);

  return (
    <Box mx={1}>
      <AvatarGroup
        max={4}
        sx={{ cursor: 'pointer' }}
        total={users.length}
        onClick={handleOpenList}
      >
        {users?.map((user) => {
          const name = `${user?.first_name} ${user?.last_name}`;
          return (
            <Tooltip arrow={true} key={user?.id} placement="top" title={`${user?.first_name} ${user?.last_name}`}>
              <OnlineUserBadge
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                key={user.id}
                overlap="circular"
                variant="dot"
              >
                <Avatar
                  alt={name}
                  key={user.id}
                  src={user?.profile_image?.thumb_url}
                  style={{ border: '2px solid', borderColor: '#f0c940', borderRadius: '50%' }}
                >
                  {user?.first_name?.[0]}
                  {user?.last_name?.[0]}
                </Avatar>
              </OnlineUserBadge>
            </Tooltip>
          );
        })}
      </AvatarGroup>
      {openList &&
        <Menu
          anchorEl={openList}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          id="online-user-list"
          open={openList}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClick={handleCloseList}
          onClose={handleCloseList}
        >
          {users?.slice(3, users?.length)?.map((user) => {
            return (
              <>
                <MenuItem key={user.id}>
                  <ListItemIcon sx={{ marginRight: 1 }}>
                    <OnlineUserBadge
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      overlap="circular"
                      variant="dot"
                    >
                      <Avatar
                        alt={`${user?.first_name} ${user?.last_name}`}
                        key={user.id}
                        src={user?.profile_image?.thumb_url}
                        sx={{
                          border: '2px solid',
                          borderColor: '#f0c940',
                          borderRadius: '50%',
                          width: 30,
                          height: 30,
                          fontSize: '14px',
                        }}
                      >
                        {user?.first_name?.[0]}
                        {user?.last_name?.[0]}
                      </Avatar>
                    </OnlineUserBadge>
                  </ListItemIcon>
                  <Typography variant="inherit">
                    {user?.first_name} {user?.last_name}
                  </Typography>
                </MenuItem>
              </>
            );
          })}
        </Menu>}
    </Box>
  );
};

export default GanttOnlineUsers;