import { StyledDialog } from '@blackhyve/common';
import {
  Box,
  Button,
  CircularProgress,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { PercentInput, formatDecimals } from './NumericControls';
import {
  useCreateBudgetCodeMutation,
  useUpdateBudgetCodeMutation,
} from '../store/budget-codes.api';
import { useEffect } from 'react';
import {
  BELL_CURVE,
  NORMAL_DISTRIBUTION,
  CurveOptions,
  DistributionOptions,
} from '../models/curves';
import { TradesAutocomplete } from 'features/trades/components/TradesAutocomplete';

const initialState = {
  division: '',
  cost_code: '',
  description: '',
  trade_id: null,
  percent: '0',
  labor_percent: '50',
  labor_curve: BELL_CURVE,
  labor_curve_distribution: NORMAL_DISTRIBUTION,
  material_percent: '50',
  material_curve: BELL_CURVE,
  material_curve_distribution: NORMAL_DISTRIBUTION,
};

export const BudgetCodeFormDialog = ({ open, handleClose, budgetCode, creating = false }) => {
  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: { ...initialState, ...budgetCode },
  });

  const [createBudgetCode, { isLoading: isLoadingCreate }] = useCreateBudgetCodeMutation();
  const [updateBudgetCode, { isLoading: isLoadingUpdate }] = useUpdateBudgetCodeMutation();

  const isLoading = isLoadingCreate || isLoadingUpdate;

  async function handleStore(data) {
    if (budgetCode?.id) {
      await updateBudgetCode({ id: budgetCode.id, ...data })
        .unwrap()
        .then(closeDialog);
    } else {
      await createBudgetCode(data).unwrap().then(closeDialog);
    }
  }

  function closeDialog() {
    handleClose();
    reset();
  }

  useEffect(() => {
    const percentWatcher = watch((state, { name, type }) => {
      if (!type) return;

      if (name === 'labor_percent') {
        setValue('material_percent', formatDecimals(100 - state.labor_percent));
      }

      if (name === 'material_percent') {
        setValue('labor_percent', formatDecimals(100 - state.material_percent));
      }
    });

    return () => {
      percentWatcher.unsubscribe();
    };
  }, [watch, setValue]);

  useEffect(() => {
    reset({ ...initialState, ...budgetCode });
  }, [budgetCode, reset]);

  return (
    <StyledDialog
      DialogContentProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
      handleClose={closeDialog}
      open={open}
      title={creating ? 'Create Budget Code' : 'Edit Budget Code'}
      actions={
        <>
          <Button disabled={isLoading} onClick={closeDialog}>
            Close
          </Button>
          <Button
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={'1rem'} />}
            variant={'contained'}
            onClick={handleSubmit(handleStore)}
          >
            Save
          </Button>
        </>
      }
    >
      <Grid container item spacing={1} xs={12}>
        <Grid container item xs={6}>
          <FormLabel>Division</FormLabel>
          <Controller
            control={control}
            name="division"
            rules={{ required: 'Division field is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={error}
                helperText={error?.message}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid container item xs={6}>
          <FormLabel>Cost Code</FormLabel>
          <Controller
            control={control}
            name="cost_code"
            rules={{ required: 'Cost Code field is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={error}
                helperText={error?.message}
                size="small"
              />
            )}
          />
        </Grid>

        <Grid container item xs={12}>
          <FormLabel>Description</FormLabel>
          <Controller
            control={control}
            name="description"
            rules={{ required: 'Description field is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={error}
                helperText={error?.message}
                size="small"
              />
            )}
          />
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Trade</FormLabel>
            <Box width={'100%'}>
              <Controller
                control={control}
                name="trade_id"
                render={({ field }) => (
                  <TradesAutocomplete
                    {...field}
                    onChange={(_event, newValue) => {
                      field.onChange(newValue ?? null);
                    }}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Estimated Amount (%)</FormLabel>
            <Controller
              control={control}
              name="percent"
              rules={{ required: 'Estimated Amount field is required' }}
              render={({ field, fieldState: { error } }) => (
                <PercentInput {...field} fullWidth error={error} />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Estimated Labor (%)</FormLabel>
            <Controller
              control={control}
              name="labor_percent"
              rules={{ required: 'Estimated Labor field is required' }}
              render={({ field, fieldState: { error } }) => (
                <PercentInput {...field} fullWidth error={error} />
              )}
            />
          </Grid>
          <Grid container item xs={6}>
            <FormLabel>Estimated Material (%)</FormLabel>
            <Controller
              control={control}
              name="material_percent"
              rules={{ required: 'Estimated Material field is required' }}
              render={({ field, fieldState: { error } }) => (
                <PercentInput {...field} fullWidth error={error} />
              )}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Labor Curve</FormLabel>
            <Controller
              control={control}
              name="labor_curve"
              rules={{ required: 'Labor Curve field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select {...field} fullWidth name={'name'} size={'small'}>
                  {CurveOptions.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Material Curve</FormLabel>
            <Controller
              control={control}
              name="material_curve"
              rules={{ required: 'Material Curve field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select {...field} fullWidth name={'name'} size={'small'}>
                  {CurveOptions.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Labor Curve Distribution</FormLabel>
            <Controller
              control={control}
              name="labor_curve_distribution"
              rules={{ required: 'Labor Curve Distribution field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select {...field} fullWidth size={'small'}>
                  {DistributionOptions.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Material Curve Distribution</FormLabel>
            <Controller
              control={control}
              name="material_curve_distribution"
              rules={{ required: 'Material Curve Distribution field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select {...field} fullWidth size={'small'}>
                  {DistributionOptions.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};
