import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import DraftsOutlined from '@mui/icons-material/DraftsOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Skeleton from '@mui/material/Skeleton';
import { StyledArchivedLabel } from 'assets/style-components/typography';
import Avatar from 'components/common/v3/Avatar';
import { selectUserOrContactById } from 'features/users/store/usersSlice';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { TaskFeedDateStyle, TaskFeedRowStyle, TaskFeedUserName } from './utils/taskFeedStyles';

/**
 * Task Feed Card Or Audit Card
 * @param {String} userId
 * @param {String} dateTime
 * @param {String} content
 * @param {String} subHeading
 * @returns
 */
const TaskFeedCard = ({
  userId,
  dateTime,
  content,
  subHeading,
  onLoad,
  readCount,
  unreadCount,
}) => {
  const user = useSelector((state) => selectUserOrContactById(state, userId));
  const isUserArchived = user?.deleted_at;

  useLayoutEffect(() => {
    onLoad && onLoad();
  }, [onLoad]);

  return (
    <TaskFeedRowStyle container item>
      <Grid item>
        <Avatar
          firstName={user?.first_name || 'Deleted User'}
          icon={user?.profile_image?.original_url}
          lastName={user?.last_name}
          avatarStyle={{
            fontSize: '13px',
            marginRight: '5px',
          }}
        />
      </Grid>
      <Grid container item xs alignItems="center">
        <Grid container item alignItems="center" xs={12}>
          <TaskFeedUserName style={isUserArchived ? { textDecoration: 'line-through' } : {}}>
            {user?.first_name ? user?.first_name : 'Deleted User'} {user?.last_name}
          </TaskFeedUserName>
          {subHeading ? subHeading : null}
          {isUserArchived ? <StyledArchivedLabel>Archived User</StyledArchivedLabel> : null}
          <TaskFeedDateStyle>{dateTime}</TaskFeedDateStyle>
        </Grid>
        <Grid item xs={12}>
          {content}
        </Grid>
        <Grid container item style={{ gap: 5 }} xs={12}>
          <Tooltip arrow title={'Unread notifications.'}>
            <Box className="flex" style={{ alignItems: 'center' }}>
              <MailOutlineIcon
                fontSize="small"
                htmlColor={'#8E8E90'}
                style={{ marginLeft: 5, marginRight: 5 }}
              />
              <Typography variant="body2">{unreadCount ? unreadCount : 0}</Typography>
            </Box>
          </Tooltip>
          <Tooltip arrow title={'Read notifications.'}>
            <Box className="flex" style={{ alignItems: 'center' }}>
              <DraftsOutlined fontSize="small" htmlColor={'#8E8E90'} style={{ marginRight: 5 }} />
              <Typography variant="body2">{readCount ? readCount : 0}</Typography>
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
    </TaskFeedRowStyle>
  );
};

export const TaskFeedSkeleton = () => {
  return (
    <TaskFeedRowStyle container item>
      <Grid item>
        <Skeleton
          animation="wave"
          height={35}
          style={{ marginRight: '5px' }}
          variant="circular"
          width={35}
        />
      </Grid>
      <Grid container item xs alignItems="center">
        <Grid container item alignItems="center" xs={12}>
          <TaskFeedUserName>
            <Skeleton animation="wave" height={20} variant="text" width={150} />
            <TaskFeedDateStyle>
              <Skeleton animation="wave" height={20} variant="text" width={100} />
            </TaskFeedDateStyle>
            <Skeleton animation="wave" height={20} variant="text" width={300} />
          </TaskFeedUserName>
        </Grid>
      </Grid>
    </TaskFeedRowStyle>
  );
};

export default TaskFeedCard;
