import { Autocomplete, Box, Checkbox, FormHelperText, FormLabel, Grid, Paper, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ForecastGraph } from "./BudgetOverviewGraph";
import ForecastBreakdownTable from "./ForecastBreakdownTable";
import { useState } from "react";
import { parseDate } from '@blackhyve/utilities/dates';
import { startOfMonth, differenceInMonths, addMonths, subMonths } from 'date-fns';
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

const initialState = {
  showLaborAndMaterial: false,
  showEstimated: true,
  showSummary: true,
  showContracts: true,
  showInvoiced: true,
};

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const Forecast = ({ project, forecast, forecastFilters = null, onForecastFilterChanged = () => null, showFilters = true, showTable = true }) => {
  const [visibleEntries, setVisibleEntries] = useState(initialState);

  const [filters, setFilters] = useState({
    start_date: null,
    end_date: null,
  });

  const setFilter = (field, value) => {
    setFilters((state) => ({ ...state, [field]: value }));
  };

  const filterDatasets = (forecast, filters) => {
    return [quickActionFilters, dateFilters].reduce((acc, curr) => {
      return curr(acc, { filters, visibleEntries, project });
    }, forecast);
  };

  const filteredForecast = filterDatasets(forecast, filters);

  let startDateMin = parseDate(project.start_date);
  let startDateMax = subMonths(parseDate(filters.end_date || project.sub_completion_date), 1);

  let endDateMin = addMonths(parseDate(filters.start_date || project.start_date), 1);
  let endDateMax = parseDate(project.sub_completion_date);

  return (
    <>
      <Paper elevation={2}>
        {showFilters && (
          <Grid container item px={2} spacing={2} xs={12}>
            {forecastFilters && (
              <>
                <Grid item xs={6}>
                  <Autocomplete
                    multiple
                    id="checkboxes-budgets"
                    options={forecast.filters.budget_lines}
                    value={forecast.filters.budget_lines.filter(line => forecastFilters.budgetLines.includes(line.id))}
                    onChange={(_event, state = []) => {
                      onForecastFilterChanged({
                        ...forecastFilters,
                        budgetLines: state.map(opt => opt.id),
                      })
                    }}
                    sx={{ width: '100% !important' }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => `${option.division} (${option.cost_code}) - ${option.description}`}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.id}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.division} ({option.cost_code}) - {option.description}
                      </li>
                    )}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Budgets" placeholder="Budgets" />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Autocomplete
                    multiple
                    id="checkboxes-contracts"
                    options={forecast.filters.contracts}
                    value={forecast.filters.contracts.filter(line => forecastFilters.contracts.includes(line.id))}
                    onChange={(_event, state = []) => {
                      onForecastFilterChanged({
                        ...forecastFilters,
                        contracts: state.map(opt => opt.id),
                      })
                    }}
                    sx={{ width: '100% !important' }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => `#${option.id} ${option.title}`}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.id}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        #{option.id} {option.title}
                      </li>
                    )}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Contracts" placeholder="Contracts" />
                    )}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={6}>
              <FormLabel>Start Date (optional)</FormLabel>
              <DatePicker
                maxDate={startDateMax}
                minDate={startDateMin}
                value={filters.start_date}
                views={['month', 'year']}
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true,
                    clearable: true,
                  },
                }}
                onChange={(value) => {
                  setFilter('start_date', value ? startOfMonth(new Date(value)) : null);
                }}
              />
              <FormHelperText>Leave blank to use the project start date.</FormHelperText>
            </Grid>

            <Grid item xs={6}>
              <FormLabel>End Date (optional)</FormLabel>
              <DatePicker
                maxDate={endDateMax}
                minDate={endDateMin}
                value={filters.end_date}
                views={['month', 'year']}
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true,
                    clearable: true,
                  },
                }}
                onChange={(value) => {
                  setFilter('end_date', value ? startOfMonth(new Date(value)) : null);
                }}
              />
              <FormHelperText>Leave blank to use the project estimated end date.</FormHelperText>
            </Grid>
          </Grid>
        )}

        <ForecastGraph
          forecast={filteredForecast}
          setVisibleEntries={setVisibleEntries}
          visibleEntries={visibleEntries}
          resetVisibleEntries={() => setVisibleEntries(initialState)}
        />
      </Paper>

      {showTable && (
        <Box pt={6}>
          <Paper elevation={2}>
            <ForecastBreakdownTable forecast={filteredForecast} />
          </Paper>
        </Box>
      )}
    </>
  );
}

const dateFilters = (forecast, { filters, project }) => {
  const filtered = {};

  const startIndex = filters.start_date
    ? Math.max(
      0,
      Math.abs(
        differenceInMonths(startOfMonth(project.start_date), startOfMonth(filters.start_date))
      ) - 1
    )
    : 0;

  const endIndex = filters.end_date
    ? Math.max(
      0,
      Math.abs(
        differenceInMonths(startOfMonth(project.start_date), startOfMonth(filters.end_date))
      ) - 1
    )
    : Infinity;

  for (const datasetName in forecast.datasets) {
    filtered[datasetName] = forecast.datasets[datasetName].filter((_item, index) => {
      return index >= startIndex && index <= endIndex;
    });
  }

  return { datasets: filtered };
};

const quickActionFilters = (forecast, { visibleEntries }) => {
  const datasets = {};

  for (const datasetName in forecast.datasets) {
    if (visibleEntries.showSummary && datasetName.includes('summary_budget')) {
      datasets[datasetName] = forecast.datasets[datasetName];
    }
    if (visibleEntries.showContracts && datasetName.includes('summary_contract')) {
      datasets[datasetName] = forecast.datasets[datasetName];
    }
    if (visibleEntries.showEstimated && datasetName.startsWith('budget')) {
      datasets[datasetName] = forecast.datasets[datasetName];
    }
    if (visibleEntries.showInvoiced && datasetName.startsWith('summary_invoice')) {
      datasets[datasetName] = forecast.datasets[datasetName];
    }
  }

  return { datasets };
};

export default Forecast;
