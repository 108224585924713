import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import {
  fetchCustomViewsAPI,
  deleteCustomViewAPI,
  duplicateCustomViewAPI,
  saveCustomViewAPI,
  createCustomViewAPI,
  updateCustomViewAPI,
} from 'services/CustomViewService';
import isEmpty from 'lodash/isEmpty';
import { normalize, schema } from 'normalizr';
import { handleAPIError } from 'helpers/handleAPIError';
import { logout } from 'features/auth';

/**
 * Fetch custom views
 * @param {String} viewType
 */
export const fetchCustomViews = createAsyncThunk(
  'customView/fetchCustomViews',
  async (viewType, { rejectWithValue }) => {
    const apiResponse = await fetchCustomViewsAPI(viewType);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      const customViewSchema = new schema.Entity('customView');
      const formattedData = new schema.Array(customViewSchema);
      const formattedResponse = normalize(apiResponse.response.data, formattedData);
      return formattedResponse?.entities?.customView;
    }
  }
);

/**
 * Create custom view
 * @param {Object} requestParameter
 * @param {String} requestParameter.name
 * @param {String} requestParameter.view
 * @param {Object} requestParameter.parameters
 */
export const createCustomView = createAsyncThunk(
  'customView/createCustomView',
  async (requestParameter, { rejectWithValue, getState }) => {
    const menuState = getState().customViews.menus;
    const parameters = { ...requestParameter };
    Object.keys(menuState).forEach(
      (menuKey) => (parameters[menuKey] = menuState[menuKey].currentValue)
    );

    const apiResponse = await createCustomViewAPI({ ...requestParameter, parameters });
    return handleAPIError(apiResponse, rejectWithValue);
  }
);

/**
 * Update custom view
 * @param {Object} requestParameter
 * @param {Object} requestParameter.id
 * @param {String} requestParameter.name
 * @param {String} requestParameter.view
 * @param {Object} requestParameter.parameters
 */
export const updateCustomView = createAsyncThunk(
  'customView/updateCustomView',
  async (requestParameter, { rejectWithValue }) => {
    const apiResponse = await updateCustomViewAPI(requestParameter);
    return handleAPIError(apiResponse, rejectWithValue);
  }
);

/**
 * Delete custom view
 * @param {String} id
 */
export const deleteCustomView = createAsyncThunk(
  'customView/deleteCustomView',
  async (id, { rejectWithValue }) => {
    const apiResponse = await deleteCustomViewAPI(id);
    return handleAPIError(apiResponse, rejectWithValue);
  }
);

/**
 * Duplicate custom view
 * @param {String} id
 */
export const duplicateCustomView = createAsyncThunk(
  'customView/duplicateCustomView',
  async (id, { rejectWithValue }) => {
    const apiResponse = await duplicateCustomViewAPI(id);
    return handleAPIError(apiResponse, rejectWithValue);
  }
);

export const customViewAdapter = createEntityAdapter({
  sortComparer: (prev, curr) => new Date(curr.created_at) - new Date(prev.created_at),
});

const initialState = customViewAdapter.getInitialState({
  selected: undefined,
  menus: {},
});

const customViewSlice = createSlice({
  name: 'customView',
  initialState,
  reducers: {
    registerMenuState: (state, action) => {
      if (
        !state.menus.hasOwnProperty(action.payload.name) ||
        isEmpty(state.menus[action.payload.name].currentValue)
      ) {
        state.menus[action.payload.name] = {
          initialValue: action.payload.value,
          currentValue: action.payload.value,
        };
      }
    },
    updateMenuState: (state, action) => {
      if (action.payload?.name) {
        state.menus[action.payload.name] = {
          ...state.menus[action.payload.name],
          currentValue: action.payload.value,
        };
      }
      state.selected = undefined;
    },
    resetToDefaultView: (state, action) => {
      Object.keys(state.menus).forEach((menuKey) => {
        state.menus[menuKey] = {
          ...state.menus[menuKey],
          currentValue: state.menus[menuKey].initialValue,
        };
      });
      state.selected = undefined;
    },
    setCustomView: (state, action) => {
      const selectedCustomView = state.entities[action.payload];
      if (selectedCustomView?.parameters) {
        Object.keys(state.menus).forEach((menuKey) => {
          state.menus[menuKey].currentValue = selectedCustomView.parameters[menuKey]
            ? selectedCustomView.parameters[menuKey]
            : state.menus[menuKey].initialValue;
        });
      }
      if (selectedCustomView?.name) {
        state.selected = selectedCustomView.id;
      }
    },
    setClosedTasks: (state, action) => {
      const { projectId, type, id } = action.payload;

      if (!state.menus['closedTasks'] || !state.menus['closedTasks'].currentValue) {
        state.menus['closedTasks'] = { currentValue: {} };
      }

      const projectClosedTasks = state.menus['closedTasks'].currentValue[projectId] || [];
      let updatedIds = []
      switch (type) {
        case 'remove':
          updatedIds = projectClosedTasks.filter(taskId => taskId !== id);
          break;
        case 'reset':
          updatedIds = action.payload.ids || [];
          break;
        case 'add':
          updatedIds = [...projectClosedTasks, id];
          break;
        default:
          updatedIds = projectClosedTasks;
      }

      state.menus['closedTasks'].currentValue[projectId] = updatedIds
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomViews.fulfilled, (state, action) => {
      customViewAdapter.setAll(state, isEmpty(action.payload) ? {} : action.payload);
    });

    builder.addCase(createCustomView.fulfilled, (state, action) => {
      customViewAdapter.upsertOne(state, action.payload);
    });

    builder.addCase(deleteCustomView.fulfilled, (state, action) => {
      if (action.meta.arg) {
        customViewAdapter.removeOne(state, action.meta.arg);
      }
    });

    builder.addCase(duplicateCustomView.fulfilled, (state, action) => {
      customViewAdapter.upsertOne(state, action.payload);
    });

    builder.addCase(updateCustomView.fulfilled, (state, action) => {
      customViewAdapter.upsertOne(state, action.payload);
    });

    builder.addCase(logout.fulfilled, () => {
      return initialState;
    });
    builder.addCase(logout.rejected, () => {
      return initialState;
    });
  },
});

const customViewReducer = customViewSlice.reducer;

export const selectMenuByName = createSelector(
  [(state, _) => state.customViews?.menus, (_, menuName) => menuName],
  (menus, menuName) => {
    return menus[menuName]?.currentValue ? menus[menuName]?.currentValue : {};
  }
);

export const { registerMenuState, updateMenuState, resetToDefaultView, setCustomView, setClosedTasks } =
  customViewSlice.actions;

// Selectors
export const {
  selectById: selectCustomViewById,
  selectIds: selectCustomViewIds,
  selectEntities: selectCustomViewEntities,
  selectAll: selectAllCustomViews,
  selectTotal: selectTotalCustomViews,
} = customViewAdapter.getSelectors((state) => state.customViews);

export default customViewReducer;
