import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
window.Pusher = Pusher;

let socket = null;
const getSocketOptions = (authToken) => ({
    broadcaster: 'pusher',
    key: process.env.REACT_APP_WEB_SOCKET_KEY,
    wsHost: process.env.REACT_APP_WEB_SOCKET_HOST,
    wsPort: process.env.REACT_APP_WEB_SOCKET_PORT,
    wssPort: process.env.REACT_APP_WEB_SOCKET_PORT,
    forceTLS: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    cluster: 'mt1',
    autoConnect: false,
    authEndpoint: `${process.env.REACT_APP_SERVER_URL}/broadcasting/auth`,
    auth: {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    },
});

export const setupSocketConnection = (authToken) => {
    const isSocketInitialized = socket &&
        socket?.options?.auth?.headers?.Authorization === `Bearer ${authToken}`;

    if (!isSocketInitialized) {
        const options = getSocketOptions(authToken);
        socket = new Echo(options);
    }

    return socket;
};

export const getSocket = () => {
    return socket
}

export const disconnectSocketConnection = () => {
    const socketConnection = getSocket();
    socketConnection?.disconnect();
}


// ----- OLD CODE ----- //
// import Echo from 'laravel-echo';
// import { createContext, useContext, useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';

// const SocketContext = createContext();

// export const useWebSocket = () => {
//   return useContext(SocketContext);
// };

// export const SocketProvider = ({ children }) => {
//   const [echo, setEcho] = useState(null);

//   const authToken = useSelector((state) => state.auth.authToken);

//   const setupSocketConnection = (authToken) => {
//     const options = {
//       broadcaster: 'pusher',
//       key: process.env.REACT_APP_WEB_SOCKET_KEY,
//       wsHost: process.env.REACT_APP_WEB_SOCKET_HOST,
//       wsPort: process.env.REACT_APP_WEB_SOCKET_PORT,
//       wssPort: process.env.REACT_APP_WEB_SOCKET_PORT,
//       forceTLS: true,
//       disableStats: true,
//       enabledTransports: ['ws', 'wss'],
//       cluster: 'mt1',
//       autoConnect: false,
//       authEndpoint: `${process.env.REACT_APP_SERVER_URL}/broadcasting/auth`,
//       auth: {
//         headers: {
//           Authorization: 'Bearer ' + authToken,
//         },
//       },
//     };
//     const echoClient = new Echo(options);

//     setEcho(echoClient);
//   };

//   const disconnectSocket = () => {
//     if (echo) {
//       echo.disconnect();
//     }
//   };

//   useEffect(() => {
//     if (authToken) {
//       setupSocketConnection(authToken);
//     }
//     return () => {
//       disconnectSocket();
//     };
//   }, [authToken]);

//   const value = {
//     echo,
//     setupSocketConnection,
//     disconnectSocket,
//   };

//   return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
// };
