import { useParams } from 'react-router-dom';
import '../../../styles/area.css';
import LocationList from '../components/LocationList';

const LbsListView = () => {
  const projectId = Number(useParams().projectId) || undefined;
  ;

  return <LocationList projectId={projectId} />;
};

export default LbsListView;
