const { getChildren } = require('./getChildren');
const { hasChild } = require('./hasChild');

function calculateTaskProductionRating(task, gantt) {
  if (hasChild(task.id, gantt)) {
    const children = getChildren(task.id, gantt).filter(
      (task) => task.type !== 'placeholder' && task.status !== 'todo'
    );

    const earnedDays = parseFloat(
      children.reduce((sum, child) => sum + (parseFloat(child?.earned_days) || 0), 0).toFixed(2)
    );
    const earnedPlannedDays = parseFloat(
      children
        .reduce((sum, child) => sum + (parseFloat(child?.earned_planned_days) || 0), 0)
        .toFixed(2)
    );

    const pr = earnedPlannedDays ? parseFloat((earnedDays / earnedPlannedDays).toFixed(2)) : 0;
    return { pr, earnedDays, earnedPlannedDays };
  } else if (task.status === 'todo') {
    return { pr: null, earnedDays: null, earnedPlannedDays: null };
  } else {
    return {
      pr: task?.pr,
      earnedDays: task?.earned_days,
      earnedPlannedDays: task?.earned_planned_days,
    };
  }
}

module.exports = { calculateTaskProductionRating };
