import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import format from 'date-fns/format';
import {
  AddCompanyAPI,
  ArchiveCompanyAPI,
  AttachCompanyToProjectAPI,
  DeleteCompanyAPI,
  FetchCompaniesAPI,
  FetchCompaniesOfProjectsAPI,
  FetchProjectCompaniesAPI,
  RemoveCompanyFromProjectAPI,
  RestoreCompanyAPI,
  UpdateCompanyAPI,
  UploadCompanyImageAPI,
  fetchCompaniesAndContactsAPI,
  fetchCompanyAPI,
} from 'features/companies/api/companiesService';
import { handleAPIError } from 'helpers/handleAPIError';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import { normalize, schema } from 'normalizr';
import { assignContactToProject } from '../../contacts/store/contactsSlice';

/**
 * Fetch Companies
 */
export const fetchCompanies = createAsyncThunk(
  'company/fetchCompanies',
  async (args = {}, { rejectWithValue }) => {
    const apiResponse = await FetchCompaniesAPI(args);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      return apiResponse.response.data;
    }
  }
);

export const fetchCompaniesAndContacts = createAsyncThunk(
  'companies/fetchCompaniesAndContacts',
  async (args = {}, { rejectWithValue }) => {
    const apiResponse = await fetchCompaniesAndContactsAPI(args);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      //Normalize data
      const contactSchema = new schema.Entity('contacts');
      const companieschema = new schema.Entity('companies', {
        contacts: [contactSchema],
      });
      return normalize(apiResponse.response.data, [companieschema]);
    }
  }
);

/**
 * Get Companies
 * @param {Number} id
 * @returns
 */
export const fetchCompany = createAsyncThunk(
  'company/getCompanyDetails',
  async (id, { rejectWithValue }) => {
    const apiResponse = await fetchCompanyAPI(id);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * Add Company
 * @param {Object} requestParameter
 * @param {String} requestParameter.name
 */
export const addCompany = createAsyncThunk(
  'company/addCompany',
  async (requestParameter, { rejectWithValue }) => {
    const apiResponse = await AddCompanyAPI(requestParameter);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * Update Company
 * @param {Object} requestParameter
 * @param {String} requestParameter.name
 */
export const updateCompany = createAsyncThunk(
  'company/updateCompany',
  async (requestParameter, { rejectWithValue }) => {
    requestParameter.append('_method', 'put');
    const apiResponse = await UpdateCompanyAPI(requestParameter);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * Archive Company
 * @param {Number} id
 */
export const archiveCompany = createAsyncThunk(
  'company/archiveCompany',
  async (id, { rejectWithValue }) => {
    const apiResponse = await ArchiveCompanyAPI(id);
    return handleAPIError(apiResponse, rejectWithValue);
  }
);

/**
 * Restore Company
 * @param {Number} id
 */
export const restoreCompany = createAsyncThunk(
  'company/restoreCompany',
  async (id, { rejectWithValue }) => {
    const apiResponse = await RestoreCompanyAPI(id);
    return handleAPIError(apiResponse, rejectWithValue);
  }
);

/**
 * Delete Company
 * @param {Number} id
 */
export const deleteCompany = createAsyncThunk(
  'company/deleteCompany',
  async (id, { rejectWithValue }) => {
    const apiResponse = await DeleteCompanyAPI(id);
    return handleAPIError(apiResponse, rejectWithValue);
  }
);

/**
 * Fetch Project Company
 * @param {Number} projectId
 */
export const fetchProjectCompanies = createAsyncThunk(
  'company/fetchProjectCompanies',
  async (projectId, { rejectWithValue }) => {
    const apiResponse = await FetchProjectCompaniesAPI(projectId);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      const contactSchema = new schema.Entity('contacts');
      const companieschema = new schema.Entity('companies', { contacts: [contactSchema] });
      const formattedData = new schema.Array(companieschema);
      const formattedResponse = normalize(apiResponse.response.data, formattedData);
      return formattedResponse;
    }
  }
);

/**
 * Fetch companies of multiple projects
 * @param {[Number]} projectIds
 */
export const fetchCompanyOfProjects = createAsyncThunk(
  'company/fetchCompanyOfProjects',
  async (projectIds, { rejectWithValue }) => {
    const apiResponse = await FetchCompaniesOfProjectsAPI(projectIds);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * Attach company to project
 * @param {Object} requestParameters
 * @param {String} requestParameters.projectId
 * @param {[Number]} requestParameters.companyIds
 */
export const attachCompanyToProject = createAsyncThunk(
  'company/attachCompanyToProject',
  async (requestParameters, { rejectWithValue }) => {
    const apiResponse = await AttachCompanyToProjectAPI(requestParameters);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      return apiResponse.response.data;
    }
  }
);

// /**
//  * Detach company from project
//  * @param {Object} requestParameters
//  * @param {String} requestParameters.projectId
//  * @param {[Number]} requestParameters.companies
//  */
// export const detachCompanyFromProject = createAsyncThunk(
//   'company/detachCompanyFromProject',
//   async ({ projectId, companies }, { getState, rejectWithValue }) => {
//     const apiResponse = await DetachCompanyFromProjectAPI({ projectId, companies });
//     if (apiResponse.error) {
//       return rejectWithValue(apiResponse.response.errors);
//     } else {
//       const companyEntities = getState().companies.entities;
//       const projectContactEntities = getState().projectContacts.entities;
//       const contactIdsToRemove = companies.reduce((contactIds, companyId) => {
//         const contacts = companyEntities[companyId].contacts.filter((contactId) =>
//           Boolean(projectContactEntities[projectId + '-' + contactId])
//         );
//         return [...contactIds, ...contacts];
//       }, []);
//       return { ...apiResponse.response.data, contactIdsToRemove };
//     }
//   }
// );


/**
 * Upload company image
 */
export const uploadCompanyImage = createAsyncThunk(
  'company/uploadImage',
  async (requestParameter, { rejectWithValue }) => {
    const apiResponse = await UploadCompanyImageAPI(requestParameter);
    if (apiResponse?.response?.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * Remove company from project
 * @param {Object} requestParameter
 * @param {Number} requestParameter.id
 * @param {[Number]} requestParameter.companies
 */
export const removeCompanyFromProject = createAsyncThunk(
  'company/removeCompanyFromProject',
  async ({ projectId, companies }, { getState, rejectWithValue }) => {
    const apiResponse = await RemoveCompanyFromProjectAPI({ projectId, companies });
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      const companyEntities = getState().companies.entities;
      const projectContactEntities = getState().projectContacts.entities;
      const contactIdsToRemove = companies.reduce((contactIds, companyId) => {
        const contacts = companyEntities[companyId].contacts.filter((contactId) =>
          Boolean(projectContactEntities[projectId + '-' + contactId])
        );
        return [...contactIds, ...contacts];
      }, []);
      return { ...apiResponse.response.data, contactIdsToRemove };
    }
  }
);

export const companyAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = companyAdapter.getInitialState({
  error: undefined,
  loading: false,
  trades: {},
});

const companiesSlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    clearCompanies: (state) => {
      companyAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    /** Fetch Companies */
    builder.addCase(fetchCompanies.fulfilled, (state, action) => {
      state.loading = false;
      companyAdapter.setAll(state, action.payload);
    });

    builder.addCase(fetchCompanies.pending, (state, action) => {
      state.loading = true;
    });

    /** Get Company */
    builder.addCase(fetchCompany.fulfilled, (state, action) => {
      companyAdapter.upsertOne(state, action.payload);
    });

    /** Add Company */
    builder.addCase(addCompany.fulfilled, (state, action) => {
      companyAdapter.addOne(state, action.payload);
    });

    /** Update Company */
    builder.addCase(updateCompany.fulfilled, (state, action) => {
      companyAdapter.upsertOne(state, action.payload);
    });

    /** Archive Company */
    builder.addCase(archiveCompany.fulfilled, (state, action) => {
      companyAdapter.upsertOne(state, {
        id: action.meta.arg,
        deleted_at: format(new Date(), 'yyyy-MM-dd'),
      });
    });

    /** Restore Company */
    builder.addCase(restoreCompany.fulfilled, (state, action) => {
      companyAdapter.upsertOne(state, {
        id: action.meta.arg,
        deleted_at: null,
      });
    });

    /** Delete Company */
    builder.addCase(deleteCompany.fulfilled, (state, action) => {
      companyAdapter.removeOne(state, action.meta.arg);
      if (state.projectCompanyIds?.includes(action.meta?.arg)) {
        remove(state.projectCompanyIds, (id) => {
          return id == action.meta?.arg;
        });
      }
    });

    /** Fetch Project Companies */
    builder.addCase(fetchProjectCompanies.fulfilled, (state, action) => {
      if (action.payload?.entities?.companies) {
        // state.projectCompanyIds = action.payload.result;
        companyAdapter.upsertMany(state, action.payload?.entities?.companies);
      }
    });

    /** Fetch Companies Of Project */
    builder.addCase(fetchCompanyOfProjects.fulfilled, (state, action) => {
      if (action.payload) {
        companyAdapter.upsertMany(state, action.payload);
      }
    });

    /** Attach company to project */
    // builder.addCase(attachCompanyToProject.fulfilled, (state, action) => {
    //   state.projectCompanyIds.length
    //     ? state.projectCompanyIds.push(...action.meta.arg.companies)
    //     : (state.projectCompanyIds = [...action.meta.arg.companies]);
    // });

    /** Detach company from project */
    // builder.addCase(detachCompanyFromProject.fulfilled, (state, action) => {
    //   state.projectCompanyIds = without(
    //     state.projectCompanyIds,
    //     ...action.meta.arg.companies
    //   );
    // });

    /** Upload company image */
    builder.addCase(uploadCompanyImage.fulfilled, (state, action) => {
      companyAdapter.upsertOne(state, action.payload);
    });

    /** Assign contact from project */
    builder.addCase(assignContactToProject.fulfilled, (state, action) => {
      if (action.meta?.arg?.companyId) {
        const company = state.entities[action.meta.arg.companyId];
        companyAdapter.upsertOne(state, {
          ...company,
          contacts: company?.contacts
            ? [...company?.contacts, ...action.meta.arg.contacts]
            : action.meta.arg.contacts,
        });
      }
    });

    /** Fetch companies and contacts */
    builder.addCase(fetchCompaniesAndContacts.fulfilled, (state, action) => {
      companyAdapter.setAll(
        state,
        isEmpty(action.payload?.entities?.companies) ? [] : action.payload?.entities?.companies
      );
    });
  },
});

export default companiesSlice.reducer;

export const {
  selectById: selectCompanyById,
  selectIds: selectCompanyIds,
  selectEntities: selectCompanyEntities,
  selectAll: selectAllCompanies,
  selectTotal: selectTotalCompanies,
} = companyAdapter.getSelectors((state) => state.companies);

export const { clearCompanies } = companiesSlice.actions;
