import {
  Avatar,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  ListItemButton,
} from '@mui/material';
import Box from '@mui/system/Box';
import isEmpty from 'lodash/isEmpty';
import Clear from '@mui/icons-material/Clear';
import Autocomplete from '@mui/material/Autocomplete';
import { useGetTradesQuery } from 'features/trades/api/trades.api';

/**
 * @param {*} props
 * @param {Set} props.tradeIds
 * @param {Function} props.setTradeIds
 * @returns JSX List of contacts with checkboxes
 */
export const SelectTradeDropdown = ({ tradeIds, setTradeIds, disableEdit, single = false }) => {
  const { data: { entities: tradeEntities = {} } = {}, isLoading } = useGetTradesQuery();

  return (
    <Box display={'flex'} flexDirection={'column'} height={'100%'}>
      <Autocomplete
        disableClearable
        fullWidth
        multiple
        autoFocus={false}
        disableCloseOnSelect={!single}
        disabled={disableEdit}
        disablePortal={false}
        disableScrollLock={true}
        getOptionLabel={(option) => (option.name ? option.name : '')}
        isOptionEqualToValue={(option, value) => option.id === value}
        limitTags={0}
        loading={isLoading}
        options={!isEmpty(tradeEntities) ? Object.values(tradeEntities) : []}
        renderTags={() => null}
        size="small"
        value={tradeIds}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder="Select Trades"
            size="small"
            variant="outlined"
          />
        )}
        renderOption={(props, option, { selected }) => {
          return (
            <ListItemButton {...props}>
              {!single && (
                <ListItemIcon>
                  <Checkbox checked={selected} />
                </ListItemIcon>
              )}
              <ListItemText style={option.deleted_at ? { textDecoration: 'line-through' } : {}}>
                {option?.name}
              </ListItemText>
            </ListItemButton>
          );
        }}
        onChange={(event, newValue) => {
          if (event.key !== 'Backspace') {
            setTradeIds(newValue.map((option) => (option.id ? option.id : option)));
          }
        }}
      />

      <Box dense component={List} maxHeight={'25vh'} sx={{ overflowY: 'auto', padding: '5px' }}>
        {tradeIds?.map((tradeId) => {
          const trade = tradeEntities[tradeId];
          const tradeNameArray = trade?.name?.split(' ');
          return (
            <ListItem disableGutters key={tradeId} style={{ paddingLeft: 5 }}>
              <ListItemIcon>
                <Avatar size="small">
                  {tradeNameArray.length > 1
                    ? tradeNameArray.map((name) => name?.slice(0, 1)?.toUpperCase())
                    : tradeNameArray[0].slice(0, 2).toUpperCase()}
                </Avatar>
              </ListItemIcon>
              <ListItemText primary={trade.name} style={{ marginLeft: 10 }} />

              {!disableEdit && (
                <ListItemSecondaryAction>
                  <IconButton
                    size="large"
                    onClick={() => {
                      setTradeIds(tradeIds.filter((id) => id !== tradeId));
                    }}
                  >
                    <Clear />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
      </Box>
    </Box>
  );
};
