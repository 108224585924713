import isEmpty from 'lodash/isEmpty';
import Box from '@mui/material/Box';
import { Virtuoso } from 'react-virtuoso';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import { fetchUsers } from 'features/users';
import { useEffect, useState } from 'react';
import NotificationLogDetail from './NotificationLogDetails';
import NotificationLogListItem from './NotificationLogListItem';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetWorkspaceNotificationLogQuery } from '../../api/notification.api';
import SkeletonListComponent from 'components/common/v3/SkeletonListComponent';
import {
  StyledLabelForNoResult,
  StyledPageLoadingContainer,
} from 'assets/style-components/typography';
import { Typography } from '@mui/material';
import { fetchCompaniesAndContacts } from 'features/companies/store/companiesSlice';
import { useGetProjectsQuery } from 'features/projects/store/project.api';
import { useSelector } from 'react-redux';
import { selectCurrentUserWorkspaceId } from 'features/auth';

/**
 * Notification list
 * @returns
 */
const NotificationLogList = ({ filters }) => {
  const [loading, setLoading] = useState();
  const [queryParams, setQueryParams] = useState({
    page: 1,
  });
  const [selectedNotification, setSelectedNotification] = useState(null);

  const {
    data: notifications = {},
    isFetching,
    isLoading,
    error,
  } = useGetWorkspaceNotificationLogQuery(queryParams, {
    refetchOnMountOrArgChange: true,
  });
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { data: projects = [], isLoading: isLoadingProjects } = useGetProjectsQuery({
    workspaceId,
  });

  const dispatch = useDispatch();

  const loadMoreRows = () => {
    const nextPage = notifications?.current_page + 1;
    if (nextPage <= notifications?.last_page && !isFetching && !isLoading) {
      setQueryParams((prevParams) => ({ ...prevParams, page: nextPage }));
    }
  };

  useEffect(() => {
    if (!isLoadingProjects) {
      Promise.all([
        dispatch(fetchUsers({ projectIds: projects.map((project) => project.id) })),
        dispatch(fetchCompaniesAndContacts({ queryParam: '&include_archived' })),
      ])
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [dispatch, projects, isLoadingProjects]);

  useEffect(() => {
    setQueryParams({ ...filters });
  }, [filters]);

  return (
    <Box p={2} style={{ height: 'calc(100% - 80px)' }}>
      <Grid container style={{ border: '1px solid #e7e4e4', overflow: 'hidden', height: '100%' }}>
        <Grid
          container
          item
          md={4}
          style={{ borderRight: '1px solid #e7e4e4', height: '100%' }}
          xl={3}
          xs={12}
        >
          {isLoading || loading || isLoadingProjects ? (
            <SkeletonListComponent noOfRows={20} />
          ) : (isEmpty(notifications) || !notifications?.data?.length) && !isFetching ? (
            <StyledLabelForNoResult style={{ padding: 20 }}>
              No Notification Log
            </StyledLabelForNoResult>
          ) : (
            <>
              <Virtuoso
                data={notifications?.data}
                endReached={loadMoreRows}
                style={{ height: '100%', width: '100%', opacity: isFetching ? 0.1 : 1 }}
                itemContent={(index) => {
                  const notification = notifications?.data[index];
                  return (
                    <NotificationLogListItem
                      id={notification.id}
                      key={notification.id}
                      queryParams={queryParams}
                      setSelectedNotification={setSelectedNotification}
                    />
                  );
                }}
              />
              {isFetching && (
                <StyledPageLoadingContainer>
                  <Typography>Please wait...</Typography>
                  <CircularProgress size="2rem" />
                </StyledPageLoadingContainer>
              )}
            </>
          )}
        </Grid>
        <Grid item md={8} xl={9} xs={false}>
          <NotificationLogDetail id={selectedNotification} queryParams={queryParams} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotificationLogList;
