import { styled } from '@mui/material';
import Box from '@mui/system/Box';

export const ActionBar = ({ children, ...boxProps }) => {
  return (
    <Box
      alignItems={'center'}
      borderBottom={1}
      color={'grey.300'}
      display={'flex'}
      pl={3}
      pr={3}
      {...boxProps}
    >
      <Box color={'initial'} width={'100%'}>
        {children}
      </Box>
    </Box>
  );
};

export const ActionBarDivider = styled('div')(({ theme }) => ({
  borderRight: '1px solid',
  margin: '0 2.5px',
  height: 17,
}));
