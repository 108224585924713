import { IconButton, Popover, TableContainer, Tooltip, Typography } from '@mui/material';
import { Table } from 'components/table/Table';
import { parseDate } from '@blackhyve/utilities/dates';
import { useDeleteJobwalkMutation, useGetJobwalksQuery } from '../store/jobwalk.api';
import { Delete, Notes } from '@mui/icons-material';
import { useMemo, useState } from 'react';
import ConfirmDeleteDialog from 'components/common/popovers/ConfirmDeleteDialog';

const columns = [
  { field: 'user', label: 'User', render: ({ user }) => `${user?.first_name} ${user?.last_name}` },
  {
    field: 'effective_date',
    label: 'Effective Date',
    render: ({ effective_date }) =>
      effective_date && parseDate(effective_date).toLocaleDateString(),
  },
  {
    field: 'percent_complete',
    label: 'Percent Complete',
    render: ({ percent_complete }) => parseFloat(percent_complete * 100).toFixed(0) + '%',
  },
  { field: 'days_remaining', label: 'Days Remaining' },
  {
    field: 'forecasted_end',
    label: 'Forecasted End',
    render: ({ forecasted_end }) =>
      forecasted_end && parseDate(forecasted_end).toLocaleDateString(),
  },
  {
    field: 'pr',
    label: 'PR',
    render: ({ pr }) => pr && parseFloat(pr * 100).toFixed(0) + '%',
  },
  {
    field: 'created_at',
    label: 'Created At',
    render: ({ created_at }) => parseDate(created_at).toLocaleString(),
  },
  {
    label: 'Notes',
    field: 'notes',
    render: ({ notes }) => <NotesCell notes={notes} />,
  },
  {
    field: 'delete',
    render: ({ id, task_id: taskId }, { latestJobwalkId, disabled, onDelete }) =>
      disabled ? null : (
        <DeleteCell jobwalkId={id} taskId={taskId} onDelete={latestJobwalkId === id && onDelete} />
      ),
  },
];

export const JobwalkHistory = ({ taskId, TableContainerProps, TableProps, disabled, onDelete }) => {
  const { data: jobwalks = [], isLoading, isFetching } = useGetJobwalksQuery({ taskId });

  const orderedJobwalks = useMemo(() => {
    const sortedList = [...jobwalks].sort((a, b) => {
      const effectiveDateComparison = parseDate(b.effective_date) - parseDate(a.effective_date);

      if (effectiveDateComparison === 0) {
        return parseDate(b.created_at) - parseDate(a.created_at);
      }

      return effectiveDateComparison;
    });

    return sortedList;
  }, [jobwalks]);

  return (
    <TableContainer {...TableContainerProps}>
      <Table
        columns={columns}
        isFetching={isFetching}
        isLoading={isLoading}
        meta={{ latestJobwalkId: orderedJobwalks?.[0]?.id, disabled, onDelete }}
        rows={orderedJobwalks}
        {...TableProps}
      />
    </TableContainer>
  );
};

const NotesCell = ({ notes }) => {
  return (
    notes && (
      <Tooltip
        arrow
        placement="top"
        title={
          <Typography maxHeight={500} overflow={'auto'}>
            {notes}
          </Typography>
        }
      >
        <IconButton>
          <Notes />
        </IconButton>
      </Tooltip>
    )
  );
};

const DeleteCell = ({ taskId, jobwalkId, disabled, onDelete }) => {
  const [deleteJobwalk] = useDeleteJobwalkMutation();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const handleDelete = () => {
    deleteJobwalk({ taskId, jobwalkId })
      .unwrap()
      .then(({ data }) => {
        onDelete(data.updated_tasks[0]);
      })
      .catch((error) => console.error(error));
  };
  return (
    <>
      <ConfirmDeleteDialog
        handleClose={() => setIsConfirmOpen(false)}
        handleDelete={handleDelete}
        item="jobwalk"
        open={isConfirmOpen}
      />
      {disabled ? (
        <Tooltip arrow placement={'top'} title={'Cannot delete most up to date jobwalk record'}>
          <span>
            <IconButton disabled={disabled}>
              <Delete />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <IconButton color={'error'} disabled={disabled} onClick={() => setIsConfirmOpen(true)}>
          <Delete />
        </IconButton>
      )}
    </>
  );
};
