import { Divider } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ImageUploadComponent from 'components/common/ImageUploadComponent';
import { ColorSelect } from 'components/form/ColorSelect';
import { TradesAutocomplete } from 'features/trades/components/TradesAutocomplete';
import { pickBy } from 'lodash';
import { memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useGetCompanyByIdQuery,
  useUpdateCompanyMutation,
  useUploadCompanyLogoMutation,
} from '../api/companies.api';

const initialState = {
  name: '',
  email: '',
  website: '',
  color: '',
  logo: '',
  trade_ids: [],
};

const CompanyDetails = ({ companyId }) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: initialState,
  });
  const { data: company = {}, isLoading } = useGetCompanyByIdQuery({ companyId: companyId });

  const [updateCompany, { isLoading: isUpdating }] = useUpdateCompanyMutation();
  const [uploadLogo, { isLoading: isUploading }] = useUploadCompanyLogoMutation();

  const handleUploadImage = (file) => {
    if (company?.id) {
      const formData = new FormData();
      formData.append('id', company.id);
      formData.append('logo', file);
      uploadLogo({ companyId: company.id, formData });
    }
  };

  const onSubmit = (data) => {
    delete data.logo;
    updateCompany({ company: data });
  };

  useEffect(() => {
    if (company) {
      reset(
        pickBy(company, (value) => value !== null),
        { keepDefaultValues: true }
      );
    }
  }, [company, reset]);

  return (
    <div>
      {isLoading && (
        <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
          <CircularProgress className="loading" />
        </Backdrop>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignItems={'center'} spacing={2}>
          <Grid item m={'auto'} md={6} xs={12}>
            <ImageUploadComponent
              handleUpload={handleUploadImage}
              image={company?.logo?.original_url}
              imageHeight={'auto'}
              imageWidth={'50%'}
              isUploading={isUploading}
              // isImageBlob={!field?.original_url}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h4'}>Company Details</Typography>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item md={6} xs={12}>
              <Typography>Company Name*</Typography>
              <Controller
                control={control}
                defaultValue=""
                name="name"
                rules={{ required: 'Company name is required' }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                    size="small"
                    type="text"
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>Website</Typography>
              <Controller
                control={control}
                defaultValue=""
                name="website"
                render={({ field }) => <TextField {...field} fullWidth size="small" type="text" />}
              />
            </Grid>
            <Grid item alignItems="center" justifyContent="center" xs={1}>
              <Typography>Color</Typography>
              <Controller
                control={control}
                name="color"
                render={({ field }) => <ColorSelect {...field} fullWidth size={'small'} />}
              />
            </Grid>
            <Grid item xs={11}>
              <Typography>Trades*</Typography>
              <Controller
                control={control}
                name="trade_ids"
                render={({ field: { onChange, ...field } }) => (
                  <TradesAutocomplete
                    {...field}
                    multiple
                    onChange={(_event, newValue) => onChange(newValue)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={isUpdating}
                endIcon={isUpdating && <CircularProgress color={'inherit'} size={'1em'} />}
                type="submit"
                variant="contained"
              >
                {company.id ? 'Update' : 'Create'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default memo(CompanyDetails);
