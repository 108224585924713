import Close from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { CONSTRAINT_CONDITIONS } from '../utils/drawerHelper';
import { StyledLabel } from '../utils/drawerStyle';
import { parseDuration } from 'components/projectOverview/gantt/ganttConfig/column/menu/durationMenu';
import { formatterLabels } from 'components/projectOverview/gantt/ganttConfig/column/columnConfig';

const StyledListContainer = styled(Grid)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  padding: '5px',
  maxHeight: '250px',
  overflowY: 'auto',
}));

/**
 * @param {String} taskId
 * @param {Object} gantt
 * @param {[Array]} allTasks
 * @param {[Object]} selectedDependency
 * @param {Function} setSelectedDependency
 * @param {Object} dependencyEntities
 * @returns
 */
const SelectDependency = ({
  selectedDependencies,
  setSelectedDependencies,
  taskId,
  gantt,
  allTasks,
  disableEdit,
}) => {
  const task = gantt.getTask(taskId);
  const connectedTasks = selectedDependencies.map(({ source }) => source);
  const remainingTasks = allTasks.filter(
    (task) =>
      !connectedTasks.includes(task.id) &&
      task.type !== 'placeholder' &&
      !gantt.isChildOf(taskId, task.id) &&
      task.id !== taskId
  );

  const blankDependencyObj = {
    id: +new Date(),
    type: '',
    lag: 0,
    isNew: true,
    source: undefined,
    target: undefined,
    error: null,
  };

  const handleSaveDependency = (dependencyObj) => {
    const cloneRemainingTasks = [...remainingTasks];
    const cloneSelectedDependency = [...selectedDependencies];
    let updatedDependency = [];
    const index = cloneRemainingTasks?.findIndex(({ id }) => id == dependencyObj.source);
    if (index > -1) {
      cloneRemainingTasks.splice(index, 1);
    }
    const selectedDependencyIndex = cloneSelectedDependency?.findIndex(
      ({ id }) => id == dependencyObj.id
    );
    if (selectedDependencyIndex > -1) {
      cloneSelectedDependency[selectedDependencyIndex] = dependencyObj;
      updatedDependency = cloneSelectedDependency;
    } else {
      updatedDependency = [...cloneSelectedDependency, { ...dependencyObj, id: Math.random() }];
    }
    setSelectedDependencies(updatedDependency);
  };

  const handleDeleteDependency = ({ id }) => {
    const cloneSelectedDependency = [...selectedDependencies];
    const index = cloneSelectedDependency.findIndex((dependencyOb) => dependencyOb.id == id);
    if (index > -1) {
      cloneSelectedDependency.splice(index, 1);
      setSelectedDependencies(cloneSelectedDependency);
    }
  };

  if (!gantt || !taskId || !gantt.getTaskNode(taskId) || !task) {
    return null;
  }

  return (
    <Grid container>
      <Grid container direction="column">
        <HeaderRow />
        <StyledListContainer
          container
          item
          direction="column"
          spacing={1}
          sx={{ overflow: 'auto' }}
          wrap="nowrap"
        >
          {selectedDependencies?.map((dependencyObj) => {
            return (
              <DependencyRow
                allTasks={allTasks}
                deleteDependency={handleDeleteDependency}
                dependencyObj={dependencyObj}
                disableEdit={disableEdit}
                gantt={gantt}
                key={dependencyObj.id}
                projectId={task.project_id}
                remainingTasks={remainingTasks}
                saveDependency={handleSaveDependency}
                taskId={taskId}
              />
            );
          })}
          {!disableEdit && (
            <DependencyRow
              allTasks={allTasks}
              deleteDependency={handleDeleteDependency}
              dependencyObj={blankDependencyObj}
              gantt={gantt}
              key={blankDependencyObj.id}
              projectId={task.project_id}
              remainingTasks={remainingTasks}
              saveDependency={handleSaveDependency}
              taskId={taskId}
            />
          )}
        </StyledListContainer>
      </Grid>
    </Grid>
  );
};

const HeaderRow = () => {
  return (
    <Grid container item spacing={1} style={{ paddingLeft: 5, paddingRight: 5 }}>
      <Grid item xs={6}>
        <StyledLabel fontSize="15"> Task Name </StyledLabel>
      </Grid>
      <Grid item xs={3}>
        <StyledLabel fontSize="15"> Type </StyledLabel>
      </Grid>
      <Grid item xs={2}>
        <StyledLabel fontSize="15"> Lag </StyledLabel>
      </Grid>
    </Grid>
  );
};

/**
 * Dependency Row
 * @param {String} ganttId
 * @param {String} taskId
 * @param {Object} dependencyObj
 * @param {[Object]} dependencyObj
 * @param {Function} saveDependency
 * @param {Function} deleteDependency
 * @returns
 */
export const DependencyRow = ({
  gantt,
  dependencyObj,
  remainingTasks,
  saveDependency,
  deleteDependency,
  taskId,
  disableEdit,
}) => {
  const task = gantt.getTask(taskId);
  const [formState, setFormState] = useState(dependencyObj);

  const handleSelectTask = (event, option) => {
    if (!option) {
      return;
    }
    const updatedState = {
      ...formState,
      taskId,
      source: option.id,
      target: taskId,
      type: formState.type ? formState.type : 'FS',
      error: null,
    };

    if (validateLink(updatedState)) {
      saveDependency(updatedState);
      setFormState(updatedState);
    }
  };

  const handleOnChange = (event) => {
    const updatedState = { ...formState, [event.target.name]: event.target.value };
    if (validateLink(updatedState)) {
      saveDependency(updatedState);
      setFormState(updatedState);
    }
  };

  const handleDelete = () => {
    deleteDependency({ id: dependencyObj.id });
  };

  const validateLink = (link) => {
    if (!gantt.isLinkAllowed(link)) {
      setFormState({ ...link, error: { message: 'Invalid link' } });
      return false;
    }
    if (gantt.isCircularLink(link)) {
      setFormState({ ...link, error: { message: 'Is circular link' } });
      return false;
    }
    return true;
  };

  useEffect(() => {
    setFormState(dependencyObj);
  }, [dependencyObj]);

  const sourceTask =
    dependencyObj?.source !== undefined ? gantt.getTask(dependencyObj.source) : undefined;

  return (
    <Grid container item spacing={1}>
      <Grid item xs={6}>
        <Autocomplete
          autoComplete
          autoHighlight
          disabled={task.status === 'complete' || disableEdit}
          getOptionLabel={(option) => option?.text}
          isOptionEqualToValue={(option, value) => option?.id == value?.id}
          options={sourceTask ? [...remainingTasks, sourceTask] : remainingTasks}
          size="small"
          value={sourceTask}
          variant="outlined"
          renderInput={(params) => (
            <TextField
              {...params}
              error={formState.error}
              helperText={formState.error && formState.error?.message}
              placeholder={'Name Of Task'}
              variant="outlined"
            />
          )}
          onChange={handleSelectTask}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          select
          disabled={task.status === 'complete' || disableEdit}
          name="type"
          options={CONSTRAINT_CONDITIONS}
          placeholder="Type"
          size={'small'}
          value={formState?.type}
          variant={'outlined'}
          onChange={handleOnChange}
        >
          {CONSTRAINT_CONDITIONS.map(({ name, value }) => {
            return (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          disabled={task.status === 'complete' || disableEdit}
          name="lag"
          placeholder="Lag"
          size="small"
          type="number"
          value={formState?.lag}
          variant="outlined"
          onChange={handleOnChange}
        />
      </Grid>
      <Grid
        item
        xs={1}
        style={{
          display: 'flex',
          marginTop: '5px',
          justifyContent: 'center',
        }}
      >
        {task.status !== 'complete' && !disableEdit && (
          <Close style={{ color: '#B3B3B3', cursor: 'pointer' }} onClick={handleDelete} />
        )}
      </Grid>
    </Grid>
  );
};

export const handleSyncDependency = (gantt, response) => {
  gantt.batchUpdate(() => {
    if (response.created_dependencies) {
      for (let i = 0; i < response.created_dependencies.length; i++) {
        const dependency = response.created_dependencies[i];
        gantt.addLink({
          id: dependency.id,
          source: dependency.source,
          target: dependency.target,
          type: dependency.type,
          lag: dependency.lag,
          isCreated: true,
        });
      }
    }
    if (response.deleted_dependencies) {
      for (let i = 0; i < response.deleted_dependencies.length; i++) {
        gantt.deleteLink(response.deleted_dependencies[i].id);
      }
    }
    if (response.updated_dependencies) {
      gantt.batchUpdate(() => {
        for (let i = 0; i < response.updated_dependencies.length; i++) {
          const dependency = response.updated_dependencies[i];
          if (gantt.isLinkExists(dependency.id)) {
            const link = gantt.getLink(dependency.id);
            link.source = dependency.source;
            link.target = dependency.target;
            link.type = dependency.type;
            link.lag = dependency.lag;
            link.isUpdated = true;
            gantt.updateLink(link.id);
          }
        }
      });
    }
  });
};

export default SelectDependency;
