import { api, providesList } from 'api';

export const BUDGET_LINE_KEY = 'BudgetLine';

const budgetLinesAPI = api.enhanceEndpoints({ addTagTypes: [BUDGET_LINE_KEY] }).injectEndpoints({
  endpoints: (build) => ({
    getBudgetLinesByBudget: build.query({
      query: ({ budgetId }) => `/budgets/${budgetId}/budget-lines`,
      providesTags: (result, error, args) => (result ? providesList(result, BUDGET_LINE_KEY) : []),
      transformResponse: (response) => response.data,
    }),
    createBudgetLine: build.mutation({
      query: ({ budgetId, ...body }) => ({
        url: `/budgets/${budgetId}/budget-lines`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, _error, { budgetId }) =>
        result
          ? [
            { type: BUDGET_LINE_KEY, id: 'LIST' },
            { type: 'BudgetForecast', id: budgetId },
            { type: 'ProjectForecast' },
          ]
          : [],
    }),
    updateBudgetLine: build.mutation({
      query: ({ budgetLineId, ...body }) => ({
        url: `/budget-lines/${budgetLineId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, _error, params) =>
        result
          ? [
            { type: BUDGET_LINE_KEY, id: 'LIST' },
            { type: BUDGET_LINE_KEY, id: params.id },
            { type: 'BudgetForecast', id: result.budget_id },
            { type: 'ProjectForecast' },
          ]
          : [],
    }),
    deleteBudgetLine: build.mutation({
      query: ({ budgetLine }) => ({
        url: `/budget-lines/${budgetLine.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error, params) =>
        error ? [] : [
          { type: BUDGET_LINE_KEY, id: 'LIST' },
          { type: BUDGET_LINE_KEY, id: params.budgetLine.id },
          { type: 'BudgetForecast', id: params.budgetLine.budget_id },
          { type: 'ProjectForecast' },
        ]
    })
  }),
});

export const {
  useGetBudgetLinesByBudgetQuery,
  useCreateBudgetLineMutation,
  useUpdateBudgetLineMutation,
  useDeleteBudgetLineMutation,
} = budgetLinesAPI;
